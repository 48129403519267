import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {XS_STR_EMPTY, XS_STR_SPACE, XSPredefinedPeriod, XSSearchResult, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSTranslationService} from '@xs/common';
import {XSDataManagerComponent, XSDataManagerOptions, XSDialogable} from '@xs/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcess, LCECertificateOrderBatchProcessPartial, LCECertificateOrderBatchProcessSearch} from '../api/domain/lce-certificate-order-batch-process';
import {LCECertificateOrderBatchProcessService} from '../api/services/lce-certificate-order-batch-process.service';

enum BatchOrderState {
    ALL = 'all',
    IN_PROGRESS = 'inProgress',
    COMPLETED = 'completed',
}

@Component({
    selector: 'lce-certificate-order-batch-processes',
    templateUrl: './lce-certificate-order-batch-processes.component.html',
    host: {class: 'xs-width-full xs-flex-column xs-flex-1'}
})
export class LCECertificateOrderBatchProcessesComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.certificateOrders.certificateOrderBatchProcess.';

    dataManagerOptions: XSDataManagerOptions;

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Output() closeEvent = new EventEmitter<void>();
    @Output() changeEvent = new EventEmitter<void>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    batchSearch: LCECertificateOrderBatchProcessSearch = {
        query: XS_STR_EMPTY,
        completed: undefined,
        createdOnPredefinedPeriod: XSPredefinedPeriod.THIS_MONTH,
        paginationPage: 0,
        paginationSize: 20,
        sort: ['createdOn|desc']
    };

    selectedBatchOrderState: BatchOrderState = BatchOrderState.ALL;
    batchOrderStateItems: XSLabelValueItem[] = [
        {trLabel: 'xs.core.label.all', value: BatchOrderState.ALL},
        {trLabel: this.TR_BASE + 'batchProcessingCompleted.false', value: BatchOrderState.IN_PROGRESS},
        {trLabel: this.TR_BASE + 'batchProcessingCompleted.true', value: BatchOrderState.COMPLETED}
    ];

    private countInProgress: number;
    private countCompleted: number;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService, private batchProcessService: LCECertificateOrderBatchProcessService) {
        super();
        this.initializeTranslation();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data?.styleClass;
            this.readonly = this.dialogConfig.data?.readonly;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        this.buildDataManagerOptions();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public onCompleteEvent(orderBatchProcess: LCECertificateOrderBatchProcess): void {
        this.dataManager.selectedRowResult.completed = orderBatchProcess.completed;
        this.updateStateItems();
        this.changeEvent.emit();
    }

    public onReopenEvent(orderBatchProcess: LCECertificateOrderBatchProcess): void {
        this.dataManager.selectedRowResult.completed = orderBatchProcess.completed;
        this.updateStateItems();
        this.changeEvent.emit();
    }

    public onBatchOrderStatusSelect(): void {
        if (this.selectedBatchOrderState === BatchOrderState.IN_PROGRESS) {
            this.batchSearch.completed = false;
        } else if (this.selectedBatchOrderState === BatchOrderState.COMPLETED) {
            this.batchSearch.completed = true;
        } else {
            this.batchSearch.completed = undefined;
        }
        this.dataManager.search(this.batchSearch.query);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch');
    }

    private retrieveRecordData(selectedRowResult: LCECertificateOrderBatchProcess): Observable<LCECertificateOrderBatchProcess> {
        return this.batchProcessService.retrieve(selectedRowResult.id);
    }

    private search(query?: string): Observable<XSSearchResult<LCECertificateOrderBatchProcessPartial>> {
        this.batchSearch.query = XSUtils.trim(query);
        XSUtils.removeNullAndUndefinedEntries(this.batchSearch);
        this.updateStateItems();
        return this.batchProcessService.search(this.batchSearch).pipe(tap(() => this.updateCaptionSubTitle()));
    }

    private updateCaptionSubTitle(): void {
        // Update the subtitle depending on the value of batchSearch.createdOnPredefinedPeriod or batchSearch.createdOnRange.
        this.dataManagerOptions.results!.captionSubTitle = 'Ce mois';
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query?: string) => this.search(query),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveRecordData(selectedRowData),

            search: {
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE + 'resultFound',
                captionStyleClass: 'xs-height-40',
                listingItemStyleClass: 'xs-min-width-325'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }

    private initializeTranslation(): void {
        this.updateStateItemsTranslation();
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.updateStateItemsTranslation()));
    }

    private updateStateItems(): void {
        forkJoin([
            this.batchProcessService.count({...this.batchSearch, completed: false}).pipe(tap(count => this.countInProgress = count.total)),
            this.batchProcessService.count({...this.batchSearch, completed: true}).pipe(tap(count => this.countCompleted = count.total))
        ]).subscribe(() => this.updateStateItemsTranslation());
    }

    private updateStateItemsTranslation(): void {
        this.batchOrderStateItems.forEach(stateItem => {
            let suffix = XS_STR_EMPTY;
            if (stateItem.value === BatchOrderState.IN_PROGRESS && !XSUtils.isNull(this.countInProgress)) {
                suffix = XS_STR_SPACE + `(${this.countInProgress})`;
            } else if (stateItem.value === BatchOrderState.COMPLETED && !XSUtils.isNull(this.countCompleted)) {
                suffix = XS_STR_SPACE + `(${this.countCompleted})`;
            } else if (!XSUtils.isNull(this.countInProgress) && !XSUtils.isNull(this.countCompleted)) {
                suffix = XS_STR_SPACE + `(${this.countInProgress + this.countCompleted})`;
            }
            stateItem.label = this.translationService.translateKey(stateItem.trLabel) + suffix;
        });
        this.batchOrderStateItems = [...this.batchOrderStateItems];
    }
}
