import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCECertificateOrderPartial, LCECertificateOrderStatus, LCECoreContextService, LCEUserPartial} from '@lce/core';
import {LCEMockCertificateOrderHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-handler';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL} from '@lce/mock/core/lce-mock.constant';
import {
    LCE_SHARED_ENDPOINT,
    LCECertificateOrderBatchProcess,
    LCECertificateOrderBatchProcessCompleteResponse,
    LCECertificateOrderBatchProcessCreate,
    LCECertificateOrderBatchProcessPartial,
    LCECertificateOrderBatchProcessSearch
} from '@lce/shared';
import {XSHttpMethod, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceHandler, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificateOrderBatchProcesses';

@Injectable()
export class LCEMockCertificateOrderBatchProcessHandler extends XSMockPKResourceHandler<LCECertificateOrderBatchProcess, LCECertificateOrderBatchProcessPartial, LCECertificateOrderBatchProcessSearch> {

    public static CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE: Map<string, LCECertificateOrderBatchProcess> = new Map<string, LCECertificateOrderBatchProcess>();

    private queryPredicates: XSMockSearchQueryPredicate<LCECertificateOrderBatchProcess>[] = [
        (batchProcess, query) => batchProcess.code.toLowerCase().includes(query?.toLowerCase()),
        (batchProcess, query) => batchProcess.facilityCode.toLowerCase().includes(query?.toLowerCase()),
        (batchProcess, query) => batchProcess.orders.map((order: any) => order.orderNumber).includes(query?.toLowerCase())
    ];

    private searchPredicates: XSMockSearchPredicate<LCECertificateOrderBatchProcess>[] = [
        (batchProcess, params) => this.httpParamArrayIncludes(params, 'codes', batchProcess.code),
        (batchProcess, params) => this.httpParamArrayIncludes(params, 'facilityCodes', batchProcess.facilityCode),
        (batchProcess, params) => this.httpParamBooleanEquals(params, 'completed', batchProcess.completed)
    ];

    constructor(private contextService: LCECoreContextService, private mockCertificateOrderHandler: LCEMockCertificateOrderHandler) {
        super(DATASET_BASE_ID, LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCECertificateOrderBatchProcess> {
        return LCEMockCertificateOrderBatchProcessHandler.CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(batchProcess: LCECertificateOrderBatchProcess): LCECertificateOrderBatchProcessPartial {
        return {
            id: batchProcess.id,
            createdOn: batchProcess.createdOn,
            code: batchProcess.code,
            facilityCode: batchProcess.facilityCode,
            nLastOrders: batchProcess.nLastOrders,
            size: batchProcess.size,
            completed: batchProcess.completed
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Count
            this.buildCountMockData()
            //this.buildCountMockData({matchRequestSearchFilter: {completed: true}}) // TODO: Not working yet !!!
        ];
    }

    private buildReopenMockData(batchProcessID: string): XSMockData {
        let rURL = LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index;
        rURL += '/' + batchProcessID;
        rURL += '/' + LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.reopen;
        return {
            id: DATASET_BASE_ID + '.reopen.' + batchProcessID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: rURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: this.randomRequestDelay(),
            getResponseData: () => {
                const batchProcess = LCEMockCertificateOrderBatchProcessHandler.CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE.get(batchProcessID)!;
                const on = new Date().toISOString();
                const by = LCEMockUtils.randomUserMunicipalEmployee();
                batchProcess.completed = false;
                batchProcess.completedOn = undefined;
                batchProcess.completedBy = undefined;
                batchProcess.reopenedOn = on;
                batchProcess.reopenedBy = by;
                batchProcess.updatedOn = on;
                batchProcess.updatedBy = by;
                return this.toCompleteResponse(batchProcess);
            }
        };
    }

    private buildCompleteMockData(batchProcessID: string): XSMockData {
        let rURL = LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index;
        rURL += '/' + batchProcessID;
        rURL += '/' + LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.complete;
        return {
            id: DATASET_BASE_ID + '.complete.' + batchProcessID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: rURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: this.randomRequestDelay(),
            getResponseData: () => {
                const batchProcess = LCEMockCertificateOrderBatchProcessHandler.CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE.get(batchProcessID)!;
                const on = new Date().toISOString();
                const by = LCEMockUtils.randomUserMunicipalEmployee();
                batchProcess.completed = true;
                batchProcess.completedOn = on;
                batchProcess.completedBy = by;
                batchProcess.updatedOn = on;
                batchProcess.updatedBy = by;
                return this.toCompleteResponse(batchProcess);
            }
        };
    }

    private buildDownloadMockData(batchProcessID: string): XSMockData {
        let rURL = LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index;
        rURL += '/' + batchProcessID;
        rURL += '/' + LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.download;
        return {
            id: DATASET_BASE_ID + '.download.' + batchProcessID,
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: rURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 4000
        };
    }

    private toCompleteResponse(batchProcess: LCECertificateOrderBatchProcess): LCECertificateOrderBatchProcessCompleteResponse {
        return {
            id: batchProcess.id,
            code: batchProcess.code,
            completed: batchProcess.completed,
            completedOn: batchProcess.completedOn,
            completedBy: batchProcess.completedBy,
            reopenedOn: batchProcess.reopenedOn,
            reopenedBy: batchProcess.reopenedBy,
            updatedOn: batchProcess.updatedOn,
            updatedBy: batchProcess.updatedBy
        } as LCECertificateOrderBatchProcessCompleteResponse;
    }

    private createResponseData(batchProcessCreate: LCECertificateOrderBatchProcessCreate): LCECertificateOrderBatchProcess {
        const batchProcess = this.buildRandomCertificateOrderBatchProcess(batchProcessCreate.nLastOrders, false);
        LCEMockCertificateOrderBatchProcessHandler.CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE.set(batchProcess.id, batchProcess);
        this.addBatchProcessMockData(batchProcess.id);
        return batchProcess;
    }

    private buildStorage(n: number = 5): void {
        for (let $i = 0; $i < n; $i++) {
            const batchProcess = this.buildRandomCertificateOrderBatchProcess();
            LCEMockCertificateOrderBatchProcessHandler.CERTIFICATE_ORDER_BATCH_PROCESS_STORAGE.set(batchProcess.id, batchProcess);
            this.addBatchProcessMockData(batchProcess.id);
        }
    }

    private addBatchProcessMockData(batchProcessID: string): void {
        super.addResourceBaseMockData(batchProcessID);
        this.mockDataArray.push(this.buildDownloadMockData(batchProcessID));
        this.mockDataArray.push(this.buildCompleteMockData(batchProcessID));
        this.mockDataArray.push(this.buildReopenMockData(batchProcessID));
    }

    private buildRandomCertificateOrderBatchProcess(nOrders: number = 10, completeState?: boolean): LCECertificateOrderBatchProcess {
        const digits = XSUtils.randomDigits(6);
        const batchProcessCode = `LCE-BCH-${digits}`;

        const completed = XSUtils.isNull(completeState) ? XSUtils.randomElement([true, false]) : completeState;
        const completedOn = completed ? new Date().toISOString() : undefined;

        let orderStatus: any = LCECertificateOrderStatus.PROCESSING;
        if (completed === true) {
            orderStatus = [
                LCECertificateOrderStatus.READY,
                LCECertificateOrderStatus.READY,
                LCECertificateOrderStatus.READY,
                LCECertificateOrderStatus.OUT_FOR_DELIVERY,
                LCECertificateOrderStatus.DELIVERED,
                LCECertificateOrderStatus.ERROR,
                LCECertificateOrderStatus.CANCELLED
            ];
        }
        const orders: LCECertificateOrderPartial[] = this.mockCertificateOrderHandler.generateRandomCertificateOrders(nOrders, orderStatus, batchProcessCode);

        return {
            id: XSUtils.uuid(),
            createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
            createdOn: new Date().toISOString(),
            code: batchProcessCode,
            facilityCode: LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL,
            nLastOrders: XSUtils.randomElement([15, 20, 30, 50]),
            size: orders.length,
            orders: orders,
            completed: completed,
            completedOn: completedOn,
            completedBy: completed ? LCEMockUtils.randomUserMunicipalEmployee() : undefined
        };
    }
}
