import {Injectable} from '@angular/core';
import {LCEAuthorizationService, LCEHttpClientService} from '@lce/core';
import {LCESharedContextService} from './lce-shared-context.service';

@Injectable()
export abstract class LCESharedAuthorizationService extends LCEAuthorizationService {

    protected constructor(protected httpClientService: LCEHttpClientService, protected contextService: LCESharedContextService) {
        super(httpClientService, contextService);
    }

}
