import {Injectable} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEUser, LCEUserType} from '@lce/core';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEUserAccountComponent} from './lce-user-account.component';
import {LCEUserAccountCreateUpdateComponent} from './create-update/lce-user-account-create-update.component';

export interface LCEUserAccountDialogRecordArg {
    userID?: string;
    user?: LCEUser;
    showEditButton?: boolean;
    header?: boolean;
    tabViewActiveIndex?: number;
    userType: LCEUserType;
    onClose?: (user?: LCEUser) => void;
    onEdit?: (user: LCEUser) => void;
}

export interface LCEUserAccountDialogCreateUpdateArg {
    userID?: string;
    user?: LCEUser;
    showEditButton?: boolean;
    userType: LCEUserType;
    showRecordAfterSave?: boolean;
    onClose?: (user?: LCEUser) => void;
    onSave?: (user: LCEUser) => void;
}

@Injectable()
export class LCEUserAccountDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEUserAccountDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        XSAssert.notEmpty(arg.userType, 'userType');

        if (XSUtils.isEmpty(arg.userID) && XSUtils.isEmpty(arg.user)) {
            throw new Error('arg.userID and arg.user cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.userID) && !XSUtils.isEmpty(arg.user)) {
            throw new Error('arg.userID and arg.user cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                user: arg.user,
                userID: arg.userID,
                userType: arg.userType,
                showEditButton: arg.showEditButton,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-1000',
                tabViewActiveIndex: arg.tabViewActiveIndex
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: arg.header,
            headerSeparator: arg.header,
            dialogStyleClass: 'xs-min-height-600',
            closeOnEscape: true
        };

        const accountComponent: LCEUserAccountComponent = this.dialogService.openComponentDialog(LCEUserAccountComponent, dConfig);

        accountComponent.closeEvent.subscribe((savedUser: LCEUser) => {
            if (arg?.onClose) arg!.onClose!(savedUser);
        });

        accountComponent.editEvent.subscribe((user: LCEUser) => {
            if (!XSUtils.isNull(arg.onEdit)) arg!.onEdit!(user);
            accountComponent.closeDialog();
            this.openCreateUpdateDialog({
                user: user,
                userType: arg.userType,
                showRecordAfterSave: true
            });
        });
        // handle update component
    }

    public openCreateUpdateDialog(arg?: LCEUserAccountDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.userID) && !XSUtils.isEmpty(arg?.user)) {
            throw new Error('arg.userID and arg.user cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                userID: arg?.userID,
                user: arg?.user,
                userType: arg?.userType,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-1000'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEUserAccountCreateUpdateComponent = this.dialogService.openComponentDialog(LCEUserAccountCreateUpdateComponent, dConfig);

        createUpdateComponent.closeEvent.subscribe((user: LCEUser) => {
            if (arg?.onClose) arg!.onClose!(user);
        });

        createUpdateComponent.saveEvent.subscribe((user: LCEUser) => {
            if (!user) {
                createUpdateComponent.closeDialog();
                return;
            }
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(user);
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({userID: user.id, userType: user.type, header: false});
        });
    }
}
