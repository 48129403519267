import {Injectable} from '@angular/core';
import {LCEHttpClientService} from '@lce/core';
import {LCESharedAuthorizationService} from '@lce/shared';
import {environment} from '@lce-mbo/environments/environment';
import {LCEMBOContextService} from '@lce-mbo/services/lce-mbo-context.service';

@Injectable({providedIn: 'root'})
export class LCEMBOAuthorizationService extends LCESharedAuthorizationService {

    protected constructor(protected httpClientService: LCEHttpClientService, protected contextService: LCEMBOContextService) {
        super(httpClientService, contextService);
    }

    public canViewShowcase(): boolean {
        return environment.mock === true;
    }
}
