<div
        class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()" class="{{contentStyleClass}}">
        <div class="grid">
            <div class="col-12 lg:col-4">
                <div
                        class="xs-flex-column-center xs-with-full xs-pt-15 xs-plr-30 xs-pb-30 xs-mb-30 xs-lg-border-bottom-none-imp xs-md-border-bottom-discrete xs-sm-border-bottom-discrete">

                    <xs-image [src]="municipality?.mainImage" styleClass="xs-max-width-350"></xs-image>

                    <xs-button
                            [icon]="ICON.camera"
                            class="xs-mt-10"
                            label="xs.core.label.uploadImage"
                            size="intermediate"
                            type="text">
                    </xs-button>
                </div>
            </div>
            <div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="col-12 lg:col-8 xs-pl-10">

                <div class="grid xs-fields xs-fields-spacer-15">
                    <div class="col-12 md:col-6">
                        <lce-input-field-district-autocomplete [options]="districtField" [showIcon]="true">
                        </lce-input-field-district-autocomplete>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="nameField"></xs-input-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="fullNameField"></xs-input-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="shortNameField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="mayorFirstNameField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="mayorLastNameField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-calendar [options]="mayorSinceField"></xs-input-field-calendar>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="populationSizeField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="populationUpdateYearField"></xs-input-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="squareKMField"></xs-input-field-text>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-mtb-15">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>

    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.municipality"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-justify-content-end">
        <xs-button
                (clickEvent)="fillForm()"
                *ngIf="isCreateMode()"
                label="Fill Form"
                size="intermediate"
                styleClass="xs-mr-50-imp"
                type="text">
        </xs-button>
        <xs-button
                (clickEvent)="reset()"
                *ngIf="isCreateMode()"
                [confirmation]="resetConfirmation"
                [disabled]="createUpdateLoading"
                [showConfirmation]="shouldShowResetConfirmation()"
                confirmationPopupStyleClass="xs-font-size-10"
                label="xs.core.label.reset"
                size="intermediate"
                type="text">
        </xs-button>
        <xs-button
                (clickEvent)="close()"
                [confirmation]="closeConfirmation"
                [disabled]="createUpdateLoading"
                [outlined]="true"
                [showConfirmation]="shouldShowCloseConfirmation()"
                confirmationPopupStyleClass="xs-font-size-10"
                label="xs.core.label.close"
                severity="secondary"
                styleClass="xs-mlr-15-imp"
                type="button">
        </xs-button>
        <xs-button
                (clickEvent)="createUpdate()"
                [loading]="createUpdateLoading"
                label="xs.core.label.save"
                type="button">
        </xs-button>
    </div>
</ng-template>