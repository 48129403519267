import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCECMDDeliveryCompaniesComponent} from './lce-cmd-delivery-companies.component';

const routes: Routes = [{path: 'delivery-companies', component: LCECMDDeliveryCompaniesComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCECMDDeliveryCompanyRoutingModule {
}