import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEServicePoint, LCEServicePointCreate, LCEServicePointPartial, LCEServicePointSearch, LCEUserPartial} from '@lce/core';
import {XSAddressType, XSAddressUnstructured, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import SERVICE_POINT_JSON from '../data/lce-service-points.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCEMockUtils} from '../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';
import {LCEMockMunicipalityHandler} from './lce-mock-municipality-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.servicePoints';

@Injectable()
export class LCEMockServicePointHandler extends XSMockPKResourceAuditFullHandler<LCEServicePoint, LCEServicePointPartial, LCEServicePointSearch>
    implements XSMockPKSearchable<LCEServicePointPartial> {

    public static SERVICE_POINT_STORAGE: Map<string, LCEServicePoint> = new Map<string, LCEServicePoint>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEServicePoint>[] = [
        (servicePoint, query) => servicePoint.fullName.toLowerCase().includes(query),
        (servicePoint, query) => servicePoint.name.toLowerCase().includes(query),
        (servicePoint, query) => servicePoint.subName?.toLowerCase().includes(query),
        (servicePoint, query) => servicePoint.municipality.name.toLowerCase().includes(query),
        (servicePoint, query) => servicePoint.municipality.code.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEServicePoint>[] = [
        (servicePoint, params) => this.httpParamArrayIncludes(params, 'codes', servicePoint.code),
        (servicePoint, params) => this.httpParamArrayIncludes(params, 'municipalityCodes', servicePoint.municipality.code),
        (servicePoint, params) => this.httpParamArrayIncludes(params, 'districtCodes', servicePoint.municipality.district.code)
    ];

    constructor(private contextService: LCECoreContextService, private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.servicePoints.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEServicePoint> {
        return LCEMockServicePointHandler.SERVICE_POINT_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(servicePoint: LCEServicePoint): LCEServicePointPartial {
        return {
            id: servicePoint.id,
            name: servicePoint.name,
            code: servicePoint.code,
            fullName: servicePoint.fullName,
            subName: servicePoint.subName,
            municipality: servicePoint.municipality
        };
    }

    buildMockDataArray(): void {
        // Build a map from the json file containing fake service point.
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.AUDIT_FULL_ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 2,
                    active: 2,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];


    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEServicePointPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private createResponseData(body: any): LCEServicePoint {
        const servicePointCreate: LCEServicePointCreate = body as LCEServicePointCreate;
        const mainImage = 'assets/mock/images/service-points/ci-create-service-point.webp';
        const servicePoint: LCEServicePoint = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-SPT-' + XSUtils.randomDigits(3),
            name: servicePointCreate.name,
            fullName: servicePointCreate.fullName,
            subName: servicePointCreate.subName,
            address: servicePointCreate.address,
            municipality: this.mockMunicipalityHandler.findOneByCodeAsPartial(servicePointCreate.municipalityCode),
            gmapLocation: servicePointCreate.mainImage ? servicePointCreate.mainImage : mainImage,
            contactPhoneNumber: servicePointCreate.contactPhoneNumber,
            contactEmail: servicePointCreate.contactEmail,
            primaryContactPerson: servicePointCreate.primaryContactPerson,
            secondaryContactPerson: servicePointCreate.secondaryContactPerson,
            openingHours: undefined
        };

        LCEMockServicePointHandler.SERVICE_POINT_STORAGE.set(servicePoint.id, servicePoint);
        this.addResourceBaseMockData(servicePoint.id);
        this.mockDataArray.push(this.buildFindByCodePartialMockData(servicePoint.code));

        return servicePoint;
    }

    private buildStorage(): void {
        SERVICE_POINT_JSON.forEach((jsonServicePoint) => {
            const servicePoint: LCEServicePoint = {
                ...jsonServicePoint,
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                municipality: this.mockMunicipalityHandler.findOneByCodeAsPartial(jsonServicePoint.municipality),
                address: {
                    type: XSAddressType.UNSTRUCTURED,
                    title: 'Patisserie Chez Paul',
                    line1: 'Rue Des Jardins, à 100 m du Restaurant l\'Automatic',
                    line2: 'Deux-Plateaux, Vallon',
                    city: 'abidjan',
                    countryISO: 'ci'
                } as XSAddressUnstructured
            };

            if (XSUtils.randomElement<boolean>([true, true, false])) {
                servicePoint.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                servicePoint.updatedOn = new Date().toISOString();
            }

            LCEMockServicePointHandler.SERVICE_POINT_STORAGE.set(servicePoint.id, servicePoint);
            this.addResourceBaseMockData(servicePoint.id);
            this.mockDataArray.push(this.buildFindByCodePartialMockData(servicePoint.code));

        });
    }
}
