import { InjectionToken } from '@angular/core';
import { LCECategory, LCECategoryCreate, LCECategoryPartial, LCECategorySearch } from '../domain/lce-category';
import { XSHttpClientService, XSPKResourceAuditFullService } from '@xs/base';
import { Observable } from 'rxjs';
import { LCEValidatorUtils } from '../utils/lce-validator-utils';

export const LCE_TOKEN_CATEGORY_SERVICE = new InjectionToken<LCECategoryService>('lce.token.categoryService');

export abstract class LCECategoryService extends XSPKResourceAuditFullService<LCECategory, LCECategoryPartial, LCECategorySearch> {

    protected constructor(protected httpClientService: XSHttpClientService, protected endpointIndex: string, protected resourceName: string) {
        super(httpClientService, endpointIndex, resourceName);
    }

    public create(categoryCreate: LCECategoryCreate): Observable<LCECategory> {
        LCEValidatorUtils.validateCategoryCreate(categoryCreate);
        return this.httpClientService.post<LCECategory>(this.buildEndpoint(), categoryCreate);
    }
}
