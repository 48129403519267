import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateOrderStatsCount, LCECertificateOrderStatus} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';

@Component({selector: 'lce-certificate-order-simple-stats', templateUrl: './lce-certificate-order-simple-stats.component.html'})
export class LCECertificateOrderSimpleStatsComponent implements OnInit, OnDestroy {

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() statsContainerStyleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showStatItemBorders?: boolean;

    @Input() municipalityCodes: string[];
    @Input() statuses: LCECertificateOrderStatus[];

    @Input() title?: string;

    loading: boolean = false;
    stats: LCECertificateOrderStatsCount;

    private subscription: Subscription = new Subscription();

    constructor(private certificateOrderService: LCECertificateOrderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.municipalityCodes, 'municipalityCodes');
        XSAssert.notEmpty(this.statuses, 'statuses');

        if (XSUtils.isNull(this.showBorder)) this.showBorder = false;
        if (XSUtils.isNull(this.showStatItemBorders)) this.showStatItemBorders = true;

        const statsSearch: LCECertificateOrderSearch = {
            statuses: this.statuses,
            municipalityCodes: this.municipalityCodes
        };
        this.loading = true;
        this.subscription.add(
            this.certificateOrderService.countStats(statsSearch).subscribe((simpleStats: LCECertificateOrderStatsCount) => {
                this.stats = simpleStats;
                this.loading = false;
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
