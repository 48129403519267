<xs-status-generic
        (clickEvent)="statusAuditOverlay.toggle($event.event)"
        [baseTranslation]="TR_STATUS"
        [clickable]="clickable"
        [data]="data"
        [showBackground]="showBackground"
        [showOnlyState]="showOnlyCircle"
        [styleClass]="styleClass"
        statusStyleClass="{{textStyleClass}} xs-font-size-intermediate-imp">
</xs-status-generic>

<p-overlayPanel
    #statusAuditOverlay
    styleClass="xs-overlay-panel-content-no-padding xs-mt-25-imp">
    <ng-template pTemplate>
        <xs-status-audit
                *ngIf="statusAudit | xsIsNotEmpty"
                [auditByOverlayTemplateRef]="auditByOverlayTemplateRef"
                [currentStatus]="status"
                [data]="internalStatusAudit"
                [options]="statusAuditOptions"
                [showAuditByOverlay]="showAuditByOverlay"
                [verticalGapHeight]="verticalGapHeight"
                styleClass="xs-ptb-20-imp">
        </xs-status-audit>
    </ng-template>
</p-overlayPanel>


