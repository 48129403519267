<div [ngClass]="{ 'xs-disabled': disabled }" class="xs-flex-column-center {{ styleClass }}">
    <div class="xs-flex xs-align-items-center">
        <xs-icon (clickEvent)="numberOfCopiesMinusOne()" [color]="numberOfCopiesColor" [size]="size" [styleClass]="minusSignStyleClass" [value]="ICON.minus"></xs-icon>

        <span [style.color]="numberOfCopiesColor" [style.font-size]="fontSizeStyleValue" class="xs-display-block xs-text-align-center xs-mlr-40 {{ fontSizeStyleClass }} {{ numberStyleClass }}" id="numberOfCopies">{{
                numberOfCopies
            }}</span>

        <xs-icon (clickEvent)="numberOfCopiesPlusOne()" [color]="numberOfCopiesColor" [size]="size" [styleClass]="plusSignStyleClass" [value]="ICON.plus"></xs-icon>
    </div>
    <span *ngIf="belowText | xsIsNotEmpty" class="xs-display-block xs-font-size-small xs-color-secondary xs-mt-5 {{ belowTextStyleClass }}">{{ belowText! | translate }}</span>
</div>
