import {Component, Input} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-smartcity-article-stats-icons', templateUrl: './lce-smartcity-article-stats-icons.component.html'})
export class LCESmartCityStatsIconsComponent {
    ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() readCount: number;
    @Input() scanCount: number;
    @Input() shareCount: number;
}
