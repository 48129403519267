import { Component, Input, OnInit } from '@angular/core';
import { LCEMunicipalityPartial } from '@lce/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';
import { LCEMunicipalityDialogService } from '../lce-municipality-dialog.service';
import { XSAssert, XSUtils } from '@xs/base';

@Component({ selector: 'lce-municipality-partial-overlay', templateUrl: './lce-municipality-partial-overlay.component.html', providers: [LCEMunicipalityDialogService] })
export class LCEMunicipalityPartialOverlayComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCEMunicipalityPartial;

    @Input() showBorder?: boolean;
    @Input() viewRecord?: boolean;
    @Input() showBackgroundIcon?: boolean;

    constructor(private municipalityDialogService: LCEMunicipalityDialogService) {}

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isNull(this.showBorder)) this.showBorder = false;
        if (XSUtils.isNull(this.viewRecord)) this.viewRecord = true;
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = false;
    }

    public openDetail(): void {
        this.municipalityDialogService.openRecordDialog({ municipalityID: this.data.id });
    }
}
