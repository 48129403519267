import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCEHttpClientService} from '@lce/core';
import {XSTranslationService} from '@xs/common';
import {XSAuthenticationBaseService, XSAuthenticationEndpoint, XSAuthenticationRoute, XSDialogService, XSLocalStorageService} from '@xs/core';
import {LCE_CMD_AUTHENTICATION_ROUTE} from '../constants/lce-cmd.constant';
import {LCECMDContextService} from './lce-cmd-context.service';
import {LCE_CMD_ENDPOINT} from '../constants/lce-cmd-endpoint.constant';
import {LCE_CMD_ICON} from '../constants/lce-cmd-icon.constant';

@Injectable({providedIn: 'root'})
export class LCECMDAuthenticationService extends XSAuthenticationBaseService {

    constructor(
        protected router: Router,
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCECMDContextService,
        protected translationService: XSTranslationService,
        protected localStorageService: XSLocalStorageService,
        protected dialogService: XSDialogService) {
        super(router, httpClientService, contextService, translationService, localStorageService, dialogService, LCE_CMD_ICON);
    }

    public getEndpoint(): XSAuthenticationEndpoint {
        return LCE_CMD_ENDPOINT.authentication;
    }

    public getRoute(): XSAuthenticationRoute {
        return LCE_CMD_AUTHENTICATION_ROUTE;
    }
}
