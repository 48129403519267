import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEDistrictPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-district-partial',
    templateUrl: './lce-district-partial.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEDistrictPartialComponent implements OnInit {
    
    readonly ICON_DISTRICT = LCE_SHARED_ICON.district;

    @Input() styleClass?: string;
    @Input() fullNameStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() labelStyleClass?: string;
    @Input() label?: string;
    @Input() showDefaultLabel?: boolean;

    @Input() data: LCEDistrictPartial;

    @Input() showCode?: boolean;
    @Input() showIcon?: boolean;

    @Input() intermediate?: boolean;

    @Output() clickEvent = new EventEmitter<LCEDistrictPartial>();

    clickable: boolean;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.showCode)) this.showCode = true;
        if (XSUtils.isNull(this.intermediate)) this.intermediate = true;
        if (this.showDefaultLabel === true) {
            this.label = 'lce.core.label.district';
        }
        this.clickable = this.clickEvent.observers.length > 0;
    }
}
