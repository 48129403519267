import {Injectable} from '@angular/core';
import {LOG, XSAssert, XSUtils, XSWebSocketClientConfig, XSWebSocketClientService} from '@xs/base';
import {BehaviorSubject, Subject} from 'rxjs';
import {LCEWebSocketAuthenticationResponse, LCEWebSocketConnectionStatus, LCEWebSocketMessage, LCEWebSocketMessageType} from '../domain/lce-websocket';
import {LCEEventPartial} from '../domain/lce-event';

@Injectable({providedIn: 'root'})
export class LCEWebSocketService {
    private authenticationSubject = new Subject<LCEWebSocketAuthenticationResponse>();
    onAuthentication = this.authenticationSubject.asObservable();

    private eventSubject = new Subject<LCEEventPartial>();
    onEvent = this.eventSubject.asObservable();

    private connectionStatusChangeSubject = new BehaviorSubject<LCEWebSocketConnectionStatus>(LCEWebSocketConnectionStatus.CLOSED);
    onConnectionStatusChange = this.connectionStatusChangeSubject.asObservable();

    private webSocketServerURL: string;
    private config?: XSWebSocketClientConfig;
    private token: string;
    private initialized: boolean = false;
    private beenConnected: boolean = false;

    constructor(private webSocketClientService: XSWebSocketClientService) {
    }

    public hasBeenInitialized(): boolean {
        return this.initialized;
    }

    public initialize(webSocketServerURL: string, webSocketClientConfig?: XSWebSocketClientConfig): void {
        if (this.initialized) throw new Error('LCEWebSocketService already initialized and cannot be initialized twice. In this case the reconnect function should be used.');
        XSAssert.notEmpty(webSocketServerURL, 'webSocketServerURL');
        this.webSocketServerURL = webSocketServerURL.trim();
        this.config = this.handleConfig(webSocketClientConfig);

        LOG().debug('Initializing WebSocket Service [webSocketServerURL: ' + this.webSocketServerURL + '] ...', this.config);

        this.webSocketClientService.onOpen.subscribe(() => this.onOpen());
        this.webSocketClientService.onMessage.subscribe((data) => this.onMessage(data));
        this.webSocketClientService.onError.subscribe((error: any) => this.onError(error));
        this.webSocketClientService.onClosing.subscribe(() => this.onClosing());
        this.webSocketClientService.onClose.subscribe(() => this.onClose());

        this.initialized = true;
    }

    public isConnected(): boolean {
        return this.connectionStatusChangeSubject.getValue() === LCEWebSocketConnectionStatus.CONNECTED;
    }

    public hasBeenConnected(): boolean {
        return this.beenConnected;
    }

    public connect(token: string): void {
        if (XSUtils.isEmpty(token)) throw new Error('token must not be null or empty.');
        this.token = token.trim();
        this.webSocketClientService.connect(this.webSocketServerURL, this.config);
    }

    public reconnect(): void {
        if (!this.beenConnected) throw new Error('failed to reconnect. LCE WebSocket must have been connected at least once before.');
        if (this.isConnected()) {
            LOG().warn('Reconnect not needed. LCE WebSocket is already connected.');
            return;
        }
        this.webSocketClientService.reconnect();
    }

    public disconnect(): void {
        this.webSocketClientService.disconnect();
        this.beenConnected = false;
        LOG().info('---| LCE WebSocket: Disconnecting ...');
    }

    private onMessage(webSocketMessage: LCEWebSocketMessage): void {
        LOG().debug('---| LCE WebSocket Message Received: ', webSocketMessage);
        if (XSUtils.isEmpty(webSocketMessage)) return;
        switch (webSocketMessage.type) {
            case LCEWebSocketMessageType.AUTHENTICATION_TOKEN:
                this.authenticationSubject.next(webSocketMessage.data);
                break;
            case LCEWebSocketMessageType.NOTIFICATION:
                this.eventSubject.next(webSocketMessage.data);
                break;
            case LCEWebSocketMessageType.ERROR:
                LOG().error('Something went wrong with the WebSocket Server :-(.', webSocketMessage.data);
                break;
            default:
                LOG().warn('Message Type [' + webSocketMessage.type + '] not handled yet !');
                break;
        }
    }

    private handleConfig(config?: XSWebSocketClientConfig): XSWebSocketClientConfig {
        let webSocketConfig = config;
        if (XSUtils.isEmpty(webSocketConfig)) webSocketConfig = {};
        if (XSUtils.isNull(webSocketConfig!.keepAlive)) webSocketConfig!.keepAlive = true;
        if (XSUtils.isNull(webSocketConfig!.keepAliveDelay)) webSocketConfig!.keepAliveDelay = 5 * 1000;
        if (XSUtils.isNull(webSocketConfig!.reconnectAttemptDelay)) webSocketConfig!.reconnectAttemptDelay = 5 * 1000;
        if (XSUtils.isNull(webSocketConfig!.maxReconnectAttempts)) webSocketConfig!.maxReconnectAttempts = -1;

        return webSocketConfig!;
    }

    private onOpen(): void {
        this.connectionStatusChangeSubject.next(LCEWebSocketConnectionStatus.CONNECTED);
        this.webSocketClientService.send({type: LCEWebSocketMessageType.AUTHENTICATION_TOKEN, data: {token: this.token}});
        LOG().info('---| LCE WebSocket: Connection Opened :-). [sessionID: ' + this.webSocketClientService.getSessionID() + ']');
        this.beenConnected = true;
    }

    private onError(error: any): void {
        LOG().error('---| LCE WebSocket: something went wrong :-(.', error);
    }

    private onClosing(): void {
        LOG().info('---| LCE WebSocket: Closing Connection ...');
        this.webSocketClientService.send({messageType: LCEWebSocketMessageType.CLOSE, data: undefined});
    }

    private onClose(): void {
        LOG().info('---| LCE WebSocket: Connection Closed :-).');
        this.connectionStatusChangeSubject.next(LCEWebSocketConnectionStatus.CLOSED);
    }
}
