import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCEBirthDeclaration, LCEBirthDeclarationPartial, LCEBirthDeclarationSearch, LCECoreDomainUtils, LCEIdentityDocumentType, LCEUserPartial} from '@lce/core';
import {XSAddressBasic, XSAddressType, XSGender, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_BIRTH_DECLARATION_JSON from '../data/lce-birth-declaration.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCEMockUtils} from '../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';
import {LCEMockFacilityHandler} from './facility/lce-mock-facility-handler';
import {LCEMockFacilityWorkerHandler} from './lce-mock-facility-worker-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.birthDeclarations';

@Injectable()
export class LCEMockBirthDeclarationHandler extends XSMockPKResourceAuditFullHandler<LCEBirthDeclaration, LCEBirthDeclarationPartial, LCEBirthDeclarationSearch>
    implements XSMockPKSearchable<LCEBirthDeclarationPartial> {

    public static BIRTH_DECLARATION_STORAGE: Map<string, LCEBirthDeclaration> = new Map<string, LCEBirthDeclaration>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEBirthDeclaration>[] = [
        (birthDeclaration, query) => birthDeclaration.delivery.birthNumber?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.delivery.newBornName?.firstName?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.delivery.newBornName?.lastName?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.mother.name?.firstName?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.mother.name?.lastName?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.father?.name?.firstName?.toLowerCase().includes(query),
        (birthDeclaration, query) => birthDeclaration.father?.name?.lastName?.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEBirthDeclaration>[] = [
        (birthDeclaration, params) => this.httpParamArrayIncludes(params, 'codes', birthDeclaration.code),
        (birthDeclaration, params) => this.httpParamArrayIncludes(params, 'facilityCodes', birthDeclaration.facility.code),
        (birthDeclaration, params) => this.httpParamArrayIncludes(params, 'birthNumbers', birthDeclaration.delivery.birthNumber),
        (birthDeclaration, params) => this.httpParamArrayIncludes(params, 'newBornGenders', birthDeclaration.delivery.newBornGender)
    ];

    constructor(private mockFacilityHandler: LCEMockFacilityHandler, private mockFacilityWorkerHandler: LCEMockFacilityWorkerHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.birthDeclarations.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEBirthDeclaration> {
        return LCEMockBirthDeclarationHandler.BIRTH_DECLARATION_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return LCEMockUtils.randomUserMunicipalEmployee();
    }

    public toPartial(birthDeclaration: LCEBirthDeclaration): LCEBirthDeclarationPartial {
        return LCECoreDomainUtils.toBirthDeclarationPartial(birthDeclaration);
    }

    buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 100400,
                    active: 14,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEBirthDeclarationPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private createResponseData(body: any): LCEBirthDeclaration {
        const birthDeclarationCreate: LCEBirthDeclaration = body as LCEBirthDeclaration;

        const birthDeclaration: LCEBirthDeclaration = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-BID-' + XSUtils.randomDigits(3),
            facility: birthDeclarationCreate.facility,
            delivery: birthDeclarationCreate.delivery,
            mother: birthDeclarationCreate.mother,
            father: birthDeclarationCreate.father
        };

        LCEMockBirthDeclarationHandler.BIRTH_DECLARATION_STORAGE.set(birthDeclaration.id, birthDeclaration);
        this.addResourceBaseMockData(birthDeclaration.id);

        return birthDeclaration;
    }

    private buildStorage(): void {
        LCE_BIRTH_DECLARATION_JSON.forEach((jsonBirthDeclaration) => {
            const birthDeclaration: LCEBirthDeclaration = {
                ...jsonBirthDeclaration,
                delivery: {
                    ...jsonBirthDeclaration.delivery,
                    birthNumber: XSUtils.randomDigits(10),
                    declaredBy: this.mockFacilityWorkerHandler.getMidWifeWorker(),
                    bornOn: new Date().toISOString(),
                    receivedOn: new Date().toISOString(),
                    newBornGender: XSUtils.randomElement([XSGender.MALE, XSGender.FEMALE])
                },
                mother: {
                    ...jsonBirthDeclaration.mother,
                    identityType: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
                    address: this.basicAddress(),
                    birthDate: XSUtils.randomDate()
                },
                facility: this.mockFacilityHandler.getYopougonTownHall(),
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE
            };
            if (!XSUtils.isEmpty(jsonBirthDeclaration.father)) {
                birthDeclaration.father = {
                    ...jsonBirthDeclaration.father!,
                    identityType: LCEIdentityDocumentType.PASSPORT,
                    address: this.basicAddress(),
                    birthDate: XSUtils.randomDate()
                };
            }

            if (XSUtils.randomElement<boolean>([true, true, false])) {
                birthDeclaration.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                birthDeclaration.updatedOn = new Date().toISOString();
            }

            LCEMockBirthDeclarationHandler.BIRTH_DECLARATION_STORAGE.set(birthDeclaration.id, birthDeclaration);
            this.addResourceBaseMockData(birthDeclaration.id);
        });
    }

    private basicAddress(): XSAddressBasic {
        return {
            type: XSAddressType.BASIC,
            city: 'abidjan',
            countryISO: 'ci'
        };
    }
}
