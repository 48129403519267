import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {XSAssert, XSCount, XSInstantRange, XSPKResourceAuditFullService, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LCE_CORE_ENDPOINTS} from '../../../constants/lce-core-endpoint.constant';
import {
    LCECertificateOrder,
    LCECertificateOrderConfirmation,
    LCECertificateOrderCreate,
    LCECertificateOrderGlobalStats,
    LCECertificateOrderPartial,
    LCECertificateOrderPriceSettings,
    LCECertificateOrderSearch,
    LCECertificateOrderStatsChartLine,
    LCECertificateOrderStatsCount,
    LCECertificateOrderStatsPayment
} from '../../../domain/certificate/order/lce-certificate-order';
import {LCECertificateOrderLight} from '../../../domain/certificate/order/lce-certificate-order-light';
import {LCECertificateOrderStatus} from '../../../domain/certificate/order/lce-certificate-order-status';
import {LCEValidatorUtils} from '../../../utils/lce-validator-utils';
import {LCEHttpClientService} from '../../lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderService extends XSPKResourceAuditFullService<LCECertificateOrder, LCECertificateOrderPartial, LCECertificateOrderSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.certificateOrders.index, 'certificateOrder');
    }

    public updatePriceSettings(fieldValueData: any): Observable<LCECertificateOrderPriceSettings> {
        XSAssert.notEmpty(fieldValueData, 'fieldValueData');
        return this.httpClientService.patch<LCECertificateOrderPriceSettings>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.priceSettings), fieldValueData);
    }

    public retrieveSettings(): Observable<LCECertificateOrderPriceSettings> {
        return this.httpClientService.get<LCECertificateOrderPriceSettings>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.priceSettings));
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Payment ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public retrieveByTransactionID(transactionID: string): Observable<LCECertificateOrderConfirmation> {
        XSAssert.notEmpty(transactionID, 'transactionID');
        return this.httpClientService.get<LCECertificateOrderConfirmation>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.paymentsConfirmation).replace('{transactionID}', transactionID));
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Delivery Man ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public retrieveDeliveryManOrders(deliveryManID: string): Observable<LCECertificateOrderPartial[]> {
        XSAssert.notEmpty(deliveryManID, 'deliveryManID');
        return this.find({deliveryManIDs: [deliveryManID]});
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Customers ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public retrieveCustomerOrders(customerID: string): Observable<LCECertificateOrderPartial[]> {
        XSAssert.notEmpty(customerID, 'customerID');
        return this.find({customerIDs: [customerID], sort: ['createdOn|desc']});
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Charts ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public chartLine(
        predefinedPeriod: XSPredefinedPeriod | undefined,
        instantRange: XSInstantRange | undefined,
        municipalityCodes: string[] | undefined
    ): Observable<LCECertificateOrderStatsChartLine> {
        let params: HttpParams = new HttpParams();
        if (!XSUtils.isEmpty(predefinedPeriod)) {
            params = params.set('predefinedPeriod', predefinedPeriod!);
        }
        if (!XSUtils.isNull(instantRange)) {
            params = params.set('instantRange', instantRange!.toStr!());
        }
        if (!XSUtils.isEmpty(municipalityCodes)) {
            params = params.set('municipalityCodes', municipalityCodes!.join(','));
        }
        return this.httpClientService.get<LCECertificateOrderStatsChartLine>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.chartLine), params);
    }

    public chartDoughnut(
        predefinedPeriod: XSPredefinedPeriod | undefined,
        instantRange: XSInstantRange | undefined,
        municipalityCodes: string[] | undefined
    ): Observable<LCECertificateOrderStatsCount> {
        let params: HttpParams = new HttpParams();
        if (!XSUtils.isEmpty(predefinedPeriod)) {
            params = params.set('predefinedPeriod', predefinedPeriod!);
        }
        if (!XSUtils.isNull(instantRange)) {
            params = params.set('instantRange', instantRange!.toStr!());
        }
        if (!XSUtils.isEmpty(municipalityCodes)) {
            params = params.set('municipalityCodes', municipalityCodes!.join(','));
        }
        return this.httpClientService.get<LCECertificateOrderStatsCount>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.chartDoughnut), params);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Counts ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public countMunicipalityPendingOrders(municipalityCode: string): Observable<XSCount> {
        XSAssert.notEmpty(municipalityCode, 'municipalityCode');
        const orderSearch: LCECertificateOrderSearch = {
            municipalityCodes: [municipalityCode],
            statuses: [LCECertificateOrderStatus.PENDING]
        };
        return this.count(orderSearch);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Stats ===
    // ---------------------------------------------------------------------------------------------------------------------------------
    public statsFinances(orderSearch: LCECertificateOrderSearch): Observable<LCECertificateOrderStatsPayment> {
        XSAssert.notNull(orderSearch, 'orderSearch');
        return this.httpClientService.get<LCECertificateOrderStatsPayment>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.statsFinances), this.toHttpParameters(orderSearch));
    }

    public countStats(orderSearch: LCECertificateOrderSearch): Observable<LCECertificateOrderStatsCount> {
        XSAssert.notNull(orderSearch, 'orderSearch');
        return this.httpClientService.get<LCECertificateOrderStatsCount>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.countStats), this.toHttpParameters(orderSearch));
    }

    public globalStats(orderSearch: LCECertificateOrderSearch): Observable<LCECertificateOrderGlobalStats> {
        XSAssert.notNull(orderSearch, 'orderSearch');
        return this.httpClientService.get<LCECertificateOrderGlobalStats>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.globalStats), this.toHttpParameters(orderSearch));
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Find / Retrieve ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public retrieveMunicipalityLastOrder(municipalityCode: string): Observable<LCECertificateOrderLight> {
        return this.findMunicipalityNLastOrders(municipalityCode, 1).pipe(map((orders) => orders[0] || undefined));
    }

    public findMunicipalityNLastOrders(municipalityCode: string, nLast: number = 1): Observable<LCECertificateOrderLight[]> {
        XSAssert.notEmpty(municipalityCode, 'municipalityCode');
        const params: HttpParams = new HttpParams().set('municipalityCode', municipalityCode.trim()).set('nLast', nLast);
        return this.httpClientService.get<LCECertificateOrderLight[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.last), params);
    }

    public findOneByVerificationCode(verificationCode: string): Observable<LCECertificateOrder> {
        XSAssert.notEmpty(verificationCode, 'verificationCode');
        return this.httpClientService.get<LCECertificateOrder>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.verificationCodes, verificationCode.trim()));
    }

    public findOneByOrderNumber(orderNumber: string): Observable<LCECertificateOrder> {
        XSAssert.notEmpty(orderNumber, 'orderNumber');
        return this.httpClientService.get<LCECertificateOrder>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.orderNumbers, orderNumber.trim()));
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Create ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public createFromFrontOfficeUser(orderCreate: LCECertificateOrderCreate): Observable<LCECertificateOrder> {
        LCEValidatorUtils.validateOrderCreate(orderCreate);
        return this.httpClientService.post<LCECertificateOrder>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.createMFO), orderCreate);
    }

    public create(orderCreate: LCECertificateOrderCreate): Observable<LCECertificateOrder> {
        LCEValidatorUtils.validateOrderCreate(orderCreate);
        return this.httpClientService.post<LCECertificateOrder>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.createMFO), orderCreate);
    }
}
