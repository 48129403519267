import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';
import {LCEUser} from '@lce/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSInputFieldAddressOptions, XSInputFieldContactPersonOptions, XSInputFieldPhoneNumberOptions, XSInputFieldTextOptions} from '@xs/core';
import {XSAddressType, XSUtils} from '@xs/base';

@Component({selector: 'lce-user-account-contact-create-update', templateUrl: './lce-user-account-contact-create-update.component.html'})
export class LCEUserAccountContactCreateUpdateComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_CORE_LABEL: string = 'xs.core.label.';
    readonly TR_BASE_COMMON_LABEL: string = 'xs.common.label.';

    @Input() styleClass?: string;
    @Input() formContainerStyleClass?: string;

    @Input() user?: LCEUser;

    @Input() disabled: boolean;

    @Input() showSecondaryPhoneNumberField?: boolean;
    @Input() showWhatsAppPhoneNumberField?: boolean;

    @Input() userCountryISO: string;

    @Input() formGroup?: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();
    emailField: XSInputFieldTextOptions;
    primaryPhoneNumberField: XSInputFieldPhoneNumberOptions;
    secondaryPhoneNumberField: XSInputFieldPhoneNumberOptions;
    whatsAppPhoneNumberField: XSInputFieldPhoneNumberOptions;
    addressField: XSInputFieldAddressOptions;
    emergencyContactPersonField: XSInputFieldContactPersonOptions;

    ngOnInit(): void {
        this.buildFields();

        if (!XSUtils.isNull(this.user)) this.disabledFields();
    }

    public isFormEmpty(): boolean {
        return (
            XSUtils.isEmpty(this.primaryPhoneNumberField.control?.value) &&
            XSUtils.isEmpty(this.secondaryPhoneNumberField?.control?.value) &&
            XSUtils.isEmpty(this.whatsAppPhoneNumberField?.control?.value) &&
            XSUtils.isEmpty(this.emailField.control?.value) &&
            XSUtils.isEmpty(this.addressField.control?.value) &&
            XSUtils.isEmpty(this.emergencyContactPersonField.control?.value)
        );
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.user);
    }

    private disabledFields(): void {
        this.primaryPhoneNumberField.control!.disabled;
        this.emailField.control!.disabled;
    }

    private buildFields(): void {
        this.primaryPhoneNumberField = {
            fieldName: 'primaryPhoneNumber',
            label: this.TR_BASE_COMMON_LABEL + 'primaryPhoneNumber',
            control: new FormControl(this.user?.primaryPhoneNumber, Validators.required),
            countryISO: this.userCountryISO,
            countryDropdown: XSUtils.isEmpty(this.userCountryISO),
            countryDropdownFilter: XSUtils.isEmpty(this.userCountryISO),
            showCountryFlag: !XSUtils.isEmpty(this.userCountryISO)
        };
        this.formGroup!.addControl(this.primaryPhoneNumberField.fieldName, this.primaryPhoneNumberField.control!);

        if (this.showSecondaryPhoneNumberField !== false || !XSUtils.isEmpty(this.user?.secondaryPhoneNumber)) {
            this.secondaryPhoneNumberField = {
                fieldName: 'secondaryPhoneNumber',
                label: this.TR_BASE_COMMON_LABEL + 'secondaryPhoneNumber',
                control: new FormControl(this.user?.secondaryPhoneNumber),
                countryISO: this.userCountryISO,
                countryDropdown: XSUtils.isEmpty(this.userCountryISO),
                countryDropdownFilter: XSUtils.isEmpty(this.userCountryISO),
                showCountryFlag: !XSUtils.isEmpty(this.userCountryISO)
            };
            this.formGroup!.addControl(this.secondaryPhoneNumberField.fieldName, this.secondaryPhoneNumberField.control!);
        }
        if (this.showWhatsAppPhoneNumberField !== false || !XSUtils.isEmpty(this.user?.whatsAppPhoneNumber)) {
            this.whatsAppPhoneNumberField = {
                fieldName: 'whatsAppPhoneNumber',
                label: this.TR_BASE_COMMON_LABEL + 'whatsAppPhoneNumber',
                control: new FormControl(this.user?.whatsAppPhoneNumber),
                whatsApp: true
            };
            this.formGroup!.addControl(this.whatsAppPhoneNumberField.fieldName, this.whatsAppPhoneNumberField.control!);
        }
        this.emailField = {
            fieldName: 'email',
            label: this.TR_BASE_COMMON_LABEL + 'email',
            control: new FormControl(this.user?.email, Validators.required)
        };
        this.formGroup!.addControl(this.emailField.fieldName, this.emailField.control!);

        this.addressField = {
            fieldName: 'address',
            label: 'xs.core.label.address',
            control: new FormControl(this.user?.address, Validators.required),
            addressOptions: {
                dataOptions: {
                    capitalize: true
                },
                formOptions: {
                    type: this.user?.address?.type ? this.user.address.type : XSAddressType.UNSTRUCTURED,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED, XSAddressType.STRUCTURED],
                    title: true,
                    showResetButton: !this.isUpdateMode()
                }
            }
        };
        this.formGroup!.addControl(this.addressField.fieldName, this.addressField.control!);

        this.emergencyContactPersonField = {
            fieldName: 'emergencyContactPerson',
            label: this.TR_BASE_CORE_LABEL + 'emergencyContact',
            control: new FormControl(this.user?.emergencyContactPerson, Validators.required),
            contactPersonOptions: {
                formOptions: {
                    showDescriptionField: true,
                    showSecondaryPhoneNumberField: false,
                    showEmailField: true,
                    showNoteField: true,
                    primaryPhoneNumber: {
                        countryISO: this.userCountryISO,
                        showCountryFlag: true
                    },
                    showResetButton: !this.isUpdateMode()
                }
            }
        };
        this.formGroup!.addControl(this.emergencyContactPersonField.fieldName, this.emergencyContactPersonField.control!);

    }
}
