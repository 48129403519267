import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {XS_LOREM_IPSUM, XSAssert, XSLanguage, XSResourceAuditFullCanOptions, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSAboutComponent, XSDialogConfig, XSDialogService, XSInputFieldSwitchOptions, XSScheme, XSSchemeService} from '@xs/core';
import {OverlayPanel} from 'primeng/overlaypanel';
import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {LCEContextMenuOptions} from './lce-context-menu';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedContextService} from '../../api/services/lce-shared-context.service';
import {LCESharedAuthorizationService} from '../../api/services/lce-shared-authorization.service';
import {LCESoundAlertService} from '../../api/services/lce-sound-alert.service';
import {LCEAuthorizationTesterComponent} from '../../authorization/tester/lce-authorization-tester.component';

@Component({selector: 'lce-context-menu', templateUrl: './lce-context-menu.component.html'})
export class LCEContextMenuComponent implements OnInit, OnDestroy {

    readonly ICON_MENU: string = LCE_SHARED_ICON.menu;
    readonly ICON_PARAMETERS: string = LCE_SHARED_ICON.parameters;

    readonly TR_BASE: string = 'lce.shared.template.contextMenu.';

    @Input() styleClass?: string;

    @Input() appSecondaryName: string;

    @Input() options?: LCEContextMenuOptions;

    @ViewChild('overlayMenu') overlayMenu: OverlayPanel;

    darkModeFieldOptions: XSInputFieldSwitchOptions;
    languageFieldOptions: XSInputFieldSwitchOptions;
    soundAlertFieldOptions: XSInputFieldSwitchOptions;
    globalCacheFieldOptions: XSInputFieldSwitchOptions;

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private translationService: XSTranslationService,
        private schemeService: XSSchemeService,
        private dialogService: XSDialogService,
        private contextService: LCESharedContextService,
        private authorizationService: LCESharedAuthorizationService,
        private soundAlertService: LCESoundAlertService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.appSecondaryName, 'appSecondaryName');
        this.initOptions();
        this.buildFieldOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onClearCache(): void {
        // TODO
    }

    public onClearStorage(): void {
        // TODO
    }

    public handleEventsClick(): void {
        this.router.navigateByUrl('events').then();
        this.overlayMenu.hide();
    }

    public handleGlobalSearchClick(): void {
        this.router.navigateByUrl('global-search').then();
        this.overlayMenu.hide();
    }

    public onEnableDisableEnglish(state: boolean): void {
        const language: XSLanguage = state ? XSLanguage.ENGLISH : XSLanguage.FRENCH;
        this.translationService.changeCurrentLanguage(language).subscribe();
    }

    public onEnableDisableDarkMode(state: boolean): void {
        if (state) this.schemeService.changeScheme(XSScheme.DARK);
        else this.schemeService.changeScheme(XSScheme.LIGHT);
    }

    public onEnableDisableSoundAlertGlobally(state: boolean): void {
        this.soundAlertService.enableSoundAlertGlobally(state);
    }

    public onEnableDisableGlobalCache(state: boolean): void {
        // TODO
        console.log('onEnableDisableGlobalCache state: ' + state);
    }

    public handleShowcaseClick(): void {
        this.router.navigateByUrl('showcase').then();
        this.overlayMenu.hide();
    }

    public handleSTNBrandClick(): void {
        window.open('/#/stn', '_blank');
        this.overlayMenu.hide();
    }

    public handleDDBBrandClick(): void {
        window.open('/#/ddb', '_blank');
        this.overlayMenu.hide();
    }

    public handleAuthorizationTesterClick(): void {
        const permission: XSResourceAuditFullCanOptions = {
            audit: {
                deactivate: true,
                activate: true,
                delete: true,
                restore: true,
                purge: true
            }
        };
        this.dialogService.openComponentDialog(LCEAuthorizationTesterComponent, {
            data: {
                permission: permission,
                dialogStyleClass: 'xs-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            contentStyleClass: 'xs-flex-row xs-justify-content-center xs-max-width-1000'
        });
    }

    public canViewShowcase(): boolean {
        return this.authorizationService.canViewShowcase() && this.options!.can!.view!.showcase === true;
    }

    public canViewAuthorizationTester(): boolean {
        return this.authorizationService.canViewTestingTools() && this.options!.can!.view!.authorizationTester === true;
    }

    public handleAboutClick(): void {
        const dConfig: XSDialogConfig = {
            data: {
                colLeft: 4,
                colRight: 8,
                license: XS_LOREM_IPSUM.veryLong,
                engineAPIURL: this.contextService.getAppConfig().engineAPIBaseUrl,
                logoImage: 'assets/images/lce-logo-black-bottom-text.png',
                logoImageStyleClass: 'xs-width-percent-60-imp',
                appSecondaryName: this.appSecondaryName
            },
            footer: true,
            footerOkButton: true,
            dialogStyleClass: 'xs-width-full-imp xs-max-width-800',
            contentStyleClass: 'xs-pt-50-imp xs-min-height-400-imp'
        };
        this.dialogService.openComponentDialog(XSAboutComponent, dConfig);
        this.overlayMenu.hide();
    }

    public handleHelpClick(): void {
        console.log('Help ...');
        this.overlayMenu.hide();
    }

    public handleReportAnIssueClick(): void {
        console.log('Report An Issue ...');
        this.overlayMenu.hide();
    }

    private buildFieldOptions(): void {
        this.languageFieldOptions = {
            fieldName: 'english',
            label: this.TR_BASE + 'english',
            labelContainerStyleClass: 'xs-pl-0-imp',
            labelStyleClass: 'xs-font-weight-500 xs-color-secondary',
            subLabel: this.TR_BASE + 'englishDescription',
            subLabelStyleClass: 'xs-color-secondary',
            align: 'center',
            ellipsis: true,
            changeEvent: event => this.onEnableDisableEnglish(event),
            control: new FormControl(false)
        };
        this.darkModeFieldOptions = {
            fieldName: 'darkMode',
            label: this.TR_BASE + 'darkMode',
            labelContainerStyleClass: 'xs-pl-0-imp',
            labelStyleClass: 'xs-font-weight-500 xs-color-secondary',
            subLabel: this.TR_BASE + 'darkModeDescription',
            subLabelStyleClass: 'xs-color-secondary',
            align: 'center',
            ellipsis: true,
            changeEvent: event => this.onEnableDisableDarkMode(event),
            control: new FormControl(false)
        };
        this.soundAlertFieldOptions = {
            fieldName: 'soundAlert',
            label: this.TR_BASE + 'soundAlert',
            labelContainerStyleClass: 'xs-pl-0-imp',
            labelStyleClass: 'xs-font-weight-500 xs-color-secondary',
            subLabel: this.TR_BASE + 'soundAlertDescription',
            subLabelStyleClass: 'xs-color-secondary',
            align: 'center',
            ellipsis: true,
            disabled: true,
            changeEvent: event => this.onEnableDisableSoundAlertGlobally(event),
            control: new FormControl(false)
        };
        this.globalCacheFieldOptions = {
            fieldName: 'globalCache',
            label: this.TR_BASE + 'globalCache',
            labelContainerStyleClass: 'xs-pl-0-imp',
            labelStyleClass: 'xs-font-weight-500 xs-color-secondary',
            subLabel: this.TR_BASE + 'globalCacheDescription',
            subLabelStyleClass: 'xs-color-secondary',
            align: 'center',
            ellipsis: true,
            disabled: true,
            changeEvent: event => this.onEnableDisableGlobalCache(event),
            control: new FormControl(false)
        };
    }

    private initOptions(): void {
        if (XSUtils.isNull(this.options)) this.options = {};
        if (XSUtils.isNull(this.options!.can)) this.options!.can = {view: {}};
        if (XSUtils.isNull(this.options!.can!.view)) this.options!.can!.view = {};

        if (XSUtils.isNull(this.options!.can!.view!.stnButton)) this.options!.can!.view!.stnButton = true;
        if (XSUtils.isNull(this.options!.can!.view!.ddbButton)) this.options!.can!.view!.ddbButton = true;

        if (XSUtils.isNull(this.options!.can!.view!.showcase)) this.options!.can!.view!.showcase = true;
        if (XSUtils.isNull(this.options!.can!.view!.authorizationTester)) this.options!.can!.view!.authorizationTester = true;

        if (XSUtils.isNull(this.options!.can!.view!.soundAlert)) this.options!.can!.view!.soundAlert = true;

        if (XSUtils.isNull(this.options!.can!.view!.globalCache)) this.options!.can!.view!.globalCache = true;
        if (XSUtils.isNull(this.options!.can!.view!.clearCache)) this.options!.can!.view!.clearCache = true;

        if (XSUtils.isNull(this.options!.can!.view!.clearStorage)) this.options!.can!.view!.clearStorage = true;
    }
}
