import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {XSAssert, XSLanguage} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_DATE_EN, XS_DATE_FORMAT_MEDIUM_DATE_FR, XSTranslationService} from '@xs/common';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCESmartCityArticleCard} from '@lce/core';

@Component({selector: 'lce-smartcity-article-card', templateUrl: 'lce-smartcity-article-card.component.html', styles: [':host { width: 100%; }']})
export class LCESmartCityArticleCardComponent implements OnInit {

    readonly TR_BASE_SMART_CITY_ARTICLE_TYPE: string = LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE;

    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() data: LCESmartCityArticleCard;

    constructor(private router: Router,
                private translationService: XSTranslationService) {
    }

    get dateFormat(): string {
        const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        return currentLanguage === XSLanguage.FRENCH ? XS_DATE_FORMAT_MEDIUM_DATE_FR : XS_DATE_FORMAT_MEDIUM_DATE_EN;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public getBackgroundImage(): string {
        return `linear-gradient(180deg, transparent 10%, black 80%), url("${this.data.coverImage}")`;
    }

    public isClickable(): boolean {
        return this.disabled !== true;
    }

    public onCardClick(event: Event): void {
        if (this.disabled === true) {
            event.stopImmediatePropagation();
            return;
        }
        this.router.navigate(['/smartcity/articles', this.data.id],
            {
                state: {
                    options: {
                        showAudit: false,
                        showQRcode: false
                    }

                }
            })
            .then();
    }

}
