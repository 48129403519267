import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEFacilityTownHallStampToken, LCEFacilityTownHallStampTokenPartial} from '@lce/core';
import {XSAssert, XSPKDTOAuditFull, XSPKResourceAuditFullService, XSResourceAuditCanOptions, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSConfirmation, XSPKResourceAuditFullActionStr, XSPKResourceAuditFullMenuAction} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-facility-town-hall-stamp-token-actions', templateUrl: './lce-facility-town-hall-stamp-token-actions.component.html'})
export class LCEFacilityTownHallStampTokenActionsComponent extends XSPKResourceAuditFullMenuAction<LCEFacilityTownHallStampToken> implements OnInit {

    readonly ICON = LCE_SHARED_ICON;
    readonly LOADER_ID_DELETE = XSUtils.uuid();
    readonly TR_ERROR_MESSAGE: string = 'xs.core.pkResourceAuditFullErrorMessage.';

    @Input() styleClass?: string;

    @Input() canAudit?: XSResourceAuditCanOptions;

    @Input() resourceService: XSPKResourceAuditFullService<LCEFacilityTownHallStampToken, any, any>;

    @Input() showDeleteConfirmation?: boolean;

    @Input() data: LCEFacilityTownHallStampToken;

    @Output() dataChange = new EventEmitter<LCEFacilityTownHallStampToken | LCEFacilityTownHallStampTokenPartial>();
    @Output() deleteEvent = new EventEmitter<LCEFacilityTownHallStampToken | LCEFacilityTownHallStampTokenPartial>();

    @ViewChild('actionMenu') actionMenu: TieredMenu;

    error: any;
    errorMessage: string | undefined;
    deleteLoading: boolean = false;
    deleteConfirmation: XSConfirmation = {
        trMessage: 'lce.shared.facility.townHallStampToken.label.deleteConfirmationMessage',
        accept: () => this.delete()
    };

    constructor(protected translationService: XSTranslationService) {
        super(translationService);
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.resourceService, 'resourceService');
    }

    public onMenuClick(event: any): void {
        this.buildAuditMenuItems();
        this.actionMenu.toggle(event);
    }

    public delete(): void {
        this.deleteLoading = true;
        this.resourceService
            .setAsDeleted(this.data.id)
            .pipe(finalize(() => this.deleteLoading = false))
            .subscribe({
                next: (response: XSPKDTOAuditFull<any>) => {
                    this.data.updatedOn = response.updatedOn;
                    this.data.updatedBy = response.updatedBy;

                    this.data.deleted = response.deleted;
                    this.data.deletedOn = response.deletedOn;
                    this.data.deletedBy = response.deletedBy;
                    this.deleteEvent.emit(this.data);

                },
                error: error => {
                    this.error = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'delete';
                }
            });
    }

    protected doAfterAuditAction(action: XSPKResourceAuditFullActionStr, response?: XSPKDTOAuditFull<any>): void {

        this.data.id = response!.id;

        this.data.createdOn = response!.createdOn;
        this.data.createdBy = response!.createdBy;

        this.data.updatedOn = response!.updatedOn;
        this.data.updatedBy = response!.updatedBy;

        this.data.deleted = response!.deleted;
        this.data.deletedOn = response!.deletedOn;
        this.data.deletedBy = response!.deletedBy;

        this.data.active = response!.active;
        this.data.activeUpdatedOn = response!.activeUpdatedOn;
        this.data.activeUpdatedBy = response!.activeUpdatedBy;

        this.data.restoredOn = response!.restoredOn;
        this.data.restoredBy = response!.restoredBy;

        this.dataChange.emit(this.data);
    }

}
