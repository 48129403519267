import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_CERTIFICATE_ORDER_STATUS, LCECertificateOrderService, LCECertificateOrderStatus} from '@lce/core';
import {XSAssert} from '@xs/base';
import {XSLoaderType} from '@xs/common';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';

@Component({
    selector: 'lce-certificate-orders-pending-orders-square',
    template: `
        <xs-square-data
                [styleClass]="styleClass"
                valueStyleClass="xs-font-size-default-imp"
                [showBorder]="true"
                [value]="nPendingOrders | xsToString"
                [bottomText]="bottomText"
                bottomTextStyleClass="xs-color-secondary xs-mt-6-imp"
                [clickable]="hasObserver()"
                [error]="error"
                (clickEvent)="clickEvent.emit(nPendingOrders)">
        </xs-square-data>
    `
})
export class LCECertificateOrdersPendingOrdersSquareComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = 'pendingOrdersSquare';
    readonly LOADER_TYPE = XSLoaderType.THREE_BOUNCE;
    readonly LOADER_SIZE?: number = 15;

    bottomText = LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PENDING;

    @Input() styleClass?: string;

    @Input() municipalityCode: string;

    @Output() clickEvent = new EventEmitter<number>();

    nPendingOrders: number;

    error: any;

    private subscription: Subscription = new Subscription();

    constructor(private certificateOrderService: LCECertificateOrderService, private eventProcessorService: LCEEventProcessorService) {
        this.subscription.add(this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.update()));
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
        this.update();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasObserver(): boolean {
        return this.clickEvent.observers.length > 0;
    }

    public update(): void {
        this.nPendingOrders = undefined!;
        this.subscription.add(
            this.certificateOrderService.countMunicipalityPendingOrders(this.municipalityCode).subscribe({
                next: (count) => this.nPendingOrders = count.total,
                error: (error) => this.error = error
            })
        );
    }
}
