import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {LCE_TR_BASE_CERTIFICATE_ORDER_STATUS} from '../constants/lce-core.constant';
import {Subscription} from 'rxjs';
import {LCECertificateOrderStatus} from '../domain/certificate/order/lce-certificate-order-status';

// Reference : https://github.com/angular/angular/issues/15041
@Pipe({name: 'lceCertificateOrderStatus', pure: false})
export class LCECertificateOrderStatusPipe implements PipeTransform, OnDestroy {

    private markForTransform = true;

    private value: string;
    private statusKey?: string;

    private readonly change: Subscription;

    constructor(private translationService: XSTranslationService) {
        this.change = this.translationService.onLanguageChanged.subscribe(() => {
            this.markForTransform = true;
        });
    }

    ngOnDestroy() {
        this.change.unsubscribe();
    }

    public transform(status: string | LCECertificateOrderStatus | undefined): string {
        if (!this.markForTransform && this.statusKey === status) {
            return this.value;
        }
        this.value = this.translate(status);
        this.markForTransform = false;
        this.statusKey = status?.toString();
        return this.value;
    }

    private translate(status: string | LCECertificateOrderStatus | undefined): string {
        if (XSUtils.isEmpty(status)) return XS_STR_EMPTY;
        const trKey = LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + status;
        return this.translationService.translateKey(trKey);
    }
}
