<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !loader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div class="xs-card">
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div *ngIf="!isDialog()" class="xs-card-header">
                <xs-header-stripe
                        [avatar]="headerAvatar"
                        [subtitle]="stampToken!.facility.fullName"
                        [title]="TR_BASE + 'stampToken'"
                        titleStyleClass="xs-font-size-default">
                </xs-header-stripe>
            </div>

            <div class="xs-width-full xs-flex-column xs-align-items-center">
                <lce-facility-town-hall-stamp-token-qrcode
                        *ngIf="stampToken!?.qrCode | xsIsNotEmpty"
                        [code]="stampToken!.code"
                        [qrCode]="stampToken!.qrCode"
                        [showCode]="true"
                        [showTimeAgoShortMode]="false"
                        [showTimeAgo]="true"
                        [timeAgoValue]="stampToken!.createdOn"
                        styleClass="xs-mb-30"
                        timeAgoPrefix="lce.shared.facility.townHallStampToken.label.generated">
                </lce-facility-town-hall-stamp-token-qrcode>
            </div>

            <xs-title [text]="TR_BASE + 'data'" textStyleClass="xs-font-size-default"></xs-title>

            <div class="xs-flex-row xs-justify-content-space-between xs-align-items-center xs-mt-30 xs-mb-50">
                <lce-user-resource-audit [data]="stampToken!"></lce-user-resource-audit>
                <lce-facility-town-hall-stamp-token-actions
                        (dataChange)="onDataChange($event)"
                        (deleteEvent)="onDataChange($event)"
                        [data]="stampToken!"
                        [resourceService]="facilityStampTokenService"
                        [showDeleteConfirmation]="true">
                </lce-facility-town-hall-stamp-token-actions>
            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <div class="col-12 md:col-6">
                    <xs-data-field-identifier
                            [copyable]="true"
                            [ellipsis]="true"
                            [required]="true"
                            [testColored]="true"
                            [value]="stampToken!.code"
                            label="xs.core.label.code"
                            type="code"
                            valueStyleClass="xs-font-size-intermediate">
                    </xs-data-field-identifier>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-resource-state [value]="stampToken!" styleClass="xs-mt-15"></xs-data-field-resource-state>
                </div>
                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <div class="xs-data-field-value-container">
                            <lce-facility-town-hall-stamp-number
                                    [numberOfStampsRemaining]="stampToken!.numberOfStampsLeft"
                                    [numberOfStamps]="stampToken!.numberOfStamps">
                            </lce-facility-town-hall-stamp-number>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <div class="xs-data-field-value-container">
                            <lce-facility-town-hall-stamp-token-status
                                    [data]="stampToken!.status"
                                    [showBackground]="true"
                                    [styleClass]=" !stampToken!.consumptions ? 'xs-mt-15' : ''">
                            </lce-facility-town-hall-stamp-token-status>
                            <lce-user-resource-audit-inline
                                    *ngIf="stampToken!.consumptions"
                                    [by]="stampToken!.consumedBy"
                                    [on]="stampToken!.consumedOn"
                                    [oneLine]="true"
                                    styleClass="xs-mt-2">
                            </lce-user-resource-audit-inline>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number
                            [label]="TR_BASE + 'mobilePhoneNumber'"
                            [required]="false"
                            [subLabel]="TR_BASE + 'usedForStampGeneration'"
                            [value]="stampToken!.mobilePhoneNumber">
                    </xs-data-field-phone-number>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-calendar
                            [calendarOptions]="calendarOptions"
                            [label]="TR_BASE + 'validUntil'"
                            [required]="false"
                            [value]="stampToken!.validUntil"
                            valueStyleClass="xs-font-weight-500 xs-font-size-intermediate xs-white-space-nowrap">
                    </xs-data-field-calendar>
                </div>

                <div *ngIf="stampToken!.note" class="col-12">
                    <xs-data-field-textarea
                            [value]="stampToken!.note!"
                            label="xs.common.label.note"
                            valueStyleClass="xs-font-size-small">
                    </xs-data-field-textarea>
                </div>

                <!-- ------------------------------------------------------------------------------------------------ -->
                <!-- === Consumptions === -->
                <!-- ------------------------------------------------------------------------------------------------ -->
                <div *ngIf="stampToken!.consumptions | xsIsNotEmpty" class="col-12">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="consumptionContainer"
                            [expanded]="true"
                            [text]="TR_BASE + 'consumption'"
                            textStyleClass="xs-font-size-default">
                    </xs-title>
                    <div #consumptionContainer class="xs-max-width-500 xs-pt-15">
                        <ul class="xs-listing xs-overflow-y-auto xs-scrollbar-hide">
                            <li *ngFor="let consumption of stampToken!.consumptions" class="xs-p-0-imp">
                                <lce-facility-town-hall-stamp-token-consumption [data]="consumption" [showBorder]="true"></lce-facility-town-hall-stamp-token-consumption>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>

        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="stampToken!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.stamp"
            [line1]="TR_BASE + 'stampToken'"
            [line2]="headerSubtitle"
            styleClass="xs-dialog-header xs-font-size-medium">
    </lce-record-header-component>
</ng-template>
