import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
	selector: 'lce-certificate-order-number-of-copies',
	template: `
        <xs-square-data
                [styleClass]="styleClass"
                [showBorder]="showBorder"
                [value]="value | xsToString"
                [rightText]="textPosition === 'right' ? getText() : undefined"
                [bottomText]="textPosition === 'bottom' ? getText() : undefined"
                valueStyleClass="{{ large === true ? 'xs-font-size-large-imp' : '' }}"
                rightTextStyleClass="xs-color-secondary"
                bottomTextStyleClass="xs-color-secondary">
        </xs-square-data>
	`
})
export class LCECertificateOrderNumberOfCopiesComponent implements OnInit {

	readonly N_COPIES_TEXT = 'lce.core.label.numberOfCopiesShort';

	@Input() styleClass?: string;

	@Input() showBorder?: boolean;
	@Input() showText?: boolean;
	@Input() large?: boolean;

	@Input() value: number;

	@Input() textPosition?: 'right' | 'bottom';

	ngOnInit(): void {
		XSAssert.notNull(this.value, 'value');
		if (XSUtils.isEmpty(this.textPosition)) this.textPosition = 'right';
		if (XSUtils.isNull(this.showBorder)) this.showBorder = false;
		if (XSUtils.isNull(this.showText)) this.showText = false;
	}

	public getText(): string | undefined {
		if (this.showText !== true) return undefined;
		return this.N_COPIES_TEXT;
	}
}
