import {Injectable, NgZone} from '@angular/core';
import {XSErrorHandler} from '@xs/core';
import {LCECMDErrorService} from '../services/lce-cmd-error.service';

@Injectable({providedIn: 'root'})
export class LCECMDErrorHandler extends XSErrorHandler {
    constructor(protected ngZone: NgZone, protected errorService: LCECMDErrorService) {
        super(ngZone, errorService);
    }
}
