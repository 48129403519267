import {Injectable} from '@angular/core';
import {LCEUser, LCEUserPartial, LCEUserSearch, LCEUserType} from '@lce/core';
import {LCEMockUserHandler} from '@lce/mock/core/handlers/user/lce-mock-user-handler';
import {XSUtils} from '@xs/base';
import {XSMockHttpHandler} from '@xs/mock';

@Injectable()
export class LCEMockUserFinder extends XSMockHttpHandler {

    public static USER_HANDLER_MAP: Map<LCEUserType, LCEMockUserHandler<any, LCEUserPartial, LCEUserSearch>> = new Map<LCEUserType, LCEMockUserHandler<any, LCEUserPartial, LCEUserSearch>>();

    constructor() {
        super();
    }

    buildMockDataArray(): void {
        this.mockDataArray = [];
    }

    public findOneByUsername(username: string): LCEUser | undefined {
        const handlers = Array.from(LCEMockUserFinder.USER_HANDLER_MAP.values());
        let foundUser: LCEUser | undefined = undefined;
        handlers.forEach(mockUserHandler => {
            foundUser = mockUserHandler.findOneByField('primaryPhoneNumber', username);
            if (!XSUtils.isNull(foundUser)) {
                return;
            }
            foundUser = mockUserHandler.findOneByField('email', username);
            if (!XSUtils.isNull(foundUser)) {
                return;
            }
        });
        return foundUser;
    }

    public findOneByID(userID: string): LCEUser {
        let foundUser: LCEUser = undefined!;
        const userTypes: LCEUserType[] = XSUtils.getEnums(LCEUserType);
        for (const userType of userTypes) {
            console.log(userType);
            foundUser = LCEMockUserFinder.USER_HANDLER_MAP.get(userType)?.findOneByID(userID);
            if (!XSUtils.isNull(foundUser)) break;
        }

        if (XSUtils.isNull(foundUser)) {
            console.error('[MOCK] Unable to find the given user [userID: ' + userID + '].');
        }
        return foundUser;
    }
}