<div class="xs-flex-column  xs-position-relative xs-width-full {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="retrieveErrorRetryButton"
                [error]="retrieveError"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="lce.shared.birthDeclaration.label.errorMessageRetrieve"
                mode="block"
                styleClass="xs-max-width-500"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()" [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card">
        <div class="xs-card-content xs-mb-0-imp">
            <div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="grid xs-fields xs-fields-spacer-15">
                <div class="col-6">
                    <xs-input-field-text [options]="firstNameField"></xs-input-field-text>
                </div>
                <div class="col-6">
                    <xs-input-field-text [options]="lastNameField"></xs-input-field-text>
                </div>
                <div class="col-6">
                    <xs-input-field-calendar [options]="birthDateField"></xs-input-field-calendar>
                </div>
                <div class="col-6">
                    <xs-input-field-text [options]="birthLocationField"></xs-input-field-text>
                </div>
                <div class="col-6">
                    <xs-input-field-text [options]="occupationField"></xs-input-field-text>
                </div>
                <div class="col-6">
                    <xs-input-field-address [options]="addressField"></xs-input-field-address>
                </div>
                <div class="col-6">
                    <xs-input-field-dropdown-country [options]="nationalityField"></xs-input-field-dropdown-country>
                </div>
                <div class="col-6">
                    <xs-input-field-phone-number [options]="phoneNumberField"></xs-input-field-phone-number>
                </div>

                <div class="col-12">
                    <div class="xs-field xs-field-input">
                        <xs-input-field-label [options]="{ fieldName: 'identityNumber', label: TR_BASE + 'identityNumber' }" styleClass="xs-mb-10-imp"></xs-input-field-label>
                        <xs-input-radios
                                (clickEvent)="onIdentityTypeClicked()"
                                [(value)]="selectedIdentityType"
                                [radios]="DOCUMENT_IDENTITY_TYPE_RADIOS"
                                colStyleClass="md:col-4"
                                inputId="identityType"
                                inputName="identityType"
                                styleClass="xs-font-size-intermediate-imp xs-mb-5">
                        </xs-input-radios>
                        <xs-input-field-text [options]="identityNumberField"></xs-input-field-text>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }" class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-error
                *ngIf="hasCreateUpdateError()"
                [ellipsis]="true"
                [error]="createUpdateError"
                [showErrorDetailButton]="true"
                errorDetailButtonClass="xs-mt-2-imp"
                message="{{ TR_BASE }}errorMessageCreateUpdate"
                mode="inline">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form" size="intermediate" type="text"></xs-button>
            <xs-button
                    (clickEvent)="reset()"
                    *ngIf="isCreateMode()"
                    [confirmation]="resetConfirmation"
                    [disabled]="createUpdateLoading"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="createUpdateLoading || centralLoader.isRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.close"
                    severity="secondary"
                    styleClass="xs-mlr-15-imp"
                    type="button">
            </xs-button>
            <xs-button (clickEvent)="createUpdate()" [disabled]="centralLoader.isRunning()" [loading]="createUpdateLoading" label="xs.core.label.save" type="button"></xs-button>
        </div>
    </div>
</ng-template>

<ng-template #dHeader>
    <xs-ivar
            [ellipsis]="true"
            [line1]=" TR_BASE + 'title.' + type"
            line2="{{
            'lce.shared.birthDeclaration.label.birth' | translate }} {{ birthDeclarationNumber }}"
            styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>
