<div class="xs-flex-column xs-p-15">
    <div class="xs-flex-row">
        <div class="xs-flex-center xs-mt-5 xs-mr-50">
            <xs-input-switch (changeEvent)="onStatisticsVisibleChange($event)" [value]="getStatisticsVisible()"></xs-input-switch>
        </div>
        <xs-ivar
                [line1]="TR_BASE + 'statisticsSectionVisibleLabel'"
                [line2]="TR_BASE + 'statisticsSectionVisibleSubLabel'"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
    <div class="xs-flex-row xs-mt-20">
        <div class="xs-flex-center xs-mt-5 xs-mr-50">
            <xs-input-switch (changeEvent)="onSoundAlertEnabledChange($event)" [value]="soundAlertEnabled"></xs-input-switch>
        </div>
        <xs-ivar
                line1="lce.shared.label.enableSoundAlertLabel"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2="lce.shared.label.enableSoundAlertSubLabel"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
    <div class="xs-flex-row xs-mt-20">
        <div class="xs-flex-center xs-mt-5 xs-mr-50">
            <xs-input-switch (changeEvent)="onBatchProcessCodeVisibleChange($event)" [value]="getBatchProcessCodeVisible()"></xs-input-switch>
        </div>
        <xs-ivar
                [line1]="TR_BASE + 'batchProcessCodeVisibleLabel'"
                [line2]="TR_BASE + 'batchProcessCodeVisibleSubLabel'"
                line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
                line2StyleClass="xs-font-size-small-imp">
        </xs-ivar>
    </div>
</div>
