<div class="xs-card xs-min-width-400 xs-max-width-500 {{ styleClass }}">
    <div class="xs-card-content xs-flex-column xs-font-size-default xs-pb-0-imp">
        <lce-user-detail-head [data]="data"></lce-user-detail-head>

        <div class="xs-flex-column xs-width-full xs-mt-20 xs-pt-20 xs-border-top-discrete">
            <span class="xs-data-section-title xs-mb-15">{{ 'xs.core.label.contact' | translate }}</span>
            <xs-data-email
                    *ngIf="data.email | xsIsNotEmpty" [value]="data.email!"
                    styleClass="xs-color-secondary"
                    valueStyleClass="xs-font-size-intermediate">
            </xs-data-email>

            <xs-data-phone-number
                    [value]="data.primaryPhoneNumber"
                    styleClass="xs-color-secondary xs-mt-10"
                    valueStyleClass="xs-font-size-intermediate">
            </xs-data-phone-number>
        </div>

        <div *ngIf="showViewRecordButton" class="xs-flex-row xs-width-full xs-justify-content-end xs-mt-30">
            <xs-button
                    (clickEvent)="onViewRecordBtnClick()"
                    label="lce.shared.user.label.viewDetail"
                    styleClass="xs-button-small"
                    type="text">
            </xs-button>
        </div>
    </div>
</div>
