<div class="xs-position-relative xs-width-full {{ styleClass }}">
    <xs-input-search
            (changeEvent)="search()"
            (focusEvent)="resultMapOverlay.show($event)"
            [(value)]="searchText"
            [delay]="options!.searchTextDelay"
            [maxLength]="options!.searchTextMaxLength"
            [minNChars]="options!.searchTextMinNChars"
            [placeholder]="options!.searchPlaceholder"
            [searching]="searching"
            iconStyleClass="xs-left-0-imp"
            inputId="searchText"
            inputStyleClass="xs-input-just-border-bottom">
    </xs-input-search>

    <p-overlayPanel #resultMapOverlay [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding xs-mt-16-imp xs-border-radius-8-imp">
        <lce-global-search-input-result
                (selectEvent)="resultMapOverlay.hide()"
                *ngIf="(resultsMap | xsIsNotNull) && hasRequiredNCharsForSearchText()"
                [resultsMap]="resultsMap"
                [searchText]="searchText"
                styleClass="xs-min-height-100 xs-width-500-imp xs-border-radius-8-imp xs-border-light-imp">
        </lce-global-search-input-result>
    </p-overlayPanel>
</div>
