<div class="lce-template-default">
    <!-- Content -->
    <div class="lce-template-default-content">
        <div class="lce-template-default-content-absolute">
            <router-outlet></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <div class="lce-template-default-footer">
        <lce-template-footer></lce-template-footer>
    </div>
</div>
