<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card">
        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
					<span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
						{{ 'lce.shared.user.label.general' | translate }}
					</span>
                </div>
                <div class="xs-flex-row">
                    <lce-municipal-employee-actions
                        #actions
                        (dataChange)="dataChange.emit($event)"
                        (editEvent)="editEvent.emit($event)"
                        (purgeEvent)="purgeEvent.emit($event)"
                        [(data)]="data">
                    </lce-municipal-employee-actions>
                </div>
            </div>
        </div>

        <div [ngClass]="{ 'xs-disabled': actions.isLoading() }" class="xs-card-content">

            <div class="xs-flex-row xs-align-items-start xs-justify-content-center xs-height-40">
                <xs-error *ngIf="actions.error && !actions.isLoading()"
                          [error]="actions.error"
                          [message]="actions.errorMessage!"
                          [showErrorDetailButton]="true"
                          mode="inline">
                </xs-error>
            </div>

            <div class="grid xs-fields xs-fields-spacer-30">
                <div class="col-12 md:col-8">
                    <lce-user-resource-audit [data]="$any(data)" styleClass="xs-mb-40"></lce-user-resource-audit>
                </div>
                <div class="col-12 md:col-4 xs-flex-row xs-align-items-center xs-justify-content-end">
                    <xs-data-field-resource-state [value]="$any(data)">
                    </xs-data-field-resource-state>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Personal Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="lce.shared.user.label.personalInformation"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [value]="data.name!.firstName" label="xs.common.label.firstName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [value]="data.name!.lastName" label="xs.common.label.lastName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [valueBaseTranslation]="TR_BASE_GENDER" [value]="data.gender" label="xs.common.label.gender"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [valueBaseTranslation]="TR_BASE_LANGUAGE" [value]="data.language" label="xs.common.label.language"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'municipalityOfResidence'"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <lce-data-field-municipality
                                    [required]="false"
                                    [showIcon]="true"
                                    [value]="data.municipalityOfResidence">
                            </lce-data-field-municipality>
                        </div>
                    </div>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Account === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="lce.shared.user.label.account"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [ellipsis]="true"
                            [rightIcon]="usedIDCopyIcon"
                            [valueTooltip]="{ text: data.id }"
                            [value]="data.id"
                            label="lce.shared.user.label.userID"
                            valueStyleClass="xs-font-size-small xs-color-secondary-imp xs-font-weight-normal-imp">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [leftIcon]="userIcon" [value]="data.code" label="lce.shared.user.label.userCode" valueStyleClass="xs-font-size-intermediate">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [value]="data.mainRole | lceUserMainRole: data.type" label="xs.common.label.mainRole">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [value]="data.position | lceUserPosition: data.type" label="lce.shared.user.label.position">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [required]="false" [valueBaseTranslation]="TR_BASE_ACCOUNT_STATUS" [value]="data.accountStatus" label="lce.shared.user.label.accountStatus">
                        <ng-template xsTemplate="below">
                            <lce-user-resource-audit-inline
                                    *ngIf="data.accountStatusUpdatedOn | xsIsNotEmpty"
                                    [by]="data.accountStatusUpdatedBy"
                                    [on]="data.accountStatusUpdatedOn"
                                    styleClass="xs-mt-5 xs-color-secondary">
                            </lce-user-resource-audit-inline>
                        </ng-template>
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-calendar
                            [mediumLongDateTime]="true"
                            [required]="false"
                            [timeAgoLive]="true"
                            [timeAgo]="true"
                            [value]="data.lastLoginOn"
                            label="lce.core.label.lastLogin">
                    </xs-data-field-calendar>
                </div>

                <div class="col-12 md:col-6">
                    <lce-data-field-facility
                            [required]="true"
                            [showIcon]="true"
                            [value]="data.facility"
                            partialLabel="lce.core.label.facility">
                    </lce-data-field-facility>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Contact === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="xs.core.label.contact"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-email [value]="data.email" label="xs.common.label.email"></xs-data-field-email>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [value]="data.primaryPhoneNumber" label="xs.common.label.primaryPhoneNumber"></xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.secondaryPhoneNumber!" label="xs.common.label.secondaryPhoneNumber">
                    </xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.whatsAppPhoneNumber!" label="xs.common.label.whatsAppPhoneNumber">
                    </xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-address
                            [editable]="true"
                            [options]="addressFieldOptions"
                            [value]="data!.address"
                            label="xs.common.label.address">
                    </xs-data-field-address>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-contact-person
                            [deletable]="false"
                            [editable]="true"
                            [options]="emergencyContactPersonFieldOptions"
                            [required]="false"
                            [value]="data!.emergencyContactPerson"
                            label="lce.shared.user.label.contactPerson"
                            subLabel="lce.shared.user.label.inCaseOfEmergency">
                    </xs-data-field-contact-person>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Note === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="lce.shared.user.label.note"></xs-title>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-textarea [required]="false" [value]="data.note" valueStyleClass="xs-font-size-intermediate"></xs-data-field-textarea>
                </div>

            </div>
        </div>
    </div>
</div>
