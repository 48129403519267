import {Injectable} from '@angular/core';
import {LCEUserMunicipalEmployee} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEMunicipalEmployeeCreateUpdateComponent} from './create-update/lce-user-municipal-employee-create-update.component';
import {LCEUserMunicipalEmployeeRecordComponent} from './record/lce-user-municipal-employee-record.component';

export interface LCEUserMunicipalEmployeeDialogCreateUpdateArg {
    userID?: string;
    user?: LCEUserMunicipalEmployee;
    adminView: boolean;
    showRecordAfterSave?: boolean;
    showSecondaryPhoneNumberField?: boolean;
    showWhatsAppPhoneNumberField?: boolean;
    onClose?: (userRecord?: LCEUserMunicipalEmployee) => void;
    onSave?: (userRecord: LCEUserMunicipalEmployee) => void;
}

export interface LCEUserMunicipalEmployeeDialogRecordArg {
    userID?: string;
    user?: LCEUserMunicipalEmployee;
    readonly: boolean;
    onClose?: (userRecord?: LCEUserMunicipalEmployee) => void;
    onEdit?: (userRecord: LCEUserMunicipalEmployee) => void;
}

@Injectable()
export class LCEUserMunicipalEmployeeDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openMunicipalEmployeeRecordDialog(arg: LCEUserMunicipalEmployeeDialogRecordArg): void {
        XSAssert.notNull(arg, 'arg');

        const dConfig: XSDialogConfig = {
            data: {
                userID: arg.userID,
                user: arg.user,
                readonly: arg.readonly
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90 xs-max-width-1000'
        };
        const accountComponent: LCEUserMunicipalEmployeeRecordComponent = this.dialogService.openComponentDialog(LCEUserMunicipalEmployeeRecordComponent, dConfig);

        accountComponent.closeEvent.subscribe((userRecord: LCEUserMunicipalEmployee) => {
            if (arg?.onClose) arg!.onClose!(userRecord);
        });
        accountComponent.editEvent.subscribe((userRecord: LCEUserMunicipalEmployee) => {
            if (!XSUtils.isNull(arg.onEdit)) arg!.onEdit!(userRecord);
            accountComponent.closeDialog();
            this.openMunicipalEmployeeCreateUpdateDialog({
                user: userRecord,
                adminView: arg.readonly,
                showRecordAfterSave: true
            });
        });
    }

    public openMunicipalEmployeeCreateUpdateDialog(arg: LCEUserMunicipalEmployeeDialogCreateUpdateArg): void {
        XSAssert.notNull(arg, 'arg');

        const dConfig: XSDialogConfig = {
            data: {
                userID: arg.user?.id,
                user: arg.user,
                userCountryISO: 'ci', //this.contextService.getCurrentCountryISO(),
                showSecondaryPhoneNumberField: arg.showSecondaryPhoneNumberField,
                showWhatsAppPhoneNumberField: arg.showWhatsAppPhoneNumberField,
                loadingStyleClass: 'xs-min-height-800 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90 xs-max-width-1000'
        };

        const createUpdateComponent: LCEMunicipalEmployeeCreateUpdateComponent = this.dialogService.openComponentDialog(LCEMunicipalEmployeeCreateUpdateComponent, dConfig);

        createUpdateComponent.closeEvent.subscribe((userRecord: LCEUserMunicipalEmployee) => {
            if (arg?.onClose) arg!.onClose!(userRecord);
        });
        createUpdateComponent.saveEvent.subscribe((userRecord: LCEUserMunicipalEmployee) => {
            if (!XSUtils.isNull(arg.onSave)) arg!.onSave!(userRecord);
            createUpdateComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openMunicipalEmployeeRecordDialog({
                user: userRecord,
                readonly: arg.adminView
            });
        });
    }
}
