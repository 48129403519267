<div class="xs-flex-column xs-width-full xs-height-full {{ styleClass }}">
    <div class="xs-card">
        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
                    <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
                        {{ 'lce.shared.user.label.profile' | translate }}
                    </span>
                </div>
                <div class="xs-flex-row">
                    <xs-button (clickEvent)="onEdit()" *ngIf="canEdit()" [icon]="ICON.edit" class="xs-mr-40" label="xs.core.label.edit" size="intermediate" type="text"> </xs-button>
                </div>
            </div>
        </div>
        <div class="xs-card-content xs-pt-50-imp">
            <div class="grid xs-fields xs-fields-spacer-30">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Personal Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="lce.shared.user.label.personalInformation"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [value]="data.name!.firstName" label="xs.common.label.firstName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [value]="data.name!.lastName" label="xs.common.label.lastName"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [valueBaseTranslation]="TR_BASE_GENDER" [value]="data.gender" label="xs.common.label.gender"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-text [valueBaseTranslation]="TR_BASE_LANGUAGE" [value]="data.language" label="xs.common.label.language"></xs-data-field-text>
                </div>

                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Contact === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="xs.core.label.contact"></xs-title>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-email [value]="data.email" label="xs.common.label.email"></xs-data-field-email>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [value]="data.primaryPhoneNumber" label="xs.common.label.primaryPhoneNumber"></xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.secondaryPhoneNumber!" label="xs.common.label.secondaryPhoneNumber"></xs-data-field-phone-number>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-phone-number [required]="false" [value]="data.whatsAppPhoneNumber!" label="xs.common.label.whatsAppPhoneNumber"></xs-data-field-phone-number>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-address [editable]="true" [options]="addressFieldOptions" [value]="data!.address" label="xs.common.label.address"></xs-data-field-address>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-contact-person
                        [required]="false"
                        label="xs.core.label.emergencyContact"
                        [value]="data.emergencyContactPerson"
                        [editable]="true"
                        [deletable]="true"
                        [options]="emergencyContactPersonFieldOptions">
                    </xs-data-field-contact-person>
                </div>
            </div>
        </div>
        <div class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="data.id"></xs-data-id>
        </div>
    </div>
</div>
