import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    LCE_FACILITY_PARTIAL_YOPOUGON,
    LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    LCE_TR_BASE_FACILITY_WORKER_POSITION,
    LCEFacilityPartial,
    LCEFacilityService,
    LCEFacilityWorker,
    LCEFacilityWorkerCreate,
    LCEFacilityWorkerPosition,
    LCEFacilityWorkerService
} from '@lce/core';
import {LOG, XS_LOREM_IPSUM, XSAddressType, XSTranslatableString, XSUtils} from '@xs/base';
import {XSFormUtils, XSLabelValue, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldAddressOptions, XSInputFieldAutoCompleteOptions, XSInputFieldCalendarOptions, XSInputFieldDropdownOptions, XSInputFieldTextOptions} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-facility-worker-create-update', templateUrl: './lce-facility-worker-create-update.component.html'})
export class LCEFacilityWorkerCreateUpdateComponent extends XSDialogable implements OnInit {

    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.facilityWorker.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateServiceWorkerCentralLoader';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() facilityWorkerID?: string;
    @Input() facilityWorker?: LCEFacilityWorker;

    @Input() showEditButton?: boolean;

    @Input() facilityCode?: string;
    @Input() facility?: LCEFacilityPartial;

    @Output() saveEvent = new EventEmitter<LCEFacilityWorker>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEFacilityWorker>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    formGroup: FormGroup = new FormGroup({});
    facilityCodeField: XSInputFieldAutoCompleteOptions;
    firstNameField: XSInputFieldTextOptions;
    lastNameField: XSInputFieldTextOptions;
    addressField: XSInputFieldAddressOptions;
    emailField: XSInputFieldTextOptions;
    primaryPhoneNumberField: XSInputFieldTextOptions;
    secondaryPhoneNumberField: XSInputFieldTextOptions;
    noteField: XSInputFieldTextOptions;
    positionField: XSInputFieldDropdownOptions;
    hiredOnField: XSInputFieldCalendarOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveFacilityWorker()
    };

    createUpdateError: any;

    private subscription: Subscription = new Subscription();

    private readonly POSITIONS: XSLabelValue[] = [
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.COURTHOUSE_OFFICER, value: LCEFacilityWorkerPosition.COURTHOUSE_OFFICER},
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.DOCTOR, value: LCEFacilityWorkerPosition.DOCTOR},
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.MIDWIFE, value: LCEFacilityWorkerPosition.MIDWIFE},
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.NURSE, value: LCEFacilityWorkerPosition.NURSE},
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.POLICE_OFFICER, value: LCEFacilityWorkerPosition.POLICE_OFFICER},
        {label: LCE_TR_BASE_FACILITY_WORKER_POSITION + LCEFacilityWorkerPosition.OTHER, value: LCEFacilityWorkerPosition.OTHER}
    ];

    constructor(private loaderService: XSLoaderService, private facilityWorkerService: LCEFacilityWorkerService, private facilityService: LCEFacilityService) {
        super();
    }

    get headerTitle(): string | XSTranslatableString {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.facilityWorker?.name.firstName + ' ' + this.facilityWorker?.name.lastName;
        if (XSUtils.isEmpty(title)) title = this.TR_BASE_LABEL + 'serviceWorker';
        return title!;
    }

    get headerSubTitle(): string | XSTranslatableString {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.facilityWorker?.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facilityWorkerID = this.dialogConfig.data.facilityWorkerID;
            this.facilityWorker = this.dialogConfig.data.facilityWorker;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.facility = this.dialogConfig.data.facility;
            this.facilityCode = this.facility?.code;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.facilityWorker));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.facilityWorker)) {
            if (!XSUtils.isEmpty(this.facilityWorker)) this.facilityWorkerID = this.facilityWorker!.id;
            this.initialize();
        } else this.retrieveFacilityWorker();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.facilityWorkerID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.facilityWorkerID) && XSUtils.isEmpty(this.facilityWorker);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty;
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.firstNameField.control?.value) ||
            !XSUtils.isEmpty(this.primaryPhoneNumberField.control?.value) ||
            !XSUtils.isEmpty(this.positionField.control?.value)
        ) {
            return false;
        }
        return true;
    }

    public fillForm(): void {
        this.formGroup.get(this.facilityCodeField.fieldName)?.setValue(LCE_FACILITY_PARTIAL_YOPOUGON);
        this.formGroup.get(this.hiredOnField.fieldName)?.setValue('2022-03-25');
        this.formGroup.get(this.firstNameField.fieldName)?.setValue('Steve');
        this.formGroup.get(this.lastNameField.fieldName)?.setValue('Jobs');
        this.formGroup.get(this.emailField.fieldName)?.setValue('steve.jobs@iro.com');
        this.formGroup.get(this.primaryPhoneNumberField.fieldName)?.setValue('+2250101010101');
        this.formGroup.get(this.secondaryPhoneNumberField.fieldName)?.setValue('+2250101010101');
        this.formGroup.get(this.positionField.fieldName)?.setValue(LCEFacilityWorkerPosition.NURSE);
        this.formGroup.get(this.noteField.fieldName)?.setValue(XSUtils.randomElement([XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long, XS_LOREM_IPSUM.short]));
        this.formGroup.get(this.addressField.fieldName)?.setValue(LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA);
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private retrieveFacilityWorker(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.facilityWorkerService
                .retrieve(this.facilityWorkerID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: (worker) => {
                        this.facilityWorker = worker;
                        this.initialize();
                    },
                    error: (error) => (this.retrieveError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating Facility worker ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const facilityWorkerCreate = this.buildFacilityWorkerCreate(formData);

        this.subscription.add(
            this.facilityWorkerService
                .create(facilityWorkerCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (facilityWorker) => {
                        LOG().debug('facilityWorker successfully saved :-) [code: ' + facilityWorker.code + ', id: ' + facilityWorker.id + ']');
                        this.saveEvent.emit(facilityWorker);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildFacilityWorkerCreate(formData: any): LCEFacilityWorkerCreate {
        let serviceWorkerCreate: LCEFacilityWorkerCreate = {
            facilityCode: this.facilityCode ? this.facilityCode : XSUtils.trim(formData.facilityCode.code),
            name: {firstName: XSUtils.trim(formData.firstName), lastName: XSUtils.trim(formData.lastName)},
            email: XSUtils.trim(formData.email),
            primaryPhoneNumber: XSUtils.trim(formData.primaryPhoneNumber),
            secondaryPhoneNumber: XSUtils.trim(formData.secondaryPhoneNumber),
            address: formData.address,
            note: XSUtils.trim(formData.note),
            position: formData.position,
            hiredOn: XSUtils.trim(formData.hiredOn)
        };

        XSUtils.removeNullAndUndefinedEntries(serviceWorkerCreate);

        return serviceWorkerCreate;
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating FacilityWorker [facilityWorkerID: ' + this.facilityWorkerID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildFacilityWorkerUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Facility Worker not updated. No change detected ! [code: ' + this.facilityWorker!.code + ', id: ' + this.facilityWorkerID + ']');
            this.saveEvent.emit(this.facilityWorker);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.facilityWorkerService
                .update(this.facilityWorkerID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (facilityWorker) => {
                        const updatedFacilityWorkerID = facilityWorker.id;
                        LOG().debug('Facility Worker successfully updated :-) [code: ' + facilityWorker.code + ', id: ' + updatedFacilityWorkerID + ']');
                        this.saveEvent.emit(facilityWorker);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildFacilityWorkerUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (!XSUtils.equal(formData.hiredOn, this.facilityWorker?.hiredOn)) fieldValueMap.set('facilityCode', XSUtils.trim(formData.facilityCode.code));
        if (!XSUtils.equal(formData.facilityCode, this.facilityWorker?.facility?.code)) fieldValueMap.set('facilityCode', XSUtils.trim(formData.facilityCode.code));
        if (!XSUtils.equal(formData.firstName, this.facilityWorker?.name.firstName) || !XSUtils.equal(formData.lastName, this.facilityWorker?.name.lastName))
            fieldValueMap.set('name', {firstName: XSUtils.trim(formData.firstName), lastName: XSUtils.trim(formData.lastName)});
        if (!XSUtils.equal(formData.email, this.facilityWorker?.email)) fieldValueMap.set('email', XSUtils.trim(formData.email));
        if (!XSUtils.equal(formData.primaryPhoneNumber, this.facilityWorker?.primaryPhoneNumber)) fieldValueMap.set('primaryPhoneNumber', XSUtils.trim(formData.primaryPhoneNumber));
        if (!XSUtils.equal(formData.secondaryPhoneNumber, this.facilityWorker?.secondaryPhoneNumber)) fieldValueMap.set('secondaryPhoneNumber', XSUtils.trim(formData.secondaryPhoneNumber));
        //TODO : Address
        if (!XSUtils.equal(formData.note, this.facilityWorker?.note)) fieldValueMap.set('note', XSUtils.trim(formData.note));
        if (!XSUtils.equal(formData.position, this.facilityWorker?.position)) fieldValueMap.set('position', formData.position);
        if (!XSUtils.equal(formData.hiredOn, this.facilityWorker?.hiredOn)) fieldValueMap.set('hiredOn', formData.hiredOn);

        return fieldValueMap;
    }

    private searchFacilities(query?: string): Observable<LCEFacilityPartial[]> {
        return this.facilityService.autocomplete(XSUtils.trim(query));
    }

    private buildFields(): void {
        this.facilityCodeField = {
            fieldName: 'facilityCode',
            label: this.TR_BASE_LABEL + 'facility',
            control: new FormControl(this.facilityWorker?.facility, [Validators.required]),
            placeholder: 'Search ...',
            autoCompleteOptions: {
                labelField: 'name',
                forceSelection: true,
                search: (query: string) => this.searchFacilities(query)
            }
        };
        this.firstNameField = {
            fieldName: 'firstName',
            control: new FormControl(this.facilityWorker?.name.firstName, Validators.required),
            label: this.TR_BASE_LABEL + 'firstName'
        };
        this.lastNameField = {
            fieldName: 'lastName',
            control: new FormControl(this.facilityWorker?.name.lastName, Validators.required),
            label: this.TR_BASE_LABEL + 'lastName'
        };
        this.emailField = {
            fieldName: 'email',
            control: new FormControl(this.facilityWorker?.email),
            label: this.TR_BASE_LABEL + 'email'
        };
        this.addressField = {
            fieldName: 'address',
            label: this.TR_BASE_LABEL + 'address',
            control: new FormControl(this.facilityWorker?.address, Validators.required),
            addressOptions: {
                formOptions: {
                    type: undefined!,
                    title: true,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED]
                }
            }
        };
        this.primaryPhoneNumberField = {
            fieldName: 'primaryPhoneNumber',
            control: new FormControl(this.facilityWorker?.primaryPhoneNumber, Validators.required),
            label: this.TR_BASE_LABEL + 'primaryPhoneNumber'
        };
        this.secondaryPhoneNumberField = {
            fieldName: 'secondaryPhoneNumber',
            control: new FormControl(this.facilityWorker?.secondaryPhoneNumber),
            label: this.TR_BASE_LABEL + 'secondaryPhoneNumber'
        };
        this.noteField = {
            fieldName: 'note',
            control: new FormControl(this.facilityWorker?.note),
            label: this.TR_BASE_LABEL + 'note'
        };
        this.positionField = {
            fieldName: 'position',
            label: this.TR_BASE_LABEL + 'position',
            control: new FormControl(this.facilityWorker?.position),
            items: this.POSITIONS,
            placeholder: this.TR_BASE_LABEL + 'positionPlaceholder'
        };

        let hiredOn = this.facilityWorker?.hiredOn ? new Date(this.facilityWorker?.hiredOn) : '';
        this.hiredOnField = {
            fieldName: 'hiredOn',
            calendarOptions: {dateFormatFR: 'yy-mm-dd', showIcon: true},
            control: new FormControl(hiredOn),
            label: this.TR_BASE_LABEL + 'hiredOn'
        };

        this.formGroup.addControl(this.hiredOnField.fieldName, this.hiredOnField.control!);
        this.formGroup.addControl(this.facilityCodeField.fieldName, this.facilityCodeField.control!);
        this.formGroup.addControl(this.firstNameField.fieldName, this.firstNameField.control!);
        this.formGroup.addControl(this.lastNameField.fieldName, this.lastNameField.control!);
        this.formGroup.addControl(this.emailField.fieldName, this.emailField.control!);
        this.formGroup.addControl(this.addressField.fieldName, this.addressField.control!);
        this.formGroup.addControl(this.primaryPhoneNumberField.fieldName, this.primaryPhoneNumberField.control!);
        this.formGroup.addControl(this.secondaryPhoneNumberField.fieldName, this.secondaryPhoneNumberField.control!);
        this.formGroup.addControl(this.noteField.fieldName, this.noteField.control!);
        this.formGroup.addControl(this.positionField.fieldName, this.positionField.control!);
        this.formGroup.addControl(this.hiredOnField.fieldName, this.hiredOnField.control!);
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'lce.shared.confirmation.resetConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'lce.shared.confirmation.closeConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            }
        };
    }
}
