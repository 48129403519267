import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ChartModule} from 'primeng/chart';
import {LCECMDDashboardComponent} from './lce-cmd-dashboard.component';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';
import {LCECMDDashboardService} from './lce-cmd-dashboard.service';
import {LCETemplateModule} from '../../templates/lce-template.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, ChartModule, LCETemplateModule],
    declarations: [
        LCECMDDashboardComponent
    ],
    providers: [LCECMDDashboardService]
})
export class LceCMDDashboardModule {
}
