import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEFacilityPartial, LCEFacilityService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-input-field-facility-autocomplete',
    template: `
        <xs-input-field-auto-complete
                styleClass="{{ styleClass }}"
                [appendTo]="appendTo"
                [options]="autocompleteOptions">
            <ng-template let-facility xsTemplate="item">
                <lce-facility-partial [data]="facility"></lce-facility-partial>
            </ng-template>
        </xs-input-field-auto-complete>
    `,
    host: {class: 'xs-width-full'}
})
export class LCEInputFieldFacilityAutocompleteComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() options: XSInputFieldBaseOptions;

    @Input() showIcon?: boolean;

    @Input() completeOnFocus?: boolean;

    @Input() showDefaultBelowText?: boolean;

    @Input() placeholder?: string;

    @Input() appendTo?: any;

    @Input() registered?: boolean;

    @Output() selectEvent = new EventEmitter<LCEFacilityPartial>();

    autocompleteOptions: XSInputFieldAutoCompleteOptions;

    @ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

    constructor(private facilityService: LCEFacilityService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.facility.label.autocompletePlaceholder';
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        this.autocompleteOptions = {
            control: this.options.control,
            autoCompleteOptions: {
                labelField: 'fullName',
                forceSelection: true,
                completeOnFocus: this.completeOnFocus,
                search: (query: string) => {
                    if (this.registered) return this.facilityService.autocompleteRegistered(query);
                    return this.facilityService.autocomplete(query);
                }
            },
            placeholder: this.placeholder,
            belowText: this.showDefaultBelowText === true ? 'lce.core.label.facility' : undefined,
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
            selectEvent: (event: any) => {
                this.handleSelectEvent(event);
            },
            ...this.options
        };
        if (this.showIcon) {
            this.autocompleteOptions.inputStyleClass = 'xs-pl-35-imp';
            this.autocompleteOptions.leftIcon = LCE_SHARED_ICON.facility;
            this.autocompleteOptions.leftIconStyleClass = 'xs-color-secondary xs-font-size-16-imp xs-mt-minus-1';
        }
    }

    private handleSelectEvent(facility: LCEFacilityPartial): void {
        this.selectEvent.emit(facility);
    }
}
