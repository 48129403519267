import {LCEDistrictPartial, LCEFacilityPartial, LCEFacilityType, LCEMunicipalityPartial, LCEServicePointPartial} from '@lce/core';
import {LCE_MOCK_DISTRICT_CODE_ABIDJAN, LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL, LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON} from '@lce/mock/core/lce-mock.constant';

export const DISTRICT_PARTIAL_ABIDJAN: LCEDistrictPartial = {
    id: 'c5eb1f46-ef73-475f-9262-03c765551db4',
    code: LCE_MOCK_DISTRICT_CODE_ABIDJAN,
    abbreviation: 'ABJ',
    name: 'Abidjan',
    fullName: 'District Autonome d\'Abidjan',
    shortName: 'Dist. Abidjan'
};

export const MUNICIPALITY_PARTIAL_YOPOUGON: LCEMunicipalityPartial = {
    id: '7067ab2e-6298-42e7-919e-a4a446cdee4e',
    code: LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON,
    name: 'Yopougon',
    fullName: 'Commune de Yopougon',
    shortName: 'C. de Yopougon',
    district: DISTRICT_PARTIAL_ABIDJAN
};

export const FACILITY_PARTIAL_YOPOUGON_TOWN_HALL: LCEFacilityPartial = {
    id: '2260833f-fce8-434d-bd00-51b2e5008f78',
    code: LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL,
    type: LCEFacilityType.TOWN_HALL,
    name: 'Mairie de Yopougon',
    fullName: 'Mairie de Yopougon Selmer',
    subName: 'M. de Yopougon',
    municipality: MUNICIPALITY_PARTIAL_YOPOUGON
};

export const SERVICE_POINT_PARTIAL_YOPOUGON_COSMOS: LCEServicePointPartial = {
    id: '5c3becdb-895e-44fd-86ff-19abb563537d',
    code: 'LCE-SEP-ABJ-YOP-ABC-32',
    name: 'Cosmos Yopougon Selmer',
    fullName: 'Point de Services Cosmos Yopougon Selmer',
    subName: 'Cosmos',
    municipality: MUNICIPALITY_PARTIAL_YOPOUGON
};
