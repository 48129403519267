import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCESuggestion, LCESuggestionCreate, LCESuggestionPartial, LCESuggestionSearch, LCESuggestionStatus, LCEUserPartial} from '@lce/core';
import {XSHttpMethod, XSHttpParamsUtils, XSPKDTOStats, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockSearchQueryPredicate} from '@xs/mock';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';
import {LCEMockDataSuggestionBuilder} from '@lce/mock/core/data-builder/lce-mock-data-suggestion.builder';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.suggestions';

@Injectable()
export class LCEMockSuggestionHandler extends XSMockPKResourceAuditFullHandler<LCESuggestion, LCESuggestionPartial, LCESuggestionSearch> {

    public static SUGGESTION_STORAGE: Map<string, LCESuggestion> = new Map<string, LCESuggestion>();

    constructor(
        private contextService: LCECoreContextService,
        private mockDataSuggestionBuilder: LCEMockDataSuggestionBuilder
    ) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.suggestions.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCESuggestion> {
        return LCEMockSuggestionHandler.SUGGESTION_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(suggestion: LCESuggestion): LCESuggestionPartial {
        return {
            id: suggestion.id,
            code: suggestion.code,
            status: suggestion.status,
            createdOn: new Date().toISOString(),
            anonymous: suggestion.anonymous
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        const queryPredicates: XSMockSearchQueryPredicate<LCESuggestion>[] = [
            (suggestion, query) => suggestion.message.toLowerCase().includes(query)
        ];

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.AUDIT_FULL_ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 3000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData({random: true, randomMin: 11, randomMax: 100}),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: queryPredicates}),
            // Search
            this.buildSearchMockData({
                queryPredicates: queryPredicates,
                predicates: [
                    (suggestion, params) => this.httpParamArrayIncludes(params, 'codes', suggestion.code),
                    (suggestion, params) => this.httpParamStringIncludes(params, 'subject', suggestion.subject),
                    (suggestion, params) => {
                        const paramAnonymous = XSHttpParamsUtils.getAsBoolean(params, 'anonymous');
                        if (XSUtils.isNull(paramAnonymous)) return true;
                        if (XSUtils.isNull(suggestion.anonymous)) {
                            return paramAnonymous !== true;
                        }
                        return paramAnonymous === suggestion.anonymous;
                    },
                    (suggestion, params) => this.httpParamArrayIncludes(params, 'statuses', suggestion.status),
                    (suggestion, params) => this.httpParamArrayIncludes(params, 'message', suggestion.message)
                ]
            }),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 23,
                    active: 21,
                    inactive: 1,
                    deleted: 1
                } as XSPKDTOStats
            },
            // Stats
            {
                id: DATASET_BASE_ID + '.statsStatuses',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.suggestions.index + '/' + LCE_CORE_ENDPOINTS.suggestions.statsStatuses,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 120,
                    unread: 14,
                    read: 102,
                    considered: 4,
                    selected: 2
                }
            },
            // Last
            this.buildFindNLastMockData(1)
        ];
    }

    private addSuggestionMockData(suggestionID: string): void {
        this.addResourceBaseMockData(suggestionID);
        this.mockDataArray.push(this.buildUpdateStatusMockData(suggestionID));
    }

    private buildUpdateStatusMockData(suggestionID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.updateSuggestionStatus.' + suggestionID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.suggestions.index}/${suggestionID}/${LCE_CORE_ENDPOINTS.suggestions.status}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: XSUtils.randomElement([2000, 3000, 4000]),
            getResponseData: rArg => {
                const status: LCESuggestionStatus = rArg.params!.get('status')! as LCESuggestionStatus;

                const suggestionRecord = LCEMockSuggestionHandler.SUGGESTION_STORAGE.get(suggestionID)!;
                suggestionRecord.status = status;

                const on = new Date().toISOString();
                const by = this.contextService.getUser();

                suggestionRecord.updatedOn = on;
                suggestionRecord.updatedBy = by;

                switch (status) {
                    case LCESuggestionStatus.READ:
                        suggestionRecord.readOn = on;
                        suggestionRecord.readBy = by;
                        break;
                    case LCESuggestionStatus.UNREAD:
                        suggestionRecord.unreadOn = on;
                        suggestionRecord.unreadBy = by;
                        break;
                    case LCESuggestionStatus.CONSIDERED:
                        suggestionRecord.consideredOn = on;
                        suggestionRecord.consideredBy = by;
                        break;
                    case LCESuggestionStatus.SELECTED:
                        suggestionRecord.consideredOn = on;
                        suggestionRecord.selectedBy = by;
                        break;
                }
                return {...suggestionRecord};
            }
        };
    }

    private createResponseData(body: any): LCESuggestion {
        const suggestionCreate: LCESuggestionCreate = body as LCESuggestionCreate;
        const suggestion = this.mockDataSuggestionBuilder.buildSuggestion(suggestionCreate);

        LCEMockSuggestionHandler.SUGGESTION_STORAGE.set(suggestion.id, suggestion);
        this.addSuggestionMockData(suggestion.id);

        return suggestion;
    }

    private buildStorage(): void {
        for (let i = 0; i < 10; i++) {
            const suggestion: LCESuggestion = this.mockDataSuggestionBuilder.buildSuggestion();
            LCEMockSuggestionHandler.SUGGESTION_STORAGE.set(suggestion.id, suggestion);
            this.addSuggestionMockData(suggestion.id);
        }
    }

}
