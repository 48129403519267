<div class="xs-width-full {{styleClass}} {{dialogStyleClass}}">
    <div class="xs-card">
        <div *ngIf="title | xsIsNotEmpty" class="xs-card-header xs-mb-30-imp">
            <span [innerHTML]="title! | translate" class="xs-card-header-title xs-color-secondary"></span>
            <span
                    *ngIf="subtitle | xsIsNotEmpty"
                    [innerHTML]="subtitle! | translate"
                    class="xs-card-header-subTitle xs-color-secondary xs-font-size-small-imp xs-mt-1-imp">
            </span>
        </div>

        <div class="xs-card-content">
            <lce-authorization [data]="data"></lce-authorization>
        </div>
    </div>
</div>