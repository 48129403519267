import {LCEFacilityWorkerPosition} from '@lce/core';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {XSAssert} from '@xs/base';

export class LCEFacilityWorkerUtils {

    public static getIcon(position: LCEFacilityWorkerPosition) {
        XSAssert.notEmpty(position, 'position');
        switch (position) {
            case LCEFacilityWorkerPosition.COURTHOUSE_OFFICER:
                return LCE_SHARED_ICON.balance;
            case LCEFacilityWorkerPosition.DOCTOR:
                return LCE_SHARED_ICON.doctor;
            case LCEFacilityWorkerPosition.MIDWIFE:
                return LCE_SHARED_ICON.nurse;
            case LCEFacilityWorkerPosition.NURSE:
                return LCE_SHARED_ICON.nurse;
            case LCEFacilityWorkerPosition.POLICE_OFFICER:
                return LCE_SHARED_ICON.gavel;
            case LCEFacilityWorkerPosition.OTHER:
                return LCE_SHARED_ICON.user;
        }
    }
}
