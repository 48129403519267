<div [ngClass]="{ 'xs-disabled': disabled === true }"
     class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh xs-width-full">
        <xs-error [actionButton]="retrieveErrorRetryButton" [error]="retrieveError" [showActionButton]="true"
                  [showErrorDetailButton]="true" message="{{ TR_BASE }}errorRetrieveUser" mode="block"
                  styleClass="xs-max-width-600" subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()">
        <div [ngClass]="{ 'xs-disabled': createUpdateLoading }"
             class="grid xs-fields xs-fields-spacer-15 xs-fields-plr-15 {{ formContainerStyleClass }}">
            <!-- ------------------------------------------------------------------------------------------------------ -->
            <!-- === Profile Image  === -->
            <!-- ------------------------------------------------------------------------------------------------------ -->

            <div class="col-12">
                <div class="xs-flex-column-center xs-with-full xs-p-15 xs-mb-25">
                    <xs-ivar [avatarType]="avatarType" [avatar]="avatar" [disabled]="disabled"
                             avatarIconStyleClass="xs-color-secondary-imp xs-font-size-80-imp"
                             avatarLabelStyleClass="xs-color-secondary-imp xs-font-size-80-imp xs-font-weight-200-imp" avatarSize="none"
                             avatarStyleClass="xs-border-discrete"
                             avatarStyleClass="xs-width-200-imp xs-height-200-imp"
                             class="xs-width-fit-content">
                    </xs-ivar>
                    <xs-button
                            (clickEvent)="onUploadImage()"
                            [icon]="ICON.camera"
                            class="xs-mt-10"
                            label="xs.core.label.uploadImage"
                            size="intermediate"
                            type="text">
                    </xs-button>
                </div>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------ -->
            <!-- === Personal Information  === -->
            <!-- ------------------------------------------------------------------------------------------------------ -->

            <div class="col-12">
                <xs-title text="{{ TR_BASE_LABEL }}personalInformation"></xs-title>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-text [options]="firstNameField"></xs-input-field-text>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-text [options]="lastNameField"></xs-input-field-text>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-dropdown [options]="genderField"></xs-input-field-dropdown>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-radios [options]="languageField"></xs-input-field-radios>
            </div>
            <div class="col-12 md:col-6">
                <lce-input-field-municipality-autocomplete [options]="municipalityOfResidenceField" [showIcon]="true">
                </lce-input-field-municipality-autocomplete>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------ -->
            <!-- === Account === -->
            <!-- ------------------------------------------------------------------------------------------------------ -->

            <div class="col-12">
                <xs-title text="{{ TR_BASE_LABEL }}account"></xs-title>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-dropdown [options]="mainRoleField"></xs-input-field-dropdown>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-dropdown [options]="positionField"></xs-input-field-dropdown>
            </div>
            <div class="col-12 md:col-6">
                <lce-input-field-facility-autocomplete [options]="facilityField" [showIcon]="true">
                </lce-input-field-facility-autocomplete>
            </div>

            <!-- ------------------------------------------------------------------------------------------------------ -->
            <!-- === Contact === -->
            <!-- ------------------------------------------------------------------------------------------------------ -->

            <div class="col-12">
                <xs-title text="{{ TR_BASE_LABEL }}contact"></xs-title>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-email [options]="emailField"></xs-input-field-email>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-phone-number [options]="primaryPhoneNumberField"></xs-input-field-phone-number>
            </div>
            <div *ngIf="showSecondaryPhoneNumberField !== false" class="col-12 md:col-6">
                <xs-input-field-phone-number [options]="secondaryPhoneNumberField"></xs-input-field-phone-number>
            </div>
            <div *ngIf="showWhatsAppPhoneNumberField !== false" class="col-12 md:col-6">
                <xs-input-field-phone-number [options]="whatsAppPhoneNumberField"></xs-input-field-phone-number>
            </div>

            <div class="col-12 md:col-6">
                <xs-input-field-address [options]="addressField"></xs-input-field-address>
            </div>
            <div class="col-12 md:col-6">
                <xs-input-field-contact-person [options]="emergencyContactPersonField"></xs-input-field-contact-person>
            </div>

            <div class="col-12">
                <xs-title [text]="TR_BASE_COMMON_LABEL + 'note'"></xs-title>
                <xs-input-field-textarea [options]="noteField" styleClass="xs-mt-15"></xs-input-field-textarea>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------------ -->
        <!-- === Footer === -->
        <!-- ------------------------------------------------------------------------------------------------------ -->
        <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-border-top-discrete xs-mt-25 xs-ptb-15">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>
    </div>

</div>

<ng-template #dHeader>
    <xs-ivar [ellipsis]="true" [icon]="ICON.user" [line1]="headerTitle" [line2]="headerSubTitle"
             styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-align-items-center xs-justify-content-end">

        <xs-error *ngIf="hasCreateUpdateError()" [ellipsis]="false" [error]="createUpdateError" [showErrorDetailButton]="true"
                  errorDetailButtonClass="xs-mt-2-imp" message="{{ TR_BASE_LABEL }}errorMessageCreateUpdate"
                  mode="inline" styleClass="xs-mr-30">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form"
                       size="intermediate" type="text">
            </xs-button>

            <xs-button (clickEvent)="reset()" *ngIf="isCreateMode()" [confirmation]="resetConfirmation" [disabled]="createUpdateLoading"
                       [showConfirmation]="shouldShowResetConfirmation()" confirmationPopupStyleClass="xs-font-size-10"
                       label="xs.core.label.reset" size="intermediate"
                       type="text">
            </xs-button>

            <xs-button (clickEvent)="closeDialog()" [confirmation]="closeConfirmation" [disabled]="createUpdateLoading || centralLoader.isRunning()" [outlined]="true"
                       [showConfirmation]="shouldShowCloseConfirmation()" confirmationPopupStyleClass="xs-font-size-10" label="xs.core.label.close"
                       severity="secondary" styleClass="xs-mlr-15-imp"
                       type="button">
            </xs-button>

            <xs-button (clickEvent)="createUpdate()" [disabled]="centralLoader.isRunning()" [loading]="createUpdateLoading"
                       label="xs.core.label.save" type="button">
            </xs-button>
        </div>
    </div>
</ng-template>