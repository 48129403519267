<div [ngClass]="{'xs-border-radius-default xs-p-15 xs-border-discrete': showBorder}"
     class=" xs-width-full xs-flex-row xs-justify-content-space-between  {{styleClass}}">

    <div class="xs-flex-column">
        <span (click)="openRecord(data.certificateOrder.id)"
              [innerHTML]="data.certificateOrder.orderNumber | translate"
              class="xs-font-size-intermediate xs-clickable xs-mb-2">
        </span>
        <lce-user-resource-audit-inline
                [by]="data.by"
                [on]="data.on"
                [oneLine]="true">
        </lce-user-resource-audit-inline>
        <xs-time-ago
                [givenDate]="data.on"
                styleClass="xs-display-block xs-color-secondary xs-font-size-extra-small xs-mt-1">
        </xs-time-ago>
    </div>

    <xs-square-data
            [rightText]="TR_BASE + 'label.consumedStamps'"
            [showBorder]="true"
            [styleClass]="styleClass"
            [value]="data.consumedNumberOfStamps | xsToString"
            valueStyleClass="xs-font-size-medium xs-mb-2">
    </xs-square-data>
</div>
