import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEFacilitiesFeatureService} from '@lce/shared';
import {LCECMDFacilitiesComponent} from './lce-cmd-facilities.component';
import {LCECMDFacilitiesRoutingModule} from './lce-cmd-facilities-routing.module';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, LCECMDFacilitiesRoutingModule],
    declarations: [LCECMDFacilitiesComponent],
    exports: [
        LCECMDFacilitiesComponent
    ],
    providers: [LCEFacilitiesFeatureService]
})
export class LCECMDFacilitiesModule {
}
