import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSLanguage, XSUtils} from '@xs/base';
import {XS_GENDER_ITEMS, XS_TR_BASE_LANGUAGE, XSCommonDomainUtils, XSLabelValue, XSValidators} from '@xs/common';
import {LCEUser} from '@lce/core';
import {XSInputFieldDropdownOptions, XSInputFieldRadiosOptions, XSInputFieldTextOptions, XSIvarAvatarType} from '@xs/core';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-user-account-personal-information-create-update', templateUrl: './lce-user-account-personnal-information-create-update.component.html'})
export class LCEUserAccountPersonalInformationCreateUpdateComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_COMMON_LABEL: string = 'xs.common.label.';
    @Input() styleClass?: string;
    @Input() formContainerStyleClass?: string;
    @Input() user?: LCEUser;
    @Input() disabled: boolean;

    @Input() formGroup?: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();
    firstNameField: XSInputFieldTextOptions;
    lastNameField: XSInputFieldTextOptions;
    genderField: XSInputFieldDropdownOptions;
    languageField: XSInputFieldRadiosOptions;

    avatar: string;
    avatarType: XSIvarAvatarType;
    private readonly GENDERS: XSLabelValue[] = XS_GENDER_ITEMS;

    ngOnInit(): void {
        this.buildFields();
    }

    public isFormEmpty(): boolean {
        return (
            XSUtils.isEmpty(this.firstNameField.control?.value) &&
            XSUtils.isEmpty(this.lastNameField.control?.value) &&
            XSUtils.isEmpty(this.genderField.control?.value) &&
            XSUtils.isEmpty(this.languageField.control?.value)
        );
    }

    private updateAvatar(): void {
        if (XSUtils.isEmpty(this.user?.profileImage)) {
            if (!XSUtils.isEmpty(this.firstNameField.control?.value) && !XSUtils.isEmpty(this.lastNameField.control?.value)) {
                this.avatar = XSCommonDomainUtils.getPersonInitials({
                    firstName: this.firstNameField.control!.value,
                    lastName: this.lastNameField.control?.value
                });
                this.avatarType = XSIvarAvatarType.LABEL;
            } else {
                this.avatar = this.ICON.user;
                this.avatarType = XSIvarAvatarType.ICON;
            }
        } else {
            this.avatar = this.user!.profileImage!;
            this.avatarType = XSIvarAvatarType.IMAGE;
        }
    }

    private buildFields(): void {
        this.firstNameField = {
            fieldName: 'firstName',
            label: this.TR_BASE_COMMON_LABEL + 'firstName',
            control: new FormControl(this.user?.name?.firstName, Validators.required),
            inputStyleClass: 'xs-text-transform-capitalize',
            valueChange: () => this.updateAvatar(),
            keyUpEvent: () => this.updateAvatar(),
            blurEvent: () => this.updateAvatar()
        };
        this.lastNameField = {
            fieldName: 'lastName',
            label: this.TR_BASE_COMMON_LABEL + 'lastName',
            control: new FormControl(this.user?.name?.lastName, Validators.required),
            inputStyleClass: 'xs-text-transform-capitalize',
            valueChange: () => this.updateAvatar(),
            keyUpEvent: () => this.updateAvatar(),
            blurEvent: () => this.updateAvatar()
        };
        this.genderField = {
            fieldName: 'gender',
            label: this.TR_BASE_COMMON_LABEL + 'gender',
            control: new FormControl(this.user?.gender, Validators.required),
            placeholder: this.TR_BASE_COMMON_LABEL + 'genderPlaceholder',
            items: this.GENDERS
        };
        this.languageField = {
            fieldName: 'language',
            label: this.TR_BASE_COMMON_LABEL + 'language',
            control: new FormControl(this.user?.language, XSValidators.checkedRadioRequired()),
            colStyleClass: 'md:col-6',
            radios: [
                {label: XS_TR_BASE_LANGUAGE + XSLanguage.FRENCH, value: XSLanguage.FRENCH},
                {label: XS_TR_BASE_LANGUAGE + XSLanguage.ENGLISH, value: XSLanguage.ENGLISH}
            ]
        };

        this.formGroup!.addControl(this.firstNameField.fieldName, this.firstNameField.control!);
        this.formGroup!.addControl(this.lastNameField.fieldName, this.lastNameField.control!);
        this.formGroup!.addControl(this.genderField.fieldName, this.genderField.control!);
        this.formGroup!.addControl(this.languageField.fieldName, this.languageField.control!);
    }
}
