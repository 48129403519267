import {XSIntegerRange, XSPKAuditUpdatedSearchFilter, XSPKDTOAuditFull, XSTranslatableString} from '@xs/base';
import {LCEImage} from '../article/lce-article-image';
import {LCEArticleReviewer} from '../article/lce-article-reviewer';
import {LCEUserPartial} from '../user/lce-user';
import {LCECategoryPartial} from '../lce-category';
import {LCEMunicipalityPartial} from '../lce-municipality';

export enum LCENewsEventName {
    ARTICLE_NEW = 'newsArticleNew',
    ARTICLE_REVIEWER_ADDED = 'newsArticleReviewerAdded',
    ARTICLE_REVIEWER_REMOVED = 'newsArticleReviewerRemoved',
    ARTICLE_REVIEWER_UPDATED_STATUS = 'newsArticleReviewerUpdatedStatus',
}

export enum LCENewsArticlePredefinedFilter {
    LATEST = 'latest',
    POPULAR = 'popular',
    MOST_READ = 'mostRead',
    MOST_LIKED = 'mostLiked',
    MOST_COMMENTED = 'mostCommented',
    MOST_UNLIKED = 'mostUnliked',
}

export enum LCENewsArticleStatus {
    HIDDEN = 'hidden',
    CREATED = 'created',
    ACCEPTED = 'accepted',
    IN_REVIEW = 'inReview',
    SCHEDULED = 'scheduled',
    REWORKING = 'reworking',
    PUBLISHED = 'published',
    TO_BE_REWORK = 'toBeRework',
    PARTIALLY_PUBLISHED = 'partiallyPublished',
}

export interface LCENewsArticleSearch extends XSPKAuditUpdatedSearchFilter {
    codes?: string[];

    title?: string;
    subTitle?: string;
    description?: string;
    content?: string;

    statuses?: LCENewsArticleStatus[];

    categoryCodes?: string[];
    tags?: string[];

    favoriteCountRange?: XSIntegerRange;
    readCountRange?: XSIntegerRange;
    commentCountRange?: XSIntegerRange;
    shareCountRange?: XSIntegerRange;
    likeCountRange?: XSIntegerRange;
    dislikeCountRange?: XSIntegerRange;
}

export interface LCENewsArticleCreate {
    title: XSTranslatableString;
    subTitle?: XSTranslatableString;
    description: XSTranslatableString;
    content: XSTranslatableString;

    coverImage: string;
    images?: LCEImage[];

    reviewerIDs: string[];

    categoryCodes: string[];

    tags?: string[];
}

export interface LCENewsArticleCard {
    id: string;

    createdOn: string;
    createdBy: LCEUserPartial;

    code: string;

    title: string;
    description: string;
    coverImage?: string;

    readCount: number;
    likeCount: number;
    commentCount: number;
    shareCount: number;

    publishedOn?: string;
    publishedBy?: LCEUserPartial;
}

export interface LCENewsArticlePartial {
    id: string;

    createdOn: string;

    code: string;

    title: string;
    description: string;
    coverImage?: string;

    readCount: number;
    likeCount: number;
    commentCount: number;
    shareCount: number;

    publishedOn?: string;
}

export interface LCENewsArticle extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;

    ownerMunicipality: LCEMunicipalityPartial;

    title: string;
    subTitle?: string;
    description: string;
    content: string;

    status: LCENewsArticleStatus;

    coverImage?: string;
    images?: LCEImage[];

    reviewers: LCEArticleReviewer[];

    categories: LCECategoryPartial[];

    tags?: string[];

    favoriteCount: number;
    readCount: number;
    commentCount: number;
    shareCount: number;
    likeCount: number;
    dislikeCount: number;

    publishedOn?: string;
    publishedBy?: LCEUserPartial;
}
