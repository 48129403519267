import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {XSUtils} from '@xs/base';
import {LCECertificateOrderDeliveryAssignmentRequest, LCECertificateOrderDeliveryAssignmentType, LCECertificateOrderDeliveryOperationRequest, LCEUserDeliveryManPartial} from '@lce/core';
import {XSLoaderService} from '@xs/common';
import {XSConfirmation, XSInputFieldBaseOptions} from '@xs/core';
import {FormControl} from '@angular/forms';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-delivery-assignment-request',
    templateUrl: './lce-delivery-assignment-request.component.html'
})
export class LCEDeliveryAssignmentRequestComponent implements OnInit, OnChanges {

    readonly TR_BASE = 'lce.shared.delivery.request.';

    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() loading?: boolean;

    @Input() selectedDeliveryMan: LCEUserDeliveryManPartial | undefined;

    @Input() disabled?: boolean;
    @Input() showSelected?: boolean;

    @Input() error: any;

    @Input() showOperation?: boolean;

    @Output() assignEvent = new EventEmitter<LCECertificateOrderDeliveryAssignmentRequest>();
    @Output() cancelEvent = new EventEmitter<void>();

    deliveryMenAutoCompleteField: XSInputFieldBaseOptions;

    notifyDeliveryMan: boolean;

    assignmentConfirmation: XSConfirmation = {
        key: 'assignmentConfirmationKey',
        trMessage: this.TR_BASE + 'confirmation.assignment',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => this.onAssign()
    };

    assignmentRequest: LCECertificateOrderDeliveryAssignmentRequest;

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isEmpty(this.subtitle)) this.subtitle = this.TR_BASE + 'subtitle';

        this.deliveryMenAutoCompleteField = {
            control: new FormControl(),
            fieldName: 'deliveryMen'
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.loading)) {
            if (this.loading === true) this.loaderService.startLoader(this.LOADER_ID);
            else this.loaderService.stopLoader(this.LOADER_ID);
        }
    }

    public onCancel(): void {
        this.cancelEvent.emit();
    }

    public handleNotifierDeliveryMan(event: boolean): void {
        this.assignmentRequest = {
            ...this.assignmentRequest,
            notifyDeliveryMan: event
        };
    }

    public handleRequestOperation(request: LCECertificateOrderDeliveryOperationRequest): void {
        this.assignmentRequest = {
            ...this.assignmentRequest,
            ...request
        };
    }

    public handleDeliveryManSelected(selectedEvent: LCEUserDeliveryManPartial): void {
        this.selectedDeliveryMan = selectedEvent;
        this.assignmentRequest = {
            assignmentType: LCECertificateOrderDeliveryAssignmentType.MANUAL,
            deliveryManID: this.selectedDeliveryMan.id,
            certificateOrderID: ''
        };
    }

    public onAssign(): void {
        this.assignEvent.emit(this.assignmentRequest);
    }

}