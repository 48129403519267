import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCENewsArticlePartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'lce-news-article-partial',
    templateUrl: './lce-news-article-partial.component.html',
    host: {class: 'xs-width-full'}
})
export class LCENewsArticlePartialComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCENewsArticlePartial;

    @Input() avatarSize?: 'default' | 'medium';

    @Input() showCode?: boolean;
    @Input() showDescription?: boolean;

    @Input() showAvatar?: boolean;
    @Input() forceShowIcon?: boolean;

    @Input() showOverlay?: boolean;
    @Input() viewArticle?: boolean;

    @Output() clickEvent = new EventEmitter<LCENewsArticlePartial>();

    @ViewChild('partialOverlay') detailOverlay: OverlayPanel;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');

        if (XSUtils.isEmpty(this.avatarSize)) this.avatarSize = 'default';
        if (XSUtils.isNull(this.showAvatar)) this.showAvatar = false;
        if (XSUtils.isNull(this.forceShowIcon)) this.forceShowIcon = false;

        if (XSUtils.isNull(this.showCode)) this.showCode = true;
        if (XSUtils.isNull(this.showDescription)) this.showDescription = false;

        if (XSUtils.isNull(this.showOverlay)) this.showOverlay = false;
        if (XSUtils.isNull(this.viewArticle)) this.viewArticle = true;
    }

    public onClick(event: any): void {
        if (!this.showOverlay) return;
        this.detailOverlay.toggle(event);
        this.clickEvent.emit(this.data);
    }
}
