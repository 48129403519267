import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceAuditFullService} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../constants/lce-core-endpoint.constant';
import {LCEMunicipality, LCEMunicipalityCreate, LCEMunicipalityPartial, LCEMunicipalitySearch, LCEMunicipalitySettings} from '../domain/lce-municipality';
import {LCEValidatorUtils} from '../utils/lce-validator-utils';
import {LCEHttpClientService} from './lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCEMunicipalityService extends XSPKResourceAuditFullService<LCEMunicipality, LCEMunicipalityPartial, LCEMunicipalitySearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.municipalities.index, 'municipality');
    }

    public updateSettings(municipalityID: string, fieldValueData: { [key: string]: any; }): Observable<LCEMunicipalitySettings> {
        XSAssert.notEmpty(municipalityID, 'municipalityID');
        XSAssert.notEmpty(fieldValueData, 'fieldValueData');
        console.log('update Settings fieldValueData', fieldValueData);
        return this.httpClientService.patch<LCEMunicipalitySettings>(this.buildEndpoint(municipalityID, LCE_CORE_ENDPOINTS.municipalities.settings), fieldValueData);
    }

    public retrieveSettings(municipalityID: string): Observable<LCEMunicipalitySettings> {
        return this.httpClientService.get<LCEMunicipalitySettings>(this.buildEndpoint(municipalityID, LCE_CORE_ENDPOINTS.municipalities.settings));
    }

    public create(municipalityCreate: LCEMunicipalityCreate): Observable<LCEMunicipality> {
        LCEValidatorUtils.validateMunicipalityCreate(municipalityCreate);
        return this.httpClientService.post<LCEMunicipality>(this.buildEndpoint(), municipalityCreate);
    }
}
