export enum LCEResourceType {
    CERTIFICATE_ORDER = 'certificateOrder',
    CERTIFICATE = 'certificate',
    SUGGESTION = 'suggestion',
    BIRTH_DECLARATION = 'birthDeclaration',
    NEWS_ARTICLE = 'newsArticle',
    SMARTCITY_ARTICLE = 'smartCityArticle',
    DISTRICT = 'district',
    MUNICIPALITY = 'municipality',
    FACILITY = 'facility',
    SERVICE_POINT = 'servicePoint',
    FACILITY_WORKER = 'facilityWorker',
    CUSTOMER = 'customer',
    MUNICIPAL_EMPLOYEE = 'municipalEmployee',
    EMPLOYEE = 'employee',
    DELIVERY_MAN = 'deliveryMan',
}

export enum LCESessionDuration {
    ONE_HOUR = '1',
    TWO_HOUR = '2',
    FOUR_HOUR = '4',
    EIGHT_HOURS = '8',
    TWELVE_HOURS = '12',
    TWENTY_FOUR_HOURS = '24',
}

export enum LCEIdentityDocumentType {
    PASSPORT = 'passport',
    NATIONAL_IDENTITY_CARD = 'nationalIdentityCard',
    DRIVER_LICENSE = 'driverLicense',
}

export enum LCERequestorType {
    COMMANDER_APP = 'commander',
    MUNICIPALITY_BACK_OFFICE_APP = 'municipalityBackOfficeApp',
    MUNICIPALITY_FRONT_OFFICE_APP = 'municipalityFrontOfficeApp',
    MUNICIPALITY_INTERNAL_APP = 'municipalityInternalApp',
    SERVICE_POINT_APP = 'servicePointApp',
    PUBLIC_MOBILE_APP = 'publicMobileApp',
    PUBLIC_WEBSITE_APP = 'publicWebsiteApp',
    PARTNER_APP = 'partnerApp',
    POSTMAN = 'postman',
    INTELLIJ_HTTP_CLIENT = 'intellijHTTPClient',
    WEBSITE_APP = 'websiteApp'
}

export interface LCEIDCode {
    id: string;
    code: string;
}
