<div class="xs-width-full xs-flex-column xs-position-relative xs-height-full lce-web-certificate-order-process-page {{ styleClass }}">

    <div class="xs-absolute-center-vh">
        <xs-loader
                [loaderId]="RETRIEVE_ORDER_CREATE_LOADER_ID"
                [loaderSize]="30">
        </xs-loader>
    </div>
    <div class="xs-min-height-600 xs-width-full">
        <div *ngIf="options.showBackButton" class="xs-flex-row xs-justify-content-end xs-mb-30">
            <xs-button (clickEvent)="backEvent.emit()" [icon]="ICON_BACK" [label]="options.backButtonLabel" styleClass="xs-sm-display-none" type="text"></xs-button>
        </div>

        <div *ngIf="hasRetrieveOrderCreateError() || !hasSaveOrderCreateError()" class="xs-width-full xs-flex-row xs-justify-content-center">
            <div *ngIf="hasRetrieveOrderCreateError() && !isRetrieveOrderCreateRunning()" class="xs-max-width-800 xs-mb-25">
                <xs-error
                        [error]="retrieveOrderCreateError"
                        [showErrorDetailButton]="canShowErrorActionButton()"
                        [showIcon]="true"
                        iconStyleClass="xs-color-warn"
                        message="lce.shared.certificateOrders.process.error.retrieveOrderCreateError"
                        messageStyleClass="xs-color-warn"
                        mode="inline">
                </xs-error>
            </div>

            <div *ngIf="hasSaveOrderCreateError() && !isSaveOrderCreateRunning()" class="xs-max-width-800 xs-mb-25">
                <xs-error
                        [error]="saveOrderCreateError"
                        [showErrorDetailButton]="canShowErrorActionButton()"
                        [showIcon]="true"
                        message="lce.shared.certificateOrders.process.error.saveOrderCreateFull"
                        mode="inline">
                </xs-error>
            </div>
        </div>

        <div class="xs-width-full xs-flex-column xs-align-items-center">

            <lce-certificate-order-create
                #orderCreateForm
                *ngIf="isCreateStep()"
                [canUseDesiredDeliveryTimeRange]="options.canUseDesiredDeliveryTimeRange"
                [data]="certificateOrderCreate"
                [facilityCode]="options.facilityCode"
                [forceShippingInternationalProvider]="options.forceShippingInternationalProvider"
                [loading]="isSaveOrderCreateRunning()"
                [numberOfCopiesColor]="options.parameters?.styles?.primaryColor"
                [showCardBorders]="options.parameters?.styles?.cardBorder"
                [useDelivery]="options.useDelivery"
                [useProcuration]="options.useProcuration">
            </lce-certificate-order-create>
            <div *ngIf="!isDialog()" class="xs-mt-20 xs-width-full">
                <div *ngTemplateOutlet="dFooter; context: {size: 'intermediate'}"></div>
            </div>
        </div>

    </div>
</div>

<ng-template #dFooter let-size="size">
    <div class="xs-width-full xs-flex xs-justify-content-space-between">
        <div>
            <xs-error
                    *ngIf="hasSaveOrderCreateError() && !isSaveOrderCreateRunning()"
                    [error]="saveOrderCreateError"
                    [showErrorDetailButton]="canShowErrorActionButton()"
                    [showIcon]="true"
                    message="lce.shared.certificateOrders.process.error.saveOrderCreateShort"
                    mode="inline"
                    styleClass="xs-xs-display-none xs-sm-display-none">
            </xs-error>
        </div>
        <div class="xs-flex-row">
            <xs-button
                    (clickEvent)="orderCreateForm.fillForm()"
                    *ngIf="options.showFillFormButton && isCreateStep()"
                    [labelColor]="options.parameters?.styles?.primaryColor"
                    [size]="size"
                    class="xs-mr-40"
                    label="Fill Form"
                    type="text">
            </xs-button>

            <xs-button
                    (clickEvent)="cancel()"
                    *ngIf="isCreateStep()"
                    [borderColor]="options.parameters?.styles?.secondaryColor"
                    [confirmation]="cancelConfirmation"
                    [labelColor]="options.parameters?.styles?.secondaryColor"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCancelConfirmation()"
                    [size]="size"
                    class="xs-mr-15"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.cancel"
                    styleClass="xs-color-secondary"
                    type="text">
            </xs-button>

            <xs-button
                    (clickEvent)="onNextStep()"
                    *ngIf="isCreateStep()"
                    [backgroundColor]="options.parameters?.styles?.primaryColor"
                    [disabled]="isSaveOrderCreateRunning()"
                    [label]="nextButtonLabel"
                    [loading]="isSaveOrderCreateRunning()"
                    [size]="size"
                    loaderPosition="bottom"
                    styleClass="xs-width-150"
                    type="button">
            </xs-button>

            <xs-button
                    (clickEvent)="closeDialog()"
                    *ngIf="isConfirmationStep()"
                    [backgroundColor]="options.parameters?.styles?.primaryColor"
                    [size]="size"
                    label="xs.core.label.close"
                    type="button">
            </xs-button>
        </div>
    </div>
</ng-template>


<ng-template #dHeader>
    <xs-ragnar
            [avatar]="{type: 'icon', data: CERTIFICATE_ORDER_ICON}"
            line1="lce.shared.certificateOrders.label.newOder"
            line1StyleClass="xs-font-weight-600"
            line2="lce.shared.certificateOrders.label.newOderDescription">
    </xs-ragnar>
</ng-template>