import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LCESharedModule} from '@lce/shared';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // --- * ---
        LCESharedModule
    ],
    declarations: [],
    exports: [
        RouterModule,
        CommonModule,
        // --- * ---
        LCESharedModule
        // --- * ---
    ]
})
export class LCECMDSharedModule {
}
