import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LCEDeliveryCompaniesFeatureService} from '@lce/shared';
import {LCECMDDeliveryCompaniesComponent} from './lce-cmd-delivery-companies.component';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';
import {LCECMDDeliveryCompanyRoutingModule} from './lce-cmd-delivery-companies.routing.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, LCECMDDeliveryCompanyRoutingModule],
    declarations: [LCECMDDeliveryCompaniesComponent],
    exports: [LCECMDDeliveryCompaniesComponent],
    providers: [LCEDeliveryCompaniesFeatureService]
})
export class LCECMDDeliveryCompaniesModule {
}