import {XSAddress, XSAddressStructured, XSTimeRange} from '@xs/base';
import {LCECertificateOrderDeliveryAssignmentTiny, LCEUserPartial} from '../public-api';
import {LCEFacilityPartial} from './facility/lce-facility';
import {LCEServicePointPartial} from './lce-service-point';

export interface LCEDelivery {
    destination: LCEDeliveryDestination;

    desiredDeliveryDate?: string;
    desiredDeliveryTimeRange?: XSTimeRange;

    estimatedDeliveryDate?: string;
    estimatedDeliveryTimeRange?: XSTimeRange;

    note?: string;

    signatureImage?: string;

    deliveredBy?: LCEUserPartial;
    deliveredOn?: string;

    updatedBy?: LCEUserPartial;
    updatedOn?: string;
    assignmentID?: string;
    assignment?: LCECertificateOrderDeliveryAssignmentTiny;
}

export enum LCEShippingInternationalProvider {
    DHL = 'dhl',
    UPS = 'ups'
}

export interface LCEDeliveryDestinationInternational extends LCEDeliveryDestination {
    type: LCEDeliveryDestinationType.INTERNATIONAL;
    address: XSAddressStructured;
    shippingProvider: LCEShippingInternationalProvider;
}

export interface LCEDeliveryDestinationCustomLocation extends LCEDeliveryDestination {
    type: LCEDeliveryDestinationType.CUSTOM_LOCATION;
    address: XSAddress;
    home?: boolean;
    office?: boolean;
}

export interface LCEDeliveryDestinationServicePoint<T = string | LCEServicePointPartial> extends LCEDeliveryDestination {
    type: LCEDeliveryDestinationType.SERVICE_POINT;
    servicePoint: T;
}

export interface LCEDeliveryDestinationFacility<T = string | LCEFacilityPartial> extends LCEDeliveryDestination {
    type: LCEDeliveryDestinationType.FACILITY;
    facility: T;
}

export interface LCEDeliveryDestination {
    type: LCEDeliveryDestinationType;
}

export enum LCEDeliveryDestinationType {
    FACILITY = 'facility',
    SERVICE_POINT = 'servicePoint',
    CUSTOM_LOCATION = 'customLocation',
    INTERNATIONAL = 'international'
}
