import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { XSAssert, XSBaseService, XSLanguage, XSValueResponse } from '@xs/base';
import { Observable } from 'rxjs';
import { LCE_CORE_ENDPOINTS } from '../../constants/lce-core-endpoint.constant';
import {
    LCEUserForgotPasswordSendCodeResponse,
    LCEUserForgotPasswordVerifyCodeResponse,
    LCEUserForgotPasswordVerifyUsernameResponse,
    LCEUserLockRequest,
    LCEUserLockUnlockResponse,
    LCEUserResetPasswordResponse,
    LCEUserType,
} from '../../domain/user/lce-user';
import { LCEVerificationType } from '../../domain/xs';
import { LCEHttpClientService } from '../lce-http-client.service';

@Injectable({ providedIn: 'root' })
export class LCEUserAccountService extends XSBaseService {
    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.userAccounts.index);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    public verifyUsername(userType: LCEUserType, username: string): Observable<LCEUserForgotPasswordVerifyUsernameResponse> {
        XSAssert.notEmpty(userType, 'userType');
        XSAssert.notEmpty(username, 'username');
        const params: HttpParams = new HttpParams().set('userType', userType).set('username', username.trim());
        return this.httpClientService.patch<LCEUserForgotPasswordVerifyUsernameResponse>(this.buildEndpoint(LCE_CORE_ENDPOINTS.userAccounts.verifyUsername), null, params);
    }

    public sendCode(sessionID: string, verificationType: LCEVerificationType, language: XSLanguage): Observable<LCEUserForgotPasswordSendCodeResponse> {
        XSAssert.notEmpty(sessionID, 'sessionID');
        XSAssert.notEmpty(verificationType, 'verificationType');
        XSAssert.notEmpty(language, 'language');
        const params: HttpParams = new HttpParams().set('sessionID', sessionID.trim()).set('verificationType', verificationType).set('language', language.trim());
        return this.httpClientService.patch<LCEUserForgotPasswordSendCodeResponse>(this.buildEndpoint(LCE_CORE_ENDPOINTS.userAccounts.sendCode), null, params);
    }

    public verifyCode(sessionID: string, code: string): Observable<LCEUserForgotPasswordVerifyCodeResponse> {
        XSAssert.notEmpty(sessionID, 'sessionID');
        XSAssert.notEmpty(code, 'code');
        const params: HttpParams = new HttpParams().set('sessionID', sessionID.trim()).set('code', code.trim());
        return this.httpClientService.patch<LCEUserForgotPasswordVerifyCodeResponse>(this.buildEndpoint(LCE_CORE_ENDPOINTS.userAccounts.verifyCode), null, params);
    }

    public updateForgotPassword(sessionID: string, newPassword: string): Observable<void> {
        XSAssert.notEmpty(sessionID, 'sessionID');
        XSAssert.notEmpty(newPassword, 'newPassword');
        const params: HttpParams = new HttpParams().set('sessionID', sessionID.trim()).set('newPassword', newPassword.trim());
        return this.httpClientService.patch<void>(this.buildEndpoint(LCE_CORE_ENDPOINTS.userAccounts.updateForgotPassword), null, params);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    public resetPassword(userID: string): Observable<LCEUserResetPasswordResponse> {
        XSAssert.notEmpty(userID, 'userID');
        return this.httpClientService.patch<LCEUserResetPasswordResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.userAccounts.resetPassword));
    }

    public updatePassword(userID: string, oldPassword: string, newPassword: string): Observable<XSValueResponse<string>> {
        XSAssert.notEmpty(userID, 'userID');
        XSAssert.notEmpty(oldPassword, 'oldPassword');
        XSAssert.notEmpty(newPassword, 'newPassword');
        const params: HttpParams = new HttpParams().set('oldPassword', oldPassword.trim()).set('newPassword', newPassword.trim());
        return this.httpClientService.patch<XSValueResponse<string>>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.userAccounts.updatePassword), null, params);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    public unlock(userID: string): Observable<LCEUserLockUnlockResponse> {
        XSAssert.notEmpty(userID, 'userID');
        return this.httpClientService.patch<LCEUserLockUnlockResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.userAccounts.unlock));
    }

    public lock(userID: string, lockRequest: LCEUserLockRequest): Observable<LCEUserLockUnlockResponse> {
        XSAssert.notEmpty(userID, 'userID');
        XSAssert.notEmpty(lockRequest, 'lockRequest');
        XSAssert.notEmpty(lockRequest.reason, 'lockRequest.reason');
        XSAssert.notEmpty(lockRequest.reason?.value, 'lockRequest.reason.value');
        return this.httpClientService.patch<LCEUserLockUnlockResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.userAccounts.lock), lockRequest);
    }
}
