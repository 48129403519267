import {Component, Input, OnInit} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCENewsArticlePartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSIvarAvatarType} from '@xs/core';
import {LCENewsArticleDialogService} from '../lce-news-article-dialog.service';

@Component({selector: 'lce-news-article-partial-overlay', templateUrl: './lce-news-article-partial-overlay.html', providers: [LCENewsArticleDialogService]})
export class LCENewsArticlePartialOverlay implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() data: LCENewsArticlePartial;

    @Input() viewArticle?: boolean;

    @Input() showAvatar?: boolean;

    @Input() showBorder?: boolean;

    avatar: { value?: string; type?: XSIvarAvatarType } = {};

    constructor(private newsDialogService: LCENewsArticleDialogService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');

        if (XSUtils.isNull(this.viewArticle)) this.viewArticle = false;

        if (XSUtils.isNull(this.data.coverImage)) {
            this.avatar.type = XSIvarAvatarType.ICON;
            this.avatar.value = this.ICON.news;
        } else {
            this.avatar.type = XSIvarAvatarType.IMAGE;
            this.avatar.value = this.data.coverImage!;
        }
    }

    public onViewRecordBtnClick() {
        this.newsDialogService.openArticleDialog({articleID: this.data.id});
    }
}
