import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { XSAssert, XSUtils } from '@xs/base';
import { XS_DATE_FORMAT_DD_MM_YYYY_HH_MM, XS_DATE_FORMAT_YYYY_MM_DD_HH_MM, XS_TR_BASE_GENDER } from '@xs/common';
import { XSDataManagerComponent, XSDataManagerOptions, XSDialogable } from '@xs/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';
import { LCEBirthDeclarationDelivery, LCEFacilityWorkerPartial } from '@lce/core';

@Component({ selector: 'lce-birth-declaration-delivery', templateUrl: './lce-birth-declaration-delivery.component.html' })
export class LCEBirthDeclarationDeliveryComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.delivery.';

    readonly DATE_FORMAT_EN = XS_DATE_FORMAT_YYYY_MM_DD_HH_MM;
    readonly DATE_FORMAT_FR = XS_DATE_FORMAT_DD_MM_YYYY_HH_MM;

    readonly TR_BASE_GENDER = XS_TR_BASE_GENDER;

    @Input() styleClass?: string;

    @Input() data: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>;
    @Input() birthDeclarationID?: string;

    @Input() showBorder?: boolean = true;

    @Input() showHeader?: boolean = true;

    @Input() readonly?: boolean;

    @Input() birthDeclarationCode: string;

    @Output() editEvent = new EventEmitter<LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>>();
    @Output() valueChange = new EventEmitter<LCEBirthDeclarationDelivery<any>>();

    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();

    dataManagerOptions: XSDataManagerOptions;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;
    @ViewChild('dHeader', { static: true }) headerTemplateRef: TemplateRef<any>;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.data = this.dialogConfig.data.delivery;
            this.readonly = this.dialogConfig.data.readonly;
            this.showBorder = this.dialogConfig.data.showBorder;
            this.birthDeclarationCode = this.dialogConfig.data.birthDeclarationCode;
            this.showHeader = this.dialogConfig.data.showHeader;
        }
        
        XSAssert.notNull(this.data);
        XSAssert.notNull(this.birthDeclarationCode);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public onEditDelivery(): void {
        this.editEvent.emit(this.data);
    }
}
