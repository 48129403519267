import {Injectable} from '@angular/core';
import {LCEFacilityPartial, LCEFacilityWorker} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEFacilityWorkerCreateUpdateComponent} from './create-update/lce-facility-worker-create-update.component';
import {LCEFacilityWorkerRecordComponent} from './record/lce-facility-worker-record.component';
import {XSTranslationService} from '@xs/common';

export interface LCEFacilityWorkerCreateUpdateDialogArg {
    facilityWorkerID?: string;
    facilityWorker?: LCEFacilityWorker;

    facility?: LCEFacilityPartial;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (facilityWorker?: LCEFacilityWorker) => void;
    onSave?: (facilityWorker?: LCEFacilityWorker) => void;
}

export interface LCEFacilityWorkerRecordDialogArg {
    facilityWorkerID?: string;
    facilityWorker?: LCEFacilityWorker;

    showEditButton?: boolean;
    onClose?: (facilityWorker?: LCEFacilityWorker) => void;
    onEdit?: (facilityWorker?: LCEFacilityWorker) => void;
}

@Injectable()
export class LCEFacilityWorkerDialogService {

    constructor(private translationService: XSTranslationService, private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEFacilityWorkerRecordDialogArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.facilityWorkerID) && XSUtils.isEmpty(arg.facilityWorker)) {
            throw new Error('arg.facilityWorkerID and arg.facilityWorker cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.facilityWorkerID) && !XSUtils.isEmpty(arg.facilityWorker)) {
            throw new Error('arg.facilityWorkerID and arg.facilityWorker cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                facilityWorkerID: arg.facilityWorkerID,
                facilityWorker: arg.facilityWorker,
                showEditButton: arg.showEditButton,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-width-500 xs-min-height-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-700'
        };

        const recordComponent: LCEFacilityWorkerRecordComponent = this.dialogService.openComponentDialog(LCEFacilityWorkerRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedFacilityWorker: LCEFacilityWorker) => {
            if (arg?.onClose) arg!.onClose!(savedFacilityWorker);
        });
        recordComponent.editEvent.subscribe((facilityWorkerRecord: LCEFacilityWorker) => {
            if (arg?.onEdit) arg.onEdit(facilityWorkerRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    facilityWorker: facilityWorkerRecord,
                    onClose: (facilityWorker) => this.openRecordDialog({facilityWorker: facilityWorker, showEditButton: true}),
                    onSave: (facilityWorker) => this.openRecordDialog({facilityWorker: facilityWorker, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEFacilityWorkerCreateUpdateDialogArg): void {
        if (!XSUtils.isEmpty(arg?.facilityWorkerID) && !XSUtils.isEmpty(arg?.facilityWorker)) {
            throw new Error('arg.facilityWorkerID and arg.facilityWorker cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                facilityWorkerID: arg?.facilityWorkerID,
                facilityWorker: arg?.facilityWorker,
                facility: arg?.facility,
                styleClass: 'xs-width-full ',
                loadingStyleClass: 'xs-min-width-500 xs-min-height-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-600'
        };

        const createUpdateComponent: LCEFacilityWorkerCreateUpdateComponent = this.dialogService.openComponentDialog(LCEFacilityWorkerCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((facilityWorker: LCEFacilityWorker) => {
            if (arg?.onClose) arg!.onClose!(facilityWorker);
        });
        createUpdateComponent.saveEvent.subscribe((facilityWorker: LCEFacilityWorker) => {
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(facilityWorker);
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({facilityWorker: facilityWorker});
        });
    }
}
