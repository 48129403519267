<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card {{ styleClass }}">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-ivar
                line1="lce.shared.certificateOrderRecord.general"
                line1StyleClass="xs-color-secondary xs-font-size-default-imp">
        </xs-ivar>
    </div>
    <div [ngClass]="{ 'xs-pt-30-imp': showHeader }" class="xs-card-content">
        <div class="grid xs-width-full xs-min-height-225">
            <div class="col-3 xs-flex-row-center">
                <div class="xs-flex-column-center {{ leftSideStyleClass }}">
                    <i [style.font-size]="'75px'" class="xs-display-block xs-color-secondary {{ deliveryIcon }}"></i>
                </div>
            </div>
            <div class="col-9 xs-border-left-discrete xs-pl-30-imp">
                <div class="grid xs-fields xs-fields-spacer-20">
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'destination'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <lce-delivery-destination
                                        [data]="data.destination"
                                        [full]="true"
                                        [showOverlay]="true">
                                </lce-delivery-destination>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label label="xs.core.audit.updatedOn"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <lce-user-resource-audit-inline [by]="data.updatedBy" [on]="data.updatedOn">
                                </lce-user-resource-audit-inline>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'desiredDeliveryDate'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <xs-data-calendar *ngIf="data.desiredDeliveryDate" [options]="calendarOptions"
                                                  [value]="data.desiredDeliveryDate!" valueStyleClass="xs-font-size-intermediate">
                                </xs-data-calendar>
                                <span *ngIf="!data.desiredDeliveryDate" class="xs-data-field-empty-value">---</span>

                                <span *ngIf="data.desiredDeliveryTimeRange"
                                      class="xs-display-block xs-color-secondary xs-font-size-intermediate xs-mt-3">
                                        {{ 'lce.core.label.between' | translate }} {{ data.desiredDeliveryTimeRange!.start }}
                                    {{ 'lce.core.label.and' | translate }} {{ data.desiredDeliveryTimeRange!.end }}
                                    </span>
                                <span *ngIf="!data.desiredDeliveryTimeRange" class="xs-data-field-empty-value xs-mt-3">---</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'estimatedDeliveryDate'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <xs-data-calendar
                                        *ngIf="data.estimatedDeliveryDate"
                                        [options]="calendarOptions"
                                        [value]="data.estimatedDeliveryDate!" valueStyleClass="xs-font-size-intermediate">
                                </xs-data-calendar>

                                <span *ngIf="!data.estimatedDeliveryDate" class="xs-data-field-empty-value">---</span>

                                <span *ngIf="data.estimatedDeliveryTimeRange"
                                      class="xs-display-block xs-color-secondary xs-font-size-intermediate xs-mt-3">
                                        {{ 'lce.core.label.between' | translate }} {{ data.estimatedDeliveryTimeRange!.start }}
                                    {{ 'lce.core.label.and' | translate }} {{ data.estimatedDeliveryTimeRange!.end }}
                                    </span>
                                <span *ngIf="!data.estimatedDeliveryTimeRange" class="xs-data-field-empty-value xs-mt-3">---</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'deliveredOn'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <lce-user-resource-audit-inline [by]="data.deliveredBy" [on]="data.deliveredOn"></lce-user-resource-audit-inline>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'signature'"></xs-data-field-label>
                            <div class="xs-data-field-value-container xs-max-width-300">
                                <div *ngIf="data.signatureImage | xsIsNotEmpty" class="xs-container-frame-discrete">
                                    <xs-image [src]="data.signatureImage!" alt="Signature">
                                    </xs-image>
                                </div>
                                <span *ngIf="data.signatureImage | xsIsEmpty" class="xs-data-field-empty-value">---</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <xs-data-field-textarea [label]="TR_BASE + 'note'" [required]="false" [value]="data.note" valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>