import {XSAssert, XSUtils} from '@xs/base';
import {LCESmartCityArticle} from '@lce/core';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {Injectable} from '@angular/core';
import {LCESmartCityArticleDetailComponent} from './article-detail/lce-smartcity-article-detail.component';

export interface LCESmartCityDialogDetailArg {
    articleID?: string;
    articleCode?: string;
    article?: LCESmartCityArticle;
    onClose?: (smartcityArticle?: LCESmartCityArticle) => void;
}

@Injectable()
export class LCESmartCityArticleDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openArticleDialog(arg: LCESmartCityDialogDetailArg): void {
        XSAssert.notEmpty(arg, 'arg');

        if (XSUtils.isEmpty(arg.articleCode) && XSUtils.isEmpty(arg.articleID) && XSUtils.isEmpty(arg.article)) {
            throw new Error('arg.articleCode and arg.articleID and arg.article cannot both be empty at the same time.');
        }
        if (XSUtils.isEmpty(arg.articleCode) && !XSUtils.isEmpty(arg.articleID) && !XSUtils.isEmpty(arg.article)) {
            throw new Error('arg.articleCode and arg.articleID and arg.article cannot both be set at the same time.');
        }

        const dConfig: XSDialogConfig = {
            data: {
                articleID: arg.articleID,
                articleCode: arg.articleCode,
                article: arg.article,
                loadingStyleClass: 'xs-min-width-500 xs-min-height-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1200'
        };

        const detailComponent: LCESmartCityArticleDetailComponent = this.dialogService.openComponentDialog(LCESmartCityArticleDetailComponent, dConfig);

        detailComponent.closeEvent.subscribe((savedSmartcityArticle: LCESmartCityArticle) => {
            if (arg?.onClose) arg!.onClose!(savedSmartcityArticle);
        });
    }
}
