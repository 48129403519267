import {Component, Input, OnInit} from '@angular/core';
import {LCEFacilityTownHallStampTokenConsumption, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {XSAssert} from '@xs/base';
import {LCESharedService} from '../../../api/services/lce-shared.service';

@Component({selector: 'lce-facility-town-hall-stamp-token-consumption', templateUrl: './lce-facility-town-hall-stamp-token-consumption.component.html'})
export class LCEFacilityTownHallStampTokenConsumptionComponent implements OnInit {

    readonly TR_BASE = 'lce.shared.facility.townHallStampToken.';

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;

    @Input() data: LCEFacilityTownHallStampTokenConsumption<LCEUserMunicipalEmployeePartial>;

    constructor(private sharedService: LCESharedService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public openRecord(orderID: string): void {
        console.log('openRecord: ', orderID);
        this.sharedService.emitOpenCertificateOrderDialog({orderID: orderID, stampTokenClickable: false});
    }
}
