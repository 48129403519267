import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEFacilityPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {OverlayPanel} from 'primeng/overlaypanel';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-partial',
    templateUrl: './lce-facility-partial.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEFacilityPartialComponent implements OnInit {

    readonly ICON_FACILITY = LCE_SHARED_ICON.facility;

    @Input() styleClass?: string;
    @Input() fullNameStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() labelStyleClass?: string;
    @Input() label?: string;
    @Input() showDefaultLabel?: boolean;

    @Input() showBorder?: boolean;

    @Input() data: LCEFacilityPartial;

    @Input() showCode?: boolean;

    @Input() showIcon?: boolean;

    @Input() showOverlay?: boolean;
    @Input() viewRecord?: boolean;

    @Input() intermediate?: boolean;

    @Output() clickEvent = new EventEmitter<LCEFacilityPartial>();

    @ViewChild('detailOverlay') detailOverlay: OverlayPanel;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.showCode)) this.showCode = false;
        if (XSUtils.isNull(this.showOverlay)) this.showOverlay = false;
        if (XSUtils.isNull(this.viewRecord)) this.viewRecord = true;
        if (XSUtils.isNull(this.intermediate)) this.intermediate = true;
        if (this.showDefaultLabel) this.label = 'lce.core.label.facility';
    }

    public onClick(event: any): void {
        if (!this.showOverlay) return;
        this.detailOverlay.toggle(event);
        this.clickEvent.emit(this.data);
    }
}
