<div class="{{ styleClass }}">
	<lce-user-avatar [data]="user" avatarSize="medium" [clickable]="true" [showStatus]="false"
		(avatarClickEvent)="userOverlay.toggle($event)">
	</lce-user-avatar>

	<p-overlayPanel #userOverlay styleClass="lce-template-sheldon-user-menu-overlay">
		<div class="xs-flex-column">
			<lce-user-detail-head [data]="user"></lce-user-detail-head>
			
			<div class="xs-flex-column xs-width-full xs-mt-20 xs-pt-20 xs-border-top-discrete">
			
				<div class="xs-flex-row xs-plr-40 xs-justify-content-space-between">
					<xs-button type="icon" [icon]="ICON.user" iconStyleClass="xs-font-size-medium-imp"
						(clickEvent)="openAccountDialog()">
					</xs-button>
					<xs-button type="icon" [icon]="ICON.setting" iconStyleClass="xs-font-size-medium-imp"
						(clickEvent)="openSettingsDialog()">
					</xs-button>
					<xs-button type="icon" [icon]="ICON.logout" iconStyleClass="xs-font-size-medium-imp" (clickEvent)="onLogout()">
					</xs-button>
				</div>

			</div>
		</div>
	</p-overlayPanel>
</div>
