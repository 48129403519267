<div class="{{styleClass}}">
    <div class="grid">
        <div *ngIf="canShow?.deactivate" class="col">
            <xs-input-checkbox
                    (valueChange)="onPermissionChange('deactivate', $event)"
                    [binary]="true"
                    [value]="data!.deactivate!"
                    label="{{ TR_BASE }}deactivate"
                    labelStyleClass="xs-font-size-intermediate">
            </xs-input-checkbox>
        </div>
        <div *ngIf="canShow?.activate" class="col">
            <xs-input-checkbox
                    (valueChange)="onPermissionChange('activate', $event)"
                    [binary]="true"
                    [value]="data!.activate!"
                    label="{{ TR_BASE }}activate"
                    labelStyleClass="xs-font-size-intermediate">
            </xs-input-checkbox>
        </div>
        <div *ngIf="canShow?.restore" class="col">
            <xs-input-checkbox
                    (valueChange)="onPermissionChange('restore', $event)"
                    [(value)]="data!.restore!"
                    [binary]="true"
                    label="{{ TR_BASE }}restore"
                    labelStyleClass="xs-font-size-intermediate">
            </xs-input-checkbox>
        </div>
        <div *ngIf="canShow?.purge" class="col">
            <xs-input-checkbox
                    (valueChange)="onPermissionChange('purge', $event)"
                    [binary]="true"
                    [value]="data!.purge!"
                    label="{{ TR_BASE }}purge"
                    labelStyleClass="xs-font-size-intermediate">
            </xs-input-checkbox>
        </div>
    </div>
</div>

<div class="grid">
    <div class="col">
        <xs-input-checkbox
                (valueChange)="onPermissionChange('deactivate', $event)"
                [binary]="true"
                [value]="data!.deactivate!"
                label="{{ TR_BASE }}deactivate"
                labelStyleClass="xs-font-size-intermediate">
        </xs-input-checkbox>
    </div>
    <div class="col">
        <xs-input-checkbox
                (valueChange)="onPermissionChange('activate', $event)"
                [binary]="true"
                [value]="data!.activate!"
                label="{{ TR_BASE }}activate"
                labelStyleClass="xs-font-size-intermediate">
        </xs-input-checkbox>
    </div>
    <div class="col">
        <xs-input-checkbox
                (valueChange)="onPermissionChange('delete', $event)"
                [binary]="true"
                [value]="data!.delete!"
                label="{{ TR_BASE }}delete"
                labelStyleClass="xs-font-size-intermediate">
        </xs-input-checkbox>
    </div>
    <div class="col">
        <xs-input-checkbox
                (valueChange)="onPermissionChange('restore', $event)"
                [(value)]="data!.restore!"
                [binary]="true"
                label="{{ TR_BASE }}restore"
                labelStyleClass="xs-font-size-intermediate">
        </xs-input-checkbox>
    </div>
    <div class="col">
        <xs-input-checkbox
                (valueChange)="onPermissionChange('purge', $event)"
                [binary]="true"
                [value]="data!.purge!"
                label="{{ TR_BASE }}purge"
                labelStyleClass="xs-font-size-intermediate">
        </xs-input-checkbox>
    </div>
</div>
