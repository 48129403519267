import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCE_CORE_ENDPOINTS, LCEHttpClientService, LCEUserMunicipalEmployeeAuthenticationService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLocalStorageService} from '@xs/core';
import {LCECMDInitialDataService} from './lce-cmd-initial-data.service';
import {LCECMDContextService} from './lce-cmd-context.service';
import {LCECMDAppInitialData} from '../domain/lce-cmd-app-initial-data';

@Injectable({providedIn: 'root'})
export class LCECMDUserAuthenticationService extends LCEUserMunicipalEmployeeAuthenticationService {

    constructor(
        protected router: Router,
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCECMDContextService,
        private localStorageService: XSLocalStorageService,
        private initialDataService: LCECMDInitialDataService) {
        super(router, httpClientService, contextService, LCE_CORE_ENDPOINTS.initialization.cmd);
        this.listen();
    }

    protected async storageRemove(key: string): Promise<void> {
        this.localStorageService.remove(key);
    }

    protected async storageSetValue(key: string, value: any): Promise<void> {
        if (XSUtils.isObject(value)) {
            this.localStorageService.setObject(key, value);
        } else this.localStorageService.setItem(key, value);
    }

    protected async storageGetValue(key: string): Promise<string | undefined> {
        return this.localStorageService.getItem(key);
    }

    private listen(): void {
        this.onFetchInitialData.subscribe(initialData => {
            this.initialDataService.initialize(initialData as LCECMDAppInitialData).then();
        });
        this.onLogout.subscribe(() => {
            this.initialDataService.logout();
            console.log('[LCEMBOUserAuthenticationService] Logout ...');
        });
    }
}
