<div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full xs-pt-2 {{styleClass}}">
    <xs-ivar
            [line1]="TR_BASE + 'recordName' | translate : { dateStr: (data.createdOn! | xsDate : 'xsMediumDateTime') }"
            [line2]="data.code | uppercase"
            [line3]="TR_BASE + 'batchProcessingCompleted.' + data.completed | translate"
            line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500"
            line2StyleClass="xs-color-secondary xs-font-size-small-imp"
            line3StyleClass="xs-color-secondary">
    </xs-ivar>
    <xs-square-data
            [bottomText]="TR_BASE + 'orders'"
            [indicatorIcon]="data.completed ? ICON.thumbsUp : ICON.inProgress"
            [indicator]="true"
            [showBorder]="false"
            indicatorColorStyleClass="{{ data.completed ? 'xs-color-primary' : 'xs-color-secondary' }}"
            indicatorStyleClass="xs-top-minus-12 xs-right-minus-18"
            value="{{ data.size }}">
    </xs-square-data>
</div>
