<div [style.filter]="'brightness(106%)'" class="xs-width-full xs-flex-column-center xs-background-color-discrete xs-border-radius-default xs-p-30 {{styleClass}}">
    <div class="xs-flex-row xs-justify-content-space-between xs-width-full xs-mb-30">
        <div>
            <span class="xs-display-block xs-font-weight-600">{{ TR_BASE + 'title' | translate }}</span>
            <span *ngIf="data | xsIsNotEmpty" class="xs-display-block xs-font-size-small">{{ TR_BASE + 'subTitle' | translate : {municipalityName: data!.context.municipalityFullName} }}
                - {{ 'lce.core.certificateTypeMedium.' + data!.context.certificateType | translate }}</span>
        </div>
        <div *ngIf="(data | xsIsNotEmpty)" class="xs-width-100">
            <xs-input-dropdown
                    (valueChange)="onCurrencyChange($event)"
                    [(value)]="currencySelected"
                    [items]="currencies"
                    [options]="currenciesOptions">
            </xs-input-dropdown>
        </div>
    </div>
    <div class="xs-background-white xs-position-relative xs-plr-15 xs-pt-25 xs-pb-10 xs-border-radius-default xs-width-full xs-min-height-325">
        <div class="xs-absolute-center-vh">
            <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="!loader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full xs-p-15">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showActionButton]="true"
                    [showErrorDetailButton]="true"
                    message="xs.core.label.httpErrorMessage"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>

        <div
                *ngIf="(data | xsIsEmpty) && showDefaultMessage &&!loader.isRunning()"
                class="xs-absolute-center-vh">
            <xs-floky
                    [line1]="defaultMessage!.summary"
                    [line2]="defaultMessage!.detail!"
                    [showBorder]="false"
                    [showIcon]="true"
                    iconName="information">
            </xs-floky>
        </div>

        <div *ngIf="!loader.isRunning() && !hasError() && (data | xsIsNotEmpty)" class="grid">
            <div class="col-6">
                <xs-data-field-label
                        label="{{TR_BASE + 'copies'  | translate : { numberOfCopies: data!.numberOfCopies } }}"
                        labelStyleClass="xs-font-weight-600" styleClass="xs-mb-15"
                        subLabel="{{ TR_BASE + 'copiesSubTitle' | translate : { unitPrice: data!.unitPrice.value } }}"
                        subLabelStyleClass="xs-font-size-small">
                </xs-data-field-label>
            </div>
            <div class="col-6 xs-flex xs-justify-content-end">
                <xs-amount [data]="data!.priceOfCopies" styleClass="xs-font-weight-500 xs-text-primary-color"></xs-amount>
            </div>
            <ng-container *ngIf="data!.deliveryCost | xsIsNotEmpty">
                <div class="col-6">
                    <xs-data-field-label
                            [label]="TR_BASE + 'delivery'"
                            labelStyleClass="xs-font-weight-600"
                            styleClass="xs-mb-15"
                            subLabel=" {{ data!.deliveryDistance ? (TR_BASE + 'deliverySubTitle' | translate : { deliveryDistance: data!.deliveryDistance}) : undefined }}"
                            subLabelStyleClass="xs-font-size-small">
                    </xs-data-field-label>
                </div>
                <div class="col-6 xs-flex xs-justify-content-end">
                    <xs-amount [data]="data!.deliveryCost!" size="small" styleClass="xs-font-weight-500 xs-text-primary-color"></xs-amount>
                </div>
            </ng-container>

            <ng-container *ngIf="data!.tax | xsIsNotEmpty">
                <div class="col-6">
                    <xs-data-field-label [label]="TR_BASE + 'tax'" labelStyleClass="xs-font-weight-600" size="small" styleClass="xs-mb-15"></xs-data-field-label>
                </div>
                <div class="col-6 xs-flex xs-justify-content-end">
                    <xs-amount [data]="data!.tax!" size="small" styleClass="xs-font-weight-500 xs-text-primary-color"></xs-amount>
                </div>
            </ng-container>

            <ng-container *ngIf="data!.totalFees | xsIsNotEmpty">
                <div class="col-6">
                    <xs-data-field-label [label]="TR_BASE + 'fees'" labelStyleClass="xs-font-weight-600" styleClass="xs-mb-15"></xs-data-field-label>
                </div>
                <div class="col-6 xs-flex xs-justify-content-end">
                    <xs-amount [data]="data!.totalFees!" size="intermediate" styleClass="xs-font-weight-500 xs-text-primary-color">
                    </xs-amount>
                </div>
            </ng-container>

            <div class="xs-width-full xs-display-block xs-border-top-discrete xs-mt-10 xs-mb-20"></div>
            <div class="col-6">
                <xs-data-field-label [label]="TR_BASE + 'total'" labelStyleClass="xs-font-weight-600" styleClass="xs-mb-15"></xs-data-field-label>
            </div>
            <div class="col-6 xs-flex xs-justify-content-end">
                <xs-amount [data]="data!.total" size="medium" styleClass="xs-font-weight-500 xs-text-primary-color"></xs-amount>
            </div>
        </div>

    </div>
</div>