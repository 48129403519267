import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceCodeAuditFullService, XSStringResponse} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {
    LCEFacilityTownHallStampToken,
    LCEFacilityTownHallStampTokenCreate,
    LCEFacilityTownHallStampTokenDetail,
    LCEFacilityTownHallStampTokenPartial,
    LCEFacilityTownHallStampTokenSearch
} from '../../domain/facility/lce-facility-town-hall-stamp-token';
import {LCEHttpClientService} from '../lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCEFacilityTownHallStampTokenService extends XSPKResourceCodeAuditFullService<LCEFacilityTownHallStampToken, LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.tokens.index, 'facilityStampToken');
    }

    public deleteLast(): Observable<XSStringResponse> {
        return this.httpClientService.delete<XSStringResponse>(this.buildEndpoint('last'));
    }

    public create(stampTokenCreate: LCEFacilityTownHallStampTokenCreate): Observable<LCEFacilityTownHallStampTokenDetail> {
        XSAssert.notEmpty(stampTokenCreate, 'stampTokenCreate');
        return this.httpClientService.post<LCEFacilityTownHallStampTokenDetail>(this.buildEndpoint(), stampTokenCreate);
    }
}
