<div class="{{styleClass}}">
    <xs-title
            [expandCollapseEnable]="true"
            [expandCollapseTarget]="auditContainer"
            styleClass="xs-pb-30"
            subTextStyleClass="xs-font-size-small-imp xs-color-secondary xs-mt-1-imp"
            text="Audit"
            textStyleClass="xs-font-weight-500">
        <ng-template xsTemplate="rightSection">
            <div class="xs-flex-row xs-align-items-center xs-mlr-15">
                <xs-input-checkbox
                        [(value)]="allAuditSelected"
                        [binary]="true"
                        label="{{ TR_BASE }}{{ allAuditSelected ? 'deselectAll' : 'selectAll' }}"
                        labelStyleClass="xs-font-size-intermediate"
                        styleClass="xs-width-150">
                </xs-input-checkbox>
            </div>
        </ng-template>
    </xs-title>

    <div #auditContainer class="xs-mb-50">
        <xs-authorization-base
                (changeEvent)="handleAuditChange($event)"
                (checkedEvent)="handleAuditChecked($event)"
                [allAuditSelected]="allAuditSelected"
                [canShow]="canShow">
        </xs-authorization-base>
    </div>

    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="searchContainer" styleClass="xs-mb-15" text="Search" textStyleClass="xs-font-weight-500">
        <ng-template xsTemplate="rightSection">
            <div class="xs-flex-row xs-align-items-center xs-mlr-15">
                <xs-input-checkbox
                        [(value)]="selectAllSearch"
                        [binary]="true"
                        label="{{ TR_BASE }}{{ selectAllSearch ? 'deselectAll' : 'selectAll' }}"
                        labelStyleClass="xs-font-size-intermediate"
                        styleClass="xs-width-150">
                </xs-input-checkbox>
            </div>
        </ng-template>
    </xs-title>

    <div #searchContainer class="grid xs-mb-50">
        <lce-authorization-search></lce-authorization-search>
    </div>

    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="dashboardContainer" styleClass="xs-mb-15" text="Dashboard" textStyleClass="xs-font-weight-500">
        <ng-template xsTemplate="rightSection">
            <div class="xs-flex-row xs-align-items-center xs-mlr-15">
                <xs-input-checkbox
                        [(value)]="selectAllDashboard"
                        [binary]="true"
                        label="{{ TR_BASE }}{{ selectAllDashboard ? 'deselectAll' : 'selectAll' }}"
                        labelStyleClass="xs-font-size-intermediate"
                        styleClass="xs-width-150">
                </xs-input-checkbox>
            </div>
        </ng-template>
    </xs-title>
    <div #dashboardContainer class="grid">
        <lce-authorization-dashboard></lce-authorization-dashboard>
    </div>
</div>