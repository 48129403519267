import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceService, XSUtils} from '@xs/base';
import {Observable, of} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCE_FACILITY_PARTIAL_YOPOUGON} from '../../constants/lce-core-fill-form-data.constant';
import {LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadCreate, LCEFacilityTownHallStampLoadPartial, LCEFacilityTownHallStampLoadSearch} from '../../domain/facility/lce-facility-town-hall-stamp-load';
import {LCEFacilityTownHallCertificateOrderRegistrationCreate} from '../../domain/facility/registration/lce-facility-townhall-certificate-order-registration-create';
import {LCEFacilityTownHallCertificateOrderRegistrationResponse} from '../../domain/facility/registration/lce-facility-townhall-certificate-order-registration-response';
import {LCEHttpClientService} from '../lce-http-client.service';

const BASE_ENDPOINT = LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads;

@Injectable({providedIn: 'root'})
export class LCEFacilityTownHallStampLoadService extends XSPKResourceService<LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadPartial, LCEFacilityTownHallStampLoadSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, BASE_ENDPOINT.index, 'facilityStampLoad');
    }

    public create(facilityTownHallStampLoadCreate: LCEFacilityTownHallStampLoadCreate): Observable<LCEFacilityTownHallStampLoad> {
        XSAssert.notEmpty(facilityTownHallStampLoadCreate, 'facilityTownHallStampLoadCreate');
        return this.httpClientService.post<LCEFacilityTownHallStampLoad>(this.buildEndpoint(), facilityTownHallStampLoadCreate);
    }

    public findLastStampLoad(facilityID: string): Observable<LCEFacilityTownHallStampLoadPartial> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        return of({
            id: XSUtils.uuid(),
            createdOn: new Date().toDateString(),
            size: XSUtils.randomInteger(500, 2000),
            facility: LCE_FACILITY_PARTIAL_YOPOUGON
        });
    }

    public unregister(facilityID: string): Observable<LCEFacilityTownHallCertificateOrderRegistrationResponse> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        return this.httpClientService.patch<LCEFacilityTownHallCertificateOrderRegistrationResponse>(BASE_ENDPOINT.unregister.replace('{facilityID}', facilityID));
    }

    public register(facilityID: string): Observable<LCEFacilityTownHallCertificateOrderRegistrationResponse> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        return this.httpClientService.patch<LCEFacilityTownHallCertificateOrderRegistrationResponse>(BASE_ENDPOINT.register.replace('{facilityID}', facilityID));
    }

    public updateRegistration(facilityID: string, fieldValueMap: Map<String, Object>): Observable<LCEFacilityTownHallCertificateOrderRegistrationResponse> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(fieldValueMap, 'fieldValueMap');
        return this.httpClientService.patch<LCEFacilityTownHallCertificateOrderRegistrationResponse>(BASE_ENDPOINT.registration.replace('{facilityID}', facilityID), fieldValueMap);
    }

    public createRegistration(facilityID: string, registrationCreate: LCEFacilityTownHallCertificateOrderRegistrationCreate): Observable<LCEFacilityTownHallCertificateOrderRegistrationResponse> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(registrationCreate, 'registrationCreate');
        return this.httpClientService.post<LCEFacilityTownHallCertificateOrderRegistrationResponse>(BASE_ENDPOINT.registration.replace('{facilityID}', facilityID), registrationCreate);
    }
}
