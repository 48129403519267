import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {LCECertificateOrderGlobalStatPartial, LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateOrderStatus, LCECertificateType} from '@lce/core';
import {XS_STR_SPACE, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XSStyleHelper} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';
import {LCECertificateOrderBatchProcessesStateComponent} from '../../certificate-order-batch-process/lce-certificate-order-batch-processes-state.component';
import {LCECertificateOrderLastComponent} from '../../certificate-order/last/lce-certificate-order-last.component';
import {LCECertificateOrdersPendingOrdersSquareComponent} from '../../certificate-order/pending-orders/lce-certificate-orders-pending-orders-square.component';
import {LCEStatisticBox} from '../../statistic/lce-statistic';
import {LCECertificateOrdersFeatureService} from '../../certificate-order/lce-certificate-orders-feature.service';
import {LCECertificateOrderDataBoxesComponent} from '../../certificate-order/data-boxes/lce-certificate-order-data-boxes.component';

@Component({
    selector: 'lce-dashboard-certificate-order-stats',
    templateUrl: 'lce-dashboard-certificate-order-stats.component.html',
    providers: [LCECertificateOrdersFeatureService]
})
export class LCEDashboardCertificateOrderStatsComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.';

    readonly CERTIFICATE_TYPE = LCECertificateType;

    @Input() styleClass?: string;
    @Input() municipalityCode?: string;
    @Input() facilityCode: string;

    @ViewChild('pendingOrdersSquare') pendingOrdersSquare: LCECertificateOrdersPendingOrdersSquareComponent;
    @ViewChild('lastOrder') lastOrder: LCECertificateOrderLastComponent;
    @ViewChild('batchProcessesState') batchProcessesState: LCECertificateOrderBatchProcessesStateComponent;
    @ViewChild('dataBoxes') dataBoxes: LCECertificateOrderDataBoxesComponent;

    statisticLoading: boolean = false;

    statData: {
        totalNumberOfOrders: LCEStatisticBox;
        birthCertificateOrders: LCEStatisticBox;
        marriageCertificateOrders: LCEStatisticBox;
        deathCertificateOrders: LCEStatisticBox;
    };

    predefinedPeriod: XSPredefinedPeriod = XSPredefinedPeriod.THIS_WEEK;

    error: any;

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private certificateOrderService: LCECertificateOrderService,
        private eventProcessorService: LCEEventProcessorService) {
    }

    ngOnInit(): void {
        this.buildStats();
        this.retrieveStats();

        this.subscription.add(
            this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.retrieveStats())
        );
    }

    public handleDataBoxesCOrderMainValueEvent(event: { certificateType?: LCECertificateType; predefinedPeriod?: XSPredefinedPeriod }): void {
        this.router.navigate(['/certificate-orders'],
            {
                state: {
                    certificateType: event.certificateType,
                    predefinedPeriod: event.predefinedPeriod,
                    municipalityCode: this.municipalityCode,
                    status: LCECertificateOrderStatus.PENDING
                }
            })
            .then();
    }

    public onPendingOrdersClick(): void {
        this.router.navigate(['/certificate-orders'],
            {
                state: {
                    municipalityCode: this.municipalityCode,
                    status: LCECertificateOrderStatus.PENDING
                }
            })
            .then();
    }

    public onStatClick(certificateType?: LCECertificateType): void {
        this.router.navigate(['/certificate-orders'],
            {
                state: {
                    municipalityCode: this.municipalityCode,
                    predefinedPeriod: this.predefinedPeriod,
                    status: undefined,
                    certificateType: certificateType
                }
            })
            .then();
    }

    public hasError() {
        return !XSUtils.isEmpty(this.error);
    }

    public update(): void {
        this.pendingOrdersSquare.update();
        this.lastOrder.update();
        this.batchProcessesState.update();
        this.dataBoxes.update();
        this.retrieveStats();
    }

    private retrieveStats(): void {
        const statsSearch: LCECertificateOrderSearch = {
            createdOnPredefinedPeriod: this.predefinedPeriod
        };
        if (!XSUtils.isEmpty(this.municipalityCode)) statsSearch.municipalityCodes = [this.municipalityCode!];

        this.statisticLoading = true;
        this.error = undefined;
        this.subscription.add(
            this.certificateOrderService.globalStats(statsSearch)
                .pipe(finalize(() => this.statisticLoading = false))
                .subscribe({
                    next: stats => {
                        this.updateStatisticBox(this.predefinedPeriod, stats.total, this.statData.totalNumberOfOrders);
                        this.updateStatisticBox(this.predefinedPeriod, stats.birth, this.statData.birthCertificateOrders);
                        this.updateStatisticBox(this.predefinedPeriod, stats.marriage, this.statData.marriageCertificateOrders);
                        this.updateStatisticBox(this.predefinedPeriod, stats.death, this.statData.deathCertificateOrders);
                    },
                    error: error => this.error = error
                })
        );
    }

    private buildStats(): void {
        const variationValueStyleClass = XSStyleHelper.CLASS.fontWeight.medium;
        const additionalInfoLine1StyleClass = XSStyleHelper.CLASS.fontWeight.normal + XS_STR_SPACE + XSStyleHelper.CLASS.color.secondary + XS_STR_SPACE + XSStyleHelper.CLASS.fontSize.largeImp;
        this.statData = {
            totalNumberOfOrders: {
                period: this.predefinedPeriod,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.total',
                additionalInfo: {
                    line1: 'T',
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            birthCertificateOrders: {
                period: this.predefinedPeriod,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.birth',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.BIRTH,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            marriageCertificateOrders: {
                period: this.predefinedPeriod,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.marriage',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.MARRIAGE,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            deathCertificateOrders: {
                period: this.predefinedPeriod,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.death',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.DEATH,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            }
        };
    }

    private updateStatisticBox(predefinedPeriod: XSPredefinedPeriod, partialStats: LCECertificateOrderGlobalStatPartial, statisticBox: LCEStatisticBox): void {
        statisticBox.period = predefinedPeriod;
        statisticBox.value = partialStats.value;
        const variationValue = partialStats.value - partialStats.variation;
        statisticBox.variation!.value = Math.abs(variationValue);
        statisticBox.variation!.direction = variationValue > 0 ? 'up' : 'down';
        statisticBox.errorValue = XSUtils.isNull(partialStats.inError) || partialStats.inError === 0 ? undefined : partialStats.inError.toString();
    }
}
