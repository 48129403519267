import {Injectable} from '@angular/core';
import {XSDialogService, XSErrorService} from '@xs/core';
import {LCECMDEnvironmentService} from './lce-cmd-environment.service';

@Injectable({providedIn: 'root'})
export class LCECMDErrorService extends XSErrorService {
    constructor(protected environmentService: LCECMDEnvironmentService, protected dialogService: XSDialogService) {
        super(environmentService, dialogService);
    }
}
