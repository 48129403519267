import {Injectable} from '@angular/core';
import {LCECertificateOrderGlobalStats, LCECertificateOrderStatsCount, LCECertificateOrderStatsPayment, LCECertificateType} from '@lce/core';
import {XSUtils} from '@xs/base';

@Injectable()
export class LCEMockDataCertificateOrderStatisticsBuilder {

    public buildStatsGlobal(): LCECertificateOrderGlobalStats {
        return {
            total: {
                value: XSUtils.randomInteger(20, 200),
                variation: XSUtils.randomInteger(20, 100),
                inError: XSUtils.randomInteger(0, 20)
            },
            birth: {
                value: XSUtils.randomInteger(0, 100),
                variation: XSUtils.randomInteger(0, 50),
                inError: XSUtils.randomInteger(0, 10)
            },
            marriage: {
                value: XSUtils.randomInteger(0, 100),
                variation: 0,
                inError: XSUtils.randomInteger(0, 5)
            },
            death: {
                value: XSUtils.randomInteger(0, 50),
                variation: XSUtils.randomInteger(0, 50),
                inError: 0
            }
        } as LCECertificateOrderGlobalStats;
    }

    public buildStatsCount(): LCECertificateOrderStatsCount {
        const countBirth = XSUtils.randomInteger(10, 100);
        const countMarriage = XSUtils.randomInteger(0, 50);
        const countDeath = XSUtils.randomInteger(0, 30);

        return {
            total: countBirth + countMarriage + countDeath,
            birth: countBirth,
            marriage: countMarriage,
            death: countDeath
        } as LCECertificateOrderStatsCount;
    }

    public buildStatsPayment(): LCECertificateOrderStatsPayment {
        const birthTotalPerceivedAmount = XSUtils.randomInteger(10, 100);
        const birthTotalPerceivedAmountVariation = XSUtils.randomInteger(0, 50);

        const birthTotalChargedAmount = XSUtils.randomInteger(10, 100);
        const birthTotalChargedAmountVariation = XSUtils.randomInteger(0, 50);

        const birthTotalDueAmount = XSUtils.randomInteger(500000, 2000000);
        const birthTotalDueAmountVariation = XSUtils.randomInteger(1000000, 1500000);

        const birthNumberOfOrders = XSUtils.randomInteger(500, 3500);
        const birthNumberOfCopies = XSUtils.randomInteger(1000, 7000);

        const marriageTotalPerceivedAmount = XSUtils.randomInteger(10, 100);
        const marriageTotalPerceivedAmountVariation = XSUtils.randomInteger(0, 50);

        const marriageTotalChargedAmount = XSUtils.randomInteger(10, 100);
        const marriageTotalChargedAmountVariation = XSUtils.randomInteger(0, 50);

        const marriageTotalDueAmount = XSUtils.randomInteger(500000, 2000000);
        const marriageTotalDueAmountVariation = XSUtils.randomInteger(1000000, 1500000);

        const marriageNumberOfOrders = XSUtils.randomInteger(500, 3500);
        const marriageNumberOfCopies = XSUtils.randomInteger(1000, 7000);

        const deathTotalPerceivedAmount = XSUtils.randomInteger(10, 100);
        const deathTotalPerceivedAmountVariation = XSUtils.randomInteger(0, 50);

        const deathTotalChargedAmount = XSUtils.randomInteger(10, 100);
        const deathTotalChargedAmountVariation = XSUtils.randomInteger(0, 50);

        const deathTotalDueAmount = XSUtils.randomInteger(500000, 2000000);
        const deathTotalDueAmountVariation = XSUtils.randomInteger(1000000, 1500000);

        const deathNumberOfOrders = XSUtils.randomInteger(500, 3500);
        const deathNumberOfCopies = XSUtils.randomInteger(1000, 7000);

        const tTotalPerceivedAmount = birthTotalPerceivedAmount + marriageTotalPerceivedAmount + deathTotalPerceivedAmount;
        const tTotalPerceivedAmountVariation = birthTotalPerceivedAmountVariation + marriageTotalPerceivedAmountVariation + deathTotalPerceivedAmountVariation;

        const tTotalChargedAmount = birthTotalChargedAmount + marriageTotalChargedAmount + deathTotalChargedAmount;
        const tTotalChargedAmountVariation = birthTotalChargedAmountVariation + marriageTotalChargedAmountVariation + deathTotalChargedAmountVariation;

        const tTotalDueAmount = birthTotalDueAmount + marriageTotalDueAmount + deathTotalDueAmount;
        const tTotalDueAmountVariation = birthTotalDueAmountVariation + marriageTotalDueAmountVariation + deathTotalDueAmountVariation;

        const tNumberOfOrders = birthNumberOfOrders + marriageNumberOfOrders + deathNumberOfOrders;
        const tNumberOfCopies = birthNumberOfCopies + marriageNumberOfCopies + deathNumberOfCopies;
        return {
            total: {
                totalPerceivedAmount: tTotalPerceivedAmount,
                totalPerceivedAmountVariation: tTotalPerceivedAmountVariation,

                totalChargedAmount: tTotalChargedAmount,
                totalChargedAmountVariation: tTotalChargedAmountVariation,

                totalDueAmount: tTotalDueAmount,
                totalDueAmountVariation: tTotalDueAmountVariation,

                numberOfOrders: tNumberOfOrders,
                numberOfCopies: tNumberOfCopies
            },
            birth: {
                certificateType: LCECertificateType.BIRTH,
                totalPerceivedAmount: birthTotalPerceivedAmount,
                totalPerceivedAmountVariation: birthTotalPerceivedAmountVariation,

                totalChargedAmount: birthTotalChargedAmount,
                totalChargedAmountVariation: birthTotalChargedAmountVariation,

                totalDueAmount: birthTotalDueAmount,
                totalDueAmountVariation: birthTotalDueAmountVariation,

                numberOfOrders: birthNumberOfOrders,
                numberOfCopies: birthNumberOfCopies
            },
            marriage: {
                certificateType: LCECertificateType.MARRIAGE,
                totalPerceivedAmount: marriageTotalPerceivedAmount,
                totalPerceivedAmountVariation: marriageTotalPerceivedAmountVariation,

                totalChargedAmount: marriageTotalChargedAmount,
                totalChargedAmountVariation: marriageTotalChargedAmountVariation,

                totalDueAmount: marriageTotalDueAmount,
                totalDueAmountVariation: marriageTotalDueAmountVariation,

                numberOfOrders: marriageNumberOfOrders,
                numberOfCopies: marriageNumberOfCopies
            },
            death: {
                certificateType: LCECertificateType.DEATH,
                totalPerceivedAmount: deathTotalPerceivedAmount,
                totalPerceivedAmountVariation: deathTotalPerceivedAmountVariation,

                totalChargedAmount: deathTotalChargedAmount,
                totalChargedAmountVariation: deathTotalChargedAmountVariation,

                totalDueAmount: deathTotalDueAmount,
                totalDueAmountVariation: deathTotalDueAmountVariation,

                numberOfOrders: deathNumberOfOrders,
                numberOfCopies: deathNumberOfCopies
            }
        } as LCECertificateOrderStatsPayment;
    }
}
