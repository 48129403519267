import {Component, Input, OnInit} from '@angular/core';
import {LCEUserStatus, LCEUserStatusStr} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lce-user-status',
    template: `
        <xs-user-status
                [styleClass]="styleClass"
                [iconColorStyleClass]="iconColorStyleClass"
                [text]="text"
                textStyleClass="xs-font-size-intermediate {{ textStyleClass }}">
        </xs-user-status>`,
    styles: [':host { display: block; width: fit-content; }']
})
export class LCEUserStatusComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() status: LCEUserStatus | LCEUserStatusStr;

    @Input() showIcon?: boolean;

    @Input() showText?: boolean;

    @Input() applyStatusColorToText?: boolean;

    private readonly TR_BASE_USER_STATUS: string = 'lce.core.user.status.';
    private readonly BASE_STYLE_CLASS_COLOR_USER_STATUS: string = 'lce-user-status-color-';

    get textStyleClass(): string {
        return this.applyStatusColorToText === true ? this.getStatusColor() : '';
    }

    get text(): string | undefined {
        if (this.showText === false) return undefined;
        return this.TR_BASE_USER_STATUS + this.status.toString();
    }

    get iconColorStyleClass(): string | undefined {
        if (this.showIcon === false) return undefined;
        return this.getStatusColor();
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.status, 'status');
        if (this.showIcon === false && this.showText === false) {
            throw new Error('showIcon and showText cannot both be set to false at the same time.');
        }
        if (XSUtils.isNull(this.showIcon)) this.showIcon = true;
        if (XSUtils.isNull(this.showText)) this.showText = true;
        if (XSUtils.isNull(this.applyStatusColorToText) && this.showIcon === false) this.applyStatusColorToText = true;
    }

    private getStatusColor(): string {
        return this.BASE_STYLE_CLASS_COLOR_USER_STATUS + this.status.toString();
    }
}
