import {Injectable} from '@angular/core';
import {XS_LOREM_IPSUM, XSPKDTOAuditFullState, XSUtils} from '@xs/base';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {LCESuggestion, LCESuggestionCreate, LCESuggestionStatus, LCEUserCustomerPartial} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';

@Injectable()
export class LCEMockDataSuggestionBuilder {

    constructor(private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
    }

    public buildSuggestion(suggestionCreate?: LCESuggestionCreate): LCESuggestion {
        const targetAllMunicipalities = XSUtils.randomElement([false, true]);
        const targetMunicipalities = targetAllMunicipalities ? undefined : [this.mockMunicipalityHandler.getMunicipalityYopougon()];

        const anonymous = XSUtils.randomElement([true, false]);

        let author: LCEUserCustomerPartial = undefined!;
        if (!anonymous) {
            author = LCEMockUtils.randomUserCustomer();
        }

        const suggestion: LCESuggestion = {

            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: author,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: this.buildCode(),
            author: author,
            status: XSUtils.randomElement([
                LCESuggestionStatus.READ, LCESuggestionStatus.UNREAD, LCESuggestionStatus.CONSIDERED, LCESuggestionStatus.UNREAD, LCESuggestionStatus.UNREAD
            ]),
            subject: XSUtils.randomElement([XS_LOREM_IPSUM.short, undefined, XS_LOREM_IPSUM.medium]),
            message: XSUtils.randomElement([XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long]),
            targetAllMunicipalities: targetAllMunicipalities,
            targetMunicipalities: targetMunicipalities,
            anonymous: anonymous
        };

        const by = LCEMockUtils.randomUserMunicipalEmployee();

        if (suggestion.status === LCESuggestionStatus.READ) {
            suggestion.readOn = new Date().toISOString();
            suggestion.readBy = by;
        } else if (suggestion.status === LCESuggestionStatus.CONSIDERED) {
            suggestion.consideredOn = new Date().toISOString();
            suggestion.consideredBy = by;
        } else if (suggestion.status === LCESuggestionStatus.SELECTED) {
            suggestion.selectedOn = new Date().toISOString();
            suggestion.selectedBy = by;
        }

        if (suggestion.status !== LCESuggestionStatus.UNREAD) {
            suggestion.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
            suggestion.updatedOn = new Date().toISOString();
        }

        if (XSUtils.isEmpty(suggestionCreate)) return suggestion;
        else return {
            ...suggestion,
            anonymous: suggestionCreate!.anonymous,
            subject: suggestionCreate!.subject,
            message: suggestionCreate!.message,
            targetMunicipalities: targetMunicipalities,
            targetAllMunicipalities: suggestionCreate!.targetAllMunicipalities
        };
    }

    private buildCode(): string {
        return 'LCE-SUG-' + XSUtils.randomDigits(6);
    }

}
