import { LCEHttpClientService } from './lce-http-client.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { LCEDashboardStatsGlobal, LCEDashboardStatsSummaryCountType } from '../domain/lce-dashboard';
import { HttpParams } from '@angular/common/http';
import { XSBaseService, XSPredefinedPeriod, XSUtils } from '@xs/base';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';

@Injectable({ providedIn: 'root' })
export class LCEDashboardService extends XSBaseService {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.dashboard.index);
    }

    public retrieveStatsGlobal(
        municipalityCode?: string, countTypes?: LCEDashboardStatsSummaryCountType[], predefinedPeriod?: XSPredefinedPeriod):
        Observable<LCEDashboardStatsGlobal> {
        
        let params = new HttpParams();
        if (!XSUtils.isEmpty(municipalityCode)) {
            params = params.set('municipalityCode', municipalityCode!);
        }
        if (!XSUtils.isEmpty(countTypes)) {
            params = params.set('countTypes', countTypes!.toString());
        }
        if (!XSUtils.isEmpty(predefinedPeriod)) {
            params = params.set('predefinedPeriod', predefinedPeriod!.toString());
        }
        return this.httpClientService.get<LCEDashboardStatsGlobal>(this.buildEndpoint(LCE_CORE_ENDPOINTS.dashboard.statsGlobal), params);
    }
}
