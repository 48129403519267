<div class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <lce-certificate-avatar *ngIf="showLetterBorder" [type]="data.type" styleClass="xs-mr-10"></lce-certificate-avatar>
    <xs-ivar
            [line1]="('lce.core.label.referencePrefix' | translate) + ' ' + (data.referenceNumber | uppercase)"
            [line2]="('xs.common.label.year' | translate) + ' ' + (data.issueDate | xsDate : dateFormat)"
            [line3]="municipality"
            line1StyleClass="xs-font-weight-500 xs-font-size-intermediate-imp"
            line2StyleClass="xs-font-size-small-imp xs-color-secondary"
            line3StyleClass="xs-font-size-small-imp xs-color-secondary">
    </xs-ivar>
</div>
