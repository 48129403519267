<div class="xs-flex-column-center xs-width-full {{ styleClass }}">
    <div class="grid xs-fields xs-fields-spacer-5">
        <div class="col-12">
            <xs-input-field-text [options]="representativeFullNameField"></xs-input-field-text>
        </div>
        <div class="col-12 xl:col-6">
            <xs-input-field-phone-number [options]="representativePhoneNumberField"></xs-input-field-phone-number>
        </div>
        <div class="col-12 xl:col-6">
            <xs-input-field-email [options]="representativeEmailField"></xs-input-field-email>
        </div>
        <div class="col-12 xs-mt-30">
            <xs-title [text]="TR_BASE + (identityDocumentIsRequired ? 'identityDocument' : 'identityDocumentOptional')" textStyleClass="xs-color-secondary xs-font-size-intermediate">
                <ng-template xsTemplate="rightSection">
                    <xs-button
                            (clickEvent)="onIdentityDocumentFormChange()"
                            *ngIf="!hidedIdentityDocumentForm"
                            label="xs.core.label.delete"
                            size="small"
                            type="text">
                    </xs-button>
                    <xs-button
                            (clickEvent)="fillForm()"
                            *ngIf="showFillForm"
                            label="Fill Form"
                            size="small"
                            type="text">
                    </xs-button>
                </ng-template>
            </xs-title>
            <div class="xs-mt-30">
                <div *ngIf="hidedIdentityDocumentForm" class="xs-flex-column-center xs-mlr-8 xs-width-full">
                    <xs-button
                            (clickEvent)="onIdentityDocumentFormChange()"
                            [icon]="ICON.plus"
                            [label]="TR_BASE + 'addIdentityDocument'"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <span class="xs-display-block xs-font-size-small xs-text-align-center xs-color-secondary xs-mb-2">{{ TR_BASE + 'addIdentityDocumentMessage' | translate }}</span>
                </div>

                <div *ngIf="!hidedIdentityDocumentForm" class="xs-field xs-field-input">
                    <xs-input-field-radios [options]="identityDocumentTypeField" styleClass="xs-mb-12-imp"></xs-input-field-radios>

                    <xs-input-field-text [options]="identityDocumentNumberField"></xs-input-field-text>
                </div>
            </div>
        </div>
    </div>
</div>
