import {XSAddress, XSAmount, XSAuditOnBy, XSCoordinate, XSDateRange, XSIntegerRange, XSPersonName, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSTimeRange} from '@xs/base';
import {XSPaymentChargePartial, XSPaymentChargeStatus, XSPaymentType} from '@xs/payment/base';
import {XSMonthKey} from '../../../constants/lce-core.constant';
import {LCEFacilityPartial, LCEFacilityType} from '../../facility/lce-facility';
import {LCEIdentityDocumentType, LCERequestorType} from '../../lce-core';
import {LCEDelivery, LCEDeliveryDestination, LCEDeliveryDestinationType, LCEShippingInternationalProvider} from '../../lce-delivery';
import {LCEUserPartial} from '../../user/lce-user';
import {LCEUserCustomerCreate, LCEUserCustomerPartial} from '../../user/lce-user-customer';
import {XSReasonAction} from '../../xs';
import {LCECertificate, LCECertificateCreate, LCECertificatePartial, LCECertificateType} from '../lce-certificate';
import {LCECertificateOrderStatus} from './lce-certificate-order-status';
import {XSPaymentCreditCardType} from '@iro-xs/xs-payment-base/api/domain/xs-payment';

// ---------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------

export interface LCECertificateOrderStatusAudit {
    pending?: XSAuditOnBy;
    cancelled?: XSAuditOnBy;
    processing?: XSAuditOnBy;
    print?: XSAuditOnBy;
    error?: XSAuditOnBy;
    transit?: XSAuditOnBy;
    ready?: XSAuditOnBy;
    deliveryAssigned?: XSAuditOnBy;
    outForDelivery?: XSAuditOnBy;
    delivered?: XSAuditOnBy;
}

export interface LCECertificateOrderEventData {
    certificateType: LCECertificateType;
    status: LCECertificateOrderStatus;
    destinationType: LCEDeliveryDestinationType;
}

export enum LCECertificateOrderEventName {
    NEW_ORDER = 'certificateOrderNew',
    ORDER_PROCESSING = 'certificateOrderProcessing',
    ORDER_READY = 'certificateOrderReady',
    ORDER_CANCELLED = 'certificateOrderCancelled',
    ORDER_OUT_FOR_DELIVERY = 'certificateOrderOutForDelivery',
    ORDER_ERROR = 'certificateOrderError',
    ORDER_DELIVERED = 'certificateOrderDelivered',
    ORDER_STATUS_CHANGED = 'certificateOrderStatusChanged',
}

// ---------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------------------

export interface LCECertificateOrderConfirmation {
    orderID: string;
    orderNumber: string;
    orderCreatedOn: string;

    certificateType: LCECertificateType;

    facilityType: LCEFacilityType;
    facilityCode: string;
    facilityFullName: string;

    customerCode?: string;
    customerName?: XSPersonName,
    customerPrimaryPhoneNumber?: string,
    customerEmail?: string;

    procurationRepresentativeFullName?: string;
    procurationRepresentativePhoneNumber?: string;
    procurationRepresentativeEmail?: string;

    status: LCECertificateOrderStatus;
    qrCode: string;
    numberOfCopies: number;
    verificationCode?: string;

    chargeType?: XSPaymentType;
    chargeTransactionID?: string;
    chargeTransactionDate?: string;
    chargeChargedAmount?: XSAmount;
    chargeStatus?: XSPaymentChargeStatus;
    creditCardType?: XSPaymentCreditCardType;
    deliveryDestination: LCEDeliveryDestination;

    test?: boolean;
}

export interface LCECertificateOrderPriceContext {
    certificateType: LCECertificateType;
    facilityCode?: string;
    facilityName?: string;
    municipalityCode?: string;
    municipalityFullName: string;
}

export interface LCECertificateOrderPriceTripDefinition {

    id: string;
    title: string;
    description: string;
    minKM: number;
    maxKM: number;
    price: XSAmount;
    note?: string;
    active: boolean;
}

export interface LCECertificateOrderPriceSettings extends XSPKDTOAuditFull {

    orderUnitPrice: XSAmount;
    partnerUnitPrice?: XSAmount;
    taxAmount: XSAmount;
    dhlUnitPrice: XSAmount;
    upsUnitPrice: XSAmount;
    servicePointDeliveryFees?: XSAmount;
    feesAmount: XSAmount;
    message?: String;
    tripDefinitions: LCECertificateOrderPriceTripDefinition[];
}

export interface LCECertificateOrderPriceParameters {
    currencyRate: number;
    priceSettingsID: string;
}

export interface LCECertificateOrderPriceDetail {
    unitPrice: XSAmount;
    numberOfCopies: number;
    priceOfCopies: XSAmount;
    tax?: XSAmount;
    deliveryCost?: XSAmount;
    deliveryDistance?: number;
    totalFees?: XSAmount;
    total: XSAmount;

    context: LCECertificateOrderPriceContext;

    parameters: LCECertificateOrderPriceParameters;
}

export interface LCECertificateOrderPriceRequest {
    certificateType?: LCECertificateType;
    shippingProvider?: LCEShippingInternationalProvider;
    destinationType: LCEDeliveryDestinationType;
    destinationCustomLocationCoordinate?: XSCoordinate;
    destinationInternationalAddress?: XSAddress;
    destinationServicePointCode?: string;
    destinationFacilityCode?: string;
    facilityCode: string;
    numberOfCopies: number;
    currency: string;
}

export enum LCECertificateOrderErrorReason {
    WRONG_CERTIFICATE_TYPE = 'wrongCertificateType',
    WRONG_CERTIFICATE_REFERENCE_NUMBER = 'wrongCertificateReferenceNumber',
    WRONG_CERTIFICATE_ISSUE_DATE = 'wrongCertificateIssueDate',
    OTHER = 'other',
}

export enum LCECertificateOrderCancelReason {
    WAS_IN_ERROR = 'wasInError',
    FRAUD = 'fraud',
    FAILED_TO_DELIVER_TOO_MANY_TIMES = 'failedToDeliverTooManyTimes',
    PAYMENT_ISSUE = 'paymentIssue',
    TEST = 'test',
    OTHER = 'other',
}

export interface LCECertificateOrderUpdateStatusResponse {
    id: string;
    orderNumber: string;
    batchProcessCode?: string;
    status: LCECertificateOrderStatus;
    statusUpdatedOn: string;
    statusUpdatedBy: LCEUserPartial;
    updatedOn: string;
    updatedBy: LCEUserPartial;
    cancelReasonData?: XSReasonAction<LCECertificateOrderCancelReason, LCEUserPartial>;
    errorReasonData?: XSReasonAction<LCECertificateOrderErrorReason, LCEUserPartial>;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// === Statistics ===
// ----------------------------------------------------------------------------------------------------------------------------------------------------------

export interface LCECertificateOrderStatsPayment {
    total: LCECertificateOrderStatsPaymentPartial;
    birth: LCECertificateOrderStatsPaymentPartial;
    marriage: LCECertificateOrderStatsPaymentPartial;
    death: LCECertificateOrderStatsPaymentPartial;
}

export interface LCECertificateOrderStatsPaymentPartial {
    certificateType?: LCECertificateType;
    totalPerceivedAmount: number;
    totalPerceivedAmountVariation: number;

    totalChargedAmount: number;
    totalChargedAmountVariation: number;

    totalDueAmount: number;
    totalDueAmountVariation: number;

    numberOfOrders: number;
    numberOfCopies: number;
}

export interface LCECertificateOrderStatsChartLine {
    xAxis: XSMonthKey[];
    birthYAxisData?: number[];
    marriageYAxisData?: number[];
    deathYAxisData?: number[];
}

export interface LCECertificateOrderGlobalStats {
    total: LCECertificateOrderGlobalStatPartial;
    birth: LCECertificateOrderGlobalStatPartial;
    marriage: LCECertificateOrderGlobalStatPartial;
    death: LCECertificateOrderGlobalStatPartial;
}

export interface LCECertificateOrderGlobalStatPartial {
    value: number;
    variation: number;
    inError: number;
}

export interface LCECertificateOrderStatsCount {
    total: number;
    birth: number;
    marriage: number;
    death: number;
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// === Search ===
// ----------------------------------------------------------------------------------------------------------------------------------------------------------

export interface LCECertificateOrderSearch extends XSPKAuditFullSearchFilter {
    orderNumbers?: string[];
    orderNumber?: string;
    orderNumberEndsWith?: string;

    statuses?: LCECertificateOrderStatus[];

    facilityCodes?: string[];
    facilityTypes?: LCEFacilityType[];

    municipalityCodes?: string[];
    districtCodes?: string[];

    assistedBy?: string[];

    certificateTypes?: LCECertificateType[];
    certificateReferenceNumbers?: string[];
    certificateIssueDateRange?: XSDateRange;

    numberOfCopiesRange?: XSIntegerRange;

    destinationTypes?: LCEDeliveryDestinationType[];

    customerIDs?: string[];
    deliveryManIDs?: string[];

    customerCodes?: string[];
    customerFirstName?: string;
    customerLastName?: string;
    customerPrimaryPhoneNumber?: string;
    customerEmail?: string;

    coordinateGeoCircle?: string; // TODO: create XSGeoCircle
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------------
// === *** ===
// ----------------------------------------------------------------------------------------------------------------------------------------------------------

export interface LCECertificateOrderPartial {
    id: string;
    createdOn: string;
    updatedOn?: string;
    orderNumber: string;
    batchProcessCode?: string;
    status: LCECertificateOrderStatus;
    statusAudit: LCECertificateOrderStatusAudit;
    statusUpdatedOn: string;
    facility: LCEFacilityPartial;
    certificate: LCECertificatePartial;
    numberOfCopies: number;
    customer?: LCEUserCustomerPartial;
    deliveryDestinationType?: LCEDeliveryDestinationType;
}

export interface LCECertificateOrderProcuration {
    representativeFullName: string;
    representativePhoneNumber: string;
    representativeEmail?: string;
    representativeCustomer?: LCEUserCustomerPartial;
    identityDocumentType?: LCEIdentityDocumentType;
    identityDocumentNumber?: string;
}

export interface LCECertificateOrderProcurationCreate {
    representativeFullName: string;
    representativePhoneNumber: string;
    representativeEmail?: string;
    identityDocumentType?: LCEIdentityDocumentType;
    identityDocumentNumber?: string;
}

export interface LCECertificateOrderCreate {
    facilityCode: string;
    facility?: LCEFacilityPartial;

    queueNumber?: string;
    stampTokenCode?: string;
    assistedBy?: string;

    customerCreate?: LCEUserCustomerCreate;
    certificateCreate: LCECertificateCreate;
    procurationCreate?: LCECertificateOrderProcurationCreate;

    numberOfCopies: number;

    deliveryDestination: LCEDeliveryDestination;
    desiredDeliveryDate?: string;
    desiredDeliveryTimeRange?: XSTimeRange;
    deliveryNote?: string;

    currentCoordinate?: XSCoordinate;

    test?: boolean;
}

export interface LCECertificateOrder extends XSPKDTOAuditFull<LCEUserPartial> {
    orderNumber: string;
    batchProcessCode?: string;
    stampTokenCode?: string;
    queueNumber?: string;
    assistedBy?: LCEUserPartial;
    status: LCECertificateOrderStatus;
    statusAudit: LCECertificateOrderStatusAudit;
    statusUpdatedOn: string;
    facility: LCEFacilityPartial;
    certificate: LCECertificate;
    procuration?: LCECertificateOrderProcuration;
    customer?: LCEUserCustomerPartial;
    numberOfCopies: number;
    cost?: XSAmount;
    priceDetail?: LCECertificateOrderPriceDetail;
    delivery: LCEDelivery;
    assignmentID?: string;
    //deliveryDestinationType: LCEDeliveryDestinationType;
    charge?: XSPaymentChargePartial;
    coordinate?: XSCoordinate;
    qrCode: string;
    cancelData?: LCECertificateOrderCancel;
    // private XSReasonAction<LCEUserPartial> cancelReasonData;
    // private XSReasonAction<LCEUserPartial> errorReasonData;
    source: LCERequestorType;
    facilityDueAmountPaidOn?: string;
    test?: boolean;
}

export interface LCECertificateOrderCancel {
    reason: LCECertificateOrderCancelReason;
    note?: string;
    on: string;
    by: LCEUserPartial;
}
