<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card">
        <div class="xs-card-header xs-border-radius-none-imp xs-container-left-stripe">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <span [innerHTML]="TR_BASE + 'delivery' | translate" class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp"></span>
                <div *ngIf="readonly !== true" class="xs-flex-column xs-pr-10">
                    <lce-certificate-order-delivery-actions
                            (assignedEvent)="handleAssignOrderToDeliveryMan($event)"
                            (errorEvent)="onError($event)"
                            (removedAssignmentEvent)="handleRemovedAssignment($event)"
                            [data]="data">
                    </lce-certificate-order-delivery-actions>
                    <div class="xs-flex-row xs-align-items-center xs-mtb-15 xs-height-30">
                        <xs-error *ngIf="error" [error]="error" [message]="TR_BASE + 'operationError'" [showErrorDetailButton]="true" mode="inline">
                        </xs-error>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-card-content xs-pt-0-imp">
            <lce-delivery-record
                    [data]="data.delivery"
                    [showBorder]="true"
                    [showHeader]="true">
            </lce-delivery-record>
        </div>
    </div>
</div>