<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card {{ styleClass }}">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-ivar [line1]="TR_BASE + 'procuration'"
                 line1StyleClass="xs-color-secondary xs-font-weight-500 xs-font-size-default-imp"
                 line2StyleClass="xs-color-secondary xs-font-size-small-imp xs-mt-0-imp">
        </xs-ivar>
    </div>
    <div [ngClass]="{ 'xs-pt-30-imp': showHeader }" class="xs-card-content">
        <div class="grid xs-width-full xs-min-height-225">
            <div class="col-3 xs-flex-row-center">
                <div class="xs-flex-column-center {{ leftSideStyleClass }}">
                    <i [style.font-size]="'75px'" class="xs-display-block xs-color-secondary {{ ICON.procuration }}"></i>
                </div>
            </div>
            <div class="col-9 xs-border-left-discrete xs-pl-30-imp">
                <div class="grid xs-fields xs-fields-spacer-30">
                    <div class="col-12 md:col-6">
                        <xs-data-field-text
                                [label]="TR_BASE_LABEL + 'representativeFullName'"
                                [value]="data!.representativeFullName">
                        </xs-data-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-text
                                [label]="TR_BASE_LABEL + 'identityDocumentNumber'"
                                [value]="data!.identityDocumentNumber"
                                belowText="{{ 'lce.core.identityDocumentType.' + data!.identityDocumentType | translate}}">
                        </xs-data-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-phone-number
                                (clickEvent)="openCustomerRecord()"
                                [clickable]="isSame"
                                [label]="TR_BASE_LABEL + 'representativePhoneNumber'"
                                [value]="data!.representativePhoneNumber"
                                subLabelStyleClass="xs-color-secondary-imp">
                        </xs-data-field-phone-number>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-email
                                [label]="TR_BASE_LABEL + 'representativeEmail'"
                                [required]="false"
                                [value]="data!.representativeEmail">
                        </xs-data-field-email>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>