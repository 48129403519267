import {Injectable} from '@angular/core';
import {LCE_APP_SHORT_NAME} from '../constants/lce-core.constant';
import {LCERequestorType} from '../domain/lce-core';
import {XSCommonEnvironmentService} from '@xs/common';

@Injectable()
export abstract class LCEEnvironmentService extends XSCommonEnvironmentService {

    public getAppShortName(): string {
        return LCE_APP_SHORT_NAME;
    }

    public isPublicMobile(): boolean {
        return this.getRequestor() === LCERequestorType.PUBLIC_MOBILE_APP;
    }

    public isWebsite(): boolean {
        return this.getRequestor() === LCERequestorType.WEBSITE_APP;
    }

    public isMFO(): boolean {
        return this.getRequestor() === LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP;
    }

    public isMBO(): boolean {
        return this.getRequestor() === LCERequestorType.MUNICIPALITY_BACK_OFFICE_APP;
    }
}