import {Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {XS_DATE_FORMAT_DD_MM_YYYY_HH_MM_FR, XS_DATE_FORMAT_YYYY_MM_DD_HH_MM_EN, XSTranslationService} from '@xs/common';
import {
    LCE_CERTIFICATE_ORDER_STATUS_COLOR_MAP,
    LCE_TR_BASE_CERTIFICATE_ORDER_STATUS,
    LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION,
    LCECertificateOrderStatus,
    LCECertificateOrderStatusAudit,
    LCECertificateOrderStatusStr
} from '@lce/core';
import {XSStatusAuditOptions, XSStatusGeneric} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {XSPaymentTemplateManager} from '@xs/payment/core';
import {LOG, XSAssert, XSLanguage, XSStatusAudit, XSUtils} from '@xs/base';

@Component({selector: 'lce-certificate-order-status', templateUrl: './lce-certificate-order-status.component.html'})
export class LCECertificateOrderStatusComponent implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_STATUS = LCE_TR_BASE_CERTIFICATE_ORDER_STATUS;

    @Input() styleClass?: string;
    @Input() textStyleClass?: string;
    @Input() auditByOverlayStyleClass?: string;

    @Input() status: LCECertificateOrderStatus | LCECertificateOrderStatusStr;

    @Input() statusAudit?: LCECertificateOrderStatusAudit;

    @Input() disabled?: boolean;
    @Input() showOnlyCircle?: boolean;
    @Input() showBackground?: boolean;
    @Input() showAuditByOverlay?: boolean;

    @Input() clickable?: boolean;

    @Input() verticalGapHeight?: string;

    @Input() auditByOverlayTemplateRef?: TemplateRef<any>;

    statusAuditOptions: XSStatusAuditOptions;

    data: XSStatusGeneric;

    constructor(private translationService: XSTranslationService, private templateManager: XSPaymentTemplateManager) {
    }

    get dateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_YYYY_MM_DD_HH_MM_EN : XS_DATE_FORMAT_DD_MM_YYYY_HH_MM_FR;
    }

    get internalStatusAudit(): XSStatusAudit {
        return {
            pending: this.statusAudit?.pending,
            cancelled: this.statusAudit?.cancelled,
            processing: this.statusAudit?.processing,
            print: this.statusAudit?.print,
            error: this.statusAudit?.error,
            transit: this.statusAudit?.transit,
            deliveryAssigned: this.statusAudit?.deliveryAssigned,
            ready: this.statusAudit?.ready,
            outForDelivery: this.statusAudit?.outForDelivery,
            delivered: this.statusAudit?.delivered
        };
    }


    ngOnInit(): void {
        XSAssert.notNull(this.status, 'status');
        if (XSUtils.isNull(this.verticalGapHeight)) this.verticalGapHeight = '100px';
        if (XSUtils.isNull(this.showOnlyCircle)) this.showOnlyCircle = false;
        if (XSUtils.isNull(this.showBackground) && this.showOnlyCircle !== true) this.showBackground = true;
        if (XSUtils.isNull(this.showAuditByOverlay)) this.showAuditByOverlay = true;
        if (this.statusAudit && XSUtils.isEmpty(this.statusAudit![this.status as keyof LCECertificateOrderStatusAudit])) {
            LOG().error(`unable to find audit for the current status (${this.status}).`);
        }

        this.buildStatusAuditOptions();

        this.data = this.toGenericData();

        if (XSUtils.isNull(this.auditByOverlayTemplateRef)) {
            this.auditByOverlayTemplateRef = this.templateManager.auditByOverlayTemplateRef;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!XSUtils.isEmpty(changes.status) && !changes.status!.isFirstChange()) {
            this.data = this.toGenericData();
        }
    }

    private buildStatusAuditOptions(): void {
        this.statusAuditOptions = {
            statusMap: new Map([
                [LCECertificateOrderStatus.PENDING, {
                    id: LCECertificateOrderStatus.PENDING,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PENDING,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.PENDING,
                    color: this.statusDetail(LCECertificateOrderStatus.PENDING).color,
                    background: this.statusDetail(LCECertificateOrderStatus.PENDING).backgroundColor
                }],
                [LCECertificateOrderStatus.CANCELLED, {
                    id: LCECertificateOrderStatus.CANCELLED,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.CANCELLED,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.CANCELLED,
                    color: this.statusDetail(LCECertificateOrderStatus.CANCELLED).color,
                    background: this.statusDetail(LCECertificateOrderStatus.CANCELLED).backgroundColor
                }],
                [LCECertificateOrderStatus.PROCESSING, {
                    id: LCECertificateOrderStatus.PROCESSING,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PROCESSING,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.PROCESSING,
                    color: this.statusDetail(LCECertificateOrderStatus.PROCESSING).color,
                    background: this.statusDetail(LCECertificateOrderStatus.PROCESSING).backgroundColor
                }],
                [LCECertificateOrderStatus.CANCELLED, {
                    id: LCECertificateOrderStatus.CANCELLED,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.CANCELLED,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.CANCELLED,
                    color: this.statusDetail(LCECertificateOrderStatus.CANCELLED).color,
                    background: this.statusDetail(LCECertificateOrderStatus.CANCELLED).backgroundColor
                }],
                [LCECertificateOrderStatus.PRINT, {
                    id: LCECertificateOrderStatus.PRINT,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PRINT,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.PRINT,
                    color: this.statusDetail(LCECertificateOrderStatus.PRINT).color,
                    background: this.statusDetail(LCECertificateOrderStatus.PRINT).backgroundColor
                }],
                [LCECertificateOrderStatus.ERROR, {
                    id: LCECertificateOrderStatus.ERROR,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.ERROR,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.ERROR,
                    color: this.statusDetail(LCECertificateOrderStatus.ERROR).color,
                    background: this.statusDetail(LCECertificateOrderStatus.ERROR).backgroundColor
                }],
                [LCECertificateOrderStatus.TRANSIT, {
                    id: LCECertificateOrderStatus.TRANSIT,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.TRANSIT,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.TRANSIT,
                    color: this.statusDetail(LCECertificateOrderStatus.TRANSIT).color,
                    background: this.statusDetail(LCECertificateOrderStatus.TRANSIT).backgroundColor
                }],
                [LCECertificateOrderStatus.DELIVERY_ASSIGNED, {
                    id: LCECertificateOrderStatus.DELIVERY_ASSIGNED,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.DELIVERY_ASSIGNED,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.DELIVERY_ASSIGNED,
                    color: this.statusDetail(LCECertificateOrderStatus.DELIVERY_ASSIGNED).color,
                    background: this.statusDetail(LCECertificateOrderStatus.DELIVERY_ASSIGNED).backgroundColor
                }],
                [LCECertificateOrderStatus.READY, {
                    id: LCECertificateOrderStatus.READY,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.READY,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.READY,
                    color: this.statusDetail(LCECertificateOrderStatus.READY).color,
                    background: this.statusDetail(LCECertificateOrderStatus.READY).backgroundColor
                }],
                [LCECertificateOrderStatus.OUT_FOR_DELIVERY, {
                    id: LCECertificateOrderStatus.OUT_FOR_DELIVERY,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.OUT_FOR_DELIVERY,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.OUT_FOR_DELIVERY,
                    color: this.statusDetail(LCECertificateOrderStatus.OUT_FOR_DELIVERY).color,
                    background: this.statusDetail(LCECertificateOrderStatus.OUT_FOR_DELIVERY).backgroundColor
                }],
                [LCECertificateOrderStatus.DELIVERED, {
                    id: LCECertificateOrderStatus.DELIVERED,
                    name: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.DELIVERED,
                    description: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION + LCECertificateOrderStatus.DELIVERED,
                    color: this.statusDetail(LCECertificateOrderStatus.DELIVERED).color,
                    background: this.statusDetail(LCECertificateOrderStatus.DELIVERED).backgroundColor
                }]]),
            auditOneLine: true,
            auditByShowUserProfileImage: true
        };
    }

    private toGenericData(): XSStatusGeneric {
        return {
            id: this.status,
            status: this.status,
            auditOnBy: this.statusAudit ? this.statusAudit![this.status as keyof LCECertificateOrderStatusAudit] : undefined,
            color: this.statusDetail(this.status).color,
            background: this.statusDetail(this.status).backgroundColor
        };
    }

    private statusDetail(status: LCECertificateOrderStatus | LCECertificateOrderStatusStr): { color: string; backgroundColor: string; } {
        return LCE_CERTIFICATE_ORDER_STATUS_COLOR_MAP.get(status as LCECertificateOrderStatus)!;
    }
}
