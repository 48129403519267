<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #facilityRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !facilityRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton" [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog() && showHeader" class="xs-card-header">
            <xs-header-stripe [avatar]="{type: 'icon', data: ICON.certificateOrder}" [subtitle]="TR_BASE + '.label.subTitle'" [title]="TR_BASE + 'label.title'"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-max-width-percent-95">

                <div class="grid xs-fields xs-fields-spacer-25-imp">
                    <div class="col-12 md:col-6">
                        <xs-data-field-amount
                                [belowText]="TR_BASE + 'label.orderUnitPriceSubLabel'"
                                [label]="TR_BASE + 'label.orderUnitPrice'"
                                [value]="certificateOrderRegistration!.orderUnitPrice"
                                belowTextStyleClass="xs-color-secondary">
                        </xs-data-field-amount>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-amount
                                [belowText]="TR_BASE + 'label.orderFeeSubLabel'"
                                [label]="TR_BASE + 'label.orderFee'"
                                [value]="certificateOrderRegistration!.orderFee"
                                belowTextStyleClass="xs-color-secondary">
                        </xs-data-field-amount>
                    </div>
                    <div class="col-12 xs-mt-20">
                        <xs-data-field-text
                                [label]="this.TR_BASE + 'label.registrationType'"
                                value="{{ (this.TR_BASE + 'label.registrationType' + certificateOrderRegistration!.registrationType) | translate}}"
                        >
                        </xs-data-field-text>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-header-stripe [avatar]="{type: 'icon', data: ICON.certificateOrder}" [subtitle]="TR_BASE + '.label.subTitle'" [title]="TR_BASE + 'label.title'"></xs-header-stripe>
</ng-template>
