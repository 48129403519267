import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEPermission, XSPermissionBase, XSPermissionBaseCanShow} from '@lce/core';

@Component({
    selector: 'lce-authorization',
    templateUrl: './lce-authorization.component.html'
})
export class LCEAuthorizationComponent implements OnInit {

    readonly TR_BASE: string = 'lce.shared.authorization.';

    @Input() styleClass?: string;

    @Input() data?: LCEPermission;

    @Output() checkedEvent = new EventEmitter<{ name: string, state: boolean }>();
    @Output() changeEvent = new EventEmitter<LCEPermission>();
    @Output() auditChangeEvent = new EventEmitter<XSPermissionBase>();

    canShow: XSPermissionBaseCanShow = {'read': true};

    allAuditSelected: boolean = false;
    selectAllSearch: boolean = false;
    selectAllDashboard: boolean = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    public handleAuditChange(auditPermission: XSPermissionBase): void {
        this.data = {};
        this.data!.audit = auditPermission;
        this.changeEvent.emit(this.data);
        this.auditChangeEvent.emit(this.data!.audit);
    }

    public handleAuditChecked(data: { name: string, state: boolean }): void {
        this.checkedEvent.emit(data);
    }
}