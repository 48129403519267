import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE, LCECertificateOrderProcuration, LCECertificateOrderProcurationCreate, LCEIdentityDocumentType} from '@lce/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSInputFieldPhoneNumberOptions, XSInputFieldRadiosOptions, XSInputFieldTextOptions, XSInputRadio} from '@xs/core';
import {XSUtils} from '@xs/base';

@Component({selector: 'lce-certificate-order-create-procuration', templateUrl: './lce-certificate-order-create-procuration.component.html'})
export class LCECertificateOrderCreateProcurationComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE: string = 'lce.shared.certificateOrders.create.procuration.';

    readonly DOCUMENT_IDENTITY_TYPE_RADIOS: XSInputRadio[] = [
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.PASSPORT,
            inputId: LCEIdentityDocumentType.PASSPORT,
            value: LCEIdentityDocumentType.PASSPORT
        },
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.DRIVER_LICENSE,
            inputId: LCEIdentityDocumentType.DRIVER_LICENSE,
            value: LCEIdentityDocumentType.DRIVER_LICENSE
        },
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
            inputId: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
            value: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD
        }
    ];

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() procurationCreate?: LCECertificateOrderProcurationCreate;

    @Input() showFillForm?: boolean;

    @Input() formGroup: FormGroup = new FormGroup({});

    @Input() identityDocumentIsRequired?: boolean;

    @Output() saveEvent = new EventEmitter<LCECertificateOrderProcuration>();
    @Output() closeEvent = new EventEmitter<LCECertificateOrderProcuration>();

    hidedIdentityDocumentForm: boolean = true;

    identityDocumentTypeField: XSInputFieldRadiosOptions;
    representativeFullNameField: XSInputFieldTextOptions;
    representativePhoneNumberField: XSInputFieldPhoneNumberOptions;
    representativeEmailField: XSInputFieldTextOptions;
    identityDocumentNumberField: XSInputFieldTextOptions;

    selectedIdentityType?: LCEIdentityDocumentType;

    ngOnInit(): void {
        this.buildFields();
        if (XSUtils.isNull(this.identityDocumentIsRequired)) this.identityDocumentIsRequired = true;
        if (this.identityDocumentIsRequired) this.onIdentityDocumentFormChange();
    }

    public fillForm(): void {
        this.formGroup.get('representativeFullName')?.setValue('Mike Ross');
        this.formGroup.get('representativePhoneNumber')?.setValue('+225 0707070707');
        this.formGroup.get('representativeEmail')?.setValue('mike.ross@avocat.com');

        if (this.hidedIdentityDocumentForm) {
            this.formGroup.get('identityDocumentType')?.setValue(LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD);
            this.formGroup.get('identityDocumentNumber')?.setValue('CNI48954495');
        }
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.identityDocumentTypeField.control?.value) ||
            !XSUtils.isEmpty(this.representativeFullNameField.control?.value) ||
            !XSUtils.isEmpty(this.representativePhoneNumberField.control?.value) ||
            !XSUtils.isEmpty(this.representativeEmailField.control?.value) ||
            !XSUtils.isEmpty(this.identityDocumentNumberField.control?.value));
    }

    public onIdentityDocumentFormChange(): void {
        this.hidedIdentityDocumentForm = !this.hidedIdentityDocumentForm;

        if (this.hidedIdentityDocumentForm) {
            this.selectedIdentityType = undefined;
            this.identityDocumentTypeField.control?.setValue(undefined);
            this.identityDocumentNumberField.control?.setValue(undefined);
            this.identityDocumentTypeField.control?.removeValidators(Validators.required);
            this.identityDocumentNumberField.control?.clearValidators();
            this.identityDocumentNumberField.control?.updateValueAndValidity();
            this.identityDocumentNumberField.placeholder = this.TR_BASE + 'identityDocumentNumberPlaceholder';
        } else {
            this.identityDocumentTypeField.control?.addValidators(Validators.required);
            this.identityDocumentTypeField.control?.markAsTouched();
        }

        this.identityDocumentTypeField.control?.updateValueAndValidity();
    }

    private onIdentityTypeClicked(): void {
        this.identityDocumentNumberField.control?.reset();
        this.identityDocumentNumberField.control?.clearValidators();

        switch (this.selectedIdentityType) {
            case LCEIdentityDocumentType.DRIVER_LICENSE:
                this.updateIdentityDocumentNumberFieldPlaceholderAndValidator('lce.core.identityDocumentType.driverLicensePlaceholder', 12, 12);
                break;
            case LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD:
                this.updateIdentityDocumentNumberFieldPlaceholderAndValidator('lce.core.identityDocumentType.nationalIdentityCardPlaceholder', 11, 11);
                break;
            case LCEIdentityDocumentType.PASSPORT:
                this.updateIdentityDocumentNumberFieldPlaceholderAndValidator('lce.core.identityDocumentType.passportPlaceholder', 10, 10);
                break;
        }

        this.formGroup.get('identityDocumentType')?.setValue(this.selectedIdentityType);
        this.identityDocumentNumberField.control?.markAllAsTouched();
    }

    private updateIdentityDocumentNumberFieldPlaceholderAndValidator(placeholder: string, validatorMin: number, validatorMax: number): void {
        this.identityDocumentNumberField.placeholder = placeholder;
        this.identityDocumentNumberField.control?.addValidators(Validators.required);
        this.identityDocumentNumberField.control?.addValidators(Validators.minLength(validatorMin));
        this.identityDocumentNumberField.control?.addValidators(Validators.maxLength(validatorMax));
        this.identityDocumentNumberField.control?.updateValueAndValidity();
    }

    private buildFields(): void {
        this.representativeFullNameField = {
            fieldName: 'representativeFullName',
            label: this.TR_BASE + 'representativeFullName',
            control: new FormControl(this.procurationCreate?.representativeFullName, Validators.required)
        };

        this.representativePhoneNumberField = {
            fieldName: 'representativePhoneNumber',
            control: new FormControl(this.procurationCreate?.representativePhoneNumber, Validators.required),
            label: this.TR_BASE + 'representativePhoneNumber',
            labelStyleClass: ' xs-ellipsis-imp',
            countryISO: 'ci',
            showCountryFlag: true
        };

        this.representativeEmailField = {
            fieldName: 'representativeEmail',
            control: new FormControl(this.procurationCreate?.representativeEmail),
            label: this.TR_BASE + 'representativeEmail'
        };

        if (!XSUtils.isEmpty(this.procurationCreate?.identityDocumentType)) {
            this.hidedIdentityDocumentForm = false;
            this.selectedIdentityType = this.procurationCreate?.identityDocumentType;
        }
        this.identityDocumentNumberField = {
            fieldName: 'identityDocumentNumber',
            control: new FormControl(this.procurationCreate?.identityDocumentNumber),
            belowText: this.TR_BASE + 'identityDocumentNumber',
            placeholder: this.TR_BASE + 'identityDocumentNumberPlaceholder'
        };

        this.identityDocumentTypeField = {
            fieldName: 'identityDocumentType',
            control: new FormControl(this.procurationCreate?.identityDocumentType),
            radios: this.DOCUMENT_IDENTITY_TYPE_RADIOS,
            gridStyleClass: 'grid-nogutter',
            colStyleClass: 'lg:col-4 xs-mb-2',
            valueChange: (event: LCEIdentityDocumentType) => {
                this.selectedIdentityType = event;
                this.onIdentityTypeClicked();
            }
        };

        this.formGroup.addControl(this.identityDocumentTypeField.fieldName, this.identityDocumentTypeField.control!);
        this.formGroup.addControl(this.representativeFullNameField.fieldName, this.representativeFullNameField.control!);
        this.formGroup.addControl(this.representativeEmailField.fieldName, this.representativeEmailField.control!);
        this.formGroup.addControl(this.representativePhoneNumberField.fieldName, this.representativePhoneNumberField.control!);
        this.formGroup.addControl(this.identityDocumentNumberField.fieldName, this.identityDocumentNumberField.control!);
    }
}
