import {Directive, EventEmitter, Output} from '@angular/core';
import {LCEUser, LCEUserAccountService, LCEUserAccountStatus, LCEUserLockReason, LCEUserLockRequest} from '@lce/core';
import {LOG, XSPKDTOAuditFull} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSPKResourceAuditFullActionStr, XSPKResourceAuditFullMenuAction} from '@xs/core';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Directive()
export abstract class LCEUserActions<U extends LCEUser> extends XSPKResourceAuditFullMenuAction<U> {

	readonly ICON = LCE_SHARED_ICON;

	readonly TR_BASE: string = 'lce.shared.user.label.';

	@Output() dataChange = new EventEmitter<U>();

	@Output() editEvent = new EventEmitter<U>();

	@Output() lockEvent = new EventEmitter<string>();
	@Output() unlockEvent = new EventEmitter<string>();
	@Output() lockUnlockEvent = new EventEmitter<string>();

	@Output() loadingEvent = new EventEmitter<boolean>();

	loading = {
		edit: false,
		lock: false,
		unlock: false
	};

	protected constructor(
		protected translationService: XSTranslationService,
		protected userAccountService: LCEUserAccountService) {
		super(translationService);
	}

	public isAccountStatusLocked(): boolean {
		return this.data.accountStatus === LCEUserAccountStatus.LOCKED;
	}

	public isLoading(): boolean {
		for (const [key, value] of Object.entries(this.loading)) {
			if (value === true) return true;
		}
		return this.auditLoading === true;
	}

	public onEdit(): void {
		this.clearError();
		this.editEvent.emit(this.data);
	}

	public doUnlockUser(): void {
		this.clearError();
		this.menuDisabled = true;
		this.loading.unlock = true;
		this.loadingEvent.emit(true);
		this.subscription.add(
			this.userAccountService
				.unlock(this.data.id)
				.pipe(
					finalize(() => {
						this.loading.unlock = false;
						this.menuDisabled = false;
						this.loadingEvent.emit(false);
					})
				)
				.subscribe({
					next: (response) => {
						this.data.status = response.status;
						this.data.accountStatus = response.accountStatus;
						this.data.accountStatusUpdatedOn = response.accountStatusUpdatedOn;
						this.data.accountStatusUpdatedBy = response.accountStatusUpdatedBy;
						this.data.locking = response.locking;
						this.data.updatedOn = response.updatedOn;
						this.data.updatedBy = response.updatedBy;

						this.dataChange.emit({...this.data});

						LOG().debug('User (' + this.data.id + ') Unlocked On ' + this.data.accountStatusUpdatedOn + ' [status: ' + this.data.accountStatus + '].');
						this.unlockEvent.emit(this.data.id);
						this.lockUnlockEvent.emit(this.data.id);
					},
					error: (error) => {
						this.error = error;
						this.errorMessage = this.TR_BASE + 'errorUnlockFailed';
						this.errorEvent.emit(this.error);
					}
				})
		);
	}

	public doLockUser(): void {
		this.clearError();
		this.menuDisabled = true;
		this.loading.lock = true;
		this.loadingEvent.emit(true);
		const lockRequest: LCEUserLockRequest = {reason: {value: LCEUserLockReason.UNKNOWN}};
		this.subscription.add(
			this.userAccountService
				.lock(this.data.id, lockRequest)
				.pipe(
					finalize(() => {
						this.loading.lock = false;
						this.menuDisabled = false;
						this.loadingEvent.emit(false);
					})
				)
				.subscribe({
					next: (response) => {
						this.data.accountStatus = response.accountStatus;
						this.data.status = response.status;
						this.data.accountStatusUpdatedOn = response.accountStatusUpdatedOn;
						this.data.accountStatusUpdatedBy = response.accountStatusUpdatedBy;
						this.data.locking = response.locking;
						this.data.updatedOn = response.updatedOn;
						this.data.updatedBy = response.updatedBy;

						this.dataChange.emit({...this.data});

						LOG().debug('User (' + this.data.id + ') Locked On ' + this.data.accountStatusUpdatedOn + ' [status: ' + this.data.accountStatus + '].');
						this.lockEvent.emit(this.data.id);
						this.lockUnlockEvent.emit(this.data.id);
					},
					error: (error) => {
						this.error = error;
						this.errorMessage = this.TR_BASE + 'errorLockFailed';
						this.errorEvent.emit(this.error);
					}
				})
		);
	}

	protected doAfterAuditAction(action: XSPKResourceAuditFullActionStr, response: XSPKDTOAuditFull): void {
		if (action === 'purge') return;
		this.dataChange.emit({...this.data});
	}
}