import {Component, OnInit} from '@angular/core';
import {LCEFacilitiesFeatureService, LCEFacilityDialogService} from '@lce/shared';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';

@Component({
    selector: 'lce-cmd-facilities',
    templateUrl: './lce-cmd-facilities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEFacilityDialogService]
})
export class LCECMDFacilitiesComponent implements OnInit {
    readonly ICON = LCE_CMD_ICON;

    readonly TR_BASE: string = 'lce.shared.facility.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    constructor(private featureService: LCEFacilitiesFeatureService, private facilityDialogService: LCEFacilityDialogService) {
    }

    ngOnInit(): void {
    }

    public onCreate(): void {
        this.facilityDialogService.openCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
