import { LCEUserPartial } from '../user/lce-user';

export enum LCEArticleReviewStatus {
	PENDING = 'pending',
	REQUEST_CHANGES = 'requestChanges',
	APPROVED = 'approved'
}

export interface LCEArticleReviewer {
	user: LCEUserPartial;

	addedOn?: string;

	reviewStatus?: LCEArticleReviewStatus;
	updatedStatusOn?: string;

	isDefault?: boolean;
}
