//TODO: move to lce-certificate-order.ts
export enum LCECertificateOrderStatus {
    PENDING = 'pending',
    CANCELLED = 'cancelled',
    PROCESSING = 'processing',
    PRINT = 'print',
    ERROR = 'error',
    TRANSIT = 'transit',
    READY = 'ready',
    DELIVERY_ASSIGNED = 'deliveryAssigned',
    OUT_FOR_DELIVERY = 'outForDelivery',
    DELIVERED = 'delivered',
}

export type LCECertificateOrderStatusStr = 'pending' | 'cancelled' | 'processing' | 'print' | 'error' | 'transit' | 'ready' | 'deliveryAssigned' | 'outForDelivery' | 'delivered';
