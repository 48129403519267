import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCEServicePoint, LCEServicePointCanOptions, LCEServicePointPartial, LCEServicePointSearch, LCEServicePointService} from '@lce/core';
import {XSPagination, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEServicePointDialogService} from './lce-service-point-dialog.service';
import {LCEServicePointsFeatureService} from './lce-service-points-feature.service';

@Component({
    selector: 'lce-service-points',
    templateUrl: './lce-service-points.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCEServicePointsComponent implements OnInit, OnDestroy {

    readonly ICON_SERVICE_POINT: string = LCE_SHARED_ICON.servicePoint;

    readonly TR_CORE: string = 'xs.core.';

    readonly TR_BASE: string = 'lce.shared.servicePoint.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    @Input() styleClass?: string;

    @Input() showBackgroundIcon?: boolean;

    @Input() canOptions?: LCEServicePointCanOptions;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    private subscription: Subscription = new Subscription();

    constructor(
        public servicePointService: LCEServicePointService,
        private featureService: LCEServicePointsFeatureService,
        private servicePointDialogService: LCEServicePointDialogService) {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
        this.retrieveStats();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEdit(): void {
        this.servicePointDialogService.openCreateUpdateDialog({
            servicePoint: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: (servicePointRecord) => {
                this.dataManager.updateSelectedRecordData(servicePointRecord);
                this.dataManager.selectedRowResult.fullName = servicePointRecord?.fullName;
            }
        });
    }

    public refresh(): void {
        this.retrieveStats();
        this.dataManager.updateSearch();
    }

    public canEdit(): boolean {
        return this.canOptions?.update !== false;
    }

    private retrieveStats(): void {
        this.statsError = undefined;
        this.statsLoading = true;
        this.subscription.add(
            this.servicePointService
                .retrieveStats()
                .pipe(finalize(() => (this.statsLoading = false)))
                .subscribe({
                    next: (servicePointStats) => (this.stats = servicePointStats),
                    error: (error) => (this.statsError = error)
                })
        );
    }

    private retrieveServicePoint(selectedRowResult: LCEServicePointPartial): Observable<LCEServicePoint> {
        return this.servicePointService.retrieve(selectedRowResult.id);
    }

    private searchServicePoints(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCEServicePointPartial>> {
        const servicePointSearch: LCEServicePointSearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.servicePointService.search(servicePointSearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchServicePoints(query, pagination),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveServicePoint(selectedRowData),

            search: {
                defaultPaginationSize: 20,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultFound'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
