import {Injectable} from '@angular/core';
import {LCEFacilityTownHallStampToken} from '@lce/core';
import {XSAssert, XSResourceAuditCanOptions} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEFacilityTownHallStampTokenRecordComponent} from './record/lce-facility-town-hall-stamp-token-record.component';

export interface LCEFacilityTownHallStampTokenDialogRecordArg {
    stampTokenID?: string;
    stampTokenCode?: string;
    stampToken?: LCEFacilityTownHallStampToken;
    canAudit?: XSResourceAuditCanOptions;
    onClose?: (stampToken?: LCEFacilityTownHallStampToken) => void;
}

@Injectable()
export class LCEFacilityTownHallStampTokenDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEFacilityTownHallStampTokenDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        LCEFacilityTownHallStampTokenRecordComponent.validate(arg.stampTokenID, arg.stampTokenCode, arg.stampToken, 'arg');
        const dConfig: XSDialogConfig = {
            data: {
                stampTokenID: arg.stampTokenID,
                stampTokenCode: arg.stampTokenCode,
                stampToken: arg.stampToken,
                canAudit: arg.canAudit,
                loadingStyleClass: 'xs-min-height-700 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            headerSeparator: false,
            header: true,
            closable: true,
            dialogStyleClass: 'xs-width-percent-90 xs-max-width-900'
        };

        const recordComponent: LCEFacilityTownHallStampTokenRecordComponent = this.dialogService.openComponentDialog(LCEFacilityTownHallStampTokenRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((closeFacilityStampToken: LCEFacilityTownHallStampToken) => {
            if (arg?.onClose) arg!.onClose!(closeFacilityStampToken);
        });
    }
}
