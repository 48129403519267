<div class="xs-flex-column xs-width-full xs-height-full">
	<div class="xs-card">
		<div class="xs-card-header xs-container-left-stripe-secondary">
			<div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
				<div class="xs-flex-colum">
					<span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
						{{ TR_BASE + 'settings' | translate }}
					</span>
				</div>
				<div class="xs-flex-row">
					<!-- <xs-button class="xs-mr-40" type="text" [icon]="ICON.edit" label="xs.core.label.edit" (clickEvent)="onEdit()"></xs-button>
					<xs-button type="text" [icon]="ICON.ellipsisVertical"></xs-button> -->
				</div>
			</div>
		</div>
		<div class="xs-card-content xs-pt-50-imp">
			<div class="grid">
				<div class="col-12">
					<span class="xs-data-section-title xs-data-section-title-separator">General</span>
				</div>

				<div class="col-12">
					<span class="xs-data-section-title xs-data-section-title-separator">Notifications</span>
				</div>
			</div>
		</div>
	</div>
</div>