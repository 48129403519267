<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card {{ styleClass }}">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-ivar [line1]="TR_BASE + 'certificateShort'"
                 [line2]="showHeaderSubTitle === true ? (TR_BASE_CERTIFICATE_TYPE + data.type) : undefined"
                 line1StyleClass="xs-color-secondary xs-font-weight-500 xs-font-size-default-imp"
                 line2StyleClass="xs-color-secondary xs-font-size-small-imp xs-mt-0-imp">
        </xs-ivar>
    </div>
    <div [ngClass]="{ 'xs-pt-30-imp': showHeader }" class="xs-card-content">
        <div class="grid xs-width-full xs-min-height-225">
            <div class="col-3 xs-flex-row-center">
                <div class="xs-flex-column-center {{ leftSideStyleClass }}">

                    <i [style.height]="'80px!important'" [style.width]="'80px!important'" class="xs-display-block xs-color-secondary {{ ICON.certificate }}"></i>

                    <xs-square-data
                            bottomText="{{ '<b>' + data.nOrderedCopies + '</b>' }} {{ TR_BASE + 'totalCopies' | translate }}"
                            rightText="{{ TR_BASE }}times"
                            rightTextStyleClass="xs-ml-8-imp"
                            styleClass="xs-mt-40"
                            topText="{{ TR_BASE }}ordered"
                            value="{{ data.nOrdered }}">
                    </xs-square-data>
                </div>
            </div>
            <div class="col-9 xs-border-left-discrete xs-pl-30-imp xs-flex-row xs-align-items-center">

                <div class="grid xs-fields xs-fields-spacer-20">
                    <div class="col-12 md:col-6">
                        <xs-data-field-text [valueBaseTranslation]="TR_BASE_CERTIFICATE_TYPE" [value]="data.type" label="xs.common.label.type">
                        </xs-data-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-text [label]="TR_BASE + 'referenceNumber'" [upperCase]="true" [value]="data.referenceNumber">
                        </xs-data-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-calendar [calendarOptions]="issueDateOptions" [label]="TR_BASE + 'issueDate'" [value]="data.issueDate">
                        </xs-data-field-calendar>
                    </div>
                    <div class="col-12 md:col-6">
                        <lce-municipality-partial [data]="data.municipality" [showIcon]="true" [showOverlay]="true"></lce-municipality-partial>
                    </div>

                    <div *ngIf="isBirthCertificate() && (holderName | xsIsNotEmpty)" class="col-12 md:col-6">
                        <xs-data-field-text [label]="TR_BASE + 'certificatePersonName'" [value]="holderName | xsPersonName"></xs-data-field-text>
                    </div>

                    <ng-container *ngIf="isMarriageCertificate() && ((brideName | xsIsNotEmpty) || (groomName | xsIsNotEmpty))">
                        <div *ngIf="brideName | xsIsNotEmpty" class="col-12 md:col-6">
                            <xs-data-field-text [label]="TR_BASE + 'certificatePersonBrideName'" [value]="brideName | xsPersonName"></xs-data-field-text>
                        </div>
                        <div *ngIf="groomName | xsIsNotEmpty" class="col-12 md:col-6">
                            <xs-data-field-text [label]="TR_BASE + 'certificatePersonGroomName'" [value]="groomName | xsPersonName"></xs-data-field-text>
                        </div>
                    </ng-container>

                    <div *ngIf="isDeathCertificate() && (deceasedName | xsIsNotEmpty)" class="col-12 md:col-6">
                        <xs-data-field-text [label]="TR_BASE + 'certificatePersonDeceasedName'" [value]="deceasedName | xsPersonName"></xs-data-field-text>
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>