import {Component, OnInit} from '@angular/core';
import {LCEDeliveryCompaniesFeatureService, LCEDeliveryCompanyDialogService} from '@lce/shared';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';

@Component({
    selector: 'lce-cmd-delivery-companies',
    templateUrl: './lce-cmd-delivery-companies.component.html',
    providers: [LCEDeliveryCompaniesFeatureService, LCEDeliveryCompanyDialogService]
})
export class LCECMDDeliveryCompaniesComponent implements OnInit {

    ICON = LCE_CMD_ICON;
    readonly TR_BASE: string = 'lce.shared.deliveryCompany.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    constructor(private featureService: LCEDeliveryCompaniesFeatureService, private deliveryCompanyDialogService: LCEDeliveryCompanyDialogService) {
    }

    ngOnInit(): void {
    }

    public onCreate(): void {
        this.deliveryCompanyDialogService.openCreateUpdate({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}