<div *ngIf="exceptionType" class="lce-template-default-router-outlet xs-card">
    <div class="xs-card-content xs-align-items-center xs-justify-content-center xs-pt-50-imp xs-pb-25-imp">
        <div class="xs-flex-column-center xs-color-danger xs-line-height-1">
            <span class="xs-display-block xs-font-size-50">{{ exceptionCode | translate }}</span>
            <span class="xs-display-block xs-font-size-intermediate xs-mt-5">
                {{ 'lce.cmd.template.default.exception.codeDescription.' + exceptionType | translate }}
            </span>
        </div>
        <div class="xs-mt-50 xs-text-align-center">
            <span class="xs-display-block">{{ 'lce.cmd.template.default.exception.message.' + exceptionType | translate }}</span>
            <span class="xs-display-block xs-mt-5 xs-font-size-intermediate xs-text-secondary-color">{{ getResource() }}</span>
        </div>
        <div *ngIf="state?.secondaryMessage | xsIsNotEmpty" class="xs-mt-25 xs-text-align-center">
            <span [innerHTML]="state!.secondaryMessage! | translate" class="xs-display-block"></span>
        </div>
        <div class="xs-mt-50 xs-width-full">
            <xs-button (clickEvent)="redirect()" [label]="redirectButtonLabel | translate" class="xs-width-full-imp" styleClass="xs-width-full xs-max-width-300" type="button"></xs-button>
        </div>
        <div class="xs-flex-row xs-justify-content-space-between xs-width-full xs-mt-75 xs-plr-15">
            <xs-button (clickEvent)="goBack()" [icon]="ICON.back" label="xs.core.label.back" size="intermediate" type="text">
            </xs-button>
            <xs-button (clickEvent)="reportIssue()" [icon]="ICON.bug" label="lce.cmd.template.default.exception.reportAnIssue" size="intermediate"
                       type="text">
            </xs-button>
        </div>
    </div>
</div>
