import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEServicePointsFeatureService} from '@lce/shared';
import {LCECMDServicePointsRoutingModule} from './lce-cmd-service-points-routing.module';
import {LCECMDServicePointsComponent} from './lce-cmd-service-points.component';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, LCECMDServicePointsRoutingModule],
    declarations: [LCECMDServicePointsComponent],
    exports: [
        LCECMDServicePointsComponent
    ],
    providers: [LCEServicePointsFeatureService]
})
export class LCECMDServicePointsModule {
}
