<div class="xs-card xs-min-width-400 xs-width-full xs-card-border {{ styleClass }}">
    <div [ngClass]="{'xs-background-color-discrete': showHeaderBackgroundColor}" [style.filter]="'brightness(106%)'"
         class="xs-card-header xs-card-header-separator xs-mb-25">
        <xs-title
                [expandCollapseEnable]="true"
                [expandCollapseTarget]="filter"
                [expanded]="true"
                [icon]="ICON.certificateOrder"
                [text]="title!"
                styleClass="xs-mb-3"
                subText="{{ (deliveryMan | xsIsNotEmpty) ? ((deliveryMan!.name | xsPersonName ) + ' | ' + deliveryMan?.code!) : TR_BASE + 'subtitle' }}"
                subTextStyleClass="xs-font-size-default xs-color-secondary {{ subTitleStyleClass }}"
                textStyleClass="xs-font-size-intermediate xs-font-weight-500-imp xs-color-secondary {{ titleStyleClass }}">
        </xs-title>
    </div>
    <div class="xs-card-content xs-p-0-imp">
        <div #filter [ngClass]="{ 'xs-disabled': disabled || (deliveryMan | xsIsEmpty)  }" class="xs-mb-25 xs-plr-10">
            <xs-input-period-select
                    (periodSelectEvent)="onPeriodSelectEvent($event)"
                    [disabled]="disabledSearchField"
                    styleClass="xs-mb-20">
            </xs-input-period-select>

            <xs-input-multi-select
                    (valueChange)="onStatusSearch($event)"
                    [(value)]="orderStatus"
                    [disabled]="disabledSearchField"
                    [items]="orderStatusItems"
                    [options]="orderStatusSearchField"
                    [placeholder]="TR_BASE + 'filter.statusPlaceholder'"
                    styleClass="xs-mb-20">
            </xs-input-multi-select>
        </div>
        <div class="xs-flex xs-justify-content-space-between xs-align-item-center xs-border-bottom-discrete xs-pb-5 xs-plr-10">

            <div class="xs-width-175">
                <div class="xs-position-absolute xs-pl-10">
                    <xs-loader #orderNumberLoader [loaderId]="ORDER_NUMBER_LOADER_ID" [loaderSize]="15" [loaderType]="loaderType"></xs-loader>
                </div>
                <span *ngIf="!orderNumberLoader.isRunning()" class="xs-display-block xs-font-size-default xs-color-secondary">
                {{ TR_BASE + 'caption' | translate : {nResults: totalResults} }}
            </span>
            </div>

            <div class="xs-flex xs-align-item-center">
                <xs-button
                        (clickEvent)="onUnassignOrders()"
                        *ngIf="isUnassignButton()"
                        [confirmation]="unassignmentConfirmation"
                        [loaderId]="UNASSIGN_LOADER_ID"
                        [loading]="isUnassignLoaderRunning()"
                        [transform]="false"
                        label="{{ 'lce.shared.certificateOrders.manualDispatcher.unassign' | translate : { orderToUnassign: selectedOrders.length } }}"
                        loaderPosition="left"
                        size="intermediate"
                        styleClass="xs-width-full xs-mr-25-imp"
                        type="text"></xs-button>

                <div
                        *ngIf="hasAssignError() && !isUnassignButton()"
                        class="xs-width-full xs-mr-25-imp">
                    <xs-error-icon
                            [clickable]="true"
                            [error]="errorUnassign"
                            [showErrorDialogOnClick]="true">
                    </xs-error-icon>
                </div>
                <xs-icon
                        [disabled]="true"
                        [value]="ICON.setting"
                        color="secondary"
                        styleClass="xs-clickable xs-mr-40">
                </xs-icon>
                <xs-icon
                        (clickEvent)="showMap = !showMap"
                        [value]="ICON.map"
                        size="15px"
                        styleClass="{{ showMap ? 'xs-color-secondary-imp' : 'xs-color-primary-imp' }}">
                </xs-icon>
            </div>
        </div>

        <div class="xs-plr-10 xs-pt-10 xs-position-relative xs-min-height-200 xs-flex-column-center">
            <div class="xs-absolute-center-vh">
                <xs-loader
                    #searchLoader
                    [loaderId]="LOADER_ID"
                    [loaderSize]="30">
                </xs-loader>
            </div>
            <div
                    *ngIf="hasError() && !searchLoader.isRunning()"
                    class="xs-absolute-center-vh xs-width-full">
                <xs-error
                        [actionButton]="errorRetryButton"
                        [error]="error"
                        [showActionButton]="true"
                        [showErrorDetailButton]="true"
                        message="xs.core.label.httpErrorMessage"
                        mode="inline">
                </xs-error>
            </div>
            <ul
                    *ngIf="!hasError() && !searchLoader.isRunning() && assignments | xsIsNotEmpty"
                    class="xs-listing xs-listing-separator xs-width-full xs-overflow-y-scroll xs-height-300">
                <li
                        (click)="onAssignmentSelect(assignment)"
                        *ngFor="let assignment of assignments"
                        [ngClass]="{ 'lce-background-items-selected xs-border-radius-default': isSelected(assignment.certificateOrder) }"
                        class="xs-listing-item xs-p-15-imp xs-cursor-pointer">
                    <lce-certificate-order-partial
                            [data]="assignment.certificateOrder"
                            [detailed]="true"
                            [openRecordOnOrderNumberClick]="true">
                    </lce-certificate-order-partial>
                </li>

                <li *ngIf="assignments | xsIsNotEmpty" class="xs-background-none-imp">
                    <xs-paginator-load-more
                            (errorEvent)="handlePaginationError($event)"
                            (loadEvent)="handlePaginationLoad($event)"
                            [options]="paginationOptions"
                            [paginationPage]="pagination.page"
                            [paginationSize]="pagination.size"
                            [total]="totalResults"
                            styleClass="xs-mt-5 xs-flex-row-center">
                    </xs-paginator-load-more>
                </li>
            </ul>

            <xs-no-result-found
                    *ngIf="!hasError() && !searchLoader.isRunning() && assignments | xsIsEmpty"
                    [subText]="TR_BASE + 'noResultFoundSubText'"
                    [text]="TR_BASE + 'noResultFoundText'"
                    iconStyleClass="xs-font-size-35-imp"
                    styleClass="xs-flex-column-center xs-width-full xs-height-300"></xs-no-result-found>
        </div>
    </div>
    <div *ngIf="showMap" class="xs-card-footer xs-border-top-discrete">
        <div class="xs-flex-column-center xs-min-height-250">
            <xs-icon [value]="ICON.map" color="discrete" size="150px"></xs-icon>
        </div>
    </div>
</div>
