import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON, LCEDeliveryCompany, LCEDeliveryCompanyCreate, LCEDeliveryCompanyService} from '@lce/core';
import {LOG, XSAddressType, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldAddressOptions, XSInputFieldBaseOptions, XSInputFieldContactPersonOptions, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: './lce-delivery-company-create-update', templateUrl: './lce-delivery-company-create-update.component.html'})
export class LCEDeliveryCompanyCreateUpdateComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE: string = 'lce.shared.deliveryCompany.';
    readonly LOADER_ID_CENTRAL: string = 'createUpdateDeliveryCompany';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() deliveryCompanyID?: string;
    @Input() deliveryCompany?: LCEDeliveryCompany;

    @Input() userCountryISO: string;

    @Output() saveEvent = new EventEmitter<LCEDeliveryCompany>();
    @Output() closeEvent = new EventEmitter<LCEDeliveryCompany>();

    formGroup: FormGroup = new FormGroup({});
    nameField: XSInputFieldTextOptions;
    fullNameField: XSInputFieldTextOptions;
    ccField: XSInputFieldTextOptions;
    municipalityField: XSInputFieldBaseOptions;
    addressField: XSInputFieldAddressOptions;
    contactPersonField: XSInputFieldContactPersonOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveDeliveryCompany()
    };
    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    constructor(private deliveryCompanyService: LCEDeliveryCompanyService, private loaderService: XSLoaderService) {
        super();
    }

    get headerTitle(): string {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.deliveryCompany?.fullName;
        if (XSUtils.isEmpty(title)) title = this.TR_BASE + 'label.deliveryCompany';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.deliveryCompany?.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        console.log(this.deliveryCompany);
        if (this.isDialog()) {
            this.deliveryCompanyID = this.dialogConfig.data.deliveryCompanyID;
            this.deliveryCompany = this.dialogConfig.data.deliveryCompany;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.contentStyleClass = this.dialogConfig.data.contentStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.deliveryCompany));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.deliveryCompany)) {
            if (!XSUtils.isEmpty(this.deliveryCompany)) this.deliveryCompanyID = this.deliveryCompany!.id;
            this.initialize();
        } else this.retrieveDeliveryCompany();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isUpdate(): boolean {
        return !XSUtils.isEmpty(this.deliveryCompanyID);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.deliveryCompanyID) || !XSUtils.isEmpty(this.deliveryCompany);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.deliveryCompanyID) && XSUtils.isEmpty(this.deliveryCompany);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty;
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public fillForm(): void {
        this.nameField.control?.setValue('IRO COMPANY');
        this.fullNameField.control?.setValue('IRO SARL COMPANY ');
        this.ccField.control?.setValue('CC');
        this.formGroup.get(this.addressField.fieldName)?.setValue(LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON);
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private retrieveDeliveryCompany(): void {
        this.retrieveError = undefined;
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.subscription.add(
            this.deliveryCompanyService
                .retrieve(this.deliveryCompanyID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: retrievedDeliveryCompany => {
                        this.deliveryCompany = retrievedDeliveryCompany;
                        this.initialize();
                    },
                    error: error => (this.retrieveError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating Delivery Company [deliveryCompanyID: ' + this.deliveryCompanyID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Delivery Company not updated. No change detected ! [code: ' + this.deliveryCompany!.code + ', id: ' + this.deliveryCompanyID + ']');
            this.saveEvent.emit(undefined);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.deliveryCompanyService
                .update(this.deliveryCompanyID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: deliveryCompanyRecord => {
                        const updatedDeliveryCompanyRecordID = deliveryCompanyRecord.id;
                        LOG().debug('Delivery Company successfully updated :-) [code: ' + deliveryCompanyRecord.code + ', id: ' + updatedDeliveryCompanyRecordID + ']');
                        this.deliveryCompany = deliveryCompanyRecord;
                        this.saveEvent.emit(this.deliveryCompany);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating Delivery Company ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const deliveryCompanyCreate = this.buildCreate(formData);

        this.subscription.add(
            this.deliveryCompanyService
                .create(deliveryCompanyCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: deliveryCompany => {
                        LOG().debug('Delivery Company successfully saved :-) [code: ' + deliveryCompany.code + ', id: ' + deliveryCompany.id + ']');
                        this.deliveryCompany = deliveryCompany;
                        this.saveEvent.emit(deliveryCompany);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private buildUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (!XSUtils.equal(formData.shortName, this.deliveryCompany?.shortName)) fieldValueMap.set('shortName', XSUtils.trim(formData.shortName));
        if (!XSUtils.equal(formData.name, this.deliveryCompany?.name)) fieldValueMap.set('name', XSUtils.trim(formData.name));
        if (!XSUtils.equal(formData.fullName, this.deliveryCompany?.fullName)) fieldValueMap.set('fullName', XSUtils.trim(formData.fullName));
        if (!XSUtils.equal(formData.municipalityCode, this.deliveryCompany?.municipality?.code)) fieldValueMap.set('municipalityCode', XSUtils.parseInt(formData.municipalityCode));
        if (!XSUtils.equal(formData.address, this.deliveryCompany?.address)) fieldValueMap.set('address', formData.address);

        return fieldValueMap;
    }

    private buildCreate(formData: any): LCEDeliveryCompanyCreate {
        return {
            name: XSUtils.trim(formData.name),
            fullName: XSUtils.trim(formData.fullName),
            shortName: XSUtils.trim(formData.shortName),
            municipalityCode: XSUtils.trim(formData.municipality.code),
            cc: XSUtils.trim(formData.cc),
            address: formData.address,
            contactPerson: formData.contactPerson
        };
    }

    private isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.nameField.control?.value) ||
                !XSUtils.isEmpty(this.fullNameField.control?.value)) ||
            !XSUtils.isEmpty(this.contactPersonField.control?.value);

    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            }
        };
    }

    private buildFields(): void {
        this.contactPersonField = {
            fieldName: 'contactPerson',
            label: 'xs.core.label.primaryContactPerson',
            control: new FormControl(this.deliveryCompany?.contactPerson, Validators.required),
            contactPersonOptions: {
                formOptions: {
                    showDescriptionField: true,
                    showSecondaryPhoneNumberField: false,
                    showEmailField: true,
                    showNoteField: true,
                    primaryPhoneNumber: {
                        countryISO: this.userCountryISO,
                        showCountryFlag: true
                    },
                    showResetButton: !this.isUpdate()
                }
            }
        };

        this.nameField = {
            fieldName: 'name',
            control: new FormControl(this.deliveryCompany?.name, Validators.required),
            label: this.TR_BASE + 'label.name'
        };
        this.fullNameField = {
            fieldName: 'fullName',
            control: new FormControl(this.deliveryCompany?.fullName, Validators.required),
            label: this.TR_BASE + 'label.fullName'
        };

        this.municipalityField = {
            fieldName: 'municipality',
            control: new FormControl(this.deliveryCompany?.municipality, Validators.required),
            label: this.TR_BASE + 'label.municipality'
        };

        this.ccField = {
            fieldName: 'cc',
            control: new FormControl(this.deliveryCompany?.cc),
            label: this.TR_BASE + 'label.cc'
        };

        this.addressField = {
            fieldName: 'address',
            label: 'xs.common.label.address',
            control: new FormControl(this.deliveryCompany?.address),
            addressOptions: {
                formOptions: {
                    type: undefined!,
                    title: true,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.STRUCTURED, XSAddressType.UNSTRUCTURED]
                }
            }
        };

        this.formGroup.addControl(this.nameField.fieldName, this.nameField.control!);
        this.formGroup.addControl(this.fullNameField.fieldName, this.fullNameField.control!);
        this.formGroup.addControl(this.ccField.fieldName, this.ccField.control!);
        this.formGroup.addControl(this.addressField.fieldName, this.addressField.control!);
        this.formGroup.addControl(this.municipalityField.fieldName, this.municipalityField.control!);
        this.formGroup.addControl(this.contactPersonField.fieldName, this.contactPersonField.control!);
    }

}
