<div [ngClass]="{ 'xs-disabled': disabled === true }" class="lce-news-article-card {{ styleClass }}">
    <div class="lce-news-article-card-image-container">
        <img (click)="onCardClick($event)" [ngClass]="{ 'xs-cursor-pointer': isClickable() }" [src]="data.coverImage" alt="Cover Image" class="lce-news-article-card-image"/>
        <lce-user-ivar
                [data]="data.createdBy"
                [showAvatarBackground]="true"
                [showOnlyAvatar]="true"
                [showStatus]="false"
                [showTooltip]="false"
                [viewDetail]="true"
                class="xs-flex xs-position-absolute xs-bottom-minus-25 xs-right-30">
        </lce-user-ivar>
    </div>
    <div class="lce-news-article-card-center">
        <span (click)="onCardClick($event)" [ngClass]="{ 'xs-cursor-pointer': isClickable() }" class="lce-news-article-card-title">
            {{ data.title | translate }}
        </span>
        <span *ngIf="data.publishedOn | xsIsNotEmpty" class="lce-news-article-card-created-on">{{ data.publishedOn! | xsDate : createdOnDateFormat }}</span>
        <span *ngIf="data.publishedOn | xsIsEmpty" class="lce-news-article-card-created-on">{{ data.createdOn | xsDate : createdOnDateFormat }}</span>
        <span class="lce-news-article-card-description">{{ data.description! | translate }}</span>
    </div>

    <lce-news-article-stats-icons [numberOfComments]="data.commentCount"
                                  [numberOfShares]="data.shareCount"
                                  [numberOfViews]="data.readCount"
                                  styleClass="xs-width-full xs-plr-15 xs-ptb-10">
    </lce-news-article-stats-icons>
</div>
