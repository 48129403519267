import { Component, Input, OnInit } from '@angular/core';
import { XSAssert, XSUtils } from '@xs/base';
import { LCEServicePointPartial } from '@lce/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';
import { LCEServicePointDialogService } from '../lce-service-point-dialog.service';

@Component({ selector: 'lce-service-point-partial-overlay', templateUrl: './lce-service-point-partial-overlay.component.html', providers: [LCEServicePointDialogService] })
export class LCEServicePointPartialOverlayComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCEServicePointPartial;

    @Input() showBorder?: boolean;
    @Input() viewRecord?: boolean;
    @Input() showBackgroundIcon?: boolean;

    constructor(private servicePointDialogService: LCEServicePointDialogService) {}

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isNull(this.showBorder)) this.showBorder = false;
        if (XSUtils.isNull(this.viewRecord)) this.viewRecord = true;
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = false;
    }

    public openDetail(): void {
        this.servicePointDialogService.openRecordDialog({ servicePointID: this.data.id });
    }
}
