<div (click)="clickEvent.emit(data)" [ngClass]="{'xs-border-discrete xs-p-15 xs-border-radius-default': showBorder}" class="xs-flex-row xs-align-items-center {{styleClass}}">
    <lce-user-partial [data]="data" styleClass="xs-width-175"></lce-user-partial>

    <div class="xs-mlr-20 xs-flex-column-center xs-position-relative">

        <xs-arrow
                colorName="secondary"
                direction="left-right"
                styleClass="xs-min-width-75-imp">
        </xs-arrow>

        <div *ngIf="showRefreshButton" class="xs-position-absolute xs-top-25">
            <xs-loader [loaderId]="LOADER_ID" [loaderSize]="20"></xs-loader>
        </div>

        <xs-icon (clickEvent)="refresh()"
                 *ngIf="showRefreshButton && !isLoaderRunning()"
                 [value]="ICON_REFRESH"
                 class="xs-position-absolute xs-top-15"
                 size="small">
        </xs-icon>
    </div>

    <xs-square-data
            (clickEvent)="orderNumberClickEvent.emit(data)"
            [clickable]="orderNumberClickable"
            [disabled]="isLoaderRunning()"
            [error]="error"
            [showBorder]="true"
            [value]="deliveryAssignmentNumber | xsToString"
            bottomText="lce.shared.delivery.request.deliveryAssigned"
            bottomTextStyleClass="xs-color-secondary">
    </xs-square-data>
</div>