import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCE_FACILITY_PARTIAL_YOPOUGON,
    LCECoreContextService,
    LCEFacilityTownHallCertificateOrderRegistrationCreate,
    LCEFacilityTownHallStampLoad,
    LCEFacilityTownHallStampLoadCreate,
    LCEFacilityTownHallStampLoadPartial,
    LCEFacilityTownHallStampLoadSearch,
    LCEFacilityTownHallStampTokenPartial,
    LCEUserPartial
} from '@lce/core';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY} from '@lce/mock/core/lce-mock-user-data';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XS_LOREM_IPSUM, XSHttpMethod, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.facilityStampLoad';

@Injectable()
export class LCEMockFacilityTownHallStampLoadHandler extends XSMockPKResourceHandler<LCEFacilityTownHallStampLoad, LCEFacilityTownHallStampLoadPartial, LCEFacilityTownHallStampLoadSearch>

    implements XSMockPKSearchable<LCEFacilityTownHallStampTokenPartial> {

    public static FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE: Map<string, LCEFacilityTownHallStampLoad> = new Map<string, LCEFacilityTownHallStampLoad>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEFacilityTownHallStampLoad>[] = [
        (facilityTownHallStampToken, query) => facilityTownHallStampToken.facility.code.toLowerCase().includes(query),
        (facilityTownHallStampToken, query) => facilityTownHallStampToken.facility.name?.toLowerCase().includes(query)
    ];
    private searchPredicates: XSMockSearchPredicate<LCEFacilityTownHallStampLoad>[] = [
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'numberOfStamps', String(facilityTownHallStampToken.numberOfStamps)),
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'facilityCodes', facilityTownHallStampToken.facility.code)
    ];

    constructor(
        private contextService: LCECoreContextService,
        private mockFacilityService: LCEMockFacilityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.index);
        this.mockDataArray = [];
    }

    getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    getStorage(): Map<string, LCEFacilityTownHallStampLoad> {
        return LCEMockFacilityTownHallStampLoadHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE;
    }

    toPartial(facilityTownHallStampToken: LCEFacilityTownHallStampLoad): LCEFacilityTownHallStampLoadPartial {
        return {
            id: facilityTownHallStampToken.id,
            createdOn: facilityTownHallStampToken.createdOn,
            facility: facilityTownHallStampToken.facility,
            size: facilityTownHallStampToken.numberOfStamps
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,

            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates})
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEFacilityTownHallStampTokenPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private createResponseData(body: LCEFacilityTownHallStampLoadCreate): LCEFacilityTownHallStampLoad {

        const facilityTownHallStampLoad: LCEFacilityTownHallStampLoad = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            facility: LCE_FACILITY_PARTIAL_YOPOUGON,
            numberOfStamps: body.numberOfStamps,
            previousTotalNumberOfStamps: XSUtils.randomInteger(200, 500),
            note: body.note,
            unload: body.unload
        };

        LCEMockFacilityTownHallStampLoadHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE.set(facilityTownHallStampLoad.id, facilityTownHallStampLoad);
        this.addResourceBaseMockData(facilityTownHallStampLoad.id);
        this.mockDataArray.push(this.buildCreateFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
        this.mockDataArray.push(this.buildUpdateFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
        this.mockDataArray.push(this.buildRegisterFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
        this.mockDataArray.push(this.buildUnregisterFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
        return facilityTownHallStampLoad;
    }

    private UpdateFacilityTownHallCertificateOrderRegistrationResponseData(facilityID: string, body: Map<String, Object>, requestURL: string): void {
        let facility = this.mockFacilityService.findOneByID(facilityID);
        if (XSUtils.isEmpty(facility)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get facility with given id : [${facilityID}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: requestURL
            });
        }
        if (!XSUtils.isEmpty(facility)) {
            if (XSUtils.isMap(body)) {
                const fieldValueMap: Map<string, any> = body as Map<string, any>;
                fieldValueMap.forEach((value: any, key: string) => {
                    XSUtils.setNestedPropertyValue(facility, key, value);
                });
            } else {
                for (const [key, value] of Object.entries(body)) {
                    XSUtils.setNestedPropertyValue(facility, key, value);
                }
            }
        }
        LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
    }

    private createFacilityTownHallCertificateOrderRegistrationResponseData(body: LCEFacilityTownHallCertificateOrderRegistrationCreate, requestURL: string): void {
        let facility = this.mockFacilityService.findOneByCode(body.facilityCode);
        if (XSUtils.isEmpty(facility)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get facility with given code : [${body.facilityCode}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: requestURL
            });
        }

        facility.certificateOrderRegistration = {};
        facility.certificateOrderRegistration = {...body};
        LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
    }

    private registerFacilityTownHallCertificateOrderRegistrationResponseData(facilityID: string, requestURL: string): void {
        let facility = this.mockFacilityService.findOneByID(facilityID);
        if (XSUtils.isEmpty(facility)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get facility with given id : [${facilityID}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: requestURL
            });
        }

        facility.certificateOrderRegistration!.registered = true;
        facility.certificateOrderRegistration!.registeredOn = new Date().toDateString();
        facility.certificateOrderRegistration!.registeredBy = LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY;
        LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
    }

    private unregisterFacilityTownHallCertificateOrderRegistrationResponseData(facilityID: string, requestURL: string): void {
        let facility = this.mockFacilityService.findOneByID(facilityID);
        if (XSUtils.isEmpty(facility)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get facility with given id : [${facilityID}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: requestURL
            });
        }
        facility.certificateOrderRegistration!.registered = false;
        facility.certificateOrderRegistration!.unregisteredOn = new Date().toDateString();
        facility.certificateOrderRegistration!.unregisteredBy = LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY;
        LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
    }

    private buildCreateFacilityTownHallCertificateOrderRegistrationMockData(facilityID: string): XSMockData {
        let requestURL = LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.registration.replace('{facilityID}', facilityID);
        return {
            id: DATASET_BASE_ID + '.createFacilityTownHallCertificateOrderRegistration',
            active: true,
            requestMethod: XSHttpMethod.POST,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => this.createFacilityTownHallCertificateOrderRegistrationResponseData(rArg.body, requestURL)

        };
    }

    private buildUpdateFacilityTownHallCertificateOrderRegistrationMockData(facilityID: string): XSMockData {
        let requestURL = LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.registration.replace('{facilityID}', facilityID);

        return {
            id: DATASET_BASE_ID + '.updateFacilityTownHallCertificateOrderUnregister',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => this.UpdateFacilityTownHallCertificateOrderRegistrationResponseData(facilityID, rArg.body, requestURL)
        };
    }

    private buildRegisterFacilityTownHallCertificateOrderRegistrationMockData(facilityID: string): XSMockData {
        let requestURL = LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.register.replace('{facilityID}', facilityID);

        return {
            id: DATASET_BASE_ID + '.registerFacilityTownHallCertificateOrderRegister',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 5000,
            getResponseData: rArg => this.registerFacilityTownHallCertificateOrderRegistrationResponseData(facilityID, requestURL)
        };
    }

    private buildUnregisterFacilityTownHallCertificateOrderRegistrationMockData(facilityID: string): XSMockData {
        let requestURL = LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.loads.unregister.replace('{facilityID}', facilityID);

        return {
            id: DATASET_BASE_ID + '.registerFacilityTownHallCertificateOrderRegistration',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 5000,
            getResponseData: rArg => this.unregisterFacilityTownHallCertificateOrderRegistrationResponseData(facilityID, requestURL)
        };
    }

    private buildRandomFacilityTownHallStampLoad(): LCEFacilityTownHallStampLoad {
        return {
            createdOn: new Date().toISOString(),
            createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
            id: XSUtils.uuid(),
            facility: LCE_FACILITY_PARTIAL_YOPOUGON,
            numberOfStamps: XSUtils.randomInteger(100, 1500),
            unload: XSUtils.randomElement([true, false]),
            previousTotalNumberOfStamps: XSUtils.randomInteger(1500, 2000),
            note: XSUtils.randomElement<string>([XS_LOREM_IPSUM.short, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long])
        };
    }

    private buildStorage(): void {
        for (let $i = 0; $i <= 11; $i++) {
            const facilityTownHallStampLoad: LCEFacilityTownHallStampLoad = this.buildRandomFacilityTownHallStampLoad();
            LCEMockFacilityTownHallStampLoadHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE.set(facilityTownHallStampLoad.id, facilityTownHallStampLoad);
            this.addResourceBaseMockData(facilityTownHallStampLoad.id);
            this.mockDataArray.push(this.buildCreateFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
            this.mockDataArray.push(this.buildUpdateFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
            this.mockDataArray.push(this.buildRegisterFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
            this.mockDataArray.push(this.buildUnregisterFacilityTownHallCertificateOrderRegistrationMockData(facilityTownHallStampLoad.facility.id));
        }
    }
}
