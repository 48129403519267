<div class="xs-flex-column xs-width-full xs-height-full {{ styleClass }}">
    <div class="xs-card">
        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
                    <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
                        {{ 'lce.shared.user.label.authentication' | translate }}
                    </span>
                </div>
                <div class="xs-flex-row"></div>
            </div>
        </div>
        <div class="xs-card-content xs-pt-50-imp">
            <div class="grid xs-fields xs-fields-spacer-30">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Temporary Password Create  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title text="xs.core.authentication.updatePasswordTitle"></xs-title>
                </div>

                <div class="col-12">
                    <xs-authentication-update-password [options]="updatePasswordOptions"></xs-authentication-update-password>
                </div>
            </div>

            <div *ngIf="showUpdatePINCode" class="grid xs-fields xs-fields-spacer-30 xs-mt-30">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Temporary PIN Code Create  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->

                <div class="col-12">
                    <xs-title styleClass="" text="xs.core.authentication.updatePINCodeTitle"></xs-title>
                </div>

                <div class="col-12">
                    <xs-authentication-update-pin-code [options]="updatePinCodeOptions" [showBorder]="false"></xs-authentication-update-pin-code>
                </div>
            </div>
        </div>
    </div>
</div>
