import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEDeliveryCompanyPartial} from '@lce/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XSIvarAvatarType} from '@xs/core';

@Component({
    selector: './lce-delivery-company-partial', template: `
        <xs-ragnar
                (avatarClickEvent)="onClick()"
                (line1ClickEvent)="onClick"
                [avatarClickable]="clickable"
                [avatar]="{type: avatarType, data: avatar, size: 'extra-small'}"
                [disabled]="disabled"
                [line1Clickable]="clickable"
                [avatarIconStyleClass]="iconStyleClass"
                [avatarImageStyleClass]="imageStyleClass"
                [line1]="data.fullName"
                [line2]="showCode ? data.code : undefined"
                [styleClass]="styleClass"
                avatarStyleClass="lce-resource-partial-avatar-icon xs-color-secondary-imp xs-font-size-20-imp"
                line1StyleClass="xs-font-size-intermediate-imp {{ fullNameStyleClass }}"
                line2StyleClass="xs-font-size-small-imp xs-color-secondary xs-mt-2-imp">
        </xs-ragnar>
    `
})
export class LCEDeliveryCompanyPartialComponent implements OnInit {
    
    ICON_DELIVERY_COMPANY = LCE_SHARED_ICON.deliveryCompany;

    @Input() styleClass?: string;
    @Input() fullNameStyleClass?: string;
    @Input() logoStyleClass?: string;
    @Input() iconStyleClass?: string;
    @Input() imageStyleClass?: string;
    @Input() disabled?: boolean;
    @Input() showCode?: boolean;
    @Input() showRecord?: boolean;
    @Input() avatarBorderRadius?: boolean;

    @Input() data: LCEDeliveryCompanyPartial;

    @Output() clickEvent = new EventEmitter<LCEDeliveryCompanyPartial>();

    avatarType: XSIvarAvatarType;
    avatar: string;

    clickable: boolean;
    protected readonly XSIvarAvatarType = XSIvarAvatarType;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.clickable = this.clickEvent.observers.length > 0;
        if (XSUtils.isEmpty(this.data.logo)) {
            this.avatarType = XSIvarAvatarType.ICON;
            this.avatar = this.ICON_DELIVERY_COMPANY;
        } else {
            this.avatarType = XSIvarAvatarType.IMAGE;
            this.avatar = this.data.logo!;
        }

    }

    public onClick(): void {
        if (!this.showRecord) return;
        this.clickEvent.emit(this.data);
    }
}