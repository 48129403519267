<div [ngClass]="{ 'lce-template-sheldon-vertical-sidebar-expanded': verticalSidebar.isExpanded(), 'lce-template-sheldon-vertical-sidebar-collapsed': !verticalSidebar.isExpanded() }"
     class="lce-template-sheldon">

    <div class="lce-template-sheldon-left-part">
        <lce-vertical-sidebar #verticalSidebar [bottomTextMenu]="true" [expandCollapse]="false" [expandMenu]="false"></lce-vertical-sidebar>
    </div>

    <div class="lce-template-sheldon-right-part">
        <div class="lce-template-sheldon-top-part">
            <lce-header></lce-header>
        </div>

        <div class="lce-template-sheldon-center-part">
            <div class="lce-template-sheldon-center-part-inner">
                <lce-breadcrumb messageTextStyleClass="xs-font-size-10-imp" styleClass="lce-template-sheldon-breadcrumb"></lce-breadcrumb>
                <div class="lce-template-sheldon-router-container sm:mt-0 xs-mt-25">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>

        <div class="lce-template-sheldon-bottom-part">
            <lce-template-footer></lce-template-footer>
        </div>
    </div>
</div>
