import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCESmartCityArticle, LCESmartCityArticleService} from '@lce/core';
import {XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, first} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESmartCityArticleDetailOptions} from './lce-smartcity-article-detail.options';

@Component({
    selector: 'lce-smartcity-article-detail',
    templateUrl: 'lce-smartcity-article-detail.component.html',
    host: {class: 'xs-width-full'}
})
export class LCESmartCityArticleDetailComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE_SMART_CITY_ARTICLE_TYPE: string = LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE;

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() articleID?: string;
    @Input() articleCode?: string;

    @Input() data?: LCESmartCityArticle;

    @Input() options?: LCESmartCityArticleDetailOptions;

    @Output() closeEvent = new EventEmitter<LCESmartCityArticle>();

    error: any;

    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveArticle()
    };

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    private articleIDCode?: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private smartcityService: LCESmartCityArticleService) {
        super();
        this.handleRouting();
    }

    get createdOnDateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN : XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.articleID = this.dialogConfig.data.articleID;
            this.articleCode = this.dialogConfig.data.articleCode;
            this.data = this.dialogConfig.data.article;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }

        this.initializeIDCode();

        if (!XSUtils.isEmpty(this.articleIDCode)) {
            this.retrieveArticle();
        } else {
            this.loaderService.startLoader(this.LOADER_ID);
            this.activatedRoute.params.pipe(first()).subscribe((params) => {
                params['code'] ? this.articleIDCode = params['code'] : this.articleIDCode = params['id'];
                this.retrieveArticle();

            });
        }

        if (XSUtils.isEmpty(this.articleIDCode) && XSUtils.isEmpty(this.data)) {
            throw new Error('articleIDCode and data cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.articleIDCode) && !XSUtils.isEmpty(this.data)) {
            throw new Error('articleIDCode and data cannot both be set at the same time.');
        }

        if (!XSUtils.isEmpty(this.articleIDCode)) this.retrieveArticle();

        this.handleDefaults();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public goToArticle(): void {
        this.router.navigate(['/smartcity/articles', this.data!.id]).then();
        this.closeDialog();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.data);
    }

    private handleRouting(): void {
        if (XSUtils.isEmpty(this.options)) this.options = {};
        const routerStateData = this.router.getCurrentNavigation()?.extras?.state;

        if (!XSUtils.isEmpty(routerStateData?.showAudit)) {
            this.options!.showAudit = routerStateData!.options.showAudit;
        }

        if (!XSUtils.isEmpty(routerStateData?.showQRcode)) {
            this.options!.showQRcode = routerStateData!.options.showQRcode;
        }
    }

    private retrieveArticle(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.smartcityService
                .retrieveByIDOrCode(this.articleIDCode!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (article: LCESmartCityArticle) => {
                        this.data = article;
                        this.articleIDCode = this.data.id;
                    },
                    error: error => this.error = error
                })
        );
    }

    private initializeIDCode(): void {
        if (!XSUtils.isEmpty(this.articleID) && !XSUtils.isEmpty(this.articleCode)) {
            throw new Error('smartCityArticleID and smartCityArticleCode cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.articleID)) this.articleIDCode = this.articleID;
        if (!XSUtils.isEmpty(this.articleCode)) this.articleIDCode = this.articleCode;
    }

    private handleDefaults(): void {
        if (XSUtils.isEmpty(this.options)) this.options = {};
        if (!XSUtils.isNull(this.options!.showAudit)) this.options!.showAudit = true;
        if (!XSUtils.isNull(this.options!.showQRcode)) this.options!.showQRcode = true;
    }
}
