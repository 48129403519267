<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card xs-border-radius-none">
        <div class="xs-card-header xs-border-radius-none-imp xs-container-left-stripe">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
                    {{ TR_BASE + 'general' | translate }}
                </span>
                <div *ngIf="readonly !== true" class="xs-flex-row xs-pr-10">
                    <lce-certificate-order-actions
                            (errorEvent)="onError($event)"
                            (statusUpdatedEvent)="onStatusUpdated($event)"
                            [data]="data">
                    </lce-certificate-order-actions>
                </div>
            </div>
        </div>

        <div class="xs-card-content xs-pt-0-imp">
            <div class="xs-flex-row xs-justify-content-space-between xs-align-items-center xs-mtb-30">
                <lce-user-resource-audit [data]="data"></lce-user-resource-audit>

                <div class="xs-flex-row xs-align-items-center xs-pl-150 xs-height-30">
                    <xs-error
                            *ngIf="error"
                            [error]="error"
                            [message]="TR_BASE + 'operationError'"
                            [showErrorDetailButton]="true"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                </div>
            </div>

            <lce-certificate-order-detail [data]="data" [showBorder]="true" [stampTokenClickable]="stampTokenClickable"></lce-certificate-order-detail>
            <lce-certificate [data]="data.certificate" [showBorder]="true" styleClass="xs-mt-25"></lce-certificate>
            <lce-certificate-order-procuration *ngIf="data.procuration | xsIsNotEmpty" [data]="data.procuration!" styleClass="xs-mt-25"></lce-certificate-order-procuration>
            <lce-certificate-order-payment *ngIf="data.charge | xsIsNotEmpty" [data]="data.charge!" styleClass="xs-mt-25"></lce-certificate-order-payment>

        </div>
        <div class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="data.id" styleClass="xs-mt-40"></xs-data-id>
        </div>
    </div>
</div>