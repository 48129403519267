import {CommonModule, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule} from '@angular/common/http';
import localeFR from '@angular/common/locales/fr';
import {APP_INITIALIZER, ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {LCECoreContextService, LCEEnvironmentService, LCEHttpClientService, LCEUserMunicipalEmployeeService, LCEUserService} from '@lce/core';
import {LCEEventProcessorService, LCESharedAuthorizationService, LCESharedContextService} from '@lce/shared';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {XSAppInitializerBaseService, XSBaseContextService, XSBaseEnvironmentService, XSHttpClientService, XSLoggerService, XSStorageService, XSWebSocketClientService} from '@xs/base';
import {XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, XSCommonContextService, XSCommonEnvironmentService, XSCountryService, XSLoaderType} from '@xs/common';
import {
    XS_TOKEN_CORE_ENDPOINT_GEOLOCATE,
    XS_TOKEN_CORE_ICON,
    XSAppInitializerCoreService,
    XSAuthenticationBaseService,
    XSCoreContextService,
    XSCoreEnvironmentService,
    XSCoreModule,
    XSEnsureModuleImportedOnce,
    XSErrorHelper,
    XSErrorService,
    XSLocalStorageService
} from '@xs/core';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import {environment} from '../environments/environment';
import {ADDITIONAL_IMPORTS, ADDITIONAL_PROVIDERS} from '../environments/environment-common';
import {LCECMDAuthenticationComponent} from './authentication/lce-cmd-authentication.component';
import {LCE_CMD_ENDPOINT} from './core/constants/lce-cmd-endpoint.constant';
import {LCE_CMD_ICON} from './core/constants/lce-cmd-icon.constant';
import {LCECMDErrorHandler} from './core/handlers/lce-cmd-error-handler';
import {LCECMDErrorHelper} from './core/helpers/lce-cmd-error.helper';
import {LCECMDHttpInterceptor} from './core/interceptors/lce-cmd-http-interceptor';
import {LCECMDAppInitializerService} from './core/services/lce-cmd-app-initializer.service';
import {LCECMDAuthenticationService} from './core/services/lce-cmd-authentication.service';
import {LCECMDContextService} from './core/services/lce-cmd-context.service';
import {LCECMDEnvironmentService} from './core/services/lce-cmd-environment.service';
import {LCECMDErrorService} from './core/services/lce-cmd-error.service';
import {LCECMDEventProcessorService} from './core/services/lce-cmd-event-processor.service';
import {LCECMDRoutingModule} from './lce-cmd-routing.module';
import {LCECMDComponent} from './lce-cmd.component';
import {LCEUserEmployeeAuthenticationService} from '../../../lce-core/services/user/lce-user-employee-authentication.service';
import {LCECMDUserAuthenticationService} from './core/services/lce-cmd-user-authentication.service';
import {XS_PAYMENT_TOKEN_ENDPOINT_BASE} from '@xs/payment/base';
import {LCEMBOAuthorizationService} from '@lce-mbo/services/lce-mbo-authorization.service';

// ------------------------------------------------------------------------------------------------------
// ---------------------------------------- Translation -------------------------------------------------
// ------------------------------------------------------------------------------------------------------

registerLocaleData(localeFR);

export function HttpLoaderFactory(httpBackend: HttpBackend) {
    const version = Date.now(); // Workaround to force the browser to take the new version of translation files if needed.
    return new MultiTranslateHttpLoader(new HttpClient(httpBackend), [
        {prefix: './assets/i18n/xs-primeng/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-common/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-base/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-payment-checkout/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-core-authentication/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/xs-country/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-core/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-shared/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-template/', suffix: '.json?v=' + version},
        {prefix: './assets/i18n/lce-features/', suffix: '.json?v=' + version}
    ]);
}

// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------

@NgModule({
    declarations: [
        // ***
        LCECMDComponent,
        LCECMDAuthenticationComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.common.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpBackend]
            }
        }),
        LoggerModule.forRoot({level: NgxLoggerLevel.TRACE, timestampFormat: XSLoggerService.TIMESTAMP_FORMAT}),
        XSCoreModule,
        LCECMDRoutingModule,
        ADDITIONAL_IMPORTS
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        // --- * ---
        ADDITIONAL_PROVIDERS,
        // --- Initializers ---
        {provide: XSAppInitializerBaseService, useExisting: LCECMDAppInitializerService},
        {provide: XSAppInitializerCoreService, useExisting: LCECMDAppInitializerService},
        {
            provide: APP_INITIALIZER,
            useFactory: (appInitializerService: LCECMDAppInitializerService) => () => appInitializerService.initialize(),
            deps: [LCECMDAppInitializerService],
            multi: true // means we could have multiple APP_INITIALIZER.
        },
        // --- Interceptors ---
        {provide: HTTP_INTERCEPTORS, useClass: LCECMDHttpInterceptor, multi: true},

        // --- Services ---
        {provide: XSHttpClientService, useExisting: LCEHttpClientService},

        {provide: XSErrorHelper, useExisting: LCECMDErrorHelper},
        {provide: ErrorHandler, useExisting: LCECMDErrorHandler},
        {provide: XSErrorService, useExisting: LCECMDErrorService},

        {provide: XSBaseEnvironmentService, useExisting: LCECMDEnvironmentService},
        {provide: XSCommonEnvironmentService, useExisting: LCECMDEnvironmentService},
        {provide: XSCoreEnvironmentService, useExisting: LCECMDEnvironmentService},
        {provide: LCEEnvironmentService, useExisting: LCECMDEnvironmentService},

        {provide: XSBaseContextService, useExisting: LCECMDContextService},
        {provide: XSCommonContextService, useExisting: LCECMDContextService},
        {provide: XSCoreContextService, useExisting: LCECMDContextService},
        {provide: LCECoreContextService, useExisting: LCECMDContextService},
        {provide: LCESharedContextService, useExisting: LCECMDContextService},

        {provide: XSStorageService, useExisting: XSLocalStorageService},

        {provide: XSAuthenticationBaseService, useExisting: LCECMDAuthenticationService},
        {provide: LCEUserEmployeeAuthenticationService, useExisting: LCECMDUserAuthenticationService},
        {provide: LCEUserService, useExisting: LCEUserMunicipalEmployeeService},

        {provide: LCESharedAuthorizationService, useExisting: LCEMBOAuthorizationService},

        {provide: LCEEventProcessorService, useExisting: LCECMDEventProcessorService},

        // --- * ---
        XSWebSocketClientService,

        // --- * ---
        XSCountryService,

        // --- Tokens ---
        {provide: XS_PAYMENT_TOKEN_ENDPOINT_BASE, useValue: 'payment'},

        {provide: XS_TOKEN_CORE_ENDPOINT_GEOLOCATE, useValue: LCE_CMD_ENDPOINT.geolocate},
        {provide: XS_TOKEN_CORE_ICON, useValue: LCE_CMD_ICON},
        {provide: XS_TOKEN_COMMON_LOADER_DEFAULT_TYPE, useValue: XSLoaderType.RECTANGLE_BOUNCE}
    ],
    exports: [],
    bootstrap: [LCECMDComponent]
})
export class LCECMDModule extends XSEnsureModuleImportedOnce {
    public constructor(@SkipSelf() @Optional() module: LCECMDModule) {
        super(module);
    }
}
