<div class="xs-flex-column-center xs-width-full {{ styleClass }}">
    <div class="grid xs-fields xs-fields-spacer-5">
        <div class="col-12 xl:col-6">
            <xs-input-field-text [options]="firstNameField"></xs-input-field-text>
        </div>
        <div class="col-12 xl:col-6">
            <xs-input-field-text [options]="lastNameField"></xs-input-field-text>
        </div>
        <div class="col-12 xl:col-6">
            <xs-input-field-phone-number [options]="primaryPhoneNumberField"></xs-input-field-phone-number>
        </div>
        <div class="col-12 xl:col-6">
            <xs-input-field-email [options]="emailField"></xs-input-field-email>
        </div>
        <div class="col-12">
            <xs-input-field-auto-complete [appendTo]="appendTo" [options]="municipalityOfResidenceField">
                <ng-template let-municipality xsTemplate="item">
                    <lce-municipality-partial [data]="municipality"></lce-municipality-partial>
                </ng-template>
            </xs-input-field-auto-complete>
        </div>
    </div>
</div>
