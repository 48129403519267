import {Component, OnInit} from '@angular/core';
import {LCEDistrictDialogService, LCEDistrictsFeatureService} from '@lce/shared';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';
import {LCEDistrictCanOptions} from '../../../../../lce-core';

@Component({
    selector: 'lce-cmd-districts',
    templateUrl: './lce-cmd-districts.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEDistrictDialogService]
})
export class LCECMDDistrictsComponent implements OnInit {
    ICON = LCE_CMD_ICON;

    readonly TR_BASE: string = 'lce.shared.district.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    readonly canOptions: LCEDistrictCanOptions = {
        update: true
    };

    constructor(private featureService: LCEDistrictsFeatureService, private districtDialogService: LCEDistrictDialogService) {
    }

    ngOnInit(): void {
    }

    public onCreate(): void {
        this.districtDialogService.openDistrictCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
