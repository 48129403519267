import {LCE_SHARED_ICON} from '@lce/shared';

export const LCE_CMD_ICON = {
    ...LCE_SHARED_ICON,
    scheduled: 'fas fa-stopwatch',
    review: 'fas fa-check-square',
    // ---
    resources: 'fas fa-sitemap',
    equals: 'fas fa-equals',
    menuBlock: 'fas fa-th-large',
    piSend: 'pi pi-send',
    piComment: 'pi pi-comment'
};
