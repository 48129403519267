import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCEMunicipalityPartial, LCEServicePoint, LCEServicePointCreate, LCEServicePointService} from '@lce/core';
import {LOG, XSAddressType, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldAddressOptions, XSInputFieldBaseOptions, XSInputFieldContactPersonOptions, XSInputFieldPhoneNumberOptions, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-service-point-create-update',
    templateUrl: './lce-service-point-create-update.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCEServicePointCreateUpdateComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.servicePoint.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateServicePointCentralLoader';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() userCountryISO: string;

    @Input() servicePointID?: string;
    @Input() servicePoint?: LCEServicePoint;

    @Output() saveEvent = new EventEmitter<LCEServicePoint>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEServicePoint>();

    formGroup: FormGroup = new FormGroup({});
    nameField: XSInputFieldTextOptions;
    fullNameField: XSInputFieldTextOptions;
    subNameField: XSInputFieldTextOptions;
    municipalityField: XSInputFieldBaseOptions;
    contactPhoneNumberField: XSInputFieldPhoneNumberOptions;
    contactEmailField: XSInputFieldTextOptions;
    addressField: XSInputFieldAddressOptions;
    primaryContactPersonField: XSInputFieldContactPersonOptions;
    secondaryContactPersonField: XSInputFieldContactPersonOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveServicePoint()
    };
    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private servicePointService: LCEServicePointService) {
        super();
    }

    get headerTitle(): string {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.servicePoint?.fullName;
        if (XSUtils.isEmpty(title)) title = 'Service Point';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.servicePoint?.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.servicePointID = this.dialogConfig.data.servicePointID;
            this.servicePoint = this.dialogConfig.data.servicePoint;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.contentStyleClass = this.dialogConfig.data.contentStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.servicePoint));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.servicePoint)) {
            if (!XSUtils.isEmpty(this.servicePoint)) this.servicePointID = this.servicePoint!.id;
            this.initialize();
        } else this.retrieveServicePoint();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isUpdate(): boolean {
        return !XSUtils.isEmpty(this.servicePointID);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.servicePointID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.servicePointID) && XSUtils.isEmpty(this.servicePoint);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.hasFormDataChanged();
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.nameField.control?.value) ||
            !XSUtils.isEmpty(this.fullNameField.control?.value) ||
            !XSUtils.isEmpty(this.subNameField.control?.value) ||
            !XSUtils.isEmpty(this.municipalityField.control?.value) ||
            !XSUtils.isEmpty(this.contactPhoneNumberField.control?.value) ||
            !XSUtils.isEmpty(this.contactEmailField.control?.value) ||
            !XSUtils.isEmpty(this.addressField.control?.value) ||
            !XSUtils.isEmpty(this.primaryContactPersonField.control?.value) ||
            !XSUtils.isEmpty(this.secondaryContactPersonField.control?.value)
        ) {
            return false;
        }
        return true;
    }

    public fillForm(): void {
        this.municipalityField.control?.setValue({
            id: '7067ab2e-6298-42e7-919e-a4a446cdee4e',
            code: 'LCE-MUN-ABJ-YOP',
            name: 'Yopougon',
            abbreviation: 'YOP',
            fullName: 'Commune de Yopougon',
            district: {
                id: 'xxx',
                code: 'LCE-DST-ABJ',
                name: 'Abidjan',
                abbreviation: 'ABJ',
                fullName: 'District Autonome d\'Abidjan'
            }
        } as LCEMunicipalityPartial);
        this.nameField.control?.setValue('Pentagon Habor');
        this.fullNameField.control?.setValue('Pentagon Habor Yopougon Selmer');
        this.subNameField.control?.setValue('Washington DC');
        this.contactPhoneNumberField.control?.setValue('+2250101011234');
        this.contactEmailField.control?.setValue('pentagon.habor@defence.us');

        this.addressField.control?.setValue({
            title: 'Patisserie Chez Paul',
            type: XSAddressType.UNSTRUCTURED,
            line1: 'Rue Des Jardins, à 100 m du Restaurant l\'Automatic',
            line2: 'Deux-Plateaux, Vallon',
            city: 'abidjan',
            countryISO: 'ci',
            coordinate: {latitude: 5.362178105658659, longitude: -3.989454615343488}
        });

        this.primaryContactPersonField.control?.setValue({
            name: {firstName: 'Jane', lastName: 'Doe'},
            primaryPhoneNumber: '+2250707972354',
            email: 'jane.doe@iro-xs.com',
            note: 'Reachable only during the day between 10:00AM and 2:00PM.'
        });

        this.secondaryContactPersonField.control?.setValue({
            name: {firstName: 'John', lastName: 'Doe'},
            primaryPhoneNumber: '+2250707972343',
            email: 'john.doe@iro-xs.com'
        });
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private retrieveServicePoint(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.servicePointService
                .retrieve(this.servicePointID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: retrieveServicePoint => {
                        this.servicePoint = retrieveServicePoint;
                        this.initialize();
                    },
                    error: error => (this.retrieveError = error)
                })
        );
    }

    private validateFormGroup(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
    }

    private update(): void {
        this.validateFormGroup();
        if (!this.formGroup.valid) return;
        LOG().debug('Updating servicePoint [servicePointID: ' + this.servicePointID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('servicePoint not updated. No change detected ! [code: ' + this.servicePoint!.code + ', id: ' + this.servicePointID + ']');
            this.saveEvent.emit(undefined);
            return;
        }

        this.createUpdateLoading = true;

        this.subscription.add(
            this.servicePointService
                .update(this.servicePointID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: servicePoint => {
                        const updatedservicePoinID = servicePoint.id;
                        LOG().debug('updateServicePoint successfully updated :-) [code: ' + servicePoint.code + ', id: ' + updatedservicePoinID + ']');
                        this.saveEvent.emit(servicePoint);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        this.validateFormGroup();
        if (!this.formGroup.valid) return;
        LOG().debug('Creating service Point ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const servicePointCreate = this.buildCreate(formData);
        this.subscription.add(
            this.servicePointService
                .create(servicePointCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: servicePoint => {
                        LOG().debug('servicePointCreate successfully saved :-) [code: ' + servicePoint.code + ', id: ' + servicePoint.id + ']');
                        this.saveEvent.emit(servicePoint);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private hasFormDataChanged(): boolean {
        return this.buildUpdate(this.formGroup.getRawValue()).size > 0;
    }

    private buildUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (XSUtils.trim(formData.name) !== this.servicePoint?.name) fieldValueMap.set('name', XSUtils.trim(formData.name));
        if (XSUtils.trim(formData.fullName) !== this.servicePoint?.fullName) fieldValueMap.set('fullName', XSUtils.trim(formData.fullName));
        if (XSUtils.trim(formData.subName) !== this.servicePoint?.subName) fieldValueMap.set('subName', XSUtils.trim(formData.subName));
        if (!XSUtils.equal(formData.municipality?.code, this.servicePoint?.municipality.code)) fieldValueMap.set('municipality', XSUtils.trim(formData.municipality));
        if (XSUtils.trim(formData.contactPhoneNumber) !== this.servicePoint?.contactPhoneNumber) fieldValueMap.set('contactPhoneNumber', XSUtils.trim(formData.contactPhoneNumber));
        if (XSUtils.trim(formData.contactEmail) !== this.servicePoint?.contactEmail) fieldValueMap.set('contactEmail', XSUtils.trim(formData.contactEmail));
        if (!XSUtils.equal(formData.address, this.servicePoint?.address)) fieldValueMap.set('address', formData.address);
        if (!XSUtils.equal(formData.primaryContactPerson, this.servicePoint?.primaryContactPerson)) fieldValueMap.set('primaryContactPerson', formData.primaryContactPerson);
        if (!XSUtils.equal(formData.secondaryContactPerson, this.servicePoint?.secondaryContactPerson)) fieldValueMap.set('secondaryContactPerson', formData.secondaryContactPerson);
        return fieldValueMap;
    }

    private buildCreate(formData: any): LCEServicePointCreate {
        let servicePointCreate: LCEServicePointCreate = {
            name: XSUtils.trim(formData.name),
            fullName: XSUtils.trim(formData.fullName),
            subName: XSUtils.trim(formData.subName),
            municipalityCode: XSUtils.trim(formData.municipality.code),
            contactPhoneNumber: XSUtils.trim(formData.contactPhoneNumber),
            contactEmail: XSUtils.trim(formData.contactEmail),
            address: formData.address,
            primaryContactPerson: formData.primaryContactPerson,
            secondaryContactPerson: formData.secondaryContactPerson
        };
        return servicePointCreate;
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            }
        };
    }

    private buildFields(): void {
        this.municipalityField = {
            fieldName: 'municipality',
            control: new FormControl(this.servicePoint?.municipality, Validators.required),
            label: 'lce.shared.label.municipality'
        };
        this.nameField = {
            fieldName: 'name',
            control: new FormControl(this.servicePoint?.name, Validators.required),
            label: this.TR_BASE_LABEL + 'name'
        };
        this.fullNameField = {
            fieldName: 'fullName',
            control: new FormControl(this.servicePoint?.fullName, Validators.required),
            label: this.TR_BASE_LABEL + 'fullName'
        };
        this.subNameField = {
            fieldName: 'subName',
            control: new FormControl(this.servicePoint?.subName),
            label: this.TR_BASE_LABEL + 'subName'
        };
        this.contactPhoneNumberField = {
            fieldName: 'contactPhoneNumber',
            label: 'xs.core.label.phoneNumber',
            control: new FormControl(this.servicePoint?.contactPhoneNumber),
            countryISO: 'ci',
            showCountryFlag: true
        };
        this.contactEmailField = {
            fieldName: 'contactEmail',
            control: new FormControl(this.servicePoint?.contactEmail),
            label: 'xs.core.label.email'
        };
        this.addressField = {
            fieldName: 'address',
            label: 'xs.core.label.address',
            control: new FormControl(this.servicePoint?.address, Validators.required),
            addressOptions: {
                dataOptions: {
                    capitalize: true
                },
                formOptions: {
                    type: this.servicePoint?.address?.type ? this.servicePoint.address.type : undefined!,
                    showResetButton: !this.isUpdateMode()
                }
            }
        };
        this.primaryContactPersonField = {
            fieldName: 'primaryContactPerson',
            label: 'xs.core.label.primaryContactPerson',
            control: new FormControl(this.servicePoint?.primaryContactPerson, Validators.required),
            contactPersonOptions: {
                formOptions: {
                    showDescriptionField: true,
                    showSecondaryPhoneNumberField: false,
                    showEmailField: true,
                    showNoteField: true,
                    primaryPhoneNumber: {
                        countryISO: this.userCountryISO,
                        showCountryFlag: true
                    },
                    showResetButton: !this.isUpdate()
                }
            }
        };
        this.secondaryContactPersonField = {
            fieldName: 'secondaryContactPerson',
            label: 'xs.core.label.secondaryContactPerson',
            control: new FormControl(this.servicePoint?.secondaryContactPerson),
            contactPersonOptions: {
                formOptions: {
                    showDescriptionField: true,
                    showSecondaryPhoneNumberField: false,
                    showEmailField: true,
                    showNoteField: true,
                    primaryPhoneNumber: {
                        countryISO: this.userCountryISO,
                        showCountryFlag: true
                    },
                    showResetButton: !this.isUpdate()
                }
            }
        };
        this.formGroup.addControl(this.municipalityField.fieldName, this.municipalityField.control!);
        this.formGroup.addControl(this.nameField.fieldName, this.nameField.control!);
        this.formGroup.addControl(this.fullNameField.fieldName, this.fullNameField.control!);
        this.formGroup.addControl(this.subNameField.fieldName, this.subNameField.control!);
        this.formGroup.addControl(this.contactPhoneNumberField.fieldName, this.contactPhoneNumberField.control!);
        this.formGroup.addControl(this.contactEmailField.fieldName, this.contactEmailField.control!);
        this.formGroup.addControl(this.addressField.fieldName, this.addressField.control!);
        this.formGroup.addControl(this.primaryContactPersonField.fieldName, this.primaryContactPersonField.control!);
        this.formGroup.addControl(this.secondaryContactPersonField.fieldName, this.secondaryContactPersonField.control!);
    }
}
