<xs-button (clickEvent)="overlayMenu.toggle($event)" icon="{{ ICON_MENU }} xs-font-size-23-imp" type="icon"></xs-button>
<p-overlayPanel #overlayMenu styleClass="xs-mt-25-imp xs-pb-15 xs-pl-15 xs-width-full xs-max-width-750">
    <ng-template pTemplate>
        <div class="grid lce-context-menu">
            <div class="col-5 xs-flex-column xs-border-right-discrete xs-overflow-y-auto xs-max-height-750">
                <xs-text
                        [fontWeight600]="true"
                        color="secondary"
                        styleClass="xs-mb-50"
                        value="{{ TR_BASE }}additionalMenu">
                </xs-text>

                <div class="xs-flex-row xs-align-items-center xs-pb-20 xs-border-bottom-discrete">
                    <lce-brand
                            (clickEvent)="handleSTNBrandClick()"
                            *ngIf="options!.can!.view!.stnButton"
                            class="xs-max-width-100"
                            letterSpacing="4px"
                            styleClass="xs-height-30-imp"
                            text="STN"
                            textStyleClass="xs-font-size-14">
                    </lce-brand>
                    <lce-brand
                            (clickEvent)="handleDDBBrandClick()"
                            *ngIf="options!.can!.view!.ddbButton"
                            class="xs-max-width-100 xs-ml-50"
                            letterSpacing="4px"
                            styleClass="xs-height-30-imp"
                            text="DDB"
                            textStyleClass="xs-font-size-14">
                    </lce-brand>
                </div>
                <xs-ivar
                        (line1Click)="handleGlobalSearchClick()"
                        line1="{{ TR_BASE }}globalSearch"
                        line2="{{ TR_BASE }}globalSearchDescription"
                        line2StyleClass="xs-font-size-small-imp xs-mt-0-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleEventsClick()"
                        line1="{{ TR_BASE }}events"
                        line2="{{ TR_BASE }}eventsDescription"
                        line2StyleClass="xs-font-size-small-imp xs-mt-0-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleHelpClick()"
                        [disabled]="true"
                        line1="{{ TR_BASE }}help"
                        line2="{{ TR_BASE }}helpDescription"
                        line2StyleClass="xs-font-size-small-imp xs-mt-0-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleAboutClick(); overlayMenu.hide();"
                        line1="{{ TR_BASE }}about"
                        line2="{{ TR_BASE }}aboutDescription"
                        line2StyleClass="xs-font-size-small-imp xs-mt-0-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleReportAnIssueClick()"
                        [disabled]="true"
                        line1="{{ TR_BASE }}reportIssue"
                        line2="{{ TR_BASE }}reportIssueDescription"
                        line2StyleClass="xs-font-size-small-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleShowcaseClick()"
                        *ngIf="canViewShowcase()"
                        line1="{{ TR_BASE }}showcase"
                        line2="{{ TR_BASE }}showcaseDescription"
                        line2StyleClass="xs-font-size-small-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
                <xs-ivar
                        (line1Click)="handleAuthorizationTesterClick(); overlayMenu.hide();"
                        *ngIf="canViewAuthorizationTester()"
                        line1="{{ TR_BASE }}authorizationTester"
                        line2="{{ TR_BASE }}authorizationTesterDescription"
                        line2StyleClass="xs-font-size-small-imp"
                        styleClass="xs-mt-30">
                </xs-ivar>
            </div>
            <div class="col-7 xs-flex-column xs-pl-25">
                <xs-text
                        [fontWeight600]="true"
                        color="secondary"
                        value="{{ TR_BASE }}applicationSettings">
                </xs-text>
                <div class="xs-flex-row-center xs-mtb-25">
                    <xs-icon [value]="ICON_PARAMETERS" color="discrete" size="50px" styleClass="xs-opacity-60"></xs-icon>
                </div>
                <xs-input-field-switch [options]="darkModeFieldOptions" styleClass="xs-mt-20"></xs-input-field-switch>
                <xs-input-field-switch [options]="languageFieldOptions" styleClass="xs-mt-20"></xs-input-field-switch>
                <xs-input-field-switch *ngIf="options!.can!.view!.soundAlert" [options]="soundAlertFieldOptions" styleClass="xs-mt-20"></xs-input-field-switch>
                <xs-input-field-switch *ngIf="options!.can!.view!.globalCache" [options]="globalCacheFieldOptions" styleClass="xs-mt-20"></xs-input-field-switch>

                <div *ngIf="options!.can!.view!.clearCache" class="xs-flex-column xs-disabled xs-mt-50">
                    <xs-button-text (clickEvent)="onClearCache()" label="{{ TR_BASE }}clearCache" size="intermediate"></xs-button-text>
                    <xs-text
                            color="secondary"
                            size="small"
                            styleClass="xs-mt-2"
                            value="{{ TR_BASE }}clearCacheDescription">
                    </xs-text>
                </div>

                <div *ngIf="options!.can!.view!.clearStorage" class="xs-flex-column xs-disabled xs-mt-25">
                    <xs-button-text (clickEvent)="onClearStorage()" label="{{ TR_BASE }}clearStorage" size="intermediate"></xs-button-text>
                    <xs-text
                            color="secondary"
                            size="small"
                            styleClass="xs-mt-2"
                            value="{{ TR_BASE }}clearStorageDescription">
                    </xs-text>
                </div>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>