<div [ngClass]="{ 'xs-disabled': disabled, 'xs-border-discrete xs-border-radius-default': showBorder}" class="xs-card xs-min-width-500 {{ styleClass }}">

    <div *ngIf="title | xsIsNotEmpty" class="xs-card-header xs-pb-40">
        <div class="xs-flex-column">
            <span [innerHTML]="title" class="xs-display-block xs-font-size-default xs-font-weight-500 {{ titleStyleClass }}"></span>
            <span
                    *ngIf="subtitle | xsIsNotEmpty"
                    [innerHTML]="subtitle"
                    class="xs-display-block xs-font-size-small xs-mt-1 {{ subtitleStyleClass }}">
            </span>
        </div>
    </div>

    <div
            [style.max-height]="containerMaxHeight"
            class="xs-card-content xs-position-relative xs-card-content-scrollable xs-min-height-250 xs-ptb-30-imp">

        <div class="xs-absolute-center-vh">
            <xs-loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>
        <xs-error *ngIf="!isLoaderRunning() && hasError()"
                  [error]="error"
                  [showErrorDetailButton]="true"
                  class="xs-flex-row-center"
                  message="lce.shared.event.label.errorSearchEvents"
                  mode="inline">
        </xs-error>

        <div *ngIf="!isLoaderRunning() && !hasError() && data | xsIsEmpty" class="xs-flex-row-center xs-pt-15">
            <xs-not-found
                    [icon]="EVENT_ICON"
                    [showIcon]="true"
                    iconSize="100px"
                    text="lce.shared.certificateOrderRecord.noEvent">
            </xs-not-found>
        </div>

        <div *ngIf="!isLoaderRunning() && !hasError() && data | xsIsNotEmpty">
            <p-timeline [value]="data" styleClass="{{ timelineStyleClass }}">
                <ng-template let-event pTemplate="opposite">

                    <lce-event-name-type
                            *ngIf="!isFakeData(event.id)"
                            [eventName]="event.eventName"
                            [style.height]="verticalGapHeight"
                            [type]="event.type"
                            eventNameStyleClass="xs-font-size-intermediate"
                            styleClass="{{eventStyleClass}}">
                    </lce-event-name-type>

                    <div *ngIf="isFakeData(event.id)" class="xs-flex-column xs-min-height-150">
                        <p-skeleton
                                animation="none"
                                animation="none"
                                borderRadius="12px"
                                height="10px"
                                styleClass="xs-mb-5"
                                width="100px">
                        </p-skeleton>
                        <p-skeleton
                                animation="none"
                                animation="none"
                                borderRadius="12px"
                                height="10px"
                                width="50px">
                        </p-skeleton>
                    </div>
                </ng-template>

                <ng-template let-event pTemplate="marker">
                    <span class="xs-flex xs-width-12 xs-height-12 xs-border-radius-10 xs-background-color-discrete"></span>
                </ng-template>

                <ng-template let-event pTemplate="content">

                    <div *ngIf="dataAuditTemplateRef">
                        <ng-container *ngTemplateOutlet="dataAuditTemplateRef!; context: { $implicit: event }"></ng-container>
                    </div>
                    <div *ngIf="!dataAuditTemplateRef">
                        <div *ngIf="!isFakeData(event.id)" class="xs-flex-column">
                            <lce-event-data-row
                                    [data]="event.data"
                                    [resourceType]="event.resourceType"
                                    styleClass="xs-mb-2">
                            </lce-event-data-row>
                            <xs-resource-audit-on-by
                                    (clickEvent)="onAuditByClick($event,event)"
                                    [byStyleClass]="auditByStyleClass"
                                    [clickable]="auditByClickable"
                                    [data]="{on:event.createdOn, by: event.createdBy}"
                                    [onStyleClass]="auditOnStyleClass"
                                    [oneLine]="true"
                                    styleClass="xs-mt-3 {{auditStyleClass}}">
                            </xs-resource-audit-on-by>
                        </div>
                        <div *ngIf="isFakeData(event.id)" class="xs-flex-column">
                            <p-skeleton
                                    animation="none"
                                    borderRadius="12px"
                                    height="10px"
                                    styleClass="xs-mb-5"
                                    width="100px">
                            </p-skeleton>
                            <p-skeleton
                                    animation="none"
                                    borderRadius="12px"
                                    height="10px"
                                    width="50px">
                            </p-skeleton>
                        </div>
                    </div>
                </ng-template>
            </p-timeline>
        </div>

    </div>
</div>
