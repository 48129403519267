import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {XSDialogable, XSInputFieldDropdownOptions} from '@xs/core';
import {LOG, XSAssert, XSUtils} from '@xs/base';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSFormUtils, XSLabelValue} from '@xs/common';
import {
    LCECertificateOrderPriceRequest,
    LCECertificateType,
    LCEDeliveryDestination,
    LCEDeliveryDestinationCustomLocation,
    LCEDeliveryDestinationInternational,
    LCEDeliveryDestinationType,
    LCEShippingInternationalProvider
} from '@lce/core';
import {LCECertificateOrderPriceDetailsComponent} from '../price-details/lce-certificate-order-price-details.component';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderUtils} from '../lce-certificate-order.utils';

@Component({selector: 'lce-certificate-order-price-estimator', templateUrl: './lce-certificate-order-price-estimator.component.html'})
export class LCECertificateOrderPriceEstimatorComponent extends XSDialogable implements OnInit {

    readonly CONFIRMATION_ICON = LCE_SHARED_ICON.confirmation;
    readonly TR_BASE: string = 'lce.shared.certificateOrders.create.certificate.';
    readonly TR_BASE_CERTIFICATE_TYPE: string = 'lce.core.certificateTypeMedium.';

    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() showBorder?: boolean;
    @Input() PriceDetailsShowDefaultMessage?: boolean;

    @Input() facilityCode: string;
    @Input() currency: string;
    @Input() shippingProvider?: LCEShippingInternationalProvider;

    @ViewChild('priceDetails') priceDetails: LCECertificateOrderPriceDetailsComponent;
    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    numberOfCopies: number = 1;

    formGroup: FormGroup = new FormGroup({});
    certificateTypeField: XSInputFieldDropdownOptions;
    deliveryFields = new FormGroup({});

    orderPriceRequest: LCECertificateOrderPriceRequest;

    private readonly CERTIFICATE_TYPE: XSLabelValue[] = [
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.BIRTH, value: LCECertificateType.BIRTH},
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.DEATH, value: LCECertificateType.DEATH},
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.MARRIAGE, value: LCECertificateType.MARRIAGE}
    ];

    constructor() {
        super();
    }

    ngOnInit(): void {
        console.log('shippingProvider', this.shippingProvider);

        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.titleStyleClass = this.dialogConfig.data.titleStyleClass;
            this.subtitleStyleClass = this.dialogConfig.data.subtitleStyleClass;
            this.title = this.dialogConfig.data.title;
            this.subtitle = this.dialogConfig.data.subtitle;
            this.PriceDetailsShowDefaultMessage = this.dialogConfig.data.PriceDetailsShowDefaultMessage;
            this.facilityCode = this.dialogConfig.data.facilityCode;
            this.currency = this.dialogConfig.data.currency;
            this.currency = this.dialogConfig.data.currency;
        }

        this.buildFields();

        XSAssert.notEmpty(this.facilityCode, 'facilityCode');
        XSAssert.notEmpty(this.currency, 'currency');
        if (XSUtils.isEmpty(this.title)) this.title = 'lce.shared.certificateOrders.priceEstimator.title';
        if (XSUtils.isEmpty(this.subtitle)) this.subtitle = 'lce.shared.certificateOrders.priceEstimator.subtitle';

    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isRunning(): boolean {
        return !XSUtils.isEmpty(this.priceDetails) && this.priceDetails.isRunning();
    }

    public calculate(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        console.log('formGroup', this.formGroup);
        LOG().debug('Calculate Amount ...');
        const formData = this.formGroup.value;
        this.orderPriceRequest = this.buildOrderPriceRequest(formData);
        console.log('| orderPriceRequest', this.orderPriceRequest);
    }

    private buildOrderPriceRequest(formData: any): LCECertificateOrderPriceRequest {
        console.log('formData', formData);
        const deliveryDestination: LCEDeliveryDestination = LCECertificateOrderUtils.buildDeliveryDestination(formData.delivery.destinationType, formData.delivery.deliveryDestination, formData.delivery);
        let type = formData.delivery.destinationType as LCEDeliveryDestinationType;
        return {
            certificateType: formData.certificateType,
            destinationType: type,
            destinationCustomLocationCoordinate: type === LCEDeliveryDestinationType.CUSTOM_LOCATION ? (deliveryDestination as LCEDeliveryDestinationCustomLocation)?.address?.coordinate : undefined,
            shippingProvider: type === LCEDeliveryDestinationType.INTERNATIONAL ? formData.delivery.shippingInternationalProvider : undefined,
            destinationInternationalAddress: type === LCEDeliveryDestinationType.INTERNATIONAL ? (deliveryDestination as LCEDeliveryDestinationInternational)?.address : undefined,
            destinationServicePointCode: type === LCEDeliveryDestinationType.SERVICE_POINT ? (deliveryDestination as any).code : undefined,
            destinationFacilityCode: type === LCEDeliveryDestinationType.FACILITY ? (deliveryDestination as any).code : undefined,
            facilityCode: this.facilityCode,
            numberOfCopies: this.numberOfCopies,
            currency: this.currency
        };
    }


    private buildFields(): void {
        this.certificateTypeField = {
            fieldName: 'certificateType',
            label: this.TR_BASE + 'type',
            control: new FormControl(undefined, Validators.required),
            items: this.CERTIFICATE_TYPE,
            placeholder: this.TR_BASE + 'typePlaceholder'
        };
        this.formGroup.addControl(this.certificateTypeField.fieldName, this.certificateTypeField.control!);
        this.formGroup.addControl('delivery', this.deliveryFields);
    }
}