<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card xs-flex-1">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-title
                [expandCollapseEnable]="showCollapse && (data | xsIsNotEmpty)"
                [expandCollapseTarget]="parentCard"
                [expanded]="true"
                [text]="title"
                textStyleClass="xs-color-secondary xs-font-size-medium-imp">
            <ng-template xsTemplate="rightSection">
                <xs-button
                        (clickEvent)="onEditParent()"
                        *ngIf="readonly !== false"
                        iconStyleClass="xs-font-size-13-imp"
                        label="xs.core.label.edit"
                        size="intermediate"
                        type="text">
                </xs-button>
            </ng-template>
        </xs-title>
    </div>
    <div #parentCard class="xs-card-content">
        <div *ngIf="data | xsIsEmpty" class="xs-flex-column-center xs-width-full xs-height-full xs-position-relative">
            <div class="xs-absolute-center-vh xs-flex-column-center">
                <i [style.font-size]="'200px'" class="{{ ICON.male }} xs-display-block xs-color-discrete xs-opacity-40"></i>
            </div>
        </div>
        <div *ngIf="data | xsIsNotEmpty" class="grid xs-fields xs-fields-spacer-25">
            <div class="col-6">
                <xs-data-field-text [label]="TR_BASE + 'firstName'" [value]="data!.name?.firstName" valueStyleClass="xs-font-size-intermediate"></xs-data-field-text>
            </div>
            <div class="col-6">
                <xs-data-field-text [label]="TR_BASE + 'lastName'" [value]="data!.name?.lastName" valueStyleClass="xs-font-size-intermediate"></xs-data-field-text>
            </div>
            <div *ngIf="data!.birthDate" class="col-6">
                <xs-data-field-calendar
                        [calendarOptions]="{ type: 'date', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }"
                        [label]="TR_BASE + 'birthDate'"
                        [value]="data!.birthDate">
                </xs-data-field-calendar>
            </div>
            <div class="col-6">
                <xs-data-field-text [label]="TR_BASE + 'birthLocation'"
                                    [required]="false"
                                    [value]="data!.birthLocation"
                                    valueStyleClass="xs-font-size-intermediate">
                </xs-data-field-text>
            </div>
            <div class="col-6">
                <xs-data-field-text [label]="TR_BASE + 'occupation'" [required]="false" [value]="data!.occupation" valueStyleClass="xs-font-size-intermediate">
                </xs-data-field-text>
            </div>
            <div class="col-6">
                <xs-data-field-address [label]="TR_BASE + 'address'" [required]="false" [value]="data!.address"></xs-data-field-address>
            </div>
            <div class="col-6">
                <div class="xs-data-field">
                    <xs-data-field-label [label]="TR_BASE + 'nationality'"></xs-data-field-label>
                    <div class="xs-data-field-value-container">
                        <xs-country [options]="{ showFlag: false, showName: true }" [value]="data!.nationality" styleClass="xs-font-size-intermediate"></xs-country>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <xs-data-field-phone-number [label]="TR_BASE + 'phoneNumber'" [required]="false" [value]="data!.phoneNumber"></xs-data-field-phone-number>
            </div>
            <div class="col-12">
                <xs-data-field-ivar
                        [label]="TR_BASE + 'identityType'"
                        [required]="false"
                        [value]="(data!.identityNumber | xsIsEmpty) ? undefined : {
                        icon: ICON.idCard,
                        line1: data!.identityNumber,
                        line2: TR_BASE_IDENTITY_DOCUMENT_TYPE + data!.identityType,
                        iconStyleClass: 'xs-color-secondary xs-font-size-26-imp',
                        line1StyleClass: 'xs-font-size-intermediate-imp',
                        line2StyleClass:'xs-color-secondary xs-font-size-small-imp xs-mt-0-imp'
                       }"
                >
                </xs-data-field-ivar>
            </div>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-ivar
            [ellipsis]="true"
            [line1]=" TR_BASE + 'title.' + type"
            line2="{{
            'lce.shared.birthDeclaration.label.birth' | translate }} {{ birthNumber }}"
            styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>
