import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {LCEWebSocketService} from '@lce/core';
import {XSTranslationService} from '@xs/common';
import {XSLocalStorageService, XSToastService} from '@xs/core';
import {LCEEventProcessorService, LCESoundAlertService} from '@lce/shared';

/**
 * Allowing to handle event received from the socket client.
 */
@Injectable({providedIn: 'root'})
export class LCECMDEventProcessorService extends LCEEventProcessorService {

    constructor(
        protected translationService: XSTranslationService,
        protected toastService: XSToastService,
        protected datePipe: DatePipe,
        protected localStorageService: XSLocalStorageService,
        protected webSocketService: LCEWebSocketService,
        protected soundPlayerService: LCESoundAlertService) {
        super(translationService, toastService, datePipe, localStorageService, webSocketService, soundPlayerService);
    }
}
