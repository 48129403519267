export enum LCEWebSocketConnectionStatus {
    CONNECTED = 'connected',
    CLOSED = 'closed',
}

export enum LCEWebSocketMessageType {
    AUTHENTICATION_TOKEN = 'authenticationToken',
    ERROR = 'error',
    CLOSE = 'close',
    NOTIFICATION = 'notification',
    CHAT = 'chat',
}

export interface LCEWebSocketMessage {
    sessionID: string;
    type: LCEWebSocketMessageType;
    data: any;
}

export enum LCEWebSocketAuthenticationFailedReason {
    TOKEN_MISSING = 'tokenMissing',
    TOKEN_INVALID = 'tokenInvalid',
}

export interface LCEWebSocketAuthenticationResponse {
    isOK: boolean;
    failedReason?: LCEWebSocketAuthenticationFailedReason;
    failedMessage?: string;
}
