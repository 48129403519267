import {Injectable} from '@angular/core';
import {LCEDistrict} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEDistrictCreateUpdateComponent} from './create-update/lce-district-create-update.component';
import {LCEDistrictRecordComponent} from './record/lce-district-record.component';

export interface LCEDistrictDialogCreateUpdateArg {
    districtID?: string;
    district?: LCEDistrict;
    showRecordAfterSave?: boolean;
    onClose?: (district?: LCEDistrict) => void;
    onSave?: (district: LCEDistrict) => void;
}

export interface LCEDistrictDialogRecordArg {
    districtID?: string;
    district?: LCEDistrict;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (district?: LCEDistrict) => void;
    onEdit?: (district: LCEDistrict) => void;
}

@Injectable()
export class LCEDistrictDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openDistrictRecordDialog(arg: LCEDistrictDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.districtID) && XSUtils.isEmpty(arg.district)) {
            throw new Error('arg.districtID and arg.district cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.districtID) && !XSUtils.isEmpty(arg.district)) {
            throw new Error('arg.districtID and arg.district cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                districtID: arg.districtID,
                district: arg.district,
                showEditButton: arg.showEditButton,
                dataManager: arg.dataManager,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEDistrictRecordComponent = this.dialogService.openComponentDialog(LCEDistrictRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedDistrict: LCEDistrict) => {
            if (arg?.onClose) arg!.onClose!(savedDistrict);
        });
        recordComponent.editEvent.subscribe((districtRecord: LCEDistrict) => {
            if (arg?.onEdit) arg.onEdit(districtRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openDistrictCreateUpdateDialog({
                    district: districtRecord,
                    onClose: (district) => this.openDistrictRecordDialog({district: district, showEditButton: true}),
                    onSave: (district) => this.openDistrictRecordDialog({district: district, showEditButton: true})
                });
            }
        });
    }

    public openDistrictCreateUpdateDialog(arg?: LCEDistrictDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.districtID) && !XSUtils.isEmpty(arg?.district)) {
            throw new Error('arg.districtID and arg.district cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                districtID: arg?.districtID,
                district: arg?.district,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEDistrictCreateUpdateComponent = this.dialogService.openComponentDialog(LCEDistrictCreateUpdateComponent, dConfig);

        createUpdateComponent.closeEvent.subscribe((district: LCEDistrict) => {
            if (arg?.onClose) arg!.onClose!(district);
        });
        createUpdateComponent.saveEvent.subscribe((district: LCEDistrict) => {
            if (!district) {
                createUpdateComponent.closeDialog();
                return;
            }
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(district);
            if (arg?.showRecordAfterSave === true) this.openDistrictRecordDialog({district: district});
        });
    }
}
