import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import LCE_NEWS_JSON_ARTICLES_CATEGORIES from '../../data/lce-news-article-categories.json';
import {LCE_CORE_ENDPOINTS, LCECategory, LCECategoryCreate, LCECategoryPartial, LCECategorySearch, LCEUserPartial} from '@lce/core';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../../lce-mock.constant';
import {XSHttpMethod, XSPKDTOAuditFullState, XSUtils} from '@xs/base';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../../lce-mock-user-data';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {XSMockPKResourceAuditFullHandler, XSMockSearchQueryPredicate} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.news.categories';

@Injectable()
export class LCEMockNewsArticleCategoryHandler extends XSMockPKResourceAuditFullHandler<LCECategory, LCECategoryPartial, LCECategorySearch> {

    public static ARTICLE_CATEGORY_STORAGE: Map<string, LCECategory> = new Map<string, LCECategory>();

    constructor() {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.news.categories.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCECategory> {
        return LCEMockNewsArticleCategoryHandler.ARTICLE_CATEGORY_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return LCEMockUtils.randomUserCustomer();
    }

    public toPartial(category: LCECategory): LCECategoryPartial {
        return {
            id: category.id,
            code: category.code,
            name: category.name.fr!,
            shortName: category.shortName?.fr
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();

        const queryPredicates: XSMockSearchQueryPredicate<LCECategory>[] = [
            (category, query) => category.code.toLowerCase().includes(query),
            (category, query) => category.name.fr?.toLowerCase().includes(query),
            (category, query) => category.name.en?.toLowerCase().includes(query),
            (category, query) => category.shortName?.fr?.toLowerCase().includes(query),
            (category, query) => category.shortName?.en?.toLowerCase().includes(query),
            (category, query) => category.description?.fr?.toLowerCase().includes(query),
            (category, query) => category.description?.en?.toLowerCase().includes(query)
        ];

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 3000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: queryPredicates}),
            // Search
            this.buildSearchMockData({
                queryPredicates: queryPredicates,
                predicates: [
                    (category, params) => this.httpParamArrayIncludes(params, 'codes', category.code),
                    (category, params) => this.httpParamStringIncludes(params, 'description', category.description?.fr),
                    (category, params) => this.httpParamStringIncludes(params, 'description', category.description?.en),
                    (category, params) => this.httpParamBooleanEquals(params, 'deletable', category.deletable),
                    (category, params) => this.httpParamBooleanEquals(params, 'updatable', category.updatable)
                ]
            })
        ];
    }

    private createResponseData(body?: any): LCECategory {
        const articleCategoryCreate: LCECategoryCreate = body as LCECategoryCreate;

        const articleCategory: LCECategory = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-NES-CAT-' + XSUtils.randomDigits(4),
            name: {fr: articleCategoryCreate.name.fr, en: articleCategoryCreate.name.en},
            shortName: {fr: articleCategoryCreate.shortName?.fr, en: articleCategoryCreate.shortName?.en},
            description: {fr: articleCategoryCreate.description?.fr, en: articleCategoryCreate.description?.en}
        };

        LCEMockNewsArticleCategoryHandler.ARTICLE_CATEGORY_STORAGE.set(articleCategory.id, articleCategory);
        this.addResourceBaseMockData(articleCategory.id);

        return articleCategory;
    }

    private buildStorage(): void {
        LCE_NEWS_JSON_ARTICLES_CATEGORIES.forEach((jsonArticleCategory) => {
            const articleCategory: LCECategory = {
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                ...jsonArticleCategory
            };
            LCEMockNewsArticleCategoryHandler.ARTICLE_CATEGORY_STORAGE.set(articleCategory.id, articleCategory);
            this.addResourceBaseMockData(articleCategory.id);
        });
    }
}
