export const LCE_SHARED_ICON = {
    assignment: 'fas fa-hand-point-right',
    terminal: 'fas fa-tablet',
    timeline: 'fas fa-stream',
    procuration: 'fas fa-people-arrows',
    fullScreen: 'fas fa-expand-arrows-alt',
    exitFullScreen: 'fas fa-compress-arrows-alt',
    print: 'fas fa-print',
    stamp: 'fas fa-stamp',
    computerMouse: 'fas fa-mouse',
    handPointer: 'fas fa-hand-pointer',
    last: 'fas fa-clock',
    caretRight: 'fas fa-caret-right',
    caretLeft: 'fas fa-caret-left',
    categories: 'fas fa-cubes',
    tag: 'fas fa-tag',
    tags: 'fas fa-tags',
    female: 'fas fa-venus',
    male: 'fas fa-mars',
    nurse: 'fas fa-user-nurse',
    doctor: 'fas fa-user-md',
    userGraduate: 'fas fa-user-graduate',
    balance: 'fas fa-balance-scale',
    gavel: 'fas fa-gavel',
    // === * ===
    externalLink: 'fas fa-external-link-alt',
    qrCode: 'fas fa-qrcode',
    connection: 'fas fa-plug',
    fileCheck: 'fas fa-file-check',
    history: 'fas fa-history',
    process: 'fas fa-microchip',
    international: 'fas fa-globe-europe',
    localLocation: 'fas fa-map-pin',
    finances: 'fas fa-hand-holding-usd',
    internalCommunication: 'fas fa-mail-bulk',
    externalCommunication: 'fas fa-comment',
    news: 'fas fa-newspaper',
    customer: 'fas fa-user-tie',
    comingSoon: 'fas fa-grin-wink',
    // === * ===
    equals: 'fas fa-equals',
    menuBlock: 'fas fa-th-large',
    send: 'pi pi-send',
    comment: 'pi pi-comment',
    smartCity: 'fas fa-city',
    idCard: 'fas fa-id-card',
    payment: 'fas fa-dollar-sign',
    birthDeclaration: 'fas fa-baby',
    delivery: 'fas fa-truck',
    deliveryMan: 'fas fa-biking',
    order: 'fas fa-box-open',
    certificateOrder: 'lce-certificate-icon',
    certificate: 'lce-certificate-icon',
    birth: 'fas fa-baby',
    marriage: 'fas fa-ring',
    death: 'fas fa-skull',
    suggestion: 'fas fa-lightbulb',
    facility: 'fas fa-building',
    servicePoint: 'fas fa-briefcase',
    municipality: 'fas fa-university',
    district: 'fas fa-map',
    anonymous: 'fas fa-user-secret',
    setting: 'fas fa-cog',
    event: 'fas fa-clipboard-list',
    deliveryCompany: 'fas fa-truck',
    deliveryCompanyValid: 'fas fa-thumbs-up',
    deliveryCompanyInvalid: 'fas fa-thumbs-down',
    // -----------------------------------------------------------------------------------------------------
    // === * ===
    // -----------------------------------------------------------------------------------------------------
    inProgress: 'fas fa-hourglass-half',
    wifi: 'fas fa-wifi',
    fingerprint: 'fas fa-fingerprint',
    envelope: 'fas fa-envelope',
    spinner: 'fas fa-spinner',
    bread: 'fas fa-bread-slice',
    mobile: 'pi pi-mobile',
    phone: 'fas fa-phone-alt',
    checkSquare: 'pi pi-check-square',
    tablet: 'fas fa-tablet-alt',
    bars: 'fas fa-bars',
    faComment: 'fas fa-comment',
    list: 'fas fa-list',
    listAlt: 'fas fa-list-alt',
    share: 'pi pi-share-alt',
    overlay: 'pi pi-clone',
    form: 'fas fa-id-card',
    table: 'pi pi-table',
    panel: 'fas fa-columns',
    stickyNote: 'far fa-sticky-note',
    bookmark: 'far fa-bookmark',
    globe: 'fas fa-globe',
    clear: 'far fa-trash-alt',
    window: 'far fa-window-maximize',
    camera: 'fas fa-camera',
    cameraRetro: 'fas fa-camera-retro',
    video: 'fas fa-video',
    play: 'fas fa-play',
    playCircle: 'fas fa-play-circle',
    circle: 'fas fa-circle',
    mapPin: 'fas fa-map-pin',
    mapMarker: 'fas fa-map-marker',
    map: 'fas fa-map',
    puzzle: 'fas fa-puzzle-piece',
    thumbtack: 'fas fa-thumbtack',
    ellipsisVertical: 'fas fa-ellipsis-v',
    ellipsisHorizontal: 'fas fa-ellipsis-h',
    pen: 'fas fa-pen',
    fileAlt: 'fas fa-file-alt',
    // -----------------------------------------------------------------------------------------------------
    // --------------------------------------- XS Required Icons -------------------------------------------
    // -----------------------------------------------------------------------------------------------------
    thumbsUp: 'fas fa-thumbs-up',
    thumbsDown: 'fas fa-thumbs-down',
    scheme: 'fas fa-adjust',
    calendar: 'far fa-calendar',
    check: 'fas fa-check',
    details: 'fas fa-list',
    success: 'fas fa-check',
    info: 'fas fa-info',
    infoCircle: 'fas fa-info-circle',
    warn: 'fas fa-exclamation',
    error: 'fas fa-exclamation-triangle',
    errorCircle: 'fas fa-exclamation-circle',
    help: 'fas fa-question',
    helpCircle: 'far fa-question-circle',
    search: 'pi pi-search',
    alignCenter: 'pi pi-align-center',
    alignRight: 'pi pi-align-right',
    confirmation: 'fas fa-exclamation-triangle',
    back: 'pi pi-arrow-left',
    next: 'pi pi-arrow-right',
    clock: 'far fa-clock',
    lock: 'pi pi-lock',
    lockOpen: 'pi pi-lock-open',
    unlock: 'pi pi-unlock',
    authentication: 'pi pi-key',
    copy: 'fas fa-copy',
    download: 'fas fa-download',
    bug: 'fas fa-bug',
    angleRight: 'pi pi-angle-right',
    angleLeft: 'pi pi-angle-left',
    angleTop: 'pi pi-angle-top',
    angleDown: 'pi pi-angle-down',
    home: 'fas fa-home',
    settings: 'fas fa-cogs',
    asterisk: 'fas fa-asterisk',
    security: 'fas fa-shield-alt',
    refresh: 'fas fa-retweet',
    link: 'fas fa-link',
    ban: 'fas fa-ban',
    forbidden: 'fas fa-ban',
    cloudUploadAlt: 'fas fa-cloud-upload-alt',
    file: 'far fa-file',
    filePDF: 'far fa-file-pdf',
    fileWord: 'far fa-file-word',
    fileExcel: 'far fa-file-excel',
    fileCSV: 'fas fa-file-csv',
    fileText: 'far fa-file-alt',
    filePowerPoint: 'far fa-file-powerpoint',
    fileCode: 'far fa-file-code',
    fileArchive: 'far fa-file-archive',
    imageSolid: 'fas fa-image',
    reset: 'fas fa-sync',
    undo: 'fas fa-undo',
    redo: 'fas fa-redo',
    arrowsVertical: 'fas fa-arrows-alt-v',
    arrowsHorizontal: 'fas fa-arrows-alt-h',
    zoomIn: 'fas fa-search-plus',
    zoomOut: 'fas fa-search-minus',
    eye: 'fas fa-eye',
    eyeSlash: 'fas fa-eye-slash',
    user: 'fas fa-user',
    userPlus: 'fas fa-user-plus',
    users: 'fas fa-users',
    username: 'pi pi-user',
    password: 'pi pi-key',
    email: 'pi pi-envelope',
    emailFilled: 'fas fa-envelope',
    bell: 'pi pi-bell',
    parameters: 'fas fa-sliders-h',
    advancedSearch: 'fas fa-sliders-h',
    cog: 'fas fa-cog',
    export: 'fas fa-file-export',
    columns: 'fas fa-columns',
    sort: 'pi pi-sort-alt',
    imageRegular: 'far fa-image',
    arrowUp: 'fas fa-long-arrow-alt-up',
    arrowDown: 'fas fa-long-arrow-alt-down',
    arrowLeft: 'fas fa-long-arrow-alt-left',
    arrowRight: 'fas fa-long-arrow-alt-right',
    record: 'fas fa-clipboard',
    userRecord: 'fas fa-clipboard-user',
    stats: 'fas fa-chart-area',
    chartLine: 'fas fa-chart-line',
    chartBar: 'fas fa-chart-bar',
    chartPie: 'fas fa-chart-pie',
    expanded: 'fas fa-caret-down',
    collapsed: 'fas fa-caret-right',
    box: 'fas fa-box',
    edit: 'fas fa-edit',
    android: 'fab fa-android',
    apple: 'fab fa-apple',
    linux: 'fab fa-linux',
    windows: 'fab fa-windows',
    huawei: 'fas fa-tablet',
    delete: 'far fa-trash-alt',
    plus: 'fas fa-plus',
    minus: 'fas fa-minus',
    square: 'far fa-square',
    squareFull: 'fas fa-square-full',
    label: 'fas fa-tag',
    at: 'fas fa-at',
    menu: 'fas fa-bars',
    logout: 'pi pi-sign-out',
    fileImport: 'fas fa-file-import'
};
