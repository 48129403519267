import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCECertificateOrderPriceCalculatorService, LCECertificateOrderPriceDetail, LCECertificateOrderPriceRequest} from '@lce/core';
import {XSCurrency, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSInputDropdownOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-certificate-order-price-details',
    templateUrl: './lce-certificate-order-price-details.component.html'
})
export class LCECertificateOrderPriceDetailsComponent implements OnInit, OnChanges, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.certificateOrders.priceDetails.';
    readonly TR_CURRENCY = 'xs.common.currencyCode.';

    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;

    @Input() showDefaultMessage?: boolean;

    @Input() orderPriceRequest?: LCECertificateOrderPriceRequest;
    @Input() certificateOrderCreateSessionID?: string;

    @Input() currencySelected: XSCurrency;

    @Input() defaultMessage?: { summary: string, detail?: string; };

    @Output() currencyEvent = new EventEmitter<XSCurrency>();
    @Output() dataEvent = new EventEmitter<LCECertificateOrderPriceDetail>();


    currencies: XSLabelValueItem[] = [
        {trLabel: this.TR_CURRENCY + XSCurrency.XOF.toLowerCase(), value: XSCurrency.XOF},
        {trLabel: this.TR_CURRENCY + XSCurrency.EURO.toLowerCase(), value: XSCurrency.EURO},
        {trLabel: this.TR_CURRENCY + XSCurrency.US_DOLLAR.toLowerCase(), value: XSCurrency.US_DOLLAR},
        {trLabel: this.TR_CURRENCY + XSCurrency.CANADIAN_DOLLAR.toLowerCase(), value: XSCurrency.CANADIAN_DOLLAR}
    ];
    currenciesOptions: XSInputDropdownOptions = {
        labelField: 'label',
        valueField: 'value',
        showClear: false,
        showBorder: false
    };

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrievePriceDetail()
    };

    data: LCECertificateOrderPriceDetail;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService, private loaderService: XSLoaderService, private certificateOrderPriceCalculatorService: LCECertificateOrderPriceCalculatorService) {
        this.translationService.translateItems(this.currencies);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.currencies);
                this.currencies = [...this.currencies];
            })
        );
    }

    ngOnInit(): void {

        if (XSUtils.isEmpty(this.currencySelected)) this.currencySelected = XSCurrency.XOF;
        if (this.showDefaultMessage) {
            if (XSUtils.isEmpty(this.defaultMessage)) {
                this.defaultMessage = {
                    summary: 'lce.shared.certificateOrders.priceDetails.messageDefault.summary',
                    detail: 'lce.shared.certificateOrders.priceDetails.messageDefault.detail'
                };
            }
        } else {
            if (XSUtils.isEmpty(this.orderPriceRequest) && XSUtils.isEmpty(this.certificateOrderCreateSessionID)) {
                throw new Error('orderPriceRequest and certificateOrderCreateSessionID cannot both be empty at the same time.');
            }
            if (!XSUtils.isEmpty(this.orderPriceRequest) && !XSUtils.isEmpty(this.certificateOrderCreateSessionID)) {
                throw new Error('orderPriceRequest and certificateOrderCreateSessionID cannot both be set at the same time.');
            }
        }

        this.retrievePriceDetail();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!XSUtils.isEmpty(changes.orderPriceRequest) && !changes.orderPriceRequest!.isFirstChange()) this.retrievePriceDetailByRequest();
        if (!XSUtils.isEmpty(changes.certificateOrderCreateSessionID) && !changes.certificateOrderCreateSessionID!.isFirstChange()) this.retrievePriceDetailByOrderCreateSessionID();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public onCurrencyChange(currency: XSCurrency): void {
        this.currencyEvent.emit(currency);
        if (!XSUtils.isNull(this.certificateOrderCreateSessionID)) this.retrievePriceDetailByOrderCreateSessionID(currency);
        if (!XSUtils.isNull(this.orderPriceRequest)) this.retrievePriceDetailByRequest();
    }

    private retrievePriceDetail(): void {
        if (!XSUtils.isEmpty(this.certificateOrderCreateSessionID)) this.retrievePriceDetailByOrderCreateSessionID();
        if (!XSUtils.isEmpty(this.orderPriceRequest)) this.retrievePriceDetailByRequest();
    }

    private retrievePriceDetailByRequest(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.certificateOrderPriceCalculatorService
                .retrievePriceDetailByRequest(this.orderPriceRequest!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: certificateOrderPriceDetail => {
                        this.data = certificateOrderPriceDetail;
                        this.dataEvent.emit(this.data);
                    },
                    error: (error: any) => this.error = error
                })
        );
    }

    private retrievePriceDetailByOrderCreateSessionID(currency?: XSCurrency): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.certificateOrderPriceCalculatorService
                .retrievePriceDetailByOrderCreateSessionID(this.certificateOrderCreateSessionID!, currency)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: certificateOrderPriceDetail => {
                        this.data = certificateOrderPriceDetail;
                        console.log('price details', this.data);
                        this.dataEvent.emit(this.data);
                    },
                    error: (error: any) => this.error = error
                })
        );
    }

}
