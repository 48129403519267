import {XSPersonName, XSPKAuditUpdatedSearchFilter, XSPKDTOAuditUpdatedOn} from '@xs/base';
import {LCEMunicipalityPartial} from '../lce-municipality';

export interface LCECertificateSearch extends XSPKAuditUpdatedSearchFilter {
    types?: LCECertificateType[];
    municipalityCodes?: string[];
    referenceNumbers?: string[];
    issueDateRange?: string;
    issueDateUseYear?: boolean;
}

export interface LCECertificatePartial {
    id: string;
    type: LCECertificateType;
    referenceNumber: string;
    issueDate: string;
    issueDateUseYear?: boolean;
}

export interface LCECertificateCreate {
    certificateID?: string;
    type: LCECertificateType;
    referenceNumber: string;
    issueDate: string;
    issueDateUseYear?: boolean;
    test?: boolean;

    birthHolderName?: XSPersonName;

    marriageGroomName?: XSPersonName;
    marriageBrideName?: XSPersonName;

    deathDeceasedName?: XSPersonName;
}

export interface LCECertificateBirth extends LCECertificate {
    type: LCECertificateType.BIRTH;
    holderName?: XSPersonName;
}

export interface LCECertificateMarriage extends LCECertificate {
    type: LCECertificateType.MARRIAGE;
    groomName?: XSPersonName;
    brideName?: XSPersonName;
}

export interface LCECertificateDeath extends LCECertificate {
    type: LCECertificateType.DEATH;
    deceasedName?: XSPersonName;
}

export interface LCECertificate extends XSPKDTOAuditUpdatedOn {
    type: LCECertificateType;
    municipality: LCEMunicipalityPartial;
    referenceNumber: string;
    issueDate: string;
    issueDateUseYear?: boolean;
    nOrdered?: number;
    nOrderedCopies?: number;
}

export enum LCECertificateType {
    BIRTH = 'birth',
    MARRIAGE = 'marriage',
    DEATH = 'death'
}

export type LCECertificateTypeStr = 'birth' | 'marriage' | 'death';
