<div class="xs-width-full {{styleClass}}">
    <div class="grid xs-fields">
        <div class="col-12">
            <xs-input-field-checkboxes [options]="notifyChannelsField"></xs-input-field-checkboxes>
        </div>
        <div class="col-12">
            <xs-input-field-text
                    [options]="notifyDeliveryManAfterNSecondsField"
                    styleClass="xs-input-just-border-botto">
            </xs-input-field-text>
        </div>
        <div class="col-12">
            <xs-input-field-textarea [options]="noteField"></xs-input-field-textarea>
        </div>
    </div>
</div>