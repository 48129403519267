import {LCEUserPartial} from './user/lce-user';
import {XSPKAuditFullSearchFilter, XSPKDTOAuditCreated} from '@xs/base';
import {LCEResourceType} from './lce-core';
import {LCENotificationChannel} from './lce-notification';

// ----------------------------------------------------------------------------------------------------------
// === * ===
// ----------------------------------------------------------------------------------------------------------

export interface LCEEventSearch extends XSPKAuditFullSearchFilter {
    types?: string[];
    resourceTypes?: string[];
    eventNames?: string[];

    resourceIDs?: string[];
    resourceCodes?: string[];

    municipalityCodes?: string[];
    facilityCodes?: string[];
    districtCodes?: string[];

    notifiable?: boolean;

    notificationChannels?: string[];

    notificationProcessed?: boolean;
    notificationProcessedOnRange?: string;

    batch?: boolean;
}

export interface LCEEvent<T = any> extends XSPKDTOAuditCreated<LCEUserPartial> {
    type: LCEEventType;
    resourceType: LCEResourceType;

    eventName: string;

    resourceID: string;
    resourceCode: string;

    districtCodes: string[];
    municipalityCodes: string[];
    facilityCodes: string[];

    data?: T;

    notifiable: boolean;
    notificationDelayInSeconds: number;
    notificationChannels: LCENotificationChannel[];
    notificationProcessed: boolean;
    notificationProcessedOn: string;

    batch: boolean;
    metadata?: string;
}

export interface LCEEventPartial<T = any> {
    id: string;
    createdOn: string;
    createdBy: LCEUserPartial;

    type: LCEEventType;
    resourceType: LCEResourceType;

    eventName: string;

    resourceID: string;
    resourceCode: string;

    data?: T;
}

export enum LCEEventType {
    RESOURCE = 'resource',
    INFORMATION = 'information',
    SYSTEM = 'system',
    ALERT = 'alert',
    ADVERTISING = 'advertising',
}
