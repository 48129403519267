import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEFacilityTownHallStampToken, LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenService} from '@lce/core';
import {XS_STR_EMPTY, XSResourceAuditCanOptions, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_DD_MM_YYYY_HH_MM, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XS_DATE_FORMAT_YYYY_MM_DD_HH_MM, XSLoaderService, XSNumberPipe} from '@xs/common';
import {XSAvatar, XSButton, XSCalendarOptions, XSCoreDomUtils, XSDialogable} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-facility-stamp-token-record',
    templateUrl: './lce-facility-town-hall-stamp-token-record.component.html',
    host: {class: 'xs-width-full'},
    providers: [XSNumberPipe]
})
export class LCEFacilityTownHallStampTokenRecordComponent extends XSDialogable
    implements OnInit, AfterViewInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.facility.townHallStampToken.label.';

    readonly headerAvatar: XSAvatar = {type: 'icon', data: this.ICON.stamp};

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() stampTokenID?: string;
    @Input() stampTokenCode?: string;
    @Input() stampToken?: LCEFacilityTownHallStampToken;

    @Input() canAudit?: XSResourceAuditCanOptions;

    @Output() closeEvent = new EventEmitter<LCEFacilityTownHallStampToken>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieve()
    };

    protected readonly DATE_FORMAT_EN = XS_DATE_FORMAT_YYYY_MM_DD_HH_MM;
    protected readonly DATE_FORMAT_FR = XS_DATE_FORMAT_DD_MM_YYYY_HH_MM;
    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        public facilityStampTokenService: LCEFacilityTownHallStampTokenService) {
        super();
    }

    get headerSubtitle(): string {
        let title = XSUtils.isEmpty(this.stampToken) ? '...' : this.stampToken!.facility.fullName;
        return title!;
    }

    public static validate(
        stampTokenID: string | undefined, stampTokenCode: string | undefined, stampToken: LCEFacilityTownHallStampToken | undefined, prefix: string = XS_STR_EMPTY): void {
        const str = XSUtils.isEmpty(prefix) ? XS_STR_EMPTY : prefix.trim() + '.';
        if (XSUtils.isEmpty(stampTokenID) && XSUtils.isEmpty(stampTokenCode) && XSUtils.isEmpty(stampToken)) {
            throw new Error(`${str}stampTokenID, arg.stampTokenCode and ${str}stampToken cannot all be empty at the same time.`);
        }
        if (!XSUtils.isEmpty(stampTokenID) && !XSUtils.isEmpty(stampTokenCode)) {
            throw new Error(`${str}stampTokenID and ${str}stampTokenCode cannot both be set at the same time.`);
        }
        if ((!XSUtils.isEmpty(stampTokenID) || !XSUtils.isEmpty(stampTokenCode)) && !XSUtils.isEmpty(stampToken)) {
            throw new Error(`${str}stampTokenID or ${str}stampTokenCode and ${str}stampToken cannot both be set at the same time.`);
        }
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.stampTokenID = this.dialogConfig.data.stampTokenID;
            this.stampTokenCode = this.dialogConfig.data.stampTokenCode;
            this.stampToken = this.dialogConfig.data.stampToken;
            this.canAudit = this.dialogConfig.data.canAudit;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.stampToken));
        }
        LCEFacilityTownHallStampTokenRecordComponent.validate(this.stampTokenID, this.stampTokenCode, this.stampToken);
        if (XSUtils.isEmpty(this.stampToken)) {
            this.retrieve();
        } else {
            this.stampTokenID = this.stampToken!.id;
            this.stampTokenCode = this.stampToken!.code;
        }
    }

    ngAfterViewInit(): void {
        if (!XSUtils.isEmpty(this.stampToken) && !XSUtils.isNull(this.stampToken!.numberOfStampsLeft)) {
            setTimeout(() => {
                XSCoreDomUtils.scrollTo(undefined, undefined, {
                    top: 200,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 5000);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onDataChange(data: LCEFacilityTownHallStampToken | LCEFacilityTownHallStampTokenPartial): void {
        this.stampToken = {...data as LCEFacilityTownHallStampToken};
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.stampToken);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private retrieve(): void {
        if (!XSUtils.isEmpty(this.stampTokenID)) {
            this.retrieveByID();
        } else if (!XSUtils.isEmpty(this.stampTokenCode)) {
            this.retrieveByCode();
        } else {
            throw new Error('unknown error, stampTokenID and stampTokenCode are both blank. This is not supposed to happen.');
        }
    }

    private retrieveByCode(): void {
        return this.internalRetrieve(this.facilityStampTokenService.findOneByCode(this.stampTokenCode!));
    }

    private retrieveByID(): void {
        return this.internalRetrieve(this.facilityStampTokenService.findOneByID(this.stampTokenID!));
    }

    private internalRetrieve(observable: Observable<LCEFacilityTownHallStampToken>): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            observable
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe(
                    {
                        next: (stampToken) => {
                            this.stampToken = stampToken;
                            this.stampTokenID = this.stampToken.id;
                            this.stampTokenCode = this.stampToken.code;
                        },
                        error: (error) => (this.error = error)
                    }
                )
        );
    }
}
