import {XSReason} from '@xs/base';

export interface XSResourceRegistration<T> {

    registered?: boolean;
    registeredOn?: string;
    registeredBy?: T;
    unregisteredOn?: string;
    unregisteredBy?: T;
}

export enum XSPKDTOAuditFullAction {
    ACTIVATE = 'activate',
    DEACTIVATE = 'deactivate',
    DELETE = 'delete',
    RESTORE = 'restore',
    PURGE = 'purge'
}

export interface XSReasonAction<R = any, U = any> {
    reason: XSReason<R>;
    on: string;
    by: U;
}

export interface XSOpeningHours {
}

export interface XSPaymentChargePartial {
}

export interface XSPaymentChargeCreate {
}

export interface XSPaymentCharge {
}

export interface XSTranslatableString {
}

export enum LCEVerificationType {
    SMS = 'sms',
    EMAIL = 'email'
}

export interface XSUserLockRequest<R> {
    reason: XSReason<R>;
    until?: string;
}

export interface XSUserLocking<R, U> {
    locked: boolean;
    reason?: XSReason<R>;
    until?: string;
    lockedOn?: string;
    lockedBy?: U;
    unlockedOn?: string;
    unlockedBy?: U;
    message?: string;
}
