import {Injectable, Optional} from '@angular/core';
import {
    LCECertificate,
    LCECertificateCreate,
    LCECertificateOrder,
    LCECertificateOrderCreate,
    LCECertificateOrderProcuration,
    LCECertificateOrderStatus,
    LCECertificateOrderStatusAudit,
    LCEDelivery,
    LCEDeliveryDestinationType,
    LCEIdentityDocumentType,
    LCEMunicipalityPartial,
    LCERequestorType,
    LCEUserCustomer,
    LCEUserCustomerCreate,
    LCEUtils
} from '@lce/core';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {XS_STR_SPACE, XSAssert, XSCurrency, XSPersonName, XSPKDTOAuditFullState, XSTemporalUtils, XSUtils} from '@xs/base';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '@lce/mock/core/lce-mock-user-data';
import {LCE_MOCK_COORDINATE_PHARMACIE_ANALYA} from '@lce/mock/core/lce-mock-various-data';
import {QR_CODE_DATA} from '@lce/mock/core/lce-mock.constant';
import {LCEMockUserCustomerHandler} from '@lce/mock/core/handlers/user/lce-mock-user-customer-handler';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCEMockCertificateHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-handler';
import {DELIVERY_CUSTOM_LOCATION_POINT_COSMOS_YOPOUGON, DELIVERY_FACILITY_TOWN_HALL_YOPOUGON, DELIVERY_INTERNATIONAL_CANADA, DELIVERY_SERVICE_POINT_COSMOS_YOPOUGON} from '@lce/mock/core/lce-mock-delivery';
import {XSMockUtils} from '@xs/mock';
import {XSPaymentChargePartial, XSPaymentUtils} from '@xs/payment/base';
import {XSPaymentMockDataChargeBuilder} from '@xs/payment/mock';

@Injectable()
export class LCEMockDataCertificateOrderBuilder {

    constructor(
        private mockFacilityHandler: LCEMockFacilityHandler,
        private mockUserCustomerHandler: LCEMockUserCustomerHandler,
        private mockCertificateHandler: LCEMockCertificateHandler,
        @Optional() private paymentMockDataChargeBuilder: XSPaymentMockDataChargeBuilder) {
    }

    public buildCertificateOrder(certificateOrderStatus?: LCECertificateOrderStatus, batchProcessCode?: string, orderCreate?: LCECertificateOrderCreate, charge?: XSPaymentChargePartial): LCECertificateOrder {

        const certificateType = !XSUtils.isEmpty(orderCreate) ? orderCreate!.certificateCreate.type : LCEMockUtils.randomCertificateType();

        const prefix = LCEUtils.getCertificateTypePrefix(certificateType);
        const digits = XSUtils.randomDigits(6);
        const certificateOrderNumber = `LCE-${prefix}-${digits}`;

        const priceFactor = XSUtils.randomInteger(1, 15);
        const unitPrice = 1500;
        const orderCost = {currency: XSCurrency.XOF, value: unitPrice * priceFactor};

        const facilityYopougon = this.mockFacilityHandler.getYopougonTownHall();
        const oStatus = XSUtils.isEmpty(certificateOrderStatus) ? LCECertificateOrderStatus.PROCESSING : certificateOrderStatus!;
        const on = XSTemporalUtils.dateMinus(new Date(), {days: 2}).toISOString();

        return {
            id: XSUtils.uuid(),
            createdBy: LCEMockUtils.randomUserCustomer(),
            createdOn: on,
            statusAudit: this.buildStatusAudit(oStatus, on, LCE_MBO_MOCK_USER_STEVE_JOBS),
            state: XSPKDTOAuditFullState.ACTIVE,
            orderNumber: certificateOrderNumber,
            queueNumber: XSUtils.randomElement([undefined, XSUtils.randomInteger(1, 100).toString()]),
            batchProcessCode: batchProcessCode,
            status: oStatus,
            statusUpdatedOn: on,
            facility: facilityYopougon,
            certificate: this.buildCertificate(orderCreate?.certificateCreate),
            customer: this.buildCustomer(orderCreate?.customerCreate),
            procuration: this.buildProcuration(orderCreate),
            delivery: this.buildDelivery(oStatus, orderCreate),
            numberOfCopies: !XSUtils.isEmpty(orderCreate) ? orderCreate!.numberOfCopies : XSUtils.randomInteger(1, 15),
            cost: orderCost,
            charge: this.paymentMockDataChargeBuilder ? this.buildCharge(orderCreate, charge) : undefined,
            coordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
            qrCode: QR_CODE_DATA,
            cancelData: undefined,
            source: XSUtils.randomElement([LCERequestorType.PUBLIC_MOBILE_APP, LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP])
        };
    }

    private buildStatusAudit(status: LCECertificateOrderStatus, on: string, by: any): LCECertificateOrderStatusAudit {
        const statusAudit: LCECertificateOrderStatusAudit = {
            pending: undefined,
            cancelled: undefined,
            processing: undefined,
            print: undefined,
            error: undefined,
            transit: undefined,
            ready: undefined,
            outForDelivery: undefined,
            delivered: undefined
        };
        statusAudit[status as keyof LCECertificateOrderStatusAudit] = {on: on, by: by};
        return statusAudit;
    }

    private buildDelivery(orderStatus: LCECertificateOrderStatus, orderCreate?: LCECertificateOrderCreate, destinationType: LCEDeliveryDestinationType = LCEDeliveryDestinationType.FACILITY): LCEDelivery {
        let delivery: LCEDelivery;

        if (orderStatus === LCECertificateOrderStatus.DELIVERED) {
            delivery = {...DELIVERY_CUSTOM_LOCATION_POINT_COSMOS_YOPOUGON};
        }
        if (destinationType === LCEDeliveryDestinationType.FACILITY) {
            delivery = {...DELIVERY_FACILITY_TOWN_HALL_YOPOUGON};
        }
        if (destinationType === LCEDeliveryDestinationType.SERVICE_POINT) {
            delivery = {...DELIVERY_SERVICE_POINT_COSMOS_YOPOUGON};
        }
        if (destinationType === LCEDeliveryDestinationType.INTERNATIONAL) {
            delivery = {...DELIVERY_INTERNATIONAL_CANADA};
        }

        if (XSUtils.isEmpty(orderCreate)) return delivery!;
        else {
            return {
                ...delivery!,
                desiredDeliveryDate: orderCreate!.desiredDeliveryDate,
                desiredDeliveryTimeRange: orderCreate!.desiredDeliveryTimeRange,
                note: orderCreate!.deliveryNote
            };
        }

    }

    private buildCertificate(certificateCreate?: LCECertificateCreate, municipality?: LCEMunicipalityPartial): LCECertificate {
        if (XSUtils.isEmpty(certificateCreate)) return this.mockCertificateHandler.buildRandomCertificate(LCEMockUtils.randomCertificateType());
        else {
            let certificate: LCECertificate = {
                ...this.mockCertificateHandler.buildRandomCertificate(LCEMockUtils.randomCertificateType()),
                type: certificateCreate!.type,
                referenceNumber: certificateCreate!.referenceNumber,
                issueDate: certificateCreate!.issueDate
            };
            if (!XSUtils.isEmpty(municipality)) certificate.municipality = municipality!;
            return certificate;
        }
    }

    private buildCustomer(customerCreate?: LCEUserCustomerCreate): LCEUserCustomer | undefined {
        if (XSUtils.isEmpty(customerCreate)) return XSUtils.randomElement([undefined, this.mockUserCustomerHandler.findOneRandomly()]);
        else return {
            ...this.mockUserCustomerHandler.findOneRandomly(),
            name: customerCreate!.name,
            primaryPhoneNumber: customerCreate!.primaryPhoneNumber,
            email: customerCreate!.email
        };
    }

    private buildProcuration(orderCreate?: LCECertificateOrderCreate): LCECertificateOrderProcuration | undefined {


        if (!XSUtils.isEmpty(orderCreate)) {
            if (XSUtils.isEmpty(orderCreate!.procurationCreate)) return undefined;
            else return {
                representativeFullName: orderCreate!.procurationCreate!.representativeFullName,
                representativePhoneNumber: orderCreate!.procurationCreate!.representativePhoneNumber,
                representativeEmail: orderCreate!.procurationCreate!.representativeEmail,
                identityDocumentType: orderCreate!.procurationCreate!.identityDocumentType,
                identityDocumentNumber: orderCreate!.procurationCreate!.identityDocumentNumber
            };
        } else {
            let randomName: XSPersonName = {firstName: XSMockUtils.randomFirstName(), lastName: XSMockUtils.randomLastName()};
            let randomDocumentType: LCEIdentityDocumentType = XSUtils.randomElement(XSUtils.getEnums(LCEIdentityDocumentType));
            return {
                representativeFullName: randomName.firstName + XS_STR_SPACE + randomName.lastName,
                representativePhoneNumber: XSMockUtils.randomPhoneNumberCI(),
                representativeEmail: XSMockUtils.randomEmail(randomName),
                identityDocumentType: randomDocumentType,
                identityDocumentNumber: this.buildIdentityDocumentNumber(randomDocumentType, randomName.firstName)
            } as LCECertificateOrderProcuration;
        }
    }

    private buildDriverLicenceNumber(firstName: string): string {
        XSAssert.notEmpty(firstName, 'firstName');
        let firstFourNameLetter = firstName.slice(0, 4);
        return `${firstFourNameLetter.toUpperCase()}${XSUtils.randomDigits(2)}-${XSUtils.randomDigits(2)}-${XSUtils.randomDigits(8)}${firstFourNameLetter.split('')[3]}`;
    }

    private buildIdentityDocumentNumber(type: LCEIdentityDocumentType, firstName: string): string {
        switch (type) {
            case LCEIdentityDocumentType.PASSPORT:
                return `${XSUtils.randomDigits(8)}`;
            case LCEIdentityDocumentType.DRIVER_LICENSE:
                return this.buildDriverLicenceNumber(firstName);
            case LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD:
                return `CI${XSUtils.randomDigits(10)}`;
        }
    }

    private buildCharge(orderCreate?: LCECertificateOrderCreate, charge?: XSPaymentChargePartial): XSPaymentChargePartial | undefined {
        if (!XSUtils.isEmpty(orderCreate)) {
            if (XSUtils.isEmpty(charge)) return undefined;
            return charge;
        } else {
            return XSPaymentUtils.toChargePartial(this.paymentMockDataChargeBuilder.buildCharge());
        }
    }
}