import {LCEUserPartial} from '../../user/lce-user';
import {XSAuditOnBy, XSPKAuditUpdatedSearchFilter, XSPKDTOAuditUpdated} from '@xs/base';
import {LCECertificateOrderPartial} from './lce-certificate-order';
import {LCENotificationChannel} from '../../lce-notification';

export interface LCECertificateOrderDeliveryAssignmentTiny {
    id: string;
    type: LCECertificateOrderDeliveryAssignmentType;
    status: LCECertificateOrderDeliveryAssignmentStatus;
    assignedOn?: string;
    assignedBy?: LCEUserPartial;
    assignedTo?: LCEUserPartial;
    unassignedOn?: string;
    unassignedBy?: LCEUserPartial;
}

export interface LCECertificateOrderDeliveryAssignmentResponse {
    id: string;
    assignedOn?: string;
    assignedBy?: LCEUserPartial;
    assignedTo: LCEUserPartial;
    unassignedOn?: string;
    unassignedBy?: LCEUserPartial;
}

export interface LCECertificateOrderDeliveryOperationRequest {
    notifyDeliveryMan?: boolean;
    notifyDeliveryManAfterNSeconds?: number;
    notifyChannels?: LCENotificationChannel[];
    note?: string;
}

export interface LCECertificateOrderDeliveryUnassignmentRequest {
    // assignmentID: string;
    certificateOrderID: string;
    notifyDeliveryMan?: boolean;
    notifyDeliveryManAfterNSeconds?: number;
    notifyChannels?: LCENotificationChannel[];
    note?: string;
}

export interface LCECertificateOrderDeliveryAssignmentRequest {
    assignmentType: LCECertificateOrderDeliveryAssignmentType;
    deliveryManID: string;
    certificateOrderID: string;
    notifyDeliveryMan?: boolean;
    notifyDeliveryManAfterNSeconds?: number;
    notifyChannels?: LCENotificationChannel[];
    note?: string;
}

export enum LCECertificateOrderDeliveryAssignmentStatus {
    ASSIGNED = 'assigned',
    UNASSIGNED = 'unassigned',
    ACCEPTED = 'accepted',
    REFUSED = 'refused',
    DELIVERY_IN_PROGRESS = 'deliveryInProgress',
    COMPLETED = 'completed',
    BLOCKED = 'blocked'
}

export interface LCECertificateOrderDeliveryAssignmentStatusAudit {
    assigned?: XSAuditOnBy;
    unassigned?: XSAuditOnBy;
    accepted?: XSAuditOnBy;
    refused?: XSAuditOnBy;
    deliveryInProgress?: XSAuditOnBy;
    completed?: XSAuditOnBy;
    blocked?: XSAuditOnBy;
}

export interface LCECertificateOrderDeliveryAssignmentSearch extends XSPKAuditUpdatedSearchFilter {
    types?: LCECertificateOrderDeliveryAssignmentType[];
    deliveryManIDs?: string[];
    deliveryManCodes?: string[];
    certificateOrderIDs?: string[];
    certificateOrderNumbers?: string[];
    statuses?: LCECertificateOrderDeliveryAssignmentStatus[];
}

export interface LCECertificateOrderDeliveryAssignmentPartial {
    id: string;
    createdOn: string;
    updatedOn?: string;
    type: LCECertificateOrderDeliveryAssignmentType;
    assignedOn: string,
    assignedBy: LCEUserPartial,
    assignedTo: LCEUserPartial;
    certificateOrder: LCECertificateOrderPartial;
    status: LCECertificateOrderDeliveryAssignmentStatus;
}

export interface LCECertificateOrderDeliveryAssignment extends XSPKDTOAuditUpdated<LCEUserPartial> {
    type: LCECertificateOrderDeliveryAssignmentType;

    assignedOn: string;
    assignedBy: LCEUserPartial;
    assignedTo: LCEUserPartial;

    unassignedOn?: string;
    unassignedBy?: LCEUserPartial;

    certificateOrder: LCECertificateOrderPartial;

    status: LCECertificateOrderDeliveryAssignmentStatus;
    statusAudit: LCECertificateOrderDeliveryAssignmentStatusAudit;
}

export enum LCECertificateOrderDeliveryAssignmentAction {
    ASSIGN = 'assign',
    UNASSIGN = 'unassign'
}

export enum LCECertificateOrderDeliveryAssignmentType {
    AUTOMATIC = 'automatic',
    MANUAL = 'manual',
    HYBRID = 'hybrid',
}