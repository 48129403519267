import {LCEDeliveryCompany} from '@lce/core';
import {Injectable} from '@angular/core';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEDeliveryCompanyRecordComponent} from './record/lce-delivery-company-record.component';
import {LCEDeliveryCompanyCreateUpdateComponent} from './create-update/lce-delivery-company-create-update.component';

export interface LCEDeliveryCompanyCreateUpdateArg {
    deliveryCompanyID?: string;
    deliveryCompany?: LCEDeliveryCompany;
    showRecordAfterSave?: boolean;
    onClose?: (deliveryCompany?: LCEDeliveryCompany) => void;
    onSave?: (deliveryCompany: LCEDeliveryCompany) => void;
}

export interface LCEDeliveryCompanyRecordArg {
    deliveryCompanyID?: string;
    deliveryCompany?: LCEDeliveryCompany;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (deliveryCompany?: LCEDeliveryCompany) => void;
    onEdit?: (deliveryCompany: LCEDeliveryCompany) => void;
}

@Injectable()
export class LCEDeliveryCompanyDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecord(arg: LCEDeliveryCompanyRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.deliveryCompanyID) && XSUtils.isEmpty(arg.deliveryCompany)) {
            throw new Error('arg.deliveryCompanyID and arg.deliveryCompany cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.deliveryCompanyID) && !XSUtils.isEmpty(arg.deliveryCompany)) {
            throw new Error('arg.deliveryCompanyID and arg.deliveryCompany cannot both be set at the same time.');
        }

        const dConfig: XSDialogConfig = {
            data: {
                deliveryCompanyID: arg.deliveryCompanyID,
                deliveryCompany: arg.deliveryCompany,
                showEditButton: arg.showEditButton,
                styleClass: 'xs-width-full',
                dataManager: arg.dataManager,
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEDeliveryCompanyRecordComponent = this.dialogService.openComponentDialog(LCEDeliveryCompanyRecordComponent, dConfig);
        recordComponent.closeEvent.subscribe((savedDeliveryCompany: LCEDeliveryCompany) => {
            if (arg?.onClose) arg!.onClose!(savedDeliveryCompany);
        });
        recordComponent.editEvent.subscribe((deliveryCompanyRecord: LCEDeliveryCompany) => {
            if (arg?.onEdit) arg.onEdit(deliveryCompanyRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdate({
                    deliveryCompany: deliveryCompanyRecord,
                    onClose: (deliveryCompany) => this.openRecord({deliveryCompany: deliveryCompany, showEditButton: true}),
                    onSave: (deliveryCompany) => this.openRecord({deliveryCompany: deliveryCompany, showEditButton: true})
                });
            }
        });
    }


    public openCreateUpdate(arg?: LCEDeliveryCompanyCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.deliveryCompanyID) && !XSUtils.isEmpty(arg?.deliveryCompany)) {
            throw new Error('arg.deliveryCompanyID and arg.deliveryCompany cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                deliveryCompanyID: arg?.deliveryCompanyID,
                deliveryCompany: arg?.deliveryCompany,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            headerTextStyleClass: 'xs-font-size-intermediate-imp',
            headerSubTextStyleClass: 'xs-font-size-extra-small-imp',
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEDeliveryCompanyCreateUpdateComponent = this.dialogService.openComponentDialog(LCEDeliveryCompanyCreateUpdateComponent, dConfig);

        createUpdateComponent.closeEvent.subscribe((deliveryCompany: LCEDeliveryCompany) => {
            if (arg?.onClose) arg!.onClose!(deliveryCompany);
        });
        createUpdateComponent.saveEvent.subscribe((deliveryCompany: LCEDeliveryCompany) => {
            if (!deliveryCompany) {
                createUpdateComponent.closeDialog();
                return;
            }
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(deliveryCompany);
            if (arg?.showRecordAfterSave === true) this.openRecord({deliveryCompany: deliveryCompany});
        });
    }
}
