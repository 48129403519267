import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCEFacilityWorker, LCEFacilityWorkerPartial, LCEFacilityWorkerSearch, LCEFacilityWorkerService} from '@lce/core';
import {XSSearchResult, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XS_TR_ACTIVATE, XS_TR_DEACTIVATE, XS_TR_MARK_AS_DELETED, XS_TR_PURGE, XS_TR_RESTORE, XSDataManagerComponent, XSDataManagerOptions, XSMenuItem, XSStyleHelper} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEFacilityWorkerDialogService} from './lce-facility-worker-dialog.service';

@Component({
    selector: 'lce-facility-workers',
    templateUrl: './lce-facility-workers.component.html',
    host: {class: 'xs-flex-column xs-width-full xs-height-full xs-flex-1 xs-max-width-1250'},
    providers: [LCEFacilityWorkerDialogService]
})
export class LCEFacilityWorkersComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_CORE: string = 'xs.core.';
    readonly TR_CORE_LABEL: string = this.TR_CORE + 'label.';
    readonly TR_ERROR_MESSAGE: string = this.TR_CORE + 'errorMessage.';

    TR_BASE: string;
    TR_BASE_MAIN: string;
    TR_BASE_LABEL: string;

    @Input() styleClass?: string;
    dataManagerOptions: XSDataManagerOptions;
    @ViewChild('dataManager') dataManager: XSDataManagerComponent;
    @ViewChild('actionMenu') actionMenu: TieredMenu;
    actionMenuItems: XSMenuItem[];
    readonly LOADER_ID = XSUtils.uuid();
    actionError: any;
    errorMessage: string;
    private subscription: Subscription = new Subscription();

    constructor(
        private facilityWorkerService: LCEFacilityWorkerService,
        private facilityWorkerDialogService: LCEFacilityWorkerDialogService,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService
    ) {
    }

    ngOnInit(): void {
        this.initialize();
        this.buildDataManagerOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onMenuClick(event: any): void {
        this.buildActionMenuItems();
        this.actionMenu.toggle(event);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public onAddFacilityWorker(): void {
        this.facilityWorkerDialogService.openCreateUpdateDialog({
            facilityWorker: this.dataManager.selectedRecordData,
            showRecordAfterSave: true,
            onSave: (facilityWorkerRecord) => {
                this.dataManager.updateSelectedRecordData(facilityWorkerRecord);
                const currentLanguage = this.translationService.getCurrentLanguage();
            }
        });
    }

    public canEdit(): boolean {
        return this.canDoAction();
    }

    public canActivate(): boolean {
        return this.canDoAction();
    }

    public canDisable(): boolean {
        return this.canDoAction();
    }

    public canDeleteAndRestore(): boolean {
        return this.canDoAction();
    }

    public canPurge(): boolean {
        return this.canDoAction();
    }

    public onDeleteFacilityWorker(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.actionError = null;
        this.facilityWorkerService
            .setAsDeleted(this.dataManager.selectedRowResult.id)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: (facilityWorkerRecord) => {
                    this.dataManager.updateSelectedRecordData(facilityWorkerRecord);
                },
                error: (error) => {
                    this.actionError = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'deleteError';
                }
            });
    }

    public onRestoreFacilityWorker(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.actionError = null;

        this.facilityWorkerService
            .restore(this.dataManager.selectedRowResult.id)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: (facilityWorkerRecord) => {
                    this.dataManager.updateSelectedRecordData(facilityWorkerRecord);
                },
                error: (error) => {
                    this.actionError = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'restoreError';
                }
            });
    }

    public onPurgeFacilityWorker(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.actionError = null;

        this.facilityWorkerService
            .purge(this.dataManager.selectedRowResult.id)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: () => {
                    this.dataManager.search();
                },
                error: (error) => {
                    this.actionError = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'purgeError';
                }
            });
    }

    private buildActionMenuItems(): void {
        const facilityWorkerRecord = this.dataManager.selectedRecordData as LCEFacilityWorker;

        this.actionMenuItems = [
            {
                trLabel: XS_TR_DEACTIVATE,
                command: () => this.markAsDeactivate(),
                visible: facilityWorkerRecord.active && this.canDisable()
            },
            {
                trLabel: XS_TR_ACTIVATE,
                command: () => this.markAsActive(),
                visible: !facilityWorkerRecord.active && this.canActivate()
            },
            {
                trLabel: XS_TR_MARK_AS_DELETED,
                styleClass: XSStyleHelper.CLASS.color.danger,
                visible: (XSUtils.isNull(facilityWorkerRecord.deleted) || facilityWorkerRecord.deleted === false) && this.canDeleteAndRestore(),
                command: () => this.onDeleteFacilityWorker()
            },
            {
                trLabel: XS_TR_RESTORE,
                visible: facilityWorkerRecord.deleted === true && this.canDeleteAndRestore(),
                command: () => this.onRestoreFacilityWorker()
            },
            {
                trLabel: XS_TR_PURGE,
                styleClass: XSStyleHelper.CLASS.color.danger,
                visible: this.canPurge(),
                command: () => this.onPurgeFacilityWorker()
            }
        ];
        this.translationService.translateItems(this.actionMenuItems);
    }

    private markAsDeactivate(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.actionError = null;

        const facilityWorkerRecord = this.dataManager.selectedRecordData as LCEFacilityWorker;
        this.facilityWorkerService
            .deactivate(facilityWorkerRecord.id)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: (facilityWorkerRecord) => {
                    this.dataManager.updateSelectedRecordData(facilityWorkerRecord);
                },
                error: (error) => {
                    this.actionError = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'disableError';
                }
            });
    }

    private markAsActive(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.actionError = null;

        const facilityWorkerRecord = this.dataManager.selectedRecordData as LCEFacilityWorker;
        this.facilityWorkerService
            .activate(facilityWorkerRecord.id)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: (facilityWorkerRecord) => {
                    this.dataManager.updateSelectedRecordData(facilityWorkerRecord);
                },
                error: (error) => {
                    this.actionError = error;
                    this.errorMessage = this.TR_ERROR_MESSAGE + 'activeError';
                }
            });
    }

    private canDoAction(): boolean {
        return !this.dataManager.isLoaderRunning() && this.dataManager.hasSelectedRowResult();
    }

    private retrieveFacilityWorker(selectedRowResult: LCEFacilityWorkerPartial): Observable<LCEFacilityWorker> {
        return this.facilityWorkerService.retrieve(selectedRowResult.id!);
    }

    private searchFacilityWorker(query?: string): Observable<XSSearchResult<LCEFacilityWorkerPartial>> {
        const facilityWorkerSearch: LCEFacilityWorkerSearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: 0,
            paginationSize: 20,
            sort: ['name|desc']
        };
        return this.facilityWorkerService.search(facilityWorkerSearch);
    }

    private initialize(): void {
        this.TR_BASE = 'lce.shared.facilityWorker.';
        this.TR_BASE_MAIN = this.TR_BASE + 'main.';
        this.TR_BASE_LABEL = this.TR_BASE + 'label.';
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query) => this.searchFacilityWorker(query),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveFacilityWorker(selectedRowData),

            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultsFound'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
