import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEUserPartial} from '@lce/core';
import {XS_STR_EMPTY, XS_STR_SPACE, XSAssert, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSTranslationService} from '@xs/common';
import {XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC, XSIvarAvatarSize, XSIvarAvatarSizeStr, XSIvarAvatarType, XSTooltip} from '@xs/core';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'lce-user-ivar',
    templateUrl: './lce-user-ivar.component.html'
})
export class LCEUserIvarComponent implements OnInit, OnChanges {

    readonly BASE_STYLE_CLASS_BG_COLOR_USER_STATUS: string = 'lce-user-status-background-';

    readonly IMAGE_NOT_FOUND_USER_AVATAR_SRC = XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC;

    readonly STR_SPACE = XS_STR_SPACE;

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() avatarStyleClass?: string;
    @Input() personNameStyleClass?: string;
    @Input() userPositionStyleClass?: string;
    @Input() userCodeStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCEUserPartial;

    @Input() avatarSize?: XSIvarAvatarSize | XSIvarAvatarSizeStr;

    @Input() showAvatarBackground?: boolean;
    @Input() showOnlyAvatar?: boolean;
    @Input() showPosition?: boolean;
    @Input() showCode?: boolean;
    @Input() showStatus?: boolean;
    @Input() showTooltip?: boolean;
    @Input() viewDetail?: boolean;
    @Input() showViewRecordButton?: boolean;
    @Input() userIcon?: boolean;

    @Output() avatarClickEvent = new EventEmitter<Event>();

    @ViewChild('detailOverlay') detailOverlay: OverlayPanel;

    avatar: string;
    avatarType: XSIvarAvatarType;
    avatarStatusTooltip: XSTooltip | undefined;

    private readonly TR_BASE_USER_STATUS: string = 'lce.shared.user.status.';

    constructor(private translationService: XSTranslationService) {
    }

    get userCode(): string | undefined {
        return this.showCode === true ? this.data.code : XS_STR_EMPTY;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.handleDefaults();
        this.handleAvatar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) this.handleAvatar();
        if (!XSUtils.isEmpty(changes.small) && !changes.small!.isFirstChange()) this.handleAvatar();
    }

    public onAvatarClick(event: Event) {
        if (this.disabled === true || !this.isAvatarClickable()) {
            event.stopImmediatePropagation();
            return;
        }
        if (this.viewDetail === true) this.detailOverlay.toggle(event);
        this.avatarClickEvent.emit(event);
    }

    public isAvatarClickable(): boolean {
        return this.viewDetail === true || this.avatarClickEvent.observers.length > 0;
    }

    public getAvatarStatusTooltip(): XSTooltip | undefined {
        if (XSUtils.isEmpty(this.data.status)) return undefined;
        const personFullName = XSCommonDomainUtils.getPersonFullName(this.data.name!);
        const userStatusText = this.translationService.translate(this.TR_BASE_USER_STATUS + this.data.status);
        return {text: personFullName + ' : ' + userStatusText};
    }

    public hasViewDetailPermission(): boolean {
        return true; // TODO
    }

    public isSizeSmall(): boolean {
        return this.avatarSize === 'small';
    }

    private handleAvatar(): void {
        this.avatarType = XSUtils.isEmpty(this.data.profileImage) ? XSIvarAvatarType.LABEL : XSIvarAvatarType.IMAGE;
        this.avatar = XSUtils.isEmpty(this.data.profileImage) ? XSCommonDomainUtils.getPersonInitials(this.data.name!) : this.data.profileImage!;
        this.avatarStatusTooltip = this.getAvatarStatusTooltip();
        if (this.userIcon === true) {
            this.avatar = this.ICON.user;
            this.avatarType = XSIvarAvatarType.ICON;
        }
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.showPosition)) this.showPosition = true;
        if (XSUtils.isNull(this.showCode)) this.showCode = false;
        if (XSUtils.isNull(this.avatarSize)) this.avatarSize = XSIvarAvatarSize.MEDIUM;
        if (XSUtils.isNull(this.showOnlyAvatar)) this.showOnlyAvatar = false;

        if (XSUtils.isNull(this.showStatus)) {
            this.showStatus = this.isSizeSmall() ? false : true;
        }
        if (XSUtils.isEmpty(this.data.status)) this.showStatus = false;

        if (XSUtils.isNull(this.viewDetail)) this.viewDetail = true;
    }
}
