import { Injectable } from '@angular/core';
import { XSAssert, XS_STR_SPACE } from '@xs/base';
import { XSDialogConfig, XSDialogService } from '@xs/core';
import { XSTranslationService } from '@xs/common';
import { LCEFacilityWorkersComponent } from './lce-facility-workers.component';

export interface LCEFacilityWorkerDataManagerDialogArg {
    facilityCode: string;
    facilityFullName: string;
    onClose?: (facilityCode?: string) => void;
}

@Injectable()
export class LCEFacilityWorkerDataManagerDialogService {

    constructor(private translationService: XSTranslationService, private dialogService: XSDialogService) {}

    public openDataManagerDialog(arg:  LCEFacilityWorkerDataManagerDialogArg): void {
        XSAssert.notEmpty(arg, 'arg');
        let headerText = this.translationService.translateKey('lce.shared.facilityWorker.main.title');
        headerText += XS_STR_SPACE + '-' + XS_STR_SPACE + arg.facilityFullName;

        const dConfig: XSDialogConfig = {
            data: {
                styleClass: 'xs-width-full',
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerText: headerText,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1200',
            onClose: () => {
                if (arg.onClose) arg.onClose!(arg.facilityCode);
            }
        };
        this.dialogService.openComponentDialog(LCEFacilityWorkersComponent, dConfig);
    }
}
