import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lce-brand',
    template: `
        <div
                (click)="clickEvent.emit()"
                [ngClass]="{'xs-clickable': clickable}"
                [style.letter-spacing]="letterSpacing"
                class="lce-brand {{styleClass}}">
            <span class="xs-display-block {{textStyleClass}}" [innerHTML]="text | translate"></span>
        </div>
    `,
    host: {class: 'xs-width-full'}
})
export class LCEBrandComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() textStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() text: string;

    @Input() letterSpacing?: string;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<Event>();

    ngOnInit() {
        XSAssert.notEmpty(this.text, 'text');
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
        this.handleLetterSpacing();
    }

    public onClick(event: Event): void {
        if (!this.clickable || this.disabled) {
            event.stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(event);
    }

    private handleLetterSpacing(): void {
        if (XSUtils.isEmpty(this.letterSpacing)) return;
        if (!this.letterSpacing!.trim().endsWith('px')) this.letterSpacing = this.letterSpacing + 'px';
    }
}