<div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="retrieveErrorRetryButton"
                [error]="retrieveError"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="lce.shared.birthDeclaration.label.errorMessageRetrieve"
                mode="block"
                styleClass="xs-max-width-500"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card xs-max-width-1500">
        <div *ngIf="showHeader" class="xs-card-header">
            <xs-title
                    [icon]="ICON.birthDeclaration"
                    iconStyleClass="xs-font-size-30-imp"
                    subText="{{ TR_BASE + 'createSubTitle' | translate }}"
                    subTextStyleClass="xs-color-secondary"
                    text="{{ TR_BASE + 'createTitle' | translate }}"
                    textStyleClass="xs-color-secondary xs-font-weight-normal">
            </xs-title>
        </div>

        <div class="xs-card-content">
            <div class="grid">
                <div class="col-12">
                    <lce-birth-declaration-delivery-create-update #deliveryForm [(formGroup)]="deliveryFormGroup" [delivery]="birthDeclaration?.delivery">
                    </lce-birth-declaration-delivery-create-update>
                </div>
                <div class="col-12 md:col-6">
                    <lce-birth-declaration-parent-create-update #motherParentForm [(formGroup)]="motherFormGroup" [data]="birthDeclaration?.mother" type="mother">
                    </lce-birth-declaration-parent-create-update>
                </div>
                <div class="col-12 md:col-6">
                    <lce-birth-declaration-parent-create-update #fatherParentForm [(formGroup)]="fatherFormGroup" [data]="birthDeclaration?.father" type="father">
                    </lce-birth-declaration-parent-create-update>
                </div>
            </div>
        </div>

        <div *ngIf="showFooter" class="xs-card-footer">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-end">
                <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form" size="intermediate" type="text"></xs-button>
                <xs-button
                        (clickEvent)="reset()"
                        *ngIf="isCreateMode()"
                        [confirmation]="resetConfirmation"
                        [disabled]="createUpdateLoading"
                        class="xs-mr-10"
                        confirmationPopupStyleClass="xs-font-size-10"
                        label="xs.core.label.reset"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="createUpdate()"
                        [disabled]="centralLoader.isRunning()"
                        [loading]="createUpdateLoading"
                        label="xs.core.label.save"
                        type="button">
                </xs-button>
            </div>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <xs-ivar [ellipsis]="true" [icon]="ICON.birthDeclaration" [line1]="headerTitle" [line2]="headerSubTitle" styleClass="xs-dialog-header"></xs-ivar>
</ng-template>

<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }" class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-error
                *ngIf="hasCreateUpdateError()"
                [ellipsis]="true"
                [error]="createUpdateError"
                [showErrorDetailButton]="true"
                errorDetailButtonClass="xs-mt-2-imp"
                message="lce.shared.birthDeclaration.label.errorMessageCreateUpdate"
                mode="inline">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form" size="intermediate" type="text"></xs-button>
            <xs-button
                    (clickEvent)="reset()"
                    *ngIf="isCreateMode()"
                    [confirmation]="resetConfirmation"
                    [disabled]="createUpdateLoading"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="createUpdateLoading || centralLoader.isRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.close"
                    severity="secondary"
                    styleClass="xs-mlr-15-imp"
                    type="button">
            </xs-button>
            <xs-button (clickEvent)="createUpdate()" [disabled]="centralLoader.isRunning()" [loading]="createUpdateLoading" label="xs.core.label.save" type="button"></xs-button>
        </div>
    </div>
</ng-template>
