import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEUserPartial} from '@lce/core';
import {XSPersonName, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSPersonNamePipe} from '@xs/common';
import {XSCalendarOptions} from '@xs/core';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'lce-user-resource-audit-inline',
    templateUrl: './lce-user-resource-audit-inline.component.html',
    providers: [XSPersonNamePipe]
})
export class LCEUserResourceAuditInlineComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() onStyleClass?: string;
    @Input() byStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() on?: string;
    @Input() by?: LCEUserPartial;

    @Input() onPrefix?: string;

    @Input() oneLine?: boolean;

    @Input() showCalendarIcon?: boolean;

    @Input() viewDetail?: boolean;
    @Input() showViewRecordButton?: boolean;

    calendarOptions: XSCalendarOptions = {
        type: 'date',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR,
        prefixStyleClass: 'xs-font-size-intermediate xs-font-weight-normal-imp'
    };

    @ViewChild('detailOverlay') detailOverlay: OverlayPanel;

    private readonly TR_ANONYMOUS: string = 'lce.core.label.anonymous';

    constructor(private personNamePipe: XSPersonNamePipe) {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.oneLine)) this.oneLine = false;
        if (XSUtils.isNull(this.viewDetail)) this.viewDetail = true;
        this.calendarOptions.prefix = this.onPrefix;
    }

    public getName(personName: XSPersonName | undefined): string {
        if (XSUtils.isEmpty(personName)) {
            return this.TR_ANONYMOUS;
        }
        return this.personNamePipe.transform(personName);
    }

    public onNameClick(event: any): void {
        if (this.viewDetail !== true) return;
        this.detailOverlay.toggle(event);
    }

    public hasViewRecordPermission(): boolean {
        return true; // TODO
    }
}
