import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCERequestorType, LCEUserCustomer, LCEUserCustomerAdditionalStats, LCEUserCustomerCreate, LCEUserCustomerPartial, LCEUserCustomerSearch, LCEUserType} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUserAccountHandler} from '@lce/mock/core/handlers/user/lce-mock-user-account-handler';
import {LCEMockUserFinder} from '@lce/mock/core/handlers/user/lce-mock-user-finder';
import {LCEMockUserHandler} from '@lce/mock/core/handlers/user/lce-mock-user-handler';
import {LCE_MBO_MOCK_CUSTOMER_RECORD_ANGELINA_JOLIE, LCE_MBO_MOCK_CUSTOMER_RECORD_BRAD_PITT, LCE_MBO_MOCK_CUSTOMER_RECORD_VICTOR_HUGO} from '@lce/mock/core/lce-mock-user-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSPKDTOStats, XSTemporalUtils, XSUtils} from '@xs/base';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.customers';

@Injectable()
export class LCEMockUserCustomerHandler extends LCEMockUserHandler<LCEUserCustomer, LCEUserCustomerPartial, LCEUserCustomerSearch> {

    public static CUSTOMER_STORAGE: Map<string, LCEUserCustomer> = new Map<string, LCEUserCustomer>();

    constructor(
        protected contextService: LCECoreContextService,
        protected mockMunicipalityHandler: LCEMockMunicipalityHandler,
        protected mockUserAccountHandler: LCEMockUserAccountHandler) {
        super(contextService, mockMunicipalityHandler, mockUserAccountHandler, DATASET_BASE_ID, LCE_CORE_ENDPOINTS.customers.index);
        this.mockDataArray = [];
        LCEMockUserFinder.USER_HANDLER_MAP.set(LCEUserType.CUSTOMER, this);
    }

    public getStorage(): Map<string, LCEUserCustomer> {
        return LCEMockUserCustomerHandler.CUSTOMER_STORAGE;
    }

    public toPartial(customer: LCEUserCustomer): LCEUserCustomerPartial {
        return {
            ...this.baseToPartial(customer),
            createdOn: customer.createdOn,
            createdFrom: customer.createdFrom,
            registeredOn: customer.registeredOn,
            registeredFrom: customer.registeredFrom
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.customers.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Autocomplete
            this.buildCountMockData(),
            this.buildAutocompleteMockData({queryPredicates: this.getQueryPredicates()}),
            // Search
            this.buildSearchMockData({
                queryPredicates: this.getQueryPredicates(),
                predicates: this.getSearchPredicates()
            }),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: XSUtils.randomInteger(0, 100),
                    active: XSUtils.randomInteger(0, 60),
                    inactive: XSUtils.randomInteger(0, 10),
                    deleted: XSUtils.randomInteger(0, 5)
                } as XSPKDTOStats
            },
            // Additional Stats
            {
                id: DATASET_BASE_ID + '.stats.additional',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.customers.index + '/' + LCE_CORE_ENDPOINTS.customers.additionalStats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: XSUtils.randomInteger(0, 100),
                    registered: XSUtils.randomInteger(0, 60),
                    unregistered: XSUtils.randomInteger(0, 10),
                    orderedAtLeastOnce: XSUtils.randomInteger(0, 5),
                    neverOrdered: XSUtils.randomInteger(0, 5)
                } as LCEUserCustomerAdditionalStats
            }
        ];
    }

    protected buildCode(): string {
        return 'LCE-CUS-' + XSUtils.randomDigits(6);
    }

    private createResponseData(body: any): LCEUserCustomer {
        const customerCreate: LCEUserCustomerCreate = body as LCEUserCustomerCreate;
        const customer: LCEUserCustomer = {
            ...this.baseCreate(customerCreate),
            type: LCEUserType.CUSTOMER
        };

        LCEMockUserCustomerHandler.CUSTOMER_STORAGE.set(customer.id, customer);
        this.addUserMockData(customer.id);

        return customer;
    }

    private buildStorage(n: number = 5): void {
        const predefinedCustomers = [LCE_MBO_MOCK_CUSTOMER_RECORD_VICTOR_HUGO, LCE_MBO_MOCK_CUSTOMER_RECORD_ANGELINA_JOLIE, LCE_MBO_MOCK_CUSTOMER_RECORD_BRAD_PITT];
        predefinedCustomers.forEach(predefinedCustomer => {
            LCEMockUserCustomerHandler.CUSTOMER_STORAGE.set(predefinedCustomer.id, predefinedCustomer);
            this.addUserMockData(predefinedCustomer.id);
        });

        for (let $i = 0; $i < n; $i++) {
            const customer = this.buildRandomCustomer();
            LCEMockUserCustomerHandler.CUSTOMER_STORAGE.set(customer.id, customer);
            this.addUserMockData(customer.id);
        }
    }

    private buildRandomCustomer(): LCEUserCustomer {
        return {
            ...this.baseRandomUser(),
            type: LCEUserType.CUSTOMER,
            registered: true,
            registeredOn: XSTemporalUtils.dateMinus(new Date(), {years: 1}).toISOString(),
            createdFrom: XSUtils.randomElement([LCERequestorType.PUBLIC_MOBILE_APP, LCERequestorType.PUBLIC_MOBILE_APP, LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP, LCERequestorType.WEBSITE_APP]),
            registeredFrom: XSUtils.randomElement([LCERequestorType.PUBLIC_MOBILE_APP, LCERequestorType.PUBLIC_MOBILE_APP, LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP, LCERequestorType.WEBSITE_APP])
        };
    }
}
