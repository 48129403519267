<div [ngClass]="{ 'xs-overflow-hidden': ellipsis === true }"
     class="xs-flex-inline xs-width-full xs-align-items-center xs-justify-content-center xs-background-discrete xs-border-radius-25 xs-plr-20 xs-ptb-4 {{ styleClass }} {{ containerStyleClass }}">
    <i *ngIf="showCircle" [style.color]="statusColor.color" class="{{ ICON.circle }} xs-font-size-small-imp xs-mr-10"></i>
    <span
            [ngClass]="{ 'xs-ellipsis': ellipsis === true}"
            class="xs-display-block xs-font-size-intermediate xs-white-space-nowrap xs-color-secondary xs-font-weight-500 {{ statusStyleClass }}">
            {{ 'lce.shared.deliveryCompany.label.registerStatus.' + statusColor.status | translate }}
        </span>

</div>
