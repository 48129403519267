<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card xs-min-height-400 {{ styleClass }}">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-ivar [line1]="TR_BASE + 'title'"
                 line1StyleClass="xs-color-secondary xs-font-weight-400 xs-font-size-medium-imp">
        </xs-ivar>
    </div>
    <div [ngClass]="{ 'xs-pt-30-imp': showHeader }" class="xs-card-content">
        <div class="grid xs-width-full xs-min-height-225">
            <div class="col-3 xs-flex-row-center">
                <div class="xs-flex-column-center {{ leftSideStyleClass }}">
                    <i [style.font-size]="'75px'" class="xs-display-block xs-color-secondary {{ ICON_ASSIGNMENT }}"></i>
                </div>
            </div>
            <div class="col-9 xs-border-left-discrete xs-position-relative xs-pl-30-imp">
                <div class="xs-absolute-center-vh">
                    <xs-loader
                        #assigmentLoader
                        [loaderId]="LOADER_ID"
                        [loaderSize]="30">
                    </xs-loader>
                </div>

                <div *ngIf="hasError() && !assigmentLoader.isRunning()" class="xs-absolute-center-vh">
                    <xs-error
                            [actionButton]="errorRetryButton"
                            [error]="error"
                            [showActionButton]="true"
                            [showErrorDetailButton]="true"
                            message="lce.shared.assignment.error.messageRetrieve"
                            mode="block"
                            subMessage="xs.core.error.contactAdminMessage">
                    </xs-error>
                </div>

                <div *ngIf="canDisplayData()" class="grid xs-fields xs-fields-spacer-30">
                    <div class="col-12">
                        <div class="xs-data-field">
                            <lce-user-resource-audit [data]="data!"></lce-user-resource-audit>
                        </div>
                    </div>

                    <div class="col-12 lg:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-text
                                    [label]="TR_BASE + 'label.type'"
                                    [value]="TR_BASE + 'type.' + data!.type">
                            </xs-data-field-text>
                        </div>
                    </div>

                    <div class="col-12 lg:col-6">
                        <div class="xs-data-field">
                            <div class="xs-data-field-value-container">
                                <lce-delivery-assignment-status
                                        [statusAudit]="data!.statusAudit"
                                        [status]="data!.status"
                                        styleClass="xs-mr-15"
                                        type="text">
                                </lce-delivery-assignment-status>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="data!.unassignedOn | xsIsNotEmpty" class="grid xs-fields xs-fields-spacer-30">
                        <div class="col-12 lg:col-6">
                            <div class="xs-data-field">
                                <xs-data-field-label [label]="TR_BASE + 'label.unassignedOn'"></xs-data-field-label>
                                <div class="xs-data-field-value-container">
                                    <xs-data-calendar
                                            [options]="calendarOptions"
                                            [value]="data!.unassignedOn!"
                                            valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                                    </xs-data-calendar>
                                </div>
                                <xs-time-ago
                                        [givenDate]="data!.unassignedOn!"
                                        styleClass="xs-display-block xs-font-size-small-imp xs-color-secondary xs-mt-5">
                                </xs-time-ago>
                            </div>
                        </div>

                        <div class="col-12 lg:col-6">
                            <div class="xs-data-field">
                                <div class="xs-data-field-value-container">
                                    <lce-user-partial
                                            [data]="data!.unassignedBy!"
                                            [label]="TR_BASE + 'label.unassignedBy'"
                                            [showAvatar]="true">
                                    </lce-user-partial>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div
                        *ngIf="canDisplayData()"
                        [ngClass]="{'xs-disabled xs-background-discrete xs-border-radius-8 xs-p-10': data!.unassignedOn | xsIsNotEmpty}"
                        class="grid xs-fields xs-fields-spacer-30">

                    <div class="col-12 lg:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'label.on'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <xs-data-calendar
                                        [options]="calendarOptions"
                                        [value]="data!.assignedOn"
                                        valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                                </xs-data-calendar>
                            </div>
                            <xs-time-ago
                                    [givenDate]="data!.assignedOn"
                                    styleClass="xs-display-block xs-font-size-small-imp xs-color-secondary xs-mt-5">
                            </xs-time-ago>
                        </div>
                    </div>

                    <div class="col-12 lg:col-6">
                        <div class="xs-data-field">
                            <div class="xs-data-field-value-container">
                                <lce-user-partial
                                        [data]="data!.assignedBy"
                                        [label]="TR_BASE + 'label.by'"
                                        [showAvatar]="true">
                                </lce-user-partial>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="xs-data-field">
                            <div class="xs-data-field-value-container">
                                <lce-delivery-man-assignment
                                        [data]="data!.assignedTo"
                                        [showRefreshButton]="showRefreshOrderNumberButton || (data!.unassignedOn | xsIsEmpty)"
                                        styleClass="{{ (showRefreshOrderNumberButton || (data!.unassignedOn | xsIsEmpty)) ? 'xs-container-left-stripe xs-container-left-stripe-light-imp' : ''}}">
                                </lce-delivery-man-assignment>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>