<div class="lce-certificate-orders-main {{ styleClass }}">
    <div class="lce-certificate-orders-main-container">

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Toolbar === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div
                class="xs-flex-row xs-width-full xs-align-items-center xs-justify-content-space-between xs-mt-5"
        >
            <div class="xs-flex-row xs-align-items-center">
                <div class="xs-display-block xs-pr-15 xs-border-right-discrete">
                    <lce-certificate-orders-pending-orders-square
                        #pendingOrdersSquare
                        (clickEvent)="searchPendingOrders()"
                        [municipalityCode]="municipalityCode">
                    </lce-certificate-orders-pending-orders-square>
                </div>
                <div class="xs-display-block xs-pl-15">
                    <lce-certificate-order-last
                        #lastOrder
                        [municipalityCode]="municipalityCode"
                        [orderNumberClickable]="true"
                        [showBorder]="false"
                        [showLabel]="true" class="xs-min-width-150 xs-height-55">
                    </lce-certificate-order-last>
                </div>
            </div>
            <div class="xs-flex-row xs-border-discrete xs-border-radius-50 xs-ptb-7 xs-plr-25">
                <xs-button
                        (clickEvent)="onProcessPendingOrders()"
                        *ngIf="readonly !== true"
                        class="xs-mr-75"
                        label="{{ TR_BASE + 'pendingOrders.buttonLabel' | translate }} ({{ ((pendingOrdersSquare | xsIsNotEmpty) && (pendingOrdersSquare.nPendingOrders | xsIsNotNull)) ?
                        pendingOrdersSquare.nPendingOrders : '...' }})"
                        size="intermediate"
                        styleClass="xs-button-label-font-weight-500"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="onBatchProcessManagerClick()"
                        label="{{ TR_BASE + 'certificateOrderBatchProcess.buttonLabel' | translate }} ({{ countNonCompletedBatchProcess }})"
                        size="intermediate"
                        styleClass="xs-button-label-font-weight-500"
                        type="text">
                </xs-button>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Statistics === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div *ngIf="statisticsSectionVisible != false" class="xs-flex-column-center xs-width-full xs-border-discrete xs-border-radius-default xs-p-15 xs-mt-25 xs-mb-40">
            <xs-title
                    [expandCollapseEnable]="true"
                    [expandCollapseTarget]="statisticsSection"
                    [expanded]="true"
                    [showSeparator]="false"
                    [storageEnable]="true"
                    [storageKey]="STORAGE_KEY_STATISTICS_EXPANDED"
                    [storageRootKey]="STORAGE_KEY"
                    [text]="TR_BASE + 'stats.title'"
                    styleClass="xs-mb-10"
                    textStyleClass="xs-font-size-medium-imp xs-font-weight-400-imp">
            </xs-title>

            <div #statisticsSection class="xs-flex-column-center xs-width-full">

                <p-selectButton
                        (onChange)="onStatsFilterChange()"
                        [(ngModel)]="statsPeriodFilter"
                        [options]="statsPeriodFilterItems"
                        styleClass="xs-select-button xs-select-button-small xs-width-600 xs-mb-20">
                </p-selectButton>
                <div [style.min-width]="'375px'" class="xs-width-full">
                    <div class="col-12">
                        <lce-certificate-order-data-boxes
                            #dataBoxes
                            (mainValueEvent)="handleDataBoxesCOrderMainValueEvent($event)"
                            (orderErrorEvent)="handleDataBoxesCOrderErrorEvent($event)"
                            [predefinedPeriod]="statsPeriodFilter"
                        ></lce-certificate-order-data-boxes>
                    </div>
                </div>
            </div>

        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-flex-row-center xs-width-full xs-mt-20">
            <div class="xs-field xs-field-input xs-field-input-search xs-max-width-750">
                <label
                        [innerHTML]="TR_BASE + 'label.searchOrders' | translate"
                        class="xs-font-size-medium-imp xs-text-primary-color-imp"
                        for="{{ searchText }}">
                </label>
                <xs-input-search
                        (changeEvent)="search()"
                        [(value)]="searchText"
                        [delay]="SEARCH_TEXT_DELAY"
                        [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                        [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                        [placeholder]="TR_BASE + 'label.searchTextPlaceholder'"
                        [searching]="searching"
                        inputId="searchText">
                </xs-input-search>
                <div class="xs-sm-display-none xs-disabled xs-flex xs-justify-content-end xs-mt-4 xs-pr-12">
                    <lce-certificate-orders-advanced-search (sidebarHide)="onSidebarHide($event)" [(searchText)]="searchText">
                    </lce-certificate-orders-advanced-search>
                </div>
            </div>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Quick Search === -->
        <!-- ------------------------------------------------------------------------------------------------ -->
        <div class="xs-sm-display-none xs-flex-column-center xs-width-full xs-mt-25 xs-mb-25">
            <p-selectButton
                    (onChange)="search()"
                    [(ngModel)]="periodFilter"
                    [options]="periodFilterItems"
                    styleClass="xs-select-button xs-select-button-small xs-min-width-550 xs-mb-15">
            </p-selectButton>
            <p-selectButton
                    (onChange)="search()"
                    [(ngModel)]="statusFilter"
                    [multiple]="true"
                    [options]="statusFilterItems"
                    styleClass="xs-select-button xs-min-width-550 xs-select-button-small xs-mb-15">
            </p-selectButton>
            <p-selectButton
                    (onChange)="search()"
                    [(ngModel)]="certificateTypeFilter"
                    [multiple]="true"
                    [options]="certificateTypeFilterItems"
                    styleClass="xs-select-button xs-select-button-small xs-min-width-350">
            </p-selectButton>
        </div>

        <!-- ------------------------------------------------------------------------------------------------ -->
        <!-- === Table === -->
        <!-- ------------------------------------------------------------------------------------------------ -->

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <lce-certificate-orders-table
                    (paginationEvent)="onPagination($event)"
                    (resetPreferencesEvent)="onResetPreferences()"
                    (resetSortEvent)="onResetSort()"
                    (sortEvent)="onSort($event)"
                    [caption]="tableCaption"
                    [data]="tableData"
                    [loadingState]="tableLoadingState"
                    [nResults]="tableNResults"
                    [readonly]="readonly"
                    [subCaption]="tableSubCaption">
            </lce-certificate-orders-table>
        </div>

    </div>
</div>
