import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LCECertificateOrderStatus} from '@lce/core';
import {XSHealthIndicatorOptions} from '@xs/core';
import {LCEContextMenuOptions} from '@lce/shared';

@Component({selector: 'lce-header', templateUrl: './lce-header.component.html'})
export class LCEHeaderComponent implements OnInit {

    readonly APP_SECONDARY_NAME: string = `<b>C</b>o<b>m</b>man<b>d</b>er`;

    municipalityCode?: string;

    healthIndicatorOptions: XSHealthIndicatorOptions = this.buildHealthIndicatorOptions();

    contextMenuOptions?: LCEContextMenuOptions = {
        can: {
            view: {
                stnButton: false
            }
        }
    };

    constructor(private router: Router) {
    }

    ngOnInit(): void {
    }

    public goToCertificatePendingOrders(): void {
        this.router.navigate(['/certificate-orders'],
            {
                state: {
                    status: LCECertificateOrderStatus.PENDING,
                    municipalityCode: this.municipalityCode
                }
            })
            .then();
    }

    private buildHealthIndicatorOptions(): XSHealthIndicatorOptions {
        return {
            indicatorMap: new Map([
                ['mongo',
                    {
                        id: 'mongo',
                        name: 'MongoDB',
                        description: 'NOSQL Database',
                        endpoint: 'actuator/health/mongo',
                        updatable: true
                    }
                ],
                ['wave',
                    {
                        id: 'wave',
                        name: 'Wave',
                        description: 'Payment - Wallet',
                        endpoint: 'actuator/health/wave',
                        updatable: true
                    }
                ],
                ['mtnMoneyCollection',
                    {
                        id: 'wave',
                        name: 'MTN Money Collection',
                        description: 'Payment - Wallet',
                        endpoint: 'actuator/health/mtnMoneyCollection',
                        updatable: true
                    }
                ],
                ['mtnMoneyDisbursement',
                    {
                        id: 'mtnMoneyDisbursement',
                        name: 'MTN Money Disbursement',
                        description: 'Payment - Mobile Money',
                        endpoint: 'actuator/health/mtnMoneyDisbursement',
                        updatable: true
                    }
                ],
                ['orangeMoney',
                    {
                        id: 'orangeMoney',
                        name: 'Orange Money',
                        description: 'Payment - Mobile Money',
                        endpoint: 'actuator/health/orangeMoney',
                        updatable: true
                    }
                ],
                ['stripe',
                    {
                        id: 'stripe',
                        name: 'Stripe',
                        description: 'Payment - Credit Card',
                        endpoint: 'actuator/health/stripe',
                        updatable: true
                    }
                ]
            ]),
            ignorableIndicatorIDs: ['mtnMoneyCollection', 'mtnMoneyDisbursement'],
            endpoint: 'actuator/health',
            updateEveryNSeconds: 60 * 10 // Every 10 minutes
        };
    }

}
