<div
        [ngClass]="{ 'xs-border-discrete xs-p-15 xs-border-radius-default': showBorder === true }"
        class="xs-flex-column xs-plr-20 xs-pt-20 xs-pb-10 xs-width-full {{ styleClass }}">
    <xs-ivar
            [avatarRounded]="false"
            [avatarType]="avatar.type"
            [avatar]="avatar.value"
            [ellipsis]="false"
            [line2]="data.title"
            [line3]="data.description"
            [showAvatar]="showAvatar"
            avatarImageStyleClass="lce-article-partial-overlay-image-container"
            avatarSize="extra-large"
            avatarStyleClass="xs-border-radius-default"
            iconStyleClass="xs-color-discrete"
            line1="{{data.code | uppercase}}"
            line1StyleClass="xs-color-secondary xs-font-size-small-imp xs-font-weight-500 xs-mb-2-imp"
            line2StyleClass="xs-font-weight-500 xs-font-size-percent-110-imp xs-text-primary-color-imp xs-mb-10-imp"
            line3StyleClass="xs-font-size-intermediate xs-mt-0-imp"
            styleClass="xs-align-items-start-imp">
    </xs-ivar>
    <div *ngIf="viewArticle" class="xs-flex-row xs-width-full xs-justify-content-end xs-mt-50">
        <xs-button (clickEvent)="onViewRecordBtnClick()" label="lce.shared.label.viewArticle" size="small" type="text"></xs-button>
    </div>
</div>
