import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEFacilityPartial, LCEFacilityService} from '@lce/core';
import {XSLabelValueItem} from '@xs/common';
import {XSInputDropdownOptions} from '@xs/core';

@Component({
    selector: 'lce-facility-select',
    template: `
        <xs-input-dropdown
                (valueChange)="changeEvent.emit($event)"
                [(value)]="defaultFacility"
                [items]="facilityItems"
                [options]="facilityOptions"
                panelStyleClass="xs-min-width-200-imp"
                placeholder="lce.cmd.features.label.selectMunicipality"
                styleClass="xs-min-width-250-imp {{styleClass}}">
        </xs-input-dropdown>`

})
export class LCEFacilitySelectComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() defaultFacility?: LCEFacilityPartial;

    @Output() changeEvent = new EventEmitter<LCEFacilityPartial>();

    facilityItems: XSLabelValueItem[] = [];
    facilityOptions: XSInputDropdownOptions = {
        labelField: 'label',
        valueField: 'value'
    };

    constructor(private facilityService: LCEFacilityService) {
    }

    ngOnInit(): void {
        this.search();
    }

    search(): void {
        this.facilityService.find({
            paginationPage: 0,
            paginationSize: 20
        }).subscribe({
            next: response => {
                response.forEach(facility => {
                    this.facilityItems.push({label: facility.fullName, value: facility});
                });
            }
        });
    }
}
