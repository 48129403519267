<div class="xs-flex-column-center {{styleClass}}">
    <xs-qrcode [data]="qrCode" styleClass="xs-width-150"></xs-qrcode>
    <div class="xs-flex-column-center xs-mt-14">
        <span *ngIf="showCode && (code | xsIsNotEmpty)"
              class="xs-display-block xs-color-secondary xs-font-weight-500 xs-font-size-intermediate xs-text-transform-uppercase">
          {{ code }}
    </span>
        <xs-time-ago
                *ngIf="showTimeAgo && (timeAgoValue | xsIsNotEmpty)"
                [givenDate]="timeAgoValue!"
                [prefix]="timeAgoPrefix"
                [shortMode]="showTimeAgoShortMode === true"
                styleClass="xs-display-block xs-color-secondary xs-font-size-extra-small xs-mt-1">
        </xs-time-ago>
    </div>
</div>