import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCE_BIRTH_COLOR, LCE_DEATH_COLOR, LCE_MARRIAGE_COLOR, LCECertificateOrderService} from '@lce/core';
import {XSDateRange, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton} from '@xs/core';
import {UIChart} from 'primeng/chart';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-dashboard-certificate-order-chart-doughnut',
    templateUrl: './lce-dashboard-certificate-order-chart-doughnut.component.html',
    host: {class: 'xs-height-full'}
})
export class LCEDashboardCertificateOrderChartDoughnutComponent implements OnInit {

    ICON = LCE_SHARED_ICON;

    readonly BIRTH_COLOR = LCE_BIRTH_COLOR;
    readonly MARRIAGE_COLOR = LCE_MARRIAGE_COLOR;
    readonly DEATH_COLOR = LCE_DEATH_COLOR;

    readonly TR_BASE: string = 'lce.shared.dashboard.chartDoughnut.';

    readonly LOADER_ID: string = 'chartDoughnutLoader';

    @Input() styleClass?: string;

    @Input() municipalityCode?: string;

    @Input() districtCode?: string;

    @Input() period?: XSPredefinedPeriod;

    @Input() showSeparators?: boolean;

    @ViewChild('chart') chart: UIChart;

    chartDoughnutData: any;
    chartDoughnutOptions: any;

    birthPercentage: number;
    marriagePercentage: number;
    deathPercentage: number;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.update()
    };

    private readonly TR_BIRTH = 'lce.core.certificateType.birth';
    private readonly TR_MARRIAGE = 'lce.core.certificateType.marriage';
    private readonly TR_DEATH = 'lce.core.certificateType.death';

    private subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private certificateOrderService: LCECertificateOrderService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.period)) this.period = XSPredefinedPeriod.THIS_YEAR;
        if (!XSUtils.isEmpty(this.municipalityCode) && !XSUtils.isEmpty(this.districtCode)) {
            throw new Error('municipalityCode and districtCode cannot be set both at the same time.');
        }
        this.buildChartDoughnut();
        this.update();
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.updateLabels()));
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    public hasData(): boolean {
        return !XSUtils.isEmpty(this.chartDoughnutData.datasets[0].data) &&
            (this.chartDoughnutData.datasets[0].data[0] !== 0 || this.chartDoughnutData.datasets[0].data[1] !== 0 || this.chartDoughnutData.datasets[0].data[2] !== 0);
    }

    public update(): void {
        this.loaderService.startLoader(this.LOADER_ID);

        const predefinedPeriod: XSPredefinedPeriod | undefined = this.period;
        const dateRange: XSDateRange | undefined = undefined;
        const municipalityCodes: string[] | undefined = XSUtils.isEmpty(this.municipalityCode) ? undefined : [this.municipalityCode!];

        this.error = undefined;

        this.subscription.add(
            this.certificateOrderService.chartDoughnut(predefinedPeriod, dateRange, municipalityCodes)
                .pipe(finalize(() => {
                    this.loaderService.stopLoader(this.LOADER_ID);
                }))
                .subscribe(
                    {
                        next: resultCount => {
                            this.chartDoughnutData.datasets[0].data = [resultCount.birth, resultCount.marriage, resultCount.death];

                            this.birthPercentage = 0;
                            this.marriagePercentage = 0;
                            this.deathPercentage = 0;
                            if (this.hasData()) {
                                this.birthPercentage = Math.round((resultCount.birth / resultCount.total) * 100);
                                this.marriagePercentage = Math.round((resultCount.marriage / resultCount.total) * 100);
                                this.deathPercentage = Math.round((resultCount.death / resultCount.total) * 100);
                            }

                            if (!XSUtils.isNull(this.chart) && this.hasData()) this.chart.refresh();
                        },
                        error: error => this.error = error
                    })
        );
    }

    private updateLabels(): void {
        const trLabels = this.translationService.translate([this.TR_BIRTH, this.TR_MARRIAGE, this.TR_DEATH]);
        this.chartDoughnutData.labels = [trLabels[this.TR_BIRTH], trLabels[this.TR_MARRIAGE], trLabels[this.TR_DEATH]];
    }

    private buildChartDoughnut(): void {
        this.chartDoughnutData = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [LCE_BIRTH_COLOR, LCE_MARRIAGE_COLOR, LCE_DEATH_COLOR],
                    hoverBackgroundColor: [LCE_BIRTH_COLOR, LCE_MARRIAGE_COLOR, LCE_DEATH_COLOR]
                }
            ]
        };
        this.chartDoughnutOptions = {
            plugins: {
                legend: {
                    display: false,
                    labels: {
                        boxHeight: 5
                    }
                }
            }
        };
        this.updateLabels();
    }
}
