<div
        class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #municipalityRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !municipalityRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="headerAvatar" [subtitle]="headerSubTitle" [title]="headerTitle"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-justify-content-space-between xs-mb-50">
                <lce-user-resource-audit [data]="municipality!"></lce-user-resource-audit>

                <div class="xs-flex-row xs-align-items-center">
                    <xs-error
                            *ngIf=" (resourceAuditFullMenuAction | xsIsNotEmpty) && resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <xs-button
                            (clickEvent)="onEdit()"
                            *ngIf="canEdit()"
                            [disabled]="(resourceAuditFullMenuAction | xsIsNotEmpty) && !resourceAuditFullMenuAction?.canDoAction()"
                            [icon]="ICON_EDIT"
                            class="xs-mr-40"
                            label="xs.core.label.edit"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <xs-pk-resource-audit-full-menu-action
                        #resourceAuditFullMenuAction
                        *ngIf="canShowAuditFullMenuActions()"
                        [dataManager]="dataManager!"
                        [resourceService]="municipalityService">
                    </xs-pk-resource-audit-full-menu-action>
                </div>

            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15 ">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Municipality Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-6">
                    <xs-data-field-text
                            [upperCase]="true"
                            [value]="municipality!.code"
                            label="xs.core.label.code"
                            valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-resource-state [value]="municipality"></xs-data-field-resource-state>
                </div>
                <div class="col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE_LABEL + 'district'"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <lce-district-partial [data]="municipality!.district"></lce-district-partial>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE_LABEL + 'name'" [value]="municipality!.name"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'fullName'" [value]="municipality!.fullName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'shortName'" [required]="false" [value]="municipality!.shortName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text
                            [label]="TR_BASE_LABEL + 'actualMayorName'"
                            [value]="municipality!.actualMayorName.firstName + ' ' + municipality!.actualMayorName.lastName">
                    </xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-calendar
                            [calendarOptions]="{ type: 'date', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }"
                            [label]="TR_BASE_LABEL + 'mayorSince'"
                            [leftIcon]="{ value: ICON_CALENDAR }"
                            [value]="municipality!.mayorSince">
                    </xs-data-field-calendar>
                </div>
                <div class="col-6">
                    <xs-data-field-ivar
                            [label]="TR_BASE_LABEL + 'populationSize'"
                            [value]="populationIvarData"
                            styleClass="xs-data-field-spacer">
                    </xs-data-field-ivar>
                </div>
                <div class="col-6">
                    <xs-data-field-text
                            [label]="TR_BASE_LABEL + 'squareKM'"
                            [required]="false"
                            [suffix]="{ value: 'km²' }"
                            [value]="municipality!.squareKM | xsNumber">
                    </xs-data-field-text>
                </div>
                <div class="col-12 xs-flex-row-center">
                    <div class="xs-width-full xs-max-width-400">
                        <xs-image [src]="municipality?.mainImage" alt="Municipality Image" noImageStyleClass="xs-max-width-150"></xs-image>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="municipality!.id"></xs-data-id>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON_MUNICIPALITY"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>