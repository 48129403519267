import {Injectable} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEBirthDeclarationCreateUpdateComponent} from './create-update/lce-birth-declaration-create-update.component';
import {LCEBirthDeclarationRecordComponent} from './record/lce-birth-declaration-record.component';
import {LCEBirthDeclaration} from '@lce/core';

export interface LCEBirthDeclarationDialogCreateUpdateArg {
    birthDeclaration?: LCEBirthDeclaration;
    birthDeclarationID?: string;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (birthDeclaration: LCEBirthDeclaration) => void;
    onSave?: (birthDeclaration: LCEBirthDeclaration) => void;
}

export interface LCEBirthDeclarationDialogRecordArg {
    birthDeclarationID?: string;
    birthDeclaration?: LCEBirthDeclaration;
    readonly?: boolean;
    onClose?: (birthDeclaration: LCEBirthDeclaration) => void;
    onEdit?: (birthDeclaration: LCEBirthDeclaration) => void;
}

@Injectable()
export class LCEBirthDeclarationDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEBirthDeclarationDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.birthDeclarationID) && XSUtils.isEmpty(arg.birthDeclaration)) {
            throw new Error('arg.birthDeclarationID and arg.birthDeclaration cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.birthDeclarationID) && !XSUtils.isEmpty(arg.birthDeclaration)) {
            throw new Error('arg.birthDeclarationID and arg.birthDeclaration cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                birthDeclarationID: arg.birthDeclarationID,
                birthDeclaration: arg.birthDeclaration,
                readonly: arg.readonly,
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1200'
        };

        const recordComponent: LCEBirthDeclarationRecordComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedBirthDeclarationRecord: LCEBirthDeclaration) => {
            if (arg?.onClose) arg!.onClose!(savedBirthDeclarationRecord);
        });
        recordComponent.editEvent.subscribe((birthDeclarationRecord: LCEBirthDeclaration) => {
            if (arg?.onEdit) arg.onEdit(birthDeclarationRecord);
            recordComponent.closeDialog();
            if (arg.readonly !== false) {
                this.openCreateUpdateDialog({
                    birthDeclaration: birthDeclarationRecord,
                    onClose: birthDeclaration => this.openRecordDialog({birthDeclaration: birthDeclaration, readonly: arg.readonly}),
                    onSave: birthDeclaration => this.openRecordDialog({birthDeclaration: birthDeclaration, readonly: arg.readonly})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEBirthDeclarationDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.birthDeclarationID) && !XSUtils.isEmpty(arg?.birthDeclaration)) {
            throw new Error('arg.birthDeclarationID and arg.birthDeclaration cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                birthDeclarationID: arg?.birthDeclarationID,
                birthDeclaration: arg?.birthDeclaration,
                loadingStyleClass: 'xs-min-height-600 xs-min-width-500',
                showHeader: false,
                showFooter: false
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1500'
        };

        const createUpdateComponent: LCEBirthDeclarationCreateUpdateComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((birthDeclaration: LCEBirthDeclaration) => {
            if (arg?.onClose) arg!.onClose!(birthDeclaration);
        });
        createUpdateComponent.saveEvent.subscribe((birthDeclaration: LCEBirthDeclaration) => {
            if (arg?.onSave) arg.onSave(birthDeclaration);
            createUpdateComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({birthDeclaration: birthDeclaration, readonly: false});
        });
    }
}
