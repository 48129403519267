import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_USER_TYPE, LCEUserType} from '@lce/core';
import {XS_STR_SPACE, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
	selector: 'lce-user-customer-anonymous',
	template: `
        <xs-ivar
                [styleClass]="styleClass"
                [avatar]="ICON.anonymous"
                [avatarRounded]="false"
                avatarIconStyleClass="xs-color-secondary-imp"
                avatarType="icon"
                [line1]="line1"
                [line2]="line2"
                [line1StyleClass]="line1StyleClass"
                [line2StyleClass]="line2StyleClass">
        </xs-ivar>
	`
})
export class LCEUserCustomerAnonymousComponent implements OnInit {

	readonly ICON = LCE_SHARED_ICON;

	@Input() styleClass?: string;
	@Input() labelStyleClass?: string;

	@Input() label?: string;
	@Input() showDefaultLabel?: boolean;

	line1: string;
	line2: string;
	line1StyleClass: string;
	line2StyleClass: string;

	ngOnInit() {
		if (this.showDefaultLabel === true && XSUtils.isEmpty(this.label)) {
			this.label = LCE_TR_BASE_USER_TYPE + LCEUserType.CUSTOMER;
		}

		if (XSUtils.isEmpty(this.label)) {
			this.line1 = 'lce.shared.label.anonymous';
			this.line1StyleClass = 'xs-color-secondary xs-font-size-intermediate-imp';
		} else {
			this.line1 = this.label!;
			this.line1StyleClass = 'xs-data-field-label-imp' + XS_STR_SPACE + this.labelStyleClass;
			this.line2 = 'lce.shared.label.anonymous';
			this.line2StyleClass = 'xs-color-secondary xs-font-size-intermediate-imp';
		}
	}
}
