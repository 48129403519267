import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEDistrict, LCEDistrictCanOptions, LCEDistrictService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLoaderService, XSNumberPipe, XSSize, XSTranslationService} from '@xs/common';
import {XSAvatar, XSButton, XSDataManagerComponent, XSDialogable, XSIvar, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-district-record',
    templateUrl: './lce-district-record.component.html',
    host: {class: 'xs-width-full'},
    providers: [XSNumberPipe]
})
export class LCEDistrictRecordComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON_DISTRICT: string = LCE_SHARED_ICON.district;
    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.district.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly IMAGE_DEFAULT_SRC = 'assets/images/district/ci-map.png';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() districtID?: string;
    @Input() district?: LCEDistrict;

    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;

    @Input() canOptions?: LCEDistrictCanOptions;

    @Output() editEvent = new EventEmitter<LCEDistrict>();
    @Output() closeEvent = new EventEmitter<LCEDistrict>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEDistrict>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieveDistrict()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        public districtService: LCEDistrictService,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private numberPipe: XSNumberPipe) {
        super();
    }

    get districtImage(): string {
        return XSUtils.isEmpty(this.district?.mapImage) ? this.IMAGE_DEFAULT_SRC : this.district?.mapImage!;
    }

    get headerAvatar(): XSAvatar {
        return {type: 'icon', size: XSSize.SMALL, data: this.ICON_DISTRICT};
    }

    get headerTitle(): string {
        return XSUtils.isEmpty(this.district) ? this.TR_BASE_LABEL + 'district' : this.district!.fullName;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.district) ? '...' : this.district!.code;
    }

    get populationIvarData(): XSIvar | undefined {
        if (XSUtils.isEmpty(this.district!.populationSize)) return undefined;
        const pIvarData: XSIvar = {line1: this.numberPipe.transform(this.district!.populationSize!)};
        if (!XSUtils.isNull(this.district!.populationUpdateYear)) {
            pIvarData.line2 = this.translationService.translateKey('lce.shared.label.yearOfUpdate', {year: this.district!.populationUpdateYear});
            pIvarData.line2StyleClass = 'xs-font-weight-normal-imp';
        }
        return pIvarData;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.districtID = this.dialogConfig.data.districtID;
            this.district = this.dialogConfig.data.district;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.district));
        }
        if (XSUtils.isEmpty(this.districtID) && XSUtils.isEmpty(this.district)) {
            throw new Error('districtID and district cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.districtID) && !XSUtils.isEmpty(this.district)) {
            throw new Error('districtID and district cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.districtID)) {
            this.retrieveDistrict();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public onEdit(): void {
        this.editEvent.emit(this.district);
    }

    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.district);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private retrieveDistrict(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.districtService
                .retrieve(this.districtID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (district: LCEDistrict) => {
                        this.district = district;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }
}
