<div class="xs-position-relative">
    <xs-table
            (onPagination)="paginationEvent.emit($event)"
            (onResetPreferences)="resetPreferencesEvent.emit()"
            (onResetSort)="resetSortEvent.emit()"
            (onRowDoubleClick)="viewOrder($event)"
            (onSort)="sortEvent.emit($event)"
            [caption]="caption"
            [columns]="columns"
            [data]="data"
            [emptyMessage]="emptyMessage"
            [loadingState]="loadingState"
            [nRecords]="nResults"
            [options]="options"
            [subCaption]="subCaption"
            captionStyleClass="xs-font-size-medium xs-font-weight-500"
            columnsStyleClass="xs-color-secondary xs-font-size-percent-90"
            subCaptionStyleClass="xs-font-size-percent-90"
            tableStyleClass="xs-table-border xs-table-border-discrete">
        <ng-template let-oneData xsTemplate="orderNumber">
            <xs-data-field-identifier
                    (clickEvent)="viewOrder(oneData)"
                    [belowText]="oneData.batchProcessCode"
                    [copyable]="false"
                    [ellipsis]="false"
                    [required]="true"
                    [testColored]="true"
                    [value]="oneData.orderNumber"
                    belowTextStyleClass="xs-font-size-8-imp xs-color-secondary xs-text-transform-uppercase"
                    type="code"
                    valueStyleClass="xs-font-size-intermediate">
            </xs-data-field-identifier>
        </ng-template>

        <ng-template let-oneData xsTemplate="certificate">
            <lce-certificate-partial [data]="oneData.certificate"></lce-certificate-partial>
        </ng-template>

        <ng-template let-oneData xsTemplate="numberOfCopies">
            <lce-certificate-order-number-of-copies [showBorder]="true" [value]="oneData.numberOfCopies"></lce-certificate-order-number-of-copies>
        </ng-template>

        <ng-template let-oneData xsTemplate="customer">
            <lce-user-partial *ngIf="oneData.customer | xsIsNotEmpty" [data]="oneData.customer!" [showStatus]="false"></lce-user-partial>
            <lce-user-customer-anonymous *ngIf="oneData.customer | xsIsEmpty"></lce-user-customer-anonymous>
        </ng-template>

        <ng-template let-oneData xsTemplate="orderStatus">
            <lce-certificate-order-status [clickable]="false" [showBackground]="false" [status]="oneData.status"></lce-certificate-order-status>
        </ng-template>

        <ng-template let-oneData xsTemplate="orderDate">
            <xs-data-field-calendar
                    [calendarOptions]="calendarOptions"
                    [timeAgoLive]="true"
                    [timeAgoShortMode]="true"
                    [timeAgo]="true"
                    [value]="oneData.createdOn"
                    valueStyleClass="xs-font-size-intermediate xs-font-weight-500 xs-white-space-nowrap">
            </xs-data-field-calendar>
        </ng-template>

        <ng-template let-oneData xsTemplate="delivery">
            <lce-delivery-destination-type [type]="oneData.deliveryDestinationType"></lce-delivery-destination-type>
        </ng-template>
    </xs-table>
</div>
