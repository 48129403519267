import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCECertificateOrderPartial} from '@lce/core';
import {XS_STR_EMPTY, XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';
import {LCECertificateOrderDialogService} from '../lce-certificate-order-dialog.service';

@Component({
    selector: 'lce-certificate-order-partial',
    templateUrl: './lce-certificate-order-partial.component.html',
    host: {class: 'xs-width-full'}
})
export class LCECertificateOrderPartialComponent implements OnInit {

    readonly ICON_CERTIFICATE_ORDER = LCE_SHARED_ICON.certificateOrder;

    @Input() styleClass?: string;
    @Input() orderNumberStyleClass?: string;
    @Input() iconStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCECertificateOrderPartial;

    @Input() showIcon?: boolean;

    @Input() detailed?: boolean;
    @Input() detailedStatusIndicator?: boolean;

    @Input() openRecordOnOrderNumberClick?: boolean;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<LCECertificateOrderPartial>();

    constructor(private certificateOrderDialogService: LCECertificateOrderDialogService) {
    }

    get colorStatusStyleClass(): string {
        if (this.detailedStatusIndicator !== true) return XS_STR_EMPTY;
        return LCESharedUtils.getCertificateOrderColorStatusStyleClass(this.data.status);
    }

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.detailed)) this.detailed = false;
        if (this.detailed && XSUtils.isNull(this.detailedStatusIndicator)) this.detailedStatusIndicator = true;
        if (XSUtils.isNull(this.openRecordOnOrderNumberClick)) this.openRecordOnOrderNumberClick = true;
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }

    public onOrderNumberClick(event: Event): void {
        if (this.disabled || !this.clickable) {
            event.stopImmediatePropagation();
            return;
        }
        if (this.openRecordOnOrderNumberClick) {
            this.certificateOrderDialogService.openRecordDialog({
                orderID: this.data.id,
                orderNumber: this.data.orderNumber,
                type: this.data.certificate.type,
                readonly: true
            });
        }
        this.clickEvent.emit(this.data);
    }
}
