<div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="retrieveErrorRetryButton"
                [error]="retrieveError"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}errorMessageRetrieve"
                mode="block"
                styleClass="xs-max-width-500"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div [ngClass]="{ 'xs-disabled': disabled, 'xs-card-border': showBorder }" class="xs-card xs-width-full {{ styleClass }}">
        <div class="xs-card-content xs-mb-0-imp">
            <div class="grid xs-fields xs-fields-spacer-20">
                <div class="col-12 xs-flex xs-align-items-center">
                    <div class="grid xs-width-full">
                        <div class="col-4">
                            <xs-input-field-text *ngIf="isCreateMode()" [options]="birthNumberField"></xs-input-field-text>
                            <xs-data-field-text *ngIf="!isCreateMode()"
                                                [label]="TR_BASE + 'birthNumber'"
                                                [value]="delivery!.birthNumber"
                                                valueStyleClass="xs-font-size-medium xs-color-secondary">
                            </xs-data-field-text>
                        </div>
                        <div class="col-4">
                            <xs-input-field-calendar [options]="bornOnField" styleClass="xs-mb-8-imp"></xs-input-field-calendar>
                            <xs-input-field-calendar [options]="receivedOnField"></xs-input-field-calendar>
                        </div>
                        <div class="col-4">
                            <xs-input-field-auto-complete [options]="declaredByField">
                                <ng-template let-facilityWorker xsTemplate="item">
                                    <div class="xs-flex-column">
                                        <lce-facility-worker-partial [data]="facilityWorker" styleClass="xs-ellipsis"></lce-facility-worker-partial>
                                    </div>
                                </ng-template>
                            </xs-input-field-auto-complete>
                        </div>
                    </div>
                </div>

                <div class="col-4">
                    <xs-input-field-dropdown [options]="newBornGenderField"></xs-input-field-dropdown>
                </div>
                <div class="col-4">
                    <xs-input-field-auto-complete [options]="newBornFirstNameField">
                        <ng-template let-firstName xsTemplate="item">
                            <div class="xs-flex-column">
                                <span>{{ firstName }}</span>
                            </div>
                        </ng-template>
                    </xs-input-field-auto-complete>
                </div>
                <div class="col-4">
                    <xs-input-field-auto-complete [options]="newBornLastNameField">
                        <ng-template let-firstName xsTemplate="item">
                            <div class="xs-flex-column">
                                <span>{{ firstName }}</span>
                            </div>
                        </ng-template>
                    </xs-input-field-auto-complete>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }" class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-error
                *ngIf="hasCreateUpdateError()"
                [ellipsis]="true"
                [error]="createUpdateError"
                [showErrorDetailButton]="true"
                errorDetailButtonClass="xs-mt-2-imp"
                message="{{ TR_BASE }}errorMessageCreateUpdate"
                mode="inline">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form" size="intermediate" type="text"></xs-button>
            <xs-button
                    (clickEvent)="reset()"
                    *ngIf="isCreateMode()"
                    [confirmation]="resetConfirmation"
                    [disabled]="createUpdateLoading"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="createUpdateLoading || centralLoader.isRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.close"
                    severity="secondary"
                    styleClass="xs-mlr-15-imp"
                    type="button">
            </xs-button>
            <xs-button (clickEvent)="createUpdate()" [disabled]="centralLoader.isRunning()" [loading]="createUpdateLoading" label="xs.core.label.save" type="button"></xs-button>
        </div>
    </div>
</ng-template>

<ng-template #dHeader>
    <xs-ivar
            [ellipsis]="true"
            [line1]="TR_BASE + 'title'"
            line2="{{ 'lce.shared.birthDeclaration.label.birth' | translate }} {{ this.delivery?.birthNumber }}"
            styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>
