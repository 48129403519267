<xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-600 {{ styleClass }}">
    <ng-template xsTemplate="sort">
        <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
    </ng-template>

    <!-- === Row Result Template ===-->
    <ng-template let-oneResult xsTemplate="row">
        <lce-facility-worker-partial [data]="oneResult"></lce-facility-worker-partial>
    </ng-template>

    <!-- === Record Toolbar Template === -->
    <ng-template xsTemplate="recordToolbar">
        <div class="xs-flex-row xs-width-full xs-align-items-center xs-justify-content-end">
            <xs-error *ngIf="actionError && !isLoaderRunning()" [error]="actionError" [message]="errorMessage" [showErrorDetailButton]="true" mode="inline"></xs-error>
            <div class="xs-flex-row">
                <xs-button
                        (clickEvent)="onAddFacilityWorker()"
                        [disabled]="!canEdit()"
                        [icon]="ICON.edit"
                        class="xs-mr-40"
                        label="xs.core.label.edit"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="onMenuClick($event)"
                        [disabled]="!canActivate() || !canDisable() || !canDeleteAndRestore() || !canPurge()"
                        [icon]="ICON.ellipsisVertical"
                        [loading]="isLoaderRunning()"
                        loaderPosition="bottom"
                        type="text">
                </xs-button>
                <p-tieredMenu #actionMenu [appendTo]="'body'" [model]="actionMenuItems" [popup]="true" styleClass="xs-min-width-225"></p-tieredMenu>
            </div>
        </div>
    </ng-template>
    <!-- === Record Template ===-->
    <ng-template let-recordData xsTemplate="record">
        <lce-facility-worker-record [facilityWorker]="recordData" loadingStyleClass="xs-min-height-500 xs-min-width-600" styleClass="xs-width-full xs-max-width-full xs-p-25">
        </lce-facility-worker-record>
    </ng-template>
</xs-data-manager>
