import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECertificate,
    LCECertificateBirth,
    LCECertificateDeath,
    LCECertificateMarriage,
    LCECertificatePartial,
    LCECertificateSearch,
    LCECertificateType,
    LCEUserPartial
} from '@lce/core';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../../lce-mock.constant';
import {XSUtils} from '@xs/base';
import {LCEMockUtils} from '../../lce-mock-utils';
import {LCEMockMunicipalityHandler} from '../lce-mock-municipality-handler';
import {XSMockPKResourceHandler} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificates';

@Injectable()
export class LCEMockCertificateHandler extends XSMockPKResourceHandler<LCECertificate, LCECertificatePartial, LCECertificateSearch> {

    public static CERTIFICATE_STORAGE: Map<string, LCECertificate> = new Map<string, LCECertificate>();

    constructor(private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.certificates.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCECertificate> {
        return LCEMockCertificateHandler.CERTIFICATE_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return LCEMockUtils.randomUserCustomer();
    }

    public toPartial(certificate: LCECertificate): LCECertificatePartial {
        return {
            id: certificate.id,
            type: certificate.type,
            referenceNumber: certificate.referenceNumber,
            issueDate: certificate.issueDate
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [...this.mockDataArray];
    }

    public buildRandomCertificatePartial(cType?: LCECertificateType): LCECertificatePartial {
        return this.toPartial(this.buildRandomCertificate(cType, false));
    }

    public buildRandomCertificate(cType?: LCECertificateType, more: boolean = false): LCECertificate {
        let certificateType: LCECertificateType;
        if (XSUtils.isEmpty(cType)) certificateType = LCEMockUtils.randomCertificateType();
        else certificateType = cType!;

        let certificate: LCECertificate = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            type: certificateType,
            municipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
            referenceNumber: XSUtils.randomDigits(8),
            issueDate: XSUtils.randomDate(),
            nOrdered: XSUtils.randomInteger(1, 10),
            nOrderedCopies: XSUtils.randomInteger(1, 15)
        };

        if (more && certificateType === LCECertificateType.BIRTH) {
            certificate = {
                ...certificate,
                holderName: {firstName: 'Bryan', lastName: 'Doe'}
            } as LCECertificateBirth;
        } else if (more && certificateType === LCECertificateType.MARRIAGE) {
            certificate = {
                ...certificate,
                groomName: {firstName: 'Marcus', lastName: 'Johnson'},
                brideName: {firstName: 'Eveline', lastName: 'Emmerson'}
            } as LCECertificateMarriage;
        } else if (more && certificateType === LCECertificateType.DEATH) {
            certificate = {
                ...certificate,
                deceasedName: {firstName: 'Edson', lastName: 'Arantes'}
            } as LCECertificateDeath;
        }

        return certificate;
    }

    private buildStorage(n: number = 20): void {
        for (let $i = 0; $i < n; $i++) {
            const certificate = this.buildRandomCertificate();
            LCEMockCertificateHandler.CERTIFICATE_STORAGE.set(certificate.id, certificate);
            this.addResourceBaseMockData(certificate.id);
        }
    }
}
