<div class="xs-card xs-width-full xs-max-width-650 xs-background-color-default xs-border-radius-default">
    <div class="xs-card-header xs-card-header-separator">
        <div class="xs-flex-row xs-align-items-center">
            <div class="xs-display-block xs-border-right-light xs-pr-15 xs-mr-15">
                <span [style.font-family]="'inherit'" class="xs-color-secondary xs-font-size-extra-large">LCE</span>
            </div>
            <div class="xs-overflow-hidden">
                <xs-ivar
                        [ellipsis]="true"
                        [line1]="title"
                        [line2]="subTitle"
                        line1StyleClass="xs-color-secondary xs-font-weight-500-imp xs-font-size-default-imp">
                </xs-ivar>
            </div>
        </div>
    </div>

    <div class="xs-card-content xs-align-items-center">
        <router-outlet></router-outlet>
    </div>
</div>
