import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECertificateOrder,
    LCECertificateOrderCancelReason,
    LCECertificateOrderErrorReason,
    LCECertificateOrderLight,
    LCECertificateOrderStatus,
    LCECertificateOrderUpdateStatusResponse,
    LCECoreContextService
} from '@lce/core';
import {XSHttpMethod, XSReason, XSUtils} from '@xs/base';
import {XSMockData, XSMockHttpHandler} from '@xs/mock';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../../lce-mock.constant';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificateOrderStatuses';

@Injectable()
export class LCEMockCertificateOrderStatusHandler extends XSMockHttpHandler {
    
    constructor(private contextService: LCECoreContextService) {
        super();
    }

    public buildMockDataArray(): void {
        this.mockDataArray = [];
    }

    public buildSetOrderStatusMockData(order: LCECertificateOrder, status: LCECertificateOrderStatus): XSMockData<LCECertificateOrderUpdateStatusResponse> {
        return {
            id: `${DATASET_BASE_ID}.single.processing.${order.id}`,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + order.id + '/status/' + status.toString(),
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: (arg) => {
                // let cancelReasonData: XSReasonAction<LCECertificateOrderCancelReason, LCEUserPartial> = undefined!;
                // let errorReasonData: XSReasonAction<LCECertificateOrderErrorReason, LCEUserPartial> = undefined!;

                const now = new Date().toISOString();
                const by = this.contextService.getUser();

                if (status === LCECertificateOrderStatus.CANCELLED) {
                    const cancelReason = arg.body as XSReason<LCECertificateOrderCancelReason>;
                    // cancelReasonData = {reason: cancelReason, on: now, by: by};
                }
                if (status === LCECertificateOrderStatus.ERROR) {
                    const errorReason = arg.body as XSReason<LCECertificateOrderErrorReason>;
                    // errorReasonData = {reason: errorReason, on: now, by: by};
                }
                return {
                    id: order.id,
                    orderNumber: order.orderNumber,
                    batchProcessCode: order.batchProcessCode,
                    status: status,
                    statusUpdatedOn: now,
                    statusUpdatedBy: by,
                    updatedOn: now,
                    updatedBy: by
                };
            }
        };
    }

    public buildSetOrderStatusesMockData(orders: LCECertificateOrderLight[], status: LCECertificateOrderStatus): XSMockData<LCECertificateOrderUpdateStatusResponse[]> {
        return {
            id: `${DATASET_BASE_ID}.multiple.statues.${XSUtils.uuid()}`,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/statuses/' + status.toString(),
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: () => {
                const now = new Date().toISOString();
                const by = this.contextService.getUser();

                const response: LCECertificateOrderUpdateStatusResponse[] = [];

                orders.forEach((order) =>
                    response.push({
                        id: order.id,
                        orderNumber: order.orderNumber,
                        status: status,
                        statusUpdatedOn: now,
                        statusUpdatedBy: by,
                        updatedOn: now,
                        updatedBy: by
                    })
                );
                return response;
            }
        };
    }
}
