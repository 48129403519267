import {LCECertificateOrderDeliveryAssignmentStatus} from '@lce/core';

export const LCE_CERTIFICATE_ORDERS_STORAGE_KEY = 'certificateOrders';

export const LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE = 'lce.shared.assignment.status.';

export const LCE_DELIVERY_ASSIGMENT_STATUS_MAP: Map<LCECertificateOrderDeliveryAssignmentStatus, { color: string; backgroundColor: string; }> = new Map([
    [LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED, {color: 'xs-color-orange', backgroundColor: 'xs-background-orange'}],
    [LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED, {color: 'xs-color-orange', backgroundColor: 'xs-background-orange'}],
    [LCECertificateOrderDeliveryAssignmentStatus.COMPLETED, {color: 'xs-color-success', backgroundColor: 'xs-background-green'}],
    [LCECertificateOrderDeliveryAssignmentStatus.REFUSED, {color: 'xs-color-danger', backgroundColor: 'xs-background-danger'}],
    [LCECertificateOrderDeliveryAssignmentStatus.BLOCKED, {color: 'xs-color-black', backgroundColor: 'xs-background-black'}],
    [LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS, {color: 'xs-color-gray', backgroundColor: 'xs-background-gray'}],
    [LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED, {color: 'xs-color-primary', backgroundColor: 'xs-background-primary'}]
]);