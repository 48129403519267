<div [ngClass]="{ 'lce-vertical-sidebar-expanded': expandMenu, 'lce-vertical-sidebar-collapsed': !expandMenu }" class="lce-vertical-sidebar">

    <div class="lce-vertical-sidebar-top">
        <lce-brand styleClass="xs-plr-5" text="Commander" textStyleClass="xs-font-size-12"></lce-brand>
    </div>

    <div (click)="onOverlayButtonClick()" *ngIf="expandCollapse"
         [ngClass]="{ 'lce-vertical-sidebar-overlay-button-expand': expandMenu, 'lce-vertical-sidebar-overlay-button-collapsed': !expandMenu }"
         class="lce-vertical-sidebar-overlay-button">
        <i class="{{ expandMenu ? ICON.back : ICON.next }}"></i>
    </div>

    <div class="lce-vertical-sidebar-menu">
        <ul>
            <li *ngFor="let menuItem of menuItems" [ngClass]="{ 'lce-vertical-sidebar-menu-item-bottom-text-enabled': bottomTextMenu && !expandMenu}" [routerLink]="menuItem.url"
                class="lce-vertical-sidebar-menu-item"
                routerLinkActive="lce-vertical-sidebar-menu-item-active">
                <span class="lce-vertical-sidebar-menu-item-inner">
                    <xs-avatar [data]="menuItem.icon!" [iconStyleClass]="menuItem.iconStyleClass" size="extra-small" type="icon"></xs-avatar>
                    <span *ngIf="menuItem.trLabel | xsIsNotEmpty" [ngClass]="{ 'xs-display-none': !expandMenu }">{{ menuItem.trLabel! | translate }}</span>
                </span>
                <span *ngIf="bottomTextMenu && !expandMenu && (menuItem.trLabel | xsIsNotEmpty)" class="lce-vertical-sidebar-menu-item-bottom-text">
                    {{ menuItem.trShortLabel! | translate }}
                </span>
                <xs-badge
                        *ngIf="menuItem.badgeValue | xsIsNotEmpty"
                        [mode]="'primary'"
                        [small]="true"
                        [value]="menuItem.badgeValue!"
                        styleClass="xs-position-absolute xs-top-2 xs-right-5 xs-min-width-30 {{ menuItem.badgeStyleClass }}">
                </xs-badge>
            </li>
        </ul>
    </div>
</div>
