import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LOG, XSGender, XSUtils} from '@xs/base';
import {XS_TR_BASE_GENDER, XSFormUtils, XSLabelValue} from '@xs/common';
import {
    XSButton,
    XSConfirmation,
    XSDialogable,
    XSInputFieldAutoCompleteOptions,
    XSInputFieldCalendarOptions,
    XSInputFieldDropdownOptions,
    XSInputFieldTextOptions
} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {
    LCE_MOCK_FACILITY_WORKER_MIDWIFE,
    LCEBirthDeclarationDelivery,
    LCEBirthDeclarationService,
    LCEFacilityWorkerPartial,
    LCEFacilityWorkerService
} from '@lce/core';

@Component({
    selector: 'lce-birth-declaration-delivery-create-update',
    templateUrl: './birth-declaration-delivery-create-update.component.html'
})
export class LCEBirthDeclarationDeliveryFormComponent extends XSDialogable implements OnInit, OnChanges {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.delivery.';

    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() disabled?: boolean;

    @Input() showBorder?: boolean = true;

    @Input() delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>;

    @Input() birthDeclarationID?: string;

    @Input() checkValidity?: boolean;

    @Input() formGroup: FormGroup = new FormGroup({});

    @Output() formGroupChange: EventEmitter<FormGroup> = new EventEmitter();
    @Output() saveEvent = new EventEmitter<LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    birthNumberField: XSInputFieldTextOptions;
    bornOnField: XSInputFieldCalendarOptions;
    receivedOnField: XSInputFieldCalendarOptions;
    newBornFirstNameField: XSInputFieldAutoCompleteOptions;
    newBornLastNameField: XSInputFieldAutoCompleteOptions;
    newBornGenderField: XSInputFieldDropdownOptions;
    declaredByField: XSInputFieldAutoCompleteOptions;
    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
    };
    createUpdateError: any;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    private subscription: Subscription = new Subscription();

    private readonly GENDERS: XSLabelValue[] = [
        {label: XS_TR_BASE_GENDER + XSGender.FEMALE, value: XSGender.FEMALE},
        {label: XS_TR_BASE_GENDER + XSGender.MALE, value: XSGender.MALE},
        {label: XS_TR_BASE_GENDER + XSGender.NOT_APPLICABLE, value: XSGender.NOT_APPLICABLE},
        {label: XS_TR_BASE_GENDER + XSGender.NOT_KNOWN, value: XSGender.NOT_KNOWN},
    ];

    constructor(private facilityWorkerService: LCEFacilityWorkerService, private birthDeclarationService: LCEBirthDeclarationService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.delivery = this.dialogConfig.data.delivery;
            this.showBorder = this.dialogConfig.data.showBorder;
        }
        this.buildField();
        this.buildConfirmations();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.checkValidity) XSFormUtils.validateFormGroup(this.formGroup);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public validate(): boolean {
        XSFormUtils.validateFormGroup(this.formGroup);
        return this.formGroup.valid;
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.delivery);
    }

    public buildCreate(formData: any): LCEBirthDeclarationDelivery<string> {
        let birthDeclarationDeliveryCreate: LCEBirthDeclarationDelivery<string> = {
            birthNumber: XSUtils.trim(formData.birthNumber),
            declaredBy: formData.declaredBy,
            bornOn: formData.bornOn,
            receivedOn: formData.receivedOn,
            newBornName: {
                firstName: XSUtils.trim(formData.newBornFirstName),
                lastName: XSUtils.trim(formData.newBornLastName)
            },
            newBornGender: formData.newBornGender,
        };
        XSUtils.removeNullAndUndefinedEntries(birthDeclarationDeliveryCreate);
        return birthDeclarationDeliveryCreate;
    }

    public formValueChange(): boolean {
        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = new Map();
        this.buildUpdate(fieldValueMap, formData);
        return fieldValueMap.size !== 0;
    }

    public update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating Delivery [delivery: ' + this.delivery + '] ...');
        this.createUpdateLoading = true;

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = new Map();
        this.buildUpdate(fieldValueMap, formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Delivery not updated. No change detected !  ');
            this.saveEvent.emit(this.delivery);
            return;
        }

        this.subscription.add(
            this.birthDeclarationService
                .update(this.birthDeclarationID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (birthDeclaration) => {
                        LOG().debug('Birth Declaration successfully saved :-) [code: ' + birthDeclaration.code + ', id: ' + birthDeclaration.id + ']');
                        this.saveEvent.emit(birthDeclaration.delivery);
                    },
                    error: (error) => (this.createUpdateError = error),
                })
        );
    }

    public buildUpdate(fieldValueMap: Map<string, any>, formData: any): void {
        if (!XSUtils.equal(formData.newBornFirstName, this.delivery!.newBornName?.firstName)) {
            fieldValueMap.set('delivery.newBornName.firstName', XSUtils.trim(formData.newBornFirstName));
        }
        if (!XSUtils.equal(formData.newBornLastName, this.delivery!.newBornName?.lastName)) {
            fieldValueMap.set('delivery.newBornName.lastName', XSUtils.trim(formData.newBornLastName));
        }
        if (!XSUtils.equal(formData.newBornGender, this.delivery!.newBornGender)) fieldValueMap.set('delivery.newBornGender', formData.newBornGender);
        if (!XSUtils.equal(formData.birthNumber, this.delivery!.birthNumber)) fieldValueMap.set('delivery.birthNumber', XSUtils.trim(formData.birthNumber));
        if (!XSUtils.equal(formData.declaredBy, this.delivery!.declaredBy)) fieldValueMap.set('delivery.declaredBy', formData.declaredBy);
        if (!XSUtils.equal(formData.bornOn, this.delivery!.bornOn)) fieldValueMap.set('delivery.bornOn', formData.bornOn);
        if (!XSUtils.equal(formData.receivedOn, this.delivery!.receivedOn)) fieldValueMap.set('delivery.receivedOn', formData.receivedOn);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.delivery);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public reset() {
        this.formGroup.reset();
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty && this.formValueChange();
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.birthNumberField.control?.value) ||
            !XSUtils.isEmpty(this.bornOnField.control?.value) ||
            !XSUtils.isEmpty(this.declaredByField.control?.value) ||
            !XSUtils.isEmpty(this.receivedOnField.control?.value) ||
            !XSUtils.isEmpty(this.newBornFirstNameField.control?.value) ||
            !XSUtils.isEmpty(this.newBornGenderField.control?.value) ||
            !XSUtils.isEmpty(this.newBornLastNameField.control?.value)
        ) {
            return false;
        }
        return true;
    }

    public fillForm(): void {
        this.formGroup.get(this.bornOnField.fieldName)?.setValue('2022-03-25');
        this.formGroup.get(this.receivedOnField.fieldName)?.setValue('2022-03-25');
        this.formGroup.get(this.birthNumberField.fieldName)?.setValue('123456789');
        this.formGroup.get(this.declaredByField.fieldName)?.setValue(LCE_MOCK_FACILITY_WORKER_MIDWIFE);
        this.formGroup.get(this.newBornFirstNameField.fieldName)?.setValue('New Born First Name');
        this.formGroup.get(this.newBornLastNameField.fieldName)?.setValue('New Born Last Name');
        this.formGroup.get(this.newBornGenderField.fieldName)?.setValue(XSGender.MALE);
    }

    private buildField(): void {
        this.birthNumberField = {
            fieldName: 'birthNumber',
            control: new FormControl(this.delivery?.birthNumber, Validators.required),
            label: this.TR_BASE + 'birthNumber',
            groupAddon: true,
            leftAddonValue: '#',
        };

        let bornOn = this.delivery?.bornOn ? new Date(this.delivery?.bornOn) : '';
        this.bornOnField = {
            fieldName: 'bornOn',
            label: this.TR_BASE + 'bornOn',
            calendarOptions: {dateFormatFR: 'yy-mm-dd', showIcon: true},
            control: new FormControl(bornOn),
        };

        let receivedOn = this.delivery?.receivedOn ? new Date(this.delivery?.receivedOn) : '';
        this.receivedOnField = {
            fieldName: 'receivedOn',
            label: this.TR_BASE + 'receivedOn',
            calendarOptions: {dateFormatFR: 'yy-mm-dd', showIcon: true},
            control: new FormControl(receivedOn),
        };

        this.declaredByField = {
            fieldName: 'declaredBy',
            label: this.TR_BASE + 'declaredBy',
            control: new FormControl(this.delivery?.declaredBy, Validators.required),
            placeholder: 'Search ...',
            leftIcon: this.ICON.user,
            leftIconStyleClass: 'xs-color-secondary',
            autoCompleteOptions: {
                labelField: `name.firstName`,
                forceSelection: false,
                search: (query: string) => this.searchFacilityWorker(query),
            },
        };

        this.newBornFirstNameField = {
            fieldName: 'newBornFirstName',
            label: this.TR_BASE + 'newBornFirstName',
            control: new FormControl(this.delivery?.newBornName?.firstName, Validators.required),
            placeholder: 'Search ...',
            leftIconStyleClass: 'xs-color-secondary',
            autoCompleteOptions: {
                forceSelection: false,
                search: (query: string) => this.birthDeclarationService.autocompleteFirstName(query),
            },
        };

        this.newBornLastNameField = {
            fieldName: 'newBornLastName',
            label: this.TR_BASE + 'newBornLastName',
            control: new FormControl(this.delivery?.newBornName?.lastName, Validators.required),
            placeholder: 'Search ...',
            leftIconStyleClass: 'xs-color-secondary',
            autoCompleteOptions: {
                forceSelection: false,
                search: (query: string) => this.birthDeclarationService.autocompleteLastName(query),
            },
        };

        this.newBornGenderField = {
            fieldName: 'newBornGender',
            label: this.TR_BASE + 'newBornGender',
            control: new FormControl(this.delivery?.newBornGender),
            items: this.GENDERS,
            placeholder: this.TR_BASE + 'newBornGenderPlaceholder',
            dropdownOptions: {showClear: true},
        };

        this.formGroup.addControl(this.declaredByField.fieldName, this.declaredByField.control!);
        this.formGroup.addControl(this.birthNumberField.fieldName, this.birthNumberField.control!);
        this.formGroup.addControl(this.bornOnField.fieldName, this.bornOnField.control!);
        this.formGroup.addControl(this.receivedOnField.fieldName, this.receivedOnField.control!);
        this.formGroup.addControl(this.newBornFirstNameField.fieldName, this.newBornFirstNameField.control!);
        this.formGroup.addControl(this.newBornLastNameField.fieldName, this.newBornLastNameField.control!);
        this.formGroup.addControl(this.newBornGenderField.fieldName, this.newBornGenderField.control!);
    }

    private searchFacilityWorker(query?: string): Observable<LCEFacilityWorkerPartial[]> {
        return this.facilityWorkerService.autocomplete(XSUtils.trim(query));
    }

    private create(): void {
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'lce.shared.confirmation.resetConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            },
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'lce.shared.confirmation.closeConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            },
        };
    }
}
