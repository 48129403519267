import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { XSTranslationService, XS_TR_BASE_GENDER, XS_TR_BASE_LANGUAGE } from '@xs/common';
import { LCE_SHARED_ICON } from '../../../api/constants/lce-shared-icon.constant';
import { XSAddressFieldOptions, XSContactPersonFieldOptions, XSMenuItem } from '@xs/core';
import { LCEUser } from '@lce/core';
import { TieredMenu } from 'primeng/tieredmenu';
import { Subscription } from 'rxjs';
import { LOG, XSAddressType, XSAssert, XSContactPerson } from '@xs/base';
import { LCEUserAccountOptions } from '../lce-user-account-options';

@Component({ selector: 'lce-user-account-profile', templateUrl: './lce-user-account-profile.component.html' })
export class LCEUserAccountProfileComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE_GENDER = XS_TR_BASE_GENDER;
    readonly TR_BASE_LANGUAGE = XS_TR_BASE_LANGUAGE;

    @Input() styleClass?: string;

    @Input() data: LCEUser;

    @Input() readonly?: boolean;

    @Input() options: LCEUserAccountOptions;

    @Output() editEvent = new EventEmitter<LCEUser>();

    @Output() editOrDeleteEmergencyContactEvent = new EventEmitter<XSContactPerson>();

    @ViewChild('actionMenu') actionMenu: TieredMenu;
    actionMenuItems: XSMenuItem[];

    addressFieldOptions: XSAddressFieldOptions;
    emergencyContactPersonFieldOptions: XSContactPersonFieldOptions;

    private subscription: Subscription = new Subscription();

    constructor(private translationService: XSTranslationService) {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');

        this.buildAddressFieldOptions();
        this.buildEmergencyContactPersonFieldOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEdit(): void {
        this.editEvent.emit(this.data);
    }

    public canEdit(): boolean {
        return this.readonly !== false;
    }

    public onMenuClick(event: any): void {
        this.buildActionMenuItems();
        this.actionMenu.toggle(event);
    }

    private buildActionMenuItems(): void {
        this.actionMenuItems = [];
        this.translationService.translateItems(this.actionMenuItems);
    }

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            formOptions: {
                type: this.data?.address?.type ? this.data.address.type : undefined!,
                typeSelection: true,
                allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED],
                showResetButton: false,
                title: false,
            },
        };
    }

    private buildEmergencyContactPersonFieldOptions(): void {
        this.emergencyContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.data.address!.countryISO,
                    showCountryFlag: true,
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving User (' + this.data.id + ') Emergency Contact Person ...');
                    return this.options.fnUpdateEmergencyContact(contactPerson);
                },
            },
            onDelete: (contactPerson: XSContactPerson) => {
                this.editOrDeleteEmergencyContactEvent.emit(contactPerson);
                LOG().debug('Removing User (' + this.data.id + ') Emergency Contact Person ...', contactPerson);
                return this.options.fnUpdateEmergencyContact(undefined);
            },
        };
    }

}