import {LCEEnvironmentCommon, LCERequestorType} from '@lce/core';
import {XSLanguage} from '@xs/base';

export const ADDITIONAL_IMPORTS: any[] = [];
export const ADDITIONAL_PROVIDERS: any[] = [];

export const commonEnvironment: LCEEnvironmentCommon = {
    appDisplayName: 'LCE',
    availableLanguages: [XSLanguage.FRENCH, XSLanguage.ENGLISH],
    defaultLanguage: XSLanguage.FRENCH,
    requestor: LCERequestorType.MUNICIPALITY_BACK_OFFICE_APP
};
