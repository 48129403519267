import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
    LCECertificateOrderDeliveryAssignmentPartial,
    LCECertificateOrderDeliveryAssignmentSearch,
    LCECertificateOrderDeliveryAssignmentService,
    LCECertificateOrderDeliveryAssignmentStatus,
    LCECertificateOrderDeliveryUnassignmentRequest,
    LCECertificateOrderPartial,
    LCECertificateOrderStatus,
    LCENotificationChannel,
    LCEUserDeliveryManPartial
} from '@lce/core';
import {XSPagination, XSPeriod, XSPredefinedPeriod, XSSearchResult, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSLoaderType, XSTranslationService} from '@xs/common';
import {XSButton, XSConfirmation, XSInputMultiSelectOptions, XSPaginationLoadMoreOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-delivery-man-order-widget', templateUrl: './lce-delivery-man-order-widget.component.html'})
export class LCEDeliveryManOrderWidgetComponent implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;
    readonly UNASSIGN_LOADER_ID = XSUtils.uuid();
    readonly LOADER_ID = XSUtils.uuid();
    readonly ORDER_NUMBER_LOADER_ID = XSUtils.uuid();
    readonly loaderType: XSLoaderType = XSLoaderType.THREE_BOUNCE;
    readonly TR_BASE = 'lce.shared.certificateOrders.widget.deliveryMan.';

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subTitleStyleClass?: string;

    @Input() title?: string;
    @Input() subTitle?: string;

    @Input() deliveryMan?: LCEUserDeliveryManPartial;
    @Input() selectMode?: 'multiple' | 'single';

    @Input() notifyDeliveryMan?: boolean;
    @Input() notifyDeliveryManAfterNSeconds?: number;
    @Input() notifyChannels?: LCENotificationChannel[];

    @Input() showHeaderBackgroundColor?: boolean;
    @Input() showMap?: boolean;
    @Input() disabled?: boolean;

    @Output() unassigningOrdersEvent = new EventEmitter<boolean>();
    @Output() unassignOrdersEvent = new EventEmitter<{ deliveryManID: string; orderIDs: string[] }>();
    @Output() selectedOrdersEvent = new EventEmitter<LCECertificateOrderPartial | LCECertificateOrderPartial[]>();

    assignments: LCECertificateOrderDeliveryAssignmentPartial[];
    selectedOrders: LCECertificateOrderPartial[] = [];
    selectedAssignments: LCECertificateOrderDeliveryAssignmentPartial[] = [];

    totalResults: number = 0;
    orderStatus: LCECertificateOrderStatus[];
    orderStatusSearchField: XSInputMultiSelectOptions;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    errorUnassign: any;

    unassignmentConfirmation: XSConfirmation;

    pagination: XSPagination = {
        size: 5,
        page: 0
    };
    certificateOrderDeliveryAssignmentSearch: LCECertificateOrderDeliveryAssignmentSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size,
        statuses: [LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED],
        sort: ['createdBy|desc']
    };
    paginationOptions: XSPaginationLoadMoreOptions = {
        fnSearch: (pagination) => {
            this.certificateOrderDeliveryAssignmentSearch.paginationPage = pagination.page;
            this.certificateOrderDeliveryAssignmentSearch.paginationSize = pagination.size;
            return this.certificateOrderDeliveryAssignmentService.search(this.certificateOrderDeliveryAssignmentSearch!);
        }
    };

    private readonly ORDER_STATUS_TR_BASE = 'lce.core.certificateOrderStatus.';
    orderStatusItems: XSLabelValueItem[] = [
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.DELIVERED, value: LCECertificateOrderStatus.DELIVERED},
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.ERROR, value: LCECertificateOrderStatus.ERROR},
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.OUT_FOR_DELIVERY, value: LCECertificateOrderStatus.OUT_FOR_DELIVERY},
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.PRINT, value: LCECertificateOrderStatus.PRINT},
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.READY, value: LCECertificateOrderStatus.READY}
    ];

    private subscription: Subscription = new Subscription();
    private _disabledSearchFields: boolean;

    constructor(
        //
        private loaderService: XSLoaderService,
        private certificateOrderDeliveryAssignmentService: LCECertificateOrderDeliveryAssignmentService,
        private translationService: XSTranslationService
    ) {
        this.initializeTranslation();
    }

    public get disabledSearchField(): boolean {
        return this._disabledSearchFields;
    }

    ngOnInit(): void {
        this.buildSearchFields();

        if (XSUtils.isNull(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isNull(this.selectMode)) this.selectMode = 'single';
        if (XSUtils.isNull(this.notifyDeliveryMan)) this.notifyDeliveryMan = true;
        if (XSUtils.isEmpty(this.notifyChannels)) this.notifyChannels = [LCENotificationChannel.EMAIL, LCENotificationChannel.SMS];

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.deliveryMan) && !changes.deliveryMan!.isFirstChange()) this.update(true);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onStatusSearch(status: any): void {
        this.certificateOrderDeliveryAssignmentSearch.statuses = status;
        this.search();
    }

    public update(disabledSearchFields: boolean = false) {
        if (!XSUtils.isEmpty(this.deliveryMan)) {
            this.certificateOrderDeliveryAssignmentSearch.deliveryManCodes = [this.deliveryMan!.code];
            this.search(disabledSearchFields);
        }
    }

    public onPeriodSelectEvent(period: XSPeriod): void {
        this.certificateOrderDeliveryAssignmentSearch.createdOnPredefinedPeriod = period.value as XSPredefinedPeriod;
        this.search();
    }

    public search(disabledSearchFields?: boolean): void {
        this.error = undefined;
        this.assignments = [];
        this.loaderService.startLoader(this.LOADER_ID);
        this.loaderService.startLoader(this.ORDER_NUMBER_LOADER_ID);
        if (disabledSearchFields === true) this.setDisabledSearchField(true);
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService.search(this.certificateOrderDeliveryAssignmentSearch)
                .pipe(
                    finalize(() => {
                        this.loaderService.stopLoader(this.LOADER_ID);
                        this.loaderService.stopLoader(this.ORDER_NUMBER_LOADER_ID);
                        if (disabledSearchFields === true) this.setDisabledSearchField(false);
                    })
                )
                .subscribe({
                    next: (searchResult: XSSearchResult<LCECertificateOrderDeliveryAssignmentPartial>) => {
                        this.assignments = searchResult.data;
                        this.pagination = searchResult.pagination!;
                        this.totalResults = searchResult.total;
                        this.selectedOrders = [];
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    public onUnassignOrders(): void {
        this.errorUnassign = undefined;
        this.loaderService.startLoader(this.UNASSIGN_LOADER_ID);
        this.unassigningOrdersEvent.emit(true);
        const assignmentIDs = this.selectedAssignments.map((assignment) => assignment.id!);
        const request = this.buildCertificateOrderDeliveryUnassignmentRequests();
        console.log('assignment Request ===>', request);
        this.setDisabledSearchField(true);
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService
                .unassign(request)
                .pipe(finalize(() => {
                    this.unassigningOrdersEvent.emit(false);
                    this.loaderService.stopLoader(this.UNASSIGN_LOADER_ID);
                }))
                .subscribe({
                    next: () => {
                        this.search(true);
                        this.unassignOrdersEvent.emit({deliveryManID: this.deliveryMan!.id, orderIDs: assignmentIDs});
                    },
                    error: (error: any) => {
                        this.errorUnassign = error;
                        throw error;
                    }
                })
        );
    }

    public isUnassignButton(): boolean {
        return !XSUtils.isEmpty(this.deliveryMan) && !XSUtils.isEmpty(this.selectedOrders);
    }

    public isUnassignLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.UNASSIGN_LOADER_ID);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public isSelected(order: LCECertificateOrderPartial): boolean {
        return !XSUtils.isNull(this.selectedOrders.find((elt) => elt.id === order.id));
    }

    public hasAssignError(): boolean {
        return !XSUtils.isNull(this.errorUnassign);
    }

    public handlePaginationLoad(searchResults: XSSearchResult<LCECertificateOrderDeliveryAssignmentPartial>): void {
        this.assignments = this.assignments.concat(searchResults.data);
        this.totalResults = searchResults.total;
    }

    public handlePaginationError(error: any) {
        this.error = error;
    }

    public onAssignmentSelect(assignment: LCECertificateOrderDeliveryAssignmentPartial): void {
        this.setAssignmentSelected(assignment);
        this.setOrderSelected(assignment.certificateOrder);
        if (!XSUtils.isEmpty(this.selectedOrders)) {
            this.selectMode === 'single' ? this.selectedOrdersEvent.emit(this.selectedOrders[0]) : this.selectedOrdersEvent.emit(this.selectedOrders);
        }
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private buildSearchFields(): void {
        this.orderStatusSearchField = {
            labelField: 'trLabel',
            valueField: 'value',
            filter: false,
            filterBy: 'trLabel',
            showToggleAll: true,
            translateItemLabels: true
        };
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.orderStatusItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.orderStatusItems);
                this.orderStatusItems = [...this.orderStatusItems];
            })
        );
    }

    private setOrderSelected(order: LCECertificateOrderPartial): void {
        if (this.selectedOrders.includes(order)) {
            let index = this.selectedOrders.indexOf(order);
            this.selectedOrders.splice(index, 1);
        } else {
            if (this.selectMode === 'single') this.selectedOrders = [];
            this.selectedOrders.push(order);
        }
    }

    private setAssignmentSelected(assignment: LCECertificateOrderDeliveryAssignmentPartial): void {
        if (this.selectedAssignments.includes(assignment)) {
            let index = this.selectedAssignments.indexOf(assignment);
            this.selectedAssignments.splice(index, 1);
        } else {
            if (this.selectMode === 'single') this.selectedAssignments = [];
            this.selectedAssignments.push(assignment);
        }

        this.unassignmentConfirmation = {
            key: 'assignmentConfirmationKey',
            trMessage: this.selectedAssignments.length > 1 ? 'lce.shared.delivery.request.confirmation.unassignments' : 'lce.shared.delivery.request.confirmation.unassignment',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.onUnassignOrders();
            },
            reject: () => {
            }
        };
    }

    private setDisabledSearchField(disabledSearchFields: boolean) {
        this._disabledSearchFields = disabledSearchFields;
    }

    private buildCertificateOrderDeliveryUnassignmentRequests(): LCECertificateOrderDeliveryUnassignmentRequest[] {

        const requests: LCECertificateOrderDeliveryUnassignmentRequest[] = [];
        this.selectedAssignments.forEach(selectedAssignment => {
            requests.push({
                certificateOrderID: selectedAssignment.certificateOrder.id,
                notifyDeliveryMan: this.notifyDeliveryMan,
                notifyDeliveryManAfterNSeconds: this.notifyDeliveryManAfterNSeconds,
                notifyChannels: this.notifyChannels
            });
        });

        return requests;
    }
}
