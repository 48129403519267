import { Component, Input, OnInit } from '@angular/core';
import { XSAssert } from '@xs/base';

@Component({ selector: 'lce-certificate-order-head', templateUrl: './lce-certificate-order-head.component.html' })
export class LCECertificateOrderHeadComponent implements OnInit {
	@Input() styleClass?: string;

	@Input() qrCode: string;

	@Input() orderNumber?: string;

	ngOnInit(): void {
		XSAssert.notEmpty(this.qrCode, 'qrCode');
	}
}
