import {HttpParams} from '@angular/common/http';
import {
    LCE_IVORIAN_FIRSTNAMES,
    LCE_IVORIAN_LASTNAMES,
    LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    LCECoreContextService,
    LCEUser,
    LCEUserAccountStatus,
    LCEUserCreate,
    LCEUserCustomerPartial,
    LCEUserPartial,
    LCEUserSearch,
    LCEUserStatus
} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUserAccountHandler} from '@lce/mock/core/handlers/user/lce-mock-user-account-handler';
import {LCE_MBO_MOCK_USER_SYSTEM} from '@lce/mock/core/lce-mock-user-data';
import {XSGender, XSLanguage, XSPKDTOAuditFullState, XSSearchResult, XSTemporalUtils, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate, XSMockUtils} from '@xs/mock';

export abstract class LCEMockUserHandler<X extends LCEUser, P extends LCEUserPartial, S extends LCEUserSearch> extends XSMockPKResourceAuditFullHandler<X, P, S>
    implements XSMockPKSearchable<LCEUserCustomerPartial> {

    protected constructor(
        protected contextService: LCECoreContextService,
        protected mockMunicipalityHandler: LCEMockMunicipalityHandler,
        protected mockUserAccountHandler: LCEMockUserAccountHandler,
        protected datasetBaseID: string,
        endpointIndex: string) {
        super(datasetBaseID, endpointIndex);
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public findOneByUsername(username: string): P {
        const foundUser = Array
            .from(this.getStorage().values())
            .find(resource => resource.email === username || resource.primaryPhoneNumber === username || resource.code === username)!;
        return XSUtils.isEmpty(foundUser) ? undefined! : this.toPartial(foundUser);
    }

    public search(params: HttpParams | undefined): XSSearchResult<P> {
        return this.searchResponseData(params, this.getQueryPredicates(), this.getSearchPredicates());
    }

    protected abstract buildCode(): string;

    protected getQueryPredicates(): XSMockSearchQueryPredicate<X>[] {
        return [
            (user, query) => user.code.toLowerCase().includes(query?.toLowerCase()),
            (user, query) => user.name?.firstName.toLowerCase().includes(query?.toLowerCase()),
            (user, query) => user.name?.lastName.toLowerCase().includes(query?.toLowerCase()),
            (user, query) => user.email?.toLowerCase().includes(query?.toLowerCase()),
            (user, query) => user.primaryPhoneNumber.toLowerCase().includes(query.toLowerCase()),
            (user, query) => user.secondaryPhoneNumber?.toLowerCase().includes(query.toLowerCase()),
            (user, query) => user.whatsAppPhoneNumber?.toLowerCase().includes(query.toLowerCase()),
            (user, query) => user.note?.toLowerCase().includes(query.toLowerCase())
        ];
    }

    protected getSearchPredicates(): XSMockSearchPredicate<X>[] {
        return [
            (user, params) => this.httpParamArrayIncludes(params, 'codes', user.code),
            (user, params) => this.httpParamArrayIncludes(params, 'municipalityOfResidenceCodes', user.municipalityOfResidence?.code),
            (user, params) => this.httpParamStringIncludes(params, 'firstName', user.name?.firstName),
            (user, params) => this.httpParamStringIncludes(params, 'lastName', user.name?.lastName),
            (user, params) => this.httpParamStringIncludes(params, 'email', user.email),
            (user, params) => this.httpParamStringIncludes(params, 'primaryPhoneNumber', user.primaryPhoneNumber),
            (user, params) => this.httpParamStringIncludes(params, 'secondaryPhoneNumber', user.secondaryPhoneNumber),
            (user, params) => this.httpParamStringIncludes(params, 'whatsAppPhoneNumber', user.whatsAppPhoneNumber)
        ];
    }

    protected addUserMockData(userID: string): void {
        this.addResourceBaseMockData(userID);
        const uMockData: XSMockData[] = this.mockUserAccountHandler.buildUserMockData(userID);
        this.mockDataArray.push(...uMockData);
    }

    protected baseToPartial(user: LCEUser): LCEUserPartial {
        return {
            id: user.id,
            code: user.code,
            type: user.type,
            name: user.name,
            email: user.email,
            primaryPhoneNumber: user.primaryPhoneNumber,
            language: user.language,
            status: user.status,
            lastActivity: user.lastActivity,
            profileImage: user.profileImage
        };
    }

    protected baseCreate(userCreate: LCEUserCreate): LCEUser {
        return {
            id: XSUtils.uuid(),
            type: undefined!,
            createdOn: new Date().toISOString(),
            createdBy: this.getAuthenticatedUser(),
            state: XSPKDTOAuditFullState.ACTIVE,
            code: this.buildCode(),
            municipalityOfResidence: XSUtils.isEmpty(userCreate.municipalityOfResidenceCode) ? undefined : this.mockMunicipalityHandler.findOneByCode(userCreate.municipalityOfResidenceCode!),
            status: XSUtils.randomElement([LCEUserStatus.ONLINE, LCEUserStatus.OFFLINE]),
            accountStatus: XSUtils.randomElement([LCEUserAccountStatus.ACTIVE, LCEUserAccountStatus.INACTIVE]),
            name: userCreate.name,
            gender: userCreate.gender,
            bloodType: userCreate.bloodType,
            email: userCreate.email,
            primaryPhoneNumber: userCreate.primaryPhoneNumber,
            secondaryPhoneNumber: userCreate.secondaryPhoneNumber,
            whatsAppPhoneNumber: userCreate.whatsAppPhoneNumber,
            address: userCreate.address,
            birthDate: userCreate.birthDate,
            language: userCreate.language,
            profileImage: userCreate.profileImage,
            note: userCreate.note
        };
    }

    protected baseRandomUser(): LCEUser {
        const randomName = {
            firstName: XSUtils.randomElement(LCE_IVORIAN_FIRSTNAMES),
            lastName: XSUtils.randomElement(LCE_IVORIAN_LASTNAMES)
        };
        const randomBoolean = XSUtils.randomElement<boolean>([true, true, false]);
        return {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_SYSTEM,
            updatedBy: randomBoolean ? this.getAuthenticatedUser() : undefined,
            updatedOn: randomBoolean ? new Date().toISOString() : undefined,
            state: XSPKDTOAuditFullState.ACTIVE,
            type: undefined!,
            code: this.buildCode(),
            name: randomName,
            gender: XSUtils.randomElement([XSGender.MALE, XSGender.FEMALE]),
            email: XSMockUtils.randomEmail(randomName),
            primaryPhoneNumber: '+225' + XSUtils.randomDigits(10),
            address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
            birthDate: XSUtils.randomDate(),
            language: XSUtils.randomElement([XSLanguage.FRENCH, XSLanguage.FRENCH, XSLanguage.ENGLISH]),
            status: XSUtils.randomElement([LCEUserStatus.ONLINE, LCEUserStatus.ONLINE, LCEUserStatus.NEVER_CONNECTED, LCEUserStatus.AWAY, LCEUserStatus.BUSY, LCEUserStatus.NEVER_CONNECTED]),
            accountStatus: XSUtils.randomElement([LCEUserAccountStatus.ACTIVE, LCEUserAccountStatus.DELETED, LCEUserAccountStatus.INACTIVE, LCEUserAccountStatus.LOCKED]),
            accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
            municipalityOfResidence: this.mockMunicipalityHandler.getMunicipalityYopougon(),
            currentCoordinate: XSUtils.randomElement([LCE_MOCK_COORDINATE_PHARMACIE_ANALYA]),
            lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
            lastActivity: new Date().toISOString()
        };
    }
}