import {Component, Input, OnInit} from '@angular/core';
import {LOG, XSAssert, XSUtils} from '@xs/base';
import {LCEUserPartial} from '@lce/core';

@Component({
    selector: 'lce-user-detail',
    templateUrl: './lce-user-detail.component.html',
    styles: [':host { display: flex; width: max-content; }']
})
export class LCEUserDetailComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() data: LCEUserPartial;

    @Input() showViewRecordButton?: boolean;

    constructor() {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showViewRecordButton)) this.showViewRecordButton = false;
    }

    public onViewRecordBtnClick() {
        LOG().debug('Opening the user record dialog [userId : ' + this.data.id + '] ...');
    }
}
