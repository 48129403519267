import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';

@Component({
    selector: 'lce-record-header-component',
    template: `
        <xs-ragnar
                [ellipsis]="ellipsis"
                [avatar]="{type: 'icon', data: icon}"
                [line1]="line1"
                [line2]="line2"
                avatarStyleClass="xs-color-secondary"
                line1StyleClass="xs-color-secondary xs-font-weight-600"
                line2StyleClass="xs-color-secondary"
                [styleClass]="styleClass">
        </xs-ragnar>
    `
})
export class LCERecordHeaderComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() iconStyleClass?: string;
    @Input() line1StyleClass?: string;
    @Input() line2StyleClass?: string;

    @Input() icon: string;
    @Input() line1: string;
    @Input() line2?: string;

    @Input() ellipsis?: boolean;

    ngOnInit() {
        XSAssert.notEmpty(this.icon, 'icon');
        XSAssert.notEmpty(this.line1, 'line1');
    }
}