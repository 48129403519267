import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceService} from '@xs/base';
import {LCECertificateOrderCreateSession, LCECertificateOrderCreateSessionSearch} from '../../../domain/certificate/order/lce-certificate-order-create-session';
import {LCEHttpClientService} from '../../lce-http-client.service';
import {LCE_CORE_ENDPOINTS} from '../../../constants/lce-core-endpoint.constant';
import {Observable} from 'rxjs';
import {LCECertificateOrderCreate} from '../../../domain/certificate/order/lce-certificate-order';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderCreateSessionService extends XSPKResourceService<LCECertificateOrderCreateSession, LCECertificateOrderCreateSession, LCECertificateOrderCreateSessionSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.index, 'certificateOrderCreateSession');
    }

    public create(orderCreate: LCECertificateOrderCreate): Observable<LCECertificateOrderCreateSession> {
        XSAssert.notEmpty(orderCreate, 'orderCreate');
        return this.httpClientService.post<LCECertificateOrderCreateSession>(this.buildEndpoint(), orderCreate);
    }

    public update(orderCreateSessionID: string, orderCreate: LCECertificateOrderCreate): Observable<LCECertificateOrderCreateSession> {
        XSAssert.notEmpty(orderCreateSessionID, 'orderCreateSessionID');
        XSAssert.notEmpty(orderCreate, 'orderCreate');
        return this.httpClientService.patch<LCECertificateOrderCreateSession>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.update).replace('{orderCreateSessionID}', orderCreateSessionID), orderCreate);
    }
}