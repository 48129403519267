import {Component, Input, OnInit} from '@angular/core';
import {XSInlineMessage} from '@xs/core';
import {MenuItem} from 'primeng/api';
import {LCEBreadcrumbService} from './lce-breadcrumb.service';
import {XSUtils} from '@xs/base';

@Component({selector: 'lce-breadcrumb', templateUrl: './lce-breadcrumb.component.html'})
export class LCEBreadcrumbComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() messageTextStyleClass?: string;

    message: XSInlineMessage | undefined;

    constructor(private breadcrumbService: LCEBreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadcrumbService.onMessage.subscribe(message => this.message = message);
    }

    public hasMessage(): boolean {
        return !XSUtils.isEmpty(this.getMessage());
    }

    public getMessage(): XSInlineMessage | undefined {
        return this.message;
    }

    public isNavigationVisible(): boolean {
        return this.breadcrumbService.isNavigationVisible();
    }

    public getItems(): MenuItem[] {
        return this.breadcrumbService.getItems();
    }

    public getHomeItem(): MenuItem {
        return this.breadcrumbService.getHomeItem();
    }
}
