import {LCEFacilityPartial} from '../facility/lce-facility';
import {LCEUser, LCEUserCreate, LCEUserPartial, LCEUserSearch, LCEUserType} from './lce-user';
import {XSContactPerson} from '@xs/base';

// ----------------------------------------------------------------------------------------------------------------------------------------------------------------
// === * ===
// ----------------------------------------------------------------------------------------------------------------------------------------------------------------

export interface LCEUserMunicipalEmployeeUpdatePINCodeResponse {
    pinCodeUpdatedOn: string;
    pinCodeUpdatedBy: LCEUserPartial;
}

export enum LCEUserMunicipalEmployeeMainRole {
    SYSTEM = 'system',
    ROOT = 'root',
    SUPER_ADMIN = 'superAdmin',
    ADMIN = 'admin',
    MANAGER = 'manager',
    AGENT = 'agent',
    GUEST = 'guest'
}

export enum LCEUserMunicipalEmployeePosition {
    SYSTEM_ADMINISTRATOR = 'systemAdministrator',
    SYSTEM_MAINTAINER = 'systemMaintainer',
    MAYOR = 'mayor',
    DEPUTY_MAYOR = 'deputyMayor',
    DSA = 'dsa',
    DSI = 'dsi',
    ADMINISTRATIVE_AGENT = 'administrativeAgent',
    IT_AGENT = 'itAgent',
    MUNICIPAL_EMPLOYEE = 'municipalEmployee',
    GUEST = 'guest',
    UNKNOWN = 'unknown'
}

export interface LCEUserMunicipalEmployeePartial extends LCEUserPartial {
    mainRole: LCEUserMunicipalEmployeeMainRole;
    position: LCEUserMunicipalEmployeePosition;
    facilityCode: string;
    municipalityCode: string;
}

export interface LCEUserMunicipalEmployeeSearch extends LCEUserSearch {
    mainRoles?: LCEUserMunicipalEmployeeMainRole[];
    positions?: LCEUserMunicipalEmployeePosition[];
    facilityCodes?: string[];
    municipalityCodes?: string[];
    districtCodes?: string[];
}

export interface LCEUserMunicipalEmployeeCreate extends LCEUserCreate {
    mainRole: LCEUserMunicipalEmployeeMainRole;
    position: LCEUserMunicipalEmployeePosition;
    facilityCode: string;
    emergencyContactPerson?: XSContactPerson;
}

export interface LCEUserMunicipalEmployee extends LCEUser {
    type: LCEUserType.MUNICIPAL_EMPLOYEE;

    mainRole: LCEUserMunicipalEmployeeMainRole;
    position: LCEUserMunicipalEmployeePosition;

    facility: LCEFacilityPartial;

    emergencyContactPerson?: XSContactPerson;

    pinCodeUpdatedOn?: string;
    pinCodeUpdatedBy?: LCEUserPartial;
}

export interface LCEUserMunicipalEmployeeSettings {
}
