import { Component, Input, OnInit } from '@angular/core';
import { LCE_SHARED_ICON } from '../../../api/constants/lce-shared-icon.constant';
import { XSAssert } from '@xs/base';
import { LCEFacilityWorkerPartial, LCE_TR_BASE_FACILITY_WORKER_POSITION } from '@lce/core';
import { LCEFacilityWorkerUtils } from '../../lce-facility-worker-utils';

@Component({
    selector: 'lce-facility-worker-detail-head',
    templateUrl: './lce-facility-worker-detail-head.component.html',
    styles: [':host { display: flex; width: max-content; }'],
})
export class LCEFacilityWorkerDetailHeadComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE_FACILITY_WORKER_POSITION = LCE_TR_BASE_FACILITY_WORKER_POSITION;

    @Input() styleClass?: string;

    @Input() data: LCEFacilityWorkerPartial;

    @Input() showBorder?: boolean;

    workerIcon: string;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');

        this.workerIcon = LCEFacilityWorkerUtils.getIcon(this.data.position);
    }
}
