import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {XSAssert, XSUtils} from '@xs/base';
import {XSRouterService} from '@xs/common';
import {XSCoreContextService} from '@xs/core';
import {LCETemplateExceptionState, LCETemplateExceptionType} from '../lce-template-default';
import {LCE_CMD_ICON} from '../../../core/constants/lce-cmd-icon.constant';

@Component({
    selector: 'lce-template-default-exception',
    templateUrl: './lce-template-default-exception.component.html',
    host: {class: 'xs-flex-row-center xs-width-full'}
})
export class LCETemplateDefaultExceptionComponent implements OnInit {
    readonly ICON = LCE_CMD_ICON;

    exceptionType: LCETemplateExceptionType;

    state: LCETemplateExceptionState | undefined;

    exceptionCode: string;

    redirectButtonLabel: string;

    private readonly TR_BUTTON_LABEL_GO_TO_DASHBOARD = 'lce.cmd.template.default.exception.goToDashboard';
    private readonly TR_BUTTON_LABEL_GO_TO_LOGIN = 'lce.cmd.template.default.exception.goToLogin';

    private redirectURL: string;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private routerService: XSRouterService,
        private contextService: XSCoreContextService) {
        this.state = this.router.getCurrentNavigation()?.extras.state;
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe(data => {
            this.exceptionType = data.exceptionType;
            XSAssert.notEmpty(this.exceptionType, 'exceptionType');
            this.exceptionCode = this.getExceptionCode();
            this.redirectURL = this.getRedirectURL();
            this.redirectButtonLabel = this.getRedirectButtonLabel();
        });
    }

    public reportIssue(): void {
        // TODO : Open a dialog with some field to report the issues.
    }

    public goBack(): void {
        this.router.navigateByUrl(this.routerService.getPreviousURL());
    }

    public redirect(): void {
        this.router.navigateByUrl(this.redirectURL);
    }

    public getResource(): string {
        return XSUtils.removeLastCharIfSlash(this.routerService.getBaseURL()) + this.routerService.getCurrentURL();
    }

    private getRedirectButtonLabel(): string {
        if (!XSUtils.isEmpty(this.state?.redirectButtonLabel)) return this.state!.redirectButtonLabel!;
        return this.contextService.isUserAuthenticated() ? this.TR_BUTTON_LABEL_GO_TO_DASHBOARD : this.TR_BUTTON_LABEL_GO_TO_LOGIN;
    }

    private getRedirectURL(): string {
        if (!XSUtils.isEmpty(this.state?.redirectURL)) return this.state!.redirectURL!;
        return '/';
    }

    private getExceptionCode(): string {
        switch (this.exceptionType) {
            case LCETemplateExceptionType.NOT_FOUND:
                return '404';
            case LCETemplateExceptionType.ACCESS_DENIED:
                return '403';
            case LCETemplateExceptionType.ERROR:
                return 'lce.cmd.template.default.exception.error';
            default:
                throw new Error('unknown exception type (' + this.exceptionType + ')');
        }
    }
}
