<div class="xs-flex-column xs-p-15 {{ styleClass }}">
    <lce-certificate-order-simple-stats
        #simpleStats
        [municipalityCodes]="[municipalityCode]"
        [statuses]="[PENDING_STATUS]"
        [title]="TR_BASE + 'title'">
    </lce-certificate-order-simple-stats>

    <form class="xs-flex-column xs-mt-50 xs-ml-minus-15">
        <xs-input-field-radios [options]="nLastOrdersFieldOptions"></xs-input-field-radios>
        <p class="xs-display-block xs-pl-15 xs-mt-30 xs-font-size-small xs-color-secondary">{{ TR_BASE + 'note' | translate }}</p>
    </form>
</div>

<!-- === Dialog Templates === -->
<ng-template #dHeader>
    <xs-ivar line1="{{ TR_BASE }}header" line2="{{ TR_BASE }}subHeader" styleClass="xs-dialog-header"></xs-ivar>
</ng-template>
<ng-template #dFooter>
    <div class="xs-flex-row xs-justify-content-end">
        <xs-button (clickEvent)="onClose()" class="xs-mr-15" label="xs.core.label.cancel" severity="secondary" type="button"></xs-button>
        <xs-button
                [confirmation]="processConfirmation"
                [disabled]="!canProcess()"
                [label]="TR_BASE + 'process'"
                [loading]="processBtnLoading"
                confirmationPopupStyleClass="xs-font-size-small"
                type="button">
        </xs-button>
    </div>
</ng-template>
