<div class="xs-flex-row xs-justify-content-center xs-width-full {{ styleClass }}">
    <div *ngIf="swiper === false">
        <xs-data-box
                *ngFor="let dataBoxe of dataBoxes"
                [applyMainValueClickableStyle]="true"
                [data]="dataBoxe"
                [loading]="loading"
                styleClass="xs-min-width-500-imp xs-mb-20">
        </xs-data-box>
    </div>
    <xs-data-boxes
            *ngIf="swiper"
            [dataBoxLoading]="loading"
            [dataBoxShowBorder]="showBorder"
            [data]="dataBoxes"
            [error]="error"
            [swiperAutoPlayDelay]="swiperAutoPlayDelay"
            [swiperAutoPlay]="swiperAutoPlay"
            class="xs-justify-content-center"
            dataBoxStyleClass="xs-min-width-500-imp"
            type="swiper">
    </xs-data-boxes>
</div>