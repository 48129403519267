import {Injectable} from '@angular/core';
import {LCEFacility} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEFacilityCreateUpdateComponent} from './create-update/lce-facility-create-update.component';
import {LCEFacilityRecordComponent} from './record/lce-facility-record.component';

export interface LCEFacilityDialogCreateUpdateArg {
    facilityID?: string;
    facility?: LCEFacility;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (facility?: LCEFacility) => void;
    onSave?: (facility?: LCEFacility) => void;
}

export interface LCEFacilityDialogRecordArg {
    facilityID?: string;
    facility?: LCEFacility;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (facility?: LCEFacility) => void;
    onEdit?: (facility: LCEFacility) => void;
}

@Injectable()
export class LCEFacilityDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEFacilityDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.facilityID) && XSUtils.isEmpty(arg.facility)) {
            throw new Error('arg.facilityID and arg.facility cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.facilityID) && !XSUtils.isEmpty(arg.facility)) {
            throw new Error('arg.facilityID and arg.facility cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                facilityID: arg.facilityID,
                facility: arg.facility,
                showEditButton: arg.showEditButton,
                dataManager: arg.dataManager,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEFacilityRecordComponent = this.dialogService.openComponentDialog(LCEFacilityRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedFacility: LCEFacility) => {
            if (arg?.onClose) arg!.onClose!(savedFacility);
        });
        recordComponent.editEvent.subscribe((facilityRecord: LCEFacility) => {
            if (arg?.onEdit) arg.onEdit(facilityRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    facility: facilityRecord,
                    onClose: (facility) => this.openRecordDialog({facility: facility, showEditButton: true}),
                    onSave: (facility) => this.openRecordDialog({facility: facility, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEFacilityDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.facilityID) && !XSUtils.isEmpty(arg?.facilityID)) {
            throw new Error('arg.facilityID and arg.facility cannot both be set at the same time.');
        }

        const dConfig: XSDialogConfig = {
            data: {
                facilityID: arg?.facilityID,
                facility: arg?.facility,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEFacilityCreateUpdateComponent = this.dialogService.openComponentDialog(LCEFacilityCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((facility: LCEFacility) => {
            if (arg?.onClose) arg!.onClose!(facility);
        });
        createUpdateComponent.saveEvent.subscribe((facility: LCEFacility) => {
            if (!facility) {
                createUpdateComponent.closeDialog();
                return;
            }
            if (arg?.onSave) arg.onSave(facility);
            createUpdateComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({facility: facility});
        });
    }
}
