import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XSPaymentChargePartial} from '@xs/payment/base';

@Component({selector: 'lce-certificate-order-payment', templateUrl: './lce-certificate-order-payment.component.html'})
export class LCECertificateOrderPaymentComponent implements OnInit {
    
    @Input() styleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;

    @Input() data: XSPaymentChargePartial;

    protected readonly ICON = LCE_SHARED_ICON;

    constructor() {
    }

    ngOnInit() {
        XSAssert.notEmpty(this.data, 'data');
    }

}