export const LCE_CMD_ENDPOINT = {
    initialization: 'initialization/cmd',
    buildInfo: 'assets/build-info.json',
    geolocate: 'geolocate',
    authentication: {
        login: 'municipal-employees/authentication/login',
        logout: 'municipal-employees/authentication/logout',
        verifyUsername: 'authentication/verify-username',
        sendVerificationCode: 'authentication/send-verification-code',
        validateVerificationCode: 'authentication/validate-verification-code',
        resetPassword: 'authentication/reset-password',
        updatePassword: 'authentication/update-password',
        verifyPassword: 'authentication/verify-password'
    }
};
