import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {XSAuthenticationPINCodeRequest, XSAuthenticationResponse, XSAuthenticationUsernamePasswordRequest} from '@iro-xs/xs-base/domain/xs-authentication';
import {LCE_MBO_ENDPOINT} from '@lce-mbo/core/constants/lce-mbo-endpoint.constant';
import {LCE_CORE_ENDPOINTS, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {LCEMockUserMunicipalEmployeeHandler} from '@lce/mock/core/handlers/user/lce-mock-user-municipal-employee-handler';
import {LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY} from '@lce/mock/core/lce-mock-user-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON, LCE_MOCK_MUNICIPALITY_NAME_YOPOUGON} from '@lce/mock/core/lce-mock.constant';
import {XSAuthenticationRequest, XSHttpMethod, XSTemporalUtils, XSUtils} from '@xs/base';
import {XSAuthenticationFailed, XSAuthenticationFailedReason} from '@xs/core';
import {XSMockHttpHandler, XSMockUtils} from '@xs/mock';
import {LCEMBOMockInitializationHandler} from './lce-mbo-mock-initialization-handler';

@Injectable()
export class LCEMBOMockAuthenticationHandler extends XSMockHttpHandler {

    constructor(
        private mockInitializationHandler: LCEMBOMockInitializationHandler,
        private mockUserMunicipalEmployeeHandler: LCEMockUserMunicipalEmployeeHandler) {
        super();
    }

    public buildMockDataArray(): void {
        this.mockDataArray = [
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.authentication.login',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.municipalEmployees.authentication.login,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => this.buildAuthenticationResponseData(rArg.body)
            },
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.authentication.logout',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.municipalEmployees.authentication.logout,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2500
            }
        ];
    }

    private buildAuthenticationResponseData(body: any): XSAuthenticationResponse<LCEUserMunicipalEmployeePartial> {
        const authenticationRequest = body as XSAuthenticationRequest;
        let user: LCEUserMunicipalEmployeePartial | undefined = undefined;

        if (authenticationRequest.hasOwnProperty('username')) {
            const authRequest = (authenticationRequest as XSAuthenticationUsernamePasswordRequest);
            user = this.mockUserMunicipalEmployeeHandler.findOneByUsername(authRequest.username);
            if (XSUtils.isNull(user)) {
                this.throwHttpErrorResponse({reason: XSAuthenticationFailedReason.USERNAME_NOT_FOUND});
            }
            this.checkPassword(authRequest);
        } else if (authenticationRequest.hasOwnProperty('pinCode')) {
            const authRequest = (authenticationRequest as XSAuthenticationPINCodeRequest);
            if (authRequest.pinCode === '5555') user = LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY;
            else if (authRequest.pinCode === '1234') user = XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]);
            else {
                XSMockUtils.throwHttpErrorResponse({
                    status: HttpStatusCode.Unauthorized,
                    url: LCE_CORE_ENDPOINTS.municipalEmployees.authentication.login,
                    exceptionName: 'LCEAuthenticationFailedException',
                    message: 'Authentication failed for the current credentials.'
                });
            }
        }

        return {
            token: 'mbo-fake-token-' + XSUtils.uuid(),
            tokenExpirationDate: XSTemporalUtils.dateAdd(new Date(), {minutes: 60}).toISOString(),
            domain: {code: LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON, name: LCE_MOCK_MUNICIPALITY_NAME_YOPOUGON},
            initialData: this.mockInitializationHandler.buildInitializationLoggedInResponseData(user!)
        };
    }

    private checkPassword(authenticationRequest: XSAuthenticationUsernamePasswordRequest): void {
        const password = authenticationRequest.password.toLowerCase().trim();

        let failedReason: XSAuthenticationFailedReason;

        if (password.length < 6) failedReason = XSAuthenticationFailedReason.INVALID_PASSWORD_REQUIREMENT;
        else if (password === 'failed') failedReason = XSAuthenticationFailedReason.WRONG_PASSWORD;
        else if (password === 'max-attempts') failedReason = XSAuthenticationFailedReason.MAX_ATTEMPTS_ALLOWED_EXCEEDED;
        else return;

        this.throwHttpErrorResponse({reason: failedReason});
    }

    private throwHttpErrorResponse(failed: XSAuthenticationFailed): void {
        throw new HttpErrorResponse({error: {data: failed}, status: HttpStatusCode.Unauthorized, statusText: 'Unauthorized', url: LCE_MBO_ENDPOINT.authentication.login});
    }
}
