import {Injectable} from '@angular/core';
import {LCEHttpClientService} from './lce-http-client.service';
import {Observable} from 'rxjs';
import {XSEnvironmentName} from '@xs/base';
import {LCECoreContextService} from './lce-core-context.service';

@Injectable({providedIn: 'root'})
export class LCEAuthorizationService {

    constructor(protected httpClientService: LCEHttpClientService, protected contextService: LCECoreContextService) {
    }

    public initialize(rolePermissions: any): void {
        // TODO
        console.log('LCEAuthorizationService.initialize', rolePermissions);
    }

    public update(): Observable<void> {
        return undefined!;
    }

    public canViewTestingTools(): boolean {
        return this.contextService.getAppConfig().environment === XSEnvironmentName.LOCAL ||
            this.contextService.getAppConfig().environment === XSEnvironmentName.NOBACKEND ||
            this.contextService.getAppConfig().environment === XSEnvironmentName.DEV;
    }

    public canViewShowcase(): boolean {
        return false;
    }
}
