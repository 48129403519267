import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSPermissionBase, XSPermissionBaseCanShow} from '@lce/core';

@Component({selector: 'xs-authorization-base', templateUrl: './xs-authorization-base.component.html'})
export class XSAuthorizationBaseComponent implements OnInit {

    readonly TR_BASE: string = 'xs.core.authorization.audit.';

    @Input() styleClass?: string;

    @Input() data?: XSPermissionBase;

    @Input() canShow: XSPermissionBaseCanShow;

    @Input() allAuditSelected?: boolean;

    @Output() checkedEvent = new EventEmitter<{ name: string, state: boolean }>();
    @Output() changeEvent = new EventEmitter<XSPermissionBase>();

    ngOnInit(): void {
        XSAssert.notNull(this.canShow, 'canShow');

        if (XSUtils.isEmpty(this.data)) this.data = this.buildDefaultAuditPermission();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.allAuditSelected) && !changes.allAuditSelected!.isFirstChange()) this.selectAllAudit(this.allAuditSelected!);
    }

    public onPermissionChange(path: string, checked: boolean) {
        if (XSUtils.isNull(this.data)) this.data = this.buildDefaultAuditPermission();

        XSUtils.setNestedPropertyValue(this.data, path, checked);

        this.checkedEvent.emit({name: path, state: checked});
        this.changeEvent.emit(this.data);
    }

    private selectAllAudit(checked: boolean): void {
        if (XSUtils.isEmpty(this.data)) this.data = {};

        this.data!.activate = checked;
        this.data!.deactivate = checked;
        this.data!.purge = checked;
        this.data!.restore = checked;
        this.data!.delete = checked;

        console.log('auditPermission', this.data);

    }

    private buildDefaultAuditPermission(): XSPermissionBase {
        return {
            deactivate: false,
            activate: false,
            delete: false,
            restore: false,
            purge: false
        };
    }
}