import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECoreContextService, LCEFacility, LCEFacilityCanOptions, LCEFacilityService} from '@lce/core';
import {LOG, XSAddress, XSAssert, XSContact, XSContactPerson, XSUtils} from '@xs/base';
import {XSLoaderService, XSNumberPipe, XSSize} from '@xs/common';
import {XSAddressFieldOptions, XSAvatar, XSButton, XSContactPersonFieldOptions, XSDataManagerComponent, XSDialogable, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEFacilityTownHallRegistrationDialogService} from '../../facility-town-hall-registration/lce-facility-town-hall-registration-dialog.service';

@Component({
    selector: 'lce-facility-record',
    templateUrl: './lce-facility-record.component.html',
    host: {class: 'xs-width-full'},
    providers: [XSNumberPipe, LCEFacilityTownHallRegistrationDialogService]
})
export class LCEFacilityRecordComponent extends XSDialogable implements OnInit {

    readonly ICON_FACILITY: string = LCE_SHARED_ICON.facility;
    readonly ICON_CERTIFICATE_ORDER: string = LCE_SHARED_ICON.certificateOrder;
    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.facility.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() facilityID?: string;
    @Input() facility?: LCEFacility;

    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;

    @Input() canOptions?: LCEFacilityCanOptions;

    @Output() editEvent = new EventEmitter<LCEFacility>();
    @Output() closeEvent = new EventEmitter<LCEFacility>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEFacility>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieveFacility()
    };

    addressFieldOptions: XSAddressFieldOptions;
    primaryContactPersonFieldOptions: XSContactPersonFieldOptions;
    secondaryContactPersonFieldOptions: XSContactPersonFieldOptions;

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCECoreContextService,
        public facilityService: LCEFacilityService,
        private loaderService: XSLoaderService,
        private facilityTownHallRegistrationDialogService: LCEFacilityTownHallRegistrationDialogService) {
        super();
    }

    get headerAvatar(): XSAvatar {
        return {type: 'icon', size: XSSize.SMALL, data: this.ICON_FACILITY};
    }

    get headerTitle(): string {
        return XSUtils.isEmpty(this.facility) ? this.TR_BASE_LABEL + 'facility' : this.facility!.fullName;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.facility) ? '...' : this.facility!.municipality.district.fullName;
    }

    get facilityContact(): XSContact {
        return {
            primaryPhoneNumber: this.facility?.contactPhoneNumber,
            email: this.facility?.contactEmail
        };
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facilityID = this.dialogConfig.data.facilityID;
            this.facility = this.dialogConfig.data.facility;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.facility));
        }

        if (XSUtils.isEmpty(this.facilityID) && XSUtils.isEmpty(this.facility)) {
            throw new Error('facilityID and facility cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityID) && !XSUtils.isEmpty(this.facility)) {
            throw new Error('facilityID and facility cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityID)) this.retrieveFacility();
        else {
            XSAssert.notNull(this.facility, 'facility');
            this.initialize();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public hasPartnership(): boolean {
        return !XSUtils.isNull(this.facility!.certificateOrderRegistration);
    }

    public onAddUpdatePartnershipDetail(): void {
        this.facilityTownHallRegistrationDialogService.openCreateDialog({
            facility: {id: this.facility!.id, code: this.facility!.code},
            certificateOrderRegistration: this.facility!.certificateOrderRegistration,
            showRecordAfterSave: true
        });
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public canUpdatePartnership(): boolean {
        return this.canEdit() && this.canOptions?.updatePartnership === true;
    }

    public onEdit(): void {
        this.editEvent.emit(this.facility);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.facility);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public retrieveFacility(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.facilityService
                .retrieve(this.facilityID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (facility: LCEFacility) => {
                        this.facility = facility;
                        this.initialize();
                    },
                    error: error => (this.error = error)
                })
        );
    }

    private initialize(): void {
        this.buildAddressFieldOptions();
        this.buildContactPersonFields();
    }

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            formOptions: {
                type: this.facility?.address?.type ? this.facility.address.type : undefined!,
                onSave: (address: XSAddress) => {
                    LOG().debug('Saving Facility Address (' + this.facility!.id + ') ...', address);
                    return this.facilityService.updateAddress(this.facility!.id, address)
                        .pipe(tap(response => {
                            this.facility!.address = response.address;
                            this.facility!.updatedOn = response.updatedOn;
                            this.facility!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
    }

    private buildContactPersonFields(): void {
        this.primaryContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.facility!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving Facility Primary Contact Person (' + this.facility!.id + ') ...', contactPerson);
                    return this.facilityService.updatePrimaryContactPerson(this.facility!.id, contactPerson)
                        .pipe(tap(response => {
                            this.facility!.primaryContactPerson = response.primaryContactPerson;
                            this.facility!.updatedOn = response.updatedOn;
                            this.facility!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
        this.secondaryContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.facility!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving Facility Secondary Contact Person (' + this.facility!.id + ') ...', contactPerson);
                    return this.facilityService
                        .updateSecondaryContactPerson(this.facility!.id, contactPerson)
                        .pipe(tap(response => {
                            this.facility!.secondaryContactPerson = response.secondaryContactPerson;
                            this.facility!.updatedOn = response.updatedOn;
                            this.facility!.updatedBy = this.contextService.getUser();
                        }));
                }
            },
            onDelete: (contactPerson: XSContactPerson) => {
                LOG().debug('Removing Facility Secondary Contact Person (' + this.facility!.id + ') ...', contactPerson);
                return this.facilityService
                    .updateSecondaryContactPerson(this.facility!.id, undefined)
                    .pipe(tap(response => {
                        this.facility!.secondaryContactPerson = response.secondaryContactPerson;
                        this.facility!.updatedOn = response.updatedOn;
                        this.facility!.updatedBy = this.contextService.getUser();
                    }));
            }
        };
    }
}
