import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LCEMunicipalitiesFeatureService {
	private refreshSubject = new Subject<void>();
	onRefresh = this.refreshSubject.asObservable();

	constructor() {}

	public emitRefresh() {
		this.refreshSubject.next();
	}
}
