import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCEHttpClientService} from '@lce/core';
import {XSAssert, XSCount, XSPKResourceService, XSUtils} from '@xs/base';
import {XSFileOutputFormat} from '@xs/core';
import FileSaver from 'file-saver';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LCE_SHARED_ENDPOINT} from '../constants/lce-shared-endpoint.constant';
import {
    LCECertificateOrderBatchProcess,
    LCECertificateOrderBatchProcessCompleteResponse,
    LCECertificateOrderBatchProcessCreate,
    LCECertificateOrderBatchProcessPartial,
    LCECertificateOrderBatchProcessSearch
} from '../domain/lce-certificate-order-batch-process';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderBatchProcessService extends XSPKResourceService<LCECertificateOrderBatchProcess, LCECertificateOrderBatchProcessPartial, LCECertificateOrderBatchProcessSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.index, 'certificateOrderBatchProcess');
    }

    public reopen(batchProcessID: string): Observable<LCECertificateOrderBatchProcessCompleteResponse> {
        XSAssert.notEmpty(batchProcessID, 'batchProcessID');
        return this.httpClientService.patch<LCECertificateOrderBatchProcessCompleteResponse>(this.buildEndpoint(batchProcessID, LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.reopen));
    }

    public complete(batchProcessID: string): Observable<LCECertificateOrderBatchProcessCompleteResponse> {
        XSAssert.notEmpty(batchProcessID, 'batchProcessID');
        return this.httpClientService.patch<LCECertificateOrderBatchProcessCompleteResponse>(this.buildEndpoint(batchProcessID, LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.complete));
    }

    public countNonCompleted(facilityCode?: string): Observable<XSCount> {
        const facilityCodes: string[] = [];
        if (!XSUtils.isEmpty(facilityCode)) {
            facilityCodes.push(facilityCode!);
        }
        return this.count({completed: false, facilityCodes: facilityCodes});
    }

    public download(batchProcessID: string, outputFormat: XSFileOutputFormat): Observable<void> {
        XSAssert.notEmpty(batchProcessID, 'batchProcessID');
        XSAssert.notEmpty(outputFormat, 'outputFormat');
        let params = new HttpParams().set('outputFormat', outputFormat);
        return this.httpClientService
            .get<any>(
                this.buildEndpoint(batchProcessID, LCE_SHARED_ENDPOINT.certificateOrderBatchProcesses.download),
                params,
                undefined,
                {responseType: 'blob', observe: 'response'}
            )
            .pipe(tap((response: any) => {
                const contentDisposition = response.headers.get('Content-Disposition');
                //attachment; filename=Demandes.LCE-BCH-933839.10012024180618.txt
                const fileName = contentDisposition.split(';')[1].split('=')[1].trim();
                FileSaver.saveAs(response.body, fileName);
            }));
    }

    public findOneByCode(batchProcessCode: string): Observable<LCECertificateOrderBatchProcess> {
        XSAssert.notEmpty(batchProcessCode, 'batchProcessCode');
        return this.httpClientService.get<LCECertificateOrderBatchProcess>(this.buildEndpoint('codes', batchProcessCode));
    }

    public create(batchCreate: LCECertificateOrderBatchProcessCreate): Observable<LCECertificateOrderBatchProcess> {
        XSAssert.notEmpty(batchCreate, 'batchCreate');
        XSAssert.notNull(batchCreate.nLastOrders, 'batchCreate.nLastOrders');
        if (batchCreate.nLastOrders <= 0) {
            throw new Error('nLastOrders must be greater than 0');
        }
        return this.httpClientService.post<LCECertificateOrderBatchProcess>(this.buildEndpoint(), batchCreate);
    }
}
