import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class LCEDeliveriesFeatureService {

    private refreshAssignmentSubject = new BehaviorSubject<void>(undefined);
    onRefreshAssignment = this.refreshAssignmentSubject.asObservable();

    constructor() {
    }

    public emitRefresh() {
        console.log('emitRefresh onRefreshAssingnment');
        this.refreshAssignmentSubject.next();
    }
}
