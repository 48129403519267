import {LCEDelivery, LCEDeliveryDestinationCustomLocation, LCEDeliveryDestinationFacility, LCEDeliveryDestinationInternational, LCEDeliveryDestinationServicePoint, LCEDeliveryDestinationType} from '@lce/core';
import {XS_LOREM_IPSUM, XSAddressType} from '@xs/base';
import {FACILITY_PARTIAL_YOPOUGON_TOWN_HALL, SERVICE_POINT_PARTIAL_YOPOUGON_COSMOS} from './lce-mock-common';
import {LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MOCK_USER_SYSTEM_DHL} from './lce-mock-user-data';
import {LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON, LCE_MOCK_COORDINATE_PHARMACIE_ANALYA} from './lce-mock-various-data';

export const DELIVERY_FACILITY_TOWN_HALL_YOPOUGON: LCEDelivery = {
    destination: {
        type: LCEDeliveryDestinationType.FACILITY,
        facility: FACILITY_PARTIAL_YOPOUGON_TOWN_HALL
    } as LCEDeliveryDestinationFacility,
    note: XS_LOREM_IPSUM.medium,
    estimatedDeliveryDate: '2023-08-11',
    estimatedDeliveryTimeRange: {start: '16:30', end: '18:30'},
    updatedBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
    updatedOn: '2023-08-12 15:08:00Z'
};

export const DELIVERY_SERVICE_POINT_COSMOS_YOPOUGON: LCEDelivery = {
    destination: {
        type: LCEDeliveryDestinationType.SERVICE_POINT,
        servicePoint: SERVICE_POINT_PARTIAL_YOPOUGON_COSMOS
    } as LCEDeliveryDestinationServicePoint,
    note: XS_LOREM_IPSUM.medium,
    estimatedDeliveryDate: '2023-08-11',
    estimatedDeliveryTimeRange: {start: '16:30', end: '18:30'}
};

export const DELIVERY_CUSTOM_LOCATION_POINT_COSMOS_YOPOUGON: LCEDelivery = {
    destination: {
        type: LCEDeliveryDestinationType.CUSTOM_LOCATION,
        address: {
            type: XSAddressType.BASIC,
            city: 'Abidjan',
            countryISO: 'ci',
            coordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA
        }
    } as LCEDeliveryDestinationCustomLocation,
    estimatedDeliveryDate: '2023-08-12',
    estimatedDeliveryTimeRange: {start: '15:30', end: '17:30'},
    desiredDeliveryDate: '2023-08-12',
    desiredDeliveryTimeRange: {start: '14:30', end: '16:30'},
    note: XS_LOREM_IPSUM.medium,
    signatureImage: 'assets/mock/images/signature.png',
    deliveredBy: LCE_MBO_MOCK_USER_ELON_MUSK,
    deliveredOn: '2023-08-12 14:59:00Z',
    updatedBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
    updatedOn: '2023-08-12 15:08:00Z'
};

export const DELIVERY_INTERNATIONAL_CANADA: LCEDelivery = {
    destination: {
        type: LCEDeliveryDestinationType.INTERNATIONAL,
        address: LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON
    } as LCEDeliveryDestinationInternational,
    estimatedDeliveryDate: '2023-08-18',
    note: XS_LOREM_IPSUM.medium,
    deliveredBy: LCE_MOCK_USER_SYSTEM_DHL,
    deliveredOn: '2023-08-18 09:32:00Z',
    updatedBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
    updatedOn: '2023-08-12 15:08:00Z'
};
