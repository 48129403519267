import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcessService} from '../api/services/lce-certificate-order-batch-process.service';

@Component({
	selector: 'lce-certificate-order-batch-processes-state',
	template: `
        <xs-square-data
                [styleClass]="styleClass"
                [bottomText]="TR_BOTTOM_TEXT"
                [indicatorIcon]="ICON.inProgress"
                [indicator]="true"
                [showBorder]="false"
                [error]="error"
                indicatorStyleClass="xs-font-size-medium-imp xs-top-minus-15 xs-right-minus-20"
                indicatorColorStyleClass="xs-color-secondary"
                value="{{ countNonCompletedBatchProcesses }}"
                valueStyleClass="xs-font-size-large-imp"
                valueContainerStyleClass="">
        </xs-square-data>
	`
})
export class LCECertificateOrderBatchProcessesStateComponent implements OnInit, OnDestroy {
	readonly ICON = LCE_SHARED_ICON;

	readonly TR_BOTTOM_TEXT = 'lce.shared.certificateOrders.certificateOrderBatchProcess.nonCompletedBatchProcess';

	@Input() styleClass?: string;

	@Input() facilityCode: string;

	countNonCompletedBatchProcesses: number;

	error: any;

	private subscription = new Subscription();

	constructor(private batchProcessService: LCECertificateOrderBatchProcessService) {
	}

	ngOnInit() {
		this.update();
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	public update(): void {
		this.countNonCompletedBatchProcesses = undefined!;
		this.error = undefined;
		this.subscription.add(
			this.batchProcessService.countNonCompleted(this.facilityCode)
				.subscribe({
					next: resultCount => this.countNonCompletedBatchProcesses = resultCount.total,
					error: error => this.error = error
				})
		);
	}
}
