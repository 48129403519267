import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {
    LOG,
    XS_STORAGE_KEY_TOKEN,
    XS_STORAGE_KEY_TOKEN_EXPIRATION,
    XS_STORAGE_KEY_USER,
    XSAssert,
    XSAuthenticationInitialData,
    XSAuthenticationRequest,
    XSAuthenticationResponse,
    XSAuthenticationResponseWrapper,
    XSHttpClientService,
    XSTemporalUtils,
    XSUserPartial,
    XSUtils
} from '@xs/base';
import {XSCommonContextService} from '@xs/common';
import {Observable, of, Subject} from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';

export abstract class XSAuthenticationService<T extends XSUserPartial> {

    private loginSuccessSubject = new Subject<XSAuthenticationResponseWrapper<T>>();
    onLoginSuccess = this.loginSuccessSubject.asObservable();

    private loginFailedSubject = new Subject<XSAuthenticationResponseWrapper<T>>();
    onLoginFailed = this.loginFailedSubject.asObservable();

    private logoutSubject = new Subject<string>();
    onLogout = this.logoutSubject.asObservable();

    private fetchInitialDataSubject = new Subject<XSAuthenticationInitialData<T>>();
    onFetchInitialData = this.fetchInitialDataSubject.asObservable();

    private logoutTimeout: any;

    protected constructor(
        protected router: Router,
        protected httpClientService: XSHttpClientService,
        protected contextService: XSCommonContextService,
        protected endpointLogin: string,
        protected endpointLogout: string,
        protected endpointInitialization: string) {
    }

    public async initialize(): Promise<void> {
        const token = await this.getToken();
        if (XSUtils.isEmpty(token)) {
            return;
        }
        this.httpClientService.setHeaderAuthorization(token!);
        const initialData: XSAuthenticationInitialData<T> = await this.httpClientService.get<XSAuthenticationInitialData<T>>(this.endpointInitialization).toPromise();
        this.handleInitialData(token!, initialData);
    }

    public async clear(): Promise<void> {
        this.httpClientService.removeHeaderAuthorization();
        await this.storageRemove(XS_STORAGE_KEY_TOKEN);
        await this.storageRemove(XS_STORAGE_KEY_TOKEN_EXPIRATION);
        LOG().debug('Authentication Cleared !');
    }

    public async check(): Promise<void> {
        const authenticated = await this.isAuthenticated();
        const token = await this.getToken();
        if (!authenticated && !XSUtils.isEmpty(token)) await this.logout();
    }

    public async isAuthenticated(): Promise<boolean> {
        const token = await this.getToken();
        const tokenExpirationDate = await this.getTokenExpirationDate();
        if (XSUtils.isEmpty(token) || XSUtils.isNull(tokenExpirationDate)) return false;
        return tokenExpirationDate! > new Date();
    }

    public async logout(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            LOG().debug('Logout ...');
            const authenticated = await this.isAuthenticated();
            if (!authenticated) {
                resolve();
                return;
            }
            this.httpClientService.get<void>(this.endpointLogout).subscribe({
                next: async () => {
                    if (!XSUtils.isNull(this.logoutTimeout)) clearTimeout(this.logoutTimeout);
                    await this.clear();
                    this.logoutSubject.next(new Date().toISOString());
                    resolve();
                },
                error: error => {
                    reject(error);
                    throw error;
                }
            });
        });
    }

    public login(authenticationRequest: XSAuthenticationRequest): Observable<XSAuthenticationResponseWrapper<T>> {
        XSAssert.notEmpty(authenticationRequest, 'authenticationRequest');
        return this.httpClientService.post<XSAuthenticationResponse<T>>(this.endpointLogin, authenticationRequest)
            .pipe(
                mergeMap(async response => {
                    this.httpClientService.setHeaderAuthorization(response.token);
                    await this.storageSetValue(XS_STORAGE_KEY_TOKEN, response.token);
                    await this.storageSetValue(XS_STORAGE_KEY_TOKEN_EXPIRATION, response.tokenExpirationDate);
                    await this.storageSetValue(XS_STORAGE_KEY_USER, response.initialData.authenticatedUser);

                    this.installLogoutTimer(new Date(response.tokenExpirationDate));
                    const responseWrapper: XSAuthenticationResponseWrapper<T> = {authenticated: true, response: response};
                    this.handleInitialData(response.token, response.initialData);
                    this.loginSuccessSubject.next(responseWrapper);
                    return responseWrapper;
                }),
                catchError((error: HttpErrorResponse) => {
                    const responseWrapper: XSAuthenticationResponseWrapper<T> = {authenticated: false, error: error};
                    this.loginFailedSubject.next(responseWrapper);
                    return of(responseWrapper);
                })
            );
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    protected abstract storageRemove(key: string): Promise<void>;

    protected abstract storageSetValue(key: string, value: any): Promise<void> ;

    protected abstract storageGetValue(key: string): Promise<string | undefined> ;

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    protected handleInitialData(token: string, initialData: XSAuthenticationInitialData<T>): void {
        if (XSUtils.isEmpty(initialData.authenticatedUser)) {
            throw new Error('initialData.authenticatedUser is not supposed to be null.');
        }
        this.contextService.setUser(initialData.authenticatedUser!);
        this.fetchInitialDataSubject.next(initialData);
    }

    protected async getToken(): Promise<string | undefined> {
        return await this.storageGetValue(XS_STORAGE_KEY_TOKEN);
    }

    protected async getTokenExpirationDate(): Promise<Date | undefined> {
        const tokenExpirationDateStr = await this.storageGetValue(XS_STORAGE_KEY_TOKEN_EXPIRATION);
        return Promise.resolve(XSUtils.isEmpty(tokenExpirationDateStr) ? undefined : new Date(tokenExpirationDateStr!));
    }

    protected installLogoutTimer(tokenExpirationDate: Date): void {
        if (!XSUtils.isNull(this.logoutTimeout)) clearTimeout(this.logoutTimeout);
        const diff = Math.round(XSTemporalUtils.computeDiffInSeconds(new Date(), tokenExpirationDate) - 30);
        LOG().debug('Installing Token Expiration Date Timer [' + tokenExpirationDate.toString() + '] - Diff: ' + diff + ' Seconds');
        this.logoutTimeout = setTimeout(async () => {
            LOG().info('Your session is expired [tokenExpirationDate: ' + tokenExpirationDate.toISOString() + ']. You will be logged out.');
            await this.logout();
        }, diff * 1000);
    }
}
