import {Component, Input, OnInit} from '@angular/core';
import {LCEUserPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lce-data-field-user-resource-audit-inline',
    template: `
        <div class="xs-data-field {{styleClass}}">
            <xs-data-field-label [label]="label"></xs-data-field-label>
            <div class="xs-data-field-value-container">
                <lce-user-resource-audit-inline
                        [styleClass]="valueStyleClass"
                        [disabled]="disabled"
                        [by]="by"
                        [on]="on"
                        [onPrefix]="onPrefix"
                        [oneLine]="oneLine"
                        [viewDetail]="viewDetail"
                        [showCalendarIcon]="showCalendarIcon"
                        [showViewRecordButton]="showViewRecordButton">
                </lce-user-resource-audit-inline>
                <span *ngIf="(required !== true) && (on | xsIsEmpty)" class="xs-data-field-empty-value {{ valueStyleClass }}">{{ emptyValue! | translate }}</span>
            </div>
        </div>
    `
})
export class LCEDataFieldUserResourceAuditInlineComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() valueStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() label?: string;

    @Input() required?: boolean;
    @Input() emptyValue?: string;

    @Input() on?: string;
    @Input() by?: LCEUserPartial;

    @Input() onPrefix?: string;

    @Input() oneLine?: boolean;

    @Input() showCalendarIcon?: boolean;

    @Input() viewDetail?: boolean;
    @Input() showViewRecordButton?: boolean;

    ngOnInit() {
        if (this.required === true) {
            XSAssert.notEmpty(this.on, 'on');
        }
        if (XSUtils.isNull(this.emptyValue)) this.emptyValue = '---';
    }
}
