import {Injectable} from '@angular/core';
import {XSAssert, XSPKResourceService} from '@xs/base';
import {
    LCECertificateOrderDeliveryAssignment,
    LCECertificateOrderDeliveryAssignmentPartial,
    LCECertificateOrderDeliveryAssignmentRequest,
    LCECertificateOrderDeliveryAssignmentResponse,
    LCECertificateOrderDeliveryAssignmentSearch,
    LCECertificateOrderDeliveryUnassignmentRequest
} from '../../../../domain/certificate/order/lce-certificate-order-delivery-assignment';
import {LCE_CORE_ENDPOINTS} from '../../../../constants/lce-core-endpoint.constant';
import {LCEHttpClientService} from '../../../lce-http-client.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderDeliveryAssignmentService extends XSPKResourceService<LCECertificateOrderDeliveryAssignment, LCECertificateOrderDeliveryAssignmentPartial, LCECertificateOrderDeliveryAssignmentSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.assignments.index, 'assignment');
    }

    public unassign(request: LCECertificateOrderDeliveryUnassignmentRequest[]): Observable<LCECertificateOrderDeliveryAssignmentResponse[]> {
        XSAssert.notEmpty(request, 'request');
        return this.httpClientService.post<LCECertificateOrderDeliveryAssignmentResponse[]>(LCE_CORE_ENDPOINTS.assignments.unassign, request);
    }

    public assign(assignmentRequests: LCECertificateOrderDeliveryAssignmentRequest[]): Observable<LCECertificateOrderDeliveryAssignmentResponse[]> {
        XSAssert.notEmpty(assignmentRequests, 'assignmentRequest');
        return this.httpClientService.post<LCECertificateOrderDeliveryAssignmentResponse[]>(LCE_CORE_ENDPOINTS.assignments.assign, assignmentRequests);
    }

    public unassignOne(request: LCECertificateOrderDeliveryUnassignmentRequest): Observable<LCECertificateOrderDeliveryAssignmentResponse> {
        XSAssert.notEmpty(request, 'request');
        return this.httpClientService.post<LCECertificateOrderDeliveryAssignmentResponse>(LCE_CORE_ENDPOINTS.assignments.unassignOne, request);
    }

    public assignOne(assignmentRequest: LCECertificateOrderDeliveryAssignmentRequest): Observable<LCECertificateOrderDeliveryAssignmentResponse> {
        XSAssert.notEmpty(assignmentRequest, 'assignmentRequest');
        return this.httpClientService.post<LCECertificateOrderDeliveryAssignmentResponse>(LCE_CORE_ENDPOINTS.assignments.assignOne, assignmentRequest);
    }
}
