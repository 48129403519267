import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {
    LCECertificateOrderCreate,
    LCEDeliveryDestination,
    LCEDeliveryDestinationCustomLocation,
    LCEDeliveryDestinationFacility,
    LCEDeliveryDestinationInternational,
    LCEDeliveryDestinationServicePoint,
    LCEDeliveryDestinationType,
    LCEFacilityPartial,
    LCEFacilityService,
    LCEServicePointPartial,
    LCEServicePointService,
    LCEShippingInternationalProvider
} from '@lce/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    XSInputFieldAddressOptions,
    XSInputFieldAutoCompleteOptions,
    XSInputFieldCalendarOptions,
    XSInputFieldDropdownOptions,
    XSInputFieldInstantRangeOptions,
    XSInputFieldMapLocationOptions,
    XSInputFieldTextAreaOptions
} from '@xs/core';
import {XS_STR_EMPTY, XS_STR_SPACE, XSAddressStructured, XSAddressType, XSInstantRange, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_YYYY_MM_DD_HH_MM, XSLabelValue} from '@xs/common';

@Component({selector: 'lce-certificate-order-create-delivery', templateUrl: './lce-certificate-order-create-delivery.component.html'})
export class LCECertificateOrderCreateDeliveryComponent implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.create.delivery.';
    readonly TR_BASE_DESTINATION_TYPE: string = 'lce.core.deliveryDestinationType.';

    @Input() styleClass?: string;

    @Input() canUseDeliveryDate?: boolean;
    @Input() canUseDesiredDeliveryTimeRange?: boolean;
    @Input() canUseNote?: boolean;
    @Input() forceShippingInternationalProvider?: LCEShippingInternationalProvider;

    @Input() certificateOrderCreate?: LCECertificateOrderCreate;

    @Input() formGroup?: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();

    desiredDeliveryDateField: XSInputFieldCalendarOptions;
    desiredDeliveryTimeRangeField: XSInputFieldInstantRangeOptions;
    deliveryNoteField: XSInputFieldTextAreaOptions;
    destinationTypeField: XSInputFieldDropdownOptions;
    deliveryDestinationField: XSInputFieldAutoCompleteOptions | XSInputFieldAddressOptions | XSInputFieldMapLocationOptions;
    shippingInternationalProviderField: XSInputFieldDropdownOptions;
    selectedDestinationType: LCEDeliveryDestinationType;

    appendTo?: any;

    private readonly DESTINATION_TYPES: XSLabelValue[] = [
        {label: this.TR_BASE_DESTINATION_TYPE + LCEDeliveryDestinationType.CUSTOM_LOCATION, value: LCEDeliveryDestinationType.CUSTOM_LOCATION},
        {label: this.TR_BASE_DESTINATION_TYPE + LCEDeliveryDestinationType.FACILITY, value: LCEDeliveryDestinationType.FACILITY},
        {label: this.TR_BASE_DESTINATION_TYPE + LCEDeliveryDestinationType.SERVICE_POINT, value: LCEDeliveryDestinationType.SERVICE_POINT},
        {label: this.TR_BASE_DESTINATION_TYPE + LCEDeliveryDestinationType.INTERNATIONAL, value: LCEDeliveryDestinationType.INTERNATIONAL}
    ];


    private readonly SHIPPING_INTERNATIONAL_PROVIDER: XSLabelValue[] = [
        {label: LCEShippingInternationalProvider.DHL.toUpperCase(), value: LCEShippingInternationalProvider.DHL},
        {label: LCEShippingInternationalProvider.UPS.toUpperCase(), value: LCEShippingInternationalProvider.UPS}
    ];

    constructor(
        private facilityService: LCEFacilityService,
        private servicePointService: LCEServicePointService) {
    }

    ngOnInit(): void {
        this.buildFields();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.certificateOrderCreate) && !changes.certificateOrderCreate!.isFirstChange()) this.update(this.certificateOrderCreate!);
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.desiredDeliveryDateField.control?.value) ||
            !XSUtils.isEmpty(this.desiredDeliveryTimeRangeField.control?.value) ||
            !XSUtils.isEmpty(this.deliveryNoteField.control?.value) ||
            !XSUtils.isEmpty(this.destinationTypeField.control?.value));
    }

    public isFacilityDestination(): boolean {
        return this.selectedDestinationType === LCEDeliveryDestinationType.FACILITY;
    }

    public isServicePointDestination(): boolean {
        return this.selectedDestinationType === LCEDeliveryDestinationType.SERVICE_POINT;
    }

    public isInternationalDestination(): boolean {
        return this.selectedDestinationType === LCEDeliveryDestinationType.INTERNATIONAL;
    }

    public isCustomDestination(): boolean {
        return this.selectedDestinationType === LCEDeliveryDestinationType.CUSTOM_LOCATION;
    }

    public buildDeliveryDestinationField(deliveryType: LCEDeliveryDestinationType, deliveryDestination?: LCEDeliveryDestination): void {
        if (XSUtils.isNull(deliveryType)) return;

        switch (deliveryType) {
            case LCEDeliveryDestinationType.FACILITY:
                let facilityDestination = deliveryDestination as LCEDeliveryDestinationFacility;
                this.buildDeliveryDestinationFacilityField(facilityDestination?.facility as LCEFacilityPartial);
                break;
            case LCEDeliveryDestinationType.SERVICE_POINT:
                let servicePointDestination = deliveryDestination as LCEDeliveryDestinationServicePoint;
                this.buildDeliveryDestinationServicePointField(servicePointDestination?.servicePoint as LCEServicePointPartial);
                break;
            case LCEDeliveryDestinationType.INTERNATIONAL:
                let internationalDestination = deliveryDestination as LCEDeliveryDestinationInternational;
                this.buildDeliveryDestinationInternationalField(internationalDestination?.address, internationalDestination?.shippingProvider);
                break;
            case LCEDeliveryDestinationType.CUSTOM_LOCATION:
                const customLocation: LCEDeliveryDestinationCustomLocation = deliveryDestination as LCEDeliveryDestinationCustomLocation;
                this.buildDeliveryDestinationCustomLocationField(customLocation);
                break;
        }
    }

    private update(certificateOrderCreate: LCECertificateOrderCreate): void {
        let desiredDeliveryDate = this.certificateOrderCreate?.desiredDeliveryDate ? new Date(this.certificateOrderCreate?.desiredDeliveryDate) : XS_STR_EMPTY;
        this.desiredDeliveryDateField.control?.setValue(desiredDeliveryDate);
        this.desiredDeliveryTimeRangeField.control?.setValue(new XSInstantRange(this.certificateOrderCreate?.desiredDeliveryDate + XS_STR_SPACE + certificateOrderCreate.desiredDeliveryTimeRange?.start, this.certificateOrderCreate?.desiredDeliveryDate + XS_STR_SPACE + certificateOrderCreate.desiredDeliveryTimeRange?.end));
        this.deliveryNoteField.control?.setValue(certificateOrderCreate.deliveryNote);
        this.destinationTypeField.control?.setValue(certificateOrderCreate.deliveryDestination.type);
        this.updateDestination(certificateOrderCreate.deliveryDestination.type, certificateOrderCreate.deliveryDestination);
    }

    private buildFields(): void {
        this.desiredDeliveryDateField = {
            fieldName: 'desiredDeliveryDate',
            calendarOptions: {showIcon: true},
            control: new FormControl(undefined),
            label: this.TR_BASE + 'desiredDeliveryDate'
        };

        this.desiredDeliveryTimeRangeField = {
            options: {
                startPlaceholder: '17:00',
                endPlaceholder: '19:00',
                timeOnly: true,
                dateFormat: XS_DATE_FORMAT_YYYY_MM_DD_HH_MM,
                startOptions: {showIcon: true, monthNavigator: false, yearNavigator: false},
                endOptions: {showIcon: true, monthNavigator: false, yearNavigator: false}
            },
            fieldName: 'desiredDeliveryTimeRange',
            styleClass: 'xs-mt-10',
            control: new FormControl(undefined),
            label: this.TR_BASE + 'desiredDeliveryTimeRange'
        };

        this.deliveryNoteField = {
            fieldName: 'deliveryNote',
            control: new FormControl(undefined),
            label: this.TR_BASE + 'deliveryNote',
            placeholder: this.TR_BASE + 'deliveryNotePlaceholder'
        };

        this.destinationTypeField = {
            fieldName: 'destinationType',
            label: this.TR_BASE + 'destinationType',
            control: new FormControl(undefined, Validators.required),
            items: this.DESTINATION_TYPES,

            placeholder: this.TR_BASE + 'destinationTypePlaceholder',
            valueChange: (event: LCEDeliveryDestinationType) => {
                this.updateDestination(event);
            }
        };
        
        this.formGroup!.addControl(this.desiredDeliveryDateField.fieldName, this.desiredDeliveryDateField.control!);
        this.formGroup!.addControl(this.destinationTypeField.fieldName, this.destinationTypeField.control!);
        if (this.canUseDesiredDeliveryTimeRange) this.formGroup!.addControl(this.desiredDeliveryTimeRangeField.fieldName, this.desiredDeliveryTimeRangeField.control!);
        this.formGroup!.addControl(this.deliveryNoteField.fieldName, this.deliveryNoteField.control!);
    }

    private updateDestination(type: LCEDeliveryDestinationType, destination?: LCEDeliveryDestination): void {
        this.selectedDestinationType = type;
        this.buildDeliveryDestinationField(this.selectedDestinationType, destination);
        if (this.selectedDestinationType !== LCEDeliveryDestinationType.INTERNATIONAL) {
            if (!XSUtils.isEmpty(this.shippingInternationalProviderField)) {
                this.formGroup!.removeControl(this.shippingInternationalProviderField?.fieldName);
            } else return;
        }
    }

    private buildDeliveryDestinationFacilityField(data?: LCEFacilityPartial): void {

        this.deliveryDestinationField = {
            fieldName: 'deliveryDestination',
            autoCompleteOptions: {
                labelField: 'fullName',
                search: (query: string) => {
                    return this.facilityService.autocomplete(query);
                }
            },
            leftIcon: this.ICON.facility,
            leftIconStyleClass: 'xs-color-secondary',
            placeholder: 'lce.shared.facility.label.autocompletePlaceholder',
            control: new FormControl(undefined, Validators.required)
        } as XSInputFieldAutoCompleteOptions;
        if (!XSUtils.isEmpty(data)) {
            this.deliveryDestinationField.control?.setValue(data);
        }
        this.formGroup!.setControl(this.deliveryDestinationField.fieldName, this.deliveryDestinationField.control!);
    }

    private buildDeliveryDestinationServicePointField(data?: LCEServicePointPartial): void {
        this.deliveryDestinationField = {
            fieldName: 'deliveryDestination',
            autoCompleteOptions: {
                labelField: 'fullName',
                search: (query: string) => {
                    return this.servicePointService.autocomplete(query);
                }
            },
            leftIcon: this.ICON.servicePoint,
            leftIconStyleClass: 'xs-color-secondary',
            placeholder: 'lce.shared.servicePoint.label.autocompletePlaceholder',
            control: new FormControl(undefined, Validators.required)
        } as XSInputFieldAutoCompleteOptions;
        if (!XSUtils.isEmpty(data)) {
            this.deliveryDestinationField.control?.setValue(data);
        }
        this.formGroup!.setControl(this.deliveryDestinationField.fieldName, this.deliveryDestinationField.control!);
    }

    private buildDeliveryDestinationInternationalField(address?: XSAddressStructured, shippingProvider?: LCEShippingInternationalProvider): void {
        this.deliveryDestinationField = {
            fieldName: 'deliveryDestination',
            label: this.TR_BASE + 'deliveryAddress',
            control: new FormControl(address, Validators.required),
            addressOptions: {
                formOptions: {
                    type: undefined!,
                    title: true,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.STRUCTURED]
                }
            }
        } as XSInputFieldAddressOptions;
        this.formGroup!.setControl(this.deliveryDestinationField.fieldName, this.deliveryDestinationField.control!);

        this.shippingInternationalProviderField = {
            fieldName: 'shippingInternationalProvider',
            label: this.TR_BASE + 'shippingInternationalProvider',
            control: new FormControl(!XSUtils.isEmpty(this.forceShippingInternationalProvider) ? this.forceShippingInternationalProvider : shippingProvider, Validators.required),
            items: this.SHIPPING_INTERNATIONAL_PROVIDER,
            placeholder: this.TR_BASE + 'shippingInternationalProviderPlaceholder'
        };
        this.formGroup!.addControl(this.shippingInternationalProviderField.fieldName, this.shippingInternationalProviderField.control!);
    }

    private buildDeliveryDestinationCustomLocationField(customLocation?: LCEDeliveryDestinationCustomLocation): void {
        // TODO: this is a temporary hack. To be remove once Ryane & Ham complete the implementation of MAP.
        console.log('|====>', 'customLocation destination', customLocation);
        this.deliveryDestinationField = {
            fieldName: 'deliveryDestination',
            control: new FormControl(customLocation?.address, Validators.required),
            valueChange: (event: any) => {
                console.log('|--- custom destination value', event);
            }
        } as XSInputFieldMapLocationOptions;

        this.formGroup!.setControl(this.deliveryDestinationField.fieldName, this.deliveryDestinationField.control!);

    }
}
