import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {XSAssert, XSGender, XSUtils} from '@xs/base';
import {XSStyleHelper} from '@xs/core';
import {LCEBirthDeclarationPartial} from '@lce/core';

@Component({selector: 'lce-birth-declaration-partial', templateUrl: './lce-birth-declaration-partial.component.html', host: {class: 'xs-width-full'}})
export class LCEBirthDeclarationPartialComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_MAIN: string = 'lce.shared.birthDeclaration.main.';

    @Input() styleClass?: string;
    @Input() birthNumberStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() showIcon?: boolean;

    @Input() data: LCEBirthDeclarationPartial;

    @Input() intermediate?: boolean;

    @Output() clickEvent = new EventEmitter<LCEBirthDeclarationPartial>();

    clickable: boolean;

    iconStyleClass: string;

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.intermediate)) this.intermediate = true;

        this.iconStyleClass = this.data.newBornGender === XSGender.FEMALE ? XSStyleHelper.CLASS.color.pink : XSStyleHelper.CLASS.color.blueLight;

        this.clickable = this.clickEvent.observers.length > 0;
    }
}
