import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECoreContextService,
    LCEUser,
    LCEUserAccountStatus,
    LCEUserForgotPasswordSendCodeResponse,
    LCEUserForgotPasswordVerifyCodeResponse,
    LCEUserForgotPasswordVerifyUsernameResponse,
    LCEUserLocking,
    LCEUserLockRequest,
    LCEUserLockUnlockResponse,
    LCEUserPartial,
    LCEUserResetPasswordResponse,
    LCEUserStatus,
    LCEUserUtils,
    LCEVerificationType
} from '@lce/core';
import {LCEMockUserFinder} from '@lce/mock/core/handlers/user/lce-mock-user-finder';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSLanguage, XSUtils, XSValueResponse} from '@xs/base';
import {XSMockData, XSMockHttpHandler} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.userAccounts';

interface SessionData {
    sessionID: string;
    username: string;
    user: LCEUser;
    verificationCode?: string;
}

@Injectable()
export class LCEMockUserAccountHandler extends XSMockHttpHandler {

    public static SESSION_STORAGE: Map<string, SessionData> = new Map<string, SessionData>();

    constructor(private contextService: LCECoreContextService, private mockUserFinder: LCEMockUserFinder) {
        super();
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    buildMockDataArray(): void {
        this.mockDataArray = [
            this.buildVerifyUsernameMockData(),
            this.buildUpdateForgotPasswordMockData(),
            this.buildVerifyCodeMockData(),
            this.buildSendCodeMockData()
        ];
    }

    public buildUserMockData(userID: string): XSMockData[] {
        return [
            this.buildLockMockData(userID),
            this.buildUnlockMockData(userID),
            this.buildUpdatePasswordMockData(userID),
            this.buildResetPasswordMockData(userID)
        ];
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Forgot Password ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    private buildUpdateForgotPasswordMockData(): XSMockData {
        return {
            id: DATASET_BASE_ID + '.verifyCode',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${LCE_CORE_ENDPOINTS.userAccounts.verifyCode}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const sessionID: string = rArg.params!.get('sessionID')!;
                const newPassword: string = rArg.params!.get('newPassword')!;

                const sessionData = LCEMockUserAccountHandler.SESSION_STORAGE.get(sessionID)!;
                if (XSUtils.isNull(sessionData)) {
                    throw new Error(`[verifyCode] sessionID data not found [${sessionID}]`);
                }
                const user = LCEUserUtils.toUserPartial(sessionData.user);
                sessionData.user.updatedOn = new Date().toISOString();
                sessionData.user.updatedBy = user;
            }
        };
    }

    private buildVerifyCodeMockData(): XSMockData {
        return {
            id: DATASET_BASE_ID + '.verifyCode',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${LCE_CORE_ENDPOINTS.userAccounts.verifyCode}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const sessionID: string = rArg.params!.get('sessionID')!;
                const verificationCode: string = rArg.params!.get('code')!;

                const sessionData = LCEMockUserAccountHandler.SESSION_STORAGE.get(sessionID)!;
                if (XSUtils.isNull(sessionData)) {
                    throw new Error(`[verifyCode] sessionID data not found [${sessionID}]`);
                }
                if (sessionData.verificationCode !== verificationCode) {
                    throw new Error(`[verifyCode] invalid code entered.`);
                }

                return {
                    verifiedOn: new Date().toISOString(),
                    user: LCEUserUtils.toUserPartial(sessionData.user)
                } as LCEUserForgotPasswordVerifyCodeResponse;
            }
        };
    }

    private buildSendCodeMockData(): XSMockData {
        return {
            id: DATASET_BASE_ID + '.sendCode',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${LCE_CORE_ENDPOINTS.userAccounts.verifyUsername}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const sessionID: string = rArg.params!.get('sessionID')!;
                const sessionData = LCEMockUserAccountHandler.SESSION_STORAGE.get(sessionID)!;
                if (XSUtils.isNull(sessionData)) {
                    throw new Error(`[sendCode] sessionID data not found [${sessionID}]`);
                }

                const verificationType: LCEVerificationType = XSUtils.toEnum(rArg.params!.get('verificationType')!, LCEVerificationType)!;
                const language: XSLanguage = XSUtils.toEnum(rArg.params!.get('language')!, XSLanguage)!;
                sessionData.verificationCode = XSUtils.randomDigits(4);

                return {
                    sessionID: sessionID,
                    username: sessionData.username,
                    sentOn: new Date().toISOString(),
                    recipient: LCEVerificationType.SMS === verificationType ? sessionData.user.primaryPhoneNumber : sessionData.user.email,
                    verificationType: verificationType,
                    language: language
                } as LCEUserForgotPasswordSendCodeResponse;
            }
        };
    }

    private buildVerifyUsernameMockData(): XSMockData {
        return {
            id: DATASET_BASE_ID + '.verifyUsername',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${LCE_CORE_ENDPOINTS.userAccounts.verifyUsername}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                //const userType: LCEUserType = XSUtils.toEnum(rArg.params!.get('userType'), LCEUserType);
                const username: string = rArg.params!.get('username')!;
                const user = this.mockUserFinder.findOneByUsername(username);
                if (XSUtils.isEmpty(user)) {
                    throw new Error(`unable to find the given username [${username}]`);
                }
                const sessionID = XSUtils.uuid();
                LCEMockUserAccountHandler.SESSION_STORAGE.set(sessionID, {
                    sessionID: sessionID,
                    username: username,
                    user: user!
                });
                return {
                    sessionID: XSUtils.uuid(),
                    username: username
                } as LCEUserForgotPasswordVerifyUsernameResponse;
            }
        };
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Update Password ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    private buildResetPasswordMockData(userID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.resetPassword.' + userID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${userID}/${LCE_CORE_ENDPOINTS.userAccounts.resetPassword}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: () => {
                const user: LCEUser = this.mockUserFinder.findOneByID(userID);

                return {
                    id: user.id,
                    code: user.code,
                    passwordResetOn: new Date().toISOString(),
                    passwordResetBy: this.getAuthenticatedUser()
                } as LCEUserResetPasswordResponse;
            }
        };
    }

    private buildUpdatePasswordMockData(userID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.updatePassword.' + userID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${userID}/${LCE_CORE_ENDPOINTS.userAccounts.updatePassword}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: () => {
                return {value: new Date().toISOString()} as XSValueResponse<string>;
            }
        };
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Lock / Unlock ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    private buildUnlockMockData(userID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.unlock.' + userID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${userID}/${LCE_CORE_ENDPOINTS.userAccounts.unlock}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: () => {
                const user: LCEUser = this.mockUserFinder.findOneByID(userID);
                if (XSUtils.isNull(user.locking)) {
                    throw new Error('user.locking is not supposed to be null.');
                }
                user.locking!.locked = false;
                user.locking!.unlockedOn = new Date().toISOString();
                user.locking!.unlockedBy = this.getAuthenticatedUser();
                const now = new Date().toISOString();
                return {
                    status: LCEUserStatus.ONLINE,
                    accountStatus: LCEUserAccountStatus.ACTIVE,
                    accountStatusUpdatedOn: now,
                    accountStatusUpdatedBy: this.contextService.getUser(),
                    locking: user.locking,
                    updatedOn: now,
                    updatedBy: this.contextService.getUser()
                } as LCEUserLockUnlockResponse;
            }
        };
    }

    private buildLockMockData(userID: string, requestDelay: number = 3000): XSMockData {
        return {
            id: DATASET_BASE_ID + '.lock.' + userID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.userAccounts.index}/${userID}/${LCE_CORE_ENDPOINTS.userAccounts.lock}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: requestDelay,
            getResponseData: rArg => {
                const user: LCEUser = this.mockUserFinder.findOneByID(userID);
                const lockRequest: LCEUserLockRequest = rArg.body as LCEUserLockRequest;
                const userLocking: LCEUserLocking = {
                    locked: true,
                    reason: lockRequest.reason,
                    until: lockRequest.until,
                    lockedOn: new Date().toISOString(),
                    lockedBy: this.getAuthenticatedUser()
                };
                user.locking = userLocking;
                const now = new Date().toISOString();
                return {
                    status: LCEUserStatus.OFFLINE,
                    accountStatus: LCEUserAccountStatus.LOCKED,
                    accountStatusUpdatedOn: now,
                    accountStatusUpdatedBy: this.contextService.getUser(),
                    locking: user.locking,
                    updatedOn: now,
                    updatedBy: this.contextService.getUser()
                } as LCEUserLockUnlockResponse;
            }
        };
    }
}