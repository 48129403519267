import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECoreContextService,
    LCEUserMunicipalEmployee,
    LCEUserMunicipalEmployeeCreate,
    LCEUserMunicipalEmployeeMainRole,
    LCEUserMunicipalEmployeePartial,
    LCEUserMunicipalEmployeePosition,
    LCEUserMunicipalEmployeeSearch,
    LCEUserMunicipalEmployeeUpdatePINCodeResponse,
    LCEUserType
} from '@lce/core';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUserAccountHandler} from '@lce/mock/core/handlers/user/lce-mock-user-account-handler';
import {LCEMockUserFinder} from '@lce/mock/core/handlers/user/lce-mock-user-finder';
import {LCEMockUserHandler} from '@lce/mock/core/handlers/user/lce-mock-user-handler';
import {LCE_MBO_MOCK_USER_RECORD_ELON_MUSK, LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS, LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY} from '@lce/mock/core/lce-mock-user-data';
import {LCE_MOCK_CONTACT_PERSON_JANE_DOE, LCE_MOCK_CONTACT_PERSON_JOHN_DOE} from '@lce/mock/core/lce-mock-various-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSPKDTOStats, XSStringResponse, XSUtils} from '@xs/base';
import {XSMockData} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.employees';

@Injectable()
export class LCEMockUserMunicipalEmployeeHandler extends LCEMockUserHandler<LCEUserMunicipalEmployee, LCEUserMunicipalEmployeePartial, LCEUserMunicipalEmployeeSearch> {

    public static MUNICIPAL_EMPLOYEE_STORAGE: Map<string, LCEUserMunicipalEmployee> = new Map<string, LCEUserMunicipalEmployee>();

    constructor(
        protected contextService: LCECoreContextService,
        protected mockMunicipalityHandler: LCEMockMunicipalityHandler,
        protected mockUserAccountHandler: LCEMockUserAccountHandler,
        private mockFacilityHandler: LCEMockFacilityHandler) {
        super(contextService, mockMunicipalityHandler, mockUserAccountHandler, DATASET_BASE_ID, LCE_CORE_ENDPOINTS.municipalEmployees.index);
        this.mockDataArray = [];
        LCEMockUserFinder.USER_HANDLER_MAP.set(LCEUserType.MUNICIPAL_EMPLOYEE, this);
    }

    public getStorage(): Map<string, LCEUserMunicipalEmployee> {
        return LCEMockUserMunicipalEmployeeHandler.MUNICIPAL_EMPLOYEE_STORAGE;
    }

    public toPartial(municipalEmployee: LCEUserMunicipalEmployee): LCEUserMunicipalEmployeePartial {
        return {
            ...this.baseToPartial(municipalEmployee),
            mainRole: municipalEmployee.mainRole,
            position: municipalEmployee.position,
            facilityCode: municipalEmployee.facility.code,
            municipalityCode: municipalEmployee.facility.municipality.code
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.municipalEmployees.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.getQueryPredicates()}),
            // Search
            this.buildSearchMockData({
                queryPredicates: this.getQueryPredicates(),
                predicates: this.getSearchPredicates()
            }),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: XSUtils.randomInteger(0, 100),
                    active: XSUtils.randomInteger(0, 60),
                    inactive: XSUtils.randomInteger(0, 10),
                    deleted: XSUtils.randomInteger(0, 5)
                } as XSPKDTOStats
            }
        ];
    }

    protected buildCode(): string {
        return 'LCE-MEM-' + XSUtils.randomDigits(6);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === PIN Code ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    private buildRetrievePINCodePlainText(userID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.pinCodePlaintText.' + userID,
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: `${LCE_CORE_ENDPOINTS.municipalEmployees.index}/${userID}/${LCE_CORE_ENDPOINTS.municipalEmployees.pinCodePlaintText}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                return {value: XSUtils.randomDigits(4)} as XSStringResponse;
            }
        };
    }

    private buildGeneratePINCodeMockData(municipalEmployee: LCEUserMunicipalEmployee): XSMockData {
        return {
            id: DATASET_BASE_ID + '.pinCodeGenerate.' + municipalEmployee.id,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.municipalEmployees.index}/${municipalEmployee.id}/${LCE_CORE_ENDPOINTS.municipalEmployees.pinCodeGenerate}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const now = new Date().toISOString();
                const by = this.getAuthenticatedUser();
                municipalEmployee.pinCodeUpdatedOn = now;
                municipalEmployee.pinCodeUpdatedBy = by;
                municipalEmployee.updatedOn = now;
                municipalEmployee.updatedBy = by;
                return {pinCodeUpdatedOn: now, pinCodeUpdatedBy: by} as LCEUserMunicipalEmployeeUpdatePINCodeResponse;
            }
        };
    }

    private buildUpdatePINCodeMockData(municipalEmployee: LCEUserMunicipalEmployee): XSMockData {
        return {
            id: DATASET_BASE_ID + '.pinCodeUpdate.' + municipalEmployee.id,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.municipalEmployees.index}/${municipalEmployee.id}/${LCE_CORE_ENDPOINTS.municipalEmployees.pinCodeUpdate}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const now = new Date().toISOString();
                const by = this.getAuthenticatedUser();
                municipalEmployee.pinCodeUpdatedOn = now;
                municipalEmployee.pinCodeUpdatedBy = by;
                municipalEmployee.updatedOn = now;
                municipalEmployee.updatedBy = by;
                return {pinCodeUpdatedOn: now, pinCodeUpdatedBy: by} as LCEUserMunicipalEmployeeUpdatePINCodeResponse;
            }
        };
    }

    private addMunicipalEmployeeMockData(municipalEmployee: LCEUserMunicipalEmployee): void {
        this.addUserMockData(municipalEmployee.id);
        this.mockDataArray.push(this.buildRetrievePINCodePlainText(municipalEmployee.id));
        this.mockDataArray.push(this.buildGeneratePINCodeMockData(municipalEmployee));
        this.mockDataArray.push(this.buildUpdatePINCodeMockData(municipalEmployee));
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    private createResponseData(body: any): LCEUserMunicipalEmployee {
        const municipalEmployeeCreate: LCEUserMunicipalEmployeeCreate = body as LCEUserMunicipalEmployeeCreate;
        const municipalEmployee: LCEUserMunicipalEmployee = {
            ...this.baseCreate(municipalEmployeeCreate),
            type: LCEUserType.MUNICIPAL_EMPLOYEE,
            mainRole: municipalEmployeeCreate.mainRole,
            position: municipalEmployeeCreate.position,
            facility: this.mockFacilityHandler.findOneByCodeAsPartial(municipalEmployeeCreate.facilityCode),
            emergencyContactPerson: municipalEmployeeCreate.emergencyContactPerson
        };

        LCEMockUserMunicipalEmployeeHandler.MUNICIPAL_EMPLOYEE_STORAGE.set(municipalEmployee.id, municipalEmployee);
        this.addMunicipalEmployeeMockData(municipalEmployee);

        return municipalEmployee;
    }

    private buildStorage(n: number = 5): void {
        const predefinedMunicipalEmployees = [
            LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY,
            LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS,
            LCE_MBO_MOCK_USER_RECORD_ELON_MUSK
        ];
        predefinedMunicipalEmployees.forEach(predefinedMunicipalEmployee => {
            LCEMockUserMunicipalEmployeeHandler.MUNICIPAL_EMPLOYEE_STORAGE.set(predefinedMunicipalEmployee.id, predefinedMunicipalEmployee);
            this.addMunicipalEmployeeMockData(predefinedMunicipalEmployee);
        });
        for (let $i = 0; $i < n; $i++) {
            const mEmployee = this.buildRandomMunicipalEmployee();
            LCEMockUserMunicipalEmployeeHandler.MUNICIPAL_EMPLOYEE_STORAGE.set(mEmployee.id, mEmployee);
            this.addMunicipalEmployeeMockData(mEmployee);
        }
    }

    private buildRandomMunicipalEmployee(): LCEUserMunicipalEmployee {
        return {
            ...this.baseRandomUser(),
            type: LCEUserType.MUNICIPAL_EMPLOYEE,
            mainRole: XSUtils.randomElement([
                LCEUserMunicipalEmployeeMainRole.SUPER_ADMIN,
                LCEUserMunicipalEmployeeMainRole.ADMIN,
                LCEUserMunicipalEmployeeMainRole.MANAGER,
                LCEUserMunicipalEmployeeMainRole.AGENT
            ]),
            position: XSUtils.randomElement([
                LCEUserMunicipalEmployeePosition.SYSTEM_ADMINISTRATOR,
                LCEUserMunicipalEmployeePosition.SYSTEM_MAINTAINER,
                LCEUserMunicipalEmployeePosition.MAYOR,
                LCEUserMunicipalEmployeePosition.DEPUTY_MAYOR,
                LCEUserMunicipalEmployeePosition.DSA,
                LCEUserMunicipalEmployeePosition.DSI,
                LCEUserMunicipalEmployeePosition.ADMINISTRATIVE_AGENT,
                LCEUserMunicipalEmployeePosition.IT_AGENT,
                LCEUserMunicipalEmployeePosition.MUNICIPAL_EMPLOYEE,
                LCEUserMunicipalEmployeePosition.GUEST,
                LCEUserMunicipalEmployeePosition.UNKNOWN
            ]),
            facility: this.mockFacilityHandler.getYopougonTownHall(),
            emergencyContactPerson: XSUtils.randomElement([LCE_MOCK_CONTACT_PERSON_JANE_DOE, LCE_MOCK_CONTACT_PERSON_JOHN_DOE])
        };
    }
}
