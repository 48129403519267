import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {LCECertificateOrderDeliveryOperationRequest, LCECertificateOrderDeliveryUnassignmentRequest} from '@lce/core';
import {XSConfirmation} from '@xs/core';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-delivery-unassignment-request',
    templateUrl: './lce-delivery-unassignment-request.component.html'
})
export class LCEDeliveryUnassignmentRequestComponent implements OnInit, OnChanges {

    readonly TR_BASE = 'lce.shared.delivery.request.unassignment.';

    readonly LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() loading?: boolean;

    @Input() disabled?: boolean;

    @Input() error: any;

    @Input() showOperation?: boolean;

    @Input() orderID: string;

    @Output() unassignEvent = new EventEmitter<LCECertificateOrderDeliveryUnassignmentRequest>();
    @Output() cancelEvent = new EventEmitter<void>();


    unassignmentConfirmation: XSConfirmation = {
        key: 'assignmentConfirmationKey',
        trMessage: 'lce.shared.delivery.request.confirmation.unassignment',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => this.unassign()
    };

    notifyDeliveryMan: boolean;

    unassignmentRequest: LCECertificateOrderDeliveryUnassignmentRequest;

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.orderID, 'orderID');

        if (XSUtils.isEmpty(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isEmpty(this.subtitle)) this.subtitle = this.TR_BASE + 'subtitle';
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.loading)) {
            if (this.loading === true) this.loaderService.startLoader(this.LOADER_ID);
            else this.loaderService.stopLoader(this.LOADER_ID);
        }
    }

    public onCancel(): void {
        this.cancelEvent.emit();
    }

    public handleRequestOperation(request: LCECertificateOrderDeliveryOperationRequest): void {
        this.unassignmentRequest = {
            ...this.unassignmentRequest,
            ...request
        };
    }

    public handleNotifierDeliveryMan(event: boolean): void {
        this.unassignmentRequest = {
            ...this.unassignmentRequest,
            notifyDeliveryMan: event
        };
    }

    public unassign(): void {
        this.unassignEvent.emit(this.unassignmentRequest);
    }

}