<div class="xs-flex-column xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-flex-column-center">
        <lce-user-detail-head [data]="getUserPartial()"></lce-user-detail-head>
    </div>

    <div class="xs-position-relative xs-flex-column xs-width-full xs-min-height-400 xs-height-full xs-mt-50">
        <div class="xs-absolute-center-vh">
            <xs-loader #userRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="!userRecordLoader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full">
            <xs-error [actionButton]="errorRetryButton" [error]="error"
                      [showBorder]="false" [showErrorDetailButton]="true" message="{{ TR_BASE_LABEL }}errorRetrieveUser"
                      mode="block" subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>
        <div *ngIf="canDisplayData()" class="xs-flex-column xs-height-full">
            <p-tabView #pTabView [scrollable]="navScrollable" class="xs-tabview"
                       styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                <p-tabPanel header="{{ 'lce.shared.user.label.general' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-municipal-employee-record-general
                                (editEvent)="editEvent.emit(user)"
                                (purgeEvent)="purgeEvent.emit($event)"
                                [(data)]="user!"
                                [readonly]="readonly">
                        </lce-user-municipal-employee-record-general>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.user.label.authentication' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-municipal-employee-record-authentication [data]="user!" [readonly]="readonly">
                        </lce-user-municipal-employee-record-authentication>
                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="true" header="{{ 'lce.shared.user.label.authorization' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-municipal-employee-record-authorization [readonly]="readonly" [userRecord]="user!">
                        </lce-user-municipal-employee-record-authorization>
                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="true" header="{{ 'lce.shared.user.label.settings' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-municipal-employee-record-settings [readonly]="readonly" [userRecord]="user!">
                        </lce-user-municipal-employee-record-settings>
                    </div>
                </p-tabPanel>
                <p-tabPanel [disabled]="true" header="{{ 'lce.shared.user.label.statistics' | translate }}">
                    <div class="xs-flex-column">
                        <lce-user-municipal-employee-record-statistics [readonly]="readonly" [userRecord]="user!">
                        </lce-user-municipal-employee-record-statistics>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-ivar [ellipsis]="true" [icon]="ICON.user" [line1]="headerTitle" [line2]="headerSubTitle"
             styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>
