<div class="xs-position-relative xs-flex-column xs-min-width-1100 xs-width-full xs-pt-40 {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="!loader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showBorder]="false"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorRetrieveUser"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div *ngIf="canDisplayData()" class="xs-flex-column xs-width-full xs-height-full">
        <div class="xs-flex-row-center">
            <lce-user-detail-head [data]="getUserPartial()"></lce-user-detail-head>
        </div>

        <div class="xs-flex-column xs-height-full xs-mt-50">
            <div class="xs-height-full">
                <p-tabView #pTabView [(activeIndex)]="activeIndex" [scrollable]="navScrollable" class="xs-tabview" styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>{{ 'lce.shared.user.label.profile' | translate }}</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <lce-user-account-profile (editEvent)="editEvent.emit($event)" [data]="user" [options]="options" countryISO='ci' styleClass="xs-max-width-1000"></lce-user-account-profile>
                        </ng-template>

                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>{{ 'lce.shared.user.label.authentication' | translate }}</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <lce-user-account-authentication [data]="user"></lce-user-account-authentication>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">
                            <span>{{ 'lce.shared.user.label.settings' | translate }}</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <lce-user-account-settings></lce-user-account-settings>
                        </ng-template>
                    </p-tabPanel>
                    <p-tabPanel [disabled]="true">
                        <ng-template pTemplate="header">
                            <span>{{ 'lce.shared.user.label.statistics' | translate }}</span>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <lce-user-account-statistics></lce-user-account-statistics>
                        </ng-template>

                    </p-tabPanel>

                </p-tabView>
            </div>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.user"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
