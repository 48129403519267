<div [ngClass]="{ 'xs-disabled': disabled === true}" class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <div class="xs-flex-row">
        <lce-user-partial [data]="internalData.createdBy" [showOnlyAvatar]="true"></lce-user-partial>
        <lce-user-partial
                *ngIf="updated && !equalCreatedByAndUpdatedBy"
                [data]="internalData.updatedBy"
                [showOnlyAvatar]="true"
                class="xs-ml-minus-13">
        </lce-user-partial>
    </div>

    <div class="xs-flex-column xs-ml-10 xs-font-size-intermediate xs-color-secondary">
        <span class="xs-display-block ">
            <span>{{ 'xs.core.audit.createdBy' | translate }} </span>
            <a (click)="userDetailCreatedByOverlay.toggle($event)" [innerHTML]="getName(internalData.createdBy?.name) | translate" class="xs-clickable"></a>
            <span> {{ 'xs.core.audit.on' | translate }} </span>
            <span [innerHTML]="internalData.createdOn | xsDate: dateFormat" class="xs-font-weight-600"></span>

            <p-overlayPanel #userDetailCreatedByOverlay [appendTo]="'body'">
                <ng-template pTemplate>
                    <lce-user-detail
                            [data]="internalData.createdBy"
                            [showViewRecordButton]="hasViewRecordPermission() && showViewRecordButton === true">
                    </lce-user-detail>
                </ng-template>
            </p-overlayPanel>
        </span>
        <span *ngIf="updated" class="xs-display-block xs-mt-5">
            <ng-container *ngIf="equalCreatedByAndUpdatedBy">
                <span> {{ 'xs.core.audit.updatedOn' | translate }} </span>
                <span class="xs-font-weight-600">{{ internalData.updatedOn | xsDate: dateFormat }}</span>
            </ng-container>
        
            <ng-container *ngIf="!equalCreatedByAndUpdatedBy">
                <span>{{ 'xs.core.audit.updatedBy' | translate }} </span>
                <a
                        (click)="userDetailUpdatedByOverlay.toggle($event)"
                        [innerHTML]="getName(internalData.updatedBy?.name) | translate" class="xs-clickable">
                </a>

                <span> {{ 'xs.core.audit.on' | translate }} </span>
                <span class="xs-font-weight-600">{{ internalData.updatedOn | xsDate: dateFormat }}</span>

                <p-overlayPanel #userDetailUpdatedByOverlay [appendTo]="'body'">
                    <ng-template pTemplate>
                        <lce-user-detail
                                [data]="internalData.updatedBy"
                                [showViewRecordButton]="hasViewRecordPermission() && showViewRecordButton === true">
                        </lce-user-detail>
                    </ng-template>
                </p-overlayPanel>
            </ng-container>
        </span>
    </div>
</div>