import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';
import {XSUtils} from '@xs/base';
import {LCEUserDeliveryManPartial, LCEUserDeliveryManService} from '@lce/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XSLoaderService} from '@xs/common';

@Component({
    selector: 'lce-delivery-men-autocomplete',
    template: `
        <xs-input-field-auto-complete
                styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-deliveryMan xsTemplate="item">
                <lce-user-partial [data]="deliveryMan"></lce-user-partial>
            </ng-template>
        </xs-input-field-auto-complete>`
})
export class LCEInputFieldDeliveryManAutocompleteComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() options: XSInputFieldBaseOptions;

    @Input() showIcon?: boolean;

    @Input() completeOnFocus?: boolean;

    @Input() showDefaultBelowText?: boolean;

    @Input() placeholder?: string;

    @Input() appendTo?: any;

    @Input() registered?: boolean;

    @Output() selectEvent = new EventEmitter<LCEUserDeliveryManPartial>();

    autocompleteOptions: XSInputFieldAutoCompleteOptions;

    @ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

    constructor(private deliveryManService: LCEUserDeliveryManService, private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {


        if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.user.deliveryMan.autoComplete.placeholder';
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        this.autocompleteOptions = {
            control: this.options.control,
            autoCompleteOptions: {
                labelField: 'fullName',
                forceSelection: true,
                completeOnFocus: this.completeOnFocus,
                search: (query: string) => {
                    return this.deliveryManService.autocomplete(query);
                }
            },
            placeholder: this.placeholder,
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
            selectEvent: (event: any) => {
                this.handleSelectEvent(event);
            },
            ...this.options
        };
        if (this.showIcon) {
            this.autocompleteOptions.inputStyleClass = 'xs-pl-45-imp';
            this.autocompleteOptions.leftIcon = LCE_SHARED_ICON.deliveryMan;
            this.autocompleteOptions.leftIconStyleClass = 'xs-color-secondary xs-font-size-16-imp xs-mr-5 xs-mt-minus-1';
        }
    }

    private handleSelectEvent(deliveryMan: LCEUserDeliveryManPartial): void {
        this.selectEvent.emit(deliveryMan);
    }
}

