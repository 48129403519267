import {Injectable} from '@angular/core';
import {XSAssert, XSIDCode, XSUtils} from '@xs/base';
import {LCEFacilityTownHallCertificateOrderRegistration, LCEFacilityTownHallCertificateOrderRegistrationResponse, LCEUserPartial} from '@lce/core';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEFacilityTownHallRegistrationCreateComponent} from './create/lce-facility-town-hall-registration-create.component';
import {LCEFacilityTownHallRegistrationRecordComponent} from './record/lce-facility-town-hall-registration-record.component';

export interface LCEFacilityTownHallRegistrationCreateArg {
    showRecordAfterSave?: boolean;
    styleClass?: string;
    facility: XSIDCode;
    certificateOrderRegistration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;
    onClose?: (facilityTownHallRegistrationResponse?: LCEFacilityTownHallCertificateOrderRegistrationResponse) => void;
    onSave?: (facilityTownHallRegistrationResponse?: LCEFacilityTownHallCertificateOrderRegistrationResponse) => void;
}

export interface LCEFacilityTownHallRegistrationRecordArg {
    facilityID?: string;
    styleClass?: string;
    certificateOrderRegistration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;
    onClose?: (registration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>) => void;
}

@Injectable()
export class LCEFacilityTownHallRegistrationDialogService {

    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEFacilityTownHallRegistrationRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.facilityID) && XSUtils.isEmpty(arg.certificateOrderRegistration)) {
            throw new Error('arg.facilityID and arg.certificateOrderRegistration cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.facilityID) && !XSUtils.isEmpty(arg.certificateOrderRegistration)) {
            throw new Error('arg.facilityID and arg.certificateOrderRegistration cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                facilityID: arg.facilityID,
                certificateOrderRegistration: arg.certificateOrderRegistration,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-350 xs-min-width-700'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-800'
        };

        const recordComponent: LCEFacilityTownHallRegistrationRecordComponent = this.dialogService.openComponentDialog(LCEFacilityTownHallRegistrationRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((registration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>) => {
            if (arg?.onClose) arg!.onClose!(registration);
        });
    }

    public openCreateDialog(arg: LCEFacilityTownHallRegistrationCreateArg): void {
        XSAssert.notEmpty(arg, 'arg');
        const dConfig: XSDialogConfig = {
            data: {
                facility: arg.facility,
                certificateOrderRegistration: arg.certificateOrderRegistration,
                styleClass: arg.styleClass
            },
            header: true,
            headerSeparator: false,
            footer: true,
            footerSeparator: true,
            closable: false,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-700'
        };

        const createComponent: LCEFacilityTownHallRegistrationCreateComponent = this.dialogService.openComponentDialog(LCEFacilityTownHallRegistrationCreateComponent, dConfig);

        createComponent.closeEvent.subscribe((facilityTownHallRegistrationResponse?: LCEFacilityTownHallCertificateOrderRegistrationResponse) => {
            if (arg?.onClose) arg!.onClose!(facilityTownHallRegistrationResponse);
        });
        createComponent.saveEvent.subscribe((facilityTownHallRegistrationResponse?: LCEFacilityTownHallCertificateOrderRegistrationResponse) => {
            if (!facilityTownHallRegistrationResponse) {
                createComponent.closeDialog();
                return;
            }
            if (arg?.onSave) arg.onSave(facilityTownHallRegistrationResponse);

            let certificateOrderRegistration = {
                registrationType: facilityTownHallRegistrationResponse.registrationType,
                registrationStampLoad: facilityTownHallRegistrationResponse.registrationStampLoad,
                registrationAutomaticTransfer: facilityTownHallRegistrationResponse.registrationAutomaticTransfer,
                orderFee: facilityTownHallRegistrationResponse.orderFee,
                orderUnitPrice: facilityTownHallRegistrationResponse.orderUnitPrice
            } as LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;

            if (arg?.showRecordAfterSave === true) this.openRecordDialog({certificateOrderRegistration: certificateOrderRegistration});
            else createComponent.closeDialog();

        });
    }

}