import {XSContactPerson, XSIntegerRange, XSPersonName, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSResourceAuditFullCanOptions} from '@xs/base';
import {XSBabavoss} from '@xs/common';
import {LCEDistrictPartial} from './lce-district';
import {LCEUserPartial} from './user/lce-user';

export interface LCEMunicipalityCanOptions extends XSResourceAuditFullCanOptions {
}

export enum LCEMunicipalityEventName {
    NEW = 'municipalityNew',
}

export interface LCEMunicipalitySearch extends XSPKAuditFullSearchFilter {
    codes?: string[];
    districtCodes?: string[];
    mayorSinceRange?: string;
    populationSizeRange?: XSIntegerRange;
    populationUpdateYearRange?: XSIntegerRange;
    squareKMRange?: XSIntegerRange;
}

export interface LCEMunicipalityCreate {
    name: string;
    fullName: string;
    shortName?: string;
    districtCode: string;
    actualMayorName: XSPersonName;
    mayorSince?: string;
    populationSize?: number;
    populationUpdateYear?: number;
    squareKM?: number;
    mainImage?: any;
    images?: any[];
}

export interface LCEMunicipalityPartial {
    id: string;
    code: string;
    name: string;
    fullName: string;
    shortName?: string;
    district: LCEDistrictPartial;
}

export interface LCEMunicipality extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;
    name: string;
    fullName: string;
    shortName?: string;
    district: LCEDistrictPartial;
    actualMayorName: XSPersonName;
    mayorSince?: string;
    populationSize?: number;
    populationUpdateYear?: number;
    squareKM?: number;
    mainImage?: string;
    images?: string[];
}

export interface LCEMunicipalitySettings {
    primaryContactPerson: XSContactPerson;
    secondaryContactPerson?: XSContactPerson;
    publicInformation?: XSBabavoss;
    authenticationPrimaryPhoneNumberAsUsername: boolean;
    authenticationCodeAsUsername: boolean;
    sessionDuration: number;
}
