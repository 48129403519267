import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {LCEFacilityPartial} from '@lce/core';
import {XSDataFieldComponent} from '@xs/core';

@Component({
	selector: 'lce-data-field-facility',
	templateUrl: './lce-data-field-facility.component.html',
	host: {class: 'xs-data-field-component'}
})
export class LCEDataFieldFacilityComponent extends XSDataFieldComponent<LCEFacilityPartial> implements OnInit, AfterViewInit {

	@Input() partialLabel?: string;
	@Input() partialShowDefaultLabel?: boolean;

	@Input() showCode?: boolean;
	@Input() showIcon?: boolean;

	@Input() showOverlay?: boolean;
	@Input() viewRecord?: boolean;

	ngOnInit(): void {
		super.onInit();
	}

	ngAfterViewInit(): void {
		super.afterViewInit();
	}
}
