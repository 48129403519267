import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {LCECertificateOrderPartial, LCECertificateOrderStatus, LCECertificateType} from '@lce/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSDialogConfig, XSDialogService, XSFileOutputFormat} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcess, LCECertificateOrderBatchProcessCompleteResponse} from '../../api/domain/lce-certificate-order-batch-process';
import {LCECertificateOrderBatchProcessService} from '../../api/services/lce-certificate-order-batch-process.service';

@Component({
    selector: 'lce-certificate-order-batch-process-record',
    templateUrl: './lce-certificate-order-batch-process-record.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCECertificateOrderBatchProcessRecordComponent extends XSDialogable implements OnInit, OnChanges, OnDestroy {

    static readonly BASE_DIALOG_CONFIG: XSDialogConfig = {
        header: true,
        headerSeparator: true,
        footer: true,
        footerSeparator: true,
        footerOkButton: true,
        width: '90%',
        maxWidth: '1100px',
        closable: true
    };

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE = 'lce.shared.certificateOrders.certificateOrderBatchProcess.';
    readonly SPACE_BETWEEN_BTN = '40px';
    readonly ORDER_STATUS = LCECertificateOrderStatus;
    readonly LOADER_MAIN_ID = XSUtils.uuid() + 'certificateOrderBatchProcessRecord';

    @Input() styleClass?: string;

    @Input() batchProcessID?: string;
    @Input() batchProcessCode?: string;
    @Input() data?: LCECertificateOrderBatchProcess;

    @Input() readonly?: boolean;

    @Output() closeEvent = new EventEmitter<void>();
    @Output() completeEvent = new EventEmitter<LCECertificateOrderBatchProcess>();
    @Output() reopenEvent = new EventEmitter<LCECertificateOrderBatchProcess>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    countOrders: {
        birth: number;
        marriage: number;
        death: number;
    };

    searchOrderText: string;

    orders: LCECertificateOrderPartial[];

    countOrderStatuses: {
        total: number;
        ready: number;
        error: number;
        cancelled: number;
    };

    selectedOrderStatus?: LCECertificateOrderStatus;

    completeReopenIcon?: string;
    completeReopenButtonLabel?: string;
    completeReopenBatchProcessConfirmation: XSConfirmation;

    nTotalCopies: number;

    loading = {
        refresh: false,
        download: false,
        completeReopen: false
    };

    errorMain?: any;
    errorMainRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieve('main')
    };
    errorRefresh?: any;

    errorMessage?: string;

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private dialogService: XSDialogService,
        private batchProcessService: LCECertificateOrderBatchProcessService) {
        super();
        this.buildConfirmations();
    }

    public static validate(
        batchProcessID: string | undefined, batchProcessCode: string | undefined, batchProcess: LCECertificateOrderBatchProcess | undefined, prefix: string = XS_STR_EMPTY): void {
        const str = XSUtils.isEmpty(prefix) ? XS_STR_EMPTY : prefix.trim() + '.';
        if (XSUtils.isEmpty(batchProcessID) && XSUtils.isEmpty(batchProcessCode) && XSUtils.isEmpty(batchProcess)) {
            throw new Error(`${str}batchProcessID, arg.batchProcessCode and ${str}batchProcess cannot all be empty at the same time.`);
        }
        if ((!XSUtils.isEmpty(batchProcessID) || !XSUtils.isEmpty(batchProcessCode)) && !XSUtils.isEmpty(batchProcess)) {
            throw new Error(`${str}batchProcessID or ${str}batchProcessCode and ${str}batchProcess cannot both be set at the same time.`);
        }
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.batchProcessID = this.dialogConfig.data.batchProcessID;
            this.batchProcessCode = this.dialogConfig.data.batchProcessCode;
            this.data = this.dialogConfig.data.batchProcess;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.readonly = this.dialogConfig.data.readonly;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        LCECertificateOrderBatchProcessRecordComponent.validate(this.batchProcessID, this.batchProcessCode, this.data);
        if (!XSUtils.isEmpty(this.data)) {
            this.batchProcessID = this.data!.id;
            this.batchProcessCode = this.data!.code;
            this.update();
        } else {
            this.retrieve('main');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            this.update();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public hasErrorMessage(): boolean {
        return !XSUtils.isEmpty(this.errorMessage);
    }

    public hasRefreshError(): boolean {
        return !XSUtils.isEmpty(this.errorRefresh);
    }

    public hasMainError(): boolean {
        return !XSUtils.isEmpty(this.errorMain);
    }

    public onCountOrderStatusClick(status?: LCECertificateOrderStatus): void {
        this.selectedOrderStatus = status;
        this.searchOrder();
    }

    public onStatusUpdated(): void {
        this.updateCountOrderStatuses();
    }

    public canReopenBatchProcess(): boolean {
        return this.data!.completed === true;
    }

    public canCompleteBatchProcess(): boolean {
        return !this.hasOrderWithStatus(LCECertificateOrderStatus.PENDING) && !this.hasOrderWithStatus(LCECertificateOrderStatus.PROCESSING);
    }

    public onOpenDialog(): void {
        const dConfig: XSDialogConfig = {
            ...LCECertificateOrderBatchProcessRecordComponent.BASE_DIALOG_CONFIG,
            data: {
                data: this.data,
                batchProcessID: this.batchProcessID,
                readonly: this.readonly
            }
        };
        this.dialogService.openComponentDialog(LCECertificateOrderBatchProcessRecordComponent, dConfig);
    }

    public onDownload(): void {
        const outputFormat: XSFileOutputFormat = XSFileOutputFormat.TXT;
        this.loading.download = true;
        this.subscription.add(
            this.batchProcessService.download(this.data!.id, outputFormat)
                .pipe(finalize(() => {
                    this.loading.download = false;
                }))
                .subscribe(() => {
                    this.loading.download = false;
                })
        );
    }

    public onRefresh(): void {
        this.retrieve('refresh');
    }

    public searchOrder(): void {
        if (XSUtils.isEmpty(this.searchOrderText) && XSUtils.isEmpty(this.selectedOrderStatus)) {
            this.orders = [...this.data!.orders];
            return;
        }
        const query = this.searchOrderText?.trim();
        this.orders = this.data!.orders.filter((order) => {
            let searchTextState = true;
            if (!XSUtils.isEmpty(query)) {
                searchTextState = (order.orderNumber.toLowerCase().includes(query) || order.certificate.referenceNumber.toLowerCase().includes(query));
            }
            if (!XSUtils.isEmpty(this.selectedOrderStatus)) {
                return searchTextState && order.status === this.selectedOrderStatus;
            }
            return searchTextState;
        });
    }

    private reopenBatchProcess(): void {
        this.loading.completeReopen = true;
        this.subscription.add(
            this.batchProcessService.reopen(this.data!.id)
                .pipe(finalize(() => {
                    this.loading.completeReopen = false;
                }))
                .subscribe(response => {
                    this.update(response);
                    this.loading.completeReopen = false;
                    this.reopenEvent.emit(this.data);
                })
        );
    }

    private completeBatchProcess(): void {
        this.clearErrorMessage();
        if (this.hasOrderWithStatus(LCECertificateOrderStatus.PROCESSING)) {
            this.errorMessage = this.TR_BASE + 'errorMessage.someOrdersStillInProcessingStatus';
            return;
        }
        this.loading.completeReopen = true;
        this.subscription.add(
            this.batchProcessService.complete(this.data!.id)
                .pipe(finalize(() => {
                    this.loading.completeReopen = false;
                }))
                .subscribe(response => {
                    this.update(response);
                    this.loading.completeReopen = false;
                    this.completeEvent.emit(this.data);
                })
        );
    }

    private hasOrderWithStatus(status: LCECertificateOrderStatus) {
        return this.data!.orders.filter(order => order.status === status).length > 0;
    }

    private update(response?: LCECertificateOrderBatchProcessCompleteResponse): void {
        this.clearErrorMessage();
        if (!XSUtils.isNull(response)) {
            if (this.data!.id !== response!.id) {
                throw new Error(`data.id (${this.data!.id}) and response.id (${response!.id}) don't match.`);
            }
            if (this.data!.id !== response!.id) {
                throw new Error(`data.code (${this.data!.code}) and response.code (${response!.code}) don't match.`);
            }
            this.data!.completed = response!.completed;
            this.data!.completedOn = response!.completedOn;
            this.data!.completedBy = response!.completedBy;
            this.data!.reopenedOn = response!.reopenedOn;
            this.data!.reopenedBy = response!.reopenedBy;
            this.data!.updatedOn = response!.updatedOn;
            this.data!.updatedBy = response!.updatedBy;
        }
        this.updateCountOrderCertificateTypes();
        this.updateCountOrderStatuses();
        this.nTotalCopies = 0;
        this.data!.orders.forEach(order => this.nTotalCopies += order.numberOfCopies);
        if (!XSUtils.isEmpty(this.searchOrderText)) this.searchOrder();

        if (this.data!.completed === true) {
            this.completeReopenIcon = LCE_SHARED_ICON.undo;
            this.completeReopenButtonLabel = this.TR_BASE + 'reopenBatchProcess';
            this.completeReopenBatchProcessConfirmation.trMessage = this.TR_BASE + 'reopenBatchConfirmationMessage';
        } else {
            this.completeReopenIcon = LCE_SHARED_ICON.thumbsUp;
            this.completeReopenButtonLabel = this.TR_BASE + 'completeBatchProcess';
            this.completeReopenBatchProcessConfirmation.trMessage = this.TR_BASE + 'completeBatchConfirmationMessage';
        }
        this.completeReopenBatchProcessConfirmation = {...this.completeReopenBatchProcessConfirmation};
    }

    private updateCountOrderCertificateTypes(): void {
        this.countOrders = {
            birth: this.data!.orders.filter((order) => order.certificate.type === LCECertificateType.BIRTH).length,
            marriage: this.data!.orders.filter((order) => order.certificate.type === LCECertificateType.MARRIAGE).length,
            death: this.data!.orders.filter((order) => order.certificate.type === LCECertificateType.DEATH).length
        };
        this.orders = [...this.data!.orders];
    }

    private updateCountOrderStatuses(): void {
        this.countOrderStatuses = {
            total: this.data!.orders.length,
            ready: this.data!.orders.filter(order => order.status === LCECertificateOrderStatus.READY).length,
            error: this.data!.orders.filter(order => order.status === LCECertificateOrderStatus.ERROR).length,
            cancelled: this.data!.orders.filter(order => order.status === LCECertificateOrderStatus.CANCELLED).length
        };
    }

    private buildConfirmations(): void {
        this.completeReopenBatchProcessConfirmation = {
            key: 'completeReopenBatchBtnConfirmationKey',
            trMessage: this.TR_BASE + 'completeBatchConfirmationMessage',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                if (this.data!.completed === true) this.reopenBatchProcess();
                else this.completeBatchProcess();
            },
            reject: () => {
            }
        };
    }

    private clearErrorMessage(): void {
        this.errorMessage = XS_STR_EMPTY;
    }

    private retrieve(mode: 'refresh' | 'main'): void {
        if (!XSUtils.isEmpty(this.batchProcessID)) {
            this.internalRetrieve(mode, this.batchProcessService.findOneByID(this.batchProcessID!));
            return;
        }
        if (!XSUtils.isEmpty(this.batchProcessCode)) {
            this.internalRetrieve(mode, this.batchProcessService.findOneByCode(this.batchProcessCode!));
            return;
        }
        throw new Error('unknown error, batchProcessID and batchProcessCode are both blank. This is not supposed to happen.');
    }

    private internalRetrieve(mode: 'refresh' | 'main', observable: Observable<LCECertificateOrderBatchProcess>): void {
        if (mode === 'main') this.loaderService.startLoader(this.LOADER_MAIN_ID);
        if (mode === 'refresh') this.loading.refresh = true;
        this.errorMain = undefined;
        this.errorRefresh = undefined;
        this.clearErrorMessage();
        this.subscription.add(
            observable
                .pipe(finalize(() => {
                    if (mode === 'main') this.loaderService.stopLoader(this.LOADER_MAIN_ID);
                    if (mode === 'refresh') this.loading.refresh = false;
                }))
                .subscribe(
                    {
                        next: (batchProcess) => {
                            this.data = batchProcess;
                            this.batchProcessID = this.data.id;
                            this.batchProcessCode = this.data.code;
                            this.update();
                        },
                        error: error => {
                            if (mode === 'main') this.errorMain = error;
                            if (mode === 'refresh') {
                                this.errorRefresh = error;
                                this.errorMessage = this.TR_BASE + 'errorMessage.retrieve';
                            }
                        }
                    }
                )
        );
    }
}
