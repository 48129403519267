<div class="xs-flex-column  xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !loader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
            styleClass="xs-max-width-500"
            mode="block"
            message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
            subMessage="xs.core.error.contactAdminMessage"
            [error]="retrieveError"
            [showErrorDetailButton]="true"
            [actionButton]="retrieveErrorRetryButton"
            [showActionButton]="true">
        </xs-error>
    </div>

    <div *ngIf="!hasRetrieveError() && !loader.isRunning()">
        <div class="grid" [ngClass]="{ 'xs-disabled': createUpdateLoading }">
            <div *ngIf="!facility" class="col-12">
                <xs-input-field-auto-complete [options]="facilityCodeField">
                    <ng-template let-facility xsTemplate="item">
                        <div class="xs-flex-column">
                            <lce-facility-partial styleClass="xs-ellipsis" [data]="facility"></lce-facility-partial>
                        </div>
                    </ng-template>
                </xs-input-field-auto-complete>
            </div>
            <div class="col-12">
                <div class="xs-field xs-field-input">
                    <label class="xs-font-size-13" for="searchText">{{ TR_BASE_LABEL + 'facility' | translate }}</label>
                    <lce-facility-partial *ngIf="facility" styleClass="xs-ml-15" [data]="facility"></lce-facility-partial>
                </div>
            </div>
            <div class="col-6">
                <xs-input-field-text [options]="firstNameField"></xs-input-field-text>
            </div>
            <div class="col-6">
                <xs-input-field-text [options]="lastNameField"></xs-input-field-text>
            </div>
            <div class="col-6">
                <xs-input-field-text [options]="emailField"></xs-input-field-text>
            </div>
            <div class="col-6">
                <xs-input-field-text [options]="primaryPhoneNumberField"></xs-input-field-text>
            </div>
            <div class="col-6">
                <xs-input-field-text [options]="secondaryPhoneNumberField"></xs-input-field-text>
            </div>
            <div class="col-6">
                <xs-input-field-dropdown [options]="positionField"></xs-input-field-dropdown>
            </div>
            <div class="col-6">
                <xs-input-field-calendar [options]="hiredOnField"></xs-input-field-calendar>
            </div>
            <div class="col-6">
                <xs-input-field-address [options]="addressField"></xs-input-field-address>
            </div>
            <div class="col-12">
                <xs-input-field-textarea [options]="noteField"></xs-input-field-textarea>
            </div>

            <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-mtb-15">
                <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
            </div>
        </div>
    </div>

    <ng-template #dHeader>
        <xs-ivar styleClass="xs-dialog-header" [line1]="this.TR_BASE + 'createUpdate.createTitle'" [ellipsis]="true"> </xs-ivar>
    </ng-template>

    <ng-template #dFooter>
        <div class="xs-flex-row xs-align-items-center xs-justify-content-end" [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }">
            <xs-error
                *ngIf="hasCreateUpdateError()"
                mode="inline"
                [ellipsis]="true"
                message="{{ TR_BASE_LABEL }}errorMessageCreateUpdate"
                [error]="createUpdateError"
                [showErrorDetailButton]="true"
                errorDetailButtonClass="xs-mt-2-imp">
            </xs-error>

            <div class="xs-flex-row xs-align-items-center">
                <xs-button *ngIf="isCreateMode()" type="text" size="intermediate" label="Fill Form" class="xs-sm-display-none xs-mr-40" (clickEvent)="fillForm()"> </xs-button>
                <xs-button
                    *ngIf="isCreateMode()"
                    (clickEvent)="reset()"
                    size="intermediate"
                    type="text"
                    label="xs.core.label.reset"
                    confirmationPopupStyleClass="xs-font-size-10"
                    [confirmation]="resetConfirmation"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    [disabled]="createUpdateLoading">
                </xs-button>
                <xs-button
                    (clickEvent)="closeDialog()"
                    type="button"
                    label="xs.core.label.close"
                    styleClass="xs-mlr-15-imp"
                    confirmationPopupStyleClass="xs-font-size-10"
                    severity="secondary"
                    [outlined]="true"
                    [confirmation]="closeConfirmation"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    [disabled]="createUpdateLoading || loader.isRunning()">
                </xs-button>
                <xs-button type="button" label="xs.core.label.save" [loading]="createUpdateLoading" [disabled]="loader.isRunning()" (clickEvent)="createUpdate()"> </xs-button>
            </div>
        </div>
    </ng-template>
</div>
