<div [ngClass]="{ 'xs-border-discrete xs-border-radius-default': showBorder }"
     class="xs-min-width-200 xs-max-width-400 xs-overflow-hidden {{ styleClass }}">

    <div [ngClass]="{ 'xs-background-icon xs-background-icon-font-size-175 xs-background-icon-opacity-025 lce-background-icon-facility': showBackgroundIcon }"
         class="xs-flex-column xs-width-full xs-plr-25 xs-pt-25 xs-pb-10">
        <div class="xs-flex-column-center xs-width-full">
            <!-- Facility -->
            <xs-ivar
                    [line1]="data.fullName"
                    [line2]="data.code"
                    iconStyleClass="xs-font-size-25-imp xs-color-light-imp"
                    line1StyleClass="xs-color-secondary-imp xs-font-weight-600-imp"
                    line2StyleClass="xs-font-size-small-imp xs-color-secondary-imp">
            </xs-ivar>
            <!-- Vertical Separator -->
            <div class="xs-flex-row xs-width-full xs-height-50 xs-pl-40 xs-mtb-15">
                <div class="xs-height-full xs-width-0 xs-border-left-light xs-border-left-width-2-imp"></div>
            </div>
            <!-- Municipality -->
            <xs-ivar
                    [line1]="data.municipality.fullName"
                    [line2]="data.municipality.code"
                    iconStyleClass="xs-font-size-25-imp xs-color-light-imp"
                    line1StyleClass="xs-color-secondary-imp xs-font-size-intermediate-imp"
                    line2StyleClass="xs-font-size-small-imp xs-color-secondary-imp">
            </xs-ivar>
            <!-- Vertical Separator -->
            <div class="xs-flex-row xs-width-full xs-height-50 xs-pl-40 xs-mtb-15">
                <div class="xs-height-full xs-width-0 xs-border-left-light xs-border-left-width-2-imp"></div>
            </div>
            <!-- District -->
            <xs-ivar
                    [line1]="data.municipality.district.fullName"
                    [line2]="data.municipality.district.code"
                    iconStyleClass="xs-font-size-25-imp xs-color-light-imp"
                    line1StyleClass="xs-color-secondary-imp xs-font-size-intermediate-imp"
                    line2StyleClass="xs-font-size-small-imp xs-color-secondary-imp">
            </xs-ivar>
        </div>

        <div *ngIf="viewRecord" class="xs-width-full xs-flex-row xs-justify-content-end xs-mt-50">
            <xs-button (clickEvent)="openDetail()" [disabled]="disabled" label="lce.shared.label.showDetail" size="small" type="text"></xs-button>
        </div>
    </div>
</div>
