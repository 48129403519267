<div class="xs-flex-column-center xs-width-full {{ styleClass }}">
    <div class="grid xs-fields xs-fields-spacer-5">
        <div *ngIf="canUseDeliveryDate" class="col-12">
            <xs-input-field-calendar [options]="desiredDeliveryDateField"></xs-input-field-calendar>
        </div>
        <div *ngIf="canUseDesiredDeliveryTimeRange" class="col-12">
            <xs-input-field-instant-range [options]="desiredDeliveryTimeRangeField"></xs-input-field-instant-range>
        </div>
        <div class="col-12">
            <xs-input-field-dropdown [options]="destinationTypeField"></xs-input-field-dropdown>
        </div>

        <div *ngIf="selectedDestinationType | xsIsNotEmpty" class="col-12">
            <xs-input-field-auto-complete *ngIf="isFacilityDestination() || isServicePointDestination()" [appendTo]="appendTo" [options]="$any(deliveryDestinationField)">
                <ng-template let-data xsTemplate="item">
                    <lce-facility-partial *ngIf="isFacilityDestination()" [data]="data"></lce-facility-partial>
                    <lce-service-point-partial *ngIf="isServicePointDestination()" [data]="data"></lce-service-point-partial>
                </ng-template>
            </xs-input-field-auto-complete>
            <div *ngIf="isInternationalDestination()" class="grid">
                <div class="col-6">
                    <xs-input-field-address [options]="$any(deliveryDestinationField)"></xs-input-field-address>
                </div>
                <div class="col-6">

                    <xs-data-field-text
                            *ngIf="forceShippingInternationalProvider | xsIsNotEmpty"
                            [label]="TR_BASE + 'shippingInternationalProvider'"
                            [upperCase]="true"
                            [value]="forceShippingInternationalProvider"
                            belowText="Lorem Ipsum"
                            valueStyleClass="xs-color-secondary">
                    </xs-data-field-text>

                    <xs-input-field-dropdown
                            *ngIf="forceShippingInternationalProvider | xsIsEmpty"
                            [options]="shippingInternationalProviderField">
                    </xs-input-field-dropdown>

                </div>
            </div>
            <xs-input-field-map-location *ngIf="isCustomDestination()" [options]="$any(deliveryDestinationField)"></xs-input-field-map-location>
        </div>

        <div *ngIf="selectedDestinationType | xsIsEmpty" class="xs-flex-column-center xs-mlr-8 xs-width-full xs-p-20 xs-mb-10 xs-border-discrete xs-border-radius-default">
            <xs-icon [value]="ICON.handPointer" color="discrete" size="40px" styleClass="xs-mb-15"></xs-icon>
            <span class="xs-display-block xs-color-secondary xs-font-size-small xs-mb-2">{{ TR_BASE + 'deliveryDestinationTest' | translate }}</span>
            <span class="xs-display-block xs-color-secondary xs-font-size-small">{{ TR_BASE + 'deliveryDestinationSubTest' | translate }}</span>
        </div>

        <div *ngIf="canUseNote" class="col-12">
            <xs-input-field-textarea [options]="deliveryNoteField"></xs-input-field-textarea>
        </div>
    </div>
</div>
