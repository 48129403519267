import {XSAddress, XSContactPerson, XSOpeningHours, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSResourceAuditFullCanOptions} from '@xs/base';
import {LCEMunicipalityPartial} from '../lce-municipality';
import {LCEUserPartial} from '../user/lce-user';
import {LCEFacilityTownHallCertificateOrderRegistration} from './registration/lce-facility-townhall-certificate-order-registration';

export interface LCEFacilityCanOptions extends XSResourceAuditFullCanOptions {
    updatePartnership?: boolean;
}

export enum LCEFacilityEventName {
    NEW = 'facilityNew',
}

export interface LCEFacilitySearch extends XSPKAuditFullSearchFilter {
    codes?: string;
    types?: LCEFacilityType[];
    municipalityCodes?: string[];
    districtCodes?: string[];
    geoCircle?: string;
}

export interface LCEFacilityCreate {
    type: LCEFacilityType;
    name: string;
    fullName: string;
    subName?: string;
    address: XSAddress;
    gmapLocation?: string;
    municipalityCode: string;
    mainImage?: any;
    images?: any[];
    contactPhoneNumber?: string;
    contactEmail?: string;
    primaryContactPerson: XSContactPerson;
    secondaryContactPerson?: XSContactPerson;
    openingHours?: XSOpeningHours;
}

export interface LCEFacilityPartial {
    id: string;
    type: LCEFacilityType;
    code: string;
    name: string;
    fullName: string;
    subName?: string;
    municipality: LCEMunicipalityPartial;

}

export interface LCEFacility extends XSPKDTOAuditFull<LCEUserPartial> {
    type: LCEFacilityType;
    code: string;
    name: string;
    fullName: string;
    subName?: string;
    address: XSAddress;
    gmapLocation?: string;
    municipality: LCEMunicipalityPartial;
    mainImage?: string;
    images?: string[];
    contactPhoneNumber?: string;
    contactEmail?: string;
    primaryContactPerson?: XSContactPerson;
    secondaryContactPerson?: XSContactPerson;
    openingHours?: XSOpeningHours;
    certificateOrderRegistration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;
}

export enum LCEFacilityType {
    TOWN_HALL = 'townHall',
    TOWN_HALL_ANNEXE = 'townHallAnnexe',
    POLICE_STATION = 'policeStation',
    COURTHOUSE = 'courthouse',
    HOSPITAL = 'hospital',
}
