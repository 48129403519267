<div [ngClass]="{ 'xs-disabled': loading }" class="xs-flex-column-center xs-position-relative xs-width-full {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="!centralLoader.isRunning()" class="grid xs-fields xs-fields-spacer-5">

        <div *ngIf="canShowPendingOrder" class="col-12 lg:col-6">
            <lce-certificate-order-process-pending-request
                    (continueEvent)="continueWithPendingOrder()"
                    [certificateType]="data!.certificateCreate.type"
                    [createdOn]="orderCreateCreatedOn"
                    [referenceNumber]="data!.certificateCreate.referenceNumber"
                    styleClass="xs-max-width-300">
            </lce-certificate-order-process-pending-request>
        </div>

        <div class="col-12">
            <lce-number-of-document
                    (numberOfCopiesChange)="numberOfCopies = $event"
                    [numberOfCopiesColor]="numberOfCopiesColor"
                    [numberOfCopies]="numberOfCopies"
                    belowText="lce.shared.label.numberOfCopies"
                    numberStyleClass="xs-font-size-50-imp lce-cop-copies-number-title-color xs-mlr-30"
                    size="large"
                    styleClass="xs-mb-50"></lce-number-of-document>
        </div>


        <div [ngClass]="{ 'lg:col-12': vertical }" class="col-12 lg:col-6">
            <div [style.border-color]="getCardColor()" class="xs-card xs-card-border">
                <div class="xs-card-header">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="certificateCard"
                            [expanded]="true"
                            text="{{ TR_BASE + 'certificate.title' }}"
                            textStyleClass="lce-cop-sub-title-color">
                    </xs-title>
                </div>
                <div #certificateCard class="xs-card-content">
                    <lce-certificate-order-create-certificate
                        #createCertificateForm
                        [(formGroup)]="certificateFields"
                        [certificateCreate]="internalData?.certificateCreate"
                        [facilityCode]="internalData?.facilityCode"
                        [facility]="internalData?.facility">
                    </lce-certificate-order-create-certificate>
                </div>
            </div>
        </div>
        <div [ngClass]="{ 'lg:col-12': vertical }" class="col-12 lg:col-6">
            <div [style.border-color]="getCardColor()" class="xs-card xs-card-border">
                <div class="xs-card-header">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="customerCard"
                            [expanded]="true" text="{{ TR_BASE + 'customer.title' }}"
                            textStyleClass="lce-cop-sub-title-color">
                    </xs-title>
                </div>
                <div #customerCard class="xs-card-content">
                    <lce-certificate-order-create-customer
                        #createCustomerForm
                        [(formGroup)]="customerFields"
                        [customerCreate]="internalData?.customerCreate">
                    </lce-certificate-order-create-customer>
                </div>
            </div>
        </div>

        <div *ngIf="isModePublic && useDelivery" [ngClass]="{ 'lg:col-12': vertical }" class="col-12 lg:col-6">
            <div [style.border-color]="getCardColor()" class="xs-card xs-card-border">
                <div class="xs-card-header">
                    <xs-title
                            [expandCollapseEnable]="true"
                            [expandCollapseTarget]="deliveryCard"
                            [expanded]="true" text="{{ TR_BASE + 'delivery.title' }}"
                            textStyleClass="lce-cop-sub-title-color">
                    </xs-title>
                </div>

                <div #deliveryCard class="xs-card-content">
                    <lce-certificate-order-create-delivery
                        #createDeliveryForm
                        [(formGroup)]="deliveryFields"
                        [canUseDeliveryDate]="true"
                        [canUseDesiredDeliveryTimeRange]="canUseDesiredDeliveryTimeRange"
                        [certificateOrderCreate]="internalData"
                        [forceShippingInternationalProvider]="forceShippingInternationalProvider">
                    </lce-certificate-order-create-delivery>
                </div>
            </div>
        </div>
        <div *ngIf="isModePublic && useProcuration" [ngClass]="{ 'lg:col-12': vertical }" class="col-12 lg:col-6">
            <div [style.border-color]="getCardColor()" class="xs-card xs-card-border">
                <div class="xs-card-header">
                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="procurationCard" [expanded]="true" text="{{ TR_BASE + 'procuration.title' }}"
                              textStyleClass="lce-cop-sub-title-color">
                        <ng-template xsTemplate="rightSection">
                            <div class="xs-flex-row xs-align-items-center xs-mlr-15">
                                <xs-button
                                        (clickEvent)="procurationVisibilityEvent.emit(false)"
                                        *ngIf="showProcurationForm"
                                        iconStyleClass="xs-font-size-13-imp"
                                        label="xs.core.label.delete"
                                        size="small"
                                        styleClass="lce-cop-copies-number-title-color"
                                        type="text">
                                </xs-button>
                            </div>
                        </ng-template>
                    </xs-title>
                </div>
                <div #procurationCard class="xs-card-content">
                    <div *ngIf="!showProcurationForm" class="xs-flex-column-center xs-mlr-8 xs-width-full xs-height-250 xs-mb-10">
                        <span class="xs-display-block xs-font-size-intermediate lce-cop-sub-title-color xs-mb-2">{{ TR_BASE + 'procuration.addMessage' | translate }}</span>
                        <xs-button
                                (clickEvent)="procurationVisibilityEvent.emit(true)"
                                [icon]="ICON.plus"
                                [label]="TR_BASE + 'procuration.addButtonLabel'"
                                size="intermediate"
                                styleClass="xs-mt-10"
                                type="text">
                        </xs-button>
                    </div>
                    <lce-certificate-order-create-procuration
                        #createProcurationForm
                        *ngIf="showProcurationForm"
                        [procurationCreate]="internalData?.procurationCreate"
                        [showFillForm]="showProcurationFillForm">
                    </lce-certificate-order-create-procuration>
                </div>
            </div>
        </div>
        <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-width-full">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>
    </div>

    <ng-template #dHeader>
        <xs-ivar [ellipsis]="true" [icon]="ICON.certificateOrder" [line1]="headerTitle" [line2]="headerSubTitle" styleClass="xs-dialog-header"></xs-ivar>
    </ng-template>

    <ng-template #dFooter>
        <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateError() }" class="xs-flex-row xs-align-items-center xs-justify-content-end xs-width-full">
            <xs-error
                    *ngIf="hasCreateError()"
                    [ellipsis]="true"
                    [error]="createError"
                    [showErrorDetailButton]="true"
                    errorDetailButtonClass="xs-mt-2-imp"
                    message="{{ TR_BASE }}errorMessage"
                    mode="inline">
            </xs-error>

            <div *ngIf="showActionButtons === true" class="xs-flex-row xs-align-items-center">

                <xs-button
                        (clickEvent)="fillForm()"
                        class="xs-mr-40"
                        label="Fill Form"
                        size="intermediate"
                        type="text">
                </xs-button>

                <xs-button
                        (clickEvent)="reset()"
                        [confirmation]="resetConfirmation"
                        [disabled]="loading"
                        [showConfirmation]="shouldShowResetConfirmation()"
                        confirmationPopupStyleClass="xs-font-size-10"
                        label="xs.core.label.reset"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="closeDialog()"
                        [confirmation]="closeConfirmation"
                        [disabled]="loading || centralLoader.isRunning()"
                        [outlined]="true"
                        [showConfirmation]="shouldShowResetConfirmation()"
                        confirmationPopupStyleClass="xs-font-size-10"
                        label="xs.core.label.cancel"
                        severity="secondary"
                        styleClass="xs-mlr-15-imp"
                        type="button">
                </xs-button>
                <xs-button
                        (clickEvent)="create()"
                        [disabled]="centralLoader.isRunning()"
                        [loading]="loading"
                        label="xs.core.label.save"
                        type="button"
                ></xs-button>
            </div>
        </div>
    </ng-template>
</div>
