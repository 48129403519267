<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !loader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }} errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum xs-header-left-stripe">
                    <span *ngIf="canDisplayData()" class="xs-card-header-title">{{ facilityWorker!.name | xsPersonName }}</span>
                    <span *ngIf="canDisplayData()" class="xs-card-header-sub-title">
                        {{ (TR_BASE_FACILITY_WORKER_POSITION + facilityWorker!.position) | translate }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-justify-content-space-between xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="facilityWorker!"></lce-user-resource-audit>
            </div>
            <div class="grid xs-fields xs-fields-spacer-30">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Facility Worker Information === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-6">
                    <xs-data-field-text [upperCase]="true" [value]="facilityWorker!.code" label="xs.core.label.code" valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-6 xs-flex-row xs-align-items-center">
                    <xs-data-field-resource-state [value]="facilityWorker"></xs-data-field-resource-state>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'facility'" [value]="(facilityWorker?.facility)!.fullName">
                    </xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text
                            [label]="TR_BASE_LABEL + 'position'"
                            [valueBaseTranslation]="TR_BASE_FACILITY_WORKER_POSITION"
                            [value]="facilityWorker?.position">
                    </xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'firstName'" [value]="(facilityWorker?.name)!.firstName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'lastName'" [value]="(facilityWorker?.name)!.lastName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-contact
                            [label]="TR_BASE_LABEL + 'contact'"
                            [value]="{
                            primaryPhoneNumber: facilityWorker?.primaryPhoneNumber,
                            secondaryPhoneNumber: facilityWorker?.secondaryPhoneNumber,
                            email: facilityWorker?.email}">
                    </xs-data-field-contact>
                </div>
                <div class="col-6">
                    <xs-data-field-address [value]="facilityWorker?.address" label="xs.common.label.address" styleClass="xs-data-field-spacer"></xs-data-field-address>
                </div>
                <div *ngIf="facilityWorker?.hiredOn" class="col-6">
                    <xs-data-field-calendar
                            [calendarOptions]="{ type: 'date', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }"
                            [label]="TR_BASE_LABEL + 'hiredOn'"
                            [value]="facilityWorker?.hiredOn">
                    </xs-data-field-calendar>
                </div>
                <div *ngIf="facilityWorker?.note" class="col-6">
                    <xs-data-field-textarea [value]="facilityWorker?.note" label="Note" styleClass="xs-data-field-spacer" valueStyleClass="xs-font-size-intermediate">
                    </xs-data-field-textarea>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="facilityWorker!.id"></xs-data-id>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.user"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
