import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_DD_MM_YYYY, XS_DATE_FORMAT_YYYY_MM_DD} from '@xs/common';
import {XSDialogable} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE, LCEBirthDeclarationParent} from '@lce/core';

@Component({
    selector: 'lce-birth-declaration-parent',
    templateUrl: './lce-birth-declaration-parent.component.html',
    host: {class: 'xs-flex-column xs-height-full'}
})
export class LCEBirthDeclarationParentComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.parent.';

    readonly DATE_FORMAT_EN = XS_DATE_FORMAT_YYYY_MM_DD;
    readonly DATE_FORMAT_FR = XS_DATE_FORMAT_DD_MM_YYYY;

    readonly TR_BASE_IDENTITY_DOCUMENT_TYPE = LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE;

    @Input() styleClass: string;
    @Input() readonly?: boolean;

    @Input() type: 'mother' | 'father';

    @Input() data?: LCEBirthDeclarationParent;
    @Input() birthDeclarationID?: string;
    @Input() birthNumber?: string;

    @Input() showBorder?: boolean;
    @Input() showCollapse?: boolean;
    @Input() showHeader?: boolean;

    @Output() editEvent = new EventEmitter<'mother' | 'father'>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclarationParent>();
    @Output() valueChange = new EventEmitter<LCEBirthDeclarationParent>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    constructor() {
        super();
    }

    public get title(): string {
        return this.type == 'mother' ? this.TR_BASE + 'title.mother' : this.TR_BASE + 'title.father';
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.data = this.dialogConfig.data.parent;
            this.type = this.dialogConfig.data.parentKey;
            this.readonly = this.dialogConfig.data.readonly;
            this.showBorder = this.dialogConfig.data.showBorder;
            this.showCollapse = this.dialogConfig.data.showCollapse;
            this.birthNumber = this.dialogConfig.data.birthDeclarationNumber;
            this.showHeader = this.dialogConfig.data.showHeader;
        }
        XSAssert.notNull(this.type, 'type');

        if (this.type === 'mother') XSAssert.notNull(this.data, 'data');

        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (XSUtils.isNull(this.showHeader)) this.showHeader = true;
    }

    public onEditParent(): void {
        if (this.readonly) return;
        this.editEvent.emit(this.type);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }
}
