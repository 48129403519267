export enum LCEDashboardStatsSummaryCountType {
    CUSTOMER = 'customer',
    CERTIFICATE_ORDER = 'certificateOrder',
    SUGGESTION = 'suggestion',
    NEWS_ARTICLE = 'newsArticle',
    SMART_CITY_ARTICLE = 'smartCityArticle'
}

export interface LCEDashboardStatsGlobal {
    countCustomers?: number;
    countCertificateOrders?: number;
    countSuggestions?: number;
    countNewsArticles?: number;
    countSmartCityArticles?: number;
}
