import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LOG, XSBaseEnvironmentService, XSHttpClientService, XSLanguage} from '@xs/base';

@Injectable({providedIn: 'root'})
export class LCEHttpClientService extends XSHttpClientService {

    constructor(protected httpClient: HttpClient, protected environmentService: XSBaseEnvironmentService) {
        super(httpClient);
    }

    public initialize(apiBaseURL: string, language: XSLanguage): void {
        this.superInitialize(apiBaseURL, language, this.environmentService.getRequestor());
        LOG().debug('Http Client Service Initialized [language: ' + this.getHeaders().get(XSHttpClientService.HEADER_LANGUAGE) + ', apiBaseURL : ' + this.apiBaseURL + '].');
    }
}
