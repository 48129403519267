import {XSAddressStructured, XSAddressType, XSAddressUnstructured, XSContactPerson, XSCoordinate} from '@xs/base';

export const LCE_MOCK_NAME_JOHN_DOE = {firstName: 'John', lastName: 'Doe'};
export const LCE_MOCK_NAME_JANE_DOE = {firstName: 'Jane', lastName: 'Doe'};
export const LCE_MOCK_NAME_TONY_STARK = {firstName: 'Tony', lastName: 'Stark'};

export const LCE_MOCK_CONTACT_PERSON_JANE_DOE: XSContactPerson = {
    name: LCE_MOCK_NAME_JANE_DOE,
    primaryPhoneNumber: '+16193400611',
    email: 'jane.doe@iro-xs.com',
    note: 'Reachable only during the day between 10:00AM and 2:00PM.'
};

export const LCE_MOCK_CONTACT_PERSON_JOHN_DOE: XSContactPerson = {
    name: LCE_MOCK_NAME_JOHN_DOE,
    primaryPhoneNumber: '+2250707972343',
    email: 'john.doe@iro-xs.com'
};

export const LCE_MOCK_CONTACT_PERSON_TONY_STARK: XSContactPerson = {
    name: LCE_MOCK_NAME_TONY_STARK,
    primaryPhoneNumber: '+2250707972349',
    email: 'tony.stark@iro-xs.com'
};

export const LCE_MOCK_COORDINATE_PHARMACIE_ANALYA: XSCoordinate = {latitude: 5.4027176999999975, longitude: -3.961497273014281};

export const LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA: XSAddressUnstructured = {
    type: XSAddressType.UNSTRUCTURED,
    title: 'Pharmacie Analya',
    line1: 'À Proximité du nouveau CHU Angré',
    city: 'Abidjan',
    countryISO: 'ci',
    coordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA
};

export const LCE_MOCK_ADDRESS_UNSTRUCTURED_YOPOUGON: XSAddressUnstructured = {
    type: XSAddressType.UNSTRUCTURED,
    title: 'Patisserie Chez Paul',
    line1: 'Rue Des Jardins, à 100 m du Restaurant l\'Automatic',
    line2: 'Deux-Plateaux, Vallon',
    city: 'abidjan',
    countryISO: 'ci'
};

export const LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON: XSAddressStructured = {
    type: XSAddressType.STRUCTURED,
    line1: '1480 gaston-vérroneau',
    line2: 'Apartment #429',
    postalCode: 'j4n 0g9',
    province: {name: 'quebec', code: 'qc'},
    city: 'montreal',
    countryISO: 'ca'
};
