<div class="xs-flex-column xs-width-fit-content xs-min-width-350 xs-min-height-450 xs-position-relative {{ styleClass }}">

    <!-- === Header === -->
    <div class="xs-header-bar">
        <i class="{{ ICON.news }}"></i>
        <span>{{ TR_BASE + 'header' | translate }}</span>
    </div>

    <!-- === Skeleton === -->
    <div *ngIf="loading" class="xs-flex-column-center xs-width-full">
        <p-skeleton borderRadius="12px" height="175px" styleClass="xs-mb-5" width="300px"></p-skeleton>
        <p-skeleton borderRadius="12px" height="240px" width="300px"></p-skeleton>
    </div>

    <!-- === Error === -->
    <div *ngIf="!loading && hasError()" class="xs-position-relative xs-flex-column xs-flex-1 xs-plr-25 xs-width-full">
        <div class="xs-absolute-center-vh xs-width-full">
            <xs-error [actionButton]="errorRetryButton" [error]="error" [showBorder]="false" [showErrorDetailButton]="true" mode="block">
            </xs-error>
        </div>
    </div>

    <!-- === No Article Found === -->
    <div *ngIf="!articleCard && !loading && !hasError()" class="xs-absolute-center-vh xs-flex-column-center xs-width-full">
        <xs-icon [value]="ICON.news" color="discrete" size="100px"></xs-icon>
        <span [innerHTML]="TR_BASE + 'noArticleFound' | translate" class="xs-display-block xs-font-size-intermediate xs-text-align-center xs-color-light xs-mt-8"></span>
    </div>

    <!-- === Article Card === -->
    <div class="xs-flex-column xs-align-items-center">
        <lce-news-article-card *ngIf="articleCard && !loading && !hasError()" [data]="articleCard!" class="xs-max-width-300"></lce-news-article-card>
    </div>
</div>