import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEFacility, LCEFacilityCanOptions, LCEFacilityPartial, LCEFacilitySearch, LCEFacilityService, LCEFacilityTownHallStampLoadService, LCEFacilityType} from '@lce/core';
import {XSPagination, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSDataManagerComponent, XSDataManagerOptions, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEFacilityWorkerDataManagerDialogService} from '../facility-worker/lce-facility-worker-data-manager-dialog.service';
import {LCEFacilityWorkerDialogService} from '../facility-worker/lce-facility-worker-dialog.service';
import {LCEFacilitiesFeatureService} from './lce-facilities-feature.service';
import {LCEFacilityDialogService} from './lce-facility-dialog.service';

@Component({
    selector: 'lce-facilities',
    templateUrl: './lce-facilities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEFacilityDialogService, LCEFacilityWorkerDialogService, LCEFacilityWorkerDataManagerDialogService]
})
export class LCEFacilitiesComponent implements OnInit {

    readonly ICON_FACILITY: string = LCE_SHARED_ICON.facility;
    readonly ICON_CHECK: string = LCE_SHARED_ICON.check;

    readonly TR_BASE: string = 'lce.shared.facility.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    readonly ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID: string = XSUtils.uuid() + 'activateDeactivateLoaderID';

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() showBackgroundIcon?: boolean;

    @Input() canOptions?: LCEFacilityCanOptions;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    @ViewChild('resourceAuditFullMenuAction') auditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEFacility>;

    dataManagerOptions: XSDataManagerOptions;

    activateDeactivatePartnerShipError: any;
    activateDeactivatePartnerShipLabel = 'lce.shared.facility.label.activatePartnership';

    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    private subscription: Subscription = new Subscription();

    constructor(
        public facilityService: LCEFacilityService,
        private featureService: LCEFacilitiesFeatureService,
        private facilityDialogService: LCEFacilityDialogService,
        private facilityWorkerDialogService: LCEFacilityWorkerDialogService,
        private facilityWorkerDataManagerDialogService: LCEFacilityWorkerDataManagerDialogService,
        private facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService,
        private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
        this.retrieveStats();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onShowFacilityWorkers(): void {
        this.facilityWorkerDataManagerDialogService.openDataManagerDialog({
            facilityCode: this.dataManager.selectedRowResult.code,
            facilityFullName: this.dataManager.selectedRowResult.fullName
        });
    }

    public canEdit(): boolean {
        return this.canOptions?.update !== false;
    }

    public hasActivateDeactivateError(): boolean {
        return !XSUtils.isNull(this.activateDeactivatePartnerShipError);
    }

    public isActivateDeactivatePartnerShipLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID);
    }

    public onActiveDeactivatePartnerShip(): void {
        let facility: LCEFacility = this.dataManager.selectedRecordData;
        if (facility.certificateOrderRegistration!.registered) this.deactivatePartnerShip(facility.id);
        else this.activatePartnerShip(facility.id);
    };

    public onEdit(): void {
        this.facilityDialogService.openCreateUpdateDialog({
            facility: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: (facilityRecord) => {
                this.dataManager.updateSelectedRecordData(facilityRecord);
                this.dataManager.selectedRowResult.fullName = facilityRecord?.fullName;
            }
        });
    }

    public onCreateFacilityWorker(): void {
        this.facilityWorkerDialogService.openCreateUpdateDialog({showRecordAfterSave: true, facility: this.toPartial(this.dataManager.selectedRecordData)});
    }

    public canDoAction(): boolean {
        if (XSUtils.isNull(this.auditFullMenuAction)) return false;
        return this.auditFullMenuAction.canDoAction();
    }

    public can(actionStr: 'showFacilityWorkers' | 'createFacilityWorker' | 'edit'): boolean {
        if (XSUtils.isNull(this.dataManager?.selectedRecordData)) return false;
        const facilityType: LCEFacilityType = this.dataManager.selectedRecordData.type;
        if (this.readonly === false) return false;
        if (actionStr === 'showFacilityWorkers' || actionStr === 'createFacilityWorker') {
            if (facilityType === LCEFacilityType.TOWN_HALL || facilityType === LCEFacilityType.TOWN_HALL_ANNEXE) return false;
        }
        return true;
    }

    private refresh(): void {
        this.retrieveStats();
        this.dataManager.updateSearch();
    }

    private retrieveStats(): void {
        this.statsError = undefined;
        this.statsLoading = true;
        this.subscription.add(
            this.facilityService
                .retrieveStats()
                .pipe(finalize(() => (this.statsLoading = false)))
                .subscribe({
                    next: (facilityStats) => (this.stats = facilityStats),
                    error: (error) => (this.statsError = error)
                })
        );
    }

    private retrieveFacility(selectedRowResult: LCEFacilityPartial): Observable<LCEFacility> {
        return this.facilityService.retrieve(selectedRowResult.id);
    }

    private searchFacilities(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCEFacilityPartial>> {
        const facilitySearch: LCEFacilitySearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.facilityService.search(facilitySearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchFacilities(query, pagination),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveFacility(selectedRowData),

            search: {
                defaultPaginationSize: 20,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultFound'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }

    private toPartial(facility: LCEFacility): LCEFacilityPartial {
        return {
            id: facility.id,
            name: facility.name,
            code: facility.code,
            type: facility.type,
            fullName: facility.fullName,
            subName: facility.subName,
            municipality: facility.municipality
        };
    }

    private activatePartnerShip(facilityID: string): void {
        this.activateDeactivatePartnerShipError = undefined;
        this.loaderService.startLoader(this.ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID);
        this.facilityTownHallStampLoadService.register(facilityID)
            .pipe(finalize(() => this.loaderService.stopLoader(this.ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID)))
            .subscribe(
                {
                    next: (registrationResponse) => {
                        this.activateDeactivatePartnerShipLabel = 'lce.shared.facility.label.deactivatePartnership';

                        this.dataManager.selectedRowResult = {...this.dataManager.selectedRowResult};
                    },
                    error: error => this.activateDeactivatePartnerShipError = error
                }
            );
    }

    private deactivatePartnerShip(facilityID: string): void {
        this.activateDeactivatePartnerShipError = undefined;
        this.loaderService.startLoader(this.ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID);
        this.facilityTownHallStampLoadService.unregister(facilityID)
            .pipe(finalize(() => this.loaderService.stopLoader(this.ACTIVATE_DEACTIVATE_PARTNERSHIP_LOADER_ID)))
            .subscribe(
                {
                    next: (registrationResponse) => {
                        this.activateDeactivatePartnerShipLabel = 'lce.shared.facility.label.activatePartnership';

                        this.dataManager.selectedRowResult = {...this.dataManager.selectedRowResult};
                    },
                    error: error => this.activateDeactivatePartnerShipError = error
                }
            );
    }
}
