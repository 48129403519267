import {Component, Input, OnInit} from '@angular/core';
import {LCECertificateType} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lce-certificate-avatar',
    template: `
        <div class="xs-position-relative {{ styleClass }}" [style.width]="sizePX" [style.height]="sizePX"
             [ngClass]="{ 'xs-border-light xs-border-radius-8 xs-p-15': showBorder !== false }">
            <span class="xs-display-block xs-absolute-center-vh xs-font-size-large {{ letterStyleClass }}">{{ TR_BASE + type | translate }}</span>
        </div>
    `
})
export class LCECertificateAvatarComponent implements OnInit {
    readonly TR_BASE = 'lce.core.certificateTypeLetter.';

    @Input() styleClass?: string;
    @Input() letterStyleClass?: string;

    @Input() type?: LCECertificateType;

    @Input() size?: 'large' | 'medium';

    @Input() showBorder?: boolean;

    constructor() {
    }

    get sizePX(): string {
        if (this.size === 'medium') return '40px';
        else if (this.size === 'large') return '60px';
        throw new Error(`invalid size. [${this.size}] not handled !`);
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.type, 'type');
        if (XSUtils.isNull(this.size)) this.size = 'medium';
    }
}
