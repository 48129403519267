import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LCECertificateOrderPriceDetail, LCECertificateOrderPriceRequest} from '../../../../domain/certificate/order/lce-certificate-order';
import {HttpParams} from '@angular/common/http';
import {LCE_CORE_ENDPOINTS} from '../../../../constants/lce-core-endpoint.constant';
import {XSAssert, XSBaseService, XSCurrency, XSUtils} from '@xs/base';
import {LCEHttpClientService} from '../../../lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderPriceCalculatorService extends XSBaseService {
    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.index);
    }

    public retrievePriceDetailByOrderCreateSessionID(certificateOrderCreateSessionID: string, currency?: XSCurrency): Observable<LCECertificateOrderPriceDetail> {
        XSAssert.notEmpty(certificateOrderCreateSessionID, 'certificateOrderCreateSessionID');
        let params: HttpParams = new HttpParams().set('certificateOrderCreateSessionID', certificateOrderCreateSessionID.trim());

        let internalCurrency = XSUtils.isEmpty(currency) ? XSCurrency.XOF : currency;
        params = params.set('currency', internalCurrency!);

        return this.httpClientService.get<LCECertificateOrderPriceDetail>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.session), params);
    }

    public retrievePriceDetailByRequest(orderPriceRequest: LCECertificateOrderPriceRequest): Observable<LCECertificateOrderPriceDetail> {
        XSAssert.notEmpty(orderPriceRequest, 'orderPriceRequest');
        return this.httpClientService.post<LCECertificateOrderPriceDetail>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.request), orderPriceRequest);
    }
}