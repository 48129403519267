<div [ngClass]="{'xs-border-border': showBorder && !isLoaderRunning()}"
     class="xs-card xs-flex-column xs-position-relative xs-min-width-300 xs-width-full  {{ styleClass }}">

    <div class="xs-card-content">
        <div class="xs-absolute-center-vh">
            <xs-loader #Loader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="!Loader.isRunning()" [ngClass]="{'xs-flex-column xs-justify-content-center': hasError()}">

            <xs-message *ngIf="!hasError()" [key]="SUCCESS_MESSAGE_KEY" [value]="successMessage"></xs-message>
            <xs-message *ngIf="hasError()" [key]="ERROR_MESSAGE_KEY" [value]="errorMessage"></xs-message>

            <div *ngIf="hasError()" class="xs-flex-row xs-justify-content-center xs-mtb-100">
                <div *ngTemplateOutlet="icon; context: {size: 200}"></div>
            </div>

            <div *ngIf="!hasError()"
                 class="xs-card xs-width-full xs-card-border xs-mt-30">
                <div [style.z-index]="'1'" class="xs-sm-display-none xs-opacity-40 xs-position-absolute xs-top-10 xs-right-10">
                    <div *ngTemplateOutlet="icon; context: {size: 150}"></div>
                </div>

                <div class="xs-card-header">
                    <span class="xs-card-header-title xs-color-secondary">{{ TR_BASE + 'orderSummary' | translate }}</span>
                </div>

                <div [style.z-index]="'999'" class="xs-card-content">
                    <div class="xs-flex-column xs-position-relative">

                        <div class="xs-flex-column">
                            <div class="grid grid-nogutter xs-mb-30">
                                <div class="col-12 sm:col-6 md:col-5 xs-mb-2">
                                    <xs-data-field-label [label]="TR_BASE + 'order'"></xs-data-field-label>
                                </div>
                                <div class="col-12 sm:col-6 md:col-7">
                                    <xs-data-field-text
                                            [belowText]="TR_BASE + 'nCopies' | translate: {n: data!.numberOfCopies}"
                                            [value]="data!.orderNumber">
                                    </xs-data-field-text>
                                </div>
                            </div>
                            <div class="grid grid-nogutter xs-mb-30">
                                <div class="col-12 sm:col-6 md:col-5 xs-mb-2">
                                    <xs-data-field-label
                                            [label]="TR_BASE + 'type'"
                                            styleClass="xs-p-0-imp">
                                    </xs-data-field-label>
                                </div>
                                <div class="col-12 sm:col-6 md:col-7 xs-p-0-imp">
                                    <xs-data-field-text
                                            styleClass="xs-p-0-imp"
                                            value="{{ 'lce.core.certificateType.' + data!.certificateType | translate}}">
                                    </xs-data-field-text>
                                </div>
                            </div>

                            <div class="grid grid-nogutter xs-mb-30">
                                <div class="col-12 sm:col-6 md:col-5 xs-mb-2">
                                    <xs-data-field-label
                                            [label]="TR_BASE + 'facility'"
                                            styleClass="xs-p-0-imp">
                                    </xs-data-field-label>
                                </div>
                                <div class="col-12 sm:col-6 md:col-7 xs-p-0-imp">
                                    <xs-data-field-text
                                            [ellipsis]="true"
                                            [valueTooltip]="{text: data!.facilityFullName}"
                                            [value]="data!.facilityFullName"
                                            styleClass="xs-p-0-imp">
                                    </xs-data-field-text>
                                </div>
                            </div>

                            <div class="grid grid-nogutter xs-mb-30">
                                <div class="col-12 sm:col-6 md:col-5 xs-mb-2">
                                    <xs-data-field-label
                                            [label]="TR_BASE + 'deliveryDestination'"
                                            styleClass="xs-p-0-imp">
                                    </xs-data-field-label>
                                </div>
                                <div class="col-12 sm:col-6 md:col-7 xs-p-0-imp">
                                    <lce-delivery-destination
                                            [data]="data!.deliveryDestination">
                                    </lce-delivery-destination>
                                </div>
                            </div>

                            <div *ngIf="data!.chargeTransactionID" class="grid grid-nogutter xs-mb-30">
                                <div class="col-12 sm:col-6 md:col-5 xs-p-0-imp">
                                    <xs-data-field-label
                                            [label]="TR_BASE + 'payment'"
                                            styleClass="xs-p-0-imp">
                                    </xs-data-field-label>
                                </div>
                                <div class="col-12 sm:col-6 md:col-7 xs-p-0-imp">
                                    <xs-amount
                                            [data]="data!.chargeChargedAmount!"
                                            styleClass="xs-pl-2">
                                    </xs-amount>
                                    <span class="xs-font-size-small">{{ TR_BASE + 'paidBy' | translate }}
                                        <b>{{ data!.chargeType | xsPaymentType }} {{
                                                data!.creditCardType ? "-" + (data!.creditCardType |
                                                    xsPaymentCreditCardType) : ''
                                            }}</b>
                                    </span>
                                    <xs-data-field-identifier
                                            *ngIf="showTransactionID"
                                            [copyable]="true"
                                            [ellipsis]="true"
                                            [required]="true"
                                            [value]="data!.chargeTransactionID"
                                            styleClass="=xs-mt-2"
                                            type="id"
                                            valueStyleClass="xs-font-size-extra-small-imp">
                                    </xs-data-field-identifier>
                                </div>
                            </div>
                        </div>
                        <div class="xs-flex-row xs-align-items-center xs-width-full xs-max-width-600 xs-border-discrete xs-border-radius-6 xs-p-10 xs-pb-0-imp">

                            <xs-qrcode
                                    [data]="data!.qrCode"
                                    imgStyleClass="xs-min-width-90 xs-max-width-120"
                                    styleClass="xs-pr-15 xs-pb-10">
                            </xs-qrcode>

                            <div class="xs-flex-column">

                                <xs-data-field-text
                                        [belowText]="data!.customerEmail"
                                        [ellipsis]="true"
                                        [label]="TR_BASE + 'sendTo'"
                                        [required]="false"
                                        [value]="data!.customerPrimaryPhoneNumber | xsPhoneNumber: false"
                                        belowStyleClass="xs-font-weight-600"
                                        belowTextStyleClass="xs-font-weight-600"
                                        styleClass="xs-mb-15"
                                        valueStyleClass="xs-font-size-small">
                                </xs-data-field-text>

                                <!-- TODO: Add verificationCode to certificate order-->
                                <xs-data-field-text [label]="TR_BASE + 'verificationCode'" [required]="false" [value]="data!.verificationCode" styleClass="xs-mb-10"></xs-data-field-text>

                            </div>
                        </div>
                    </div>

                </div>

                <div class="xs-flex-row xs-justify-content-end ">
                    <xs-button
                            (clickEvent)="onNewPayment()"
                            *ngIf="!hasError() && showMakeNewPaymentButton"
                            [label]="TR_BASE + 'makeNewOrder'"
                            size="intermediate"
                            styleClass="xs-mt-5 xs-mr-7"
                            type="text"
                    ></xs-button>
                </div>
            </div>

        </div>
        <div *ngIf="!Loader.isRunning()" class="xs-flex-column-center xs-mt-20">
            <xs-button
                    (clickEvent)="complete()"
                    [label]="TR_BASE + 'complete'"
                    styleClass="xs-width xs-width-250"
                    type="button">
            </xs-button>
        </div>
    </div>

    <ng-template #icon let-size="size">
        <i [style.height]="size + 'px !important'"
           [style.width]="size + 'px !important'"
           class="xs-display-block xs-color-discrete xs-width-fit-content {{ ICON_CERTIFICATE }}">
        </i>
    </ng-template>
</div>