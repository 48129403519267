import {LOG, XSAssert, XSUtils} from '@xs/base';
import {LCEUserCustomer, LCEUserCustomerPartial} from '../domain/user/lce-user-customer';
import {LCEUserMunicipalEmployee, LCEUserMunicipalEmployeePartial} from '../domain/user/lce-user-municipal-employee';
import {LCEUser, LCEUserPartial, LCEUserType} from '../domain/user/lce-user';

export class LCEUserUtils {

    public static toTypedUserPartial(user: LCEUser): LCEUserPartial {
        XSAssert.notEmpty(user, 'user');
        switch (user.type) {
            case LCEUserType.CUSTOMER:
                return LCEUserUtils.toCustomerPartial(user as LCEUserCustomer);
            case LCEUserType.MUNICIPAL_EMPLOYEE:
                return LCEUserUtils.toMunicipalEmployeePartial(user as LCEUserMunicipalEmployee);
            default:
                LOG().error(`user type ${user.type} not handled yet !`);
                return LCEUserUtils.toUserPartial(user);
        }
    }

    public static toCustomerPartial(customer: LCEUserCustomer): LCEUserCustomerPartial {
        return {
            ...LCEUserUtils.toUserPartial(customer),
            createdOn: customer.createdOn,
            registered: customer.registered,
            registeredOn: customer.registeredOn,
            createdFrom: customer.createdFrom,
            registeredFrom: customer.registeredFrom
        };
    }

    public static toMunicipalEmployeePartial(municipalEmployee: LCEUserMunicipalEmployee): LCEUserMunicipalEmployeePartial {
        return {
            ...LCEUserUtils.toUserPartial(municipalEmployee),
            mainRole: municipalEmployee.mainRole,
            position: municipalEmployee.position,
            facilityCode: municipalEmployee.facility.code,
            municipalityCode: municipalEmployee.facility.municipality.code
        };
    }

    public static toUserPartial(user: LCEUser): LCEUserPartial {
        XSAssert.notEmpty(user, 'user');
        return {
            id: user.id,
            type: user.type,
            status: user.status,
            code: user.code,
            name: user.name,
            email: user.email,
            primaryPhoneNumber: user.primaryPhoneNumber,
            language: user.language,
            profileImage: user.profileImage
        };
    }

    public static isUserCustomer(userType: LCEUserType): boolean {
        return userType === LCEUserType.CUSTOMER;
    }

    public static isUserDeliveryMan(userType: LCEUserType): boolean {
        return userType === LCEUserType.DELIVERY_MAN;
    }

    public static isUserMunicipalEmployee(userType: LCEUserType): boolean {
        return userType === LCEUserType.MUNICIPAL_EMPLOYEE;
    }

    public static isUserEmployee(userType: LCEUserType): boolean {
        return userType === LCEUserType.EMPLOYEE;
    }

    public static isUserSystem(userType: LCEUserType, userID?: string): boolean {
        XSAssert.notEmpty(userType, 'userType');
        return userType === LCEUserType.SYSTEM || (!XSUtils.isEmpty(userID) && userID!.toLowerCase().startsWith('lce-system'));
    }
}
