<div class="xs-flex-column xs-width-full xs-min-height-600 xs-flex-1 {{ styleClass }}">
    <xs-data-manager #dataManager [options]="dataManagerOptions">
        <ng-template xsTemplate="sort">
            <xs-coming-soon [showBorder]="true" [showIcon]="true" text="lce.shared.label.comingSoon"></xs-coming-soon>
        </ng-template>

        <!-- === Below Search Template === -->
        <ng-template xsTemplate="belowSearch">
            <div class="xs-flex-column-center xs-width-full xs-mt-25 xs-mb-15">
                <p-selectButton
                        (onChange)="onBatchOrderStatusSelect()"
                        [(ngModel)]="selectedBatchOrderState"
                        [options]="batchOrderStateItems"
                        class="xs-width-full xs-max-width-350"
                        styleClass="xs-select-button xs-select-button-small">
                </p-selectButton>
            </div>
        </ng-template>

        <!-- === Row Result Template ===-->
        <ng-template let-oneResult xsTemplate="row">
            <lce-certificate-order-batch-process-partial [data]="oneResult" styleClass="xs-pr-15">
            </lce-certificate-order-batch-process-partial>
        </ng-template>

        <!-- === Record Template ===-->
        <ng-template let-recordData xsTemplate="record">
            <lce-certificate-order-batch-process-record
                    (completeEvent)="onCompleteEvent($event)"
                    (reopenEvent)="onReopenEvent($event)"
                    [data]="recordData"
                    [readonly]="readonly">
            </lce-certificate-order-batch-process-record>
        </ng-template>
    </xs-data-manager>
</div>

<!-- === Dialog Header Template === -->
<ng-template #dHeader>
    <div class="xs-flex-row xs-align-items-center xs-width-full">
        <xs-ivar class="xs-width-percent-70-imp" line1="{{ TR_BASE }}header" line2="{{ TR_BASE }}subHeader" styleClass="xs-dialog-header">
        </xs-ivar>
    </div>
</ng-template>