import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    LCEBirthDeclarationPartial,
    LCECertificateOrderPartial,
    LCEDistrictPartial,
    LCEFacilityWorker,
    LCEFacilityWorkerPartial,
    LCEGlobalSearchResult,
    LCEGlobalSearchResultMap,
    LCEMunicipalityPartial,
    LCENewsArticlePartial,
    LCEResourceType,
    LCEServicePointPartial,
    LCESmartCityArticlePartial
} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {Router} from '@angular/router';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEBirthDeclarationDialogService} from '../../birth-declaration/lce-birth-declaration-dialog.service';
import {LCEDistrictDialogService} from '../../district/lce-district-dialog.service';
import {LCEFacilityWorkerDialogService} from '../../facility-worker/lce-facility-worker-dialog.service';
import {LCEFacilityDialogService} from '../../facility/lce-facility-dialog.service';
import {LCEMunicipalityDialogService} from '../../municipality/lce-municipality-dialog.service';
import {LCEServicePointDialogService} from '../../service-point/lce-service-point-dialog.service';
import {LCECertificateOrderDialogService} from '../../certificate-order/lce-certificate-order-dialog.service';
import {LCESmartCityArticleDialogService} from '../../smartcity/lce-smartcity-article-dialog.service';
import {LCENewsArticleDialogService} from '../../news/lce-news-article-dialog.service';

@Component({selector: 'lce-global-search-input-result', templateUrl: './lce-global-search-input-result.component.html'})
export class LCEGlobalSearchInputResultComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.globalSearch.';

    readonly RESOURCE_TYPE = LCEResourceType;

    @Input() styleClass?: string;

    @Input() resultsMap: LCEGlobalSearchResultMap;

    @Input() showIcon: boolean;

    @Input() searchText: string;

    @Output() selectEvent = new EventEmitter<any>();

    constructor(
        private router: Router,
        private birthDeclarationDialogService: LCEBirthDeclarationDialogService,
        private districtDialogService: LCEDistrictDialogService,
        private facilityWorkerDialogService: LCEFacilityWorkerDialogService,
        private facilityDialogService: LCEFacilityDialogService,
        private municipalityDialogService: LCEMunicipalityDialogService,
        private servicePointDialogService: LCEServicePointDialogService,
        private certificateOrderDialogService: LCECertificateOrderDialogService,
        private newsArticleDialogService: LCENewsArticleDialogService,
        private smartCityArticleDialogService: LCESmartCityArticleDialogService) {
    }

    ngOnInit() {
        XSAssert.notNull(this.resultsMap, 'resultsMap');
    }

    public onResultClick(data: any, resourceType: LCEResourceType) {
        switch (resourceType) {
            case LCEResourceType.CERTIFICATE_ORDER:
                const certificateOrder: LCECertificateOrderPartial = data as LCECertificateOrderPartial;
                this.certificateOrderDialogService.openRecordDialog({
                    orderID: certificateOrder.id,
                    orderNumber: certificateOrder.orderNumber
                });
                break;
            case LCEResourceType.MUNICIPALITY:
                const municipality: LCEMunicipalityPartial = data as LCEMunicipalityPartial;
                this.municipalityDialogService.openRecordDialog({municipalityID: municipality.id});
                break;
            case LCEResourceType.BIRTH_DECLARATION:
                const birthDeclaration: LCEBirthDeclarationPartial = data as LCEBirthDeclarationPartial;
                this.birthDeclarationDialogService.openRecordDialog({birthDeclarationID: birthDeclaration.id});
                break;
            case LCEResourceType.DISTRICT:
                const district: LCEDistrictPartial = data as LCEDistrictPartial;
                this.districtDialogService.openDistrictRecordDialog({districtID: district.id});
                break;
            case LCEResourceType.FACILITY_WORKER:
                const facilityWorker: LCEFacilityWorkerPartial = data as LCEFacilityWorkerPartial;
                this.facilityWorkerDialogService.openRecordDialog({facilityWorkerID: facilityWorker.id});
                break;
            case LCEResourceType.FACILITY:
                const facility: LCEFacilityWorker = data as LCEFacilityWorker;
                this.facilityDialogService.openRecordDialog({facilityID: facility.id});
                break;
            case LCEResourceType.SERVICE_POINT:
                const servicePoints: LCEServicePointPartial = data as LCEServicePointPartial;
                this.servicePointDialogService.openRecordDialog({servicePointID: servicePoints.id});
                break;
            case LCEResourceType.NEWS_ARTICLE:
                const newsArticle: LCENewsArticlePartial = data as LCENewsArticlePartial;
                this.newsArticleDialogService.openArticleDialog({articleID: newsArticle.id});
                break;
            case LCEResourceType.SMARTCITY_ARTICLE:
                const smartCityArticle: LCESmartCityArticlePartial = data as LCESmartCityArticlePartial;
                this.smartCityArticleDialogService.openArticleDialog({articleID: smartCityArticle.id});
                break;
        }
        this.selectEvent.emit(data);
    }

    public onShowAll(): void {
        this.router.navigate(['/global-search'], {queryParams: {query: this.searchText}}).then();
        this.selectEvent.emit();
    }

    public getResult(resourceType: LCEResourceType): LCEGlobalSearchResult<any> {
        return this.resultsMap[resourceType]!;
    }

    public hasResult(resourceType: LCEResourceType): boolean {
        const result = this.getResult(resourceType);
        return !XSUtils.isEmpty(result) && result.total > 0;
    }

    public isUserType(resourceType: LCEResourceType): boolean {
        return resourceType === LCEResourceType.CUSTOMER ||
            resourceType === LCEResourceType.MUNICIPAL_EMPLOYEE ||
            resourceType === LCEResourceType.EMPLOYEE ||
            resourceType === LCEResourceType.DELIVERY_MAN;
    }

}
