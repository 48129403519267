import {XSAddressStructured, XSAddressType, XSAddressUnstructured, XSCoordinate} from '@xs/base';
import {LCEDistrictPartial} from '../domain/lce-district';
import {LCEFacilityPartial, LCEFacilityType} from '../domain/facility/lce-facility';
import {LCEMunicipalityPartial} from '../domain/lce-municipality';
import {LCEServicePointPartial} from '../domain/lce-service-point';
import {LCEFacilityWorkerPartial, LCEFacilityWorkerPosition} from '../domain/lce-facility-worker';

export const LCE_DISTRICT_ABIDJAN: LCEDistrictPartial = {
    id: 'c5eb1f46-ef73-475f-9262-03c765551db4',
    code: 'LCE-DST-ABJ',
    name: 'Abidjan',
    fullName: 'District Autonome d\'Abidjan',
    abbreviation: 'ABJ'
};

export const LCE_MUNICIPALITY_YOPOUGON: LCEMunicipalityPartial = {
    id: '7067ab2e-6298-42e7-919e-a4a446cdee4e',
    code: 'LCE-MUN-ABJ-YOP',
    name: 'Yopougon',
    fullName: 'Commune de Yopougon',
    district: LCE_DISTRICT_ABIDJAN
};

export const LCE_FACILITY_PARTIAL_YOPOUGON: LCEFacilityPartial = {
    id: '2260833f-fce8-434d-bd00-51b2e5008f78',
    type: LCEFacilityType.TOWN_HALL,
    code: 'LCE-FAC-ABJ-YOP-2503',
    name: 'Yopougon',
    fullName: 'Mairie de Yopougon Selmer',
    municipality: LCE_MUNICIPALITY_YOPOUGON
};

export const LCE_SERVICE_POINT_YOPOUGON_COSMOS: LCEServicePointPartial = {
    id: '5c3becdb-895e-44fd-86ff-19abb563537d',
    code: 'LCE-SEP-ABJ-YOP-3267',
    name: 'Cosmos Yopougon Selmer',
    fullName: 'Point de Services Cosmos Yopougon Selmer',
    subName: 'Cosmos',
    municipality: LCE_MUNICIPALITY_YOPOUGON
};

export const LCE_MOCK_FACILITY_WORKER_MIDWIFE: LCEFacilityWorkerPartial = {
    id: '805c8b1f-7165-4b4b-8534-88494fcd5dfe',
    code: 'LCE-FAW-010203',
    name: {
        firstName: 'Thérèse',
        lastName: 'Koffi'
    },
    primaryPhoneNumber: '+2250101010122',
    position: LCEFacilityWorkerPosition.MIDWIFE,
    facilityCode: 'LCE-FAC-ABJ-YOP-5665',
    facilityFullName: 'Hôpital Général de Yopougon'
};

export const LCE_MOCK_COORDINATE_PHARMACIE_ANALYA: XSCoordinate = {latitude: 5.4027176999999975, longitude: -3.961497273014281};

export const LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA: XSAddressUnstructured = {
    type: XSAddressType.UNSTRUCTURED,
    title: 'Pharmacie Analya',
    line1: 'À Proximité du nouveau CHU Angré',
    city: 'Abidjan',
    countryISO: 'ci',
    coordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA
};

export const LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON: XSAddressStructured = {
    type: XSAddressType.STRUCTURED,
    line1: '1480 gaston-vérroneau',
    line2: 'Apartment #429',
    postalCode: 'j4n 0g9',
    province: {name: 'quebec', code: 'qc'},
    city: 'montreal',
    countryISO: 'ca'
};
