<div [ngClass]="{ 'xs-border-discrete xs-p-15 xs-border-radius-default': showBorder === true }"
     class="xs-flex-column xs-width-full {{ styleClass }}">
    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="{type: 'icon', data: ICON.certificateOrder}" [subtitle]="TR_BASE + 'create.subTitle'" [title]="TR_BASE + 'create.title'"></xs-header-stripe>
        </div>
        <div [ngClass]="{ 'xs-disabled': isCreateLoaderRunning() }" class="xs-card-content xs-align-items-center xs-pt-25-imp">

            <div class="xs-width-full">

                <div class="xs-container-left-stripe xs-container-left-stripe-light-imp xs-mb-40">
                    <div class="grid">
                        <div class="col-12 md:col-6 xs-pr-15">
                            <xs-input-field-text [options]="orderUnitPriceField"></xs-input-field-text>
                        </div>
                        <div class="col-12 md:col-6 xs-pl-15">
                            <xs-input-field-text [options]="orderFeeField"></xs-input-field-text>
                        </div>
                    </div>
                </div>

                <div class="xs-container-left-stripe xs-container-left-stripe-light-imp xs-mb-60">
                    <xs-input-field-radios [options]="registrationTypeField" styleClass="xs-mt-25"></xs-input-field-radios>
                </div>

                <div class="xs-width-full xs-flex-row xs-align-items-center">
                    <xs-avatar [data]="ICON.info" size="extra-small" styleClass="xs-color-secondary xs-mr-15" type="icon"></xs-avatar>
                    <div>
                        <span class="xs-display-block xs-color-secondary xs-font-size-intermediate">{{ TR_BASE + 'create.label.infoLine1' | translate }}</span>
                        <span class="xs-display-block xs-color-secondary xs-font-size-small xs-mt-1">{{ TR_BASE + 'create.label.infoLine2' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!isDialog()" class="xs-card-footer xs-card-footer-separator xs-pt-15-imp">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-header-stripe [avatar]="{type: 'icon', data: ICON.certificateOrder}" [subtitle]="TR_BASE + 'create.subTitle'" [title]="TR_BASE + 'create.title'"></xs-header-stripe>
</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-justify-content-space-between">
        <div class="xs-flex-row xs-position-relative">
            <xs-loader #loaderCreate [loaderId]="CREATE_UPDATE_LOADER_ID" class="xs-absolute-center-vh"></xs-loader>
            <xs-error
                    *ngIf="hasCreateUpdateError() && !loaderCreate.isRunning()"
                    [error]="createUpdateError"
                    [message]="TR_BASE + 'create.label.createUpdateErrorMessage'"
                    [showErrorDetailButton]="true"
                    mode="inline">
            </xs-error>
        </div>
        <div class="xs-flex-row">
            <xs-button
                    (clickEvent)="closeDialog()"
                    [confirmation]="closeConfirmation"
                    [disabled]="isCreateLoaderRunning()"
                    [outlined]="true"
                    [showConfirmation]="shouldShowCloseConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.cancel"
                    size="intermediate"
                    styleClass="xs-mlr-30-imp"
                    type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="createUpdate()"
                    [disabled]="isCreateLoaderRunning()"
                    label="xs.core.label.save"
                    type="button">
            </xs-button>
        </div>
    </div>
</ng-template>
