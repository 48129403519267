import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECoreContextService, LCEServicePoint, LCEServicePointCanOptions, LCEServicePointService} from '@lce/core';
import {LOG, XSAddress, XSContact, XSContactPerson, XSUtils} from '@xs/base';
import {XSLoaderService, XSSize} from '@xs/common';
import {XSAddressFieldOptions, XSAvatar, XSButton, XSContactPersonFieldOptions, XSDataManagerComponent, XSDialogable, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-service-point-record',
    templateUrl: './lce-service-point-record.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEServicePointRecordComponent extends XSDialogable implements OnInit {

    readonly ICON_SERVICE_POINT: string = LCE_SHARED_ICON.servicePoint;
    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.servicePoint.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() servicePointID?: string;
    @Input() servicePoint?: LCEServicePoint;

    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;

    @Input() canOptions?: LCEServicePointCanOptions;

    @Output() editEvent = new EventEmitter<LCEServicePoint>();
    @Output() closeEvent = new EventEmitter<LCEServicePoint>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEServicePoint>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieveServicePoint()
    };

    addressFieldOptions: XSAddressFieldOptions;
    primaryContactPersonFieldOptions: XSContactPersonFieldOptions;
    secondaryContactPersonFieldOptions: XSContactPersonFieldOptions;

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCECoreContextService,
        public servicePointService: LCEServicePointService,
        private loaderService: XSLoaderService) {
        super();
    }

    get headerAvatar(): XSAvatar {
        return {type: 'icon', size: XSSize.SMALL, data: this.ICON_SERVICE_POINT};
    }

    get headerTitle(): string {
        return XSUtils.isEmpty(this.servicePoint) ? this.TR_BASE_LABEL + 'servicePoint' : this.servicePoint!.fullName;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.servicePoint) ? '...' : this.servicePoint!.municipality.fullName;
    }

    get servicePointContact(): XSContact {
        return {
            primaryPhoneNumber: this.servicePoint?.contactPhoneNumber,
            email: this.servicePoint?.contactEmail
        };
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.servicePointID = this.dialogConfig.data.servicePointID;
            this.servicePoint = this.dialogConfig.data.servicePoint;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.servicePoint));
        }
        if (XSUtils.isEmpty(this.servicePointID) && XSUtils.isEmpty(this.servicePoint)) {
            throw new Error('servicePointID and servicePoint cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.servicePointID) && !XSUtils.isEmpty(this.servicePoint)) {
            throw new Error('servicePointID and servicePoint cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.servicePointID)) this.retrieveServicePoint();
        else this.initialize();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public onEdit(): void {
        this.editEvent.emit(this.servicePoint);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.servicePoint);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    retrieveServicePoint(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.servicePointService
                .retrieve(this.servicePointID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (servicePoint: LCEServicePoint) => {
                        this.servicePoint = servicePoint;
                        this.initialize();
                    },
                    error: error => (this.error = error)
                })
        );
    }

    private initialize(): void {
        this.buildAddressFieldOptions();
        this.buildContactPersonFieldOptions();
    }

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            formOptions: {
                type: this.servicePoint?.address?.type ? this.servicePoint.address.type : undefined!,
                onSave: (address: XSAddress) => {
                    LOG().debug('Saving Service Point Address (' + this.servicePoint!.id + ') ...', address);
                    return this.servicePointService.updateAddress(this.servicePoint!.id, address)
                        .pipe(tap(response => {
                            this.servicePoint!.address = response.address;
                            this.servicePoint!.updatedOn = response.updatedOn;
                            this.servicePoint!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
    }

    private buildContactPersonFieldOptions(): void {
        this.primaryContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.servicePoint!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving Service Point Primary Contact Person (' + this.servicePoint!.id + ') ...', contactPerson);
                    return this.servicePointService.updatePrimaryContactPerson(this.servicePoint!.id, contactPerson)
                        .pipe(tap(response => {
                            this.servicePoint!.primaryContactPerson = response.primaryContactPerson;
                            this.servicePoint!.updatedOn = response.updatedOn;
                            this.servicePoint!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
        this.secondaryContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.servicePoint!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving Service Point Secondary Contact Person (' + this.servicePoint!.id + ') ...', contactPerson);
                    return this.servicePointService.updateSecondaryContactPerson(this.servicePoint!.id, contactPerson)
                        .pipe(tap(response => {
                            this.servicePoint!.secondaryContactPerson = response.secondaryContactPerson;
                            this.servicePoint!.updatedOn = response.updatedOn;
                            this.servicePoint!.updatedBy = this.contextService.getUser();
                        }));
                }
            },
            onDelete: (contactPerson: XSContactPerson) => {
                LOG().debug('Removing Service Point Secondary Contact Person (' + this.servicePoint!.id + ') ...', contactPerson);
                return this.servicePointService.updateSecondaryContactPerson(this.servicePoint!.id, undefined)
                    .pipe(tap(response => {
                        this.servicePoint!.secondaryContactPerson = response.secondaryContactPerson;
                        this.servicePoint!.updatedOn = response.updatedOn;
                        this.servicePoint!.updatedBy = this.contextService.getUser();
                    }));
            }
        };
    }
}
