import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCESmartCityArticleCard, LCESmartCityArticleService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSButton} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
	selector: 'lce-dashboard-smartcity-last-article',
	templateUrl: './lce-dashboard-smartcity-last-article.component.html'
})
export class LCEDashboardSmartCityLastArticleComponent implements OnInit, OnDestroy {

	readonly ICON = LCE_SHARED_ICON;

	readonly TR_BASE = 'lce.shared.dashboard.smartCityLastArticle.';

	@Input() styleClass?: string;

	@Input() municipalityCode?: string;

	error: any;
	errorRetryButton: XSButton = {
		type: 'text',
		label: 'xs.core.label.pleaseTryAgain',
		size: 'intermediate',
		icon: this.ICON.redo,
		onClick: () => this.update()
	};

	loading: boolean = false;

	articleCard?: LCESmartCityArticleCard;

	private subscription: Subscription = new Subscription();

	constructor(private smartcityArticleService: LCESmartCityArticleService) {
	}

	ngOnInit(): void {
		this.update();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public update(): void {
		this.loading = true;
		this.error = undefined;
		this.subscription.add(
			this.smartcityArticleService
				.findNLastAsCards(this.municipalityCode, 1)
				.pipe(finalize(() => this.loading = false))
				.subscribe({
					next: (articleCards: LCESmartCityArticleCard[]) => {
						this.articleCard = XSUtils.isEmpty(articleCards) ? undefined : articleCards[0];
						this.loading = false;
					},
					error: error => this.error = error
				})
		);
	}

	public hasError() {
		return !XSUtils.isEmpty(this.error);
	}
}