import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_CORE_LABEL, LCE_TR_BASE_REQUESTOR_TYPE, LCECertificateOrder} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';
import {LCEFacilityTownHallStampTokenDialogService} from '../../../../facility-town-hall-stamp/token/lce-facility-town-hall-stamp-token-dialog.service';
import {LCESharedService} from '../../../../api/services/lce-shared.service';

@Component({
    selector: 'lce-certificate-order-detail',
    templateUrl: './lce-certificate-order-detail.component.html',
    providers: [LCEFacilityTownHallStampTokenDialogService]
})
export class LCECertificateOrderDetailComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = LCE_TR_BASE_CORE_LABEL;
    readonly TR_BASE_REQUESTOR_TYPE = LCE_TR_BASE_REQUESTOR_TYPE;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() data: LCECertificateOrder;

    @Input() stampTokenClickable?: boolean;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;

    constructor(
        private sharedService: LCESharedService,
        private stampTokenDialogService: LCEFacilityTownHallStampTokenDialogService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (XSUtils.isNull(this.showHeader)) this.showHeader = true;
        if (XSUtils.isNull(this.stampTokenClickable)) this.stampTokenClickable = true;

    }

    public openBatchProcessRecordDialog(): void {
        this.sharedService.emitOpenCertificateOrderBatchProcessDialog({batchProcessCode: this.data.batchProcessCode});
    }

    public openStampTokenRecordDialog(): void {
        if (!this.stampTokenClickable) return;
        this.stampTokenDialogService.openRecordDialog({stampTokenCode: this.data.stampTokenCode});
    }
}
