import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCEHttpClientService, LCEWebSocketService} from '@lce/core';
import {LOG, XS_STORAGE_KEY_LANGUAGE, XS_STORAGE_KEY_TOKEN, XS_STORAGE_KEY_TOKEN_EXPIRATION, XSAssert, XSLanguage, XSLoggerService, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XS_IMAGE_NOT_FOUND_SRC, XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC, XSAppInitializerCoreService, XSAuthenticationOptions, XSImageService, XSLocalStorageService} from '@xs/core';
import {PrimeNGConfig} from 'primeng/api';
import {LCECMDEnvironmentService} from './lce-cmd-environment.service';
import {LCECMDContextService} from './lce-cmd-context.service';
import {LCECMDAuthenticationService} from './lce-cmd-authentication.service';
import {LCECMDAppConfig} from '../domain/lce-cmd-app-config';
import {environment} from '../../../environments/environment';
import {LCECMDAppInitialData} from '../domain/lce-cmd-app-initial-data';
import {LCE_CMD_ENDPOINT} from '../constants/lce-cmd-endpoint.constant';
import {LCECMDInitialDataService} from './lce-cmd-initial-data.service';

@Injectable({providedIn: 'root'})
export class LCECMDAppInitializerService extends XSAppInitializerCoreService {

    constructor(
        private router: Router,
        protected primeNGConfig: PrimeNGConfig,
        protected translationService: XSTranslationService,
        private environmentService: LCECMDEnvironmentService,
        private httpClientService: LCEHttpClientService,
        private contextService: LCECMDContextService,
        private authenticationService: LCECMDAuthenticationService,
        private localStorageService: XSLocalStorageService,
        private loggerService: XSLoggerService,
        private imageService: XSImageService,
        private webSocketService: LCEWebSocketService,
        private initialDataService: LCECMDInitialDataService
    ) {
        super(primeNGConfig, translationService);
    }

    public async initialize(): Promise<void> {
        try {
            LOG().debug('Initializing App ...');

            // Force the browser to get the latest version. Does not use the cache.
            const params = new HttpParams().set('v', XSUtils.uuid());
            const appConfig: LCECMDAppConfig = await this.httpClientService.get<LCECMDAppConfig>(this.CONFIG_JSON_PATH, params).toPromise();
            LOG().debug('[' + appConfig.environment.toUpperCase() + '] Config Successfully Retrieved.', appConfig);

            this.loggerService.initialize(appConfig.logger);
            this.contextService.setAppConfig(appConfig);
            this.configLoadedSubject.next(appConfig);

            if (environment.mock === true) {
                // Wait for the mock initialization to be done.
                await new Promise((resolve) => setTimeout(resolve, 500));
            }

            const languageFromLocalStorage: XSLanguage = this.localStorageService.getItem(XS_STORAGE_KEY_LANGUAGE) as XSLanguage;
            let language: XSLanguage = XSUtils.isEmpty(languageFromLocalStorage) ? this.environmentService.getDefaultLanguage() : languageFromLocalStorage;

            this.httpClientService.initialize(appConfig.apiBaseURL, language);
            const token: string | undefined = this.localStorageService.getItem(XS_STORAGE_KEY_TOKEN);
            if (!XSUtils.isEmpty(token)) this.httpClientService.setHeaderAuthorization(token!);

            const appInitialData: LCECMDAppInitialData = await this.retrieveAppInitialData();

            this.initializeAuthenticationService(appInitialData);

            await this.imageService.cacheImage(XS_IMAGE_NOT_FOUND_SRC).toPromise();
            await this.imageService.cacheImage(XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC).toPromise();
            await this.configurePrimeNGTranslation();

            this.webSocketService.initialize(appConfig.webSocketServerURL);

            if (XSUtils.isEmpty(appInitialData.authenticatedUser)) {
                await this.translationService.initialize(language);
                this.httpClientService.removeHeaderAuthorization();
                this.localStorageService.remove(XS_STORAGE_KEY_TOKEN);
                this.localStorageService.remove(XS_STORAGE_KEY_TOKEN_EXPIRATION);
                LOG().info('Initialization DOne, redirecting toward the authentication page.');
                this.router.navigateByUrl('authentication/login').then();
            } else {
                await this.initialDataService.initialize(appInitialData);
                LOG().info('Initialization Done :-) .');
            }
        } catch (error) {
            LOG().error('Failed to initialize LCE CMD:-(.', error);
            console.error(error);
            this.router.navigateByUrl('/error').then();
        }
    }

    private async retrieveAppInitialData(): Promise<LCECMDAppInitialData> {
        let appInitialData: LCECMDAppInitialData;
        try {
            appInitialData = await this.httpClientService.get<LCECMDAppInitialData>(LCE_CMD_ENDPOINT.initialization).toPromise();
            LOG().debug('App Initialization Data Successfully Retrieved.', appInitialData);
        } catch (error) {
            if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.NotFound) {
                // In case the token was not found.
                this.clearAuthentication();
                appInitialData = await this.httpClientService.get<LCECMDAppInitialData>(LCE_CMD_ENDPOINT.initialization).toPromise();
                LOG().debug('App Initialization Data Successfully Retrieved [Previous Authentication Data Cleared].', appInitialData);
            } else throw error;
        }
        return appInitialData;
    }

    private clearAuthentication(): void {
        this.authenticationService.clearUser(); // Doesn't clear the user from the local storage. Only in the authentication service.
        this.httpClientService.removeHeaderAuthorization();
        this.localStorageService.remove(XS_STORAGE_KEY_TOKEN);
        this.localStorageService.remove(XS_STORAGE_KEY_TOKEN_EXPIRATION);
        LOG().debug('Authentication Cleared !');
    }

    private initializeAuthenticationService(appInitialData: LCECMDAppInitialData): void {
        XSAssert.notEmpty(appInitialData, 'appInitialData');

        let authenticationSettings: XSAuthenticationOptions = {
            faqURL: 'https://google.com',
            helpDeskInformation: appInitialData.helpDeskInformation,
            allowPhoneNumberAuthentication: appInitialData.cmdSettings.authenticationPrimaryPhoneNumberAsUsername,
            numberOfFailedAttemptsAllowed: 5,
            passwordMaxLength: 10,
            resetPasswordVerificationCodeLength: 6,
            passwordVerificationMaxFailedAttempts: 4,
            passwordVerificationTimeIntervalInSeconds: 3600
        };

        this.authenticationService.initialize(authenticationSettings);
    }
}
