<div class="xs-flex-column-center xs-width-full xs-position-relative xs-border-discrete xs-border-radius-default xs-p-15 {{ styleClass }}">
    <!-- === Statistics === -->
    <div class="xs-flex-column xs-width-full xs-pt-10">

        <div [ngClass]="{'xs-justify-content-end': municipalityCode | xsIsEmpty}" class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
            <div *ngIf="municipalityCode | xsIsNotEmpty" class="xs-flex-row xs-align-items-center">
                <div class="xs-display-block xs-pr-15 xs-border-right-discrete">
                    <lce-certificate-orders-pending-orders-square #pendingOrdersSquare (clickEvent)="onPendingOrdersClick()" [municipalityCode]="municipalityCode!">
                    </lce-certificate-orders-pending-orders-square>
                </div>
                <div class="xs-display-block xs-pl-15">
                    <lce-certificate-order-last
                        #lastOrder
                        [municipalityCode]="municipalityCode!"
                        [orderNumberClickable]="true"
                        [showBorder]="false"
                        [showLabel]="true"
                        class="xs-min-width-150 xs-height-55">
                    </lce-certificate-order-last>
                </div>
            </div>

            <lce-certificate-order-batch-processes-state #batchProcessesState [facilityCode]="facilityCode" styleClass="xs-mt-10"></lce-certificate-order-batch-processes-state>
        </div>

        <hr class="xs-hr xs-hr-discrete xs-mtb-30-imp">

        <lce-certificate-order-data-boxes
            #dataBoxes
            (mainValueEvent)="handleDataBoxesCOrderMainValueEvent($event)"
            [swiper]="false">
        </lce-certificate-order-data-boxes>

    </div>
</div>
