export const LCE_CORE_ENDPOINTS = {
    // --- Dispatcher
    dispatcher: {
        index: 'certificate-orders/deliveries/dispatcher',
        assign: 'certificate-orders/deliveries/dispatcher/assign',
        unassign: 'certificate-orders/deliveries/dispatcher/unassign'
    },
    // --- Assignments
    assignments: {
        index: 'certificate-orders/deliveries/assignments',
        assign: 'certificate-orders/deliveries/assignments/assign',
        unassign: 'certificate-orders/deliveries/assignments/unassign',
        assignOne: 'certificate-orders/deliveries/assignments/assign-one',
        unassignOne: 'certificate-orders/deliveries/assignments/unassign-one'
    },
    // --- Terminal
    terminals: {
        index: 'terminals',
        register: 'terminals/register',
        generateSecurityCode: 'terminals/${terminalID}/security-code/generate'
    },
    // --- Payment
    payments: {
        charges: {
            index: 'charges',
            statistics: 'statistics'
        },
        transfers: {
            index: 'transfers'
        },
        refunds: {
            index: 'refunds'
        }
    },
    // --- Delivery Company
    deliveryCompanies: {
        index: 'delivery-companies',
        unregister: '{deliveryCompanyID}/unregister',
        register: '{deliveryCompanyID}/register'
    },
    // --- Event ---
    events: {
        index: 'events',
        search: 'search'
    },
    // --- Dashboard ---
    dashboard: {
        index: 'dashboard',
        statsGlobal: 'stats/global'
    },
    // --- Global Search ---
    globalSearch: 'global-search',
    // --- Notification ---
    notifications: {
        index: 'notifications'
    },
    // --- Smart City ---
    smartCity: {
        articles: {
            index: 'smart-city/articles',
            code: 'smart-city/articles/${articleCode}/code',
            searchAsCards: 'cards/search',
            lastAsCards: 'last',
            tags: 'tags',
            autocompleteTags: 'tags/autocomplete'
        },
        reviewers: {
            index: 'smart-city/articles'
        },
        categories: {
            index: 'smart-city/articles/categories'
        }
    },
    // --- News
    news: {
        articles: {
            index: 'news/articles',
            searchAsCards: 'cards/search',
            lastAsCards: 'last',
            tags: 'tags',
            autocompleteTags: 'tags/autocomplete',
            code: 'smart-city/articles/${articleCode}/code'
        },
        comments: {
            index: 'news/articles/comments',
            create: 'news/articles/${articleID}/comments',
            search: 'search',
            report: 'news/articles/${articleID}/comments/${commentID}/report'
        },
        reviewers: {
            index: 'news/articles'
        },
        categories: {
            index: 'news/articles/categories'
        }
    },
    // --- Article Reviewers ---
    articleReviewers: {
        defaults: 'reviewers/defaults',
        reviewers: '{articleID}/reviewers',
        review: '{articleID}/reviewers/{reviewerID}/review'
    },
    // --- Birth Declaration
    birthDeclarations: {
        index: 'birth-declarations'
    },
    // --- Facility Worker
    facilityWorker: {
        index: 'facility-worker'
    },
    // --- Suggestions ---
    suggestions: {
        index: 'suggestions',
        status: 'status',
        statsStatuses: 'stats/statuses'
    },
    // --- Certificate Order Create Sessions  ---
    certificateOrderCreatePriceCalculator: {
        index: 'certificate-orders/price-calculator',
        session: 'session',
        request: 'request'
    },
    // --- Certificate Order Create Sessions  ---
    certificateOrderCreateSessions: {
        index: 'certificate-orders/sessions',
        update: '{orderCreateSessionID}/update'
    },
    // --- Certificate Orders ---
    certificateOrders: {
        index: 'certificate-orders',
        assign: 'delivery/assign/deliveryMan/{deliveryManID}',
        unAssign: 'delivery/unassign/deliveryMan/{deliveryManID}',
        paymentsConfirmation: 'payments/transactions/{transactionID}/confirmation', // TODO: changeEndpoint
        createMFO: 'mfo',
        last: 'last',
        orderNumbers: 'order-numbers',
        verificationCodes: 'verification-codes',

        priceSettings: 'priceSettings',

        chartLine: 'chart/line',
        chartDoughnut: 'chart/doughnut',

        globalStats: 'stats/global',        // statsGlobal
        countStats: 'stats/count',          // statsCount
        statsFinances: 'stats/payment',

        customers: 'customers',

        deliveryMen: 'deliveryMen',

        status: {
            processing: 'status/processing',
            ready: 'status/ready',
            cancelled: 'status/cancelled',
            error: 'status/error'
        },
        statuses: {
            processing: 'statuses/processing',
            ready: 'statuses/ready',
            cancelled: 'statuses/cancelled',
            error: 'statuses/error'
        }
    },
    // --- Certificates ---
    certificates: {
        index: 'certificates'
    },
    // --- Service Points ---
    servicePoints: {
        index: 'service-points'
    },
    // --- Facilities ---
    facilities: {
        index: 'facilities',
        townHalls: {
            stamps: {
                count: 'facilities/town-halls/{facilityID}/stamps/count',
                tokens: {
                    index: 'facilities/town-halls/stamps/tokens'
                },
                loads: {
                    index: 'facilities/town-halls',
                    create: 'facilities/town-halls/{facilityID}/stamp-loads',
                    last: 'facilities/town-halls/{facilityID}/stamp-loads/last',
                    unregister: 'facilities/town-halls/{facilityID}/certificate-order/unregister',
                    register: 'facilities/town-halls/{facilityID}/certificate-order/register',
                    registration: 'facilities/town-halls/{facilityID}/certificate-order/registration'
                }
            },
            transfers: {
                index: 'facilities/{facilityID}/payment/transfers',
                last: 'facilities/{facilityID}/payment/transfers/last',
                retrieve: 'facilities/{facilityID}/payment/last-transfer/{transferID}'
            }
        }
    },
    // --- Municipalities ---
    municipalities: {
        index: 'municipalities',
        settings: 'settings'
    },
    // --- Districts ---
    districts: {
        index: 'districts'
    },
    // --- Customers ---
    customers: {
        index: 'customers',
        additionalStats: 'stats/additional'
    },
    // --- Delivery Men ---
    deliveryMen: {
        index: 'delivery-men'
    },
    // --- LCE Employees ---
    employees: {
        index: 'employees',
        authentication: {
            login: 'municipal-employees/authentication/login',
            logout: 'municipal-employees/authentication/logout'
        },
        pinCodeUpdate: 'pin-code/update',
        pinCodeGenerate: 'pin-code/generate',
        pinCodePlaintText: 'pin-code/plain-text'
    },
    // --- Municipal Employees ---
    municipalEmployees: {
        index: 'municipal-employees',
        authentication: {
            login: 'municipal-employees/authentication/login',
            logout: 'municipal-employees/authentication/logout'
        },
        pinCodeUpdate: 'pin-code/update',
        pinCodeGenerate: 'pin-code/generate',
        pinCodePlaintText: 'pin-code/plain-text'
    },
    // --- User Accounts ---
    userAccounts: {
        index: 'user-accounts',
        lock: 'lock',
        unlock: 'unlock',
        updatePassword: 'password/update',
        resetPassword: 'password/reset',
        updateForgotPassword: 'forgot-password/password/update',
        verifyCode: 'forgot-password/code/verify',
        sendCode: 'forgot-password/code/send',
        verifyUsername: 'forgot-password/username/verify'
    },
    actuator: {
        info: 'actuator/info',
        health: 'actuator/health'
    },
    initialization: {
        cmd: 'initialization/cmd',
        mbo: 'initialization/mbo',
        mfo: 'initialization/mfo',
        mia: 'initialization/mia'
    }
};
