<div class="xs-width-full {{ styleClass }}">
    <xs-button
            (clickEvent)="delete()"
            [confirmation]="deleteConfirmation"
            [icon]="ICON.delete"
            [loaderId]="LOADER_ID_DELETE"
            [loading]="deleteLoading"
            [showConfirmation]="showDeleteConfirmation === true"
            class="xs-mr-40"
            label="xs.core.label.delete"
            loaderPosition="bottom"
            size="intermediate"
            type="text">
    </xs-button>
    <xs-button
            (clickEvent)="onMenuClick($event)"
            [icon]="ICON.ellipsisVertical"
            [loading]="auditLoading"
            [styleClass]="styleClass"
            loaderPosition="bottom"
            size="small"
            type="text">
    </xs-button>
    <p-tieredMenu #actionMenu [appendTo]="'body'" [model]="menuItems" [popup]="true" styleClass="xs-min-width-225"></p-tieredMenu>
</div>