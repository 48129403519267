import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCE_TR_BASE_USER_TYPE, LCEUserEmployeePartial, LCEUserMunicipalEmployeePartial, LCEUserPartial, LCEUserPositionPipe, LCEUserType} from '@lce/core';
import {XS_STR_EMPTY, XSAssert, XSUtils} from '@xs/base';
import {XSPersonNamePipe, XSPhoneNumberPipe} from '@xs/common';
import {XSIvarAvatarSize, XSIvarAvatarSizeStr} from '@xs/core';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'lce-user-partial',
    templateUrl: './lce-user-partial.component.html',
    providers: [LCEUserPositionPipe, XSPersonNamePipe]
})
export class LCEUserPartialComponent implements OnInit {

    readonly STR_EMPTY = XS_STR_EMPTY;

    @Input() styleClass?: string;
    @Input() avatarStyleClass?: string;
    @Input() personNameStyleClass?: string;
    @Input() userPositionStyleClass?: string;
    @Input() userCodeStyleClass?: string;
    @Input() customLineStyleClass?: string;
    @Input() labelStyleClass?: string;

    @Input() label?: string;
    @Input() showDefaultLabel?: boolean;

    @Input() disabled?: boolean;

    @Input() data: LCEUserPartial;
    @Input() customLine?: string;

    @Input() avatarSize?: XSIvarAvatarSize | XSIvarAvatarSizeStr;

    @Input() showAvatar?: boolean;
    @Input() showOnlyAvatar?: boolean;
    @Input() showPosition?: boolean;
    @Input() showCode?: boolean;

    @Input() showStatus?: boolean;
    @Input() showTooltip?: boolean;

    @Input() viewDetail?: boolean;
    @Input() showViewRecordButton?: boolean;

    @ViewChild('detailOverlay') detailOverlay: OverlayPanel;

    private readonly TR_ANONYMOUS: string = 'lce.core.label.anonymous';

    constructor(
        private userPositionPipe: LCEUserPositionPipe,
        private phoneNumberPipe: XSPhoneNumberPipe,
        private personNamePipe: XSPersonNamePipe) {
    }

    get dataLine1(): string {
        if (XSUtils.isEmpty(this.data.name)) {
            return this.TR_ANONYMOUS;
        }
        return this.personNamePipe.transform(this.data.name);
    }

    get dataLine2(): string {
        if (this.data.type === LCEUserType.EMPLOYEE || this.data.type === LCEUserType.MUNICIPAL_EMPLOYEE) {
            const userPartial = this.data as LCEUserEmployeePartial | LCEUserMunicipalEmployeePartial;
            return this.showPosition ? this.userPositionPipe.transform(userPartial.position, this.data.type) : XS_STR_EMPTY;
        }
        return this.phoneNumberPipe.transform(this.data.primaryPhoneNumber);
    }

    get dataLine3(): string {
        return this.showCode === true ? this.data.code : XS_STR_EMPTY;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (this.data.type === LCEUserType.CUSTOMER) {
            this.showStatus = false;
        }
        if (this.showDefaultLabel === true && XSUtils.isEmpty(this.label)) {
            this.label = LCE_TR_BASE_USER_TYPE + this.data.type;
        }
        this.handleDefaults();
    }

    public onLine1Click(event: any): void {
        if (this.viewDetail === true) {
            this.detailOverlay.toggle(event);
        }
    }

    public onAvatarClick(event: any) {
        if (this.viewDetail === true) {
            this.detailOverlay.toggle(event);
        }
    }

    public hasViewDetailPermission(): boolean {
        return true; // TODO
    }

    public isAvatarSizeSmall(): boolean {
        return this.avatarSize == 'small';
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.showAvatar)) {
            this.showAvatar = true;
        }
        if (XSUtils.isNull(this.showPosition)) {
            this.showPosition = true;
        }
        if (XSUtils.isNull(this.showCode)) {
            this.showCode = false;
        }
        if (XSUtils.isNull(this.avatarSize)) {
            this.avatarSize = XSIvarAvatarSize.MEDIUM;
        }
        if (XSUtils.isNull(this.showOnlyAvatar)) {
            this.showOnlyAvatar = false;
        }

        if (XSUtils.isNull(this.showStatus)) {
            this.showStatus = this.isAvatarSizeSmall() ? false : true;
        }
        if (XSUtils.isEmpty(this.data.status)) {
            this.showStatus = false;
        }

        if (XSUtils.isNull(this.viewDetail)) {
            this.viewDetail = true;
        }
    }
}
