import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECertificateOrderDeliveryAssignment,
    LCECertificateOrderDeliveryAssignmentPartial,
    LCECertificateOrderDeliveryAssignmentRequest,
    LCECertificateOrderDeliveryAssignmentResponse,
    LCECertificateOrderDeliveryAssignmentSearch,
    LCECertificateOrderDeliveryAssignmentStatus,
    LCECertificateOrderDeliveryAssignmentType,
    LCECertificateOrderDeliveryUnassignmentRequest,
    LCECoreContextService,
    LCEUserPartial
} from '@lce/core';
import {XSHttpMethod, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceHandler, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {LCEMockCertificateOrderHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-handler';
import {LCEMockUserDeliveryManHandler} from '@lce/mock/core/handlers/user/lce-mock-user-delivery-man-handler';
import {LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS} from '@lce/mock/core/lce-mock-user-data';
import {LCEMockCommonHandler} from '@lce/mock/core/handlers/lce-mock-common-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificateOrders.delivery.assignment';

@Injectable()
export class LCEMockCertificateOrderDeliveryAssignmentHandler extends XSMockPKResourceHandler<LCECertificateOrderDeliveryAssignment, LCECertificateOrderDeliveryAssignmentPartial, LCECertificateOrderDeliveryAssignmentSearch> {

    public static STORAGE: Map<string, LCECertificateOrderDeliveryAssignment> = new Map<string, LCECertificateOrderDeliveryAssignment>();

    private queryPredicates: XSMockSearchQueryPredicate<LCECertificateOrderDeliveryAssignment>[] = [
        (assignment, query) => assignment.certificateOrder.orderNumber.toLowerCase().includes(query),
        (assignment, query) => assignment.certificateOrder.status.toLowerCase().includes(query),
        (assignment, query) => assignment.assignedTo.code.toLowerCase().includes(query)
    ];
    private searchPredicates: XSMockSearchPredicate<LCECertificateOrderDeliveryAssignment>[] = [
        (assignment, params) => this.httpParamArrayIncludes(params, 'types', assignment.type),
        (assignment, params) => this.httpParamArrayIncludes(params, 'deliveryManIDs', assignment.assignedTo.id),
        (assignment, params) => this.httpParamArrayIncludes(params, 'deliveryManCodes', assignment.assignedTo.code),
        (assignment, params) => this.httpParamArrayIncludes(params, 'deliveryManIDs', assignment.assignedTo.id),
        (assignment, params) => this.httpParamArrayIncludes(params, 'certificateOrderNumbers', assignment.certificateOrder.orderNumber),
        (assignment, params) => this.httpParamArrayIncludes(params, 'statuses', assignment.status)

    ];

    constructor(
        private contextService: LCECoreContextService,
        private mockCertificateOrderHandler: LCEMockCertificateOrderHandler,
        private mockUserDeliveryManHandler: LCEMockUserDeliveryManHandler,
        private mockCommonHandler: LCEMockCommonHandler
    ) {

        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.assignments.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCECertificateOrderDeliveryAssignment> {
        return LCEMockCertificateOrderDeliveryAssignmentHandler.STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(assignment: LCECertificateOrderDeliveryAssignment): LCECertificateOrderDeliveryAssignmentPartial {
        return {
            id: assignment.id,
            createdOn: assignment.createdOn,
            updatedOn: assignment.updatedOn,
            type: assignment.type,
            assignedOn: assignment.assignedOn,
            assignedBy: assignment.assignedBy,
            assignedTo: assignment.assignedTo,
            certificateOrder: assignment.certificateOrder,
            status: assignment.status
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Assign
            {
                id: DATASET_BASE_ID + '.assign',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.assignments.assign,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 5000,
                getResponseData: rArg => this.assignResponseData(rArg.body)
            },
            //AssignOne
            {
                id: DATASET_BASE_ID + '.assignOne',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.assignments.assignOne,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 5000,
                getResponseData: rArg => this.assignOneResponseData(rArg.body)
            },
            //unassign
            {
                id: DATASET_BASE_ID + '.unassign',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.assignments.unassign,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 5000,
                getResponseData: rArg => {
                    const requests: LCECertificateOrderDeliveryUnassignmentRequest[] = rArg.body;
                    const certificateOrderIDs = requests.map((request) => request.certificateOrderID);
                    return this.unAssignResponseData(certificateOrderIDs, LCE_CORE_ENDPOINTS.assignments.unassign);
                }
            },
            //UnassignOne
            {
                id: DATASET_BASE_ID + '.unassignOne',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.assignments.unassignOne,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 5000,
                getResponseData: rArg => {
                    const request: LCECertificateOrderDeliveryUnassignmentRequest = rArg.body;
                    return this.unAssignOneResponseData(request.certificateOrderID, LCE_CORE_ENDPOINTS.assignments.unassign);
                }
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates})
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCECertificateOrderDeliveryAssignmentPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private unAssignResponseData(assignmentIDs: string[], url: string): LCECertificateOrderDeliveryAssignmentResponse[] {
        let assignments: LCECertificateOrderDeliveryAssignmentResponse[] = [];
        assignmentIDs.forEach(assignmentID => {
            assignments.push(this.unAssignOneResponseData(assignmentID, url));
        });

        return assignments;
    }

    private unAssignOneResponseData(orderID: string, url: string): LCECertificateOrderDeliveryAssignmentResponse {

        const certificateOrder = this.mockCertificateOrderHandler.findOneByID(orderID);
        if (XSUtils.isEmpty(certificateOrder)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get order by given orderID : [${orderID}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: url
            });
        }

        const orderAssignment = this.findOneByID(certificateOrder.delivery!.assignment!.id);

        const assignment = {
            ...this.buildAssigment({
                type: orderAssignment.type,
                certificateOrderID: orderAssignment.certificateOrder.id,
                deliveryManID: orderAssignment.assignedTo.id,
                status: LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED
            }),
            id: orderAssignment.id,
            unassignedBy: LCE_MBO_MOCK_USER_ELON_MUSK,
            unassignedOn: new Date().toISOString()
        };

        const unAssignmentResponse: LCECertificateOrderDeliveryAssignmentResponse = {
            id: assignment.id,
            assignedTo: assignment.assignedTo!,
            assignedBy: LCE_MBO_MOCK_USER_ELON_MUSK,
            unassignedBy: LCE_MBO_MOCK_USER_ELON_MUSK,
            unassignedOn: new Date().toISOString()
        };

        this.mockCommonHandler.emitUnassignment(assignment);

        LCEMockCertificateOrderDeliveryAssignmentHandler.STORAGE.set(assignment.id, assignment);
        this.addResourceBaseMockData(assignment.id);

        return unAssignmentResponse;
    }

    private assignResponseData(body: any): LCECertificateOrderDeliveryAssignmentResponse[] {
        const requests: LCECertificateOrderDeliveryAssignmentRequest[] = body as LCECertificateOrderDeliveryAssignmentRequest[];
        let assignments: LCECertificateOrderDeliveryAssignmentResponse[] = [];

        requests.forEach(request => {
            assignments.push(this.assignOneResponseData(request));
        });

        return assignments;
    }

    private assignOneResponseData(body: any): LCECertificateOrderDeliveryAssignmentResponse {
        const request: LCECertificateOrderDeliveryAssignmentRequest = body as LCECertificateOrderDeliveryAssignmentRequest;
        const assignTo = this.mockUserDeliveryManHandler.findOneByID(request.deliveryManID);
        const assignment = this.buildAssigment({
            type: request.assignmentType,
            certificateOrderID: request.certificateOrderID,
            deliveryManID: request.deliveryManID,
            status: LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED
        });

        const assignmentResponse: LCECertificateOrderDeliveryAssignmentResponse = {
            id: assignment.id,
            assignedTo: assignTo,
            assignedOn: new Date().toISOString(),
            assignedBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            unassignedBy: undefined,
            unassignedOn: undefined
        };

        this.mockCommonHandler.emitAssignment(assignment);
        LCEMockCertificateOrderDeliveryAssignmentHandler.STORAGE.set(assignment.id, assignment);
        this.addResourceBaseMockData(assignment.id);

        return assignmentResponse;
    }

    private buildStorage(n: number = 10): void {
        for (let $i = 0; $i < n; $i++) {
            const assignment = this.buildAssigment({status: LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED});
            this.mockCommonHandler.emitAssignment(assignment);
            LCEMockCertificateOrderDeliveryAssignmentHandler.STORAGE.set(assignment.id, assignment);
            this.addResourceBaseMockData(assignment.id);
        }
    }

    private buildAssigment(args: {
        type?: LCECertificateOrderDeliveryAssignmentType,
        deliveryManID?: string,
        certificateOrderID?: string,
        status: LCECertificateOrderDeliveryAssignmentStatus;
    }): LCECertificateOrderDeliveryAssignment {

        const type = !XSUtils.isEmpty(args?.type) ? args!.type! : XSUtils.randomElement([LCECertificateOrderDeliveryAssignmentType.AUTOMATIC, LCECertificateOrderDeliveryAssignmentType.HYBRID, LCECertificateOrderDeliveryAssignmentType.MANUAL]);
        const assignedTo = !XSUtils.isEmpty(args?.deliveryManID) ? this.mockUserDeliveryManHandler.findOneByID(args!.deliveryManID!) : this.mockUserDeliveryManHandler.findOneRandomly();
        const certificateOrder = !XSUtils.isEmpty(args?.certificateOrderID) ? this.mockCertificateOrderHandler.findOneByID(args!.certificateOrderID!) : this.mockCertificateOrderHandler.findOneRandomly();

        return {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            type: type,
            assignedOn: new Date().toISOString(),
            assignedBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            unassignedOn: undefined,
            unassignedBy: undefined,
            assignedTo: assignedTo,
            certificateOrder: certificateOrder,
            status: args!.status,
            statusAudit: {assigned: {on: new Date().toISOString(), by: LCE_MBO_MOCK_USER_STEVE_JOBS}}
        };
    }
}
