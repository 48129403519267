import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCECertificateOrderDeliveryAssignmentRequest, LCECertificateOrderDeliveryAssignmentService, LCECertificateOrderDeliveryAssignmentType, LCECertificateOrderPartial, LCEUserDeliveryManPartial} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEDeliveryMenWidgetComponent} from '../../user/delivery-man/widget/lce-delivery-men-widget.component';
import {LCECertificateOrderWidgetComponent} from '../widget/lce-certificate-order-widget.component';
import {LCEDeliveryManOrderWidgetComponent} from '../widget/man/lce-delivery-man-order-widget.component';
import {LCECertificateOrdersManualFeatureService} from './lce-certificate-order-manual-dispatcher-feature.service';
import {XSConfirmation} from '@xs/core';

@Component({selector: 'lce-certificate-order-manual-dispatcher', templateUrl: './lce-certificate-order-manual-dispatcher.component.html'})
export class LCECertificateOrderManualDispatcher implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;
    readonly ASSIGN_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;

    @Input() notifyDeliveryMan?: boolean;

    @ViewChild('deliveryMenWidget') deliveryMenWidget: LCEDeliveryMenWidgetComponent;
    @ViewChild('certificateOrderWidget') certificateOrderWidget: LCECertificateOrderWidgetComponent;
    @ViewChild('deliveryManOrderWidget') deliveryManOrderWidget: LCEDeliveryManOrderWidgetComponent;

    deliveryMan?: LCEUserDeliveryManPartial;
    errorAssign: any;

    assignmentConfirmation: XSConfirmation;

    private subscription: Subscription = new Subscription();
    private ordersToAssign: Partial<LCECertificateOrderPartial>[] = [];

    public constructor(
        private loaderService: XSLoaderService,
        private certificateOrderDeliveryAssignmentService: LCECertificateOrderDeliveryAssignmentService,
        private featureService: LCECertificateOrdersManualFeatureService) {
    }

    public get ordersToAssignNumber(): string {
        return this.ordersToAssign.length == 0 ? '' : `(${this.ordersToAssign.length.toString()})`;
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.notifyDeliveryMan)) this.notifyDeliveryMan = true;
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onDeliveryManUnassignOrder(): void {
        this.refresh();
    }

    public onAssignOrders(): void {
        this.errorAssign = undefined;
        this.loaderService.startLoader(this.ASSIGN_LOADER_ID);
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService
                .assign(this.buildAssignmentRequests())
                .pipe(finalize(() => this.loaderService.stopLoader(this.ASSIGN_LOADER_ID)))
                .subscribe({
                    next: () => {
                        this.refresh();
                        this.ordersToAssign = [];
                    },
                    error: (error: any) => (this.errorAssign = error)
                })
        );
    }

    public onDeliveryManSelected(deliveryManSelected: any): void {
        this.deliveryMan = deliveryManSelected;
    }

    public handleOrderSelected(ordersSelected: any): void {
        this.ordersToAssign = ordersSelected;
        this.assignmentConfirmation = {
            key: 'assignmentConfirmationKey',

            trMessage: this.ordersToAssign!.length > 1 ? 'lce.shared.delivery.request.confirmation.assignments' : 'lce.shared.delivery.request.confirmation.assignment',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.onAssignOrders();
            },
            reject: () => {
            }
        };
    }

    public isAssignLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.ASSIGN_LOADER_ID);
    }

    public hasAssignError(): boolean {
        return !XSUtils.isNull(this.errorAssign);
    }

    public isAssignButtonDisabled(): boolean {
        return XSUtils.isEmpty(this.deliveryMan) || XSUtils.isEmpty(this.ordersToAssign);
    }

    private refresh(): void {
        this.deliveryManOrderWidget.update(true);
        this.certificateOrderWidget.update(true);
        this.deliveryMenWidget.update();
    }

    private buildAssignmentRequests(): LCECertificateOrderDeliveryAssignmentRequest[] {

        let assignmentRequests: LCECertificateOrderDeliveryAssignmentRequest[] = [];
        this.ordersToAssign.forEach(order => {
            assignmentRequests.push({
                deliveryManID: this.deliveryMan!.id,
                certificateOrderID: order.id!,
                notifyDeliveryMan: this.notifyDeliveryMan,
                assignmentType: LCECertificateOrderDeliveryAssignmentType.MANUAL
            });
        });

        return assignmentRequests;
    }
}
