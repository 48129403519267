import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEBirthDeclaration, LCEBirthDeclarationDelivery, LCEBirthDeclarationService, LCEFacilityWorkerPartial} from '@lce/core';
import {XS_STR_SPACE, XSGender, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSDialogable, XSStyleHelper} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEBirthDeclarationDeliveryDialogService} from '../delivery/lce-birth-declaration-delivery-dialog.service';
import {LCEBirthDeclarationParentDialogService} from '../parent/lce-birth-declaration-parent-dialog.service';

@Component({
    selector: 'lce-birth-declaration-record',
    templateUrl: './lce-birth-declaration-record.component.html',
    host: {class: 'xs-width-full'},
    providers: [LCEBirthDeclarationDeliveryDialogService, LCEBirthDeclarationParentDialogService]
})
export class LCEBirthDeclarationRecordComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.label.';

    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() readonly?: boolean;

    @Input() birthDeclaration?: LCEBirthDeclaration;
    @Input() birthDeclarationID?: string;

    @Output() editEvent = new EventEmitter<LCEBirthDeclaration>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclaration>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveBirthDeclaration()
    };

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private birthDeclarationService: LCEBirthDeclarationService,
        private deliveryDialogService: LCEBirthDeclarationDeliveryDialogService,
        private parentDialogService: LCEBirthDeclarationParentDialogService) {
        super();
    }

    get headerTitle(): string {
        let title = this.translationService.translateKey(this.TR_BASE + 'birth')!;
        if (!XSUtils.isEmpty(this.birthDeclaration)) {
            title += XS_STR_SPACE + '-' + XS_STR_SPACE + this.birthDeclaration!.delivery.birthNumber;
        }
        return title;
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.birthDeclaration) ? 'LCE-...' : this.birthDeclaration!.code;
        return subTitle!;
    }

    get birthIcon(): string {
        let colorStyleClass;
        if (XSUtils.isNull(this.birthDeclaration?.delivery)) colorStyleClass = XSStyleHelper.CLASS.color.secondary;
        else {
            colorStyleClass = this.birthDeclaration!.delivery.newBornGender === XSGender.FEMALE ? XSStyleHelper.CLASS.color.pinkImp : XSStyleHelper.CLASS.color.blueLightImp;
        }
        return this.ICON.birthDeclaration + XS_STR_SPACE + colorStyleClass;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.birthDeclaration = this.dialogConfig.data.birthDeclaration;
            this.readonly = this.dialogConfig.data.readonly;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;

            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.birthDeclaration));
        } else this.loadingStyleClass = 'xs-min-height-600 xs-min-width-500';

        if (XSUtils.isEmpty(this.birthDeclarationID) && XSUtils.isEmpty(this.birthDeclaration)) {
            throw new Error('birthDeclarationID and birthDeclaration cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.birthDeclarationID) && !XSUtils.isEmpty(this.birthDeclaration)) {
            throw new Error('birthDeclarationID and birthDeclaration cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.birthDeclarationID)) this.retrieveBirthDeclaration();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public onEdit(): void {
        this.editEvent.emit(this.birthDeclaration);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.birthDeclaration);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public handleDeliveryValueChange(delivery: any): void {
        this.birthDeclaration!.delivery = delivery;
        this.editEvent.emit();
    }

    public handleParentValueChange(parent: any, parentKey: 'mother' | 'father'): void {
        parentKey == 'mother' ? (this.birthDeclaration!.mother = parent) : (this.birthDeclaration!.father = parent);
    }

    public onEditDelivery(delivery: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>): void {
        this.deliveryDialogService.openCreateUpdateDialog({
            delivery: delivery,
            birthDeclarationID: this.birthDeclaration!.id,
            showBorder: false,
            showRecordAfterSave: false,
            birthDeclarationCode: this.birthDeclaration?.code,
            onSave: (deliveryRecord) => {
                this.birthDeclaration!.delivery = deliveryRecord!;
            }
        });
    }

    public onEditParent(parentKey: 'mother' | 'father'): void {
        this.parentDialogService.openCreateUpdateDialog({
            parent: this.birthDeclaration![parentKey],
            parentKey: parentKey,
            birthDeclarationNumber: this.birthDeclaration!.delivery.birthNumber,
            birthDeclarationID: this.birthDeclaration!.id,
            showRecordAfterSave: false,
            onSave: parent => {
                this.birthDeclaration![parentKey] = parent!;
            }
        });
    }

    private retrieveBirthDeclaration(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.error = undefined;
        this.subscription.add(
            this.birthDeclarationService
                .retrieve(this.birthDeclarationID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: (birthDeclaration: LCEBirthDeclaration) => {
                        this.birthDeclaration = birthDeclaration;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }
}
