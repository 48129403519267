import {DatePipe} from '@angular/common';
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCECoreContextService, LCEUserAccountService, LCEUserMunicipalEmployee, LCEUserMunicipalEmployeeService} from '@lce/core';
import {LOG, XS_STR_EMPTY, XS_STR_SPACE, XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR, XSTranslationService} from '@xs/common';
import {XSAuthenticationUpdatePassword, XSMenuItem} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-municipal-employee-record-authentication',
    templateUrl: './lce-user-municipal-employee-record-authentication.component.html'
})
export class LCEUserMunicipalEmployeeRecordAuthenticationComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.user.label.';
    readonly TR_BASE_COMMON: string = 'xs.common.label.';
    readonly TR_BASE_CORE: string = 'xs.core.label.';

    readonly DATE_FORMAT_EN = XS_DATE_FORMAT_MEDIUM_DATE_TIME_EN;
    readonly DATE_FORMAT_FR = XS_DATE_FORMAT_MEDIUM_DATE_TIME_FR;

    readonly PIN_CODE_HIDDEN_VALUE = '****';

    @Input() styleClass?: string;

    @Input() readonly: boolean;

    @Input() data: LCEUserMunicipalEmployee;

    pinCodeValue: string = this.PIN_CODE_HIDDEN_VALUE;

    @ViewChild('actionMenu') actionMenu: TieredMenu;
    actionMenuItems: XSMenuItem[];

    loading = {
        generatePINCode: false,
        retrievePINCode: false,
        updatePassword: false,
        resetPassword: false
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private contextService: LCECoreContextService,
        private municipalEmployeeService: LCEUserMunicipalEmployeeService,
        private userAccountService: LCEUserAccountService,
        private datePipe: DatePipe) {
    }

    get dateFormat(): string {
        const currentLanguage = this.translationService.getCurrentLanguage();
        return currentLanguage === XSLanguage.FRENCH ? this.DATE_FORMAT_FR : this.DATE_FORMAT_EN;
    }

    get lastPINCodeUpdate(): string {
        if (XSUtils.isEmpty(this.data.pinCodeUpdatedOn)) return XS_STR_EMPTY;
        const dateStr = this.datePipe.transform(this.data.pinCodeUpdatedOn!, this.dateFormat);
        return this.translationService.translateKey(this.TR_BASE_COMMON + 'updatedOn') + XS_STR_SPACE + dateStr;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'userRecord');
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public canResetPassword(): boolean {
        return this.data.id !== this.contextService.getUser().id;
    }

    public resetPassword(): void {
        this.loading.resetPassword = true;
        this.subscription.add(
            this.userAccountService
                .resetPassword(this.data.id)
                .pipe(finalize(() => this.loading.resetPassword = false))
                .subscribe(response => {
                    this.data.passwordResetOn = response.passwordResetOn;
                    this.data.passwordResetBy = response.passwordResetBy;
                    LOG().debug('User (' + response.code + ') Password Reset On ' + response.passwordResetOn + '].');
                })
        );
    }

    public onUpdatePassword(updatePassword: XSAuthenticationUpdatePassword): void {
        this.loading.updatePassword = true;
        this.subscription.add(
            this.userAccountService
                .updatePassword(this.data.id, updatePassword.oldPassword, updatePassword.newPassword)
                .pipe(finalize(() => this.loading.updatePassword = false))
                .subscribe(response => {
                    this.data.passwordUpdatedOn = response.value;
                    LOG().debug('User (' + this.data.code + ') Password Updated On ' + response.value + '].');
                })
        );
    }

    public onPINCodeShow(): void {
        this.loading.retrievePINCode = true;
        this.subscription.add(
            this.municipalEmployeeService
                .retrievePINCodePlainText(this.data.id)
                .pipe(finalize(() => this.loading.retrievePINCode = false))
                .subscribe(response => {
                    this.pinCodeValue = response.value;
                    LOG().debug('Showing User (' + this.data.code + ') PIN Code (during 5 seconds) ...');
                    setTimeout(() => (this.pinCodeValue = this.PIN_CODE_HIDDEN_VALUE), 5000);
                })
        );
    }

    public generateNewPINCode(): void {
        this.loading.generatePINCode = true;
        this.pinCodeValue = this.PIN_CODE_HIDDEN_VALUE;
        this.subscription.add(
            this.municipalEmployeeService
                .generatePINCode(this.data.id)
                .pipe(finalize(() => this.loading.generatePINCode = false))
                .subscribe(response => {
                    this.data.pinCodeUpdatedOn = response.pinCodeUpdatedOn;
                    this.data.pinCodeUpdatedBy = response.pinCodeUpdatedBy;
                    LOG().debug('User (' + this.data.id + ') PIN Code generated On ' + response.pinCodeUpdatedOn + '].');
                })
        );
    }
}
