import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_USER_ACCOUNT_STATUS, LCEUserMunicipalEmployee, LCEUserMunicipalEmployeeService} from '@lce/core';
import {LOG, XSAddress, XSAddressType, XSAssert, XSContactPerson} from '@xs/base';
import {XS_TR_BASE_GENDER, XS_TR_BASE_LANGUAGE, XSIcon} from '@xs/common';
import {XSAddressFieldOptions, XSClipboardService, XSContactPersonFieldOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';
import {LCESharedContextService} from '../../../../api/services/lce-shared-context.service';

@Component({
    selector: 'lce-user-municipal-employee-record-general',
    templateUrl: './lce-user-municipal-employee-record-general.component.html'
})
export class LCEUserMunicipalEmployeeRecordGeneralComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_GENDER = XS_TR_BASE_GENDER;
    readonly TR_BASE_LANGUAGE = XS_TR_BASE_LANGUAGE;
    readonly TR_BASE_ACCOUNT_STATUS = LCE_TR_BASE_USER_ACCOUNT_STATUS;

    readonly TR_BASE: string = 'lce.shared.user.label.';

    @Input() styleClass?: string;
    @Input() readonly: boolean;

    @Input() data: LCEUserMunicipalEmployee;
    @Output() dataChange = new EventEmitter<LCEUserMunicipalEmployee>();

    @Output() editEvent = new EventEmitter<LCEUserMunicipalEmployee>();
    @Output() purgeEvent = new EventEmitter<string>();

    readonly userIcon = {value: LCE_SHARED_ICON.user, styleClass: 'xs-color-secondary'};
    readonly usedIDCopyIcon: XSIcon = {
        value: LCE_SHARED_ICON.copy,
        styleClass: 'xs-font-size-12-imp',
        clickable: true,
        onClick: () => this.copyUserID()
    };

    addressFieldOptions: XSAddressFieldOptions;

    emergencyContactPersonFieldOptions: XSContactPersonFieldOptions;

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCESharedContextService,
        private userService: LCEUserMunicipalEmployeeService,
        private clipboardService: XSClipboardService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        XSAssert.notNull(this.readonly, 'readonly');
        this.buildAddressFieldOptions();
        this.buildContactPersonFieldOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // -----------------------------------------------------------------------------------------------------------------------------------------------

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            dataOptions: {
                coordinateClickable: true
            },
            formOptions: {
                type: this.data?.address?.type ? this.data.address.type : undefined!,
                typeSelection: true,
                allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED],
                showResetButton: false,
                title: false,
                onSave: (address: XSAddress) => {
                    LOG().debug('Saving User Address (' + this.data!.id + ') ...', address);
                    return this.userService.updateAddress(this.data!.id, address)
                        .pipe(tap(response => {
                            this.data.address = response.address;
                            this.data.updatedOn = response.updatedOn;
                            this.data.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
    }

    private buildContactPersonFieldOptions(): void {
        this.emergencyContactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: true,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.data!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving User Emergency Contact Person (' + this.data!.id + ') ...', contactPerson);
                    return this.userService.updateEmergencyContactPerson(this.data!.id, contactPerson)
                        .pipe(tap(response => {
                            this.data.emergencyContactPerson = response.emergencyContactPerson;
                            this.data.updatedOn = response.updatedOn;
                            this.data.updatedBy = this.contextService.getUser();
                        }));
                }
            },
            onDelete: (contactPerson: XSContactPerson) => {
                LOG().debug('Removing User Emergency Contact Person (' + this.data!.id + ') ...', contactPerson);
                return this.userService.updateEmergencyContactPerson(this.data.id, undefined)
                    .pipe(tap(response => {
                        this.data.emergencyContactPerson = response.emergencyContactPerson;
                        this.data.updatedOn = response.updatedOn;
                        this.data.updatedBy = this.contextService.getUser();
                    }));
            }
        };
    }

    private copyUserID(): void {
        this.clipboardService.copy(this.data.id);
    }
}
