import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECoreContextService,
    LCETerminal,
    LCETerminalCreate,
    LCETerminalGenerateSecurityCodeResponse,
    LCETerminalPartial,
    LCETerminalRegistrationRequest,
    LCETerminalRegistrationResponse,
    LCETerminalSearch,
    LCEUserPartial
} from '@lce/core';
import {XS_LOREM_IPSUM, XSDeviceIDType, XSDeviceOS, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSTemporalUtils, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.terminals';

@Injectable()
export class LCEMockTerminalHandler extends XSMockPKResourceAuditFullHandler<LCETerminal, LCETerminalPartial, LCETerminalSearch>
    implements XSMockPKSearchable<LCETerminalPartial> {

    public static TERMINAL_STORAGE: Map<string, LCETerminal> = new Map<string, LCETerminal>();

    private queryPredicates: XSMockSearchQueryPredicate<LCETerminal>[] = [
        (terminal, query) => terminal.code.toLowerCase().includes(query),
        (terminal, query) => terminal.facility.name.toLowerCase().includes(query),
        (terminal, query) => terminal.facility.code.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCETerminal>[] = [
        (terminal, params) => this.httpParamArrayIncludes(params, 'codes', terminal.code),
        (terminal, params) => this.httpParamArrayIncludes(params, 'facilityCodes', terminal.facility.code),
        (terminal, params) => this.httpParamArrayIncludes(params, 'deviceIDTypes', terminal.device.idType),
        (terminal, params) => this.httpParamArrayIncludes(params, 'deviceOS', terminal.device.os)
    ];

    constructor(private contextService: LCECoreContextService, private mockFacilityHandler: LCEMockFacilityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.terminals.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCETerminal> {
        return LCEMockTerminalHandler.TERMINAL_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(terminal: LCETerminal): LCETerminalPartial {
        return {
            id: terminal.id,
            code: terminal.code,
            createdOn: terminal.createdOn,
            facility: terminal.facility,
            device: terminal.device
        };
    }

    buildMockDataArray(): void {
        const registerRequest = LCE_CORE_ENDPOINTS.terminals.register;

        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //register
            {
                id: DATASET_BASE_ID + '.register',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: registerRequest,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.registerResponseData(rArg.body, registerRequest)

            },
            //create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.terminals.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 2,
                    active: 2,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCETerminalPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private buildGenerateSecurityCodeMockData(terminalID: string): XSMockData<LCETerminalGenerateSecurityCodeResponse> {
        let requestURL = LCE_CORE_ENDPOINTS.terminals.generateSecurityCode.replace('${terminalID}', terminalID);
        return {
            id: DATASET_BASE_ID + `.${terminalID}.generateSecurityCode`,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: () => {
                const terminal = this.findOneByID(terminalID);
                const on = new Date().toISOString();
                const by = this.getAuthenticatedUser();
                return {
                    id: terminal.id,
                    code: terminal.code,
                    securityCode: XSUtils.randomDigits(4),
                    securityCodeGeneratedOn: on,
                    securityCodeGeneratedBy: by,
                    updatedOn: on,
                    updatedBy: by
                } as LCETerminalGenerateSecurityCodeResponse;
            }
        };
    }

    private registerResponseData(registrationRequest: LCETerminalRegistrationRequest, requestURL: string): LCETerminalRegistrationResponse {
        const terminal = this.findOneByField('securityCode', registrationRequest.securityCode);
        if (XSUtils.isEmpty(terminal)) {
            throw new HttpErrorResponse({
                error: {data: `failed to get terminal by security code [code: ${registrationRequest.securityCode}].`},
                status: HttpStatusCode.InternalServerError,
                statusText: 'Internal Server Error',
                url: requestURL
            });
        }
        return {
            token: XSUtils.uuid(),
            tokenExpirationDate: XSTemporalUtils.dateAdd(new Date(), {years: 5}).toISOString(),
            terminal: this.toPartial(terminal)
        };
    }

    private createResponseData(body: any): LCETerminal {
        const terminalCreate: LCETerminalCreate = body as LCETerminalCreate;
        const terminal: LCETerminal = {
            ...this.buildRandomTerminal(),
            device: {
                id: XSUtils.uuid(),
                idType: terminalCreate.deviceIDType,
                os: terminalCreate.deviceOS,
                name: terminalCreate.name,
                mark: terminalCreate.mark,
                year: terminalCreate.year
            },
            description: terminalCreate.description,
            facility: this.mockFacilityHandler.findOneByCode(terminalCreate.facilityCode),
            securityCodeGeneratedOn: new Date().toISOString(),
            securityCodeGeneratedBy: LCEMockUtils.randomUserMunicipalEmployee(),
            note: terminalCreate.note
        };

        LCEMockTerminalHandler.TERMINAL_STORAGE.set(terminal.id, terminal);
        this.addResourceBaseMockData(terminal.id);
        this.mockDataArray.push(this.buildFindByCodePartialMockData(terminal.code));
        this.mockDataArray.push(this.buildGenerateSecurityCodeMockData(terminal.id));

        return terminal;
    }

    private buildRandomTerminal(): LCETerminal {
        const by = LCEMockUtils.randomUserMunicipalEmployee();

        return {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: by,
            securityCodeGeneratedOn: new Date().toISOString(),
            securityCodeGeneratedBy: by,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-TEM-' + XSUtils.randomDigits(6),
            device: {
                name: XSUtils.randomElement(['Canopus', 'Sun', 'Deneb', 'Alnilam', 'Alphard', 'Vega', 'Mirach', 'Hamal', 'Capella', 'Alnitak']),
                id: XSUtils.uuid(),
                idType: XSUtils.randomElement([XSDeviceIDType.IMEI, XSDeviceIDType.MAC_ADDRESS]),
                os: XSUtils.randomElement([XSDeviceOS.IOS, XSDeviceOS.ANDROID, XSDeviceOS.LINUX, XSDeviceOS.WINDOWS])
            },
            description: XSUtils.randomElement([undefined, XS_LOREM_IPSUM.short, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long]),
            facility: this.mockFacilityHandler.getYopougonTownHall(),
            securityCode: XSUtils.randomDigits(4),
            registeredBy: XSUtils.randomElement([undefined, by]),
            registeredOn: new Date().toISOString(),
            note: XSUtils.randomElement([undefined, XS_LOREM_IPSUM.short, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long])
        };
    }

    private buildStorage(n: number = 10): void {
        for (let $i = 0; $i < n; $i++) {
            const terminal = this.buildRandomTerminal();
            LCEMockTerminalHandler.TERMINAL_STORAGE.set(terminal.id, terminal);
            this.addResourceBaseMockData(terminal.id);
            this.mockDataArray.push(this.buildGenerateSecurityCodeMockData(terminal.id));
        }
    }
}
