<div
        class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error [actionButton]="retrieveErrorRetryButton" [error]="retrieveError" [showActionButton]="true"
                  [showErrorDetailButton]="true" message="{{ TR_BASE }}errorMessageRetrieve" mode="block"
                  styleClass="xs-max-width-500" subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()" class="{{contentStyleClass}}">
        <div class="grid">
            <div class="col-12 lg:col-4">
                <div
                        class="xs-flex-column-center xs-with-full xs-pt-15 xs-plr-30 xs-pb-30 xs-mb-30 xs-lg-border-bottom-none-imp xs-md-border-bottom-discrete xs-sm-border-bottom-discrete">

                    <xs-image [src]="deliveryCompany?.logo" styleClass="xs-max-width-350"></xs-image>

                    <xs-button
                            [icon]="ICON.camera"
                            class="xs-mt-10"
                            label="xs.core.label.uploadImage"
                            size="intermediate"
                            type="text">
                    </xs-button>
                </div>
            </div>
            <div [ngClass]="{ 'xs-disabled': createUpdateLoading }" class="col-12 lg:col-8 xs-pl-10">

                <div class="grid xs-fields xs-fields-spacer-15">
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="nameField"></xs-input-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="fullNameField"></xs-input-field-text>
                    </div>


                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="ccField"></xs-input-field-text>
                    </div>

                    <div class="col-12 md:col-6">
                        <lce-input-field-municipality-autocomplete [options]="municipalityField" [showIcon]="true">
                        </lce-input-field-municipality-autocomplete>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-address [options]="addressField"></xs-input-field-address>
                    </div>

                    <div class="col-12 md:col-6">
                        <xs-input-field-contact-person [options]="contactPersonField">
                        </xs-input-field-contact-person>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-mtb-15">
            <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.deliveryCompany"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>

<ng-template #dFooter>
    <div [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }"
         class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-error *ngIf="hasCreateUpdateError()" [ellipsis]="true" [error]="createUpdateError"
                  [showErrorDetailButton]="true" errorDetailButtonClass="xs-mt-2-imp" message="{{ TR_BASE }} label.errorMessageCreateUpdate"
                  mode="inline">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button (clickEvent)="fillForm()" *ngIf="isCreateMode()" class="xs-sm-display-none xs-mr-40" label="Fill Form"
                       size="intermediate" type="text">
            </xs-button>
            <xs-button
                    (clickEvent)="reset()"
                    *ngIf="isCreateMode()"
                    [confirmation]="resetConfirmation"
                    [disabled]="createUpdateLoading"
                    [showConfirmation]="shouldShowResetConfirmation()"
                    confirmationPopupStyleClass="xs-font-size-10"
                    label="xs.core.label.reset"
                    size="intermediate" type="text">
            </xs-button>
            <xs-button (clickEvent)="closeDialog()" [confirmation]="closeConfirmation" [disabled]="createUpdateLoading || centralLoader.isRunning()" [outlined]="true"
                       [showConfirmation]="shouldShowCloseConfirmation()" confirmationPopupStyleClass="xs-font-size-10" label="xs.core.label.close"
                       severity="secondary" styleClass="xs-mlr-15-imp"
                       type="button">
            </xs-button>
            <xs-button (clickEvent)="createUpdate()" [disabled]="centralLoader.isRunning()" [loading]="createUpdateLoading"
                       label="xs.core.label.save" type="button">
            </xs-button>
        </div>
    </div>

</ng-template>