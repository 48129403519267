import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {LCECertificateOrderStatus} from '@lce/core';
import {LOG, XSAssert, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderType} from '@xs/common';
import {XSConfirmation, XSDialogable, XSGlobalToastKey, XSInputFieldRadiosOptions, XSToastService} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcessCreate} from '../../api/domain/lce-certificate-order-batch-process';
import {LCECertificateOrderBatchProcessService} from '../../api/services/lce-certificate-order-batch-process.service';
import {LCECertificateOrderSimpleStatsComponent} from '../stats/lce-certificate-order-simple-stats.component';

@Component({
    selector: 'lce-certificate-orders-pending-orders',
    templateUrl: './lce-certificate-orders-pending-orders.component.html'
})
export class LCECertificateOrdersPendingOrdersComponent extends XSDialogable implements OnInit {

    readonly TR_BASE = 'lce.shared.certificateOrders.pendingOrders.';

    readonly STATS_LOADER_TYPE = XSLoaderType.THREE_BOUNCE;

    readonly PENDING_STATUS = LCECertificateOrderStatus.PENDING;

    @Input() styleClass?: string;

    @Input() municipalityCode: string;

    @Output() newBatchProcessEvent = new EventEmitter<void>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    @ViewChild('simpleStats') simpleStats: LCECertificateOrderSimpleStatsComponent;

    nLastOrdersFieldOptions: XSInputFieldRadiosOptions;
    formGroup: FormGroup = new FormGroup({});
    processConfirmation: XSConfirmation;
    processBtnLoading: boolean = false;

    protected subscription: Subscription = new Subscription();

    constructor(private toastService: XSToastService, private batchProcessService: LCECertificateOrderBatchProcessService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.municipalityCode = this.dialogConfig.data.municipalityCode;
        }
        XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
        this.buildForm();
        this.buildProcessConfirmation();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public canProcess(): boolean {
        return !XSUtils.isNull(this.simpleStats?.stats?.total) && this.simpleStats!.stats!.total > 0;
    }

    public onClose(): void {
        if (!XSUtils.isNull(this.dialogRef)) this.dialogRef.close();
    }

    private process(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) {
            const formErrors = XSFormUtils.getFormErrors(this.formGroup);
            LOG().error('Invalid Form :-(', formErrors);
            return;
        }
        const formData = this.formGroup.getRawValue();

        const batchCreate: LCECertificateOrderBatchProcessCreate = {
            nLastOrders: formData.nLastOrdersField
        };
        this.subscription.add(
            this.batchProcessService.create(batchCreate).subscribe(() => {
                this.toastService.addToast(
                    {
                        key: XSGlobalToastKey.TOP_RIGHT.toString(),
                        severity: 'info',
                        summary: this.TR_BASE + 'toastSummary',
                        detail: this.TR_BASE + 'toastDetail'
                    },
                    true
                );
                this.newBatchProcessEvent.emit();
                this.processBtnLoading = false;
            })
        );
    }

    private buildProcessConfirmation(): void {
        this.processConfirmation = {
            key: 'processBtnConfirmationKey',
            trMessage: this.TR_BASE + 'confirmationMessage',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.processBtnLoading = true;
                this.process();
            },
            reject: () => {
            }
        };
    }

    private buildForm(): void {
        this.nLastOrdersFieldOptions = {
            fieldName: 'nLastOrdersField',
            label: this.TR_BASE + 'nLastOrdersLabel',
            labelContainerStyleClass: 'xs-mb-12-imp ',
            control: new FormControl(15),
            colStyleClass: 'sm:col-3',
            radios: [
                {label: '15', value: 15},
                {label: '25', value: 25},
                {label: '35', value: 35},
                {label: '50', value: 50}
            ]
        };
        this.formGroup.addControl(this.nLastOrdersFieldOptions.fieldName, this.nLastOrdersFieldOptions.control!);
    }
}
