<div class="xs-flex-row xs-align-items-center xs-justify-content-space-between {{ styleClass }}">
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.eye }} xs-display-block xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ formatValue(numberOfViews) }}</span>
    </span>
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.comment }} xs-display-block  xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ formatValue(numberOfComments) }}</span>
    </span>
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.share }} xs-display-block xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ formatValue(numberOfShares) }}</span>
    </span>
</div>
