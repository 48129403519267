import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LCECertificateOrderDeliveryAssignment} from '@lce/core';
import {XSMockHttpHandler} from '@xs/mock';

@Injectable()
export class LCEMockCommonHandler extends XSMockHttpHandler {

    private deliveryAssignSubject = new BehaviorSubject<{ assignmentResponse: LCECertificateOrderDeliveryAssignment }>(undefined!);
    onDeliveryAssignment = this.deliveryAssignSubject.asObservable();

    onDeliveryUnassignment = this.deliveryAssignSubject.asObservable();
    private deliveryUnassignSubject = new BehaviorSubject<{ unassignmentResponse: LCECertificateOrderDeliveryAssignment }>(undefined!);

    constructor() {
        super();
    }

    buildMockDataArray() {
    }

    public emitAssignment(assignmentResponse: LCECertificateOrderDeliveryAssignment): void {
        this.deliveryAssignSubject.next({assignmentResponse: assignmentResponse});
    }

    public emitUnassignment(unassignmentResponse: LCECertificateOrderDeliveryAssignment): void {
        this.deliveryUnassignSubject.next({unassignmentResponse: unassignmentResponse});
    }
}