import {LCEShippingInternationalProvider} from '@lce/core';

export interface LCECertificateOrderProcessOptions {
    forceShippingInternationalProvider?: LCEShippingInternationalProvider,
    parameters?: LCECertificateOrderCreateProcessParameters;
    useProcuration?: boolean;
    useDelivery?: boolean;
    facilityCode?: string;
    showProcurationFillForm?: boolean;
    showBackButton?: boolean;
    backButtonLabel?: string;
    showMakeNewPaymentButton?: boolean;
    showConfirmationTransactionID?: boolean;
    showFillFormButton?: boolean;
    countryISO: string;
    canUseDeliveryDate?: boolean;
    canUseDesiredDeliveryTimeRange?: boolean;
    canUseNote?: boolean;
}

export enum LCECertificateOrderProcessStep {
    CREATE = 'create',
    PAYMENT = 'payment',
    CONFIRMATION = 'confirmation',
}

export interface LCECertificateOrderCreateProcessParameters {
    styles?: LCECertificateOrderCreateProcessParameterStyles;
}

export interface LCECertificateOrderCreateProcessParameterStyles {
    titleColor?: string;
    primaryColor?: string;
    primaryContrastColor?: string;
    secondaryColor?: string;
    secondaryContrastColor?: string;
    cardBorder?: boolean;
}