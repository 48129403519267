import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {
    LCE_FACILITY_PARTIAL_YOPOUGON,
    LCE_MUNICIPALITY_YOPOUGON,
    LCE_SERVICE_POINT_YOPOUGON_COSMOS,
    LCECertificateCreate,
    LCECertificateOrder,
    LCECertificateOrderCreate,
    LCECertificateOrderProcurationCreate,
    LCECertificateOrderService,
    LCECertificateType,
    LCEDeliveryDestinationFacility,
    LCEDeliveryDestinationServicePoint,
    LCEDeliveryDestinationType,
    LCEFacilityPartial,
    LCEFacilityService,
    LCEIdentityDocumentType,
    LCEServicePointPartial,
    LCEServicePointService,
    LCEShippingInternationalProvider,
    LCEUserCustomerCreate
} from '@lce/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {forkJoin, Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {XSConfirmation, XSDialogable} from '@xs/core';
import {LOG, XS_LOREM_IPSUM, XSInstantRange, XSUtils} from '@xs/base';
import {finalize, tap} from 'rxjs/operators';
import {XS_TIME_FORMAT_HH_MM, XSFormUtils} from '@xs/common';
import {LCECertificateOrderCreateDeliveryComponent} from './delivery/lce-certificate-order-create-delivery.component';
import {LCECertificateOrderCreateProcurationComponent} from './procuration/lce-certificate-order-create-procuration.component';
import {LCECertificateOrderCreateCertificateComponent} from './certificate/lce-certificate-order-create-certificate.component';
import {LCECertificateOrderProcessService} from '../process/lce-certificate-order-process.service';
import {LCECertificateOrderUtils} from '../lce-certificate-order.utils';
import {DatePipe} from '@angular/common';

@Component({
    selector: ' lce-certificate-order-create',
    templateUrl: './lce-certificate-order-create.component.html',
    host: {class: 'xs-width-full'}
})
export class LCECertificateOrderCreateComponent extends XSDialogable implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE: string = 'lce.shared.certificateOrders.create.';
    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();
    readonly DEFAULT_NUMBER_OF_COPIES = 1;

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() facilityCode?: string;
    @Input() data?: LCECertificateOrderCreate;

    @Input() mode?: 'internal' | 'public';

    @Input() vertical?: boolean;
    @Input() showActionButtons?: boolean;
    @Input() useProcuration?: boolean;
    @Input() useDelivery?: boolean;
    @Input() showProcurationFillForm?: boolean;
    @Input() canUseDesiredDeliveryTimeRange?: boolean;

    @Input() forceShippingInternationalProvider?: LCEShippingInternationalProvider;
    @Input() loading?: boolean;
    @Input() showCardBorders?: boolean;

    @Input() numberOfCopiesColor?: string;

    @Output() saveEvent = new EventEmitter<LCECertificateOrder>();
    @Output() closeEvent = new EventEmitter<LCECertificateOrder>();

    @Output() procurationVisibilityEvent = new EventEmitter<boolean>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    @ViewChild('createCertificateForm') createCertificateForm: LCECertificateOrderCreateCertificateComponent;
    @ViewChild('createCustomerForm') createCustomerForm: LCECertificateOrderCreateDeliveryComponent;
    @ViewChild('createDeliveryForm') createDeliveryForm: LCECertificateOrderCreateDeliveryComponent;
    @ViewChild('createProcurationForm') createProcurationForm?: LCECertificateOrderCreateProcurationComponent;

    mainFormGroup: FormGroup = new FormGroup({});
    customerFields = new FormGroup({});
    certificateFields = new FormGroup({});
    deliveryFields = new FormGroup({});

    resetConfirmation: XSConfirmation = {
        key: 'resetConfirmationKey',
        trMessage: 'xs.core.label.confirmationResetForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => this.reset()
    };
    closeConfirmation: XSConfirmation = {
        key: 'closeConfirmationKey',
        trMessage: 'xs.core.label.confirmationLeaveForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => this.closeDialog()
    };

    createError: any;

    orderCreateCreatedOn = new Date().toDateString();
    internalData?: LCECertificateOrderCreate;
    numberOfCopies: number = this.DEFAULT_NUMBER_OF_COPIES;

    showProcurationForm: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private certificateOrderService: LCECertificateOrderService,
        private certificateOrderProcessService: LCECertificateOrderProcessService,
        private facilityService: LCEFacilityService,
        private servicePointService: LCEServicePointService,
        private datePipe: DatePipe
    ) {
        super();
    }

    get headerTitle(): string {
        return this.TR_BASE + 'main.title';
    }

    get headerSubTitle(): string {
        return this.TR_BASE + 'createSubTitle';
    }

    get isModePublic(): boolean {
        return this.mode === 'public';
    }

    get isModeInternal(): boolean {
        return this.mode === 'internal';
    }

    get canShowPendingOrder(): boolean {
        return !XSUtils.isEmpty(this.data) && this.certificateOrderProcessService.getShowPendingRequest();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
        }
        this.buildMainForm();

        if (XSUtils.isEmpty(this.mode)) this.mode = 'public';

        this.procurationVisibilityEvent.subscribe(state => this.handleProcurationVisibilityChange(state, false));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            if (!this.canShowPendingOrder) {
                this.update();
            }
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public continueWithPendingOrder(): void {
        this.update();
        this.certificateOrderProcessService.setShowPendingRequest(false);
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public hasCreateError(): boolean {
        return !XSUtils.isNull(this.createError);
    }

    public checkFormValidity(): void {
        XSFormUtils.validateFormGroup(this.mainFormGroup);
        if (this.isCreateProcurationForm()) {
            XSFormUtils.validateFormGroup(this.createProcurationForm!.formGroup);
            this.mainFormGroup.addControl('procuration', this.createProcurationForm!.formGroup);
        }
    }

    public isFormValid(): boolean {
        return this.mainFormGroup.valid;
    }

    public create(): void {
        this.checkFormValidity();

        if (!this.isFormValid()) return;

        this.loading = true;

        const formData = this.mainFormGroup.value;

        const certificateOrderCreate = this.buildCertificateOrderCreate(formData);
        this.subscription.add(
            this.certificateOrderService
                .createFromFrontOfficeUser(certificateOrderCreate)
                .pipe(finalize(() => (this.loading = false)))
                .subscribe({
                    next: (certificateOrder) => {
                        LOG().debug('Certificate Order successfully saved :-) [ id: ' + certificateOrder.id + ']');
                        this.saveEvent.emit(certificateOrder);
                    },
                    error: (error) => (this.createError = error)
                })
        );
    }

    public update(): void {
        this.loading = true;
        if (!XSUtils.isEmpty(this.internalData?.procurationCreate)) this.handleProcurationVisibilityChange(true, true);

        const servicePointCode = (this.data?.deliveryDestination as LCEDeliveryDestinationServicePoint).servicePoint as string;
        let servicePoint: LCEServicePointPartial = undefined!;

        const facilityCode = (this.data?.deliveryDestination as LCEDeliveryDestinationFacility).facility as string;
        let facility: LCEFacilityPartial = undefined!;

        forkJoin({
            facility: this.facilityService.findOneByCodeAsPartial(this.data?.facilityCode as string).pipe(tap(facilityRetrieved => facility = facilityRetrieved)),
            servicePointDestination: this.data?.deliveryDestination.type === LCEDeliveryDestinationType.SERVICE_POINT ? this.servicePointService.findOneByCodeAsPartial(servicePointCode).pipe(tap(servicePointRetrieved => servicePoint = servicePointRetrieved)) : [undefined!],
            facilityDestination: this.data?.deliveryDestination.type === LCEDeliveryDestinationType.FACILITY ? this.facilityService.findOneByCodeAsPartial(facilityCode).pipe(tap(servicePointRetrieved => servicePoint = servicePointRetrieved)) : [undefined!]
        }).pipe(finalize(() => this.loading = false))
            .subscribe({
                    next: value => {
                        console.log('Fork response', value);
                        this.numberOfCopies = this.data?.numberOfCopies!; // a supprimer
                        this.internalData = this.data;
                        if (!XSUtils.isEmpty(value.facility)) this.internalData!.facility = value.facility;
                        if (!XSUtils.isEmpty(value.servicePointDestination)) (this.internalData!.deliveryDestination as LCEDeliveryDestinationServicePoint).servicePoint = value.servicePointDestination;
                        if (!XSUtils.isEmpty(value.facilityDestination)) (this.internalData!.deliveryDestination as LCEDeliveryDestinationFacility).facility = value.facilityDestination;
                    }
                }
            );
    }

    public fillForm(): void {
        // --- Certificate ---
        this.certificateFields.get('facility')?.setValue(LCE_FACILITY_PARTIAL_YOPOUGON);
        this.certificateFields.get('referenceNumber')?.setValue(XSUtils.randomDigits(8));
        this.certificateFields.get('type')?.setValue(LCECertificateType.BIRTH);
        this.certificateFields.get('issueDate')?.setValue(new Date());
        // --- Customer ---
        this.customerFields.get('firstName')?.setValue('Harvey');
        this.customerFields.get('lastName')?.setValue('Spector');
        this.customerFields.get('email')?.setValue('harvey.spector@gmail.com');
        this.customerFields.get('primaryPhoneNumber')?.setValue('+225 0707070707');
        this.customerFields.get('municipalityOfResidence')?.setValue(LCE_MUNICIPALITY_YOPOUGON);
        // --- Delivery ---
        if (this.useDelivery && this.isCreateDeliveryForm()) {
            this.deliveryFields.get('desiredDeliveryDate')?.setValue(new Date());
            this.deliveryFields.get('desiredDeliveryTimeRange')?.setValue(new XSInstantRange('2022-03-25 10:00', '2022-03-25 14:00'));
            this.deliveryFields.get('deliveryNote')?.setValue(XS_LOREM_IPSUM.medium);
            this.deliveryFields.get('destinationType')?.setValue(LCEDeliveryDestinationType.SERVICE_POINT);
            this.createDeliveryForm.selectedDestinationType = LCEDeliveryDestinationType.SERVICE_POINT;
            this.createDeliveryForm.buildDeliveryDestinationField(LCEDeliveryDestinationType.SERVICE_POINT, {
                type: LCEDeliveryDestinationType.SERVICE_POINT,
                servicePoint: LCE_SERVICE_POINT_YOPOUGON_COSMOS
            } as LCEDeliveryDestinationServicePoint);
        }
        // --- Procuration ---
        if (this.useProcuration && this.isCreateProcurationForm()) {
            this.showProcurationForm = true;
            this.createProcurationForm!.formGroup.get('representativeFullName')?.setValue('Mike Ross');
            this.createProcurationForm!.formGroup.get('representativePhoneNumber')?.setValue('+225 0707070707');
            this.createProcurationForm!.formGroup.get('representativeEmail')?.setValue('mike.ross@avocat.com');

            if (!this.createProcurationForm!.hidedIdentityDocumentForm) {
                this.createProcurationForm!.formGroup.get('identityDocumentType')?.setValue(LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD);
                this.createProcurationForm!.formGroup.get('identityDocumentNumber')?.setValue('CNI48954495');
            }
        }
    }

    public getFormValue(): LCECertificateOrderCreate {
        let data: LCECertificateOrderCreate = this.buildCertificateOrderCreate(this.mainFormGroup.value);

        if (this.isCreateProcurationForm()) {
            data.procurationCreate = this.createProcurationForm!.formGroup.value;
        }

        console.log('certificate order create', data);

        return data;
    }

    public reset() {
        this.createCertificateForm.formGroup?.reset();
        this.createCustomerForm.formGroup?.reset();
        if (this.isCreateDeliveryForm()) this.createDeliveryForm!.formGroup?.reset();
        if (this.isCreateProcurationForm()) this.createProcurationForm!.formGroup?.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public buildCertificateOrderCreate(formData: any): LCECertificateOrderCreate {

        let certificateOrderCreate: LCECertificateOrderCreate = {
            facilityCode: formData.certificate.facility.code,
            certificateCreate: this.buildCertificateCreate(formData),
            customerCreate: this.buildCustomerCreate(formData),
            procurationCreate: !XSUtils.isEmpty(formData.procuration) ? this.buildProcurationCreate(formData) : undefined,
            numberOfCopies: this.numberOfCopies,
            deliveryDestination: undefined!

        };

        if (!XSUtils.isEmpty(formData.delivery)) {
            certificateOrderCreate.deliveryDestination = LCECertificateOrderUtils.buildDeliveryDestination(formData.delivery.destinationType, formData.delivery.deliveryDestination, formData.delivery.shippingInternationalProvider);
            certificateOrderCreate.deliveryNote = formData.delivery.deliveryNote;
            certificateOrderCreate.desiredDeliveryDate = this.transformDate(formData.delivery.desiredDeliveryDate);

            if (!XSUtils.isEmpty(formData.delivery.desiredDeliveryTimeRange)) {
                const timeRangeStart = this.transformDate(formData.delivery.desiredDeliveryTimeRange.start, XS_TIME_FORMAT_HH_MM);
                const timeRangeEnd = this.transformDate(formData.delivery.desiredDeliveryTimeRange.end, XS_TIME_FORMAT_HH_MM);
                certificateOrderCreate.desiredDeliveryTimeRange = {start: timeRangeStart, end: timeRangeEnd};
            }
        } else {
            certificateOrderCreate.deliveryDestination = {
                type: LCEDeliveryDestinationType.FACILITY,
                facility: this.facilityCode as string
            } as LCEDeliveryDestinationFacility;
        }

        XSUtils.removeNullAndUndefinedEntries(certificateOrderCreate);

        return certificateOrderCreate;
    }

    public isFormEmpty(): boolean {
        let createProcurationFormIsEmpty = this.isCreateProcurationForm() ? this.createProcurationForm!.isFormEmpty() : true;
        let createDeliveryFormIsEmpty = this.isCreateDeliveryForm() ? this.createDeliveryForm!.isFormEmpty() : true;

        return (this.numberOfCopies === 1 &&
            (!XSUtils.isEmpty(this.createCertificateForm) && this.createCertificateForm.isFormEmpty()) &&
            (!XSUtils.isEmpty(this.createCustomerForm) && this.createCustomerForm.isFormEmpty()) &&
            createDeliveryFormIsEmpty &&
            createProcurationFormIsEmpty);
    }

    public getCardColor(): string | undefined {
        return this.showCardBorders ? this.numberOfCopiesColor : '';
    }

    private buildCertificateCreate(formData: any): LCECertificateCreate {
        return {
            type: formData.certificate.type,
            referenceNumber: formData.certificate.referenceNumber,
            issueDate: this.transformDate(formData.certificate.issueDate)
        };
    }

    private buildCustomerCreate(formData: any): LCEUserCustomerCreate {
        return {
            email: formData.customer.email,
            primaryPhoneNumber: formData.customer.primaryPhoneNumber,
            municipalityOfResidence: formData.customer.municipalityOfResidence,
            name: {
                firstName: formData.customer.firstName.trim(),
                lastName: formData.customer.lastName.trim()
            }
        };
    }

    private buildProcurationCreate(formData: any): LCECertificateOrderProcurationCreate {
        return {
            representativeFullName: formData.procuration.representativeFullName,
            representativePhoneNumber: formData.procuration.representativePhoneNumber,
            representativeEmail: formData.procuration.representativeEmail,
            identityDocumentType: formData.procuration.identityDocumentType,
            identityDocumentNumber: formData.procuration.identityDocumentNumber
        };
    }

    private handleProcurationVisibilityChange(isProcurationFormVisible: boolean, firstInitialisation: boolean): void {
        this.showProcurationForm = isProcurationFormVisible;
        if (this.showProcurationForm) {
            if (!XSUtils.isEmpty(this.internalData?.procurationCreate) && !firstInitialisation) this.internalData!.procurationCreate = undefined;
        } else {
            this.mainFormGroup.removeControl('procuration');
        }
    }

    private buildMainForm(): void {
        // TODO: label must enWith Create
        this.mainFormGroup.addControl('certificate', this.certificateFields);
        this.mainFormGroup.addControl('customer', this.customerFields);
        this.mainFormGroup.addControl('delivery', this.deliveryFields);
    }

    private transformDate(data: string | Date, format: string = 'yyyy-MM-dd'): string {
        if (XSUtils.isEmpty(data)) new Error('data cannot be empty');
        let dateFormat = this.datePipe.transform(data, format);
        return dateFormat!;
    }

    private isCreateProcurationForm(): boolean {
        return !XSUtils.isEmpty(this.createProcurationForm);
    }

    private isCreateDeliveryForm(): boolean {
        return !XSUtils.isEmpty(this.createDeliveryForm);
    }
}
