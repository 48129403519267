import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LCEFacilityService, LCEHttpClientService, LCEWebSocketService} from '@lce/core';
import {LOG, XS_STORAGE_KEY_LANGUAGE, XS_STORAGE_KEY_TOKEN, XSLanguage, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSTranslationService} from '@xs/common';
import {XSImageService, XSLocalStorageService} from '@xs/core';
import {LCECMDContextService} from './lce-cmd-context.service';
import {LCECMDAuthenticationService} from './lce-cmd-authentication.service';
import {LCECMDAppInitialData} from '../domain/lce-cmd-app-initial-data';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class LCECMDInitialDataService {
    constructor(
        // ***
        private router: Router,
        private translationService: XSTranslationService,
        private localStorageService: XSLocalStorageService,
        private contextService: LCECMDContextService,
        private httpClientService: LCEHttpClientService,
        private webSocketService: LCEWebSocketService,
        private imageService: XSImageService,
        private authenticationService: LCECMDAuthenticationService,
        private facilityService: LCEFacilityService) {

        this.authenticationService.onGotIn.subscribe(loginResponse => this.initialize(loginResponse.initialData as LCECMDAppInitialData, true));
        this.authenticationService.onGotOut.subscribe(() => this.webSocketService.disconnect());
    }

    public logout(): void {
        this.disconnectWebSocket();
    }

    public async initialize(appInitialData: LCECMDAppInitialData, goToDashboard: boolean = false): Promise<void> {
        this.contextService.setIPGeolocation(appInitialData.ipGeolocation!);
        this.contextService.setUser(appInitialData.authenticatedUser!);
        await this.setDefaultFacility();

        const userLanguage: XSLanguage | undefined = appInitialData.authenticatedUser?.language;
        if (!XSUtils.isEmpty(userLanguage) && this.translationService.getCurrentLanguage() !== userLanguage) {
            await this.translationService.initialize(userLanguage!);
            this.httpClientService.setHeaderLanguage(userLanguage!);
            this.localStorageService.setItem(XS_STORAGE_KEY_LANGUAGE, userLanguage!);
        }

        if (!XSUtils.isEmpty(appInitialData.authenticatedUser!.profileImage)) {
            await this.imageService.cacheImage(appInitialData.authenticatedUser!.profileImage!).toPromise();
        }

        if (environment.mock !== true) {
            this.connectWebSocket();
        }

        const fullPersonName = XSCommonDomainUtils.getPersonFullName(appInitialData.authenticatedUser!.name!);
        const userEmail = appInitialData.authenticatedUser!.email;
        LOG().info('User [' + fullPersonName + '][' + userEmail + '] Successfully Authenticated.', appInitialData);
        this.contextService.setAsInitialized();
        LOG().debug('LCE Commander Successfully Initialized.');

        if (goToDashboard) {
            this.router.navigate(['/dashboard']).then();
        }
    }

    private async setDefaultFacility(): Promise<void> {
        const results = await this.facilityService.find({query: 'yopougon'}).toPromise();
        if (XSUtils.isEmpty(results)) {
            throw new Error('Default facility not found.');
        }
        this.contextService.setDefaultFacility(results[0]);
    }

    private disconnectWebSocket(): void {
        if (this.webSocketService.isConnected()) this.webSocketService.disconnect();
    }

    private connectWebSocket(): void {
        const token: string | undefined = this.localStorageService.getItem(XS_STORAGE_KEY_TOKEN);
        if (!XSUtils.isEmpty(token)) {
            this.webSocketService.connect(token!);
        }
    }
}
