<div class="xs-flex-column xs-width-fit-content xs-min-width-500 xs-min-height-300 xs-position-relative xs-border-discrete xs-border-radius-default xs-p-15 {{ styleClass }}">

    <!-- === Header === -->
    <div class="xs-header-bar">
        <i class="{{ ICON.smartCity }}"></i>
        <span>{{ TR_BASE + 'header' | translate }}</span>
    </div>

    <!-- === Skeleton === -->
    <div *ngIf="loading" class="xs-flex-column xs-width-full">

        <div class="xs-flex-column-center xs-width-full xs-plr-30">
            <p-skeleton borderRadius="12px" height="120px" styleClass="xs-mb-10" width="300px"></p-skeleton>
            <p-skeleton borderRadius="12px" height="220px" width="300px"></p-skeleton>
        </div>
    </div>

    <!-- === Error === -->
    <div *ngIf="!loading && hasError()" class="xs-position-relative xs-flex-column xs-flex-1 xs-plr-25 xs-width-full">
        <div class="xs-absolute-center-vh xs-width-full">
            <xs-error [actionButton]="errorRetryButton" [error]="error" [showBorder]="false" [showErrorDetailButton]="true" mode="block">
            </xs-error>
        </div>
    </div>

    <!-- === No Article Found === -->
    <div *ngIf="!articleCard && !loading && !hasError()" class="xs-absolute-center-vh xs-flex-column-center xs-width-full">
        <xs-icon [value]="ICON.smartCity" color="discrete" size="80px"></xs-icon>
        <span [innerHTML]="TR_BASE + 'noArticleFound' | translate" class="xs-display-block xs-font-size-intermediate xs-text-align-center xs-color-light xs-mt-8"></span>
    </div>

    <!-- === Article Card === -->
    <div class="xs-flex-column xs-align-items-center">
        <lce-smartcity-article-card *ngIf="articleCard && !loading && !hasError()" [data]="articleCard!" class="xs-max-width-300"></lce-smartcity-article-card>
    </div>

</div>