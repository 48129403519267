<div [ngClass]="{ 'xs-disabled': disabled}" class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <div *ngIf="canShowAssignmentActions()" class="xs-flex-row xs-align-items-center xs-width-full">
        <xs-button
                (clickEvent)="onAssign($event)"
                *ngIf="canShowAssignAction()"
                [disabled]="!canEnableAssignAction()"
                [loading]="loading.assign"
                [style.margin-left]="spaceBetween"
                label="lce.shared.deliveryAction.assignTo"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
        <xs-button
                (clickEvent)="onUnassign($event)"
                *ngIf="canShowRemoveAssignmentAction()"
                [disabled]="!canEnableRemoveAssignmentAction()"
                [loading]="loading.removeAssignment"
                [style.margin-left]="spaceBetween"
                label="lce.shared.deliveryAction.removeAssignment"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
    </div>
    <p-overlayPanel #assignOverlay [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding">
        <ng-template pTemplate>
            <lce-delivery-assignment-request
                    (assignEvent)="assignOrderToDeliveryMan($event)"
                    (cancelEvent)="onCancelAssignmentRequest()"
                    [error]="assignError"
                    [loading]="loading.assign"
                    [showSelected]="true">
            </lce-delivery-assignment-request>
        </ng-template>
    </p-overlayPanel>
    <p-overlayPanel #unassignOverlay [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding">
        <ng-template pTemplate>
            <lce-delivery-unassignment-request
                    (cancelEvent)="unassignOverlay.hide()"
                    (unassignEvent)="removeAssignment($event)"
                    [error]="assignError"
                    [loading]="loading.removeAssignment">
            </lce-delivery-unassignment-request>
        </ng-template>
    </p-overlayPanel>

    <xs-button
            (clickEvent)="onMenuClick($event)"
            *ngIf="canShowMenu()"
            [icon]="ICON.ellipsisVertical"
            [loading]="loading.menu"
            [style.margin-left]="spaceBetween"
            loaderContainerStyleClass="xs-mt-minus-5"
            loaderPosition="bottom"
            styleClass="{{ buttonStyleClass }}"
            type="text">
    </xs-button>
    <p-tieredMenu #actionMenu [appendTo]="'body'" [model]="actionMenuItems" [popup]="true" styleClass="xs-min-width-225"></p-tieredMenu>
</div>