import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderDeliveryAssignmentResponse, LCECertificateOrderDeliveryAssignmentStatus, LCECertificateOrderDeliveryAssignmentType} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {Subscription} from 'rxjs';
import {LCEDeliveriesFeatureService} from '../../../delivery/lce-deliveries-feature.service';

@Component({
    selector: 'lce-certificate-order-delivery',
    templateUrl: './lce-certificate-order-delivery.component.html',
    providers: [LCEDeliveriesFeatureService]
})
export class LCECertificateOrderDeliveryComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrderRecord.';

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() data: LCECertificateOrder;

    error: any;

    private subscription = new Subscription();

    constructor(private deliveriesFeatureService: LCEDeliveriesFeatureService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public handleAssignOrderToDeliveryMan(assignmentResponse: LCECertificateOrderDeliveryAssignmentResponse): void {
        let assignmentID: string | undefined;

        if (XSUtils.isEmpty(this.data.delivery.assignment)) assignmentID = undefined;
        else assignmentID = this.data.delivery.assignment!.id;

        this.updateData(assignmentResponse);
        this.data.updatedOn = assignmentResponse.assignedOn;
        this.data.updatedBy = assignmentResponse.assignedBy;

        this.data = {...this.data};

        if (!XSUtils.isEmpty(assignmentID)) this.deliveriesFeatureService.emitRefresh();
    }

    public handleRemovedAssignment(unAssignmentResponse: LCECertificateOrderDeliveryAssignmentResponse): void {

        this.updateData(unAssignmentResponse);
        this.data.updatedOn = unAssignmentResponse.unassignedOn;
        this.data.updatedBy = unAssignmentResponse.unassignedBy;

        console.log('unAssignmentResponse', unAssignmentResponse);
        this.deliveriesFeatureService.emitRefresh();
    }

    public onError(error: any): void {
        this.error = error;
    }

    private updateData(assignmentResponse: LCECertificateOrderDeliveryAssignmentResponse): void {

        this.data.delivery.assignment = {
            id: assignmentResponse.id,
            type: LCECertificateOrderDeliveryAssignmentType.MANUAL,
            status: LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED,
            assignedOn: assignmentResponse.assignedOn,
            assignedBy: assignmentResponse.unassignedBy,
            assignedTo: assignmentResponse.assignedTo,
            unassignedBy: assignmentResponse.unassignedBy,
            unassignedOn: assignmentResponse.unassignedOn
        };

        this.data = {...this.data};
    }

}
