import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {XS_STR_EMPTY, XSAssert, XSPKResourceCodeAuditFullService, XSSearchResult, XSUtils} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCENewsArticle, LCENewsArticleCard, LCENewsArticleCreate, LCENewsArticlePartial, LCENewsArticleSearch} from '../../domain/news/lce-news';
import {LCEValidatorUtils} from '../../utils/lce-validator-utils';
import {LCEHttpClientService} from '../lce-http-client.service';
import {LCE_CODE_PREFIX} from '../../constants/lce-core.constant';

@Injectable({providedIn: 'root'})
export class LCENewsArticleService extends XSPKResourceCodeAuditFullService<LCENewsArticle, LCENewsArticlePartial, LCENewsArticleSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.news.articles.index, 'newsArticle');
    }

    public retrieveByIDOrCode(articleIDOrCode: string): Observable<LCENewsArticle> {
        XSAssert.notEmpty(articleIDOrCode, 'articleIDOrCode');
        if (articleIDOrCode.toLowerCase().startsWith(LCE_CODE_PREFIX.toLowerCase())) {
            return this.retrieveByCode(articleIDOrCode);
        }
        return this.retrieveByID(articleIDOrCode);
    }

    public retrieveByID(articleID: string): Observable<LCENewsArticle> {
        return this.retrieve(articleID);
    }

    public retrieveByCode(articleCode: string): Observable<LCENewsArticle> {
        XSAssert.notEmpty(articleCode, 'articleCode');
        return this.httpClientService.get<LCENewsArticle>(LCE_CORE_ENDPOINTS.news.articles.code.replace('${articleCode}', articleCode));
    }

    public autocompleteTags(query: string = XS_STR_EMPTY, limit: number = 5): Observable<string[]> {
        let params = new HttpParams().set('query', query).set('limit', limit);
        return this.httpClientService.get<string[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.news.articles.autocompleteTags), params);
    }

    public removeTags(articleID: string, tags: string[]): Observable<string[]> {
        XSAssert.notEmpty(articleID, 'articleID');
        XSAssert.notEmpty(tags, 'tags');
        const params = new HttpParams().set('tags', tags.map(tag => tag.trim()).join(','));
        return this.httpClientService.delete<string[]>(this.buildEndpoint(articleID, LCE_CORE_ENDPOINTS.news.articles.tags), params);
    }

    public addTags(articleID: string, tags: string[]): Observable<string[]> {
        XSAssert.notEmpty(articleID, 'articleID');
        XSAssert.notEmpty(tags, 'tags');
        const params = new HttpParams().set('tags', tags.map(tag => tag.trim()).join(','));
        return this.httpClientService.patch<string[]>(this.buildEndpoint(articleID, LCE_CORE_ENDPOINTS.news.articles.tags), null, params);
    }

    public searchAsCards(articleSearch: LCENewsArticleSearch): Observable<XSSearchResult<LCENewsArticleCard>> {
        XSAssert.notEmpty(articleSearch, 'articleSearch');
        return this.httpClientService.get<XSSearchResult<LCENewsArticleCard>>(this.buildEndpoint(LCE_CORE_ENDPOINTS.news.articles.searchAsCards), this.toHttpParameters(articleSearch));
    }

    public findNLastAsCards(municipalityCode?: string, nLast: number = 1): Observable<LCENewsArticleCard[]> {
        let params = new HttpParams().set('nLast', nLast);
        if (!XSUtils.isEmpty(municipalityCode)) {
            params = params.set('municipalityCode', municipalityCode!.trim());
        }
        return this.httpClientService.get<LCENewsArticleCard[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.news.articles.lastAsCards), params);
    }

    public create(articleCreate: LCENewsArticleCreate): Observable<LCENewsArticle> {
        LCEValidatorUtils.validateNewsArticleCreate(articleCreate);
        return this.httpClientService.post<LCENewsArticle>(this.buildEndpoint(), articleCreate);
    }
}
