import {Injectable} from '@angular/core';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEShippingInternationalProvider} from '@lce/core';
import {LCECertificateOrderPriceEstimatorComponent} from './lce-certificate-order-price-estimator.component';

export interface LCECertificateOrderPriceEstimatorCreateArg {
    styleClass?: string;
    titleStyleClass?: string;
    subtitleStyleClass?: string;
    title?: string;
    subtitle?: string;
    PriceDetailsShowDefaultMessage?: boolean;
    facilityCode: string;
    currency: string;
    shippingProvider?: LCEShippingInternationalProvider;
}

@Injectable()
export class LCECertificateOrderPriceEstimatorDialogService {
    constructor(private dialogService: XSDialogService) {
    }


    public openCreateDialog(arg?: LCECertificateOrderPriceEstimatorCreateArg): void {

        const dConfig: XSDialogConfig = {
            data: {
                styleClass: arg?.styleClass,
                titleStyleClass: arg?.titleStyleClass,
                subtitleStyleClass: arg?.subtitleStyleClass,
                title: arg?.title,
                subtitle: arg?.subtitle,
                PriceDetailsShowDefaultMessage: arg?.PriceDetailsShowDefaultMessage,
                facilityCode: arg?.facilityCode,
                currency: arg?.currency,
                shippingProvider: arg?.shippingProvider
            },
            header: true,
            headerSeparator: true,
            footer: true,
            closable: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-min-height-500 xs-max-width-1000'
        };

        const createComponent: LCECertificateOrderPriceEstimatorComponent = this.dialogService.openComponentDialog(LCECertificateOrderPriceEstimatorComponent, dConfig);

    }


}