<div class="xs-min-width-400  {{ styleClass }}" [ngClass]="{ 'xs-border-discrete xs-border-radius-default': showBorder === true }">
    <div class="xs-flex-column xs-p-15">
        <lce-facility-worker-detail-head [data]="data"></lce-facility-worker-detail-head>

        <div class="xs-flex-column xs-width-full xs-mt-20 xs-pt-20 xs-border-top-discrete">
            <span class="xs-data-section-title xs-mb-15">{{ 'xs.core.label.contact' | translate }}</span>

            <xs-data-email *ngIf="data.email | xsIsNotEmpty" styleClass="xs-color-secondary" valueStyleClass="xs-font-size-intermediate" [value]="data.email!"> </xs-data-email>

            <xs-data-phone-number styleClass="xs-color-secondary xs-mt-10" valueStyleClass="xs-font-size-intermediate" [value]="data.primaryPhoneNumber"></xs-data-phone-number>
        </div>

        <div *ngIf="showViewRecordButton" class="xs-flex-row xs-width-full xs-justify-content-end xs-mt-50">
            <xs-button styleClass="xs-button-small" type="text" label="lce.shared.user.label.viewDetail" (clickEvent)="onViewRecordBtnClick()"> </xs-button>
        </div>
    </div>
</div>
