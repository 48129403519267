import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCENewsArticleComment, LCENewsArticleCommentSearch} from '@lce/core';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XS_LOREM_IPSUM, XSHttpMethod, XSIDCode, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceHandler, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.news.articles.comments';

@Injectable()
export class LCEMockNewsArticleCommentHandler extends XSMockPKResourceHandler<LCENewsArticleComment, LCENewsArticleComment, LCENewsArticleCommentSearch> {

    public static ARTICLE_COMMENT_STORAGE = new Map<string, LCENewsArticleComment>();

    private queryPredicates: XSMockSearchQueryPredicate<LCENewsArticleComment>[] = [
        (comment, query) => comment.id.toLowerCase().includes(query),
        (comment, query) => comment.article.id.toLowerCase().includes(query),
        (comment, query) => comment.article.code.toLowerCase().includes(query),
        (comment, query) => comment.content.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCENewsArticleComment>[] = [
        (comment, params) => this.httpParamArrayIncludes(params, 'articleIDs', comment.article.id),
        (comment, params) => this.httpParamStringIncludes(params, 'articleCodes', comment.article.code),
        (comment, params) => this.httpParamStringIncludes(params, 'content', comment.content)
    ];

    constructor() {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.news.comments.index);
        this.mockDataArray = [];
    }

    public buildMockDataArray(): void {
        this.mockDataArray = [
            ...this.mockDataArray,
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.search',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.news.comments.index + '/' + LCE_CORE_ENDPOINTS.news.comments.search,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 500,
                getResponseData: rArg => {
                    const searchResult = this.searchResponseData(rArg.params, this.queryPredicates, this.searchPredicates);
                    searchResult.data = XSUtils.isEmpty(searchResult.data) ? [] : searchResult.data.reverse();
                    return searchResult;
                }
            }
        ];
    }

    public getStorage(): Map<string, LCENewsArticleComment> {
        return LCEMockNewsArticleCommentHandler.ARTICLE_COMMENT_STORAGE;
    }

    public toPartial(comment: LCENewsArticleComment): LCENewsArticleComment {
        return comment;
    }

    public addArticleCommentsMockData(articleIDCode: XSIDCode, numberOfComments?: number): void {
        const nComments = XSUtils.isNull(numberOfComments) ? XSUtils.randomElement([0, 1, 2, 4, 4, 4, 5, 6, 7, 8]) : numberOfComments!;
        for (let $i = 0; $i < nComments; $i++) {
            const comment = this.buildComment(articleIDCode);
            LCEMockNewsArticleCommentHandler.ARTICLE_COMMENT_STORAGE.set(comment.id, comment);
        }
        this.mockDataArray.push(this.createCommentMockData(articleIDCode));
    }

    private createCommentMockData(articleIDCode: XSIDCode, requestDelay: number = 3000): XSMockData<LCENewsArticleComment> {
        return {
            id: DATASET_BASE_ID + '.create.' + articleIDCode.id,
            active: true,
            requestMethod: XSHttpMethod.POST,
            requestURL: LCE_CORE_ENDPOINTS.news.comments.create.replace('${articleID}', articleIDCode.id),
            requestStatus: HttpStatusCode.Ok,
            requestDelay: requestDelay,
            getResponseData: rArg => {
                const comment: LCENewsArticleComment = this.buildComment(articleIDCode, rArg.body.content);
                LCEMockNewsArticleCommentHandler.ARTICLE_COMMENT_STORAGE.set(comment.id, comment);
                return comment;
            }
        };
    }

    private buildComment(articleIDCode: XSIDCode, message?: string): LCENewsArticleComment {
        return {
            id: XSUtils.uuid(),
            createdBy: LCEMockUtils.randomUserCustomer(),
            createdOn: new Date().toDateString(),
            article: articleIDCode,
            content: XSUtils.isEmpty(message) ? this.randomContent() : message!.trim()
        };
    }

    private randomContent(): string {
        return XSUtils.randomElement([XS_LOREM_IPSUM.long, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.short]);
    }
}
