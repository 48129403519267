import {Component, Input, OnInit} from '@angular/core';
import {LCEUserMunicipalEmployee} from '@lce/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-municipal-employee-record-statistics',
    templateUrl: './lce-user-municipal-employee-record-statistics.component.html'
})
export class LCEUserMunicipalEmployeeRecordStatisticsComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.user.label.';

    @Input() styleClass?: string;

    @Input() readonly: boolean;

    @Input() userRecord: LCEUserMunicipalEmployee;

    constructor() {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.userRecord, 'userRecord');
    }
}
