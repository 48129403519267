import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {LCEDeliveryDestinationType} from '@lce/core';

@Component({
    selector: 'lce-delivery-destination-type',
    template: `
        <div class="xs-font-size-intermediate xs-color-secondary {{styleClass}}">
            <span *ngIf="!isDestinationFacility()" class="xs-display-block">{{ ('lce.core.deliveryDestinationType.' + type) | translate }}</span>
            <span *ngIf="isDestinationFacility()" class="xs-display-block">{{ 'lce.core.label.noDelivery' | translate }}</span>
        </div>
    `
})
export class LCEDeliveryDestinationTypeComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() type: LCEDeliveryDestinationType;

    constructor(private translationService: XSTranslationService) {
    }

    ngOnInit() {
        XSAssert.notEmpty(this.type, 'type');
    }

    public isDestinationFacility(): boolean {
        return this.type === LCEDeliveryDestinationType.FACILITY;
    }
}
