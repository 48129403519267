import {Injectable} from '@angular/core';
import {LCEServicePoint} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEServicePointCreateUpdateComponent} from './create-update/lce-service-point-create-update.component';
import {LCEServicePointRecordComponent} from './record/lce-service-point-record.component';

export interface LCEServicePointDialogCreateUpdateArg {
    servicePointID?: string;
    servicePoint?: LCEServicePoint;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (servicePoint?: LCEServicePoint) => void;
    onSave?: (servicePoint?: LCEServicePoint) => void;
}

export interface LCEServicePointDialogRecordArg {
    servicePointID?: string;
    servicePoint?: LCEServicePoint;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (servicePoint?: LCEServicePoint) => void;
    onEdit?: (servicePoint: LCEServicePoint) => void;
}

@Injectable()
export class LCEServicePointDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEServicePointDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.servicePointID) && XSUtils.isEmpty(arg.servicePoint)) {
            throw new Error('arg.servicePointID and arg.servicePoint cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.servicePointID) && !XSUtils.isEmpty(arg.servicePoint)) {
            throw new Error('arg.servicePointID and arg.servicePoint cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                servicePointID: arg.servicePointID,
                servicePoint: arg.servicePoint,
                showEditButton: arg.showEditButton,
                dataManager: arg.dataManager,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEServicePointRecordComponent = this.dialogService.openComponentDialog(LCEServicePointRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedServicePoint: LCEServicePoint) => {
            if (arg?.onClose) arg!.onClose!(savedServicePoint);
        });
        recordComponent.editEvent.subscribe((servicePointRecord: LCEServicePoint) => {
            if (arg?.onEdit) arg.onEdit(servicePointRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    servicePoint: servicePointRecord,
                    onClose: (servicePoint) => this.openRecordDialog({servicePoint: servicePoint, showEditButton: true}),
                    onSave: (servicePoint) => this.openRecordDialog({servicePoint: servicePoint, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEServicePointDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.servicePointID) && !XSUtils.isEmpty(arg?.servicePointID)) {
            throw new Error('arg.servicePointID and arg.servicePoint cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                servicePointID: arg?.servicePointID,
                servicePoint: arg?.servicePoint,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEServicePointCreateUpdateComponent = this.dialogService.openComponentDialog(LCEServicePointCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((servicePoint: LCEServicePoint) => {
            if (arg?.onClose) arg!.onClose!(servicePoint);
        });
        createUpdateComponent.saveEvent.subscribe((servicePoint: LCEServicePoint) => {
            if (!servicePoint) {
                createUpdateComponent.closeDialog();
                return;
            }
            if (arg?.onSave) arg.onSave(servicePoint);
            createUpdateComponent.closeDialog();
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({servicePoint: servicePoint});
        });
    }
}
