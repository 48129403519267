<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>

    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="retrieveErrorRetryButton"
                [error]="retrieveError"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}errorMessageRetrieve"
                mode="block"
                styleClass="xs-max-width-500"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum xs-header-left-stripe">
                    <span class="xs-card-header-title">
                        {{ (TR_BASE + 'birth' | translate) + birthDeclaration!.delivery.birthNumber }}
                    </span>
                </div>
                <i class="{{ birthIcon }} xs-display-block xs-font-size-extra-large-imp"></i>
            </div>
        </div>

        <div *ngIf="canDisplayData()" [ngClass]="{ 'xs-pt-0-imp': isDialog() }" class="xs-card-content">
            <div class="xs-flex-row xs-justify-content-space-between xs-align-items-center xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="birthDeclaration!" styleClass="mr-50"></lce-user-resource-audit>

                <xs-data-field-resource-state [value]="birthDeclaration"></xs-data-field-resource-state>
            </div>
            <div class="grid">
                <div class="col-12 xs-mb-25">
                    <lce-birth-declaration-delivery
                            (editEvent)="onEditDelivery($event)"
                            (valueChange)="handleDeliveryValueChange($event)"
                            [birthDeclarationCode]="birthDeclaration!.code"
                            [birthDeclarationID]="birthDeclaration!.id"
                            [data]="birthDeclaration!.delivery"
                            [readonly]="readonly">
                    </lce-birth-declaration-delivery>
                </div>

                <div class="col-12 md:col-6">
                    <lce-birth-declaration-parent
                            (editEvent)="onEditParent($event)"
                            (valueChange)="handleParentValueChange($event, 'mother')"
                            [birthDeclarationID]="birthDeclaration!.id"
                            [data]="birthDeclaration!.mother"
                            [readonly]="readonly"
                            type="mother">
                    </lce-birth-declaration-parent>
                </div>

                <div class="col-12 md:col-6">
                    <lce-birth-declaration-parent
                            (editEvent)="onEditParent($event)"
                            [birthDeclarationID]="birthDeclaration!.id"
                            [data]="birthDeclaration!.father"
                            [readonly]="readonly"
                            type="father">
                    </lce-birth-declaration-parent>
                </div>
            </div>
        </div>

        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="birthDeclaration!.id"></xs-data-id>
        </div>
    </div>
</div>
<ng-template #dHeader>
    <lce-record-header-component
            [icon]="birthIcon"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
