<div
    class="xs-flex-column  xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasRetrieveError() && !centralLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error styleClass="xs-max-width-500" mode="block" message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
            subMessage="xs.core.error.contactAdminMessage" [error]="retrieveError" [showErrorDetailButton]="true"
            [actionButton]="retrieveErrorRetryButton" [showActionButton]="true">
        </xs-error>
    </div>

    <div *ngIf="!hasRetrieveError() && !centralLoader.isRunning()" class="{{contentStyleClass}}">
        <div class="grid">
            <div class="col-12 lg:col-4">
                <div
                    class="xs-flex-column-center xs-with-full xs-pt-15 xs-plr-30 xs-pb-30 xs-mb-30 xs-lg-border-bottom-none-imp xs-md-border-bottom-discrete xs-sm-border-bottom-discrete">

                    <xs-image [src]="servicePoint?.mainImage" styleClass="xs-max-width-350"></xs-image>

                    <xs-button
                            [icon]="ICON.camera"
                            class="xs-mt-10"
                            label="xs.core.label.uploadImage"
                            size="intermediate"
                            type="text">
                    </xs-button>
                </div>
            </div>
            <div class="col-12 lg:col-8 xs-pl-10" [ngClass]="{ 'xs-disabled': createUpdateLoading }">
                <div class="grid xs-fields xs-fields-spacer-15">
                    <!-- ------------------------------------------------------------------------------------------------------ -->
                    <!-- === Generals Informations  === -->
                    <!-- ------------------------------------------------------------------------------------------------------ -->
                    <div class="col-12 md:col-6">
                        <lce-input-field-municipality-autocomplete [options]="municipalityField" [showIcon]="true">
                        </lce-input-field-municipality-autocomplete>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="nameField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="fullNameField"></xs-input-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-text [options]="subNameField"></xs-input-field-text>
                    </div>
                    <!-- ------------------------------------------------------------------------------------------------------ -->
                    <!-- === Contact === -->
                    <!-- ------------------------------------------------------------------------------------------------------ -->
                    <div class="col-12 md:col-6">
                        <xs-input-field-phone-number [options]="contactPhoneNumberField"></xs-input-field-phone-number>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-email [options]="contactEmailField"></xs-input-field-email>
                    </div>
                    <div class="col-12">
                        <xs-input-field-address styleClass="xs-mt-25" [options]="addressField"></xs-input-field-address>
                        </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-contact-person styleClass="xs-mt-25" [options]="primaryContactPersonField">
                        </xs-input-field-contact-person>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-input-field-contact-person styleClass="xs-mt-25" [options]="secondaryContactPersonField">
                        </xs-input-field-contact-person>
                    </div>

                </div>
            </div>
            <div *ngIf="!isDialog()" class="xs-flex-row xs-justify-content-end xs-mtb-15">
                <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
            </div>
    </div>
</div>


<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.servicePoint"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-align-items-center xs-justify-content-end"
        [ngClass]="{ 'xs-justify-content-space-between': hasCreateUpdateError() }">
        <xs-error *ngIf="hasCreateUpdateError()" mode="inline" [ellipsis]="true"
            message="{{ TR_BASE_LABEL }}errorMessageCreateUpdate" [error]="createUpdateError"
            [showErrorDetailButton]="true" errorDetailButtonClass="xs-mt-2-imp">
        </xs-error>

        <div class="xs-flex-row xs-align-items-center">
            <xs-button *ngIf="isCreateMode()" type="text" size="intermediate" label="Fill Form"
                class="xs-sm-display-none xs-mr-40" (clickEvent)="fillForm()">
            </xs-button>
            <xs-button *ngIf="isCreateMode()" (clickEvent)="reset()" size="intermediate" type="text"
                label="xs.core.label.reset" confirmationPopupStyleClass="xs-font-size-10"
                [confirmation]="resetConfirmation" [showConfirmation]="shouldShowResetConfirmation()"
                [disabled]="createUpdateLoading">
            </xs-button>
            <xs-button (clickEvent)="closeDialog()" type="button" label="xs.core.label.close"
                styleClass="xs-mlr-15-imp" confirmationPopupStyleClass="xs-font-size-10" severity="secondary"
                [outlined]="true" [confirmation]="closeConfirmation"
                [showConfirmation]="shouldShowCloseConfirmation()"
                [disabled]="createUpdateLoading || centralLoader.isRunning()">
            </xs-button>
            <xs-button type="button" label="xs.core.label.save" [loading]="createUpdateLoading"
                [disabled]="centralLoader.isRunning()" (clickEvent)="createUpdate()">
            </xs-button>
        </div>
    </div>

</ng-template>