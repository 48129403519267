import { LCEHttpClientService } from './lce-http-client.service';
import { XSUtils } from '@xs/base';
import { Observable } from 'rxjs';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';
import { LCEGlobalSearchResultMap } from '../domain/lce-global-search';
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LCEGlobalSearchService {

    constructor(protected httpClientService: LCEHttpClientService) {
    }

    public search(query: string): Observable<LCEGlobalSearchResultMap> {
        let params = new HttpParams();
        if (!XSUtils.isEmpty(query)) {
            params = params.set('query', query);
        }
        return this.httpClientService.get<LCEGlobalSearchResultMap>(LCE_CORE_ENDPOINTS.globalSearch, params);
    }
}
