import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEGlobalSearchResultMap, LCEGlobalSearchService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';
import {OverlayPanel} from 'primeng/overlaypanel';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEGlobalSearchOptions} from '../lce-global-search';
import {LCENewsArticleDialogService} from '../../news/lce-news-article-dialog.service';
import {LCESmartCityArticleDialogService} from '../../smartcity/lce-smartcity-article-dialog.service';
import {LCEBirthDeclarationDialogService} from '../../birth-declaration/lce-birth-declaration-dialog.service';
import {LCEDistrictDialogService} from '../../district/lce-district-dialog.service';
import {LCEFacilityWorkerDialogService} from '../../facility-worker/lce-facility-worker-dialog.service';
import {LCEFacilityDialogService} from '../../facility/lce-facility-dialog.service';
import {LCEMunicipalityDialogService} from '../../municipality/lce-municipality-dialog.service';
import {LCEServicePointDialogService} from '../../service-point/lce-service-point-dialog.service';

@Component({
    selector: 'lce-global-search-input',
    templateUrl: './lce-global-search-input.component.html',
    providers: [
        LCEBirthDeclarationDialogService,
        LCEDistrictDialogService,
        LCEFacilityWorkerDialogService,
        LCEFacilityDialogService,
        LCEMunicipalityDialogService,
        LCEServicePointDialogService,
        LCENewsArticleDialogService,
        LCESmartCityArticleDialogService
    ]
})
export class LCEGlobalSearchInputComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly DEFAULT_SEARCH_TEXT_DELAY: number = 500;
    readonly DEFAULT_SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly DEFAULT_SEARCH_TEXT_MAX_LENGTH: number = 100;

    @Input() styleClass?: string;

    @Input() options?: LCEGlobalSearchOptions;

    searchText: string;
    searching: boolean;

    resultsMap: LCEGlobalSearchResultMap;

    @ViewChild('resultMapOverlay') resultMapOverlay: OverlayPanel;

    private subscription: Subscription = new Subscription();

    constructor(private globalSearchService: LCEGlobalSearchService) {
    }

    ngOnInit() {
        if (XSUtils.isEmpty(this.options)) this.options = {};
        if (XSUtils.isEmpty(this.options?.searchTextDelay)) this.options!.searchTextDelay = this.DEFAULT_SEARCH_TEXT_DELAY;
        if (XSUtils.isEmpty(this.options?.searchTextMinNChars)) this.options!.searchTextMinNChars = this.DEFAULT_SEARCH_TEXT_MIN_N_CHARS;
        if (XSUtils.isEmpty(this.options?.searchTextMaxLength)) this.options!.searchTextMaxLength = this.DEFAULT_SEARCH_TEXT_MAX_LENGTH;
    }

    public search(): void {
        if (XSUtils.isEmpty(this.searchText) || !this.hasRequiredNCharsForSearchText()) {
            this.resultsMap = undefined!;
            return;
        }
        this.searching = true;

        this.subscription.add(
            this.globalSearchService
                .search(this.searchText?.trim())
                .pipe(finalize(() => (this.searching = false)))
                .subscribe((resultMap) => {
                    this.resultsMap = resultMap;
                })
        );
    }

    public hasRequiredNCharsForSearchText(): boolean {
        return this.searchText.trim().length >= this.options!.searchTextMinNChars!;
    }
}
