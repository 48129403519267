import {Component, Input} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcessPartial} from '../../api/domain/lce-certificate-order-batch-process';

@Component({
    selector: 'lce-certificate-order-batch-process-partial',
    templateUrl: './lce-certificate-order-batch-process-partial.component.html',
    host: {class: 'xs-width-full'}
})
export class LCECertificateOrderBatchProcessPartialComponent {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.certificateOrders.certificateOrderBatchProcess.';

    @Input() styleClass?: string;

    @Input() data: LCECertificateOrderBatchProcessPartial;
}
