<div class="grid grid-nogutter xs-width-full {{ styleClass }}">
    <div class="col-7 xs-flex-row xs-align-items-center">
        <lce-certificate-order-partial
                [clickable]="orderNumberClickable"
                [data]="data"
                [detailedStatusIndicator]="true"
                [detailed]="true">
        </lce-certificate-order-partial>
    </div>
    <div [ngClass]="{ 'xs-justify-content-end-imp': readonly }" class="col-5 xs-flex-row xs-align-items-center xs-justify-content-start">

        <lce-certificate-order-actions
                (statusUpdatedEvent)="onStatusUpdated($event)"
                *ngIf="readonly !== true && !isStatusDelivered()"
                [data]="data"
                spaceBetween="40px">
        </lce-certificate-order-actions>

        <div *ngIf="readonly && (data.statusUpdatedOn | xsIsNotEmpty)" class="xs-flex-column">
            <span class="xs-data-field-label xs-font-size-small-imp xs-mb-3">{{ 'lce.shared.label.statusUpdatedOn' | translate }}</span>
            <span class="xs-display-block xs-font-size-small xs-font-weight-500 xs-color-secondary">
                {{ data.statusUpdatedOn! | xsDate: 'xsMediumDateTime' }}
            </span>
        </div>

        <span *ngIf="!readonly && isStatusDelivered()" class="xs-display-block xs-font-size-intermediate xs-color-secondary xs-ml-40">
        {{ 'lce.shared.label.noActionAvailable' | translate }}
        </span>

    </div>
</div>
