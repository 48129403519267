<div class="xs-flex-column xs-width-full {{ styleClass }}">
    <div class="xs-card xs-max-height-500">
        <div class="xs-card-content xs-card-content-scrollable">
            <!-- === No Result Found === -->
            <div *ngIf="resultsMap | xsIsEmpty" class="xs-flex-row-center xs-ptb-10">
                <xs-no-result-found size="small"></xs-no-result-found>
            </div>
            <!-- === Found Result === -->
            <div *ngIf="resultsMap | xsIsNotEmpty" class="xs-flex-column xs-width-full">
                <div class="xs-flex-column xs-width-full">
                    <!-- === Certificate Order === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.CERTIFICATE_ORDER)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.CERTIFICATE_ORDER).data, type: RESOURCE_TYPE.CERTIFICATE_ORDER }"></div>
                    </div>

                    <!-- === Birth Declaration === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.BIRTH_DECLARATION)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.BIRTH_DECLARATION).data, type: RESOURCE_TYPE.BIRTH_DECLARATION }"></div>
                    </div>

                    <!-- === News === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.NEWS_ARTICLE)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.NEWS_ARTICLE).data, type: RESOURCE_TYPE.NEWS_ARTICLE }"></div>
                    </div>

                    <!-- === Smart City === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.SMARTCITY_ARTICLE)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.SMARTCITY_ARTICLE).data, type: RESOURCE_TYPE.SMARTCITY_ARTICLE }"></div>
                    </div>

                    <!-- === District === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.DISTRICT)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.DISTRICT).data, type: RESOURCE_TYPE.DISTRICT }"></div>
                    </div>

                    <!-- === Municipality === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.MUNICIPALITY)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.MUNICIPALITY).data, type: RESOURCE_TYPE.MUNICIPALITY }"></div>
                    </div>

                    <!-- === Facility === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.FACILITY)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.FACILITY).data, type: RESOURCE_TYPE.FACILITY }"></div>
                    </div>

                    <!-- === Service Point === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.SERVICE_POINT)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.SERVICE_POINT).data, type: RESOURCE_TYPE.SERVICE_POINT }"></div>
                    </div>

                    <!-- === Facility Worker === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.FACILITY_WORKER)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.FACILITY_WORKER).data, type: RESOURCE_TYPE.FACILITY_WORKER }"></div>
                    </div>

                    <!-- === Customer === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.CUSTOMER)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.CUSTOMER).data, type: RESOURCE_TYPE.CUSTOMER }"></div>
                    </div>

                    <!-- === Municipal Employee === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.MUNICIPAL_EMPLOYEE)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.MUNICIPAL_EMPLOYEE).data, type: RESOURCE_TYPE.MUNICIPAL_EMPLOYEE }"></div>
                    </div>

                    <!-- === Employee === -->
                    <div *ngIf="hasResult(RESOURCE_TYPE.EMPLOYEE)" class="xs-flex-column">
                        <div *ngTemplateOutlet="searchResult; context: { results: getResult(RESOURCE_TYPE.EMPLOYEE).data, type: RESOURCE_TYPE.EMPLOYEE }"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="xs-card-footer xs-card-footer-separator">
            <div class="xs-flex-row xs-justify-content-end">
                <xs-button (clickEvent)="onShowAll()" [label]="TR_BASE + 'showAll'" [transform]="false" size="small"
                           type="text"></xs-button>
            </div>
        </div>
    </div>
</div>

<ng-template #searchResult let-results="results" let-type="type">
    <div *ngIf="hasResult(type)" class="xs-flex-column xs-mb-20">
        <div class="xs-header-bar xs-header-bar-no-brightness xs-justify-content-space-between xs-mb-0-imp">
            <span class="xs-color-secondary xs-display-block">{{ type | lceResourceType }}</span>
            <xs-badge
                    (clickEvent)="onShowAll()"
                    [small]="true"
                    styleClass="xs-ml-20 xs-background-color-light-imp xs-min-width-35"
                    value="{{ getResult(type).total | xsToString }}"
                    valueStyleClass="xs-color-primary-imp">
            </xs-badge>
        </div>

        <ul class="xs-listing xs-width-full xs-mt-10-imp">
            <li (click)="onResultClick(result, type)" *ngFor="let result of results"
                class="xs-listing-item xs-mb-0-imp xs-cursor-pointer">
                <lce-certificate-order-partial
                        *ngIf="type === 'certificateOrder'"
                        [data]="result"
                        [showIcon]="showIcon"
                        orderNumberStyleClass="xs-color-primary-imp">
                </lce-certificate-order-partial>

                <lce-birth-declaration-partial
                        *ngIf="type === RESOURCE_TYPE.BIRTH_DECLARATION"
                        [data]="result"
                        [showIcon]="showIcon"
                        birthNumberStyleClass="xs-color-primary-imp">
                </lce-birth-declaration-partial>

                <lce-district-partial
                        *ngIf="type === RESOURCE_TYPE.DISTRICT"
                        [data]="result"
                        [showIcon]="showIcon"
                        fullNameStyleClass="xs-color-primary-imp"></lce-district-partial>

                <lce-facility-partial
                        *ngIf="type === RESOURCE_TYPE.FACILITY"
                        [data]="result" [showIcon]="showIcon"
                        fullNameStyleClass="xs-color-primary-imp">
                </lce-facility-partial>

                <lce-municipality-partial
                        *ngIf="type === RESOURCE_TYPE.MUNICIPALITY"
                        [data]="result"
                        [showIcon]="showIcon"
                        [viewRecord]="false"
                        fullNameStyleClass="xs-color-primary-imp">
                </lce-municipality-partial>

                <lce-service-point-partial
                        *ngIf="type === RESOURCE_TYPE.SERVICE_POINT"
                        [data]="result"
                        [showIcon]="showIcon"
                        fullNameStyleClass="xs-color-primary-imp">
                </lce-service-point-partial>

                <lce-news-article-partial
                        *ngIf="type === RESOURCE_TYPE.NEWS_ARTICLE" [data]="result"
                        [showAvatar]="showIcon"
                        titleStyleClass="xs-color-primary-imp">
                </lce-news-article-partial>

                <lce-smartcity-article-partial
                        *ngIf="type === RESOURCE_TYPE.SMARTCITY_ARTICLE" [data]="result"
                        [showAvatar]="showIcon"
                        titleStyleClass="xs-color-primary-imp">
                </lce-smartcity-article-partial>

                <lce-facility-worker-partial
                        *ngIf="type === RESOURCE_TYPE.FACILITY_WORKER"
                        [data]="result"
                        [showFacilityFullName]="true"
                        [showIcon]="showIcon"
                        [showPosition]="true"
                        nameStyleClass="xs-color-primary-imp">
                </lce-facility-worker-partial>

                <lce-user-partial
                        *ngIf="isUserType(type)"
                        [data]="result"
                        [viewDetail]="false"
                        avatarSize="small">
                </lce-user-partial>
            </li>
        </ul>
    </div>
</ng-template>
