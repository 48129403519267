import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';
import {LCECMDDistrictsModule} from '../districts/lce-cmd-districts.module';
import {LCECMDMunicipalitiesModule} from '../municipalities/lce-cmd-municipalities.module';
import {LCECMDFacilitiesModule} from '../facilities/lce-cmd-facilities.module';
import {LCECMDServicePointsModule} from '../service-points/lce-cmd-service-points.module';
import {LCECMDDeliveryCompaniesModule} from '../delivery-companies/lce-cmd-delivery-companies.module';
import {LCECMDResourcesComponent} from './lce-cmd-resources.component';


@NgModule({
    imports: [
        CommonModule,
        LCECMDSharedModule,
        LCECMDDistrictsModule,
        LCECMDMunicipalitiesModule,
        LCECMDFacilitiesModule,
        LCECMDServicePointsModule,
        LCECMDDeliveryCompaniesModule
    ],
    declarations: [
        LCECMDResourcesComponent
    ]
})
export class LCECMDResourcesModule {
}
