import {Router} from '@angular/router';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEUserMunicipalEmployeePartial} from '../../domain/user/lce-user-municipal-employee';
import {LCECoreContextService} from '../lce-core-context.service';
import {LCEHttpClientService} from '../lce-http-client.service';
import {XSAuthenticationService} from '../xs/xs-authentication.service';

export abstract class LCEUserEmployeeAuthenticationService extends XSAuthenticationService<LCEUserMunicipalEmployeePartial> {

    protected constructor(
        protected router: Router,
        protected httpClientService: LCEHttpClientService,
        protected contextService: LCECoreContextService,
        protected endpointInitialization: string) {
        super(
            router,
            httpClientService,
            contextService,
            LCE_CORE_ENDPOINTS.employees.authentication.login,
            LCE_CORE_ENDPOINTS.employees.authentication.logout,
            endpointInitialization
        );
    }
}
