import {formatNumber} from '@angular/common';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCE_TR_BASE_CERTIFICATE_TYPE_MEDIUM, LCECertificateOrderGlobalStatPartial, LCECertificateOrderGlobalStats, LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateType, LCEUtils} from '@lce/core';
import {XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XSIconText, XSSize, XSTranslationService} from '@xs/common';
import {XSDataBox, XSStatisticVariation} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrdersFeatureService} from '../lce-certificate-orders-feature.service';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';

@Component({
    selector: 'lce-certificate-order-data-boxes',
    templateUrl: './lce-certificate-order-data-boxes.component.html',
    host: {class: 'xs-flex-row xs-width-full'},
    providers: [LCECertificateOrdersFeatureService]
})
export class LCECertificateOrderDataBoxesComponent implements OnInit, OnChanges, OnDestroy {

    @Input() styleClass?: string;

    @Input() showTotalBox: boolean;
    @Input() showBirthBox: boolean;
    @Input() showMarriageBox: boolean;
    @Input() showDeathBox: boolean;

    @Input() predefinedPeriod: XSPredefinedPeriod;

    @Input() showBorder: boolean;

    @Input() swiperAutoPlay: boolean;
    @Input() swiperAutoPlayDelay: number;

    @Input() municipalityCode: string;

    @Input() swiper?: boolean;

    @Input() loading?: boolean;

    @Input() dataBoxesStyleClass?: string;

    @Output() initializedEvent = new EventEmitter<void>();
    @Output() orderErrorEvent = new EventEmitter<{ certificateType?: LCECertificateType; predefinedPeriod?: XSPredefinedPeriod; }>();
    @Output() mainValueEvent = new EventEmitter<{ certificateType?: LCECertificateType; predefinedPeriod?: XSPredefinedPeriod; }>();

    dataBoxes: XSDataBox[] = [];
    data: LCECertificateOrderGlobalStats;

    error: any;

    private initializedState: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private certificateOrdersFeatureService: LCECertificateOrdersFeatureService,
        private translationService: XSTranslationService,
        private certificateOrderService: LCECertificateOrderService,
        private eventProcessorService: LCEEventProcessorService
    ) {
        this.subscription.add(this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.update()));

        this.translationService.onLanguageChanged.subscribe(() => this.updateDataBoxes());
    }

    get initialized(): boolean {
        return this.initializedState;
    }

    ngOnInit(): void {
        this.handleDefaults();
        this.initializeDataBoxes();
        this.retrieveData(this.predefinedPeriod);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.predefinedPeriod) && !changes.predefinedPeriod!.isFirstChange()) {
            this.updateDataBoxesPredefinedPeriod();
            this.update(this.predefinedPeriod);
        }
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            this.updateDataBoxes();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public update(predefinedPeriod?: XSPredefinedPeriod): void {
        this.retrieveData(predefinedPeriod);
    }

    private retrieveData(predefinedPeriod?: XSPredefinedPeriod): void {
        if (!XSUtils.isEmpty(predefinedPeriod)) this.predefinedPeriod = predefinedPeriod!;

        const statsSearch: LCECertificateOrderSearch = {
            municipalityCodes: [this.municipalityCode],
            createdOnPredefinedPeriod: this.predefinedPeriod
        };
        this.error = undefined;
        this.loading = true;
        this.subscription.add(
            this.certificateOrderService.globalStats(statsSearch)
                .pipe(finalize(() => {
                    this.loading = false;
                    if (!this.initializedState) {
                        this.initializedState = true;
                        this.initializedEvent.emit();
                    }
                }))
                .subscribe({
                    next: (statistics: LCECertificateOrderGlobalStats) => {
                        this.loading = false;
                        this.data = statistics;
                        this.updateDataBoxes();
                    },
                    error: error => this.error = error
                })
        );
    }

    private updateDataBoxesPredefinedPeriod(): void {
        if (this.showTotalBox) this.getDataBox('total')!.bottomLeft = this.predefinedPeriod;
        if (this.showBirthBox) this.getDataBox(LCECertificateType.BIRTH)!.bottomLeft = this.predefinedPeriod;
        if (this.showMarriageBox) this.getDataBox(LCECertificateType.MARRIAGE)!.bottomLeft = this.predefinedPeriod;
        if (this.showDeathBox) this.getDataBox(LCECertificateType.DEATH)!.bottomLeft = this.predefinedPeriod;
    }

    private updateDataBoxes(): void {
        if (this.showTotalBox && !XSUtils.isEmpty(this.data?.total)) this.updateDataBox(this.data.total);
        if (this.showBirthBox && !XSUtils.isEmpty(this.data?.birth)) this.updateDataBox(this.data.birth, LCECertificateType.BIRTH);
        if (this.showMarriageBox && !XSUtils.isEmpty(this.data?.marriage)) this.updateDataBox(this.data.marriage, LCECertificateType.MARRIAGE);
        if (this.showDeathBox && !XSUtils.isEmpty(this.data?.death)) this.updateDataBox(this.data.death, LCECertificateType.DEATH);
        this.dataBoxes = [...this.dataBoxes];
    }

    private updateDataBox(statistic: LCECertificateOrderGlobalStatPartial, certificateType?: LCECertificateType): void {
        const dataBox = this.getDataBox(certificateType);
        if (XSUtils.isEmpty(dataBox)) {
            return;
        }

        dataBox!.main.avatar = {...dataBox!.main.avatar!, data: this.getCertificateLetter(certificateType)};
        dataBox!.main.value = statistic.value;
        dataBox!.main.onValueClick = () => this.mainValueEvent.emit({certificateType: certificateType, predefinedPeriod: this.predefinedPeriod});
        dataBox!.main.afterValue = this.computeVariation(statistic);

        dataBox!.bottomLeft = this.predefinedPeriod;

        (dataBox?.topRight as XSIconText).text.value = this.formatValue(statistic.variation);

        (dataBox?.bottomRight as XSIconText).text.value = this.formatValue(statistic.inError);
        (dataBox?.bottomRight as XSIconText).onClick = () => this.orderErrorEvent.emit({certificateType: certificateType, predefinedPeriod: this.predefinedPeriod});
    }

    private computeVariation(statistic: LCECertificateOrderGlobalStatPartial): XSStatisticVariation | undefined {
        if (statistic.value === 0 && statistic.variation == 0) {
            return undefined;
        }
        const percentage = ((statistic.value - statistic.variation) / statistic.value) * 100;
        const direction = percentage === 0 ? 'equal' : (percentage > 0 ? 'up' : 'down');

        return {value: Number(Math.abs(percentage).toFixed(2)), direction: direction, unit: '%', showBackground: true};
    }

    private getDataBox(certificateType: LCECertificateType | 'total' | undefined): XSDataBox | undefined {
        const cType = this.certificateOrdersFeatureService.getCertificateType(certificateType);
        return this.dataBoxes.find(dataBox => cType.toString() === dataBox.id);
    }

    private initializeDataBoxes(): void {
        this.dataBoxes = [];
        if (this.showTotalBox) this.dataBoxes.push(this.initializeDataBox('total'));
        if (this.showBirthBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.BIRTH));
        if (this.showMarriageBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.MARRIAGE));
        if (this.showDeathBox) this.dataBoxes.push(this.initializeDataBox(LCECertificateType.DEATH));
    }

    private initializeDataBox(certificateType: LCECertificateType | 'total'): XSDataBox {
        const cType = this.certificateOrdersFeatureService.getCertificateType(certificateType);
        return {
            id: certificateType,
            topLeft: {
                title: {
                    value: 'lce.shared.certificateOrders.label.orders',
                    styleClass: 'xs-font-weight-500-imp xs-color-secondary-imp'
                },
                subTitle: {
                    value: LCE_TR_BASE_CERTIFICATE_TYPE_MEDIUM + cType,
                    styleClass: 'xs-color-secondary-imp'
                }
            },
            bottomLeft: this.predefinedPeriod,
            topRight: {
                _type: 'iconText',
                icon: {
                    value: LCE_SHARED_ICON.stats,
                    size: XSSize.SMALL,
                    styleClass: 'xs-color-secondary'
                },
                iconPosition: 'before',
                text: {value: '...', styleClass: 'xs-color-secondary xs-font-size-intermediate'}
            } as XSIconText,
            bottomRight: {
                _type: 'iconText',
                icon: {
                    value: LCE_SHARED_ICON.error,
                    size: XSSize.SMALL,
                    styleClass: 'xs-color-error'
                },
                iconPosition: 'before',
                text: {value: '...', styleClass: 'xs-color-secondary xs-font-size-intermediate'}
            } as XSIconText,
            main: {
                avatar: {
                    type: 'label',
                    data: this.getCertificateLetter(certificateType!),
                    size: XSSize.MEDIUM,
                    styleClass: 'xs-color-secondary-imp'
                },
                valueStyleClass: 'xs-font-size-large-imp',
                value: undefined!,
                subValue: {value: 'lce.shared.certificateOrders.stats.' + cType},
                onValueClick: () => {
                }
            }
        };
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.showTotalBox)) this.showTotalBox = true;
        if (XSUtils.isNull(this.showBirthBox)) this.showBirthBox = true;
        if (XSUtils.isNull(this.showMarriageBox)) this.showMarriageBox = true;
        if (XSUtils.isNull(this.showDeathBox)) this.showDeathBox = true;
        if (XSUtils.isEmpty(this.predefinedPeriod)) this.predefinedPeriod = XSPredefinedPeriod.THIS_MONTH;
        if (XSUtils.isNull(this.swiper)) this.swiper = true;
        if (XSUtils.isNull(this.swiperAutoPlay)) this.swiperAutoPlay = false;
        if (XSUtils.isNull(this.swiperAutoPlayDelay) && !this.swiperAutoPlay) this.swiperAutoPlayDelay = 1000;
    }

    private formatValue(value: any): any {
        if (!XSUtils.isNumber(value)) return value;
        if (value > 0 && value < 10) {
            return XSUtils.zeroLeftPad(value);
        }
        return formatNumber(value.toFixed(2), this.translationService.getCurrentLanguage().toString());
    }

    private getCertificateLetter(certificateType: LCECertificateType | 'total' | undefined): string {
        if (XSUtils.isEmpty(certificateType) || certificateType === 'total') {
            return 'T';
        }
        return LCEUtils.getCertificateLetter(certificateType!, this.translationService.getCurrentLanguage());
    }

}
