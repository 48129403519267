import { XSContactPerson, XSCoordinate, XSLanguage, XSUser, XSUserCreate, XSUserPartial, XSUserSearch } from '@xs/base';
import { LCEMunicipalityPartial } from '../lce-municipality';
import { LCEVerificationType, XSUserLocking, XSUserLockRequest } from '../xs';

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export interface LCEUserForgotPasswordVerifyUsernameResponse {
    sessionID: string;
    username: string;
}

export interface LCEUserForgotPasswordSendCodeResponse {
    sessionID: string;
    username: string;
    sentOn: string;
    recipient: string;
    verificationType: LCEVerificationType;
    language: XSLanguage;
}

export interface LCEUserForgotPasswordVerifyCodeResponse {
    verifiedOn: string;
    user: LCEUserPartial;
}

export interface LCEUserUpdateAccountStatusResponse {
    status: LCEUserStatus;
    accountStatusUpdatedOn: string;
    accountStatusUpdatedBy: LCEUserPartial;
    updatedOn: string;
    updatedBy: LCEUserPartial;
}

export interface LCEUserResetPasswordResponse {
    id: string;
    code: string;
    passwordResetOn: string;
    passwordResetBy: LCEUserPartial;
}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export interface LCEUserLockUnlockResponse {
    status: LCEUserStatus;

    accountStatus: LCEUserAccountStatus;

    accountStatusUpdatedOn?: string;
    accountStatusUpdatedBy?: LCEUserPartial;

    locking?: XSUserLocking<LCEUserLockReason, LCEUserPartial>;

    updatedOn?: string;
    updatedBy?: LCEUserPartial;
}

export enum LCEUserLockReason {
    SECURITY_PURPOSE = 'securityPurpose',
    UNUSED_ACCOUNT = 'unusedAccount',
    UNKNOWN = 'unknown',
    OTHER = 'other',
}

export interface LCEUserLocking extends XSUserLocking<LCEUserLockReason, LCEUserPartial> {}

export interface LCEUserLockRequest extends XSUserLockRequest<LCEUserLockReason> {}

// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------

export enum LCEUserType {
    EMPLOYEE = 'employee',
    DELIVERY_MAN = 'deliveryMan',
    MUNICIPAL_EMPLOYEE = 'municipalEmployee',
    CUSTOMER = 'customer',
    SYSTEM = 'system',
}

export enum LCEUserProfileImageType {
    DEFAULT = 'default',
    UPLOADED = 'uploaded',
    PREDEFINED = 'predefined',
}

export enum LCEUserAccountStatus {
    LOCKED = 'locked',
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    DELETED = 'deleted',
}

export enum LCEUserStatus {
    ONLINE = 'online',
    OFFLINE = 'offline',
    BUSY = 'busy',
    AWAY = 'away',
    NEVER_CONNECTED = 'neverConnected',
}

export type LCEUserStatusStr = 'online' | 'offline' | 'busy' | 'away' | 'neverConnected';

export interface LCEUserPartial extends XSUserPartial {
    type: LCEUserType;
    status?: LCEUserStatus;
    lastActivity?: string;
}

export interface LCEUserSearch extends XSUserSearch {
    types?: string[];
    municipalityOfResidenceCodes?: string;
    currentCoordinateGeoCircle?: string;
}

export interface LCEUserCreate extends XSUserCreate {
    municipalityOfResidenceCode?: string;
    emergencyContactPerson?: XSContactPerson;
}

export interface LCEUser extends XSUser {
    type: LCEUserType;

    status: LCEUserStatus;
    accountStatus: LCEUserAccountStatus;
    accountStatusUpdatedOn?: string;
    accountStatusUpdatedBy?: LCEUserPartial;

    municipalityOfResidence?: LCEMunicipalityPartial;
    currentCoordinate?: XSCoordinate;

    passwordResetOn?: string;
    passwordResetBy?: LCEUserPartial;

    lastLoginOn?: string;
    lastLogoutOn?: string;

    lastActivity?: string;

    locking?: XSUserLocking<LCEUserLockReason, LCEUserPartial>;

    emergencyContactPerson?: XSContactPerson;
}
