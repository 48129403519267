<div [ngClass]="{ 'xs-border-discrete xs-border-radius-default xs-p-15': showBorder }" class="xs-flex-column {{ styleClass }}">
    <div *ngIf="title | xsIsNotEmpty" class="xs-flex-row">
        <span class="xs-display-block xs-font-size-default xs-font-weight-500 {{ titleStyleClass }}">
            {{ title! | translate : {total: loading === true ? '...' : stats.total} }}
        </span>
    </div>
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mt-15 {{ statsContainerStyleClass }}">
        <lce-certificate-order-letter-stat [loading]="loading" [showBorder]="showStatItemBorders" [value]="stats?.birth" type="birth"></lce-certificate-order-letter-stat>
        <lce-certificate-order-letter-stat [loading]="loading" [showBorder]="showStatItemBorders" [value]="stats?.marriage" type="marriage"></lce-certificate-order-letter-stat>
        <lce-certificate-order-letter-stat [loading]="loading" [showBorder]="showStatItemBorders" [value]="stats?.death" type="death"></lce-certificate-order-letter-stat>
    </div>
</div>
