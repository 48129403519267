import {AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {XSAmount, XSCurrency, XSUtils} from '@xs/base';
import {XSPaymentChargePartial, XSPaymentChargeStatus, XSPaymentType} from '@xs/payment/base';
import {LCECertificateOrderProcessService} from '../lce-certificate-order-process.service';
import {XSPaymentCheckoutCancelEventType, XSPaymentCheckoutChangeEvent, XSPaymentCheckoutComponent, XSPaymentCheckoutInputData} from '@xs/payment/checkout';
import {HttpErrorResponse} from '@angular/common/http';
import {XSCoreDomUtils, XSDisplayableError, XSErrorService, XSIFrameDialogService} from '@xs/core';
import {LCECertificateOrderPriceDetail} from '@lce/core';
import {XSTranslationService} from '@xs/common';
import {LCECertificateOrderPriceDetailsComponent} from '../../price-details/lce-certificate-order-price-details.component';

@Component({
    selector: './lce-certificate-order-process-checkout',
    templateUrl: './lce-certificate-order-process-checkout.component.html',
    providers: [XSIFrameDialogService]
})
export class LCECertificateOrderProcessCheckoutComponent implements OnInit, AfterViewInit {

    readonly DEFAULT_SMALL_SCREEN_BREAKPOINT = 750;

    @Input() styleClass?: string;
    @Input() countryISO: string;

    @Input() smallScreenBreakpoint?: number;

    @Output() successEvent = new EventEmitter<XSPaymentChargePartial>();

    @ViewChild('priceDetails') priceDetails?: LCECertificateOrderPriceDetailsComponent;
    @ViewChild('checkout') checkout?: XSPaymentCheckoutComponent;

    certificateOrderCreateSessionID?: string;

    amount: XSAmount = {value: 0, currency: XSCurrency.XOF};

    cancelEventType: XSPaymentCheckoutCancelEventType;

    changeEvent: XSPaymentCheckoutChangeEvent;
    chargeEvent: XSPaymentChargePartial;
    checkoutEvent: any = {};

    successFailedEvent: any = {};

    error: any;

    finalState: 'success' | 'failed';

    checkoutData: XSPaymentCheckoutInputData = {
        types: [XSPaymentType.WAVE, XSPaymentType.MTN_MONEY, XSPaymentType.ORANGE_MONEY, XSPaymentType.CREDIT_CARD],

        amount: this.amount,
        countryISO: 'ci',
        countryPhoneCode: '+225',

        showErrorDetailButton: true,
        showBorder: true,
        
        header: {},

        footer: {
            showAmountInPayButton: true
        },

        wave: {
            //callbackBaseURL: `${location.origin}/wave.checkout.html`
            callbackBaseURL: 'https://dev.lce.ci/wave.checkout.html'
        },
        mobileMoney: {
            orangeMoney: {
                callbackBaseURL: `${location.origin}/orange-money.checkout.html`
            }
        },
        creditCard: {
            useBillingAddress: true
        },

        successDelayInSeconds: 5,

        events: {
            onCharge: event => this.chargeEvent = event || {},

            onSuccess: event => {
                this.successFailedEvent = event || {};
                console.log('---| Payment Succeed', this.successFailedEvent);
                this.checkoutData = {...this.checkoutData, amount: this.amount};
                this.finalState = 'success';
                this.checkoutData.selectedType = undefined;
                this.successEvent.emit(event);
            },
            onFailed: event => {
                this.successFailedEvent = event || {};
                console.log('---| Payment Failed', this.successFailedEvent);
                this.finalState = 'failed';
            },

            onComplete: event => {
                this.checkoutEvent = event || {};
                console.log('---| Payment Completed', this.checkoutEvent);
            },
            onTimeout: event => this.checkoutEvent = event || {},

            onCancel: event => {
                this.cancelEventType = event;
                this.checkoutData.selectedType = undefined;
            },

            onError: event => {
                if (!event.error) return;
                this.checkoutEvent = event;
                if (event.error && !(event.error instanceof HttpErrorResponse)) {
                    this.checkoutEvent.error = {
                        name: event.error.name,
                        message: event.error.message,
                        stack: event.error.stack
                    };
                }
            },
            onShowErrorDetail: error => {
                if (error instanceof HttpErrorResponse) this.errorService.handleError(error);
                else {
                    this.errorService.logError(error);
                    const displayableError = new XSDisplayableError({
                        reason: error,
                        exceptionName: 'XSPaymentInternalFrontEndError'
                    });
                    this.errorService.showErrorDialog({error: displayableError});
                }
            }
        },

        autoUpdateOnWindowResize: true,
        //countdownNSeconds: 30,

        fnOpenModal: arg => this.iframeDialogService.open({src: arg.src, footerCloseButton: true}),

        mobilePhoneNumberOptions: {
            allowedCountryISOs: ['ci', 'bf', 'bj', 'cm', 'sn'],
            showCountryDropdown: false,
            showIcon: true,
            iconType: 'phone'
        }
    };

    screenWidth: number;
    isSmallScreen: boolean;

    constructor(
        private elementRef: ElementRef,
        private errorService: XSErrorService,
        private iframeDialogService: XSIFrameDialogService,
        private translationService: XSTranslationService,
        private certificateOrderProcessService: LCECertificateOrderProcessService) {
    }


    ngOnInit(): void {
        this.certificateOrderCreateSessionID = this.certificateOrderProcessService.getOrderCreateSessionID();
        if (!XSUtils.isEmpty(this.certificateOrderCreateSessionID)) {
            this.checkoutData.externalData = {};
            this.checkoutData.externalData!.sessionID = this.certificateOrderCreateSessionID;
        }

        if (XSUtils.isEmpty(this.smallScreenBreakpoint)) this.smallScreenBreakpoint = this.DEFAULT_SMALL_SCREEN_BREAKPOINT;
        this.checkScreenSize();
        this.certificateOrderProcessService.onResize.subscribe(() => this.handleResize());

    }

    ngAfterViewInit(): void {
        if (this.isSmallScreen) {
            setTimeout(() => {
                XSCoreDomUtils.scrollTo(undefined, undefined, {
                    top: 500,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 5000);
        }
    }

    public isCheckoutLoaderRunning(): boolean {
        return !XSUtils.isEmpty(this.checkout) && this.checkout!.isLoaderRunning();
    }

    public priceDetailsIsRunning(): boolean {
        return !XSUtils.isEmpty(this.priceDetails) && this.priceDetails!.isRunning();
    }

    public onPriceDetailChange(eventPriceDetail: LCECertificateOrderPriceDetail): void {
        this.checkoutData.amount = eventPriceDetail.total;
        this.checkoutData.amountOptions = {};
        const certificateType = this.translationService.translateKey('lce.core.certificateType.' + eventPriceDetail!.context.certificateType);
        this.checkoutData.amountOptions!.description = this.translationService.translateKey('lce.shared.certificateOrders.process.payment.checkoutDescription', {
            type: certificateType,
            copy: eventPriceDetail.numberOfCopies
        });
        this.checkoutData.amountOptions!.subDescription = eventPriceDetail.context.municipalityFullName;
    }

    public isPaymentFailed(): boolean {
        return !XSUtils.isNull(this.chargeEvent) && this.chargeEvent!.status === XSPaymentChargeStatus.FAILED;
    }

    @HostListener('window:resize')
    private onWindowResize(): void {
        this.certificateOrderProcessService.emitResize();
    }

    private getElementRefWidth(): number {
        return this.elementRef.nativeElement.offsetWidth;
    }

    private handleResize() {
        this.checkScreenSize();
    }

    private checkScreenSize(): void {
        this.screenWidth = this.getElementRefWidth();
        this.screenWidth <= this.smallScreenBreakpoint! ? this.isSmallScreen = true : this.isSmallScreen = false;
    }

}
