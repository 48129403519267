import {LCEImage} from '../article/lce-article-image';
import {LCEArticleReviewer} from '../article/lce-article-reviewer';
import {XSIntegerRange, XSPKAuditUpdatedSearchFilter, XSPKDTOAuditFull, XSTranslatableString} from '@xs/base';
import {LCEUserPartial} from '../user/lce-user';
import {LCECategoryPartial} from '../lce-category';
import {LCEMunicipalityPartial} from '../lce-municipality';

export enum LCESmartCityEventName {
    ARTICLE_NEW = 'smartCityArticleNew',
    ARTICLE_REVIEWER_ADDED = 'smartCityArticleReviewerAdded',
    ARTICLE_REVIEWER_REMOVED = 'smartCityArticleReviewerRemoved',
    ARTICLE_REVIEWER_UPDATED_STATUS = 'smartCityArticleReviewerUpdatedStatus',
}

export interface LCESmartCityArticleSearch extends XSPKAuditUpdatedSearchFilter {
    codes?: string[];

    types?: LCESmartCityArticleType[];

    title?: string;
    subTitle?: string;
    description?: string;
    content?: string;

    statuses?: LCESmartCityArticleStatus[];

    categoryCodes?: string[];
    tags?: string[];

    readCountRange?: XSIntegerRange;
    scanCount?: XSIntegerRange;
    shareCountRange?: XSIntegerRange;
}

export interface LCESmartCityArticleCreate {
    type: LCESmartCityArticleType;

    audienceMunicipalityCodes?: string[];
    audienceAllMunicipalities?: boolean;

    title: XSTranslatableString;
    subTitle?: XSTranslatableString;
    description: XSTranslatableString;
    content: XSTranslatableString;

    coverImage: string;
    images?: LCEImage[];

    categoryCodes: string[];

    tags?: string[];

    reviewerIDs: string[];
}

export interface LCESmartCityArticleCard {
    id: string;
    code: string;

    type: LCESmartCityArticleType;

    title: string;
    subTitle?: string;
    description: string;
    coverImage?: string;

    createdOn: string;
    publishedOn?: string;

    readCount: number;
    scanCount: number;
    shareCount: number;
}

export interface LCESmartCityArticlePartial {
    id: string;
    code: string;
    type: LCESmartCityArticleType;
    title: string;
    description: string;
    coverImage?: string;
}

export enum LCESmartCityArticlePredefinedFilter {
    LATEST = 'latest',
    POPULAR = 'popular',
    MOST_READ = 'mostRead',
    MOST_SHARED = 'mostShared',
    MOST_SCANNED = 'mostScanned',
}

export enum LCESmartCityArticleStatus {
    HIDDEN = 'hidden',
    CREATED = 'created',
    ACCEPTED = 'accepted',
    IN_REVIEW = 'inReview',
    SCHEDULED = 'scheduled',
    REWORKING = 'reworking',
    PUBLISHED = 'published',
    TO_BE_REWORK = 'toBeRework',
    PARTIALLY_PUBLISHED = 'partiallyPublished',
}

export enum LCESmartCityArticleType {
    PUBLIC_EVENT = 'publicEvent',
    NOTIFICATION = 'notification',
    AMBER_ALERT = 'amberAlert',
}

export interface LCESmartCityArticle extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;

    type: LCESmartCityArticleType;

    ownerMunicipality: LCEMunicipalityPartial;

    audienceMunicipalities?: LCEMunicipalityPartial[];

    audienceAllMunicipalities?: boolean;

    title: string;
    subTitle?: string;
    description: string;
    content: string;

    status: LCESmartCityArticleStatus;

    qrCode: string;

    coverImage?: string;
    images?: LCEImage[];

    map?: Object;

    reviewers: LCEArticleReviewer[];

    categories: LCECategoryPartial[];

    tags?: string[];

    readCount: number;
    shareCount: number;
    scanCount: number;

    publishedOn?: string;
    publishedBy?: LCEUserPartial;
}
