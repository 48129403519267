import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCECertificateOrderEventData, LCECertificateOrderLight, LCECertificateOrderService, LCEEventPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService, XSLoaderType} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';
import {LCECertificateOrderDialogService} from '../lce-certificate-order-dialog.service';

@Component({selector: 'lce-certificate-order-last', templateUrl: './lce-certificate-order-last.component.html'})
export class LCECertificateOrderLastComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = 'lastOrderReceived';
    readonly LOADER_TYPE = XSLoaderType.THREE_BOUNCE;
    readonly LOADER_SIZE?: number = 15;

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;

    @Input() municipalityCode: string;

    @Input() showLabel?: boolean;

    @Input() orderNumberClickable?: boolean;

    @Input() errorShowDialogOnClick?: boolean;

    error: any;

    data?: LCECertificateOrderLight;

    private subscription: Subscription = new Subscription();

    constructor(
        private certificateOrderService: LCECertificateOrderService,
        private certificateOrderDialogService: LCECertificateOrderDialogService,
        private eventProcessorService: LCEEventProcessorService,
        private loaderService: XSLoaderService) {
        this.subscription.add(
            this.eventProcessorService.onNewCertificateOrder
                .subscribe(event => this.handleNotificationCertificateOrderNew(event))
        );
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
        this.update();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onOrderNumberClick(event: any): void {
        if (this.orderNumberClickable !== true) {
            event.stopImmediatePropagation();
            return;
        }
        this.certificateOrderDialogService.openRecordDialog({
            orderID: this.data!.id,
            orderNumber: this.data!.orderNumber
        });
    }

    public update(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.certificateOrderService.findMunicipalityNLastOrders(this.municipalityCode)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: lastOrderReceived => {
                        this.data = XSUtils.isEmpty(lastOrderReceived) ? undefined : lastOrderReceived[0];
                    },
                    error: error => this.error = error
                })
        );
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    private handleNotificationCertificateOrderNew(event: LCEEventPartial<LCECertificateOrderEventData>): void {
        this.data = {
            id: event.resourceID,
            orderNumber: event.resourceCode,
            status: event.data!.status,
            createdOn: event.createdOn
        };
    }
}
