import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCE_TR_BASE_FACILITY_WORKER_POSITION, LCEFacilityWorkerPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEFacilityWorkerUtils} from '../lce-facility-worker-utils';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({
    selector: 'lce-facility-worker-partial',
    template: `
        <xs-ivar
                [styleClass]="styleClass"
                [avatarDisabled]="disabled"
                [iconDisabled]="disabled"
                [linesDisabled]="disabled"
                (line1Click)="onClick($event)"
                [line1Clickable]="clickable"
                (iconClick)="onClick($event)"
                [disabled]="disabled"
                [line1]="data.name | xsPersonName"
                [line2]="showPosition ? TR_BASE_FACILITY_WORKER_POSITION + data.position : ''"
                [line3]="showFacilityFullName ? data.facilityFullName : ''"
                [line4]="showCode ? data.code : ''"
                line1StyleClass="xs-font-size-intermediate-imp {{ nameStyleClass }}"
                line2StyleClass="xs-font-size-small-imp xs-color-secondary xs-mt-2-imp"
                line3StyleClass="xs-font-size-small-imp xs-color-secondary xs-mt-2-imp"
                line4StyleClass="xs-font-size-small-imp xs-color-secondary xs-mt-2-imp"
                iconStyleClass="xs-color-secondary-imp xs-font-size-26-imp"
                [icon]="workerIcon"
                [showIcon]="showIcon">
        </xs-ivar>

        <p-overlayPanel #partialOverlay *ngIf="showOverlay" [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding xs-max-width-500">
            <lce-facility-worker-detail [data]="data" [showViewRecordButton]="showViewRecordButton"></lce-facility-worker-detail>
        </p-overlayPanel>
    `,
    host: {class: 'xs-width-full'}
})
export class LCEFacilityWorkerPartialComponent implements OnInit {

    readonly TR_BASE_FACILITY_WORKER_POSITION = LCE_TR_BASE_FACILITY_WORKER_POSITION;

    @Input() styleClass?: string;
    @Input() nameStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCEFacilityWorkerPartial;

    @Input() showIcon?: boolean;

    @Input() showFacilityFullName?: boolean;
    @Input() showPosition?: boolean;
    @Input() showCode?: boolean;

    @ViewChild('partialOverlay') detailOverlay: OverlayPanel;

    @Input() showOverlay?: boolean;
    @Input() showViewRecordButton?: boolean;

    @Output() clickEvent = new EventEmitter<LCEFacilityWorkerPartial>();

    workerIcon: string;
    clickable: boolean;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;
        if (XSUtils.isNull(this.showFacilityFullName) && XSUtils.isNull(this.showPosition) && XSUtils.isNull(this.showCode)) {
            this.showPosition = true;
        }
        this.workerIcon = LCEFacilityWorkerUtils.getIcon(this.data.position);
        this.clickable = this.clickEvent.observers.length > 0;
    }

    public onClick(event: any): void {
        if (!this.showOverlay) return;
        this.detailOverlay.toggle(event);
        this.clickEvent.emit(this.data);
    }
}
