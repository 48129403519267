import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    LCE_TR_BASE_CORE_LABEL,
    LCE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLES,
    LCE_USER_MUNICIPAL_EMPLOYEE_POSITIONS,
    LCEMunicipalityPartial,
    LCEUserMunicipalEmployee,
    LCEUserMunicipalEmployeeCreate,
    LCEUserMunicipalEmployeeMainRole,
    LCEUserMunicipalEmployeePosition,
    LCEUserMunicipalEmployeeService
} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSAddressType, XSAssert, XSGender, XSLanguage, XSUtils} from '@xs/base';
import {XS_GENDER_ITEMS, XS_TR_BASE_LANGUAGE, XSCommonDomainUtils, XSFormUtils, XSLabelValue, XSLoaderService, XSValidators} from '@xs/common';
import {
    XSButton,
    XSConfirmation,
    XSDialogable,
    XSInputFieldAddressOptions,
    XSInputFieldBaseOptions,
    XSInputFieldContactPersonOptions,
    XSInputFieldDropdownOptions,
    XSInputFieldPhoneNumberOptions,
    XSInputFieldRadiosOptions,
    XSInputFieldTextAreaOptions,
    XSInputFieldTextOptions,
    XSIvarAvatarType
} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-municipal-employee-create-update',
    templateUrl: './lce-user-municipal-employee-create-update.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCEMunicipalEmployeeCreateUpdateComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_CORE: string = LCE_TR_BASE_CORE_LABEL;
    readonly TR_BASE: string = 'lce.shared.user.';
    readonly TR_BASE_LABEL: string = 'lce.shared.user.label.';

    readonly TR_BASE_CORE_LABEL: string = 'xs.core.label.';
    readonly TR_BASE_COMMON_LABEL: string = 'xs.common.label.';

    readonly LOADER_ID_CENTRAL: string = 'userCreateUpdateCentralLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() formContainerStyleClass?: string;

    @Input() showSecondaryPhoneNumberField?: boolean;
    @Input() showWhatsAppPhoneNumberField?: boolean;

    @Input() userCountryISO: string;

    @Input() userID?: string;
    @Input() user?: LCEUserMunicipalEmployee;

    @Output() saveEvent = new EventEmitter<LCEUserMunicipalEmployee>();
    @Output() closeEvent = new EventEmitter<LCEUserMunicipalEmployee>();

    disabled: boolean = false;
    formGroup: FormGroup = new FormGroup({});
    firstNameField: XSInputFieldTextOptions;
    lastNameField: XSInputFieldTextOptions;
    genderField: XSInputFieldDropdownOptions;
    languageField: XSInputFieldRadiosOptions;
    municipalityOfResidenceField: XSInputFieldBaseOptions;
    mainRoleField: XSInputFieldDropdownOptions;
    positionField: XSInputFieldDropdownOptions;
    facilityField: XSInputFieldBaseOptions;
    emailField: XSInputFieldTextOptions;
    primaryPhoneNumberField: XSInputFieldPhoneNumberOptions;
    secondaryPhoneNumberField: XSInputFieldPhoneNumberOptions;
    whatsAppPhoneNumberField: XSInputFieldPhoneNumberOptions;
    addressField: XSInputFieldAddressOptions;
    emergencyContactPersonField: XSInputFieldContactPersonOptions;
    noteField: XSInputFieldTextAreaOptions;

    avatar: string;
    avatarType: XSIvarAvatarType;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveUser()
    };

    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private readonly GENDERS: XSLabelValue[] = XS_GENDER_ITEMS;
    private readonly MAIN_ROLES: XSLabelValue[] = LCE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLES;
    private readonly POSITIONS: XSLabelValue[] = LCE_USER_MUNICIPAL_EMPLOYEE_POSITIONS;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private userService: LCEUserMunicipalEmployeeService) {
        super();
    }

    get headerTitle(): string {
        let title = XS_STR_EMPTY;
        if (this.isCreateMode()) title = this.TR_BASE_LABEL + 'createTitle';
        else if (!XSUtils.isEmpty(this.user?.name)) title = XSCommonDomainUtils.getPersonFullName(this.user!.name!);
        if (XSUtils.isEmpty(title)) title = this.TR_BASE_LABEL + 'user';
        return title;
    }

    get headerSubTitle(): string {
        let subTitle = XS_STR_EMPTY;
        if (this.isCreateMode()) subTitle = this.TR_BASE_LABEL + 'createSubTitle';
        else if (!XSUtils.isEmpty(this.user?.name)) subTitle = this.user!.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.userID = this.dialogConfig.data.userID;
            this.user = this.dialogConfig.data.user;
            this.userCountryISO = this.dialogConfig.data.userCountryISO;

            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.formContainerStyleClass = this.dialogConfig.data.formContainerStyleClass;
            this.showSecondaryPhoneNumberField = this.dialogConfig.data.showSecondaryPhoneNumberField;
            this.showWhatsAppPhoneNumberField = this.dialogConfig.data.showWhatsAppPhoneNumberField;

            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.user));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.user)) {
            if (!XSUtils.isEmpty(this.user)) this.userID = this.user!.id;
            this.initialize();
        } else this.retrieveUser();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.userID) || !XSUtils.isEmpty(this.user);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.userID) && XSUtils.isEmpty(this.user);
    }

    public onUploadImage(): void {
        console.log('onUploadImage ...');
    }

    public hasUser(): boolean {
        return !XSUtils.isEmpty(this.user);
    }

    public reset(): void {
        this.formGroup.reset();
        this.updateAvatar();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.buildUpdate(this.formGroup.value).size !== 0;
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public fillForm(): void {
        this.firstNameField.control?.setValue('John');
        this.lastNameField.control?.setValue('Doe');
        this.genderField.control?.setValue(XSGender.MALE);
        this.languageField.control?.setValue(XSLanguage.FRENCH);
        this.mainRoleField.control?.setValue(LCEUserMunicipalEmployeeMainRole.AGENT);
        this.positionField.control?.setValue(LCEUserMunicipalEmployeePosition.ADMINISTRATIVE_AGENT);
        this.primaryPhoneNumberField.control?.setValue('0707972351');
        this.secondaryPhoneNumberField.control?.setValue('0707972352');
        this.whatsAppPhoneNumberField.control?.setValue('+2250707972353');
        this.emailField.control?.setValue('john.doe@iro-xs.com');
        
        this.municipalityOfResidenceField.control?.setValue({
            id: '7067ab2e-6298-42e7-919e-a4a446cdee4e',
            code: 'LCE-MUN-ABJ-YOP',
            name: 'Yopougon',
            abbreviation: 'YOP',
            fullName: 'Commune de Yopougon',
            district: {
                id: 'xxx',
                code: 'LCE-DST-ABJ',
                name: 'Abidjan',
                abbreviation: 'ABJ',
                fullName: 'District Autonome d\'Abidjan'
            }
        } as LCEMunicipalityPartial);

        this.addressField.control?.setValue({
            type: XSAddressType.UNSTRUCTURED,
            title: 'Pharmacie Analya',
            line1: 'À Proximité du nouveau CHU Angré',
            city: 'Abidjan',
            countryISO: 'ci',
            coordinate: {latitude: 5.4027176999999975, longitude: -3.961497273014281}
        });
        this.emergencyContactPersonField.control?.setValue({
            name: {firstName: 'Tony', lastName: 'Stark'},
            primaryPhoneNumber: '+2250707972349',
            email: 'tony.stark@iro-xs.com'
        });
    }

    private initialize(): void {
        XSAssert.notEmpty(this.userCountryISO, 'userCountryISO');
        this.buildFields();
        this.buildConfirmations();
        this.updateAvatar();
    }

    private retrieveUser(): void {
        this.retrieveError = undefined;
        this.startLoader();
        this.userService
            .retrieve(this.userID!)
            .pipe(finalize(() => this.stopLoader()))
            .subscribe({
                next: userRecord => {
                    this.user = userRecord;
                    this.initialize();
                    this.updateDialogSize();
                },
                error: (error: any) => (this.retrieveError = error)
            });
    }

    private updateAvatar(): void {
        if (XSUtils.isEmpty(this.user?.profileImage)) {
            if (!XSUtils.isEmpty(this.firstNameField.control?.value) && !XSUtils.isEmpty(this.lastNameField.control?.value)) {
                this.avatar = XSCommonDomainUtils.getPersonInitials({
                    firstName: this.firstNameField.control!.value,
                    lastName: this.lastNameField.control?.value
                });
                this.avatarType = XSIvarAvatarType.LABEL;
            } else {
                this.avatar = this.ICON.user;
                this.avatarType = XSIvarAvatarType.ICON;
            }
        } else {
            this.avatar = this.user!.profileImage!;
            this.avatarType = XSIvarAvatarType.IMAGE;
        }
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating User [userID: ' + this.userID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        console.log('fieldValueMap', fieldValueMap);

        if (fieldValueMap.size === 0) {
            LOG().debug('User not updated. No change detected ! [code: ' + this.user!.code + ', id: ' + this.userID + ']');
            this.saveEvent.emit(this.user);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.userService
                .update(this.userID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: userRecord => {
                        this.user = userRecord;
                        this.userID = this.user.id;
                        LOG().debug('User successfully updated :-) [code: ' + this.user.code + ', id: ' + this.userID + ']');
                        this.saveEvent.emit(this.user);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating User ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const userCreate = this.buildCreate(formData);

        console.log('userCreate', userCreate);

        this.subscription.add(
            this.userService
                .create(userCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: userRecord => {
                        this.user = userRecord;
                        this.userID = this.user.id;
                        LOG().debug('User successfully saved :-) [code: ' + this.user.code + ', id: ' + this.userID + ']');
                        this.saveEvent.emit(userRecord);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private buildUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();

        if (!XSUtils.equal(XSUtils.trim(formData.firstName), XSUtils.trim(this.user!.name!.firstName))) {
            fieldValueMap.set('name.firstName', XSUtils.trim(formData.firstName));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.lastName), XSUtils.trim(this.user!.name!.lastName))) {
            fieldValueMap.set('name.lastName', XSUtils.trim(formData.lastName));
        }
        if (!XSUtils.equal(formData.gender, this.user!.gender)) fieldValueMap.set('gender', formData.gender);
        if (!XSUtils.equal(formData.language, this.user!.language)) fieldValueMap.set('language', formData.language);
        if (!XSUtils.equal(formData.municipalityOfResidence?.code, this.user!.municipalityOfResidence?.code)) {
            fieldValueMap.set('municipalityOfResidenceCode', formData.municipalityOfResidence.code);
        }

        if (!XSUtils.equal(formData.mainRole, this.user!.mainRole)) fieldValueMap.set('mainRole', formData.mainRole);
        if (!XSUtils.equal(formData.position, this.user!.position)) fieldValueMap.set('position', formData.position);
        if (!XSUtils.equal(formData.facility?.code, this.user!.facility?.code)) {
            fieldValueMap.set('facilityCode', formData.facility.code);
        }

        if (!XSUtils.equal(XSUtils.trim(formData.primaryPhoneNumber), XSUtils.trim(this.user!.primaryPhoneNumber))) {
            fieldValueMap.set('primaryPhoneNumber', XSUtils.trim(formData.primaryPhoneNumber));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.secondaryPhoneNumber), XSUtils.trim(this.user!.secondaryPhoneNumber))) {
            fieldValueMap.set('secondaryPhoneNumber', XSUtils.trim(formData.secondaryPhoneNumber));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.whatsAppPhoneNumber), XSUtils.trim(this.user!.whatsAppPhoneNumber))) {
            fieldValueMap.set('whatsAppPhoneNumber', XSUtils.trim(formData.whatsAppPhoneNumber));
        }

        if (!XSUtils.equal(XSUtils.trim(formData.email), XSUtils.trim(this.user!.email))) {
            fieldValueMap.set('email', XSUtils.trim(formData.email));
        }

        if (!XSUtils.equal(formData.address, this.user!.address)) fieldValueMap.set('address', formData.address);

        if (!XSUtils.equal(formData.emergencyContactPerson, this.user!.emergencyContactPerson)) fieldValueMap.set('emergencyContactPerson', formData.emergencyContactPerson);

        if (!XSUtils.equal(XSUtils.trim(formData.note), XSUtils.trim(this.user!.note))) {
            fieldValueMap.set('note', XSUtils.trim(formData.note));
        }

        return fieldValueMap;
    }

    private buildCreate(formData: any): LCEUserMunicipalEmployeeCreate {
        console.log('|--- Municipality create order formData', formData);
        const userCreate: LCEUserMunicipalEmployeeCreate = {
            name: {firstName: formData.firstName.trim(), lastName: formData.lastName.trim()},
            gender: formData.gender,
            language: formData.language,
            // --- * ---
            mainRole: formData.mainRole,
            position: formData.position,
            // --- * ---
            primaryPhoneNumber: formData.primaryPhoneNumber.trim(),
            secondaryPhoneNumber: formData.secondaryPhoneNumber?.trim(),
            whatsAppPhoneNumber: formData.whatsAppPhoneNumber?.trim(),
            email: formData.email.trim(),
            address: formData.address,
            // --- * ---
            municipalityOfResidenceCode: formData.municipalityOfResidence.code,
            facilityCode: formData.facility.code,
            emergencyContactPerson: formData.emergencyContactPerson,
            // --- * ---
            note: formData.note?.trim()
        };

        XSUtils.removeNullAndUndefinedEntries(userCreate);

        return userCreate;
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.reset(),
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.closeDialog(),
            reject: () => {
            }
        };
    }

    private isFormEmpty(): boolean {
        return (
            XSUtils.isEmpty(this.firstNameField.control?.value) &&
            XSUtils.isEmpty(this.lastNameField.control?.value) &&
            XSUtils.isEmpty(this.genderField.control?.value) &&
            XSUtils.isEmpty(this.languageField.control?.value) &&
            XSUtils.isEmpty(this.municipalityOfResidenceField.control?.value) &&
            XSUtils.isEmpty(this.mainRoleField.control?.value) &&
            XSUtils.isEmpty(this.positionField.control?.value) &&
            XSUtils.isEmpty(this.facilityField.control?.value) &&
            XSUtils.isEmpty(this.primaryPhoneNumberField.control?.value) &&
            XSUtils.isEmpty(this.secondaryPhoneNumberField?.control?.value) &&
            XSUtils.isEmpty(this.whatsAppPhoneNumberField?.control?.value) &&
            XSUtils.isEmpty(this.emailField.control?.value) &&
            XSUtils.isEmpty(this.addressField.control?.value) &&
            XSUtils.isEmpty(this.emergencyContactPersonField.control?.value) &&
            XSUtils.isEmpty(this.noteField.control?.value)
        );
    }

    private buildFields(): void {
        console.log('userCountryISO', this.userCountryISO);
        this.noteField = {
            fieldName: 'note',
            placeholder: this.TR_BASE_COMMON_LABEL + 'notePlaceholder',
            control: new FormControl(this.user?.note)
        };
        // --------------------------------------------------------------------------------
        // --- Contact ---
        // --------------------------------------------------------------------------------

        this.primaryPhoneNumberField = {
            fieldName: 'primaryPhoneNumber',
            label: this.TR_BASE_COMMON_LABEL + 'primaryPhoneNumber',
            control: new FormControl(this.user?.primaryPhoneNumber, Validators.required),
            countryISO: this.userCountryISO,
            countryDropdown: XSUtils.isEmpty(this.userCountryISO),
            countryDropdownFilter: XSUtils.isEmpty(this.userCountryISO),
            showCountryFlag: !XSUtils.isEmpty(this.userCountryISO)
        };
        if (this.showSecondaryPhoneNumberField !== false || !XSUtils.isEmpty(this.user?.secondaryPhoneNumber)) {
            this.secondaryPhoneNumberField = {
                fieldName: 'secondaryPhoneNumber',
                label: this.TR_BASE_COMMON_LABEL + 'secondaryPhoneNumber',
                control: new FormControl(this.user?.secondaryPhoneNumber),
                countryISO: this.userCountryISO,
                countryDropdown: XSUtils.isEmpty(this.userCountryISO),
                countryDropdownFilter: XSUtils.isEmpty(this.userCountryISO),
                showCountryFlag: !XSUtils.isEmpty(this.userCountryISO)
            };
            this.formGroup.addControl(this.secondaryPhoneNumberField.fieldName, this.secondaryPhoneNumberField.control!);
        }
        if (this.showWhatsAppPhoneNumberField !== false || !XSUtils.isEmpty(this.user?.whatsAppPhoneNumber)) {
            this.whatsAppPhoneNumberField = {
                fieldName: 'whatsAppPhoneNumber',
                label: this.TR_BASE_COMMON_LABEL + 'whatsAppPhoneNumber',
                control: new FormControl(this.user?.whatsAppPhoneNumber),
                whatsApp: true
            };
            this.formGroup.addControl(this.whatsAppPhoneNumberField.fieldName, this.whatsAppPhoneNumberField.control!);
        }
        this.emailField = {
            fieldName: 'email',
            label: this.TR_BASE_COMMON_LABEL + 'email',
            control: new FormControl(this.user?.email, Validators.required)
        };
        this.addressField = {
            fieldName: 'address',
            label: 'xs.core.label.address',
            control: new FormControl(this.user?.address, Validators.required),
            addressOptions: {
                dataOptions: {
                    capitalize: true
                },
                formOptions: {
                    type: this.user?.address?.type ? this.user.address.type : XSAddressType.UNSTRUCTURED,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.UNSTRUCTURED, XSAddressType.STRUCTURED],
                    title: true,
                    showResetButton: !this.isUpdateMode()
                }
            }
        };
        this.emergencyContactPersonField = {
            fieldName: 'emergencyContactPerson',
            label: this.TR_BASE_CORE_LABEL + 'emergencyContact',
            control: new FormControl(this.user?.emergencyContactPerson, Validators.required),
            contactPersonOptions: {
                formOptions: {
                    showDescriptionField: true,
                    showSecondaryPhoneNumberField: false,
                    showEmailField: true,
                    showNoteField: true,
                    primaryPhoneNumber: {
                        countryISO: this.userCountryISO,
                        showCountryFlag: true
                    },
                    showResetButton: !this.isUpdateMode()
                }
            }
        };

        // --------------------------------------------------------------------------------
        // --- Account ---
        // --------------------------------------------------------------------------------

        this.mainRoleField = {
            fieldName: 'mainRole',
            label: this.TR_BASE_COMMON_LABEL + 'mainRole',
            control: new FormControl(this.user?.mainRole, Validators.required),
            items: this.MAIN_ROLES,
            placeholder: this.TR_BASE_LABEL + 'mainRolePlaceholder'
        };
        this.positionField = {
            fieldName: 'position',
            label: this.TR_BASE_COMMON_LABEL + 'position',
            control: new FormControl(this.user?.position, Validators.required),
            items: this.POSITIONS,
            placeholder: this.TR_BASE_LABEL + 'positionPlaceholder'
        };
        this.facilityField = {
            fieldName: 'facility',
            control: new FormControl(this.user?.facility, Validators.required),
            label: this.TR_BASE_LABEL + 'facility'
        };

        // --------------------------------------------------------------------------------
        // --- Personal Information ---
        // --------------------------------------------------------------------------------

        this.firstNameField = {
            fieldName: 'firstName',
            label: this.TR_BASE_COMMON_LABEL + 'firstName',
            control: new FormControl(this.user?.name?.firstName, Validators.required),
            inputStyleClass: 'xs-text-transform-capitalize',
            valueChange: () => this.updateAvatar(),
            keyUpEvent: () => this.updateAvatar(),
            blurEvent: () => this.updateAvatar()
        };
        this.lastNameField = {
            fieldName: 'lastName',
            label: this.TR_BASE_COMMON_LABEL + 'lastName',
            control: new FormControl(this.user?.name?.lastName, Validators.required),
            inputStyleClass: 'xs-text-transform-capitalize',
            valueChange: () => this.updateAvatar(),
            keyUpEvent: () => this.updateAvatar(),
            blurEvent: () => this.updateAvatar()
        };
        this.genderField = {
            fieldName: 'gender',
            label: this.TR_BASE_COMMON_LABEL + 'gender',
            control: new FormControl(this.user?.gender, Validators.required),
            placeholder: this.TR_BASE_COMMON_LABEL + 'genderPlaceholder',
            items: this.GENDERS
        };
        this.languageField = {
            fieldName: 'language',
            label: this.TR_BASE_COMMON_LABEL + 'language',
            control: new FormControl(this.user?.language, XSValidators.checkedRadioRequired()),
            colStyleClass: 'md:col-6',
            radios: [
                {label: XS_TR_BASE_LANGUAGE + XSLanguage.FRENCH, value: XSLanguage.FRENCH},
                {label: XS_TR_BASE_LANGUAGE + XSLanguage.ENGLISH, value: XSLanguage.ENGLISH}
            ]
        };
        this.municipalityOfResidenceField = {
            fieldName: 'municipalityOfResidence',
            control: new FormControl(this.user?.municipalityOfResidence),
            label: this.TR_BASE_LABEL + 'municipalityOfResidence'
        };
        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------

        this.formGroup.addControl(this.firstNameField.fieldName, this.firstNameField.control!);
        this.formGroup.addControl(this.lastNameField.fieldName, this.lastNameField.control!);
        this.formGroup.addControl(this.genderField.fieldName, this.genderField.control!);
        this.formGroup.addControl(this.languageField.fieldName, this.languageField.control!);
        this.formGroup.addControl(this.municipalityOfResidenceField.fieldName, this.municipalityOfResidenceField.control!);

        this.formGroup.addControl(this.mainRoleField.fieldName, this.mainRoleField.control!);
        this.formGroup.addControl(this.positionField.fieldName, this.positionField.control!);
        this.formGroup.addControl(this.facilityField.fieldName, this.facilityField.control!);

        this.formGroup.addControl(this.emailField.fieldName, this.emailField.control!);
        this.formGroup.addControl(this.primaryPhoneNumberField.fieldName, this.primaryPhoneNumberField.control!);

        this.formGroup.addControl(this.addressField.fieldName, this.addressField.control!);
        this.formGroup.addControl(this.emergencyContactPersonField.fieldName, this.emergencyContactPersonField.control!);

        this.formGroup.addControl(this.noteField.fieldName, this.noteField.control!);
    }

    private startLoader(loaderText?: string): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL, {text: loaderText});
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID_CENTRAL);
    }
}
