import {XSAddress, XSGender, XSPersonName, XSPKAuditFullSearchFilter, XSPKDTOAuditFull} from '@xs/base';
import {LCEIdentityDocumentType} from './lce-core';
import {LCEFacilityPartial} from './facility/lce-facility';
import {LCEFacilityWorkerPartial} from './lce-facility-worker';
import {LCEUserPartial} from './user/lce-user';

export enum LCEBirthDeclarationEventName {
    NEW_DECLARATION = 'birthDeclarationNewDeclaration',
}

export interface LCEBirthDeclarationParent {
    name: XSPersonName;
    birthDate?: string;
    birthLocation?: string;
    occupation?: string;
    address?: XSAddress;
    nationality: string;
    identityType?: LCEIdentityDocumentType;
    identityNumber?: string;
    phoneNumber: string;
}

export interface LCEBirthDeclarationParentPartial {
    name: XSPersonName;
    phoneNumber: string;
}

export interface LCEBirthDeclarationCreate {
    facilityCode: string;
    delivery: LCEBirthDeclarationDelivery<string>;
    mother: LCEBirthDeclarationParent;
    father?: LCEBirthDeclarationParent;
}

export interface LCEBirthDeclarationDelivery<T> {
    birthNumber: string;
    declaredBy: T;
    bornOn: string;
    receivedOn?: string;
    newBornName?: XSPersonName;
    newBornGender: XSGender;
}

export interface LCEBirthDeclarationDeliveryPartial {
    birthNumber: string;
    newBornGender: XSGender;
    newBornName?: XSPersonName;
}

export interface LCEBirthDeclarationSearch extends XSPKAuditFullSearchFilter {
    codes?: string[];
    facilityCodes?: string[];
    birthNumbers?: string[];
    newBornGenders?: string[];
    bornOnRange?: string;
    receivedOnRange?: string;
    received?: boolean;
}

export interface LCEBirthDeclaration extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;
    facility: LCEFacilityPartial;
    delivery: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>;
    mother: LCEBirthDeclarationParent;
    father?: LCEBirthDeclarationParent;
}

export interface LCEBirthDeclarationPartial {
    id: string;
    createdOn: string;
    code: string;
    birthNumber: string;
    newBornName?: XSPersonName;
    newBornGender: XSGender;
    motherName: XSPersonName;
}
