import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEMunicipalityPartial, LCEMunicipalityService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-input-field-municipality-autocomplete',
    template: `
        <xs-input-field-auto-complete styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-municipality xsTemplate="item">
                <lce-municipality-partial [data]="municipality"></lce-municipality-partial>
            </ng-template>
        </xs-input-field-auto-complete>
    `,
    host: {class: 'xs-width-full'}
})
export class LCEInputFieldMunicipalityAutocompleteComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() options: XSInputFieldBaseOptions;

    @Input() showIcon?: boolean;

    @Input() completeOnFocus?: boolean;

    @Input() showDefaultBelowText?: boolean;

    @Input() placeholder?: string;

    @Input() appendTo?: any;

    @Output() selectEvent = new EventEmitter<LCEMunicipalityPartial>();

    autocompleteOptions: XSInputFieldAutoCompleteOptions;

    @ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

    constructor(private municipalityService: LCEMunicipalityService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.municipality.label.autocompletePlaceholder';
        if (XSUtils.isNull(this.showIcon)) this.showIcon = false;

        this.autocompleteOptions = {
            control: this.options.control,
            autoCompleteOptions: {
                labelField: 'fullName',
                forceSelection: true,
                completeOnFocus: this.completeOnFocus,
                search: (query: string) => {
                    return this.municipalityService.autocomplete(query);
                }
            },
            placeholder: this.placeholder,
            belowText: this.showDefaultBelowText === true ? 'lce.core.label.municipality' : undefined,
            belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
            selectEvent: (event: any) => {
                this.handleSelectEvent(event);
            },
            ...this.options
        };
        if (this.showIcon) {
            this.autocompleteOptions.inputStyleClass = 'xs-pl-35-imp';
            this.autocompleteOptions.leftIcon = LCE_SHARED_ICON.municipality;
            this.autocompleteOptions.leftIconStyleClass = 'xs-color-secondary xs-font-size-18-imp xs-mt-minus-1';
        }

    }

    private handleSelectEvent(municipality: LCEMunicipalityPartial): void {
        this.selectEvent.emit(municipality);
    }
}
