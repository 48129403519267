<div class=" xs-flex-column xs-width-full xs-max-width-1000 {{styleClass}}">
    <div class="xs-width-full xs-mb-50">
        <div class="xs-width-full xs-flex-row xs-justify-content-center xs-height-40">
            <xs-state-box
                    *ngIf="error | xsIsNotEmpty"
                    [state]="'failed'"
                    iconSize="20px"
                    line1="lce.shared.certificateOrders.process.payment.HttpErrorLine1"
                    line1StyleClass="xs-font-weight-500"
                    line2="lce.shared.certificateOrders.process.payment.HttpErrorLine2"
                    styleClass="xs-max-width-400">
            </xs-state-box>

            <xs-state-box
                    *ngIf="isPaymentFailed()"
                    [state]="'failed'"
                    iconSize="20px"
                    line1="lce.shared.certificateOrders.process.payment.failedStatusErrorLine1"
                    line1StyleClass="xs-font-weight-500"
                    line2="lce.shared.certificateOrders.process.payment.failedStatusErrorLine2"
                    styleClass="xs-max-width-400">
            </xs-state-box>
        </div>
    </div>
    <div class="xs-width-full {{!isSmallScreen? 'xs-flex-row xs-min-width-350' : 'xs-flex-column xs-align-items-center'}}">
        <div [ngClass]="{'xs-width-percent-50 xs-pr-50': !isSmallScreen, 'xs-width-full xs-mb-20': isSmallScreen}" class="xs-max-width-500">
            <lce-certificate-order-price-details
                #priceDetails
                (dataEvent)="onPriceDetailChange($event)"
                [certificateOrderCreateSessionID]="certificateOrderCreateSessionID"
                styleClass="xs-width-full">
            </lce-certificate-order-price-details>
        </div>

        <div class="xs-flex-row checkout {{!isSmallScreen ? 'xs-width-percent-50' : 'xs-width-full xs-justify-content-center'}}">
            <xs-payment-checkout
                #checkout
                [(data)]="checkoutData"
                [disabled]="priceDetailsIsRunning()"
                class="xs-max-width-500-imp">
            </xs-payment-checkout>
        </div>
    </div>
</div>
