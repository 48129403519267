import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEMunicipality, LCEMunicipalityCanOptions, LCEMunicipalityService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_DD_MM_YYYY, XS_DATE_FORMAT_YYYY_MM_DD, XSLoaderService, XSNumberPipe, XSSize, XSTranslationService} from '@xs/common';
import {XSAvatar, XSButton, XSDataManagerComponent, XSDialogable, XSIvar, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-municipality-record',
    templateUrl: './lce-municipality-record.component.html',
    host: {class: 'xs-width-full'},
    providers: [XSNumberPipe]
})
export class LCEMunicipalityRecordComponent extends XSDialogable implements OnInit {

    readonly ICON_MUNICIPALITY: string = LCE_SHARED_ICON.municipality;
    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;
    readonly ICON_CALENDAR: string = LCE_SHARED_ICON.calendar;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.municipality.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly DATE_FORMAT_EN: string = XS_DATE_FORMAT_YYYY_MM_DD;
    readonly DATE_FORMAT_FR: string = XS_DATE_FORMAT_DD_MM_YYYY;

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() municipalityID?: string;
    @Input() municipality?: LCEMunicipality;
    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;
    @Input() canOptions?: LCEMunicipalityCanOptions;

    @Output() editEvent = new EventEmitter<LCEMunicipality>();
    @Output() closeEvent = new EventEmitter<LCEMunicipality>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEMunicipality>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieveMunicipality()
    };

    private subscription: Subscription = new Subscription();

    constructor(
        public municipalityService: LCEMunicipalityService,
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private numberPipe: XSNumberPipe) {
        super();
    }

    get headerAvatar(): XSAvatar {
        return {type: 'icon', size: XSSize.SMALL, data: this.ICON_MUNICIPALITY};
    }

    get headerTitle(): string {
        return XSUtils.isEmpty(this.municipality) ? this.TR_BASE_LABEL + 'municipality' : this.municipality!.fullName;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.municipality) ? '...' : this.municipality!.district.fullName;
    }

    get resourceState(): string {
        if (XSUtils.isEmpty(this.municipality)) throw new Error('municipality is not supposed to be empty or undefined.');
        if (this.municipality!.deleted === true) return 'xs.core.label.deleted';
        else if (this.municipality!.active === false) return 'xs.core.label.inactive';
        return 'xs.core.label.active';
    }

    get populationIvarData(): XSIvar | undefined {
        if (XSUtils.isEmpty(this.municipality!.populationSize)) return undefined;
        const pIvarData: XSIvar = {line1: this.numberPipe.transform(this.municipality!.populationSize!)};
        if (!XSUtils.isNull(this.municipality!.populationUpdateYear)) {
            pIvarData.line2 = this.translationService.translateKey('lce.shared.label.yearOfUpdate', {year: this.municipality!.populationUpdateYear});
            pIvarData.line2StyleClass = 'xs-font-weight-normal-imp';
        }
        return pIvarData;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.municipalityID = this.dialogConfig.data.municipalityID;
            this.municipality = this.dialogConfig.data.municipality;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
        }
        if (XSUtils.isEmpty(this.municipalityID) && XSUtils.isEmpty(this.municipality)) {
            throw new Error('municipalityID and municipality cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.municipalityID) && !XSUtils.isEmpty(this.municipality)) {
            throw new Error('municipalityID and municipality cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.municipalityID)) this.retrieveMunicipality();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }


    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public onEdit(): void {
        this.editEvent.emit(this.municipality);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.municipality);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private retrieveMunicipality(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.municipalityService
                .retrieve(this.municipalityID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (municipality: LCEMunicipality) => {
                        this.municipality = municipality;
                    },
                    error: error => (this.error = error)
                })
        );
    }
}
