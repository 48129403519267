import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECertificateOrder,
    LCECertificateOrderConfirmation,
    LCECertificateOrderCreate,
    LCECertificateOrderCreateSession,
    LCECertificateOrderDeliveryAssignmentStatus,
    LCECertificateOrderDeliveryAssignmentType,
    LCECertificateOrderLight,
    LCECertificateOrderPartial,
    LCECertificateOrderPriceSettings,
    LCECertificateOrderPriceTripDefinition,
    LCECertificateOrderSearch,
    LCECertificateOrderStatsChartLine,
    LCECertificateOrderStatsCount,
    LCECertificateOrderStatus,
    LCEUserPartial,
    XS_MONTH_NAME_MAP,
    XSMonthKey
} from '@lce/core';
import {LCEMockDataCertificateOrderStatisticsBuilder} from '@lce/mock/core/data-builder/lce-mock-data-certificate-order-statistics.builder';
import {LCEMockCertificateOrderCreateSessionHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-create-session-handler';
import {LCEMockCertificateOrderStatusHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-status-handler';
import {XS_LOREM_IPSUM, XSAmount, XSAssert, XSCurrency, XSHttpMethod, XSHttpParamsUtils, XSLanguage, XSPKDTOAuditFullState, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate, XSMockUtils} from '@xs/mock';
import {XSPaymentCharge, XSPaymentChargePartial} from '@xs/payment/base';
import {XSMockPaymentChargeHandler} from '@xs/payment/mock';
import {BehaviorSubject} from 'rxjs';
import {LCEMockUtils} from '../../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../../lce-mock.constant';
import {LCEMockDataCertificateOrderBuilder} from '@lce/mock/core/data-builder/lce-mock-data-certificate-order.builder';
import {LCEMockCommonHandler} from '@lce/mock/core/handlers/lce-mock-common-handler';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '@lce/mock/core/lce-mock-user-data';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificateOrders';

@Injectable()
export class LCEMockCertificateOrderHandler
    extends XSMockPKResourceAuditFullHandler<LCECertificateOrder, LCECertificateOrderPartial, LCECertificateOrderSearch>
    implements XSMockPKSearchable<LCECertificateOrderPartial> {

    public static readonly DEFAULT_ID = 'f425a356-fb59-44bb-8ca5-f62e49ddad43';

    public static CERTIFICATE_ORDER_STORAGE: Map<string, LCECertificateOrder> = new Map<string, LCECertificateOrder>();

    public PRICE_SETTING_STORAGE: LCECertificateOrderPriceSettings = this.buildPriceSettings();

    private createSubject = new BehaviorSubject<LCECertificateOrder>(undefined!);
    onCreate = this.createSubject.asObservable();

    private queryPredicates: XSMockSearchQueryPredicate<LCECertificateOrder>[] = [
        (certificateOrder, query) => certificateOrder.orderNumber.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.customer?.primaryPhoneNumber.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.customer?.name?.firstName.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.customer?.name?.lastName.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.certificate.referenceNumber.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.facility.code.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.facility.fullName.toLowerCase().includes(query?.toLowerCase()),
        (certificateOrder, query) => certificateOrder.facility.name.toLowerCase().includes(query?.toLowerCase())
    ];

    private searchPredicates: XSMockSearchPredicate<LCECertificateOrder>[] = [
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'orderNumbers', certificateOrder.orderNumber),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'statuses', certificateOrder.status),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'facilityCodes', certificateOrder.facility.code),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'facilityTypes', certificateOrder.facility.type),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'municipalityCodes', certificateOrder.facility.municipality.code),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'districtCodes', certificateOrder.facility.municipality.district.code),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'certificateTypes', certificateOrder.certificate.type),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'certificateReferenceNumbers', certificateOrder.certificate.referenceNumber),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'destinationTypes', certificateOrder.delivery.destination.type),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'customerIDs', certificateOrder.customer?.id),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'customerCodes', certificateOrder.customer?.code),
        (certificateOrder, params) => this.httpParamArrayIncludes(params, 'deliveryManIDs', certificateOrder.delivery?.deliveredBy?.id),
        (certificateOrder, params) => this.httpParamStringIncludes(params, 'customerPrimaryPhoneNumber', certificateOrder.customer?.primaryPhoneNumber),
        (certificateOrder, params) => this.httpParamStringIncludes(params, 'customerEmail', certificateOrder.customer?.email)
    ];

    constructor(
        private mockCommonHandler: LCEMockCommonHandler,
        private mockCertificateOrderStatusHandler: LCEMockCertificateOrderStatusHandler,
        private mockDataCertificateOrderBuilder: LCEMockDataCertificateOrderBuilder,
        private mockDataCertificateOrderStatisticsBuilder: LCEMockDataCertificateOrderStatisticsBuilder,
        @Optional() private mockCertificateOrderCreateSessionHandler: LCEMockCertificateOrderCreateSessionHandler,
        @Optional() private paymentChargeHandler: XSMockPaymentChargeHandler) {

        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.certificateOrders.index);
        this.mockDataArray = [];

        this.mockCommonHandler.onDeliveryUnassignment.subscribe(event => {
            if (XSUtils.isNull(event)) return;
            const order = this.findOneByID(event.assignmentResponse.certificateOrder.id);
            order!.delivery.assignmentID = event.assignmentResponse.id;
            order!.delivery.assignment = {
                type: LCECertificateOrderDeliveryAssignmentType.MANUAL,
                id: event.assignmentResponse.id,
                status: LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED,
                assignedOn: event.assignmentResponse.assignedOn,
                assignedBy: event.assignmentResponse.assignedBy,
                assignedTo: event.assignmentResponse.assignedTo,
                unassignedBy: event.assignmentResponse.unassignedBy,
                unassignedOn: event.assignmentResponse.unassignedOn
            };
            order.status = LCECertificateOrderStatus.READY;
            order.statusAudit = {ready: {on: new Date().toISOString(), by: LCE_MBO_MOCK_USER_STEVE_JOBS}};
            LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(order!.id, order!);
            this.addOrderMockData(order!);
        });

        this.mockCommonHandler.onDeliveryAssignment.subscribe(event => {
            if (XSUtils.isNull(event)) return;
            const order = this.findOneByID(event.assignmentResponse.certificateOrder.id);
            order!.delivery.assignmentID = event.assignmentResponse.id;
            order!.delivery.assignment = {
                type: LCECertificateOrderDeliveryAssignmentType.MANUAL,
                id: event.assignmentResponse.id,
                status: LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED,
                assignedOn: event.assignmentResponse.assignedOn,
                assignedBy: event.assignmentResponse.assignedBy,
                assignedTo: event.assignmentResponse.assignedTo
            };
            order.status = LCECertificateOrderStatus.DELIVERY_ASSIGNED;
            order.statusAudit = {deliveryAssigned: {on: new Date().toISOString(), by: LCE_MBO_MOCK_USER_STEVE_JOBS}};
            LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(order!.id, order!);
            this.addOrderMockData(order!);
        });

        if (this.paymentChargeHandler) {
            XSAssert.notNull(this.mockCertificateOrderCreateSessionHandler, 'mockCertificateOrderCreateSessionHandler');
            this.paymentChargeHandler.onCreate.subscribe(charge => {
                if (XSUtils.isNull(charge)) return;
                else {
                    let lastOrderCreateSession = this.mockCertificateOrderCreateSessionHandler.findLast();
                    if (!lastOrderCreateSession) return;
                    let order = this.buildCreateCertificateOrderSession(lastOrderCreateSession!, charge!);
                    order.charge = this.paymentChargeHandler.toPartial(charge);
                    LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(order.id, order);
                    this.mockDataArray.push(this.buildPaymentConfirmation(order));
                    this.mockCertificateOrderCreateSessionHandler.removeLastCertificateOrderCreateSessionFromLocalStorage();
                }
            });
        }
    }

    public getStorage(): Map<string, LCECertificateOrder> {
        return LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return LCEMockUtils.randomUserMunicipalEmployee();
    }

    public toLight(certificateOrder: LCECertificateOrder): LCECertificateOrderLight {
        return {
            id: certificateOrder.id,
            createdOn: certificateOrder.createdOn,
            orderNumber: certificateOrder.orderNumber,
            status: certificateOrder.status
        };
    }

    public toPartial(certificateOrder: LCECertificateOrder): LCECertificateOrderPartial {
        return {
            id: certificateOrder.id,
            createdOn: certificateOrder.createdOn,
            updatedOn: certificateOrder.updatedOn,
            orderNumber: certificateOrder.orderNumber,
            batchProcessCode: certificateOrder.batchProcessCode,
            status: certificateOrder.status,
            statusAudit: certificateOrder.statusAudit,
            statusUpdatedOn: certificateOrder.statusUpdatedOn,
            facility: certificateOrder.facility,
            certificate: certificateOrder.certificate,
            numberOfCopies: certificateOrder.numberOfCopies,
            customer: certificateOrder.customer,
            deliveryDestinationType: certificateOrder.delivery.destination.type
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,
            // Create ...
            {
                id: DATASET_BASE_ID + '.createMFO',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.createMFO,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: (rArg) => this.buildCreateCertificateOrderMFO(rArg.body)
            },
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Count
            this.buildCountMockData({random: true}),

            // Chart Line Stats
            {
                id: DATASET_BASE_ID + '.chartLine',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.chartLine,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: this.buildChartLineResponseData()
            },
            // Chart Doughnut
            {
                id: DATASET_BASE_ID + '.chartDoughnut',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.chartDoughnut,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: this.buildChartDoughnutResponseData()
            },
            // Count Stats
            {
                id: DATASET_BASE_ID + '.stats.count',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.countStats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: this.mockDataCertificateOrderStatisticsBuilder.buildStatsCount()
            },
            //  Stats Finance
            {
                id: DATASET_BASE_ID + '.stats.payment',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.statsFinances,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: this.mockDataCertificateOrderStatisticsBuilder.buildStatsPayment()
            },
            // Global Stats
            {
                id: DATASET_BASE_ID + '.stats.global',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.globalStats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: this.mockDataCertificateOrderStatisticsBuilder.buildStatsGlobal()
            },
            // Last
            {
                id: DATASET_BASE_ID + '.last',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.last,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 3000,
                getResponseData: () => this.getNLastResources(1).map((resource) => this.toLight(resource))
            },
            // Customer Orders
            {
                id: DATASET_BASE_ID + '.customerOrders',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 3000,
                getResponseData: (rArgs) => {
                    const customerIDs: string[] = XSHttpParamsUtils.getAsArray(rArgs.params!, 'customerIDs')!;
                    if (XSUtils.isEmpty(customerIDs)) {
                        return [];
                    }
                    return this.findByField('customer.id', customerIDs[0]).map((order) => this.toPartial(order));
                }
            },
            {
                id: DATASET_BASE_ID + '.updateSettings.',
                active: true,
                requestMethod: XSHttpMethod.PATCH,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.priceSettings,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 3000,
                getResponseData: rArg => {
                    let priceSettings = this.PRICE_SETTING_STORAGE;
                    const fieldValueMap: any = rArg.body as any;
                    fieldValueMap.forEach((value: any, key: string) => {
                        if (key === 'tripDefinitions') {
                            const tripDefinition = this.buildTripDefinition(value);
                            priceSettings.tripDefinitions.push(tripDefinition);
                        } else XSUtils.setNestedPropertyValue(priceSettings, key, value);
                    });
                    return {...priceSettings};
                }
            },
            {
                id: DATASET_BASE_ID + '.getSettings.',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrders.index + '/' + LCE_CORE_ENDPOINTS.certificateOrders.priceSettings,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 3000,
                getResponseData: rArg => {
                    return this.PRICE_SETTING_STORAGE;
                }
            }
        ];
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    public search(params: HttpParams | undefined): XSSearchResult<LCECertificateOrderPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    public generateRandomCertificateOrders(
        numberOfOrders?: number,
        certificateOrderStatus?: LCECertificateOrderStatus | LCECertificateOrderStatus[],
        batchProcessCode?: string
    ): LCECertificateOrderPartial[] {
        const generatedCertificateOrders: LCECertificateOrderPartial[] = [];
        const n: number = XSUtils.isNull(numberOfOrders) ? 20 : numberOfOrders!;
        for (let $i = 0; $i < n; $i++) {
            let orderStatus: LCECertificateOrderStatus = undefined!;
            if (XSUtils.isArray(certificateOrderStatus)) {
                orderStatus = XSUtils.randomElement(certificateOrderStatus as LCECertificateOrderStatus[]);
            } else {
                orderStatus = certificateOrderStatus as LCECertificateOrderStatus;
            }

            const certificateOrder = this.mockDataCertificateOrderBuilder.buildCertificateOrder(orderStatus, batchProcessCode);
            certificateOrder.updatedOn = new Date().toISOString();
            certificateOrder.updatedBy = certificateOrder.createdBy;
            LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(certificateOrder.id, certificateOrder);
            generatedCertificateOrders.push(this.toPartial(certificateOrder));
            this.addOrderMockData(certificateOrder);
        }

        const orderLights: LCECertificateOrderLight[] = generatedCertificateOrders.map((order) => {
            return {
                id: order.id,
                orderNumber: order.orderNumber,
                batchProcessCode: order.batchProcessCode,
                status: undefined!,
                createdOn: order.createdOn
            };
        });

        this.mockCertificateOrderStatusHandler.buildSetOrderStatusesMockData(orderLights, LCECertificateOrderStatus.PROCESSING);
        this.mockCertificateOrderStatusHandler.buildSetOrderStatusesMockData(orderLights, LCECertificateOrderStatus.READY);
        this.mockCertificateOrderStatusHandler.buildSetOrderStatusesMockData(orderLights, LCECertificateOrderStatus.CANCELLED);
        this.mockCertificateOrderStatusHandler.buildSetOrderStatusesMockData(orderLights, LCECertificateOrderStatus.ERROR);

        return generatedCertificateOrders;
    }

    private buildPaymentConfirmation(order: LCECertificateOrder): XSMockData<LCECertificateOrderConfirmation> {
        let requestURL = `${LCE_CORE_ENDPOINTS.certificateOrders.index}/${LCE_CORE_ENDPOINTS.certificateOrders.paymentsConfirmation.replace('{transactionID}', order.charge!.transactionID)}`;

        return {
            id: DATASET_BASE_ID + '.paymentConfirmation',
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            responseData: this.buildPaymentConfirmationResponseData(order)
        };
    }

    private buildPaymentConfirmationResponseData(order: LCECertificateOrder): LCECertificateOrderConfirmation {
        return {
            orderID: order.id,
            orderCreatedOn: order.createdOn,
            orderNumber: order.orderNumber,
            certificateType: order.certificate.type,
            facilityType: order.facility.type,
            facilityCode: order.facility.code,
            facilityFullName: order.facility.fullName,
            status: order.status,
            qrCode: order.qrCode,
            verificationCode: XSUtils.randomDigits(6),
            chargeType: order.charge!.type,
            chargeTransactionID: order.charge?.transactionID,
            chargeTransactionDate: order.charge?.transactionDate,
            chargeChargedAmount: order.charge?.chargedAmount,
            chargeStatus: order.charge?.status,
            creditCardType: order.charge?.cardType,
            numberOfCopies: order.numberOfCopies,
            deliveryDestination: order.delivery.destination,
            customerCode: order.customer?.code,
            customerName: order.customer?.name,
            customerPrimaryPhoneNumber: order.customer?.primaryPhoneNumber,
            customerEmail: order.customer?.email,
            procurationRepresentativeFullName: order.procuration?.representativeFullName,
            procurationRepresentativePhoneNumber: order.procuration?.representativePhoneNumber,
            procurationRepresentativeEmail: order.procuration?.representativeEmail,
            test: order.test
        };
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Customers ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    private addCustomerMockData(customerID: string): void {
        this.mockDataArray.push(this.buildFindMockData({matchRequestSearchFilter: {customerIDs: [customerID]}}));
    }

    private buildCreateCertificateOrderSession(orderCreateSession: LCECertificateOrderCreateSession, charge: XSPaymentCharge): LCECertificateOrder {
        let order = this.createResponseData(orderCreateSession.orderCreate, this.paymentChargeHandler.toPartial(charge));
        this.addOrderMockData(order);
        this.createSubject.next(order);
        return order;
    }

    private buildCreateCertificateOrderMFO(certificateOrderCreate: LCECertificateOrderCreate): LCECertificateOrder {
        const order = this.createResponseData(certificateOrderCreate);
        LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(order.id, order);
        this.addOrderMockData(order);
        this.createSubject.next(order);

        return order;
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Charts ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    private buildChartLineResponseData(): LCECertificateOrderStatsChartLine {
        return {
            xAxis: Object.keys(XS_MONTH_NAME_MAP[XSLanguage.FRENCH]) as XSMonthKey[],
            birthYAxisData: this.randomArrayOfNumbers(12, 1000),
            marriageYAxisData: this.randomArrayOfNumbers(12, 500),
            deathYAxisData: this.randomArrayOfNumbers(12, 250)
        };
    }

    private buildChartDoughnutResponseData(): LCECertificateOrderStatsCount {
        return this.mockDataCertificateOrderStatisticsBuilder.buildStatsCount();
    }

    // ---------------------------------------------------------------------------------------------------------------------------------
    // === Storage ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    private buildStorage() {
        const defaultCertificateOrder = this.mockDataCertificateOrderBuilder.buildCertificateOrder();
        defaultCertificateOrder.id = LCEMockCertificateOrderHandler.DEFAULT_ID;
        LCEMockCertificateOrderHandler.CERTIFICATE_ORDER_STORAGE.set(defaultCertificateOrder.id, defaultCertificateOrder);
        this.addOrderMockData(defaultCertificateOrder);
        this.generateRandomCertificateOrders();
    }

    private addOrderMockData(order: LCECertificateOrder): void {
        super.addResourceBaseMockData(order.id);
        this.addCustomerMockData(order.id);
        this.mockDataArray.push(this.mockCertificateOrderStatusHandler.buildSetOrderStatusMockData(order, LCECertificateOrderStatus.PROCESSING));
        this.mockDataArray.push(this.mockCertificateOrderStatusHandler.buildSetOrderStatusMockData(order, LCECertificateOrderStatus.READY));
        this.mockDataArray.push(this.mockCertificateOrderStatusHandler.buildSetOrderStatusMockData(order, LCECertificateOrderStatus.CANCELLED));
        this.mockDataArray.push(this.mockCertificateOrderStatusHandler.buildSetOrderStatusMockData(order, LCECertificateOrderStatus.ERROR));
    }


    // ---------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ---------------------------------------------------------------------------------------------------------------------------------

    private randomArrayOfNumbers(length: number, max: number): number[] {
        return Array(length)
            .fill(0)
            .map(() => Math.round(Math.random() * max));
    }


    private createResponseData(certificateOrderCreate: LCECertificateOrderCreate, charge?: XSPaymentChargePartial): LCECertificateOrder {
        return this.mockDataCertificateOrderBuilder.buildCertificateOrder(undefined, undefined, certificateOrderCreate, charge);
    }

    private buildPriceSettings(arg?: { currency?: XSCurrency }): LCECertificateOrderPriceSettings {
        const currency: XSCurrency = !XSUtils.isEmpty(arg?.currency) ? arg!.currency! : XSCurrency.XOF;
        return {
            id: XSUtils.uuid(),
            createdBy: LCEMockUtils.randomUserCustomer(),
            createdOn: new Date().toISOString(),
            state: XSPKDTOAuditFullState.ACTIVE,
            orderUnitPrice: {value: 1500, currency: currency},
            partnerUnitPrice: {value: 1000, currency: currency},
            taxAmount: {value: 500, currency: currency},
            dhlUnitPrice: {value: 2500, currency: currency},
            upsUnitPrice: {value: 2500, currency: currency},
            servicePointDeliveryFees: {value: 100, currency: currency},
            feesAmount: {value: 300, currency: currency},
            message: XSUtils.randomElement([XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.short]),
            tripDefinitions: this.buildTripDefinitions()
        };
    }

    private buildTripDefinitions(): LCECertificateOrderPriceTripDefinition[] {

        const commonTrip = {
            id: XSUtils.uuid(),
            note: XS_LOREM_IPSUM.short,
            active: XSMockUtils.randomBoolean(),
            description: XS_LOREM_IPSUM.short
        };

        const trip1KM: LCECertificateOrderPriceTripDefinition = this.buildTripDefinition({title: 'Course de 1km à 5km', minKM: 0.1, maxKM: 5, price: {value: 1500, currency: XSCurrency.XOF}});
        const trip2KM: LCECertificateOrderPriceTripDefinition = this.buildTripDefinition({title: 'Course de 5km à 10km', minKM: 5.1, maxKM: 10, price: {value: 3000, currency: XSCurrency.XOF}});
        const trip3KM: LCECertificateOrderPriceTripDefinition = this.buildTripDefinition({title: 'Course de 10km à 20km', minKM: 10.1, maxKM: 20, price: {value: 4500, currency: XSCurrency.XOF}});

        return [trip1KM, trip2KM, trip3KM];
    }

    private buildTripDefinition(arg: { title: string, minKM: number, maxKM: number, price: XSAmount }): LCECertificateOrderPriceTripDefinition {
        XSAssert.notEmpty(arg, 'arg');
        const commonTrip = {
            id: XSUtils.uuid(),
            note: XS_LOREM_IPSUM.short,
            active: XSMockUtils.randomBoolean(),
            description: XS_LOREM_IPSUM.short
        };
        return {
            ...commonTrip,
            ...arg
        };
    }

}


