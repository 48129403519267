<div class="xs-data-field lce-data-field-municipality {{ styleClass }}">
    <xs-data-field-label
            *ngIf="(label | xsIsNotEmpty) || (subLabel | xsIsNotEmpty)"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [labelStyleClass]="labelStyleClass"
            [label]="label"
            [styleClass]="labelsStyleClass"
            [subLabelStyleClass]="subLabelStyleClass"
            [subLabel]="subLabel">
    </xs-data-field-label>

    <div [ngClass]="{ 'xs-disabled': disabled === true, 'xs-overflow-hidden': ellipsis }"
         class="xs-data-field-value-container {{ valueContainerStyleClass }}">
        <lce-municipality-partial
                *ngIf="value | xsIsNotEmpty"
                [data]="value!"
                [label]="partialLabel"
                [showCode]="showCode"
                [showDefaultLabel]="partialShowDefaultLabel"
                [showIcon]="showIcon"
                [showOverlay]="showOverlay"
                [viewRecord]="viewRecord"
                labelStyleClass="xs-font-weight-normal-imp">
        </lce-municipality-partial>

        <span *ngIf="value | xsIsEmpty" [xsTooltip]="valueTooltip" class="xs-data-field-empty-value {{ valueStyleClass }}" pTooltip>{{ emptyValue! | translate }}</span>
    </div>

    <xs-data-field-below
            (textClickEvent)="belowTextClickEvent.emit()"
            *ngIf="belowText || belowButton"
            [button]="belowButton"
            [clickableText]="clickableBelowText"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [styleClass]="belowContainerStyleClass"
            [textStyleClass]="belowTextStyleClass"
            [text]="belowText">
    </xs-data-field-below>
</div>
