import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCEDistrict, LCEDistrictCreate, LCEDistrictService} from '@lce/core';
import {LOG, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService, XSValidators} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-district-create-update', templateUrl: './lce-district-create-update.component.html'})
export class LCEDistrictCreateUpdateComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.district.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateDistrictCentralLoader';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() districtID?: string;
    @Input() district?: LCEDistrict;

    @Output() saveEvent = new EventEmitter<LCEDistrict>();
    @Output() closeEvent = new EventEmitter<LCEDistrict>();

    formGroup: FormGroup = new FormGroup({});
    nameField: XSInputFieldTextOptions;
    abbreviationField: XSInputFieldTextOptions;
    fullNameField: XSInputFieldTextOptions;
    shortNameField: XSInputFieldTextOptions;
    numberOfMunicipalitiesField: XSInputFieldTextOptions;
    capitalField: XSInputFieldTextOptions;
    populationSizeField: XSInputFieldTextOptions;
    populationUpdateYearField: XSInputFieldTextOptions;
    squareKMField: XSInputFieldTextOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveDistrict()
    };
    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private districtService: LCEDistrictService) {
        super();
    }

    get headerTitle(): string {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.district?.fullName;
        if (XSUtils.isEmpty(title)) title = this.TR_BASE_LABEL + 'district';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.district?.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.districtID = this.dialogConfig.data.districtID;
            this.district = this.dialogConfig.data.district;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.contentStyleClass = this.dialogConfig.data.contentStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.district));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.district)) {
            if (!XSUtils.isEmpty(this.district)) this.districtID = this.district!.id;
            this.initialize();
        } else this.retrieveDistrict();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.districtID) || !XSUtils.isEmpty(this.district);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.districtID) && XSUtils.isEmpty(this.district);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty;
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public fillForm(): void {
        this.nameField.control?.setValue('Pennsa');
        this.abbreviationField.control?.setValue('PEN');
        this.fullNameField.control?.setValue('District Autonome de Pennsa');
        this.shortNameField.control?.setValue('D. de Pennsa');
        this.capitalField.control?.setValue('Trivantes');
        this.numberOfMunicipalitiesField.control?.setValue(5);
        this.populationSizeField.control?.setValue('1422072');
        this.populationUpdateYearField.control?.setValue('2018');
        this.squareKMField.control?.setValue('2075');
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private retrieveDistrict(): void {
        this.retrieveError = undefined;
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.subscription.add(
            this.districtService
                .retrieve(this.districtID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: retrievedDistrict => {
                        this.district = retrievedDistrict;
                        this.initialize();
                    },
                    error: error => (this.retrieveError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating District [districtID: ' + this.districtID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildDistrictUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('District not updated. No change detected ! [code: ' + this.district!.code + ', id: ' + this.districtID + ']');
            this.saveEvent.emit(undefined);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.districtService
                .update(this.districtID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: districtRecord => {
                        const updatedDistrictID = districtRecord.id;
                        LOG().debug('District successfully updated :-) [code: ' + districtRecord.code + ', id: ' + updatedDistrictID + ']');
                        this.district = districtRecord;
                        this.saveEvent.emit(this.district);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating District ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const districtCreate = this.buildDistrictCreate(formData);

        this.subscription.add(
            this.districtService
                .create(districtCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: district => {
                        LOG().debug('District successfully saved :-) [code: ' + district.code + ', id: ' + district.id + ']');
                        this.district = district;
                        this.saveEvent.emit(district);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private buildDistrictUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (!XSUtils.equal(formData.abbreviation, this.district?.abbreviation)) fieldValueMap.set('abbreviation', XSUtils.trim(formData.abbreviation));
        if (!XSUtils.equal(formData.name, this.district?.name)) fieldValueMap.set('name', XSUtils.trim(formData.name));
        if (!XSUtils.equal(formData.fullName, this.district?.fullName)) fieldValueMap.set('fullName', XSUtils.trim(formData.fullName));
        if (!XSUtils.equal(formData.shortName, this.district?.shortName)) fieldValueMap.set('shortName', XSUtils.trim(formData.shortName));
        if (!XSUtils.equal(formData.numberOfMunicipalities, this.district?.numberOfMunicipalities)) fieldValueMap.set('numberOfMunicipalities', XSUtils.parseInt(formData.numberOfMunicipalities));
        if (!XSUtils.equal(formData.capital, this.district?.capital)) fieldValueMap.set('capital', XSUtils.trim(formData.capital));

        if (!XSUtils.equal(formData.populationSize, this.district?.populationSize)) {
            fieldValueMap.set('populationSize', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationSize)));
        }
        if (!XSUtils.equal(formData.populationUpdateYear, this.district?.populationUpdateYear)) {
            fieldValueMap.set('populationUpdateYear', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationUpdateYear)));
        }
        if (!XSUtils.equal(formData.squareKM, this.district?.squareKM)) {
            fieldValueMap.set('squareKM', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.squareKM)));
        }
        return fieldValueMap;
    }

    private buildDistrictCreate(formData: any): LCEDistrictCreate {
        let districtCreate: LCEDistrictCreate = {
            abbreviation: XSUtils.trim(formData.abbreviation),
            name: XSUtils.trim(formData.name),
            fullName: XSUtils.trim(formData.fullName),
            shortName: XSUtils.trim(formData.shortName),
            numberOfMunicipalities: XSUtils.parseInt(formData.numberOfMunicipalities),
            capital: XSUtils.trim(formData.capital)
        };

        if (!XSUtils.isEmpty(formData.populationSize)) districtCreate.populationSize = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationSize));
        if (!XSUtils.isEmpty(formData.populationUpdateYear)) districtCreate.populationUpdateYear = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationUpdateYear));
        if (!XSUtils.isEmpty(formData.squareKM)) districtCreate.squareKM = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.squareKM));

        XSUtils.removeNullAndUndefinedEntries(districtCreate);

        return districtCreate;
    }

    private isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.nameField.control?.value) ||
            !XSUtils.isEmpty(this.abbreviationField.control?.value) ||
            !XSUtils.isEmpty(this.fullNameField.control?.value) ||
            !XSUtils.isEmpty(this.shortNameField.control?.value) ||
            !XSUtils.isEmpty(this.numberOfMunicipalitiesField.control?.value) ||
            !XSUtils.isEmpty(this.capitalField.control?.value) ||
            !XSUtils.isEmpty(this.populationSizeField.control?.value) ||
            !XSUtils.isEmpty(this.populationUpdateYearField.control?.value) ||
            !XSUtils.isEmpty(this.squareKMField.control?.value));
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.reset()
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.closeDialog()
        };
    }

    private buildFields(): void {
        this.nameField = {
            fieldName: 'name',
            control: new FormControl(this.district?.name, Validators.required),
            label: this.TR_BASE_LABEL + 'name'
        };
        this.abbreviationField = {
            fieldName: 'abbreviation',
            control: new FormControl(this.district?.abbreviation, [Validators.required, XSValidators.equalLength(3)]),
            inputStyleClass: 'xs-text-transform-uppercase',
            label: this.TR_BASE_LABEL + 'abbreviation',
            errorOptions: {
                translationKeys: {
                    equalLength: this.TR_BASE + 'validationError.equalLength'
                }
            }
        };
        this.fullNameField = {
            fieldName: 'fullName',
            control: new FormControl(this.district?.fullName, Validators.required),
            label: this.TR_BASE_LABEL + 'fullName'
        };
        this.shortNameField = {
            fieldName: 'shortName',
            control: new FormControl(this.district?.shortName),
            label: this.TR_BASE_LABEL + 'shortName'
        };
        this.capitalField = {
            fieldName: 'capital',
            control: new FormControl(this.district?.capital),
            label: this.TR_BASE_LABEL + 'capital'
        };
        this.numberOfMunicipalitiesField = {
            fieldName: 'numberOfMunicipalities',
            type: 'number',
            maxLength: 3,
            control: new FormControl(this.district?.numberOfMunicipalities, Validators.required),
            label: this.TR_BASE_LABEL + 'numberOfMunicipalities'
        };
        this.populationSizeField = {
            fieldName: 'populationSize',
            type: 'number',
            maxLength: 10,
            control: new FormControl(this.district?.populationSize),
            label: this.TR_BASE_LABEL + 'populationSize',
            placeholder: this.TR_BASE_LABEL + 'populationSizePlaceholder'
        };
        this.populationUpdateYearField = {
            fieldName: 'populationUpdateYear',
            label: this.TR_BASE_LABEL + 'populationUpdateYear',
            type: 'number',
            maxLength: 4,
            control: new FormControl(this.district?.populationUpdateYear, [Validators.min(1980), Validators.max(new Date().getFullYear())]),
            placeholder: this.TR_BASE_LABEL + 'populationUpdateYearPlaceholder'
        };
        this.squareKMField = {
            fieldName: 'squareKM',
            type: 'number',
            maxLength: 10,
            control: new FormControl(this.district?.squareKM),
            label: this.TR_BASE_LABEL + 'squareKM',
            groupAddon: true,
            rightAddonValue: 'km²',
            placeholder: this.TR_BASE_LABEL + 'squareKMPlaceholder'
        };

        this.formGroup.addControl(this.nameField.fieldName, this.nameField.control!);
        this.formGroup.addControl(this.abbreviationField.fieldName, this.abbreviationField.control!);
        this.formGroup.addControl(this.fullNameField.fieldName, this.fullNameField.control!);
        this.formGroup.addControl(this.shortNameField.fieldName, this.shortNameField.control!);
        this.formGroup.addControl(this.capitalField.fieldName, this.capitalField.control!);
        this.formGroup.addControl(this.numberOfMunicipalitiesField.fieldName, this.numberOfMunicipalitiesField.control!);
        this.formGroup.addControl(this.populationSizeField.fieldName, this.populationSizeField.control!);
        this.formGroup.addControl(this.populationUpdateYearField.fieldName, this.populationUpdateYearField.control!);
        this.formGroup.addControl(this.squareKMField.fieldName, this.squareKMField.control!);
    }
}
