<div [ngClass]="{ 'xs-disabled': disabled === true }" class="grid grid-nogutter xs-width-full {{ styleClass }}">
    <div class="col-6 xs-flex-row xs-align-items-center">
        <xs-ivar
                (iconClick)="clickEvent.emit(data)"
                (line1Click)="clickEvent.emit(data)"
                [disabled]="disabled"
                [ellipsis]="true"
                [iconClickable]="clickable"
                [icon]="ICON.birthDeclaration"
                [line1Clickable]="clickable"
                [showIcon]="showIcon"
                iconStyleClass="{{ iconStyleClass }} xs-font-size-28-imp"
                line1="No {{ data.birthNumber }}"
                line1StyleClass="{{ intermediate ? 'xs-font-size-intermediate-imp' : '' }} {{ birthNumberStyleClass }}"
                line2="{{ TR_BASE_MAIN + 'mother' | translate }}  {{ data.motherName | xsPersonName }}"
                line2StyleClass="xs-font-size-small-imp"
                styleClass="xs-pr-10">
        </xs-ivar>
    </div>
    <div class="col-6 xs-flex-row xs-align-items-center">
        <xs-ivar
                [disabled]="disabled"
                [ellipsis]="true"
                [line1]="data.code"
                line1StyleClass="{{ intermediate ? 'xs-font-size-intermediate-imp' : '' }}"
                line2="{{ TR_BASE_MAIN + 'child' | translate }}  {{ (data.newBornName | xsIsNotEmpty) ? (data.newBornName | xsPersonName) : '***' }}"
                line2StyleClass="xs-font-size-small-imp"
                styleClass="xs-sm-display-none">
        </xs-ivar>
    </div>
</div>
