import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCE_FACILITY_PARTIAL_YOPOUGON,
    LCECoreContextService,
    LCEFacilityTownHallStampToken,
    LCEFacilityTownHallStampTokenCreate,
    LCEFacilityTownHallStampTokenDetail,
    LCEFacilityTownHallStampTokenPartial,
    LCEFacilityTownHallStampTokenSearch,
    LCEFacilityTownHallStampTokenStatus,
    LCEUserPartial
} from '@lce/core';
import {LCEMockCertificateOrderHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-handler';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '@lce/mock/core/lce-mock-user-data';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, QR_CODE_DATA} from '@lce/mock/core/lce-mock.constant';
import {XS_LOREM_IPSUM, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSPhoneNumberUtils} from '@xs/common';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate, XSMockUtils} from '@xs/mock';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.facilityStampToken';

@Injectable()
export class LCEMockFacilityTownHallStampTokenHandler extends XSMockPKResourceAuditFullHandler<LCEFacilityTownHallStampToken, LCEFacilityTownHallStampTokenPartial, LCEFacilityTownHallStampTokenSearch>

    implements XSMockPKSearchable<LCEFacilityTownHallStampTokenPartial> {

    public static FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE: Map<string, LCEFacilityTownHallStampToken> = new Map<string, LCEFacilityTownHallStampToken>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEFacilityTownHallStampToken>[] = [
        (facilityTownHallStampToken, query) => facilityTownHallStampToken.code.toLowerCase().includes(query),
        (facilityTownHallStampToken, query) => facilityTownHallStampToken.facility.code.toLowerCase().includes(query),
        (facilityTownHallStampToken, query) => facilityTownHallStampToken.facility.fullName?.toLowerCase().includes(query)
    ];
    private searchPredicates: XSMockSearchPredicate<LCEFacilityTownHallStampToken>[] = [
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'numberOfStamps', String(facilityTownHallStampToken.numberOfStamps)),
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'statuses', facilityTownHallStampToken.status),
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'codes', facilityTownHallStampToken.code),
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'facilityCodes', facilityTownHallStampToken.facility.code),
        (facilityTownHallStampToken, params) => this.httpParamArrayIncludes(params, 'municipalityCodes', facilityTownHallStampToken.consumedBy?.municipalityCode)
    ];

    constructor(
        private contextService: LCECoreContextService,
        private mockCertificateOrderService: LCEMockCertificateOrderHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.tokens.index);
        this.mockDataArray = [];
    }

    getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    getStorage(): Map<string, LCEFacilityTownHallStampToken> {
        return LCEMockFacilityTownHallStampTokenHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE;
    }

    toPartial(facilityTownHallStampToken: LCEFacilityTownHallStampToken): LCEFacilityTownHallStampTokenPartial {
        return {
            id: facilityTownHallStampToken.id,
            createdOn: facilityTownHallStampToken.createdOn,
            code: facilityTownHallStampToken.code,
            status: facilityTownHallStampToken.status,
            facility: facilityTownHallStampToken.facility,
            numberOfStamps: facilityTownHallStampToken.numberOfStamps,
            numberOfStampsLeft: facilityTownHallStampToken.numberOfStampsLeft,
            mobilePhoneNumber: facilityTownHallStampToken.mobilePhoneNumber,
            validUntil: facilityTownHallStampToken.validUntil!,
            consumedOn: facilityTownHallStampToken.consumedOn,
            test: facilityTownHallStampToken.test
        };
    }

    public toDetail(facilityTownHallStampToken: LCEFacilityTownHallStampToken): LCEFacilityTownHallStampTokenDetail {
        return {
            id: facilityTownHallStampToken.id,
            code: facilityTownHallStampToken.code,
            createdOn: facilityTownHallStampToken.createdOn,
            createdBy: facilityTownHallStampToken.createdBy,
            facility: facilityTownHallStampToken.facility,
            numberOfStamps: facilityTownHallStampToken.numberOfStamps,
            mobilePhoneNumber: facilityTownHallStampToken.mobilePhoneNumber,
            status: facilityTownHallStampToken.status,
            consumedOn: facilityTownHallStampToken.consumedOn,
            qrCode: facilityTownHallStampToken.qrCode,
            test: facilityTownHallStampToken.test
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.facilities.townHalls.stamps.tokens.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body, 3000)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 3,
                    active: 3,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEFacilityTownHallStampTokenPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private createResponseData(stampTokenCreate: LCEFacilityTownHallStampTokenCreate, errorDelayMS: number): LCEFacilityTownHallStampTokenDetail {
        if (stampTokenCreate.numberOfStamps === 11) {
            XSMockUtils.throwHttpErrorResponse({
                status: HttpStatusCode.InternalServerError,
                message: 'Unable to create stamp token (fake error).',
                exceptionName: 'LCEFacilityTownHallStampTokenCreateException'
            });
        }

        let mobilePhoneNumber: string | undefined = undefined;
        if (!XSUtils.isEmpty(stampTokenCreate.mobilePhoneNumber)) mobilePhoneNumber = '+225' + XSPhoneNumberUtils.cleanPhoneNumber(stampTokenCreate.mobilePhoneNumber!);

        const facilityTownHallStampToken: LCEFacilityTownHallStampToken = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-STO-YOP-' + XSUtils.randomDigits(2) + '-' + XSUtils.randomDigits(4) + '-' + XSUtils.randomDigits(2),
            facility: {id: LCE_FACILITY_PARTIAL_YOPOUGON.id, code: LCE_FACILITY_PARTIAL_YOPOUGON.code, fullName: LCE_FACILITY_PARTIAL_YOPOUGON.fullName},
            numberOfStamps: stampTokenCreate.numberOfStamps,
            numberOfStampsLeft: 0,
            mobilePhoneNumber: mobilePhoneNumber,
            qrCode: QR_CODE_DATA,
            status: LCEFacilityTownHallStampTokenStatus.UNCONSUMED,
            validUntil: new Date().toDateString(),
            consumedOn: undefined,
            consumedBy: undefined
        };

        LCEMockFacilityTownHallStampTokenHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE.set(facilityTownHallStampToken.id, facilityTownHallStampToken);
        this.addResourceBaseMockData(facilityTownHallStampToken.id);

        return this.toDetail(facilityTownHallStampToken);
    }

    private handleStatus(stampToken: LCEFacilityTownHallStampToken): LCEFacilityTownHallStampTokenStatus {
        if (!XSUtils.isEmpty(stampToken.consumptions)) {
            let stampsConsumed = 0;
            stampToken.consumptions!.forEach(el => stampsConsumed += el.consumedNumberOfStamps);
            return stampToken.numberOfStamps === stampsConsumed ? LCEFacilityTownHallStampTokenStatus.CONSUMED : LCEFacilityTownHallStampTokenStatus.PARTIALLY_CONSUMED;
        }
        return LCEFacilityTownHallStampTokenStatus.UNCONSUMED;
    }

    private buildStorage(): void {
        let certificateOrder = this.mockCertificateOrderService.findOneRandomly();

        let consumptions = [
            {
                certificateOrder: {id: certificateOrder.id, orderNumber: certificateOrder.orderNumber},
                consumedNumberOfStamps: 2,
                on: new Date().toDateString(),
                by: LCEMockUtils.randomUserMunicipalEmployee()
            }];

        for (let $i = 0; $i <= 11; $i++) {
            const facilityStampToken: LCEFacilityTownHallStampToken = this.buildRandomStampToken();

            if ($i === 2 || $i === 3 || $i === 4) {
                facilityStampToken.consumptions = consumptions;
                facilityStampToken.numberOfStamps = 2;
                facilityStampToken.numberOfStampsLeft = 1;
                facilityStampToken.status = this.handleStatus(facilityStampToken);
            } else {
                facilityStampToken.status = this.handleStatus(facilityStampToken);
            }
            facilityStampToken.consumedOn = facilityStampToken.status === LCEFacilityTownHallStampTokenStatus.UNCONSUMED ? undefined : new Date().toDateString();

            if (XSUtils.randomElement<boolean>([true, true, false])) {
                facilityStampToken.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                facilityStampToken.updatedOn = new Date().toISOString();
            }

            LCEMockFacilityTownHallStampTokenHandler.FACILITY_TOWN_HALL_STAMP_TOKEN_STORAGE.set(facilityStampToken.id, facilityStampToken);
            this.addResourceBaseMockData(facilityStampToken.id);
        }
    }

    private buildRandomStampToken(): LCEFacilityTownHallStampToken {
        return {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-STO-YOP-' + XSUtils.randomDigits(2) + '-' + XSUtils.randomDigits(4) + '-' + XSUtils.randomDigits(2),
            facility: {id: LCE_FACILITY_PARTIAL_YOPOUGON.id, code: LCE_FACILITY_PARTIAL_YOPOUGON.code, fullName: LCE_FACILITY_PARTIAL_YOPOUGON.fullName},
            numberOfStamps: XSUtils.randomInteger(1, 4),
            qrCode: QR_CODE_DATA,
            mobilePhoneNumber: XSMockUtils.randomize(XSMockUtils.randomPhoneNumberCI(), true),
            status: XSUtils.randomEnum(LCEFacilityTownHallStampTokenStatus),
            validUntil: XSUtils.randomElement<any>([new Date().toDateString(), undefined]),
            note: XSUtils.randomElement<string>([XS_LOREM_IPSUM.short, XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.long]),
            consumedBy: LCEMockUtils.randomUserMunicipalEmployee()
        };
    }
}
