import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEFacility, LCEFacilityCreate, LCEFacilityPartial, LCEFacilitySearch, LCEFacilityType, LCEUserPartial} from '@lce/core';
import {XSAddressType, XSAddressUnstructured, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import {BehaviorSubject} from 'rxjs';
import FACILITIES_JSON from '../../data/lce-facilities.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../../lce-mock-user-data';
import {LCEMockUtils} from '../../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_FACILITY_CODE_COCODY_TOWN_HALL, LCE_MOCK_FACILITY_CODE_YOPOUGON_HOSPITAL, LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL} from '../../lce-mock.constant';
import {LCEMockMunicipalityHandler} from '../lce-mock-municipality-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.facilities';

@Injectable()
export class LCEMockFacilityHandler extends XSMockPKResourceAuditFullHandler<LCEFacility, LCEFacilityPartial, LCEFacilitySearch>
    implements XSMockPKSearchable<LCEFacilityPartial> {

    public static FACILITY_STORAGE: Map<string, LCEFacility> = new Map<string, LCEFacility>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEFacility>[] = [
        (facility, query) => facility.fullName.toLowerCase().includes(query),
        (facility, query) => facility.name.toLowerCase().includes(query),
        (facility, query) => facility.subName?.toLowerCase().includes(query),
        (facility, query) => facility.municipality.name.toLowerCase().includes(query),
        (facility, query) => facility.municipality.code.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEFacility>[] = [
        (facility, params) => this.httpParamArrayIncludes(params, 'codes', facility.code),
        (facility, params) => this.httpParamArrayIncludes(params, 'types', facility.type),
        (facility, params) => this.httpParamArrayIncludes(params, 'municipalityCodes', facility.municipality.code),
        (facility, params) => this.httpParamArrayIncludes(params, 'districtCodes', facility.municipality.district.code)
    ];

    private createSubject = new BehaviorSubject<LCEFacility>(undefined!);
    onCreate = this.createSubject.asObservable();

    constructor(
        private contextService: LCECoreContextService,
        private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.facilities.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEFacility> {
        return LCEMockFacilityHandler.FACILITY_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(facility: LCEFacility): LCEFacilityPartial {
        return {
            id: facility.id,
            name: facility.name,
            code: facility.code,
            type: facility.type,
            fullName: facility.fullName,
            subName: facility.subName,
            municipality: facility.municipality
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.AUDIT_FULL_ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Find
            this.buildFindMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 3,
                    active: 3,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];

    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEFacilityPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    public getYopougonHospital(): LCEFacilityPartial {
        return this.findOneByCodeAsPartial(LCE_MOCK_FACILITY_CODE_YOPOUGON_HOSPITAL);
    }

    public getCocodyTownHall(): LCEFacilityPartial {
        return this.findOneByCodeAsPartial(LCE_MOCK_FACILITY_CODE_COCODY_TOWN_HALL);
    }

    public getYopougonTownHall(): LCEFacilityPartial {
        return this.findOneByCodeAsPartial(LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL);
    }

    protected addResourceBaseMockData(facilityID: string) {
        super.addResourceBaseMockData(facilityID);
    }

    private createResponseData(body: any): LCEFacility {
        const facilityCreate: LCEFacilityCreate = body as LCEFacilityCreate;
        const mainImage = 'assets/mock/images/facilities/ci-create-facility.jpeg';
        const facility: LCEFacility = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-FAC-' + XSUtils.randomDigits(3),
            name: facilityCreate.name,
            fullName: facilityCreate.fullName,
            subName: facilityCreate.subName,
            type: facilityCreate.type,
            address: facilityCreate.address,
            municipality: this.mockMunicipalityHandler.findOneByCodeAsPartial(facilityCreate.municipalityCode),
            contactPhoneNumber: facilityCreate.contactPhoneNumber,
            contactEmail: facilityCreate.contactEmail,
            primaryContactPerson: facilityCreate.primaryContactPerson,
            secondaryContactPerson: facilityCreate.secondaryContactPerson,
            openingHours: undefined,
            mainImage: facilityCreate.gmapLocation ? facilityCreate.mainImage : mainImage
        };

        LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
        this.addResourceBaseMockData(facility.id);
        this.mockDataArray.push(this.buildFindByCodePartialMockData(facility.code));
        this.createSubject.next(facility);
        return facility;
    }

    private buildStorage(): void {
        FACILITIES_JSON.forEach((jsonFacility) => {
            const facility: LCEFacility = {
                ...jsonFacility,
                type: jsonFacility.type as LCEFacilityType,
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                municipality: this.mockMunicipalityHandler.findOneByCodeAsPartial(jsonFacility.municipality),
                address: {
                    type: XSAddressType.UNSTRUCTURED,
                    title: 'Patisserie Chez Paul',
                    line1: 'Rue Des Jardins, à 100 m du Restaurant l\'Automatic',
                    line2: 'Deux-Plateaux, Vallon',
                    city: 'abidjan',
                    countryISO: 'ci'
                } as XSAddressUnstructured
            };

            if (XSUtils.randomElement<boolean>([true, true, false])) {
                facility.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                facility.updatedOn = new Date().toISOString();
            }

            LCEMockFacilityHandler.FACILITY_STORAGE.set(facility.id, facility);
            this.addResourceBaseMockData(facility.id);
            this.mockDataArray.push(this.buildFindByCodePartialMockData(facility.code));
        });
    }
}
