import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
    LCEUser,
    LCEUserCustomer,
    LCEUserDeliveryManService,
    LCEUserEmployeeService,
    LCEUserMunicipalEmployee,
    LCEUserMunicipalEmployeeService,
    LCEUserPartial,
    LCEUserSearch,
    LCEUserService,
    LCEUserType,
    LCEUserUtils
} from '@lce/core';
import {XSContactPerson, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSCoreDomUtils, XSDialogable} from '@xs/core';
import {TabView} from 'primeng/tabview';
import {Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEUserAccountOptions} from './lce-user-account-options';

@Component({selector: 'lce-user-account', templateUrl: 'lce-user-account.component.html'})
export class LCEUserAccountComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.user.';
    readonly TR_BASE_LABEL: string = 'lce.shared.user.label.';
    readonly TR_BASE_CORE: string = 'xs.core.label.';

    readonly LOADER_ID: string = 'userLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() userID: string;

    @Input() userType: LCEUserType;

    @Input() adminView: boolean; // readonly
    @Output() editEvent = new EventEmitter<LCEUser>();
    @Output() closeEvent = new EventEmitter<LCEUser>();


    user: LCEUser;
    options: LCEUserAccountOptions;

    navScrollable: boolean = false;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        icon: this.ICON.refresh,
        label: this.TR_BASE_CORE + 'pleaseTryAgain',
        onClick: () => this.retrieveUser()
    };

    activeIndex: number;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    private subscription: Subscription = new Subscription();
    private tabViewResizeObserver: ResizeObserver;
    private tabView: TabView;

    constructor(
        private loaderService: XSLoaderService,
        private userEmployeeService: LCEUserEmployeeService,
        private userMunicipalEmployeeService: LCEUserMunicipalEmployeeService,
        private userDeliveryManService: LCEUserDeliveryManService,
        private activatedRoute: ActivatedRoute) {
        super();
    }

    get userService(): LCEUserService<LCEUser, LCEUserPartial, LCEUserSearch> {
        switch (this.userType) {
            case LCEUserType.EMPLOYEE:
                return this.userEmployeeService;
            case LCEUserType.DELIVERY_MAN:
                return this.userDeliveryManService;
            case LCEUserType.MUNICIPAL_EMPLOYEE:
                return this.userMunicipalEmployeeService;
            default:
                throw new Error('type not considered ');
        }
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    get headerTitle(): string {
        let title = XSUtils.isEmpty(this.user) ? this.TR_BASE_LABEL + 'user' : XSCommonDomainUtils.getPersonFullName(this.user!.name!);
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.user) ? '...' : this.user!.code;
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.userID = this.dialogConfig.data.userID;
            this.userType = this.dialogConfig.data.userType;
            this.user = this.dialogConfig.data.user;

            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;

            this.adminView = this.dialogConfig.data.adminView;
            this.activeIndex = this.dialogConfig.data.tabViewActiveIndex;

            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.user));
        }
        if (XSUtils.isEmpty(this.userID) && XSUtils.isEmpty(this.user)) {
            throw new Error('userID and user cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.userID) && !XSUtils.isEmpty(this.user)) {
            throw new Error('userID and user cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.userID)) this.retrieveUser();

        this.options = {
            fnUpdateEmergencyContact: (contact?: XSContactPerson) => {
                return this.userService.updateUserEmergencyContactPerson(this.user.id, contact).pipe(tap((response) => (this.user = response)));
            }
        };

        this.user = this.activatedRoute.snapshot.data.user;
    }

    ngOnDestroy(): void {
        if (this.tabViewResizeObserver) this.tabViewResizeObserver.disconnect();
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getUserPartial(): LCEUserPartial {
        return this.toUserPartial(this.user!);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.user);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    private retrieveUser(): void {
        this.error = undefined;
        this.startLoader();
        this.subscription.add(
            this.userService
                .retrieve(this.userID!)
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: user => this.user = user,
                    error: error => (this.error = error)
                })
        );
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver(() => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }

    private toUserPartial(user: LCEUser): LCEUserPartial {
        if (user.type === LCEUserType.CUSTOMER) return LCEUserUtils.toCustomerPartial(user as LCEUserCustomer);
        return LCEUserUtils.toMunicipalEmployeePartial(user as LCEUserMunicipalEmployee);
    }
}
