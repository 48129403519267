import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_CERTIFICATE_TYPE, LCE_TR_BASE_CORE_LABEL, LCECertificate, LCECertificateBirth, LCECertificateDeath, LCECertificateMarriage, LCECertificateType} from '@lce/core';
import {XSAssert, XSPersonName, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_YYYY, XSTranslationService} from '@xs/common';
import {XSCalendarOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-certificate', templateUrl: './lce-certificate.component.html'})
export class LCECertificateRecordComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = LCE_TR_BASE_CORE_LABEL;
    readonly TR_BASE_CERTIFICATE_TYPE = LCE_TR_BASE_CERTIFICATE_TYPE;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() data: LCECertificate;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;
    @Input() showHeaderSubTitle?: boolean;

    holderName: XSPersonName | undefined;
    groomName: XSPersonName | undefined;
    brideName: XSPersonName | undefined;
    deceasedName: XSPersonName | undefined;

    issueDateOptions: XSCalendarOptions = {
        type: 'date',
        formatEN: XS_DATE_FORMAT_YYYY,
        formatFR: XS_DATE_FORMAT_YYYY
    };

    constructor(private translationService: XSTranslationService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (XSUtils.isNull(this.showHeader)) this.showHeader = true;
        this.handleData();
    }

    public isBirthCertificate(): boolean {
        return this.data.type === LCECertificateType.BIRTH;
    }

    public isMarriageCertificate(): boolean {
        return this.data.type === LCECertificateType.MARRIAGE;
    }

    public isDeathCertificate(): boolean {
        return this.data.type === LCECertificateType.DEATH;
    }

    private handleData(): void {
        if (this.isBirthCertificate()) {
            this.holderName = (this.data as LCECertificateBirth).holderName;
        } else if (this.isMarriageCertificate()) {
            this.brideName = (this.data as LCECertificateMarriage).brideName;
            this.groomName = (this.data as LCECertificateMarriage).groomName;
        } else if (this.isDeathCertificate()) {
            this.deceasedName = (this.data as LCECertificateDeath).deceasedName;
        }
    }
}
