<div class="xs-flex-column xs-width-full {{ styleClass }}">
    <span class="xs-display-block xs-font-weight-500 xs-color-secondary xs-font-size-intermediate xs-mb-5">
        {{ TR_BASE_DELIVERY_DESTINATION_TYPE + data.type | translate }}
    </span>
    <!--
    ------------------------------------------------------------------------------------------------------------------------------
    === Facility ===
    ------------------------------------------------------------------------------------------------------------------------------
    -->
    <lce-facility-partial
            *ngIf="isDestinationFacility()"
            [data]="destinationFacility.facility"
            [showCode]="full"
            [showOverlay]="showOverlay">
    </lce-facility-partial>
    <!--
    ------------------------------------------------------------------------------------------------------------------------------
    === Service Point ===
    ------------------------------------------------------------------------------------------------------------------------------
    -->
    <lce-service-point-partial
            *ngIf="isDestinationServicePoint()"
            [data]="destinationServicePoint.servicePoint"
            [showCode]="full"
            [showOverlay]="showOverlay">
    </lce-service-point-partial>
    <!--
    ------------------------------------------------------------------------------------------------------------------------------
    === Custom Location ===
    ------------------------------------------------------------------------------------------------------------------------------
    -->
    <div *ngIf="isDestinationCustomLocation() && !full" class="xs-flex-column xs-mb-2">
            <span *ngIf="destinationCustomLocation.home | xsIsNotNull"
                  class="xs-display-block xs-font-size-small xs-color-secondary">
                {{ 'lce.core.label.home' | translate }}
            </span>
        <span *ngIf="destinationCustomLocation.office | xsIsNotNull"
              class="xs-display-block xs-font-size-small xs-color-secondary">
                {{ 'lce.core.label.office' | translate }}
            </span>
    </div>
    <div *ngIf="isDestinationCustomLocation() && !full" class="xs-flex-column">
            <span (click)="onCustomLocationCoordinateClick()"
                  class="xs-display-block xs-font-size-intermediate xs-clickable">
                {{ destinationCustomLocation.address.city | xsCapitalizeFirstLetters }},
                {{ destinationCustomLocation.address.countryISO | uppercase }}
            </span>
    </div>
    <div *ngIf="isDestinationCustomLocation() && full" class="xs-flex-column">
        <xs-address
                (coordinateClickEvent)="onCustomLocationCoordinateClick()"
                [coordinateShowPinIcon]="false"
                [data]="destinationCustomLocation.address" styleClass="xs-font-size-intermediate">
        </xs-address>
    </div>
    <!--
    ------------------------------------------------------------------------------------------------------------------------------
    // === International ===
    ------------------------------------------------------------------------------------------------------------------------------
    -->
    <div *ngIf="isDestinationInternational() && !full" class="xs-flex-column">
            <span (click)="onInternationalCoordinateClick()"
                  class="xs-display-block xs-font-size-intermediate xs-clickable">
                {{ destinationInternational.address.city | xsCapitalizeFirstLetters }},
                {{ destinationInternational.address.countryISO | uppercase }}
            </span>
    </div>
    <div *ngIf="isDestinationInternational() && full" class="xs-flex-column xs-mt-2">
        <xs-address
                (coordinateClickEvent)="onInternationalCoordinateClick()"
                [coordinateShowPinIcon]="false"
                [data]="destinationInternational.address"
                styleClass="xs-font-size-intermediate">
        </xs-address>
    </div>
</div>