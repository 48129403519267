import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEFacilityWorker, LCEFacilityWorkerCreate, LCEFacilityWorkerPartial, LCEFacilityWorkerPosition, LCEFacilityWorkerSearch, LCEUserPartial} from '@lce/core';
import {XS_LOREM_IPSUM, XSAddressType, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import FACILITY_WORKER_JSON from '../data/lce-facility-workers.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCEMockUtils} from '../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';
import {LCEMockFacilityHandler} from './facility/lce-mock-facility-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.facilityWorkers';

@Injectable()
export class LCEMockFacilityWorkerHandler extends XSMockPKResourceAuditFullHandler<LCEFacilityWorker, LCEFacilityWorkerPartial, LCEFacilityWorkerSearch>
    implements XSMockPKSearchable<LCEFacilityWorkerPartial> {

    public static FACILITY_WORKER_STORAGE: Map<string, LCEFacilityWorker> = new Map<string, LCEFacilityWorker>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEFacilityWorker>[] = [
        (facilityWorker, query) => facilityWorker.code.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.name.firstName.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.name.lastName.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.email?.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.primaryPhoneNumber.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.position.toLowerCase().includes(query),
        (facilityWorker, query) => facilityWorker.facility.code.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEFacilityWorker>[] = [
        (facilityWorker, params) => this.httpParamArrayIncludes(params, 'positions', facilityWorker.code)
    ];

    constructor(private contextService: LCECoreContextService, private facilityHandler: LCEMockFacilityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.facilityWorker.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEFacilityWorker> {
        return LCEMockFacilityWorkerHandler.FACILITY_WORKER_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(facilityWorker: LCEFacilityWorker): LCEFacilityWorkerPartial {
        return {
            id: facilityWorker.id,
            name: facilityWorker.name,
            primaryPhoneNumber: facilityWorker.primaryPhoneNumber,
            email: facilityWorker.email,
            code: facilityWorker.code,
            position: facilityWorker.position,
            facilityCode: facilityWorker.facility.code,
            facilityFullName: facilityWorker.facility.fullName
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 2,
                    active: 2,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEFacilityWorkerPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    public getMidWifeWorker(): LCEFacilityWorkerPartial {
        return this.findOneByCodeAsPartial('LCE-FAW-040506');
    }

    public getNurseWorker(): LCEFacilityWorkerPartial {
        return this.findOneByCodeAsPartial('LCE-FAW-321654');
    }

    private createResponseData(body: any): LCEFacilityWorker {
        const facilityWorkerCreate: LCEFacilityWorkerCreate = body as LCEFacilityWorkerCreate;
        const facilityWorker: LCEFacilityWorker = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: this.buildCode(),
            name: facilityWorkerCreate.name,
            email: facilityWorkerCreate.email,
            primaryPhoneNumber: facilityWorkerCreate.primaryPhoneNumber,
            secondaryPhoneNumber: facilityWorkerCreate.secondaryPhoneNumber,
            address: facilityWorkerCreate.address,
            note: facilityWorkerCreate.note,
            position: facilityWorkerCreate.position,
            facility: this.facilityHandler.findOneByCodeAsPartial(facilityWorkerCreate.facilityCode),
            hiredOn: facilityWorkerCreate.hiredOn
        };

        LCEMockFacilityWorkerHandler.FACILITY_WORKER_STORAGE.set(facilityWorker.id, facilityWorker);
        this.addResourceBaseMockData(facilityWorker.id);

        return facilityWorker;
    }

    private buildStorage(): void {
        FACILITY_WORKER_JSON.forEach((jsonFacilityWorker) => {
            const facilityWorker: LCEFacilityWorker = {
                ...jsonFacilityWorker,
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                note: XSUtils.randomElement([XS_LOREM_IPSUM.medium, XS_LOREM_IPSUM.short]),
                facility: this.facilityHandler.getYopougonHospital(),
                position: jsonFacilityWorker.position as LCEFacilityWorkerPosition,
                hiredOn: new Date().toDateString(),
                address: {
                    type: XSAddressType.BASIC,
                    city: 'abidjan',
                    countryISO: 'ci'
                }
            };

            if (XSUtils.randomElement<boolean>([true, true, false])) {
                facilityWorker.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                facilityWorker.updatedOn = new Date().toISOString();
            }

            LCEMockFacilityWorkerHandler.FACILITY_WORKER_STORAGE.set(facilityWorker.id, facilityWorker);
            this.addResourceBaseMockData(facilityWorker.id);
        });
    }

    private buildCode(): string {
        return 'LCE-FAW-' + XSUtils.randomDigits(6);
    }
}
