import {XSUtils} from '@xs/base';
import {LCECertificateType, LCECertificateTypeStr} from '@lce/core';
import {XSAvatar} from '@xs/core';

export class LCECertificateUtils {

    public static getCertificateType(cType: LCECertificateType | 'all' | undefined): LCECertificateType | 'all' {
        if (XSUtils.isEmpty(cType) || cType === 'all') {
            return 'all';
        }
        return cType!;
    }
}

export const LCE_CERTIFICATE_PAYMENT_TYPE_AVATAR_MAP: Map<LCECertificateType | LCECertificateTypeStr | 'all', XSAvatar> = new Map([
    ['all' as any, {type: 'label', data: ''}],
    [LCECertificateType.BIRTH, {type: 'label', data: ''}],
    [LCECertificateType.MARRIAGE, {type: 'label', data: ''}],
    [LCECertificateType.DEATH, {type: 'label', data: ''}]
]);