import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LCEWebSocketConnectionStatus, LCEWebSocketService} from '@lce/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-websocket-connection-status',
    template: `
        <i
                class="{{ ICON.connection }} {{ styleClass }} xs-font-size-medium-imp"
                [ngClass]="{ 'xs-color-primary': isConnected(), 'xs-color-light': !isConnected() }"
                [pTooltip]="'lce.shared.connectionStatus.' + status | translate"
                [showDelay]="2000"
                tooltipPosition="bottom">
        </i>
    `
})
export class LCEWebSocketConnectionStatusComponent implements OnInit, OnDestroy {
    ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    status: LCEWebSocketConnectionStatus = LCEWebSocketConnectionStatus.CLOSED;

    private subscription: Subscription = new Subscription();

    constructor(private webSocketService: LCEWebSocketService) {
    }

    ngOnInit() {
        this.subscription.add(this.webSocketService.onConnectionStatusChange.subscribe(status => this.handleConnectionStatusChange(status)));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isConnected(): boolean {
        return this.status === LCEWebSocketConnectionStatus.CONNECTED;
    }

    private handleConnectionStatusChange(status: LCEWebSocketConnectionStatus): void {
        this.status = status;
    }
}
