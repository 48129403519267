import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {XSUtils} from '@xs/base';
import {LCECertificateOrderDeliveryAssignment, LCECertificateOrderDeliveryAssignmentService} from '@lce/core';
import {XSButton, XSCalendarOptions} from '@xs/core';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSLoaderService} from '@xs/common';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';

@Component({selector: 'lce-delivery-assignment', templateUrl: './lce-delivery-assignment.component.html'})
export class LCEDeliveryAssignmentComponent implements OnInit, OnDestroy {

    readonly LOADER_ID = XSUtils.uuid();
    readonly TR_BASE = 'lce.shared.assignment.';
    readonly ICON_ASSIGNMENT = LCE_SHARED_ICON.assignment;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;
    @Input() showRefreshOrderNumberButton?: boolean;

    @Input() data?: LCECertificateOrderDeliveryAssignment;
    @Input() assignmentID?: string;

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: LCE_SHARED_ICON.redo,
        onClick: () => this.retrieve()
    };
    private subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private certificateOrderDeliveryAssignmentService: LCECertificateOrderDeliveryAssignmentService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.assignmentID) && XSUtils.isEmpty(this.data)) {
            throw new Error('assignmentID and data cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.assignmentID) && !XSUtils.isEmpty(this.data)) {
            throw new Error('assignmentID and data cannot both be set at the same time.');
        }

        if (!XSUtils.isEmpty(this.assignmentID)) this.retrieve();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public refresh(): void {
        if (!XSUtils.isEmpty(this.assignmentID)) this.retrieve();
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.data);
    }

    private retrieve(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService.retrieve(this.assignmentID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe(
                    {
                        next: (retrievedAssignment: LCECertificateOrderDeliveryAssignment) => {
                            this.data = retrievedAssignment;
                        },
                        error: error => this.error = error
                    }
                )
        );
    }
}