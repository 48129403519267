import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {XSConfirmation, XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {XS_STR_EMPTY, XSPagination, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {LCEDeliveryCompany, LCEDeliveryCompanyCanOptions, LCEDeliveryCompanyPartial, LCEDeliveryCompanySearch, LCEDeliveryCompanyService} from '@lce/core';
import {LCEDeliveryCompanyDialogService} from './lce-delivery-company-dialog.service';
import {finalize} from 'rxjs/operators';
import {LCEDeliveryCompaniesFeatureService} from './lce-delivery-companies-feature.service';

@Component({
    selector: 'lce-delivery-companies',
    templateUrl: 'lce-delivery-companies.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCEDeliveryCompaniesComponent implements OnInit, OnDestroy {

    readonly ICON_DELIVERY_COMPANY: string = LCE_SHARED_ICON.delivery;
    readonly ICON_CONFIRMATION: string = LCE_SHARED_ICON.confirmation;

    readonly TR_BASE: string = 'lce.shared.deliveryCompany.';

    @Input() styleClass?: string;

    @Input() showBackgroundIcon?: boolean;

    @Input() canOptions?: LCEDeliveryCompanyCanOptions;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    registerUnregisterLoading: boolean = false;

    registerConfirmation: XSConfirmation;
    unregisterConfirmation: XSConfirmation;

    private subscription: Subscription = new Subscription();

    constructor(
        public deliveryCompanyService: LCEDeliveryCompanyService,
        private featureService: LCEDeliveryCompaniesFeatureService,
        private deliveryCompanyDeliveryService: LCEDeliveryCompanyDialogService) {
    }

    get selectedDeliveryCompany(): LCEDeliveryCompany | undefined {
        return this.dataManager.selectedRecordData as LCEDeliveryCompany;
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
        this.retrieveStats();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));

        this.buildConfirmations();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public registered(): void {

        this.registerUnregisterLoading = true;
        this.subscription.add(
            this.deliveryCompanyService.register(this.dataManager.selectedRecordData.id)
                .pipe(finalize(() => (this.registerUnregisterLoading = false)))
                .subscribe({
                    next: (registrationResponse) => {
                        console.log('registered', registrationResponse);
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).registered = registrationResponse.registered;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).registeredOn = registrationResponse.registeredOn;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).registeredBy = registrationResponse.registeredBy;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).updatedOn = registrationResponse.updatedOn;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).updatedBy = registrationResponse.updatedBy;
                        this.dataManager.updateSelectedRecordData({...this.dataManager.selectedRecordData});
                    }
                })
        );
    }

    public unregistered(): void {
        console.log('unregistered. ==> selectedDeliveryCompany', this.selectedDeliveryCompany);

        this.registerUnregisterLoading = true;
        this.subscription.add(
            this.deliveryCompanyService.unregister(this.dataManager.selectedRecordData.id)
                .pipe(finalize(() => (this.registerUnregisterLoading = false)))
                .subscribe({
                    next: registrationResponse => {
                        console.log('unregistered', registrationResponse);
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).registered = registrationResponse.registered;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).unregisteredOn = registrationResponse.unregisteredOn;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).unregisteredBy = registrationResponse.unregisteredBy;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).updatedOn = registrationResponse.updatedOn;
                        (this.dataManager.selectedRecordData as LCEDeliveryCompany).updatedBy = registrationResponse.updatedBy;
                        this.dataManager.updateSelectedRecordData({...this.dataManager.selectedRecordData});
                    }
                })
        );
    }

    public canEdit(): boolean {
        return this.canOptions?.update !== false;
    }

    public onEdit(): void {
        this.deliveryCompanyDeliveryService.openCreateUpdate({
            deliveryCompany: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: deliveryCompanyRecord => {
                this.dataManager.updateSelectedRecordData(deliveryCompanyRecord);
                this.dataManager.selectedRowResult.fullName = deliveryCompanyRecord.fullName;
            }
        });
    }

    public canRegistered(): boolean {
        return !XSUtils.isNull(this.dataManager.selectedRecordData);
    }

    private buildConfirmations() {
        this.registerConfirmation = {
            key: 'registerConfirmationKey',
            trMessage: 'lce.shared.deliveryCompany.label.registerConfirmation',
            icon: this.ICON_CONFIRMATION,
            accept: () => {
                this.registered();
            }
        };
        this.unregisterConfirmation = {
            key: 'unregisterConfirmationKey',
            trMessage: 'lce.shared.deliveryCompany.label.unregisterConfirmation',
            icon: this.ICON_CONFIRMATION,
            accept: () => {
                this.unregistered();
            }
        };
    }

    private refresh(): void {
        this.retrieveStats();
        this.dataManager.updateSearch();
    }

    private retrieveStats(): void {
        this.statsError = undefined;
        this.statsLoading = true;
        this.subscription.add(
            this.deliveryCompanyService
                .retrieveStats()
                .pipe(finalize(() => (this.statsLoading = false)))
                .subscribe({
                    next: stats => this.stats = stats,
                    error: error => this.statsError = error
                })
        );
    }

    private retrieveDeliveryCompany(selectedRowResult: LCEDeliveryCompanyPartial): Observable<LCEDeliveryCompany> {
        return this.deliveryCompanyService.retrieve(selectedRowResult.id);
    }

    private searchDeliveryCompanies(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCEDeliveryCompanyPartial>> {
        const deliveryCompanySearch: LCEDeliveryCompanySearch = {
            query: XSUtils.isEmpty(query) ? XS_STR_EMPTY : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.deliveryCompanyService.search(deliveryCompanySearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchDeliveryCompanies(query, pagination),
            fnRetrieveRecordData: selectedRowData => this.retrieveDeliveryCompany(selectedRowData),

            search: {
                defaultPaginationSize: 20,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE + 'main.resultFound'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }

}