import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TabView} from 'primeng/tabview';
import {XSUtils} from '@xs/base';
import {XSCoreDomUtils} from '@xs/core';

@Component({selector: 'lce-cmd-resources', templateUrl: './lce-cmd-resources.component.html', host: {class: 'xs-flex-column xs-flex-1'}})
export class LCECMDResourcesComponent implements OnInit, OnDestroy {

    navScrollable: boolean = false;

    private tabView: TabView;
    private tabViewResizeObserver: ResizeObserver;

    constructor() {
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        if (this.tabViewResizeObserver) this.tabViewResizeObserver.disconnect();
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver(() => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }
}
