import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCEDistrictPartial, LCEMunicipality, LCEMunicipalityCreate, LCEMunicipalityService} from '@lce/core';
import {LOG, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldBaseOptions, XSInputFieldCalendarOptions, XSInputFieldTextOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-municipality-create-update', templateUrl: './lce-municipality-create-update.component.html'})
export class LCEMunicipalityCreateUpdateComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.municipality.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    readonly LOADER_ID_CENTRAL: string = 'createUpdateMunicipalityCentralLoader';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() contentStyleClass?: string;

    @Input() municipalityID?: string;
    @Input() municipality?: LCEMunicipality;

    @Output() saveEvent = new EventEmitter<LCEMunicipality>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEMunicipality>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    formGroup: FormGroup = new FormGroup({});

    nameField: XSInputFieldTextOptions;
    fullNameField: XSInputFieldTextOptions;
    shortNameField: XSInputFieldTextOptions;
    districtField: XSInputFieldBaseOptions;
    mayorSinceField: XSInputFieldCalendarOptions;
    mayorFirstNameField: XSInputFieldCalendarOptions;
    mayorLastNameField: XSInputFieldCalendarOptions;
    populationSizeField: XSInputFieldTextOptions;
    populationUpdateYearField: XSInputFieldTextOptions;
    squareKMField: XSInputFieldTextOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveMunicipality()
    };

    createUpdateError: any;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private municipalityService: LCEMunicipalityService) {
        super();
    }

    get headerTitle(): string {
        let title = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createTitle' : this.municipality?.fullName;
        if (XSUtils.isEmpty(title)) title = this.TR_BASE_LABEL + 'municipality';
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = this.isCreateMode() ? this.TR_BASE + 'createUpdate.createSubTitle' : this.municipality?.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.municipalityID = this.dialogConfig.data.municipalityID;
            this.municipality = this.dialogConfig.data.municipality;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.contentStyleClass = this.dialogConfig.data.contentStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.municipality));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.municipality)) {
            if (!XSUtils.isEmpty(this.municipality)) this.municipalityID = this.municipality!.id;
            this.initialize();
        } else this.retrieveMunicipality();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.municipalityID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.municipalityID);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public reset() {
        this.formGroup.reset();
    }

    public close() {
        this.closeDialog();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty;
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.nameField.control?.value) ||
            !XSUtils.isEmpty(this.fullNameField.control?.value) ||
            !XSUtils.isEmpty(this.shortNameField.control?.value) ||
            !XSUtils.isEmpty(this.districtField.control?.value) ||
            !XSUtils.isEmpty(this.mayorFirstNameField.control?.value) ||
            !XSUtils.isEmpty(this.mayorLastNameField.control?.value) ||
            !XSUtils.isEmpty(this.mayorSinceField.control?.value) ||
            !XSUtils.isEmpty(this.populationSizeField.control?.value) ||
            !XSUtils.isEmpty(this.populationUpdateYearField.control?.value) ||
            !XSUtils.isEmpty(this.squareKMField.control?.value));
    }

    public fillForm(): void {
        this.districtField.control?.setValue({
            id: 'xxx',
            code: 'LCE-DST-ABJ',
            name: 'Abidjan',
            abbreviation: 'ABJ',
            fullName: 'District Autonome d\'Abidjan'
        } as LCEDistrictPartial);

        this.nameField.control?.setValue('Asgard');
        this.fullNameField.control?.setValue('Commune d\'Asgard');
        this.shortNameField.control?.setValue('ASG');
        this.mayorFirstNameField.control?.setValue('Heimdall');
        this.mayorLastNameField.control?.setValue('Gatekeeper');
        this.populationSizeField.control?.setValue('447055');
        this.populationUpdateYearField.control?.setValue('2020');
        this.squareKMField.control?.setValue('132');
        this.mayorSinceField.control?.setValue('2022-03-25');
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private retrieveMunicipality(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.municipalityService
                .retrieve(this.municipalityID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: retrievedMunicipality => {
                        this.municipality = retrievedMunicipality;
                        this.initialize();
                    },
                    error: error => (this.retrieveError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating Municipality [municipalityID: ' + this.municipalityID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildMunicipalityUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Municipality not updated. No change detected ! [code: ' + this.municipality!.code + ', id: ' + this.municipality!.id + ']');
            this.saveEvent.emit(undefined);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.municipalityService
                .update(this.municipalityID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: municipality => {
                        const updatedMuniciplaityID = municipality.id;
                        LOG().debug('municipality successfully updated :-) [code: ' + municipality.code + ', id: ' + updatedMuniciplaityID + ']');
                        this.saveEvent.emit(municipality);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating Municipality ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;

        const municipalityCreate = this.buildMunicipalityCreate(formData);
        this.subscription.add(
            this.municipalityService
                .create(municipalityCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: municipality => {
                        LOG().debug('Municipality successfully saved :-) [code: ' + municipality.code + ', id: ' + municipality.id + ']');
                        this.saveEvent.emit(municipality);
                    },
                    error: error => (this.createUpdateError = error)
                })
        );
    }

    private buildMunicipalityUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (!XSUtils.equal(formData.name, this.municipality?.name)) fieldValueMap.set('name', XSUtils.trim(formData.name));
        if (!XSUtils.equal(formData.fullName, this.municipality?.fullName)) fieldValueMap.set('fullName', XSUtils.trim(formData.fullName));
        if (!XSUtils.equal(formData.shortName, this.municipality?.shortName)) fieldValueMap.set('shortName', XSUtils.trim(formData.shortName));
        if (!XSUtils.equal(formData.mayorFirstName, this.municipality?.actualMayorName.firstName)) fieldValueMap.set('actualMayorName.firstName', XSUtils.trim(formData.mayorFirstName));
        if (!XSUtils.equal(formData.mayorLastName, this.municipality?.actualMayorName.lastName)) fieldValueMap.set('actualMayorName.lastName', XSUtils.trim(formData.mayorLastName));

        if (!XSUtils.equal(formData.populationSize, this.municipality?.populationSize)) {
            fieldValueMap.set('populationSize', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationSize)));
        }
        if (!XSUtils.equal(formData.populationUpdateYear, this.municipality?.populationUpdateYear)) {
            fieldValueMap.set('populationUpdateYear', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationUpdateYear)));
        }
        if (!XSUtils.equal(formData.squareKM, this.municipality?.squareKM)) {
            fieldValueMap.set('squareKM', XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.squareKM)));
        }
        if (!XSUtils.equal(formData.district.code, this.municipality!.district.code)) {
            fieldValueMap.set('districtCode', XSUtils.trim(formData.district.code));
        }
        return fieldValueMap;
    }

    private buildMunicipalityCreate(formData: any): LCEMunicipalityCreate {
        let municipalityCreate: LCEMunicipalityCreate = {
            name: XSUtils.trim(formData.name),
            fullName: XSUtils.trim(formData.fullName),
            shortName: XSUtils.trim(formData.shortName),
            districtCode: XSUtils.trim(formData.district.code),
            actualMayorName: {firstName: formData.mayorFirstName, lastName: formData.mayorLastName},
            mayorSince: formData.mayorSince
        };

        if (!XSUtils.isEmpty(formData.populationSize)) municipalityCreate.populationSize = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationSize));
        if (!XSUtils.isEmpty(formData.populationUpdateYear)) municipalityCreate.populationUpdateYear = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.populationUpdateYear));
        if (!XSUtils.isEmpty(formData.squareKM)) municipalityCreate.squareKM = XSUtils.parseInt(XSUtils.clearFormattedNumber(formData.squareKM));

        XSUtils.removeNullAndUndefinedEntries(municipalityCreate);

        return municipalityCreate;
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.close();
            },
            reject: () => {
            }
        };
    }

    private buildFields(): void {
        this.districtField = {
            fieldName: 'district',
            control: new FormControl(this.municipality?.district, Validators.required),
            label: this.TR_BASE_LABEL + 'district'
        };
        this.nameField = {
            fieldName: 'name',
            control: new FormControl(this.municipality?.name, Validators.required),
            label: this.TR_BASE_LABEL + 'name'
        };
        this.fullNameField = {
            fieldName: 'fullName',
            control: new FormControl(this.municipality?.fullName, Validators.required),
            label: this.TR_BASE_LABEL + 'fullName'
        };
        this.shortNameField = {
            fieldName: 'shortName',
            control: new FormControl(this.municipality?.shortName),
            label: this.TR_BASE_LABEL + 'shortName'
        };
        this.mayorFirstNameField = {
            fieldName: 'mayorFirstName',
            label: this.TR_BASE_LABEL + 'mayorFirstName',
            control: new FormControl(this.municipality?.actualMayorName.firstName, [Validators.required])
        };
        this.mayorLastNameField = {
            fieldName: 'mayorLastName',
            label: this.TR_BASE_LABEL + 'mayorLastName',
            control: new FormControl(this.municipality?.actualMayorName.lastName, [Validators.required])
        };
        this.mayorSinceField = {
            fieldName: 'mayorSince',
            label: this.TR_BASE_LABEL + 'mayorSince',
            calendarOptions: {dateFormatFR: 'yy-mm-dd'},
            control: new FormControl(this.municipality?.mayorSince, [Validators.required])
        };
        this.populationSizeField = {
            fieldName: 'populationSize',
            type: 'number',
            maxLength: 10,
            control: new FormControl(this.municipality?.populationSize),
            label: this.TR_BASE_LABEL + 'populationSize',
            placeholder: this.TR_BASE_LABEL + 'populationSizePlaceholder'
        };
        this.populationUpdateYearField = {
            fieldName: 'populationUpdateYear',
            label: this.TR_BASE_LABEL + 'populationUpdateYear',
            type: 'number',
            maxLength: 4,
            control: new FormControl(this.municipality?.populationUpdateYear, [Validators.min(1980), Validators.max(new Date().getFullYear())]),
            placeholder: this.TR_BASE_LABEL + 'populationUpdateYearPlaceholder'
        };
        this.squareKMField = {
            fieldName: 'squareKM',
            type: 'number',
            maxLength: 10,
            control: new FormControl(this.municipality?.squareKM),
            label: this.TR_BASE_LABEL + 'squareKM',
            placeholder: this.TR_BASE_LABEL + 'squareKMPlaceholder',
            groupAddon: true,
            rightAddonValue: 'km²'
        };

        this.formGroup.addControl(this.districtField.fieldName, this.districtField.control!);
        this.formGroup.addControl(this.nameField.fieldName, this.nameField.control!);
        this.formGroup.addControl(this.fullNameField.fieldName, this.fullNameField.control!);
        this.formGroup.addControl(this.shortNameField.fieldName, this.shortNameField.control!);
        this.formGroup.addControl(this.mayorFirstNameField.fieldName, this.mayorFirstNameField.control!);
        this.formGroup.addControl(this.mayorLastNameField.fieldName, this.mayorLastNameField.control!);
        this.formGroup.addControl(this.mayorSinceField.fieldName, this.mayorSinceField.control!);
        this.formGroup.addControl(this.populationSizeField.fieldName, this.populationSizeField.control!);
        this.formGroup.addControl(this.populationUpdateYearField.fieldName, this.populationUpdateYearField.control!);
        this.formGroup.addControl(this.squareKMField.fieldName, this.squareKMField.control!);
    }
}
