<div [ngClass]="{ 'xs-border-discrete xs-p-15 xs-border-radius-default': showBorder === true }" class="xs-flex-column xs-plr-20 xs-pt-20 xs-pb-10 xs-width-full xs-m-15 {{ styleClass }}">
    <xs-ivar
        [avatarNoBackground]="true"
        [avatarRounded]="true"
        [avatarType]="'icon'"
        [avatar]="workerIcon"
        [ellipsis]="false"
        [line1]="data.name?.firstName + ' ' + data.name?.lastName"
        [line4]="data.facilityFullName"
        [line2]="TR_BASE_FACILITY_WORKER_POSITION + data.position"
        avatarSize="extra-large"
        avatarStyleClass="xs-border-radius-default xs-mr-30-imp"
        avatarIconStyleClass="xs-color-secondary-imp"
        iconStyleClass="xs-color-discrete"
        line3="{{ data.code | uppercase }}"
        line1StyleClass="xs-font-weight-500 xs-font-size-percent-110-imp xs-text-primary-color-imp xs-mb-10-imp"
        line2StyleClass="xs-color-secondary xs-font-size-small-imp xs-font-weight-500 xs-mt-5-imp xs-mb-2-imp"
        line3StyleClass="xs-color-secondary xs-font-size-small-imp xs-mt-1-imp xs-mb-25-imp"
        line4StyleClass="xs-color-secondary xs-font-size-intermediate-imp xs-mt-1-imp"
        styleClass="xs-align-items-start-imp">
    </xs-ivar>
</div>
