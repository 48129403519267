import {InjectionToken} from '@angular/core';

export const LCE_DEFAULT_LOCATION_COUNTRY_ISO: string = 'ci';
export const LCE_DEFAULT_LOCATION_CITY: string = 'abidjan';

// ------------------------------------------------------------------------------------------------------------------------------------
// === * ===
// ------------------------------------------------------------------------------------------------------------------------------------

export const LCE_APP_SHORT_NAME = 'LCE';

export const LCE_CODE_PREFIX = 'LCE-';

export const LCE_ORDER_NUMBER_REGEX_PATTERN: string = '^LCE-(BIC|MAC|DEC)-[0-9]{6,6}$';
export const LCE_ORDER_NUMBER_PREFIX = 'LCE-';

export const LCE_USER_SYSTEM_ID: string = 'system';
export const LCE_USER_SYSTEM_DHL_ID: string = 'system-dhl';
export const LCE_USER_SYSTEM_UPS_ID: string = 'system-ups';

// ------------------------------------------------------------------------------------------------------------------------------------
// === Municipality ===
// ------------------------------------------------------------------------------------------------------------------------------------

export const LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON: string = 'LCE-MUN-ABJ-YOP';
export const LCE_MOCK_MUNICIPALITY_NAME_YOPOUGON: string = 'Yopougon';

export const LCE_MOCK_MUNICIPALITY_CODE_COCODY: string = 'LCE-MUN-ABJ-COC';
export const LCE_MOCK_MUNICIPALITY_NAME_COCODY: string = 'Cocody';
// ------------------------------------------------------------------------------------------------------------------------------------
// === Translation ===
// ------------------------------------------------------------------------------------------------------------------------------------
export const LCE_TR_BASE_NOTIFICATION_CHANNEL = 'lce.core.notificationChannel.';

export const LCE_TR_BASE_REQUESTOR_TYPE = 'lce.core.requestorType.';
export const LCE_TR_BASE_RESOURCE_TYPE = 'lce.core.resourceType.';

export const LCE_TR_BASE_DELIVERY_DESTINATION_TYPE = 'lce.core.deliveryDestinationType.';

export const LCE_TR_BASE_FACILITY_TYPE: string = 'lce.core.facilityType.';
export const LCE_TR_BASE_FACILITY_WORKER_POSITION: string = 'lce.core.facilityWorkerPosition.';

export const LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE: string = 'lce.core.identityDocumentType.';

// === Town Hall Stamp Token Status ===
export const LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS: string = 'lce.core.townHallStampToken.status.';
export const LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT: string = 'lce.core.townHallStampToken.statusShort.';

// === Smart City ===
export const LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE: string = 'lce.core.smartCityArticleType.';

// === Suggestion ===
export const LCE_TR_BASE_SUGGESTION_STATUS: string = 'lce.core.suggestionStatus.';

// === Certificate ===
export const LCE_TR_BASE_CERTIFICATE_ORDER_STATUS: string = 'lce.core.certificateOrderStatus.';
export const LCE_TR_BASE_CERTIFICATE_ORDER_STATUS_DESCRIPTION: string = 'lce.core.certificateOrderStatusDescription.';
export const LCE_TR_BASE_CERTIFICATE_TYPE: string = 'lce.core.certificateType.';
export const LCE_TR_BASE_CERTIFICATE_TYPE_MEDIUM: string = 'lce.core.certificateTypeMedium.';
export const LCE_TR_BASE_CERTIFICATE_TYPE_LETTER: string = 'lce.core.certificateTypeLetter.';

// === User ===
export const LCE_TR_BASE_USER_STATUS: string = 'lce.core.user.status.';
export const LCE_TR_BASE_USER_ACCOUNT_STATUS: string = 'lce.core.user.accountStatus.';

export const LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE: string = 'lce.core.user.municipalEmployeeMainRole.';
export const LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION: string = 'lce.core.user.municipalEmployeePosition.';

export const LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE: string = 'lce.core.user.employeeMainRole.';
export const LCE_TR_BASE_USER_EMPLOYEE_POSITION: string = 'lce.core.user.employeePosition.';

export const LCE_TR_BASE_USER_TYPE: string = 'lce.core.user.type.';

// === * ===
export const LCE_TR_BASE_CORE_LABEL: string = 'lce.core.label.';

// ------------------------------------------------------------------------------------------------------------------------------------
// === Tokens ===
// ------------------------------------------------------------------------------------------------------------------------------------

export const LCE_USER_ENDPOINTS = new InjectionToken<any>('lce.token.userEndpoints');

// ------------------------------------------------------------------------------------------------------------------------------------
// === Colors ===
// ------------------------------------------------------------------------------------------------------------------------------------

export const LCE_BIRTH_COLOR = '#36A2EB';
export const LCE_MARRIAGE_COLOR = '#FF6384';
export const LCE_DEATH_COLOR = '#000000';

// ------------------------------------------------------------------------------------------------------------------------------------
// === * ===
// ------------------------------------------------------------------------------------------------------------------------------------

export type XSMonthKey = 'm1' | 'm2' | 'm3' | 'm4' | 'm5' | 'm6' | 'm7' | 'm8' | 'm9' | 'm10' | 'm11' | 'm12';

export const XS_MONTH_NAME_MAP: any = {
    en: {
        m1: 'January',
        m2: 'February',
        m3: 'March',
        m4: 'April',
        m5: 'May',
        m6: 'June',
        m7: 'July',
        m8: 'August',
        m9: 'September',
        m10: 'October',
        m11: 'November',
        m12: 'December'
    },
    fr: {
        m1: 'Janvier',
        m2: 'Février',
        m3: 'Mars',
        m4: 'Avril',
        m5: 'Mai',
        m6: 'Juin',
        m7: 'Juillet',
        m8: 'Août',
        m9: 'Septembre',
        m10: 'Octobre',
        m11: 'Novembre',
        m12: 'Décembre'
    }
};