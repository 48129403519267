import {LCEUserCustomerCreate} from '../domain/user/lce-user-customer';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEUserCreate} from '../domain/user/lce-user';
import {LCECertificateOrderCreate} from '../domain/certificate/order/lce-certificate-order';
import {LCECertificateCreate} from '../domain/certificate/lce-certificate';
import {LCEFacilityWorkerCreate} from '../domain/lce-facility-worker';
import {LCEMunicipalityCreate} from '../domain/lce-municipality';
import {LCEServicePointCreate} from '../domain/lce-service-point';
import {LCESuggestionCreate} from '../domain/lce-suggestion';
import {LCEBirthDeclarationCreate} from '../domain/lce-birth-declaration';
import {LCENewsArticleCreate} from '../domain/news/lce-news';
import {LCESmartCityArticleCreate} from '../domain/smartcity/lce-smartcity';
import {LCECategoryCreate} from '../domain/lce-category';
import {LCEUserDeliveryManCreate} from '../domain/user/lce-user-delivery-man';
import {LCEUserMunicipalEmployeeCreate} from '../domain/user/lce-user-municipal-employee';
import {LCEUserEmployeeCreate} from '../domain/user/lce-user-employee';

export class LCEValidatorUtils {

    public static readonly CATEGORY_NAME_MIN_LENGTH = 2;
    public static readonly CATEGORY_NAME_MAX_LENGTH = 30;

    public static readonly NEWS_ARTICLE_COMMENT_MIN_CONTENT_LENGTH = 2;
    public static readonly NEWS_ARTICLE_COMMENT_MAX_CONTENT_LENGTH = 1500;

    public static readonly SUGGESTION_MIN_MESSAGE_LENGTH = 10;
    public static readonly SUGGESTION_MAX_MESSAGE_LENGTH = 1500;

    public static validateSmartCityArticleCreate(smartCityArticleCreate: LCESmartCityArticleCreate): void {
        XSAssert.notEmpty(smartCityArticleCreate, 'smartCityArticleCreate');
        XSAssert.notEmpty(smartCityArticleCreate.title, 'smartCityArticleCreate.title');
        XSAssert.notEmpty(smartCityArticleCreate.description, 'smartCityArticleCreate.description');
        XSAssert.notEmpty(smartCityArticleCreate.content, 'smartCityArticleCreate.content');
        XSAssert.notEmpty(smartCityArticleCreate.categoryCodes, 'smartCityArticleCreate.categoryCodes');
        // TODO
    }

    public static validateNewsArticleCreate(newsArticleCreate: LCENewsArticleCreate): void {
        XSAssert.notEmpty(newsArticleCreate, 'newsArticleCreate');
        XSAssert.notEmpty(newsArticleCreate.title, 'newsArticleCreate.title');
        XSAssert.notEmpty(newsArticleCreate.description, 'newsArticleCreate.description');
        XSAssert.notEmpty(newsArticleCreate.content, 'newsArticleCreate.content');
        XSAssert.notEmpty(newsArticleCreate.categoryCodes, 'newsArticleCreate.categoryCodes');
        // TODO
    }

    public static validateNewsArticleComment(commentMessage: string): void {
        XSAssert.notEmpty(commentMessage, 'commentMessage');
        XSAssert.minMaxLength(
            commentMessage,
            LCEValidatorUtils.NEWS_ARTICLE_COMMENT_MIN_CONTENT_LENGTH,
            LCEValidatorUtils.NEWS_ARTICLE_COMMENT_MAX_CONTENT_LENGTH,
            'commentMessage'
        );
    }

    public static validateCategoryCreate(categoryCreate: LCECategoryCreate): void {
        XSAssert.notEmpty(categoryCreate, 'categoryCreate');
        XSAssert.notEmpty(categoryCreate.name, 'categoryCreate.name');
        XSAssert.notEmpty(categoryCreate.name.en, 'categoryCreate.name.en');
        XSAssert.notEmpty(categoryCreate.name.fr, 'categoryCreate.name.fr');
        XSAssert.minMaxLength(categoryCreate.name.en!, LCEValidatorUtils.CATEGORY_NAME_MIN_LENGTH, LCEValidatorUtils.CATEGORY_NAME_MAX_LENGTH, 'categoryCreate.name.en');
        XSAssert.minMaxLength(categoryCreate.name.fr!, LCEValidatorUtils.CATEGORY_NAME_MIN_LENGTH, LCEValidatorUtils.CATEGORY_NAME_MAX_LENGTH, 'categoryCreate.name.fr');
    }

    public static validateBirthDeclarationCreate(birthDeclarationCreate: LCEBirthDeclarationCreate): void {
        XSAssert.notEmpty(birthDeclarationCreate, 'birthDeclarationCreate');
        XSAssert.notEmpty(birthDeclarationCreate.facilityCode, 'birthDeclarationCreate.facilityCode');
        XSAssert.notEmpty(birthDeclarationCreate.delivery, 'birthDeclarationCreate.delivery');
        XSAssert.notEmpty(birthDeclarationCreate.mother, 'birthDeclarationCreate.mother');
        // TODO
    }

    public static validateSuggestionCreate(suggestionCreate: LCESuggestionCreate): void {
        XSAssert.notEmpty(suggestionCreate, 'suggestionCreate');
        XSAssert.notEmpty(suggestionCreate.message, 'suggestionCreate.message');
        XSAssert.minMaxLength(
            suggestionCreate.message,
            LCEValidatorUtils.SUGGESTION_MIN_MESSAGE_LENGTH,
            LCEValidatorUtils.SUGGESTION_MAX_MESSAGE_LENGTH,
            'suggestionCreate.message'
        );
        if (suggestionCreate.targetAllMunicipalities === true) {
            if (!XSUtils.isEmpty(suggestionCreate.targetMunicipalityCodes)) {
                throw new Error('suggestionCreate.targetMunicipalityCodes must be empty or null since targetAllMunicipalities is set to true.');
            }
        } else {
            XSAssert.notEmpty(suggestionCreate.targetMunicipalityCodes, 'suggestionCreate.targetMunicipalityCodes');
        }
    }

    public static validateServicePointCreate(servicePointCreate: LCEServicePointCreate): void {
        XSAssert.notEmpty(servicePointCreate, 'servicePointCreate');
        XSAssert.notEmpty(servicePointCreate.name, 'servicePointCreate.name');
        XSAssert.notEmpty(servicePointCreate.fullName, 'servicePointCreate.fullName');
        XSAssert.notEmpty(servicePointCreate.address, 'servicePointCreate.address');
        XSAssert.notEmpty(servicePointCreate.primaryContactPerson, 'servicePointCreate.primaryContactPerson');
    }

    public static validateMunicipalityCreate(municipalityCreate: LCEMunicipalityCreate): void {
        XSAssert.notEmpty(municipalityCreate, 'municipalityCreate');
        XSAssert.notEmpty(municipalityCreate.name, 'municipalityCreate.name');
        XSAssert.notEmpty(municipalityCreate.fullName, 'municipalityCreate.fullName');
        XSAssert.notEmpty(municipalityCreate.shortName, 'municipalityCreate.shortName');
        XSAssert.notEmpty(municipalityCreate.actualMayorName, 'municipalityCreate.actualMayorName');
    }

    public static validateFacilityWorkerCreate(facilityWorkerCreate: LCEFacilityWorkerCreate): void {
        XSAssert.notEmpty(facilityWorkerCreate, 'facilityWorkerCreate');
        XSAssert.notEmpty(facilityWorkerCreate.facilityCode, 'facilityWorkerCreate.facilityCode');
        XSAssert.notEmpty(facilityWorkerCreate.name, 'facilityWorkerCreate.name');
        XSAssert.notEmpty(facilityWorkerCreate.primaryPhoneNumber, 'facilityWorkerCreate.primaryPhoneNumber');
        XSAssert.notEmpty(facilityWorkerCreate.position, 'facilityWorkerCreate.position');
    }

    public static validateOrderCreate(orderCreate: LCECertificateOrderCreate): void {
        XSAssert.notEmpty(orderCreate, 'orderCreate');
        if (!XSUtils.isEmpty(orderCreate.customerCreate)) {
            LCEValidatorUtils.validateCustomerCreate(orderCreate.customerCreate!);
        }
        LCEValidatorUtils.validateCertificateCreate(orderCreate.certificateCreate);
        XSAssert.notNull(orderCreate.numberOfCopies, 'orderCreate.numberOfCopies');
        if (orderCreate.numberOfCopies < 1) {
            throw new Error('number of copies must be greater than 1. Note the server might have a more complex rule.');
        }
        // XSAssert.notEmpty(orderCreate.deliveryDestination, 'orderCreate.deliveryDestination');
        //TODO:
        // if (orderCreate.deliveryDestination.type === LCEDeliveryDestinationType.FACILITY) {
        //     const deliveryDestination = orderCreate.deliveryDestination as LCEDeliveryDestinationFacility;
        //     XSAssert.notEmpty(deliveryDestination.facility, 'orderCreate.deliveryDestination.facility');
        //     XSAssert.isString(deliveryDestination.facility, 'orderCreate.deliveryDestination.facility');
        // } else if (orderCreate.deliveryDestination.type === LCEDeliveryDestinationType.SERVICE_POINT) {
        //     const deliveryDestination = orderCreate.deliveryDestination as LCEDeliveryDestinationServicePoint;
        //     XSAssert.notEmpty(deliveryDestination.servicePoint, 'orderCreate.deliveryDestination.servicePoint');
        //     XSAssert.isString(deliveryDestination.servicePoint, 'orderCreate.deliveryDestination.servicePoint');
        // }
    }

    public static validateCertificateCreate(certificateCreate: LCECertificateCreate): void {
        XSAssert.notEmpty(certificateCreate, 'certificateCreate');
        if (!XSUtils.isNull(certificateCreate.certificateID)) {
            XSAssert.notEmpty(certificateCreate.certificateID, 'certificateCreate.certificateID');
        } else {
            XSAssert.notEmpty(certificateCreate.type, 'certificateCreate.type');
            XSAssert.notEmpty(certificateCreate.referenceNumber, 'certificateCreate.referenceNumber');
            XSAssert.notEmpty(certificateCreate.issueDate, 'certificateCreate.issueDate');
        }
    }

    public static validateCustomerCreate(customerCreate: LCEUserCustomerCreate): void {
        LCEValidatorUtils.validateUserCreate(customerCreate);
        XSAssert.notEmpty(customerCreate.primaryPhoneNumber, 'customerCreate.primaryPhoneNumber');
        if (customerCreate.termsAndConditionsAccepted === true) {
            XSAssert.notEmpty(customerCreate.sessionID, 'customerCreate.sessionID');
            XSAssert.notEmpty(customerCreate.name, 'customerCreate.name');
            XSAssert.notEmpty(customerCreate.municipalityOfResidenceCode, 'customerCreate.municipalityOfResidence');
        }
    }

    public static validateDeliveryManCreate(deliveryManCreate: LCEUserDeliveryManCreate): void {
        LCEValidatorUtils.validateUserCreate(deliveryManCreate);
        // TODO
    }

    public static validateMunicipalEmployeeCreate(municipalEmployeeCreate: LCEUserMunicipalEmployeeCreate): void {
        LCEValidatorUtils.validateUserCreate(municipalEmployeeCreate);
        // TODO
    }

    public static validateEmployeeCreate(employeeCreate: LCEUserEmployeeCreate): void {
        LCEValidatorUtils.validateUserCreate(employeeCreate);
        // TODO
    }

    public static validateUserCreate(userCreate: LCEUserCreate): void {
        XSAssert.notEmpty(userCreate, 'userCreate');
        if (!XSUtils.isEmpty(userCreate.email)) {
            XSAssert.isEmail(userCreate.email!, 'userCreate.email');
        }
        if (!XSUtils.isEmpty(userCreate.note)) {
            const noteLength = userCreate.note!.trim().length;
            if (noteLength < 0) {
                throw new Error('note must have at least 3 chars.');
            }
            if (noteLength > 300) {
                throw new Error('note must have at most 300 chars.');
            }
        }
    }
}
