import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderService, LCECertificateOrderUpdateStatusResponse, LCECertificateType} from '@lce/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSCoreDomUtils, XSDialogable} from '@xs/core';
import {TabView} from 'primeng/tabview';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-certificate-order-record',
    templateUrl: './lce-certificate-order-record.component.html',
    host: {class: 'xs-flex xs-width-full'}
})
export class LCECertificateOrderRecordComponent extends XSDialogable implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrderRecord.';
    readonly TR_BASE_LABEL: string = 'lce.shared.label.';

    readonly LOADER_ID: string = 'recordLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() orderID?: string;
    @Input() orderNumber?: string;
    @Input() type?: LCECertificateType;

    @Input() readonly?: boolean;
    @Input() stampTokenClickable?: boolean;

    @Output() closeEvent = new EventEmitter<void>();
    @Output() statusUpdatedEvent = new EventEmitter<LCECertificateOrderUpdateStatusResponse>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    data: LCECertificateOrder;

    navScrollable: boolean = false;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        icon: this.ICON.refresh,
        label: 'xs.core.label.pleaseTryAgain',
        onClick: () => this.retrieve()
    };

    private subscription: Subscription = new Subscription();

    private tabViewResizeObserver: ResizeObserver;

    private tabView: TabView;

    constructor(
        private loaderService: XSLoaderService,
        private translateService: XSTranslationService,
        private certificateOrderService: LCECertificateOrderService) {
        super();
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    get headerSubTitle(): string {
        const orderNumber = this.orderNumber ? this.orderNumber : (this.data ? this.data.orderNumber : 'LCE-...');

        if (!XSUtils.isEmpty(this.type)) {
            return this.translateService.translateKey('lce.core.certificateType.' + this.type) + ' ' + orderNumber.toUpperCase();
        } else return XSUtils.isEmpty(this.data) ? '...' : this.translateService.translateKey('lce.core.certificateType.' + this.data.certificate.type) + ' ' + orderNumber.toUpperCase();
    }

    public static validate(orderID: string | undefined, orderNumber: string | undefined, prefix: string = XS_STR_EMPTY): void {
        const str = XSUtils.isEmpty(prefix) ? XS_STR_EMPTY : prefix.trim() + '.';
        if (XSUtils.isEmpty(orderID) && XSUtils.isEmpty(orderNumber)) {
            throw new Error(`${str}orderID and ${str}orderNumber cannot all be empty at the same time.`);
        }
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.orderID = this.dialogConfig.data.orderID;
            this.orderNumber = this.dialogConfig.data.orderNumber;
            this.type = this.dialogConfig.data.type;

            this.readonly = this.dialogConfig.data.readonly;
            this.stampTokenClickable = this.dialogConfig.data.stampTokenClickable;

            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        LCECertificateOrderRecordComponent.validate(this.orderID, this.orderNumber);

        this.retrieve();
    }

    ngOnDestroy(): void {
        if (this.tabViewResizeObserver) this.tabViewResizeObserver.disconnect();
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public onStatusUpdatedEvent(statusEvent: LCECertificateOrderUpdateStatusResponse): void {
        this.data.statusAudit[statusEvent.status] = {
            on: statusEvent.updatedOn,
            by: statusEvent.updatedBy
        };
        this.data = {...this.data};
        this.statusUpdatedEvent.emit(statusEvent);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.data);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    private retrieve(): void {
        if (!XSUtils.isEmpty(this.orderID)) {
            this.internalRetrieve(this.certificateOrderService.findOneByID(this.orderID!));
            return;
        }
        if (!XSUtils.isEmpty(this.orderNumber)) {
            this.internalRetrieve(this.certificateOrderService.findOneByOrderNumber(this.orderNumber!));
            return;
        }
        throw new Error('unknown error, orderID and orderNumber are both blank. This is not supposed to happen.');
    }

    private internalRetrieve(observable: Observable<LCECertificateOrder>): void {
        this.error = undefined;
        this.startLoader();
        this.subscription.add(
            observable
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: certificateOrder => {
                        this.data = certificateOrder;
                        this.orderNumber = this.data.orderNumber;
                        if (!this.isSameOderNumber()) throw Error(`data type  [ ${this.data.certificate.type} ] and type [ ${this.type}} ]`);
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver(() => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }

    private isSameOderNumber(): boolean {
        if (XSUtils.isEmpty(this.type)) return true;
        return this.data.certificate.type === this.type;
    }
}
