<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card">
        <div class="xs-card-header xs-border-radius-none-imp  xs-container-left-stripe">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
					<span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
						{{ TR_BASE + 'timeline.title' | translate }}
					</span>
                </div>
                <div class="xs-flex-row">
                </div>
            </div>
        </div>

        <div class="xs-card-content">
            <lce-certificate-order-events
                    (auditByClickEvent)="onAuditByClick($event)"
                    [certificateOrder]="data"
                    verticalGapHeight="150px">
            </lce-certificate-order-events>

            <p-overlayPanel #byOverlay [appendTo]="'body'">
                <lce-user-detail *ngIf="eventCreatedBy" [data]="eventCreatedBy!"></lce-user-detail>
            </p-overlayPanel>
        </div>
    </div>
</div>