<xs-advanced-search
    sidebarStyleClass="xs-max-width-450"
    [options]="options"
    [formGroup]="formGroup"
    [(inputSearchText)]="searchText"
    (inputSearchTextChange)="onSearchTextChange($event)"
    (sidebarShow)="sidebarShow.emit()"
    (sidebarHide)="sidebarHide.emit($event)"
    inputSearchPlaceholder="lce.mbo.features.certificateOrders.searchTextPlaceholder"
>
    <ng-template xsTemplate="form" let-data>
        <div class="xs-flex-column xs-width-full xs-fields">
            <xs-input-field-text [options]="orderNumberOptions"></xs-input-field-text>

            <xs-input-field-multi-select [options]="orderStatusFieldOptions"></xs-input-field-multi-select>

            <div class="grid grid-nogutter">
                <div class="col-6 xs-pr-10-imp">
                    <xs-input-field-calendar [options]="createdOnStartFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
                <div class="col-6 xs-pl-10-imp">
                    <xs-input-field-calendar [options]="createdOnEndFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
            </div>

            <div class="grid grid-nogutter">
                <div class="col-6 xs-pr-10-imp">
                    <xs-input-field-calendar [options]="updatedOnStartFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
                <div class="col-6 xs-pl-10-imp">
                    <xs-input-field-calendar [options]="updatedOnEndFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
            </div>

            <div class="xs-flex-row xs-mtb-25">
                <span class="xs-font-weight-600">{{ 'lce.core.label.certificate' | translate }}</span>
            </div>

            <xs-input-field-text [options]="certificateReferenceNumberOptions"></xs-input-field-text>

            <xs-input-field-multi-select [options]="certificateTypeFieldOptions"></xs-input-field-multi-select>

            <div class="grid grid-nogutter">
                <div class="col-6 xs-pr-10-imp">
                    <xs-input-field-calendar [options]="certificateIssueDateStartFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
                <div class="col-6 xs-pl-10-imp">
                    <xs-input-field-calendar [options]="certificateIssueDateEndFieldOptions" appendTo="body"></xs-input-field-calendar>
                </div>
            </div>

            <div class="grid grid-nogutter">
                <div class="col-6 xs-pr-10-imp">
                    <xs-input-field-text [options]="certificatePersonLastNameFieldOptions"></xs-input-field-text>
                </div>
                <div class="col-6 xs-pl-10-imp">
                    <xs-input-field-text [options]="certificatePersonFirstNameFieldOptions"></xs-input-field-text>
                </div>
            </div>

            <div class="xs-flex-row xs-mtb-25">
                <span class="xs-font-weight-600">{{ 'lce.core.label.center' | translate }}</span>
            </div>

            <xs-input-field-text [options]="centerCodeFieldOptions"></xs-input-field-text>

            <xs-input-field-text [options]="centerNameFieldOptions"></xs-input-field-text>

            <div *ngIf="showDistrictFields" class="xs-flex-column xs-width-full">
                <div class="xs-flex-row xs-mtb-25">
                    <span class="xs-font-weight-600">{{ 'lce.core.label.district' | translate }}</span>
                </div>
                <xs-input-field-text [options]="districtCodeFieldOptions"></xs-input-field-text>
                <xs-input-field-text [options]="districtNameFieldOptions"></xs-input-field-text>
            </div>

            <div class="xs-flex-row xs-mtb-25">
                <span class="xs-font-weight-600">{{ 'lce.core.label.customer' | translate }}</span>
            </div>

            <div class="grid grid-nogutter">
                <div class="col-6 xs-pr-10-imp">
                    <xs-input-field-text [options]="customerLastNameFieldOptions"></xs-input-field-text>
                </div>
                <div class="col-6 xs-pl-10-imp">
                    <xs-input-field-text [options]="customerFirstNameFieldOptions"></xs-input-field-text>
                </div>
            </div>

            <xs-input-field-multi-select [options]="customerGenderFieldOptions"></xs-input-field-multi-select>

            <xs-input-field-text [options]="customerPrimaryPhoneNumberFieldOptions"></xs-input-field-text>

            <xs-input-field-text [options]="customerEmailFieldOptions"></xs-input-field-text>
        </div>
    </ng-template>
</xs-advanced-search>
