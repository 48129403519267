import { Injectable } from '@angular/core';
import { XSAssert, XSPKResourceAuditFullService } from '@xs/base';
import { Observable } from 'rxjs';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';
import { LCEDistrict, LCEDistrictCreate, LCEDistrictPartial, LCEDistrictSearch } from '../domain/lce-district';
import { LCEHttpClientService } from './lce-http-client.service';

@Injectable({ providedIn: 'root' })
export class LCEDistrictService extends XSPKResourceAuditFullService<LCEDistrict, LCEDistrictPartial, LCEDistrictSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.districts.index, 'district');
    }

    public create(districtCreate: LCEDistrictCreate): Observable<LCEDistrict> {
        XSAssert.notEmpty(districtCreate, 'districtCreate');
        return this.httpClientService.post<LCEDistrict>(this.buildEndpoint(), districtCreate);
    }
}
