import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderCreate, LCECertificateOrderService} from '@lce/core';
import {LOG, XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSConfirmation, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderCreateComponent} from '../../create/lce-certificate-order-create.component';
import {LCECertificateOrderProcessOptions, LCECertificateOrderProcessStep} from '../lce-certificate-order-process';
import {LCECertificateOrderProcessService} from '../lce-certificate-order-process.service';

@Component({
    selector: 'lce-certificate-order-process-mfo',
    templateUrl: './lce-certificate-order-process-mfo.component.html',
    providers: [LCECertificateOrderProcessService],
    host: {class: 'xs-flex xs-width-full'}
})
export class LceCertificateOrderProcessMFOComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly CERTIFICATE_ORDER_ICON = LCE_SHARED_ICON.certificateOrder;
    readonly ICON_BACK = LCE_SHARED_ICON.back;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.process.';

    readonly SAVE_ORDER_CREATE_LOADER_ID = XSUtils.uuid() + 'saveOrderCreate';
    readonly RETRIEVE_ORDER_CREATE_LOADER_ID = XSUtils.uuid() + 'retrieveOrderCreate';

    @Input() styleClass?: string;

    @Input() options: LCECertificateOrderProcessOptions;

    @Output() cancelEvent = new EventEmitter<void>();
    @Output() completeEvent = new EventEmitter<void>();
    @Output() backEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCECertificateOrder | undefined>();

    @ViewChild('orderCreateForm') orderCreateForm: LCECertificateOrderCreateComponent;
    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    stepModel: any[] = [
        {id: LCECertificateOrderProcessStep.CREATE, label: this.TR_BASE + 'createStep', disabled: true},
        {id: LCECertificateOrderProcessStep.CONFIRMATION, label: this.TR_BASE + 'confirmationStep', disabled: true}
    ];

    cancelConfirmation: XSConfirmation = {
        key: 'cancelConfirmationKey',
        trMessage: 'xs.core.label.confirmationCancelForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.cancel();
        }
    };

    certificateOrderCreate?: LCECertificateOrderCreate;
    certificateOrder: LCECertificateOrder;

    saveOrderCreateError: any;
    retrieveOrderCreateError: any;

    firstInitialization: boolean = true;

    nextButtonLabel: string = this.TR_BASE + 'nextButtonLabel';

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private certificateOrderService: LCECertificateOrderService,
        private certificateOrderProcessService: LCECertificateOrderProcessService
    ) {
        super();
    }

    get orderCreateSessionID(): string | undefined {
        return this.certificateOrderProcessService.getOrderCreateSessionID();
    }

    get currentStep(): LCECertificateOrderProcessStep {
        return this.certificateOrderProcessService.getCurrentStep();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.options = {
                facilityCode: this.dialogConfig.data.facilityCode,
                forceShippingInternationalProvider: this.dialogConfig.data.forceShippingInternationalProvider,
                countryISO: this.dialogConfig.data.countryISO,
                parameters: this.dialogConfig.data.parameters,
                useProcuration: this.dialogConfig.data.useProcuration,
                useDelivery: this.dialogConfig.data.useDelivery,
                showProcurationFillForm: this.dialogConfig.data.showProcurationFillForm,
                canUseDeliveryDate: this.dialogConfig.data.canUseDeliveryDate,
                canUseDesiredDeliveryTimeRange: this.dialogConfig.data.canUseDesiredDeliveryTimeRange,
                canUseNote: this.dialogConfig.data.canUseNote,
                showBackButton: this.dialogConfig.data.showBackButton,
                showMakeNewPaymentButton: this.dialogConfig.data.showMakeNewPaymentButton,
                showConfirmationTransactionID: this.dialogConfig.data.showConfirmationTransactionID,
                showFillFormButton: this.dialogConfig.data.showFillFormButton,
                backButtonLabel: this.dialogConfig.data.backButtonLabel
            };
            this.dialogRef.onClose.subscribe(() => {
                this.closeEvent.emit();
                this.leave();
            });
        }

        XSAssert.notEmpty(this.options, 'options');
        XSAssert.notEmpty(this.options.countryISO, 'options.countryISO');
        this.certificateOrderProcessService.validate(this.options.useDelivery, this.options.facilityCode);

        if (XSUtils.isEmpty(this.options.backButtonLabel)) this.options.backButtonLabel = 'xs.common.label.back';

        this.certificateOrderProcessService.initialize();
        this.goTo(this.currentStep);

        this.firstInitialization = false;
    }

    ngOnDestroy(): void {
        this.leave();
    }

    public shouldShowCancelConfirmation(): boolean {
        return !this.isCreateStepFormEmpty();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public cancel(): void {
        this.orderCreateForm.reset();
        this.cancelEvent.emit();
    }

    public isCreateStepFormEmpty(): boolean {
        return XSUtils.isNull(this.orderCreateForm) ? true : this.orderCreateForm.isFormEmpty();
    }

    public isSaveOrderCreateRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.SAVE_ORDER_CREATE_LOADER_ID);
    }

    public isRetrieveOrderCreateRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.RETRIEVE_ORDER_CREATE_LOADER_ID);
    }

    public canShowErrorActionButton(): boolean {
        return this.certificateOrderProcessService.canShowErrorActionButton();
    }

    public onComplete(): void {
        this.certificateOrderProcessService.setOrderCreateSessionID(undefined);
        this.certificateOrderProcessService.clearLocalStorage();
        this.goTo(LCECertificateOrderProcessStep.CREATE);
        this.completeEvent.emit();
    }

    public onStepClick(orderStep: string): void {
        console.log('---| orderStep Clicked : ' + orderStep + ' || activeStep: ' + orderStep);
        this.goTo(orderStep as LCECertificateOrderProcessStep);
    }

    public isConfirmationStep(): boolean {
        return this.certificateOrderProcessService.isConfirmationStep();
    }

    public isCreateStep(): boolean {
        return this.certificateOrderProcessService.isCreateStep();
    }

    public hasSaveOrderCreateError(): boolean {
        return !XSUtils.isNull(this.saveOrderCreateError);
    }

    public hasRetrieveOrderCreateError(): boolean {
        return !XSUtils.isNull(this.retrieveOrderCreateError);
    }

    public onNextStep(): void {
        this.orderCreateForm?.checkFormValidity();
        if (this.orderCreateForm?.isFormValid()) {
            this.saveOrderCreate(this.orderCreateForm.getFormValue());
        } else {
            LOG().debug('Invalid Order Create Process Form');
        }

    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Save / Retrieve Order Create ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    private leave(): void {
        if (this.currentStep === LCECertificateOrderProcessStep.CONFIRMATION) this.certificateOrderProcessService.clearLocalStorage();
        else this.certificateOrderProcessService.setMFOCurrentStep(LCECertificateOrderProcessStep.CREATE);
    }

    private saveOrderCreate(orderCreate: LCECertificateOrderCreate): void {
        this.saveOrderCreateError = undefined;
        this.retrieveOrderCreateError = undefined;
        this.certificateOrderProcessService.clearOrderCreateSessionIDFromStorage();
        this.loaderService.startLoader(this.SAVE_ORDER_CREATE_LOADER_ID);
        this.subscription.add(
            this.certificateOrderService
                .createFromFrontOfficeUser(orderCreate)
                .pipe(finalize(() => this.loaderService.stopLoader(this.SAVE_ORDER_CREATE_LOADER_ID)))
                .subscribe({
                    next: (certificateOrder) => {
                        this.certificateOrder = {...certificateOrder};
                        this.certificateOrderProcessService.setMFOCertificateOrderIDInLocalStorage(this.certificateOrder.id);
                        this.certificateOrderProcessService.setMFOCurrentStep(LCECertificateOrderProcessStep.CONFIRMATION);
                        this.certificateOrderCreate = undefined;
                        this.enableStep(LCECertificateOrderProcessStep.CREATE, false);
                        this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, true);
                        this.closeDialog();
                        this.closeEvent.emit(this.certificateOrder);
                        LOG().debug('Certificate Order successfully saved :-) [ id: ' + certificateOrder.id + ']');
                    },
                    error: (error) => {
                        this.saveOrderCreateError = error;
                        this.retrieveOrderCreateError = undefined;
                    }
                })
        );
    }


    private retrieveOrderCreate(): void {
        this.retrieveOrderCreateError = undefined;
        this.loaderService.startLoader(this.RETRIEVE_ORDER_CREATE_LOADER_ID);
        this.subscription.add(
            this.certificateOrderProcessService
                .retrieveOrderCreate()
                .pipe(finalize(() => this.loaderService.stopLoader(this.RETRIEVE_ORDER_CREATE_LOADER_ID)))
                .subscribe({
                    next: certificateOrderCreateSession => {
                        this.certificateOrderCreate = {...certificateOrderCreateSession.orderCreate};
                        console.log('---| Retrieved Saved Order Create', this.certificateOrderCreate);
                    },
                    error: error => {
                        this.certificateOrderCreate = undefined;
                        this.retrieveOrderCreateError = error;
                    }
                })
        );
    }


    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    private goTo(step: LCECertificateOrderProcessStep): void {
        XSAssert.notEmpty(step, 'step');
        LOG().debug(`---| currentStep: ${this.currentStep} || step: ${step}`);
        switch (step) {
            case LCECertificateOrderProcessStep.CREATE:
                this.handleStepCreate();
                break;
            case LCECertificateOrderProcessStep.CONFIRMATION:
                this.handleStepConfirmation();
                break;
        }
    }

    private handleStepConfirmation(): void {
        this.enableStep(LCECertificateOrderProcessStep.CREATE, false);
        this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, true);
        this.certificateOrderProcessService.setMFOCurrentStep(LCECertificateOrderProcessStep.CONFIRMATION);
    }

    private handleStepCreate(): void {
        this.enableStep(LCECertificateOrderProcessStep.CREATE, true);
        this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, false);
        if (!this.firstInitialization) this.certificateOrderProcessService.setShowPendingRequest(false);

        this.certificateOrderProcessService.setMFOCurrentStep(LCECertificateOrderProcessStep.CREATE);
        if (!XSUtils.isEmpty(this.orderCreateSessionID)) this.retrieveOrderCreate();
    }

    private enableStep(step: LCECertificateOrderProcessStep, state: boolean): void {
        this.stepModel.find(item => item.id === step).disabled = !state;
    }

}
