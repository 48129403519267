import { Injectable } from '@angular/core';
import { XSPKResourceAuditFullService } from '@xs/base';
import { Observable } from 'rxjs';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';
import { LCEFacilityWorker, LCEFacilityWorkerCreate, LCEFacilityWorkerPartial, LCEFacilityWorkerSearch } from '../domain/lce-facility-worker';
import { LCEHttpClientService } from './lce-http-client.service';
import { LCEValidatorUtils } from '../utils/lce-validator-utils';

@Injectable({ providedIn: 'root' })
export class LCEFacilityWorkerService extends XSPKResourceAuditFullService<LCEFacilityWorker, LCEFacilityWorkerPartial, LCEFacilityWorkerSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.facilityWorker.index, 'facilityWorker');
    }

    public create(facilityWorkerCreate: LCEFacilityWorkerCreate): Observable<LCEFacilityWorker> {
        LCEValidatorUtils.validateFacilityWorkerCreate(facilityWorkerCreate);
        return this.httpClientService.post<LCEFacilityWorker>(this.buildEndpoint(), facilityWorkerCreate);
    }
}
