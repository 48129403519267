import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEUserEmployee, LCEUserEmployeeCreate, LCEUserEmployeeMainRole, LCEUserEmployeePartial, LCEUserEmployeePosition, LCEUserEmployeeSearch, LCEUserType} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUserAccountHandler} from '@lce/mock/core/handlers/user/lce-mock-user-account-handler';
import {LCEMockUserFinder} from '@lce/mock/core/handlers/user/lce-mock-user-finder';
import {LCEMockUserHandler} from '@lce/mock/core/handlers/user/lce-mock-user-handler';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSPKDTOStats, XSUtils} from '@xs/base';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.employees';

@Injectable()
export class LCEMockUserEmployeeHandler extends LCEMockUserHandler<LCEUserEmployee, LCEUserEmployeePartial, LCEUserEmployeeSearch> {

    public static EMPLOYEE_STORAGE: Map<string, LCEUserEmployee> = new Map<string, LCEUserEmployee>();

    constructor(
        protected contextService: LCECoreContextService,
        protected mockMunicipalityHandler: LCEMockMunicipalityHandler,
        protected mockUserAccountHandler: LCEMockUserAccountHandler) {
        super(contextService, mockMunicipalityHandler, mockUserAccountHandler, DATASET_BASE_ID, LCE_CORE_ENDPOINTS.employees.index);
        this.mockDataArray = [];
        LCEMockUserFinder.USER_HANDLER_MAP.set(LCEUserType.EMPLOYEE, this);
    }

    public getStorage(): Map<string, LCEUserEmployee> {
        return LCEMockUserEmployeeHandler.EMPLOYEE_STORAGE;
    }

    public toPartial(employee: LCEUserEmployee): LCEUserEmployeePartial {
        return {
            ...this.baseToPartial(employee),
            mainRole: employee.mainRole,
            position: employee.position
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.employees.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.getQueryPredicates()}),
            // Search
            this.buildSearchMockData({
                queryPredicates: this.getQueryPredicates(),
                predicates: this.getSearchPredicates()
            }),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: XSUtils.randomInteger(0, 100),
                    active: XSUtils.randomInteger(0, 60),
                    inactive: XSUtils.randomInteger(0, 10),
                    deleted: XSUtils.randomInteger(0, 5)
                } as XSPKDTOStats
            }
        ];
    }

    protected buildCode(): string {
        return 'LCE-EMP-' + XSUtils.randomDigits(6);
    }

    private createResponseData(body: any): LCEUserEmployee {
        const employeeCreate: LCEUserEmployeeCreate = body as LCEUserEmployeeCreate;
        const employee: LCEUserEmployee = {
            ...this.baseCreate(employeeCreate),
            type: LCEUserType.EMPLOYEE,
            mainRole: employeeCreate.mainRole,
            position: employeeCreate.position
        };

        LCEMockUserEmployeeHandler.EMPLOYEE_STORAGE.set(employee.id, employee);
        this.addUserMockData(employee.id);

        return employee;
    }

    private buildStorage(n: number = 10): void {
        for (let $i = 0; $i < n; $i++) {
            const employee = this.buildRandomEmployee();
            LCEMockUserEmployeeHandler.EMPLOYEE_STORAGE.set(employee.id, employee);
            this.addUserMockData(employee.id);
        }
    }

    private buildRandomEmployee(): LCEUserEmployee {
        return {
            ...this.baseRandomUser(),
            type: LCEUserType.EMPLOYEE,
            mainRole: XSUtils.randomElement([
                LCEUserEmployeeMainRole.ADMIN,
                LCEUserEmployeeMainRole.AGENT,
                LCEUserEmployeeMainRole.EMPLOYEE,
                LCEUserEmployeeMainRole.GUEST,
                LCEUserEmployeeMainRole.MANAGER,
                LCEUserEmployeeMainRole.ROOT,
                LCEUserEmployeeMainRole.SUPER_ADMIN,
                LCEUserEmployeeMainRole.SYSTEM
            ]),
            position: XSUtils.randomElement([
                LCEUserEmployeePosition.CEO,
                LCEUserEmployeePosition.DISPATCHER,
                LCEUserEmployeePosition.FIELD_AGENT,
                LCEUserEmployeePosition.GUEST,
                LCEUserEmployeePosition.OFFICE_AGENT,
                LCEUserEmployeePosition.QUALITY_ASSURANCE_AGENT,
                LCEUserEmployeePosition.QUALITY_ASSURANCE_MANAGER,
                LCEUserEmployeePosition.TECHNICAL_DIRECTOR
            ])
        };
    }
}
