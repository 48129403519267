import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCEUserPartial, LCEUserType} from '@lce/core';
import {XS_STR_SPACE, XSAssert, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSTranslationService} from '@xs/common';
import {XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC, XSIvarAvatarSize, XSIvarAvatarSizeStr, XSIvarAvatarType, XSTooltip} from '@xs/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-avatar',
    template: `
        <xs-ivar
                xsImageError
                [disabled]="disabled"
                [styleClass]="styleClass"
                [avatar]="avatar"
                [avatarType]="avatarType"
                avatarStyleClass="xs-border-discrete {{ avatarStyleClass }}"
                avatarLabelStyleClass="xs-color-primary-imp {{ avatarLabelStyleClass }}"
                avatarIconStyleClass="xs-color-secondary-imp"
                [avatarSize]="avatarSize"
                [avatarRounded]="!isCustomer()"
                [showLines]="false"
                [showAvatarBadge]="showStatus"
                [avatarBadgeValue]="STR_SPACE"
                avatarBadgePosition="bottom-right"
                avatarBadgeStyleClass="xs-min-width-10-imp xs-height-10-imp xs-bottom-2-imp xs-right-5-imp {{ BASE_STYLE_CLASS_BG_COLOR_USER_STATUS + data.status }}"
                [avatarTooltip]="avatarStatusTooltip"
                [avatarShowTooltip]="showTooltip"
                [avatarClickable]="clickable"
                (avatarClick)="onAvatarClick($event)"
                [avatarErrorImageSrc]="IMAGE_NOT_FOUND_USER_AVATAR_SRC">
        </xs-ivar>
    `
})
export class LCEUserAvatarComponent implements OnInit, OnChanges {

    readonly IMAGE_NOT_FOUND_USER_AVATAR_SRC = XS_IMAGE_NOT_FOUND_USER_AVATAR_SRC;
    readonly STR_SPACE = XS_STR_SPACE;
    readonly ICON = LCE_SHARED_ICON;
    readonly BASE_STYLE_CLASS_BG_COLOR_USER_STATUS: string = 'lce-user-status-background-';

    @Input() styleClass?: string;
    @Input() avatarStyleClass?: string;
    @Input() disabled?: boolean;
    @Input() data: LCEUserPartial;
    @Input() clickable?: boolean;
    @Input() avatarSize?: XSIvarAvatarSize | XSIvarAvatarSizeStr;
    @Input() showStatus?: boolean;
    @Input() showTooltip?: boolean;
    @Output() avatarClickEvent = new EventEmitter<Event>();

    avatar: string;
    avatarType: XSIvarAvatarType;
    avatarStatusTooltip: XSTooltip | undefined;
    avatarLabelStyleClass: string = '';

    private readonly TR_BASE_USER_STATUS: string = 'lce.core.user.status.';

    constructor(private translationService: XSTranslationService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.handleDefaults();
        this.handleAvatar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) this.handleAvatar();
        if (!XSUtils.isEmpty(changes.avatarSize) && !changes.avatarSize!.isFirstChange()) this.handleAvatar();
    }

    public onAvatarClick(event: Event) {
        if (this.disabled === true || !this.clickable) {
            event.stopImmediatePropagation();
            return;
        }
        this.avatarClickEvent.emit(event);
    }

    public getAvatarStatusTooltip(): XSTooltip | undefined {
        if (XSUtils.isEmpty(this.data.status)) return undefined;
        const personFullName = XSCommonDomainUtils.getPersonFullName(this.data.name!);
        const userStatusText = this.translationService.translate(this.TR_BASE_USER_STATUS + this.data.status);
        return {text: personFullName + ' : ' + userStatusText};
    }

    public isCustomer(): boolean {
        return this.data.type === LCEUserType.CUSTOMER;
    }

    private handleAvatar(): void {
        if (this.data.type === LCEUserType.CUSTOMER) {
            this.avatarType = XSIvarAvatarType.ICON;
            this.avatar = this.ICON.customer;
            return;
        } else {
            this.avatarType = XSUtils.isEmpty(this.data.profileImage) ? XSIvarAvatarType.LABEL : XSIvarAvatarType.IMAGE;
            if (XSUtils.isEmpty(this.data.profileImage)) {
                this.avatar = XSCommonDomainUtils.getPersonInitials(this.data.name!);
                if (this.avatarSize === 'small') {
                    this.avatarLabelStyleClass = 'xs-font-size-intermediate-imp';
                }
                if (this.avatar.length > 2) {
                    this.avatar = this.avatar[0] + this.avatar[this.avatar.length - 1];
                }
            } else {
                this.avatar = this.data.profileImage!;
            }
        }
        this.avatarStatusTooltip = this.getAvatarStatusTooltip();
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.clickable)) this.clickable = this.avatarClickEvent.observers.length > 0;
        if (XSUtils.isNull(this.avatarSize)) this.avatarSize = XSIvarAvatarSize.MEDIUM;
        if (XSUtils.isEmpty(this.data.status)) this.showStatus = false;
        if (XSUtils.isNull(this.showStatus)) {
            this.showStatus = this.avatarSize == 'small' ? false : true;
        }
    }
}
