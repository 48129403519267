import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCECertificateOrderDeliveryAssignmentService, LCECertificateOrderDeliveryAssignmentStatus, LCEUserDeliveryManPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {Subscription} from 'rxjs';
import {XSLoaderService} from '@xs/common';
import {finalize} from 'rxjs/operators';

@Component({selector: 'lce-delivery-man-assignment', templateUrl: './lce-delivery-man-assignment.component.html'})
export class LCEDeliveryManAssignmentComponent implements OnInit, OnDestroy {

    readonly LOADER_ID = XSUtils.uuid();

    readonly ICON_REFRESH = LCE_SHARED_ICON.refresh;

    @Input() styleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showRefreshButton?: boolean;

    @Input() data: LCEUserDeliveryManPartial;

    @Input() deliveryAssignmentNumber?: number;

    @Output() refreshEvent = new EventEmitter<void>();
    @Output() orderNumberClickEvent = new EventEmitter<LCEUserDeliveryManPartial>();
    @Output() clickEvent = new EventEmitter<LCEUserDeliveryManPartial>();

    error: any;

    orderNumberClickable: boolean;

    private subscription = new Subscription();

    constructor(
        private certificateOrderDeliveryAssignmentService: LCECertificateOrderDeliveryAssignmentService,
        private loaderService: XSLoaderService
    ) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data);
        this.orderNumberClickable = this.orderNumberClickEvent.observers.length > 0;

        if (XSUtils.isNull(this.showRefreshButton)) this.showRefreshButton = true;
        if (XSUtils.isNull(this.deliveryAssignmentNumber)) this.retrieveOrderNumber();

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public refresh(): void {
        this.retrieveOrderNumber();
    }

    private retrieveOrderNumber(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.deliveryAssignmentNumber = 0;
        
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService.search({
                paginationPage: 0,
                paginationSize: 10,
                statuses: [LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED],
                deliveryManCodes: [this.data.code]
            })
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe(
                    {
                        next: (result) => {
                            this.deliveryAssignmentNumber = result.data.length;
                        },
                        error: error => this.error = error
                    }
                )
        );
    }
}