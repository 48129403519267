import { Component, Input, OnInit } from '@angular/core';
import { LCECertificateOrderStatusPipe, LCEResourceType } from '@lce/core';
import { XSAssert } from '@xs/base';
import { XSTranslationService } from '@xs/common';

interface LCEEventDataRow {
    line1: string;
    line2: string;
}

@Component({
    selector: 'lce-event-data-row',
    templateUrl: './lce-event-data-row.component.html',
    providers: [LCECertificateOrderStatusPipe],
})
export class LCEEventDataRowComponent implements OnInit {
    @Input() styleClass?: string;
    @Input() line1StyleClass?: string;
    @Input() line2StyleClass?: string;

    @Input() resourceType: LCEResourceType;

    @Input() data: {};

    eventDataRow: LCEEventDataRow;

    constructor(private lceCertificateOrderStatus: LCECertificateOrderStatusPipe, private translationService: XSTranslationService) {}

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.eventDataRow = this.buildEventDataRow(this.resourceType, this.data);
    }

    private buildEventDataRow(resourceType: LCEResourceType, resourceData: any): LCEEventDataRow {
        const currentLanguage = this.translationService.getCurrentLanguage();
        let eDataRow: LCEEventDataRow = undefined!;
        switch (resourceType) {
            case LCEResourceType.DISTRICT:
                eDataRow = this.buildDataRow(resourceData.fullName, resourceData.code);
                break;
            case LCEResourceType.MUNICIPALITY:
                eDataRow = this.buildDataRow(resourceData.fullName, resourceData.code);
                break;
            case LCEResourceType.FACILITY:
                eDataRow = this.buildDataRow(resourceData.fullName, resourceData.code);
                break;
            case LCEResourceType.SERVICE_POINT:
                eDataRow = this.buildDataRow(resourceData.fullName, resourceData.code);
                break;
            case LCEResourceType.CERTIFICATE_ORDER:
                eDataRow = this.buildDataRow(
                    this.translationService.translateKey('lce.core.certificateTypeMedium.' + resourceData.certificateType),
                    this.lceCertificateOrderStatus.transform(resourceData.status)
                );
                break;
            //TODO: CERTIFICATE
            case LCEResourceType.BIRTH_DECLARATION:
                eDataRow = this.buildDataRow(`No ${resourceData.birthNumber}`, this.translationService.translateKey('xs.common.gender.' + resourceData.newBornGender));
                break;
            case LCEResourceType.SUGGESTION:
                eDataRow = this.buildDataRow(this.translationService.translateKey('lce.core.suggestionStatus.' + resourceData.status), this.getSuggestionDataSecondLine(resourceData));
                break;
            case LCEResourceType.NEWS_ARTICLE:
                eDataRow = this.buildDataRow(resourceData['title' + currentLanguage.toString().toUpperCase()], this.translationService.translateKey('lce.shared.news.status.' + resourceData.status)); // TODO: Status a translater
                break;
            case LCEResourceType.SMARTCITY_ARTICLE:
                eDataRow = this.buildDataRow(
                    resourceData['title' + currentLanguage.toString().toUpperCase()],
                    this.translationService.translateKey('lce.shared.smartcity.status.' + resourceData.status)
                ); // TODO: Status a translater
                break;
            //TODO:   FACILITY_WORKER     CUSTOMER        MUNICIPAL_EMPLOYEE      EMPLOYEE
        }
        return eDataRow;
    }

    private getSuggestionDataSecondLine(resourceData: any): string {
        if (resourceData.anonymous === true) return this.translationService.translateKey('lce.core.label.anonymous');
        else return resourceData.customerCode;
    }

    private buildDataRow(line1: any, line2: any): LCEEventDataRow {
        return { line1: line1, line2: line2 };
    }
}
