import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {
    LCECertificateOrder,
    LCECertificateOrderDeliveryAssignmentRequest,
    LCECertificateOrderDeliveryAssignmentResponse,
    LCECertificateOrderDeliveryAssignmentService,
    LCECertificateOrderDeliveryUnassignmentRequest
} from '@lce/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {TieredMenu} from 'primeng/tieredmenu';
import {XSMenuItem} from '@xs/core';
import {XSTranslationService} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({selector: 'lce-certificate-order-delivery-actions', templateUrl: './lce-certificate-order-delivery-actions.component.html'})
export class LCECertificateOrderDeliveryActionsComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() buttonStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() spaceBetween?: string;

    @Input() data: LCECertificateOrder;

    @Output() assignedEvent = new EventEmitter<LCECertificateOrderDeliveryAssignmentResponse>();
    @Output() removedAssignmentEvent = new EventEmitter<LCECertificateOrderDeliveryAssignmentResponse>();
    @Output() errorEvent = new EventEmitter<any>();

    @ViewChild('actionMenu') actionMenu: TieredMenu;
    @ViewChild('assignOverlay') assignOverlay: OverlayPanel;
    @ViewChild('unassignOverlay') unassignOverlay: OverlayPanel;

    actionMenuItems: XSMenuItem[] = [];

    loading = {
        assign: false,
        removeAssignment: false,
        menu: false
    };

    assignError: any;
    unassignError: any;

    notifyDeliveryMan: boolean;

    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private certificateOrderDeliveryAssignmentService: LCECertificateOrderDeliveryAssignmentService) {
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.buildActionMenuItems()));
    }

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isEmpty(this.spaceBetween)) this.spaceBetween = '40px';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onCancelAssignmentRequest(): void {
        this.notifyDeliveryMan = false;
        this.assignOverlay.hide();
    }

    public onAssign(event: Event): void {
        this.assignOverlay.toggle(event);
    }

    public onUnassign(event: Event): void {
        this.unassignOverlay.toggle(event);
    }

    public canShowAssignmentActions(): boolean {
        return !XSUtils.isEmpty(this.data.delivery);
    }

    public canShowAssignAction(): boolean {
        return XSUtils.isEmpty(this.data.delivery.assignment) || (!XSUtils.isEmpty(this.data.delivery.assignment) && !XSUtils.isEmpty(this.data.delivery.assignment!.unassignedOn));
    }

    public canShowRemoveAssignmentAction(): boolean {
        return (!XSUtils.isEmpty(this.data.delivery.assignment) && XSUtils.isEmpty(this.data.delivery.assignment!.unassignedOn));
    }

    public canEnableAssignAction(): boolean {
        return true;
    }

    public canEnableRemoveAssignmentAction(): boolean {
        return true;
    }

    public canShowMenu(): boolean {
        return true;
    }

    public onMenuClick(event: any): void {
        this.buildActionMenuItems();
        this.actionMenu.toggle(event);
    }

    public buildActionMenuItems(): void {
        this.actionMenuItems = [
            {
                trLabel: 'lce.shared.deliveryAction.notifyDeliveryManBySMS',
                command: () => this.notifyDeliveryManBySMS()
            }
        ];
        this.translationService.translateItems(this.actionMenuItems);
    }

    public assignOrderToDeliveryMan(request: LCECertificateOrderDeliveryAssignmentRequest): void {
        const assignmentRequest: LCECertificateOrderDeliveryAssignmentRequest = {
            ...request,
            certificateOrderID: this.data.id!
        };

        this.loading.assign = true;
        this.assignError = undefined;
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService.assignOne(assignmentRequest)
                .pipe(finalize(() => this.loading.assign = false))
                .subscribe(
                    {
                        next: (response) => {
                            this.assignedEvent.emit(response);
                            this.notifyDeliveryMan = false;
                            this.assignOverlay.hide();
                        },
                        error: (error: any) => this.assignError = error
                    }
                )
        );

    }

    public removeAssignment(request: LCECertificateOrderDeliveryUnassignmentRequest): void {
        const unassignmentRequest: LCECertificateOrderDeliveryUnassignmentRequest = {
            ...request,
            certificateOrderID: this.data.id
        };

        this.loading.removeAssignment = true;
        this.subscription.add(
            this.certificateOrderDeliveryAssignmentService.unassignOne(unassignmentRequest)
                .pipe(finalize(() => this.loading.removeAssignment = false))
                .subscribe(
                    {
                        next: (response) => {
                            this.removedAssignmentEvent.emit(response);
                        },
                        error: (error: any) => this.errorEvent.emit(error)
                    }
                )
        );
    }

    private notifyDeliveryManBySMS(): void {
        console.log('notify DeliveryMan by SMS');
    }


}
