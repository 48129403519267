import { Injectable } from '@angular/core';
import { XSAssert, XSPKResourceAuditFullService, XSUtils } from '@xs/base';
import { Observable, of } from 'rxjs';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';
import { LCEBirthDeclaration, LCEBirthDeclarationCreate, LCEBirthDeclarationPartial, LCEBirthDeclarationSearch } from '../domain/lce-birth-declaration';
import { LCEHttpClientService } from './lce-http-client.service';
import FIRST_NAME_DATA from '../assets/data/lce-ivorian-firstName.json';
import LAST_NAME_DATA from '../assets/data/lce-ivorian-lastName.json';

@Injectable({ providedIn: 'root' })
export class LCEBirthDeclarationService extends XSPKResourceAuditFullService<LCEBirthDeclaration, LCEBirthDeclarationPartial, LCEBirthDeclarationSearch> {

    private firstNames: string[];
    private lastNames: string[];

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.birthDeclarations.index, 'birthDeclaration');
        this.firstNames = XSUtils.clone(FIRST_NAME_DATA.sort((a, b) => a.localeCompare(b)));
        this.lastNames = XSUtils.clone(LAST_NAME_DATA.sort((a, b) => a.localeCompare(b)));
    }

    public autocompleteLastName(query: string | undefined, limit: number = 5): Observable<string[]> {
        if (XSUtils.isEmpty(query)) {
            return of(this.lastNames.slice(0, limit));
        }
        return of(
            this.lastNames
                .filter(name => name.trim().toLowerCase().includes(query!.trim().toLowerCase()))
                .slice(0, limit)
        );
    }

    public autocompleteFirstName(query: string | undefined, limit: number = 5): Observable<string[]> {
        if (XSUtils.isEmpty(query)) {
            return of(this.firstNames.slice(0, limit));
        }
        return of(
            this.firstNames
                .filter(name => name.trim().toLowerCase().includes(query!.trim().toLowerCase()))
                .slice(0, limit)
        );
    }

    public create(birthDeclarationCreate: LCEBirthDeclarationCreate): Observable<LCEBirthDeclaration> {
        XSAssert.notEmpty(birthDeclarationCreate, 'birthDeclarationCreate');
        return this.httpClientService.post<LCEBirthDeclaration>(this.buildEndpoint(), birthDeclarationCreate);
    }
}
