import {Injectable} from '@angular/core';
import {Howl} from 'howler';
import {BehaviorSubject} from 'rxjs';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLocalStorageService} from '@xs/core';
import {LCECertificateOrderEventName, LCEEventType, LCEResourceType, LCESuggestionEventName} from '@lce/core';

export interface LCESoundAlertEvent {
    eventType: LCEEventType;
    resourceType?: LCEResourceType;
    eventName?: string;
    enabled: boolean;
}

export interface LCESoundAlertEventArg {
    eventType: LCEEventType;
    resourceType?: LCEResourceType;
    eventName?: string;
}

@Injectable({providedIn: 'root'})
export class LCESoundAlertService {

    private readonly DEFAULT_ALERT_SOUND_FILE = 'assets/sounds/alert.wav';

    private readonly STORAGE_KEY = 'sound.alert';

    private SOUND_FILE_MAP: Map<string, string> = new Map<string, string>([
        [this.toSKey(LCEEventType.RESOURCE, LCEResourceType.CERTIFICATE_ORDER, LCECertificateOrderEventName.NEW_ORDER), this.DEFAULT_ALERT_SOUND_FILE],
        [this.toSKey(LCEEventType.RESOURCE, LCEResourceType.SUGGESTION, LCESuggestionEventName.NEW_SUGGESTION), this.DEFAULT_ALERT_SOUND_FILE]
    ]);

    private soundAlertMap: Map<string, boolean> = new Map<string, boolean>();

    private soundAlertSubject = new BehaviorSubject<LCESoundAlertEvent>(undefined!);
    onSoundAlert = this.soundAlertSubject.asObservable();

    constructor(private localStorageService: XSLocalStorageService) {
        this.initialize();
    }

    public isEventSoundAlertEnabled(arg: LCESoundAlertEventArg): boolean {
        const sKey = this.toKey(arg);
        this.check(sKey);
        return this.soundAlertMap.get(sKey)!;
    }

    public enableSoundAlertGlobally(state: boolean): void {

    }

    public setEventSoundAlertEnabled(state: boolean, arg: LCESoundAlertEventArg): void {
        const sKey = this.toKey(arg);
        this.check(sKey);
        XSAssert.notNull(state, 'state');
        this.soundAlertMap.set(sKey, state);
        this.localStorageService.setObjectProperty(this.STORAGE_KEY, sKey, state);
        this.soundAlertSubject.next({eventType: arg.eventType, resourceType: arg.resourceType, eventName: arg.eventName, enabled: state});
    }

    public playEventSoundAlert(arg: LCESoundAlertEventArg): void {
        const sKey = this.toKey(arg);
        this.check(sKey);
        this.playSoundAlert(this.SOUND_FILE_MAP.get(sKey)!);
    }

    public playDefaultSoundAlert(): void {
        this.playSoundAlert(this.DEFAULT_ALERT_SOUND_FILE);
    }

    // For More Sound Files : https://mixkit.co/free-sound-effects/notification/
    public playSoundAlert(soundFile: string): void {
        let sound = new Howl({src: [soundFile]});
        sound.play();
    }

    private check(sKey: string): void {
        XSAssert.notEmpty(sKey, 'key');
        if (!this.soundAlertMap.has(sKey)) {
            throw new Error(`event key [${sKey}] not found.`);
        }
    }

    private initialize(): void {
        this.SOUND_FILE_MAP.forEach((soundFile: string, key: string) => {
            this.soundAlertMap.set(key, this.getState(key));
        });
    }

    private getState(key: string): boolean {
        const enabled: boolean = this.localStorageService.getObjectProperty(this.STORAGE_KEY, key);
        return XSUtils.isNull(enabled) ? true : enabled;
    }

    private toSKey(eventType: LCEEventType, resourceType?: LCEResourceType, eventName?: string): string {
        return this.toKey({eventType: eventType, resourceType: resourceType, eventName: eventName});
    }

    private toKey(arg: LCESoundAlertEventArg): string {
        XSAssert.notEmpty(arg, 'arg');
        let str = arg.eventType.toString();
        if (!XSUtils.isEmpty(arg.resourceType)) {
            str += '.' + arg.resourceType!.toString();
        }
        if (!XSUtils.isEmpty(arg.eventName)) {
            str += '.' + arg.eventName;
        }
        return str;
    }
}
