import {
    LCE_USER_SYSTEM_DHL_ID,
    LCE_USER_SYSTEM_ID,
    LCE_USER_SYSTEM_UPS_ID,
    LCERequestorType,
    LCEUserAccountStatus,
    LCEUserCustomer,
    LCEUserCustomerPartial,
    LCEUserMunicipalEmployee,
    LCEUserMunicipalEmployeeMainRole,
    LCEUserMunicipalEmployeePartial,
    LCEUserMunicipalEmployeePosition,
    LCEUserPartial,
    LCEUserStatus,
    LCEUserType,
    LCEUserUtils
} from '@lce/core';
import {XS_LOREM_IPSUM, XSGender, XSLanguage, XSPKDTOAuditFullState, XSTemporalUtils} from '@xs/base';
import {FACILITY_PARTIAL_YOPOUGON_TOWN_HALL, MUNICIPALITY_PARTIAL_YOPOUGON} from './lce-mock-common';
import {LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA, LCE_MOCK_CONTACT_PERSON_JANE_DOE, LCE_MOCK_CONTACT_PERSON_TONY_STARK, LCE_MOCK_COORDINATE_PHARMACIE_ANALYA} from './lce-mock-various-data';

// -----------------------------------------------------------------------------------------------------------
// === User System ===
// -----------------------------------------------------------------------------------------------------------

// === User System ===
// The user system will be returned by the server. The name will be translated on the server side, depending
// on the language of the caller. On the server side it will be a constant object for example defined in French and English.
export const LCE_MBO_MOCK_USER_SYSTEM: LCEUserPartial = {
    id: LCE_USER_SYSTEM_ID,
    type: LCEUserType.SYSTEM,
    code: 'LCE-USR-SYS-0000',
    name: {firstName: 'LCE', lastName: 'System'},
    primaryPhoneNumber: '+2250707972352',
    email: 'user.system@iro-xs.com'
};

// === DHL ===
export const LCE_MOCK_USER_SYSTEM_DHL: LCEUserPartial = {
    id: LCE_USER_SYSTEM_DHL_ID,
    type: LCEUserType.SYSTEM,
    code: 'LCE-USR-DHL-0000',
    name: {firstName: 'DHL', lastName: 'User'},
    primaryPhoneNumber: '+2250707972352',
    email: 'user.system@iro-xs.com'
};

// === UPS ===
export const LCE_MOCK_USER_SYSTEM_UPS: LCEUserPartial = {
    id: LCE_USER_SYSTEM_UPS_ID,
    type: LCEUserType.SYSTEM,
    code: 'LCE-USR-UPS-0000',
    name: {firstName: 'UPS', lastName: 'User'},
    primaryPhoneNumber: '+2250707972352',
    email: 'user.system@iro-xs.com'
};

// -----------------------------------------------------------------------------------------------------------
// === Municipal Employees ===
// -----------------------------------------------------------------------------------------------------------

// === Vamoussa Coulibaly ===
export const LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY: LCEUserMunicipalEmployee = {
    id: 'user-id-vamoussa-coulibaly',
    createdOn: '2022-03-25 23:59:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.MUNICIPAL_EMPLOYEE,
    code: 'LCE-USR-VAC-1132',
    name: {firstName: 'vamoussa', lastName: 'coulibaly'},
    gender: XSGender.MALE,
    email: 'vamoussa.coulibaly@yopougon.ci',
    primaryPhoneNumber: '+2250705776522',
    secondaryPhoneNumber: '+2250707972351',
    whatsAppPhoneNumber: undefined,
    passwordUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {days: 5}).toISOString(),
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1990-03-25',
    language: XSLanguage.FRENCH,
    profileImage: 'assets/mock/images/vamoussa.coulibaly.jpeg',
    note: XS_LOREM_IPSUM.medium,

    mainRole: LCEUserMunicipalEmployeeMainRole.ADMIN,
    position: LCEUserMunicipalEmployeePosition.DSI,

    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    accountStatusUpdatedBy: LCE_MBO_MOCK_USER_SYSTEM,

    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),

    facility: FACILITY_PARTIAL_YOPOUGON_TOWN_HALL,

    emergencyContactPerson: LCE_MOCK_CONTACT_PERSON_JANE_DOE
};

// === Steve Jobs ===
export const LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS: LCEUserMunicipalEmployee = {
    id: 'user-id-steve-jobs',
    createdOn: '2022-03-25 23:59:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.MUNICIPAL_EMPLOYEE,
    code: 'LCE-USR-SEJ-4534',
    name: {firstName: 'steve', lastName: 'jobs'},
    gender: XSGender.MALE,
    email: 'steve.jobs@yopougon.ci',
    primaryPhoneNumber: '+2250705776528',
    secondaryPhoneNumber: '+2250707972359',
    whatsAppPhoneNumber: undefined,
    passwordUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {days: 5}).toISOString(),
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1990-03-25',
    language: XSLanguage.FRENCH,
    profileImage: 'assets/mock/images/steve.jobs.jpeg',
    note: XS_LOREM_IPSUM.medium,

    mainRole: LCEUserMunicipalEmployeeMainRole.MANAGER,
    position: LCEUserMunicipalEmployeePosition.IT_AGENT,

    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    accountStatusUpdatedBy: LCE_MBO_MOCK_USER_SYSTEM,

    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),

    facility: FACILITY_PARTIAL_YOPOUGON_TOWN_HALL,

    emergencyContactPerson: LCE_MOCK_CONTACT_PERSON_TONY_STARK
};

// === Elon Musk ===
export const LCE_MBO_MOCK_USER_RECORD_ELON_MUSK: LCEUserMunicipalEmployee = {
    id: 'user-id-elon-musk',
    createdOn: '2022-03-25 23:59:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.MUNICIPAL_EMPLOYEE,
    code: 'LCE-USR-ENM-9876',
    name: {firstName: 'elon', lastName: 'musk'},
    gender: XSGender.MALE,
    email: 'elon.musk@yopougon.ci',
    primaryPhoneNumber: '+2250705776520',
    secondaryPhoneNumber: '+2250707972354',
    whatsAppPhoneNumber: undefined,
    passwordUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {days: 5}).toISOString(),
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1990-03-25',
    language: XSLanguage.FRENCH,
    profileImage: 'assets/mock/images/elon.musk.jpeg',
    note: XS_LOREM_IPSUM.medium,

    mainRole: LCEUserMunicipalEmployeeMainRole.AGENT,
    position: LCEUserMunicipalEmployeePosition.IT_AGENT,

    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    accountStatusUpdatedBy: LCE_MBO_MOCK_USER_SYSTEM,

    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),

    facility: FACILITY_PARTIAL_YOPOUGON_TOWN_HALL,

    emergencyContactPerson: LCE_MOCK_CONTACT_PERSON_JANE_DOE
};

// ---------------------------------------------------------------------------------------------------------------------

// === Vamoussa Coulibaly ===
export const LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY);
export const LCE_MIA_MOCK_USER_VAMOUSSA_COULIBALY: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY);

// === Steve Jobs ===
export const LCE_MBO_MOCK_USER_STEVE_JOBS: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS);
export const LCE_MIA_MOCK_USER_STEVE_JOBS: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS);

// === Elon Musk ===
export const LCE_MBO_MOCK_USER_ELON_MUSK: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_ELON_MUSK);
export const LCE_MIA_MOCK_USER_ELON_MUSK: LCEUserMunicipalEmployeePartial = LCEUserUtils.toMunicipalEmployeePartial(LCE_MBO_MOCK_USER_RECORD_ELON_MUSK);

// ---------------------------------------------------------------------------------------------------------------------
// === Customers ===
// ---------------------------------------------------------------------------------------------------------------------

// === Victor Hugo ===
export const LCE_MBO_MOCK_CUSTOMER_RECORD_VICTOR_HUGO: LCEUserCustomer = {
    id: 'user-id-victor-hugo',
    createdOn: '2023-08-06 23:59:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.CUSTOMER,
    code: 'LCE-CUS-VRH-3243',
    name: {firstName: 'Victor', lastName: 'Hugo'},
    gender: XSGender.MALE,
    email: 'victor.hugo@gmail.com',
    primaryPhoneNumber: '+2250705776598',
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1992-11-20',
    language: XSLanguage.FRENCH,
    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),
    registered: true,
    registeredOn: XSTemporalUtils.dateMinus(new Date(), {years: 1}).toISOString(),
    createdFrom: LCERequestorType.PUBLIC_MOBILE_APP,
    registeredFrom: LCERequestorType.PUBLIC_MOBILE_APP
};

// === Angelina Jolie ===
export const LCE_MBO_MOCK_CUSTOMER_RECORD_ANGELINA_JOLIE: LCEUserCustomer = {
    id: 'user-id-angelina-jolie',
    createdOn: '2023-12-24 13:11:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.CUSTOMER,
    code: 'LCE-CUS-AAJ-1188',
    name: {firstName: 'Angelina', lastName: 'Jolie'},
    gender: XSGender.FEMALE,
    email: 'angelina.jolie@gmail.com',
    primaryPhoneNumber: '+2250705776544',
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1995-02-19',
    language: XSLanguage.FRENCH,
    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),
    registered: true,
    registeredOn: XSTemporalUtils.dateMinus(new Date(), {months: 6}).toISOString(),
    createdFrom: LCERequestorType.PUBLIC_MOBILE_APP,
    registeredFrom: LCERequestorType.PUBLIC_MOBILE_APP
};

// === Brad Pitt ===
export const LCE_MBO_MOCK_CUSTOMER_RECORD_BRAD_PITT: LCEUserCustomer = {
    id: 'user-id-brad-pitt',
    createdOn: '2023-12-24 13:11:00',
    createdBy: LCE_MBO_MOCK_USER_SYSTEM,
    state: XSPKDTOAuditFullState.ACTIVE,
    type: LCEUserType.CUSTOMER,
    code: 'LCE-CUS-BDT-0076',
    name: {firstName: 'Brad', lastName: 'Pitt'},
    gender: XSGender.MALE,
    email: 'brad.pitt@gmail.com',
    primaryPhoneNumber: '+2250705776517',
    address: LCE_MOCK_ADDRESS_UNSTRUCTURED_PHARMACIE_ANALYA,
    birthDate: '1985-05-01',
    language: XSLanguage.FRENCH,
    status: LCEUserStatus.ONLINE,
    accountStatus: LCEUserAccountStatus.ACTIVE,
    accountStatusUpdatedOn: XSTemporalUtils.dateMinus(new Date(), {hours: 1}).toISOString(),
    municipalityOfResidence: MUNICIPALITY_PARTIAL_YOPOUGON,
    currentCoordinate: LCE_MOCK_COORDINATE_PHARMACIE_ANALYA,
    lastLoginOn: XSTemporalUtils.dateMinus(new Date(), {minutes: 5}).toISOString(),
    lastActivity: new Date().toISOString(),
    registered: true,
    registeredOn: XSTemporalUtils.dateMinus(new Date(), {months: 3}).toISOString(),
    createdFrom: LCERequestorType.PUBLIC_MOBILE_APP,
    registeredFrom: LCERequestorType.PUBLIC_MOBILE_APP
};

// ---------------------------------------------------------------------------------------------------------------------

// === Victor Hugo ===
export const LCE_MBO_MOCK_CUSTOMER_VICTOR_HUGO: LCEUserCustomerPartial = LCEUserUtils.toCustomerPartial(LCE_MBO_MOCK_CUSTOMER_RECORD_VICTOR_HUGO);

// === Angelina Jolie ===
export const LCE_MBO_MOCK_CUSTOMER_ANGELINA_JOLIE: LCEUserCustomerPartial = LCEUserUtils.toCustomerPartial(LCE_MBO_MOCK_CUSTOMER_RECORD_ANGELINA_JOLIE);

// === Brad Pitt ===
export const LCE_MBO_MOCK_CUSTOMER_BRAD_PITT: LCEUserCustomerPartial = LCEUserUtils.toCustomerPartial(LCE_MBO_MOCK_CUSTOMER_RECORD_BRAD_PITT);

// -----------------------------------------------------------------------------------------------------------
// === Persona ===
// -----------------------------------------------------------------------------------------------------------

export enum LCEMBOMockPersonaCustomer {
    VICTOR_HUGO = 'VICTOR_HUGO',
    ANGELINA_JOLIE = 'ANGELINA_JOLIE',
    BRAD_PITT = 'BRAD_PITT',
}

export enum LCEMBOMockPersonaMunicipalEmployee {
    VAMOUSSA_COULIBALY = 'VAMOUSSA_COULIBALY',
    STEVE_JOBS = 'STEVE_JOBS',
    ELON_MUSK = 'ELON_MUSK',
}

export enum LCEMBOMockPersona {
    VAMOUSSA_COULIBALY = 'VAMOUSSA_COULIBALY',
    STEVE_JOBS = 'STEVE_JOBS',
    ELON_MUSK = 'ELON_MUSK',
    VICTOR_HUGO = 'VICTOR_HUGO',
    ANGELINA_JOLIE = 'ANGELINA_JOLIE',
    BRAD_PITT = 'BRAD_PITT',
}

export const LCE_MBO_MOCK_PERSONA = {
    VAMOUSSA_COULIBALY: {
        userPartial: LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY,
        userRecord: LCE_MBO_MOCK_USER_RECORD_VAMOUSSA_COULIBALY
    },
    STEVE_JOBS: {
        userPartial: LCE_MBO_MOCK_USER_STEVE_JOBS,
        userRecord: LCE_MBO_MOCK_USER_RECORD_STEVE_JOBS
    },
    ELON_MUSK: {
        userPartial: LCE_MBO_MOCK_USER_ELON_MUSK,
        userRecord: LCE_MBO_MOCK_USER_RECORD_ELON_MUSK
    },
    VICTOR_HUGO: {
        userPartial: LCE_MBO_MOCK_CUSTOMER_VICTOR_HUGO,
        userRecord: LCE_MBO_MOCK_CUSTOMER_RECORD_VICTOR_HUGO
    },
    ANGELINA_JOLIE: {
        userPartial: LCE_MBO_MOCK_CUSTOMER_ANGELINA_JOLIE,
        userRecord: LCE_MBO_MOCK_CUSTOMER_RECORD_ANGELINA_JOLIE
    },
    BRAD_PITT: {
        userPartial: LCE_MBO_MOCK_CUSTOMER_BRAD_PITT,
        userRecord: LCE_MBO_MOCK_CUSTOMER_RECORD_BRAD_PITT
    }
};
