import {XSContactPerson} from '@xs/base';
import {LCEUser, LCEUserCreate, LCEUserPartial, LCEUserSearch, LCEUserType} from './lce-user';

export interface LCEUserEmployeePartial extends LCEUserPartial {
	mainRole: LCEUserEmployeeMainRole;
	position: LCEUserEmployeePosition;
}

export interface LCEUserEmployeeSearch extends LCEUserSearch {
	mainRoles?: LCEUserEmployeeMainRole[];
	positions?: LCEUserEmployeePosition[];
}

export interface LCEUserEmployeeCreate extends LCEUserCreate {
	mainRole: LCEUserEmployeeMainRole;
	position: LCEUserEmployeePosition;
	emergencyContactPerson?: XSContactPerson;
}

export interface LCEUserEmployee extends LCEUser {
	type: LCEUserType.EMPLOYEE;
	mainRole: LCEUserEmployeeMainRole;
	position: LCEUserEmployeePosition;
	emergencyContactPerson?: XSContactPerson;
}

export interface LCEUserEmployeeSettings {
}

export enum LCEUserEmployeeMainRole {
	SYSTEM = 'system',
	ROOT = 'root',
	SUPER_ADMIN = 'superAdmin',
	ADMIN = 'admin',
	MANAGER = 'manager',
	AGENT = 'agent',
	EMPLOYEE = 'employee',
	GUEST = 'guest'
}

export enum LCEUserEmployeePosition {
	CEO = 'ceo',
	TECHNICAL_DIRECTOR = 'technicalDirector',
	DISPATCHER = 'dispatcher',
	FIELD_AGENT = 'fieldAgent',
	OFFICE_AGENT = 'officeAgent',
	QUALITY_ASSURANCE_AGENT = 'qualityAssuranceAgent',
	QUALITY_ASSURANCE_MANAGER = 'qualityAssuranceManager',
	GUEST = 'guest'
}
