<div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card {{ styleClass }}">
    <div *ngIf="showHeader" class="xs-card-header">
        <xs-ivar
                [line1]="TR_BASE + 'order'"
                line1StyleClass="xs-color-secondary xs-font-size-default-imp xs-font-weight-500"
                line2StyleClass="xs-color-secondary xs-font-size-small-imp xs-mt-0-imp">
        </xs-ivar>
    </div>
    <div [ngClass]="{ 'xs-pt-30-imp': showHeader }" class="xs-card-content">
        <div class="grid xs-width-full xs-min-height-225">
            <div class="col-3 xs-flex-row-center">
                <div class="xs-flex-column-center {{ leftSideStyleClass }}">
                    <i [style.font-size]="'75px'" class="xs-display-block xs-color-secondary {{ ICON.order }}"></i>
                </div>
            </div>
            <div class="col-9 xs-border-left-discrete xs-pl-30-imp">

                <div class="grid xs-fields xs-fields-spacer-30">
                    <div class="col-12 md:col-6">
                        <xs-data-field-identifier
                                [copyable]="true"
                                [ellipsis]="true"
                                [label]="TR_BASE + 'orderNumber'"
                                [testColored]="true"
                                [value]="data.orderNumber"
                                type="code"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-identifier>
                    </div>
                    <div class="col-12 md:col-6 xs-flex-row xs-align-items-center">
                        <div class="xs-data-field">
                            <div class="xs-data-field-value-container">
                                <lce-certificate-order-status [statusAudit]="data.statusAudit" [status]="data.status"></lce-certificate-order-status>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-text
                                [valueBaseTranslation]="TR_BASE_REQUESTOR_TYPE"
                                [value]="data.source"
                                label="{{ TR_BASE }}orderOrigin"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-text>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <div class="xs-data-field-value-container">
                                <lce-certificate-order-number-of-copies [large]="true" [showBorder]="true" [showText]="true" [value]="data.numberOfCopies">
                                </lce-certificate-order-number-of-copies>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="xs-data-field">
                            <xs-data-field-label [label]="TR_BASE + 'destination'"></xs-data-field-label>
                            <div class="xs-data-field-value-container">
                                <lce-delivery-destination [data]="data.delivery.destination" [full]="false">
                                </lce-delivery-destination>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <lce-user-partial
                                *ngIf="data.customer"
                                [data]="data.customer!"
                                [showCode]="false"
                                [showDefaultLabel]="true"
                                [showStatus]="false"
                                [showViewRecordButton]="true"
                                [viewDetail]="true">
                        </lce-user-partial>
                        <lce-user-customer-anonymous *ngIf="data.customer | xsIsEmpty"></lce-user-customer-anonymous>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-identifier
                                (clickEvent)="openBatchProcessRecordDialog()"
                                [copyable]="true"
                                [ellipsis]="true"
                                [required]="false"
                                [testColored]="true"
                                [value]="data.batchProcessCode"
                                label="lce.core.label.batchProcessCode"
                                subLabel="lce.core.label.batchProcessCodeShortDescription"
                                type="code"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-identifier>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-identifier
                                (clickEvent)="openStampTokenRecordDialog()"
                                [copyable]="true"
                                [ellipsis]="true"
                                [required]="false"
                                [testColored]="true"
                                [value]="data.stampTokenCode"
                                label="lce.core.label.stampTokenCode"
                                subLabel="lce.core.label.stampTokenCodeShortDescription"
                                type="code"
                                valueStyleClass="xs-font-size-intermediate">
                        </xs-data-field-identifier>
                    </div>
                    <div class="col-12 md:col-6">
                        <xs-data-field-identifier
                                [copyable]="false"
                                [ellipsis]="true"
                                [required]="false"
                                [testColored]="true"
                                [value]="data.queueNumber ? ('# ' + data.queueNumber) : undefined"
                                label="lce.core.label.queueNumber"
                                subLabel="lce.core.label.queueNumberDescription"
                                type="code"
                                valueStyleClass="xs-font-size-medium-imp xs-color-secondary">
                        </xs-data-field-identifier>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>
