import {Component, OnInit} from '@angular/core';
import {LCEMunicipalitiesFeatureService, LCEMunicipalityDialogService} from '@lce/shared';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';

@Component({
    selector: 'lce-cmd-municipalities',
    templateUrl: './lce-cmd-municipalities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'},
    providers: [LCEMunicipalityDialogService]
})
export class LCECMDMunicipalitiesComponent implements OnInit {
    ICON = LCE_CMD_ICON;

    readonly TR_BASE: string = 'lce.shared.municipality.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    constructor(private featureService: LCEMunicipalitiesFeatureService, private municipalityDialogService: LCEMunicipalityDialogService) {
    }

    ngOnInit(): void {
    }

    public onCreate(): void {
        this.municipalityDialogService.openCreateUpdateDialog({showRecordAfterSave: true});
    }

    public onRefresh(): void {
        this.featureService.emitRefresh();
    }
}
