<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #districtRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !districtRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="headerAvatar" [subtitle]="headerSubTitle" [title]="headerTitle"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-justify-content-space-between xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="district!"></lce-user-resource-audit>

                <div class="xs-flex-row xs-align-items-center">
                    <xs-error
                            *ngIf=" (resourceAuditFullMenuAction | xsIsNotEmpty) && resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <xs-button
                            (clickEvent)="onEdit()"
                            *ngIf="canEdit()"
                            [disabled]="(resourceAuditFullMenuAction | xsIsNotEmpty) && !resourceAuditFullMenuAction?.canDoAction()"
                            [icon]="ICON_EDIT"
                            class="xs-mr-40"
                            label="xs.core.label.edit"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <xs-pk-resource-audit-full-menu-action
                        #resourceAuditFullMenuAction
                        *ngIf="canShowAuditFullMenuActions()"
                        [canOptions]="canOptions"
                        [dataManager]="dataManager!"
                        [resourceService]="districtService">
                    </xs-pk-resource-audit-full-menu-action>

                </div>
            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === District Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-6">
                    <xs-data-field-text [upperCase]="true" [value]="district!.code" label="xs.core.label.code" valueStyleClass="xs-font-size-intermediate xs-color-secondary"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-resource-state [value]="district"></xs-data-field-resource-state>
                </div>
                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE_LABEL + 'name'" [value]="district!.name"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'abbreviation'" [upperCase]="true" [value]="district!.abbreviation"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'fullName'" [value]="district!.fullName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'shortName'" [required]="false" [value]="district!.shortName"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE_LABEL + 'numberOfMunicipalities'" [value]="district!.numberOfMunicipalities | xsToString"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE_LABEL + 'capital'" [required]="false" [value]="district!.capital"></xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-ivar [label]="TR_BASE_LABEL + 'populationSize'" [value]="populationIvarData" styleClass="xs-data-field-spacer"></xs-data-field-ivar>
                </div>
                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'squareKM'" [required]="false" [suffix]="{ value: 'km²' }" [value]="district!.squareKM | xsNumber"></xs-data-field-text>
                </div>
                <div class="col-12 xs-flex-row-center">
                    <div class="xs-flex-row-center xs-width-full xs-max-width-400">
                        <xs-image [src]="districtImage" alt="District Map Image" noImageStyleClass="xs-max-width-150"></xs-image>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="district!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON_DISTRICT"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
