import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS, LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT, LCEFacilityTownHallStampTokenStatus} from '@lce/core';
import {XSAssert} from '@xs/base';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-status',
    templateUrl: 'lce-facility-town-hall-stamp-token-status.component.html'
})
export class LCEFacilityTownHallStampTokenStatusComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() textStyleClass?: string;

    @Input() showBackground?: boolean;

    @Input() shortMode?: boolean;

    @Input() data: LCEFacilityTownHallStampTokenStatus;

    get trBase(): string {
        return this.shortMode ? LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS_SHORT : LCE_TR_BASE_TOWN_HALL_STAMP_TOKEN_STATUS;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }
}
