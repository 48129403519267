import {Pipe, PipeTransform} from '@angular/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {LCE_TR_BASE_RESOURCE_TYPE} from '../constants/lce-core.constant';

@Pipe({name: 'lceResourceType', pure: false})
export class LCEResourceTypePipe implements PipeTransform {
    constructor(private translationService: XSTranslationService) {
    }

    public transform(value: string | undefined): string {
        return this.translateResourceType(value);
    }

    private translateResourceType(resourceType: string | undefined): string {
        if (XSUtils.isEmpty(resourceType)) return XS_STR_EMPTY;
        const trKey = LCE_TR_BASE_RESOURCE_TYPE + resourceType;
        const trValue = this.translationService.translateKey(trKey);
        return trKey === trValue ? resourceType! : trValue;
    }
}
