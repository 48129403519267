<div class="lce-dispatcher-main {{styleClass}}">
    <div class="lce-dispatcher-main-container">
        <div class="grid">
            <div class="col-12 xs-mb-25">
                <lce-delivery-men-widget
                    #deliveryMenWidget (selectEvent)="onDeliveryManSelected($event)"
                    [disabled]="isAssignLoaderRunning()"
                    [showHeaderBackgroundColor]="true"
                    mapPosition="right">
                </lce-delivery-men-widget>
            </div>
            <div class="col-12">
                <div class="grid xs-flex">
                    <div class="col-5">
                        <lce-certificate-order-widget
                            #certificateOrderWidget
                            (selectedOrderEvent)="handleOrderSelected($event)"
                            [disabled]="isAssignLoaderRunning()"
                            [selectMode]="'multiple'"
                            [showHeaderBackgroundColor]="true">
                        </lce-certificate-order-widget>
                    </div>
                    <div class="col-2 xs-flex-column-center">
                        <xs-button [icon]="ICON.setting" [transform]="false" class="xs-mb-10" label="xs.common.label.options" size="small" type="text"></xs-button>
                        <xs-button
                                (clickEvent)="onAssignOrders()"
                                [confirmation]="assignmentConfirmation"
                                [disabled]="isAssignButtonDisabled()"
                                [icon]="ICON.arrowRight"
                                [loaderId]="ASSIGN_LOADER_ID"
                                [loading]="isAssignLoaderRunning()"
                                [transform]="false"
                                iconPosition="right"
                                label="{{ 'lce.shared.certificateOrders.manualDispatcher.assign' | translate : { orderToAssign: ordersToAssignNumber } }}"
                                labelStyleClass="xs-font-size-intermediate-import"
                                loaderPosition="bottom"
                                size="intermediate"
                                styleClass="xs-width-full xs-width-200-imp"
                                type="button"></xs-button>

                        <div *ngIf="hasAssignError()" class="xs-flex-column-center xs-mt-40">
                            <xs-error
                                    [error]="errorAssign"
                                    [showActionButton]="true"
                                    [showErrorDetailButton]="true"
                                    message="lce.shared.certificateOrders.manualDispatcher.errorMessageAssign"
                                    mode="inline"
                                    styleClass="xs-max-width-175">
                            </xs-error>
                        </div>
                    </div>
                    <div class="col-5">
                        <lce-delivery-man-order-widget
                            #deliveryManOrderWidget
                            (unassignOrdersEvent)="onDeliveryManUnassignOrder()"
                            (unassigningOrdersEvent)="deliveryMenWidget.disabled = $event"
                            [deliveryMan]="deliveryMan"
                            [disabled]="isAssignLoaderRunning()"
                            [selectMode]="'multiple'"
                            [showHeaderBackgroundColor]="true">
                        </lce-delivery-man-order-widget>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
