import {Injectable} from '@angular/core';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCECertificateOrderBatchProcess} from '../api/domain/lce-certificate-order-batch-process';
import {LCECertificateOrderBatchProcessRecordComponent} from './record/lce-certificate-order-batch-process-record.component';
import {LCECertificateOrderBatchProcessesComponent} from './lce-certificate-order-batch-processes.component';
import {XSAssert} from '@xs/base';
import {LCESharedService} from '../api/services/lce-shared.service';

export interface LCECertificateOrderBatchProcessRecordDialogArg {
    batchProcessID?: string;
    batchProcessCode?: string;
    batchProcess?: LCECertificateOrderBatchProcess,
    readonly?: boolean;
}

export interface LCECertificateOrderBatchProcessDataManagerDialogArg {
    readonly?: boolean;
    onClose?: () => void;
    onChange?: () => void;
}

@Injectable({providedIn: 'root'})
export class LCECertificateOrderBatchProcessDialogService {

    constructor(private dialogService: XSDialogService, private sharedService: LCESharedService) {
        this.sharedService.onOpenCertificateOrderBatchProcessDialog.subscribe(arg => this.openRecordDialog(arg));
    }

    public openRecordDialog(arg: LCECertificateOrderBatchProcessRecordDialogArg): void {
        XSAssert.notEmpty(arg, 'arg');
        LCECertificateOrderBatchProcessRecordComponent.validate(arg.batchProcessID, arg.batchProcessCode, arg.batchProcess, 'arg');
        const dConfig: XSDialogConfig = {
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            footerOkButton: true,
            width: '90%',
            maxWidth: '1500px',
            closable: true,
            dialogStyleClass: '',
            contentStyleClass: 'xs-min-height-700',
            data: {
                batchProcessID: arg.batchProcessID,
                batchProcessCode: arg.batchProcessCode,
                batchProcess: arg.batchProcess,
                readonly: arg.readonly
            }
        };
        this.dialogService.openComponentDialog(LCECertificateOrderBatchProcessRecordComponent, dConfig);
    }

    public openDataManagerDialog(arg?: LCECertificateOrderBatchProcessDataManagerDialogArg): void {
        const dConfig: XSDialogConfig = {
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            footerOkButton: true,
            width: '90%',
            maxWidth: '1400px',
            closable: true,
            data: {
                readonly: arg?.readonly
            }
        };
        const component: LCECertificateOrderBatchProcessesComponent = this.dialogService.openComponentDialog(LCECertificateOrderBatchProcessesComponent, dConfig);

        component.closeEvent.subscribe(() => {
            if (arg?.onClose) arg!.onClose!();
        });
        component.changeEvent.subscribe(() => {
            if (arg?.onChange) arg!.onChange!();
        });
    }
}
