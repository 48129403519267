<div class="xs-card xs-min-width-400 xs-width-full xs-card-border {{ styleClass }}">
    <div [ngClass]="{'xs-background-color-discrete': showHeaderBackgroundColor}" [style.filter]="'brightness(106%)'"
         class="xs-card-header xs-card-header-separator xs-mb-25">
        <xs-title
                [expandCollapseEnable]="true"
                [expandCollapseTarget]="filter"
                [expanded]="true"
                [icon]="ICON.certificateOrder"
                [subText]="subTitle"
                [text]="title!"
                styleClass="xs-mb-3"
                subTextStyleClass="xs-font-size-intermediate xs-color-secondary {{ subTitleStyleClass }}"
                textStyleClass="xs-font-size-default xs-font-weight-500-imp xs-color-secondary {{ titleStyleClass }}">
        </xs-title>
    </div>
    <div class="xs-card-content xs-p-0-imp">
        <div #filter [ngClass]="{ 'xs-disabled': disabled }" class="xs-mb-25 xs-plr-10">
            <xs-input-search
                    (changeEvent)="onInputSearch()"
                    [(value)]="searchText"
                    [delay]="SEARCH_TEXT_DELAY"
                    [disabled]="disabledSearchFields"
                    [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                    [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                    [placeholder]="TR_BASE + 'filter.search'"
                    [searching]="searching"
                    inputId="searchText"
                    styleClass="xs-mb-20 xs-width-full">
            </xs-input-search>
            <xs-input-period-select
                    (periodSelectEvent)="onPeriodSelectEvent($event)"
                    [disabled]="disabledSearchFields"
                    styleClass="xs-mb-20">
            </xs-input-period-select>

            <xs-input-multi-select
                    (valueChange)="onStatusSearch($event)"
                    [(value)]="orderStatus"
                    [disabled]="disabledSearchFields"
                    [items]="orderStatusItems"
                    [options]="statusSearchField"
                    [placeholder]="TR_BASE + 'filter.statusPlaceholder'">
            </xs-input-multi-select>
        </div>
        <div class="xs-flex xs-justify-content-space-between xs-align-item-center xs-border-bottom-discrete xs-pb-5 xs-plr-10">
            <div class="xs-width-175">
                <div class="xs-position-absolute xs-pl-10">
                    <xs-loader #orderNumberLoader [loaderId]="ORDER_NUMBER_LOADER_ID" [loaderSize]="15" [loaderType]="loaderType"></xs-loader>
                </div>
                <span *ngIf="!orderNumberLoader.isRunning()" class="xs-display-block xs-font-size-default xs-color-secondary">
                {{ 'lce.shared.certificateOrders.table.caption' | translate : {nResults: totalResults} }}
            </span>
            </div>

            <div class="xs-flex xs-align-item-center">
                <xs-icon
                        [disabled]="true"
                        [value]="ICON.setting"
                        color="secondary"
                        styleClass="xs-clickable xs-mr-40">
                </xs-icon>
                <xs-icon
                        (clickEvent)="showMap = !showMap"
                        [value]="ICON.map"
                        size="15px"
                        styleClass="{{ showMap ? 'xs-color-secondary-imp' : 'xs-color-primary' }}">
                </xs-icon>
            </div>
        </div>

        <div class="xs-plr-10 xs-pt-10 xs-position-relative xs-min-height-200 xs-flex-column-center">
            <div class="xs-absolute-center-vh">
                <xs-loader
                    #searchLoader
                    [loaderId]="LOADER_ID"
                    [loaderSize]="30">
                </xs-loader>
            </div>
            <div
                    *ngIf="hasError() && !searchLoader.isRunning()"
                    class="xs-absolute-center-vh xs-width-full">
                <xs-error
                        [actionButton]="errorRetryButton"
                        [error]="error"
                        [showActionButton]="true"
                        [showErrorDetailButton]="true"
                        message="xs.core.label.httpErrorMessage"
                        mode="inline">
                </xs-error>
            </div>
            <ul
                    *ngIf="!hasError() && !searchLoader.isRunning() && unassignedOrders | xsIsNotEmpty"
                    class="xs-listing xs-listing-separator xs-width-full xs-overflow-y-scroll xs-height-300 xs-mr-15">
                <li
                        (click)="onOrderSelect(order)"
                        *ngFor="let order of unassignedOrders"
                        [ngClass]="{ 'lce-background-items-selected xs-border-radius-default': isSelected(order) }"
                        class="xs-listing-item xs-p-15-imp xs-cursor-pointer">
                    <lce-certificate-order-partial
                            [data]="order"
                            [detailed]="true"
                            [openRecordOnOrderNumberClick]="true">
                    </lce-certificate-order-partial>
                </li>

                <li *ngIf="unassignedOrders | xsIsNotEmpty" class="xs-background-none-imp">
                    <xs-paginator-load-more
                            (errorEvent)="handlePaginationError($event)"
                            (loadEvent)="handlePaginationLoad($event)"
                            [options]="paginationOptions"
                            [paginationPage]="pagination.page"
                            [paginationSize]="pagination.size"
                            [total]="totalResults"
                            styleClass="xs-mt-5 xs-flex-row-center">
                    </xs-paginator-load-more>
                </li>
            </ul>

            <xs-no-result-found
                    *ngIf="!hasError() && !searchLoader.isRunning() && unassignedOrders | xsIsEmpty"
                    iconStyleClass="xs-font-size-35-imp"
                    styleClass="xs-flex-column-center xs-width-full">
            </xs-no-result-found>
        </div>
    </div>
    <div *ngIf="showMap" class="xs-card-footer xs-border-top-discrete">
        <div class="xs-flex-column-center xs-min-height-250">
            <xs-icon
                    [value]="ICON.map"
                    color="discrete"
                    size="150px">
            </xs-icon>
        </div>
    </div>
</div>
