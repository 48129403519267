import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {XSAssert, XSLanguage, XSPersonName, XSPKDTOAuditCreated, XSPKDTOAuditUpdated, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSPersonNamePipe, XSTranslationService} from '@xs/common';

@Component({
    selector: 'lce-user-resource-audit',
    templateUrl: './lce-user-resource-audit.component.html',
    providers: [XSPersonNamePipe]
})
export class LCEUserResourceAuditComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: XSPKDTOAuditCreated | XSPKDTOAuditUpdated;

    @Input() showViewRecordButton?: boolean;

    internalData: any;

    private readonly TR_ANONYMOUS: string = 'lce.core.label.anonymous';

    constructor(private translationService: XSTranslationService, private personNamePipe: XSPersonNamePipe) {
    }

    get dateFormat(): string {
        const currentLanguage: XSLanguage = this.translationService.getCurrentLanguage();
        return currentLanguage === XSLanguage.FRENCH ? XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR : XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN;
    }

    get equalCreatedByAndUpdatedBy() {
        return this.updated && XSUtils.trim(this.internalData.createdBy.id)?.toLowerCase() === XSUtils.trim(this.internalData.updatedBy.id)?.toLowerCase();
    }

    get updated(): boolean {
        return !XSUtils.isEmpty(this.internalData.updatedBy);
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showViewRecordButton)) this.showViewRecordButton = false;
        this.update();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.data) && !changes.data!.isFirstChange()) {
            this.update();
        }
    }

    public getName(personName: XSPersonName | undefined): string {
        if (XSUtils.isEmpty(personName)) {
            return this.TR_ANONYMOUS;
        }
        return this.personNamePipe.transform(personName);
    }

    public hasViewRecordPermission(): boolean {
        return true; // TODO
    }

    private update(): void {
        this.internalData = this.data as any;
    }
}
