import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCEArticleReviewStatus,
    LCECoreContextService,
    LCESmartCityArticle,
    LCESmartCityArticleCard,
    LCESmartCityArticleCreate,
    LCESmartCityArticlePartial,
    LCESmartCityArticleSearch,
    LCESmartCityArticleStatus,
    LCESmartCityArticleType,
    LCEUserPartial
} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {XSHttpMethod, XSPKDTOAuditFullState, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_SMARTCITY_JSON_ARTICLES from '../../data/lce-smartcity-articles.json';
import {LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY} from '../../lce-mock-user-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_TAGS, QR_CODE_DATA} from '../../lce-mock.constant';
import {LCEMockSmartCityArticleCategoryHandler} from './lce-mock-smartcity-article-category-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.smartCity.articles';

@Injectable()
export class LCEMockSmartCityArticleHandler extends XSMockPKResourceAuditFullHandler<LCESmartCityArticle, LCESmartCityArticlePartial, LCESmartCityArticleSearch>
    implements XSMockPKSearchable<LCESmartCityArticlePartial> {

    public static ARTICLE_STORAGE: Map<string, LCESmartCityArticle> = new Map<string, LCESmartCityArticle>();

    private queryPredicates: XSMockSearchQueryPredicate<LCESmartCityArticle>[] = [
        (article, query) => article.code.toLowerCase().includes(query),
        (article, query) => article.ownerMunicipality.code.toLowerCase().includes(query),
        (article, query) => article.title.toLowerCase().includes(query),
        (article, query) => article.subTitle?.toLowerCase().includes(query),
        (article, query) => article.description.toLowerCase().includes(query),
        (article, query) => article.content.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCESmartCityArticle>[] = [
        (article, params) => this.httpParamArrayIncludes(params, 'codes', article.code),
        (article, params) => this.httpParamArrayIncludes(params, 'types', article.type),
        (article, params) => this.httpParamStringIncludes(params, 'title', article.title),
        (article, params) => this.httpParamStringIncludes(params, 'subTitle', article.subTitle),
        (article, params) => this.httpParamStringIncludes(params, 'description', article.description),
        (article, params) => this.httpParamStringIncludes(params, 'content', article.content),
        (article, params) => this.httpParamArrayIncludes(params, 'statuses', article.status),
        (article, params) => this.httpParamArrayIncludes(params, 'categoryCodes', article.categories.map(category => category.code))
    ];

    constructor(
        private contextService: LCECoreContextService,
        private mockSmartCityArticleCategoryHandler: LCEMockSmartCityArticleCategoryHandler,
        private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.smartCity.articles.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCESmartCityArticle> {
        return LCEMockSmartCityArticleHandler.ARTICLE_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(article: LCESmartCityArticle): LCESmartCityArticlePartial {
        return {
            id: article.id,

            code: article.code,

            type: article.type,

            title: article.title,
            description: article.description,
            coverImage: article.coverImage
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 3000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search (Partials)
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Search (Cards)
            {
                id: `${DATASET_BASE_ID}.searchCards`,
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index + '/' + LCE_CORE_ENDPOINTS.smartCity.articles.searchAsCards,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => {
                    return this.searchResponseData<LCESmartCityArticleCard>(rArg.params, this.queryPredicates, this.searchPredicates, article => this.toCard(article));
                }
            },
            // Last
            {
                id: DATASET_BASE_ID + '.lastCards',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index + '/' + LCE_CORE_ENDPOINTS.smartCity.articles.lastAsCards,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => {
                    return this.getNLastResources(parseInt(rArg.params!.get('nLast')!)).map(article => this.toCard(article));
                }
            },
            {
                id: DATASET_BASE_ID + '.autocompleteTags',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index + '/' + LCE_CORE_ENDPOINTS.smartCity.articles.autocompleteTags,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => this.buildAutocompleteTagsResponseData(rArg.params!)
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCESmartCityArticlePartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private buildAutocompleteTagsResponseData(params: HttpParams): string[] {
        const query = params.get('query')?.trim();
        const limit = parseInt(params.get('limit')!);
        return LCE_MOCK_TAGS
            .filter(tag => {
                if (XSUtils.isEmpty(query)) {
                    return true;
                }
                return tag.includes(query!);
            })
            .slice(0, limit);
    }

    private buildRetrieveArticleByCode(articleCode: string): XSMockData<LCESmartCityArticle> {
        let requestURL = LCE_CORE_ENDPOINTS.smartCity.articles.code.replace('${articleCode}', articleCode);
        return {
            id: DATASET_BASE_ID + '.retrieveByCode.' + articleCode,
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByCode(articleCode);
                if (XSUtils.isNull(article)) {
                    throw new HttpErrorResponse({
                        error: {data: `failed to get SmartCity article : [${article}].`},
                        status: HttpStatusCode.InternalServerError,
                        statusText: 'Internal Server Error',
                        url: requestURL
                    });
                }
                return article;
            }
        };
    }

    private buildAddTagsMockData(articleID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.addTags.' + articleID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index + '/' + articleID + '/' + LCE_CORE_ENDPOINTS.smartCity.articles.tags,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByID(articleID);
                if (XSUtils.isNull(article.tags)) {
                    article.tags = [];
                }
                article.tags!.concat(rArg.params?.get('tags')!.split(',')!);
                return article.tags;
            }
        };
    }

    private buildRemoveTagsMockData(articleID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.removeTags.' + articleID,
            active: true,
            requestMethod: XSHttpMethod.DELETE,
            requestURL: LCE_CORE_ENDPOINTS.smartCity.articles.index + '/' + articleID + '/' + LCE_CORE_ENDPOINTS.smartCity.articles.tags,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByID(articleID);
                if (XSUtils.isNull(article.tags)) {
                    article.tags = [];
                }
                const tagsToRemove = rArg.params?.get('tags')!.split(',')!;
                article.tags = article.tags!.filter(tag => !tagsToRemove.some(item => item === tag));
                return article.tags;
            }
        };
    }

    private createResponseData(body: any): LCESmartCityArticle {
        const articleCreate: LCESmartCityArticleCreate = body as LCESmartCityArticleCreate;
        const coverImage = XSUtils.randomElement([
            'assets/images/articles/robo.webp',
            'assets/images/smartcity/event-3.webp',
            'assets/images/smartcity/event.webp',
            'assets/images/articles/introducing-apple-vision-pro-1.webp'
        ]);
        const article: LCESmartCityArticle = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: this.buildCode(),
            ownerMunicipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
            title: articleCreate.title.fr!,
            subTitle: articleCreate.subTitle?.fr!,
            description: articleCreate.description.fr!,
            content: articleCreate.content.fr!,
            status: LCESmartCityArticleStatus.CREATED,
            coverImage: XSUtils.isNull(articleCreate.coverImage) ? coverImage : articleCreate.coverImage,
            images: [],
            qrCode: QR_CODE_DATA,
            reviewers: [
                {
                    user: LCE_MBO_MOCK_USER_ELON_MUSK,
                    addedOn: new Date().toISOString(),
                    reviewStatus: LCEArticleReviewStatus.PENDING,
                    isDefault: true
                },
                {
                    user: LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY,
                    addedOn: new Date().toISOString(),
                    reviewStatus: XSUtils.randomElement([LCEArticleReviewStatus.APPROVED, LCEArticleReviewStatus.REQUEST_CHANGES])
                }
            ],
            categories: this.mockSmartCityArticleCategoryHandler.findByCodeAsPartials(articleCreate.categoryCodes),
            readCount: XSUtils.randomInteger(0, 10000000),
            shareCount: XSUtils.randomInteger(0, 10000000),
            scanCount: XSUtils.randomInteger(0, 10000000),
            type: articleCreate.type
        };
        LCEMockSmartCityArticleHandler.ARTICLE_STORAGE.set(article.id, article);
        this.addArticleMockData(article);
        return article;
    }

    private buildStorage(): void {
        LCE_SMARTCITY_JSON_ARTICLES.forEach((jsonArticle) => {
            const article: LCESmartCityArticle = {
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                qrCode: QR_CODE_DATA,
                ownerMunicipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
                status: XSUtils.randomElement(XSUtils.getEnums(LCESmartCityArticleStatus)),
                reviewers: [
                    {
                        user: LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY,
                        addedOn: new Date().toISOString(),
                        reviewStatus: LCEArticleReviewStatus.PENDING,
                        isDefault: true
                    },
                    {
                        user: LCE_MBO_MOCK_USER_ELON_MUSK,
                        addedOn: new Date().toISOString(),
                        reviewStatus: XSUtils.randomElement([LCEArticleReviewStatus.APPROVED, LCEArticleReviewStatus.REQUEST_CHANGES])
                    }
                ],
                readCount: XSUtils.randomInteger(0, 10000000),
                shareCount: XSUtils.randomInteger(0, 10000000),
                scanCount: XSUtils.randomInteger(0, 10000000),
                ...jsonArticle,
                type: jsonArticle.type as LCESmartCityArticleType
            };

            LCEMockSmartCityArticleHandler.ARTICLE_STORAGE.set(article.id, article);
            this.addArticleMockData(article);
        });
    }

    private addArticleMockData(article: LCESmartCityArticle): void {
        super.addResourceBaseMockData(article.id);
        this.buildAddTagsMockData(article.id);
        this.buildRemoveTagsMockData(article.id);
        this.mockDataArray.push(this.buildRetrieveArticleByCode(article.code));
    }

    private toCard(article: LCESmartCityArticle): LCESmartCityArticleCard {
        return {
            id: article.id,
            code: article.code,
            type: article.type,

            title: article.title,
            subTitle: article.subTitle,
            description: article.description,
            coverImage: article.coverImage,

            createdOn: article.createdOn,
            publishedOn: article.publishedOn,

            readCount: article.readCount,
            shareCount: article.shareCount,
            scanCount: article.scanCount
        };
    }

    private buildCode(): string {
        return 'LCE-SMC-' + XSUtils.randomDigits(6);
    }
}
