import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE, LCESmartCityArticleType} from '@lce/core';

@Component({
    selector: 'lce-smartcity-article-type',
    template: `
        <xs-chip
                [label]="TR_BASE_SMART_CITY_ARTICLE_TYPE + type"
                [small]="small"
                [style.filter]="darkness? 'brightness(90%)' : 'brightness(106%)'"
                styleClass="xs-color-secondary-imp xs-font-weight-500 {{styleClass}}">
        </xs-chip>
    `
})
export class LCESmartCityArticleTypeComponent implements OnInit {

    readonly TR_BASE_SMART_CITY_ARTICLE_TYPE: string = LCE_TR_BASE_SMART_CITY_ARTICLE_TYPE;

    @Input() styleClass?: string;

    @Input() type: LCESmartCityArticleType;

    @Input() darkness?: boolean;

    @Input() small?: boolean;

    ngOnInit(): void {
        XSAssert.notNull(this.type, 'type');
    }
}