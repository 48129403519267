import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEHttpClientService} from '../lce-http-client.service';
import {
    LCEUserDeliveryMan,
    LCEUserDeliveryManCreate,
    LCEUserDeliveryManPartial,
    LCEUserDeliveryManSearch
} from '../../domain/user/lce-user-delivery-man';
import {LCEUserService} from './lce-user.service';
import {LCEValidatorUtils} from '../../utils/lce-validator-utils';

@Injectable({providedIn: 'root'})
export class LCEUserDeliveryManService extends LCEUserService<LCEUserDeliveryMan, LCEUserDeliveryManPartial, LCEUserDeliveryManSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.deliveryMen.index, 'deliveryMen');
    }

    public create(deliveryManCreate: LCEUserDeliveryManCreate): Observable<LCEUserDeliveryMan> {
        LCEValidatorUtils.validateDeliveryManCreate(deliveryManCreate);
        return this.httpClientService.post<LCEUserDeliveryMan>(this.buildEndpoint(), deliveryManCreate);
    }
}
