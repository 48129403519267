import {Injectable} from '@angular/core';
import {XSCoreContextService, XSGeolocationService} from '@xs/core';
import {LCE_DEFAULT_LOCATION_COUNTRY_ISO, LCEAppConfig, LCECoreContextService} from '@lce/core';
import {XSCoordinate, XSGeolocation, XSUtils} from '@xs/base';

@Injectable()
export abstract class LCESharedContextService extends LCECoreContextService implements XSCoreContextService {

    protected constructor(protected geolocationService: XSGeolocationService) {
        super();
    }

    public getCurrentCountryISO(): string {
        const geolocation = this.geolocationService.getGeolocation();
        return XSUtils.isEmpty(geolocation) ? LCE_DEFAULT_LOCATION_COUNTRY_ISO : geolocation.countryISO;
    }

    public getCurrentPosition(): XSCoordinate {
        return this.geolocationService.getGeolocation().coordinate;
    }

    public getGeolocation(): XSGeolocation {
        return this.geolocationService.getGeolocation();
    }

    public setIPGeolocation(geolocation: XSGeolocation) {
        this.geolocationService.setIPGeolocation(geolocation);
    }

    public getAppConfig(): LCEAppConfig {
        return super.getAppConfig() as LCEAppConfig;
    }
}
