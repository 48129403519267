<div [ngClass]="{ 'xs-mt-15': label | xsIsNotEmpty }" class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <lce-user-avatar
            (avatarClickEvent)="onAvatarClick($event)"
            *ngIf="showAvatar"
            [avatarSize]="avatarSize"
            [avatarStyleClass]="avatarStyleClass"
            [clickable]="viewDetail"
            [data]="data"
            [disabled]="disabled"
            [showStatus]="showStatus"
            [showTooltip]="showTooltip"
            styleClass="{{ showOnlyAvatar !== true ? 'xs-mr-10' : STR_EMPTY }}">
    </lce-user-avatar>
    <div *ngIf="showOnlyAvatar !== true" class="xs-flex-column xs-position-relative">
        <label *ngIf="label | xsIsNotEmpty" class="xs-data-field-label xs-position-absolute xs-top-minus-25 xs-left-0 {{ labelStyleClass }}">{{ label! | translate }}</label>
        <xs-ivar
                (line1Click)="onLine1Click($event)"
                [disabled]="disabled"
                [ellipsis]="true"
                [line1AutoClickableBehaviour]="viewDetail"
                [line1]="dataLine1"
                [line2]="dataLine2"
                [line3]="dataLine3"
                [line4]="customLine! | translate"
                class="xs-min-width-1"
                line1StyleClass="xs-font-size-intermediate-imp xs-mb-2-imp {{ personNameStyleClass }}"
                line2StyleClass="xs-color-secondary {{ isAvatarSizeSmall() ? 'xs-font-size-small-imp' : 'xs-font-size-intermediate-imp' }} {{ userPositionStyleClass }}"
                line3StyleClass="xs-color-secondary xs-text-transform-uppercase xs-font-size-small-imp {{ userCodeStyleClass }}"
                line4StyleClass="{{ customLineStyleClass }}">
        </xs-ivar>
    </div>

    <p-overlayPanel #detailOverlay *ngIf="viewDetail" [appendTo]="'body'">
        <lce-user-detail [data]="data" [showViewRecordButton]="hasViewDetailPermission() && showViewRecordButton === true"></lce-user-detail>
    </p-overlayPanel>
</div>

