import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LCEDistrictPartial, LCEDistrictService} from '@lce/core';
import {XSUtils} from '@xs/base';
import {XSInputAutoCompleteComponent, XSInputFieldAutoCompleteOptions, XSInputFieldBaseOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
	selector: 'lce-input-field-district-autocomplete',
	template: `
        <xs-input-field-auto-complete styleClass="{{ styleClass }}" [appendTo]="appendTo" [options]="autocompleteOptions">
            <ng-template let-district xsTemplate="item">
                <lce-district-partial [data]="district"></lce-district-partial>
            </ng-template>
        </xs-input-field-auto-complete>
	`,
	host: {class: 'xs-width-full'}
})
export class LCEInputFieldDistrictAutocompleteComponent implements OnInit {
	@Input() styleClass?: string;

	@Input() options: XSInputFieldBaseOptions;

	@Input() showIcon?: boolean;

	@Input() completeOnFocus?: boolean;

	@Input() showDefaultBelowText?: boolean;

	@Input() placeholder?: string;

	@Input() appendTo?: any;

	@Output() selectEvent = new EventEmitter<LCEDistrictPartial>();

	autocompleteOptions: XSInputFieldAutoCompleteOptions;

	@ViewChild('autoComplete') autoComplete: XSInputAutoCompleteComponent;

	constructor(private districtService: LCEDistrictService) {
	}

	ngOnInit(): void {
		if (XSUtils.isEmpty(this.placeholder)) this.placeholder = 'lce.shared.district.label.autocompletePlaceholder';
		if (XSUtils.isNull(this.showIcon)) this.showIcon = true;
		if (XSUtils.isNull(this.completeOnFocus)) this.completeOnFocus = false;
		this.autocompleteOptions = {
			control: this.options.control,
			autoCompleteOptions: {
				labelField: 'fullName',
				forceSelection: true,
				completeOnFocus: this.completeOnFocus,
				search: (query: string) => {
					return this.districtService.autocomplete(query);
				}
			},
			placeholder: this.placeholder,
			belowText: this.showDefaultBelowText === true ? 'lce.core.label.district' : undefined,
			belowTextStyleClass: 'xs-color-secondary xs-font-size-small',
			selectEvent: (event: any) => this.handleSelectEvent(event),
			...this.options
		};
		if (this.showIcon) {
			this.autocompleteOptions.inputStyleClass = 'xs-pl-35-imp';
			this.autocompleteOptions.leftIcon = LCE_SHARED_ICON.district;
			this.autocompleteOptions.leftIconStyleClass = 'xs-color-secondary xs-font-size-16-imp xs-ml-2 xs-top-7-imp';
		}
	}

	private handleSelectEvent(district: LCEDistrictPartial): void {
		this.selectEvent.emit(district);
	}
}
