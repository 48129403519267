import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_CORE_LABEL, LCECertificateOrder, LCECertificateOrderPartial, LCECertificateOrderUpdateStatusResponse} from '@lce/core';
import {XSAssert, XSPagination, XSSort, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR, XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {XSCalendarOptions, XSClipboardService, XSMenuItem, XSTableColumn, XSTableOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCECertificateOrderDialogService} from '../lce-certificate-order-dialog.service';
import {LCECertificateOrdersFeatureService} from '../lce-certificate-orders-feature.service';

@Component({selector: 'lce-certificate-orders-table', templateUrl: './lce-certificate-orders-table.component.html'})
export class LCECertificateOrdersTableComponent implements OnInit, OnDestroy {
    readonly TR_BASE = 'lce.shared.certificateOrders.table.';

    @Input() data: LCECertificateOrderPartial[] = [];

    @Input() nResults: number = 0;

    @Input() caption: XSText;

    @Input() subCaption?: XSText;

    @Input() loadingState: XSLoaderState;

    @Input() readonly?: boolean;

    @Output() resetPreferencesEvent = new EventEmitter();

    @Output() resetSortEvent = new EventEmitter();

    @Output() paginationEvent = new EventEmitter<XSPagination>();

    @Output() sortEvent = new EventEmitter<XSSort>();

    options: XSTableOptions;
    columns: XSTableColumn[];

    emptyMessage: XSText = {value: this.TR_BASE + 'noResultFound'};

    calendarOptions: XSCalendarOptions = {
        type: 'dateTime',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_TIME_FR
    };

    private readonly ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];
    private subscription: Subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private clipboardService: XSClipboardService,
        private certificateOrderDialogService: LCECertificateOrderDialogService,
        private featureService: LCECertificateOrdersFeatureService
    ) {
    }

    get batchProcessCodeVisible(): boolean {
        return this.featureService.isBatchProcessCodeVisible();
    }

    ngOnInit(): void {
        this.buildTableOptions();
        this.buildTableColumns();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public viewOrder(selection: LCECertificateOrderPartial[] | LCECertificateOrderPartial): void {
        const certificateOrder: LCECertificateOrderPartial = XSUtils.isArray(selection) ? (selection as LCECertificateOrderPartial[])[0] : (selection as LCECertificateOrderPartial);
        this.certificateOrderDialogService.openRecordDialog({
            orderID: certificateOrder.id,
            orderNumber: certificateOrder.orderNumber,
            type: certificateOrder.certificate.type,
            readonly: this.readonly,
            onStatusUpdated: (updateStatusResponse) => this.handleStatusUpdated(certificateOrder, updateStatusResponse)
        });
    }

    private handleStatusUpdated(certificateOrder: LCECertificateOrderPartial, updateStatusResponse: LCECertificateOrderUpdateStatusResponse) {
        XSAssert.isTrue(certificateOrder.id === updateStatusResponse.id, 'certificateOrder.id and updateStatusResponse.id must be equal.');
        XSAssert.isTrue(certificateOrder.orderNumber === updateStatusResponse.orderNumber, 'certificateOrder.orderNumber and updateStatusResponse.orderNumber must be equal.');
        certificateOrder.updatedOn = updateStatusResponse.updatedOn;
        certificateOrder.status = updateStatusResponse.status;
        certificateOrder.statusUpdatedOn = updateStatusResponse.statusUpdatedOn;
    }

    private buildTableColumns(): void {
        this.columns = [
            {
                field: 'id',
                header: 'ID',
                visible: false,
                displayable: false
            },
            {
                field: 'orderNumber',
                header: LCE_TR_BASE_CORE_LABEL + 'orderNumberShort',
                visible: true,
                displayable: true
            },
            {
                field: 'certificate',
                header: LCE_TR_BASE_CORE_LABEL + 'certificateShort',
                visible: true,
                exportable: false,
                resizable: this.options.resizableColumns && true,
                reorderable: this.options.reorderableColumns && true,
                sortable: false
            },
            {
                field: 'numberOfCopies',
                header: LCE_TR_BASE_CORE_LABEL + 'nCopies',
                visible: true,
                displayable: true,
                sortable: true,
                sortableField: 'numberOfCopies'
            },
            {
                field: 'customer',
                header: LCE_TR_BASE_CORE_LABEL + 'customer',
                visible: false,
                displayable: true,
                sortable: true
            },
            {
                field: 'orderStatus',
                header: LCE_TR_BASE_CORE_LABEL + 'status',
                visible: true,
                displayable: true
            },
            {
                field: 'orderDate',
                header: 'xs.common.label.createdOn',
                visible: true,
                displayable: true,
                sortable: true,
                sortableField: 'createdOn'
            },
            {
                field: 'delivery',
                header: LCE_TR_BASE_CORE_LABEL + 'delivery',
                visible: true,
                displayable: true,
                resizable: this.options.resizableColumns && true,
                reorderable: this.options.reorderableColumns && true,
                sortable: false
            }
        ];
    }

    private buildTableOptions(): void {
        this.options = {
            id: 'certificateOrdersTable',
            localStorageKey: 'certificateOrders.table',
            dataUniqueField: 'id',
            lazy: true,
            rowHover: true,
            selectionMode: 'multiple',
            contextMenu: true,
            sortMode: 'multiple',
            paginator: true,
            simplePaginator: true,
            paginatorRows: this.featureService.pagination.size,
            paginatorRowsPerPageOptions: this.ROWS_PER_PAGE_OPTIONS,
            resizableColumns: false,
            reorderableColumns: true,
            dynamicColumns: true,
            toolbar: {
                showExport: true,
                showPreferences: true,
                showResetSort: true,
                showResetColumnWidths: true
            },
            preferences: {
                showResetColumnWidths: true,
                showResetSort: true,
                showResetDefaultPreferences: true
            },
            exportOptions: {
                fileNameWithoutExtension: 'certificateOrdersResults',
                pdfOptions: {
                    pageWidthInPx: 1000,
                    firstPageMarginTop: 70,
                    title: 'Certificate Orders From 2023-01-01 To 2023-01-31',
                    textTopLeft: 'LCE (La Commune Électronique)',
                    textTopRight: 'Certificate Orders [{{nResults}}]',
                    textBottomRight: 'Generated By Ryane Alla',
                    cellAlignCenter: false,
                    autoComputePageWidth: false
                },
                xlsxOptions: {
                    worksheetName: 'Certificate Orders',
                    title: 'This is a test Title',
                    subject: 'Certificate Orders',
                    author: 'LCE (La Commune Électronique)',
                    company: 'Iro-XS Inc.',
                    keywords: ['certificate', 'order'],
                    comments: 'Generated By Ryane Alla'
                }
            },
            buildContextMenuItems: (selection: LCECertificateOrder[]) => this.buildContextMenuItems(selection),
            loaderSize: 30
        };
    }

    private buildContextMenuItems(selection: LCECertificateOrder[]): XSMenuItem[] {
        // TODO: must be translated in table component and add contextMenu styleClass
        return [
            {label: this.translationService.translateKey(this.TR_BASE + 'copyOrderNumber'), command: () => this.copyOrderNumber(selection)},
            {label: this.translationService.translateKey(this.TR_BASE + 'copyReferenceNumber'), command: () => this.copyCertificateReferenceNumber(selection)},
            {label: this.translationService.translateKey(this.TR_BASE + 'viewOrderDetail'), command: () => this.viewOrder(selection)}
        ];
    }

    private copyCertificateReferenceNumber(selection: LCECertificateOrder[]): void {
        this.clipboardService.copy(selection.map((certificateOrder) => certificateOrder.certificate.referenceNumber).join(','));
    }

    private copyOrderNumber(selection: LCECertificateOrder[]): void {
        this.clipboardService.copy(selection.map((certificateOrder) => certificateOrder.orderNumber).join(','));
    }
}
