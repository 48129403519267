import {Component, OnInit} from '@angular/core';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';

@Component({selector: 'lce-template-sheldon', templateUrl: './lce-template-sheldon.component.html'})
export class LCETemplateSheldonComponent implements OnInit {

    readonly ICON = LCE_CMD_ICON;

    ngOnInit() {
    }
}
