<xs-ragnar
        (avatarClickEvent)="onClick($event)"
        (line1ClickEvent)="onClick($event)"
        [avatarClickable]="showOverlay"
        [avatar]="showIcon ? {type: 'icon', data: ICON_FACILITY, size: 'extra-small'} : undefined"
        [disabled]="disabled"
        [label]="label"
        [line1Clickable]="showOverlay"
        [line1]="data.fullName"
        [line2]="showCode ? data.code : data.municipality.district.fullName"
        [showBorder]="showBorder"
        [styleClass]="styleClass"
        avatarStyleClass="lce-resource-partial-avatar-icon xs-color-secondary"
        line1StyleClass="{{ intermediate ? 'xs-font-size-intermediate' : '' }} {{ fullNameStyleClass }}"
        line2StyleClass="xs-color-secondary xs-font-size-small">
</xs-ragnar>

<p-overlayPanel #detailOverlay *ngIf="showOverlay" [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding">
    <lce-facility-partial-overlay [data]="data" [viewRecord]="viewRecord"></lce-facility-partial-overlay>
</p-overlayPanel>
