import {LCEHttpClientService} from '../../lce-http-client.service';
import {Observable} from 'rxjs';
import {LCECertificateOrderCancelReason, LCECertificateOrderErrorReason, LCECertificateOrderUpdateStatusResponse} from '../../../domain/certificate/order/lce-certificate-order';
import {XSAssert, XSBaseService, XSReason} from '@xs/base';
import {LCE_CORE_ENDPOINTS} from '../../../constants/lce-core-endpoint.constant';
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LCECertificateOrderStatusService extends XSBaseService {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.certificateOrders.index);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Processing ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public setOrderAsProcessing(orderID: string): Observable<LCECertificateOrderUpdateStatusResponse> {
        XSAssert.notEmpty(orderID, 'orderID');
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse>(this.buildEndpoint(orderID, LCE_CORE_ENDPOINTS.certificateOrders.status.processing));
    }

    public setOrdersAsProcessing(orderIDs: string[]): Observable<LCECertificateOrderUpdateStatusResponse[]> {
        XSAssert.notEmpty(orderIDs, 'orderIDs');
        const params = new HttpParams().set('orderIDs', orderIDs.join(','));
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.statuses.processing), undefined, params);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Ready ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public setOrderAsReady(orderID: string): Observable<LCECertificateOrderUpdateStatusResponse> {
        XSAssert.notEmpty(orderID, 'orderID');
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse>(this.buildEndpoint(orderID, LCE_CORE_ENDPOINTS.certificateOrders.status.ready));
    }

    public setOrdersAsReady(orderIDs: string[]): Observable<LCECertificateOrderUpdateStatusResponse[]> {
        XSAssert.notEmpty(orderIDs, 'orderIDs');
        const params = new HttpParams().set('orderIDs', orderIDs.join(','));
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.statuses.ready), undefined, params);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Cancelled ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public setOrderAsCancelled(orderID: string, reason: XSReason<LCECertificateOrderCancelReason>): Observable<LCECertificateOrderUpdateStatusResponse> {
        XSAssert.notEmpty(orderID, 'orderID');
        XSAssert.notEmpty(reason, 'reason');
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse>(this.buildEndpoint(orderID, LCE_CORE_ENDPOINTS.certificateOrders.status.cancelled), reason);
    }

    public setOrdersAsCancelled(orderIDs: string[], reason: XSReason<LCECertificateOrderCancelReason>): Observable<LCECertificateOrderUpdateStatusResponse[]> {
        XSAssert.notEmpty(orderIDs, 'orderIDs');
        XSAssert.notEmpty(reason, 'reason');
        const params = new HttpParams().set('orderIDs', orderIDs.join(','));
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.statuses.cancelled), reason, params);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Error ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------

    public setOrderAsError(orderID: string, reason: XSReason<LCECertificateOrderErrorReason>): Observable<LCECertificateOrderUpdateStatusResponse> {
        XSAssert.notEmpty(orderID, 'orderID');
        XSAssert.notEmpty(reason, 'reason');
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse>(this.buildEndpoint(orderID, LCE_CORE_ENDPOINTS.certificateOrders.status.error), reason);
    }

    public setOrdersAsError(orderIDs: string[], reason: XSReason<LCECertificateOrderErrorReason>): Observable<LCECertificateOrderUpdateStatusResponse[]> {
        XSAssert.notEmpty(orderIDs, 'orderIDs');
        XSAssert.notEmpty(reason, 'reason');
        const params = new HttpParams().set('orderIDs', orderIDs.join(','));
        return this.httpClientService.patch<LCECertificateOrderUpdateStatusResponse[]>(this.buildEndpoint(LCE_CORE_ENDPOINTS.certificateOrders.statuses.error), reason, params);
    }
}