import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {LOG, XS_LOREM_IPSUM, XSAssert, XSLanguage, XSStatusAudit, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_DD_MM_YYYY_HH_MM_FR, XS_DATE_FORMAT_YYYY_MM_DD_HH_MM_EN, XSTranslationService} from '@xs/common';
import {XSStatusAuditOptions, XSStatusGeneric} from '@xs/core';
import {LCECertificateOrderDeliveryAssignmentStatus, LCECertificateOrderDeliveryAssignmentStatusAudit} from '@lce/core';
import {LCE_DELIVERY_ASSIGMENT_STATUS_MAP, LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE} from '../../../api/constants/lce-shared.constant';

@Component({selector: 'lce-delivery-assignment-status', templateUrl: './lce-delivery-assignment-status.component.html'})
export class LCEDeliveryAssignmentStatusComponent implements OnInit {

    readonly TR_STATUS = LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE;

    @Input() styleClass?: string;
    @Input() textStyleClass?: string;
    @Input() auditByOverlayStyleClass?: string;

    @Input() status: LCECertificateOrderDeliveryAssignmentStatus;
    @Input() statusAudit: LCECertificateOrderDeliveryAssignmentStatusAudit;

    @Input() showOnlyCircle?: boolean;
    @Input() showBackground?: boolean;
    @Input() showAuditByOverlay?: boolean;

    @Input() clickable?: boolean;

    @Input() verticalGapHeight?: string;

    @Input() auditByOverlayTemplateRef?: TemplateRef<any>;

    statusAuditOptions: XSStatusAuditOptions;

    data: XSStatusGeneric;

    constructor(private translationService: XSTranslationService) {
    }

    get dateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XS_DATE_FORMAT_YYYY_MM_DD_HH_MM_EN : XS_DATE_FORMAT_DD_MM_YYYY_HH_MM_FR;
    }

    get internalStatusAudit(): XSStatusAudit {
        return {
            assigned: this.statusAudit?.assigned,
            unassigned: this.statusAudit?.unassigned,
            accepted: this.statusAudit?.accepted,
            refused: this.statusAudit?.refused,
            deliveryInProgress: this.statusAudit?.deliveryInProgress,
            completed: this.statusAudit?.completed,
            blocked: this.statusAudit?.blocked
        };
    }

    ngOnInit(): void {
        XSAssert.notNull(this.status, 'status');
        if (XSUtils.isNull(this.verticalGapHeight)) this.verticalGapHeight = '100px';
        if (XSUtils.isNull(this.showOnlyCircle)) this.showOnlyCircle = false;
        if (XSUtils.isNull(this.showBackground) && this.showOnlyCircle !== true) this.showBackground = true;
        if (XSUtils.isNull(this.showAuditByOverlay)) this.showAuditByOverlay = true;
        if (this.statusAudit && XSUtils.isEmpty(this.statusAudit![this.status as keyof LCECertificateOrderDeliveryAssignmentStatusAudit])) {
            LOG().error(`unable to find audit for the current status (${this.status}).`);
        }

        this.buildStatusAuditOptions();

        this.data = this.toGenericData();
    }

    private buildStatusAuditOptions(): void {
        this.statusAuditOptions = {
            statusMap: new Map([
                [LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.ASSIGNED).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.UNASSIGNED).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.ACCEPTED).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.COMPLETED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.COMPLETED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.COMPLETED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.COMPLETED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.COMPLETED).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.DELIVERY_IN_PROGRESS).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.BLOCKED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.BLOCKED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.BLOCKED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.BLOCKED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.BLOCKED).backgroundColor
                }],
                [LCECertificateOrderDeliveryAssignmentStatus.REFUSED, {
                    id: LCECertificateOrderDeliveryAssignmentStatus.REFUSED,
                    name: LCE_DELIVERY_ASSIGMENT_STATUS_TR_BASE + LCECertificateOrderDeliveryAssignmentStatus.REFUSED,
                    description: XS_LOREM_IPSUM.short,
                    color: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.REFUSED).color,
                    background: this.statusDetail(LCECertificateOrderDeliveryAssignmentStatus.REFUSED).backgroundColor
                }]
            ]),
            auditOneLine: true,
            auditByShowUserProfileImage: true
        };
    }

    private toGenericData(): XSStatusGeneric {
        return {
            id: this.status,
            status: this.status,
            auditOnBy: this.statusAudit ? this.statusAudit![this.status as keyof LCECertificateOrderDeliveryAssignmentStatusAudit] : undefined,
            color: this.statusDetail(this.status).color,
            background: this.statusDetail(this.status).backgroundColor
        };
    }

    private statusDetail(status: LCECertificateOrderDeliveryAssignmentStatus): { color: string; backgroundColor: string; } {
        return LCE_DELIVERY_ASSIGMENT_STATUS_MAP.get(status as LCECertificateOrderDeliveryAssignmentStatus)!;
    }
}
