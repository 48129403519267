import {XSAssert, XSLanguage} from '@xs/base';
import {LCECertificateType} from '../domain/certificate/lce-certificate';

export class LCEUtils {

    public static getCertificateTypePrefix(certificateType: LCECertificateType): string {
        switch (certificateType) {
            case LCECertificateType.BIRTH:
                return 'BIC';
            case LCECertificateType.MARRIAGE:
                return 'MAC';
            case LCECertificateType.DEATH:
                return 'DEC';
        }
    }

    public static getCertificateLetter(certificateType: LCECertificateType | 'all' | 'total', language: XSLanguage): string {
        XSAssert.notEmpty(language, 'language');
        switch (certificateType) {
            case 'total':
            case 'all':
                return 'T';
            case LCECertificateType.BIRTH:
                return language === XSLanguage.FRENCH ? 'N' : 'B';
            case LCECertificateType.MARRIAGE:
                return 'M';
            case LCECertificateType.DEATH:
                return 'D';
        }
    }
}
