import {Component, Input, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {
    LCE_CERTIFICATE_ORDER_STATUS_ITEMS,
    LCE_CERTIFICATE_TYPE_ITEMS,
    LCECertificateOrderGlobalStatPartial,
    LCECertificateOrderPartial,
    LCECertificateOrderSearch,
    LCECertificateOrderService,
    LCECertificateOrderStatus,
    LCECertificateType
} from '@lce/core';
import {LOG, XS_STR_EMPTY, XS_STR_SPACE, XSAssert, XSInstantRange, XSPagination, XSPredefinedPeriod, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {XS_PREDEFINED_PERIOD_ITEMS, XSCommonUtils, XSLabelValueItem, XSLoaderState, XSText, XSTranslationService} from '@xs/common';
import {XSAdvancedSearchSidebarHideEvent, XSStyleHelper} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderBatchProcessService} from '../api/services/lce-certificate-order-batch-process.service';
import {LCEEventProcessorService} from '../api/services/lce-event-processor.service';
import {LCECertificateOrderBatchProcessDialogService} from '../certificate-order-batch-process/lce-certificate-order-batch-process-dialog.service';
import {LCEStatisticBox} from '../statistic/lce-statistic';
import {LCECertificateOrderLastComponent} from './last/lce-certificate-order-last.component';
import {LCECertificateOrdersFeatureService} from './lce-certificate-orders-feature.service';
import {LCECertificateOrdersPendingOrdersSquareComponent} from './pending-orders/lce-certificate-orders-pending-orders-square.component';
import {LCECertificateOrderDataBoxesComponent} from './data-boxes/lce-certificate-order-data-boxes.component';

@Component({
    selector: 'lce-certificate-orders',
    templateUrl: 'lce-certificate-orders.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCECertificateOrdersComponent implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.';

    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly PAGINATION_SIZE: number = 20;

    readonly STORAGE_KEY = LCECertificateOrdersFeatureService.STORAGE_KEY;
    readonly STORAGE_KEY_STATISTICS_EXPANDED = LCECertificateOrdersFeatureService.STORAGE_KEY_STATISTICS_EXPANDED;

    // === * ===
    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() municipalityCode: string;

    @ViewChild('pendingOrdersSquare') pendingOrdersSquare: LCECertificateOrdersPendingOrdersSquareComponent;
    @ViewChild('lastOrder') lastOrder: LCECertificateOrderLastComponent;
    @ViewChild('dataBoxes') dataBoxes: LCECertificateOrderDataBoxesComponent;

    // === Search ===
    searchText: string;
    searching: boolean;
    certificateOrderSearch: LCECertificateOrderSearch;

    // === Filters ===
    periodFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;
    periodFilterItems: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XS_PREDEFINED_PERIOD_ITEMS);

    statusFilter: LCECertificateOrderStatus[];
    statusFilterItems: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(
        XSCommonUtils.keepItemByValues(LCE_CERTIFICATE_ORDER_STATUS_ITEMS, [
            LCECertificateOrderStatus.PENDING,
            LCECertificateOrderStatus.PROCESSING,
            LCECertificateOrderStatus.ERROR,
            LCECertificateOrderStatus.READY,
            LCECertificateOrderStatus.DELIVERED
        ])
    );

    certificateTypeFilter: LCECertificateType[];
    certificateTypeFilterItems: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(LCE_CERTIFICATE_TYPE_ITEMS);

    // === Statistics ===
    statisticLoading: boolean = false;
    statisticsSectionVisible: boolean = true;

    statData: {
        totalNumberOfOrders: LCEStatisticBox;
        birthCertificateOrders: LCEStatisticBox;
        marriageCertificateOrders: LCEStatisticBox;
        deathCertificateOrders: LCEStatisticBox;
    };
    statsPeriodFilter: XSPredefinedPeriod = XSPredefinedPeriod.TODAY;
    statsPeriodFilterItems: XSLabelValueItem[] = XSCommonUtils.toLabelValueItems(XS_PREDEFINED_PERIOD_ITEMS);

    // === Table ===
    tableData: LCECertificateOrderPartial[] = [];
    tableNResults: number = 0;
    tableCaption: XSText;
    tableSubCaption: XSText;
    tableLoadingState: XSLoaderState;

    // === * ===
    countNonCompletedBatchProcess: string = '...';

    // === * ===
    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private translationService: XSTranslationService,
        private eventProcessorService: LCEEventProcessorService,
        public certificateOrderService: LCECertificateOrderService,
        private certificateOrderBatchProcessService: LCECertificateOrderBatchProcessService,
        private certificateOrderBatchProcessDialogService: LCECertificateOrderBatchProcessDialogService,
        private featureService: LCECertificateOrdersFeatureService) {

        this.initializeFilterItems();

        this.subscription.add(this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.handleNewCertificateOrder()));
        this.subscription.add(
            this.featureService.onStatisticsVisibleChange.subscribe((visible: boolean) => {
                if (!XSUtils.isNull(visible)) this.statisticsSectionVisible = visible;
            })
        );
        this.subscription.add(this.featureService.onBatchProcessCompleted.subscribe(() => this.refresh()));
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
        this.subscription.add(this.featureService.onStartSearch.subscribe(() => this.handleStartSearch()));
        this.subscription.add(this.featureService.onEndSearch.subscribe((searchResults) => this.handleEndSearch(searchResults)));

        // Must be in the constructor, otherwise it won't work.
        this.handleRouterState();

        this.buildStats();
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
        this.refresh();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.municipalityCode) && !changes.municipalityCode!.isFirstChange()) {
            this.search();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public handleDataBoxesCOrderMainValueEvent(event: { certificateType?: LCECertificateType; predefinedPeriod?: XSPredefinedPeriod }): void {
        this.clearSearch();
        if (!XSUtils.isEmpty(event.predefinedPeriod)) this.periodFilter = event.predefinedPeriod!;

        if (!XSUtils.isEmpty(event.certificateType)) this.certificateTypeFilter = [];
        else this.certificateTypeFilter = [event.certificateType!];
        this.search();

    }

    public handleDataBoxesCOrderErrorEvent(event: { certificateType?: LCECertificateType; predefinedPeriod?: XSPredefinedPeriod }) {
        this.clearSearch();

        if (!XSUtils.isEmpty(event.predefinedPeriod)) this.periodFilter = event.predefinedPeriod!;

        if (XSUtils.isEmpty(event.certificateType)) this.certificateTypeFilter = [];
        else this.certificateTypeFilter = [event.certificateType!];

        this.statusFilter = [LCECertificateOrderStatus.ERROR];

        this.search();
    }

    public onBatchProcessManagerClick(): void {
        this.certificateOrderBatchProcessDialogService.openDataManagerDialog({
            readonly: this.readonly,
            onChange: () => this.refresh()
        });
    }

    public onProcessPendingOrders(): void {
        this.featureService.openPendingOrdersDialog(this.municipalityCode);
    }

    public onPagination(pagination: XSPagination): void {
        this.featureService.pagination = pagination;
        this.search();
    }

    public onSort(sort: XSSort): void {
        this.featureService.sort = sort;
        this.featureService.pagination.page = 1;
        this.search();
    }

    public onResetSort(): void {
        this.featureService.sort = undefined;
        this.search();
    }

    public onResetPreferences(): void {
        this.search();
    }

    public onSidebarHide(event: XSAdvancedSearchSidebarHideEvent): void {
        if (event.isFormValid && event.searchFormChanged) this.search();
    }

    public onStatsFilterChange(): void {
        this.retrieveStats();
    }

    public searchPendingOrders(): void {
        return this.search(undefined, LCECertificateOrderStatus.PENDING, undefined);
    }

    public search(predefinedPeriod?: XSPredefinedPeriod, certificateOrderStatus?: LCECertificateOrderStatus, certificateType?: LCECertificateType): void {
        if (!XSUtils.isEmpty(predefinedPeriod) || !XSUtils.isEmpty(certificateOrderStatus) || !XSUtils.isEmpty(certificateType)) {
            this.searchText = XS_STR_EMPTY;
            this.periodFilter = predefinedPeriod!;
            this.statusFilter = XSUtils.isEmpty(certificateOrderStatus) ? [] : [certificateOrderStatus!];
            this.certificateTypeFilter = XSUtils.isEmpty(certificateType) ? [] : [certificateType!];
        }

        this.certificateOrderSearch = {};

        this.certificateOrderSearch = {municipalityCodes: [this.municipalityCode]};

        if (!XSUtils.isEmpty(this.searchText)) this.certificateOrderSearch.query = this.processSearchText(this.searchText);
        if (!XSUtils.isEmpty(this.periodFilter)) this.certificateOrderSearch.createdOnRange = XSInstantRange.fromPredefinedPeriod(this.periodFilter);
        if (!XSUtils.isEmpty(this.statusFilter)) this.certificateOrderSearch.statuses = this.statusFilter;
        if (!XSUtils.isEmpty(this.certificateTypeFilter)) this.certificateOrderSearch.certificateTypes = this.certificateTypeFilter;

        this.featureService.search(this.certificateOrderSearch).subscribe();
    }

    public clearSearch(): void {
        this.certificateOrderSearch = {
            paginationPage: 0,
            paginationSize: this.PAGINATION_SIZE
        };
        this.searchText = XS_STR_EMPTY;
        this.periodFilter = XSPredefinedPeriod.THIS_MONTH;
        this.certificateTypeFilter = [];
        this.statusFilter = [];
    }

    private refresh(): void {
        this.retrieveStats();
        this.retrieveNonCompletedBatchProcess();
        if (!XSUtils.isEmpty(this.dataBoxes)) this.dataBoxes.update(this.periodFilter);
        if (!XSUtils.isNull(this.pendingOrdersSquare)) {
            this.pendingOrdersSquare.update();
        }
        if (!XSUtils.isNull(this.lastOrder)) this.lastOrder.update();
        this.search();
    }

    private retrieveNonCompletedBatchProcess(): void {
        this.countNonCompletedBatchProcess = '...';
        this.subscription.add(
            this.certificateOrderBatchProcessService.countNonCompleted().subscribe((countResult) => {
                this.countNonCompletedBatchProcess = countResult.total.toString();
            })
        );
    }

    private handleNewCertificateOrder(): void {
        this.retrieveStats();
        this.refresh();
    }

    private handleEndSearch(searchResults: XSSearchResult<LCECertificateOrderPartial>): void {
        this.tableData = searchResults.data;
        this.tableNResults = searchResults.total;
        this.tableCaption = {value: this.TR_BASE + 'table.caption'};

        this.searching = false;
        this.tableLoadingState = {state: false};

        // TODO : generate a search explanation based on criteria.
        //this.tableSubCaption = { value: XS_LOREM_IPSUM.medium };
        LOG().debug(this.tableNResults + ' Order(s) Found.');
    }

    private handleStartSearch(loadingText?: string): void {
        this.searching = true;
        this.tableLoadingState = {state: true, text: loadingText};
    }

    private retrieveStats(): void {
        const statsSearch: LCECertificateOrderSearch = {
            createdOnPredefinedPeriod: this.statsPeriodFilter
        };
        statsSearch.municipalityCodes = [this.municipalityCode];

        this.statisticLoading = true;
        this.subscription.add(
            this.certificateOrderService.globalStats(statsSearch).subscribe((stats) => {
                this.updateStatisticBox(this.statsPeriodFilter, stats.total, this.statData.totalNumberOfOrders);
                this.updateStatisticBox(this.statsPeriodFilter, stats.birth, this.statData.birthCertificateOrders);
                this.updateStatisticBox(this.statsPeriodFilter, stats.marriage, this.statData.marriageCertificateOrders);
                this.updateStatisticBox(this.statsPeriodFilter, stats.death, this.statData.deathCertificateOrders);
                this.statisticLoading = false;
            })
        );
    }

    private updateStatisticBox(predefinedPeriod: XSPredefinedPeriod, partialStats: LCECertificateOrderGlobalStatPartial, statisticBox: LCEStatisticBox): void {
        statisticBox.period = predefinedPeriod;
        statisticBox.value = partialStats.value;
        const variationValue = partialStats.value - partialStats.variation;
        statisticBox.variation!.value = Math.abs(variationValue);
        statisticBox.variation!.direction = variationValue > 0 ? 'up' : 'down';
        statisticBox.errorValue = XSUtils.isNull(partialStats.inError) || partialStats.inError === 0 ? undefined : partialStats.inError.toString();
    }

    private buildStats(): void {
        const variationValueStyleClass = XSStyleHelper.CLASS.fontWeight.medium;
        const additionalInfoLine1StyleClass = XSStyleHelper.CLASS.fontWeight.normal + XS_STR_SPACE + XSStyleHelper.CLASS.color.secondary + XS_STR_SPACE + XSStyleHelper.CLASS.fontSize.largeImp;
        this.statData = {
            totalNumberOfOrders: {
                period: this.statsPeriodFilter,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.totalNumberOfOrders',
                additionalInfo: {
                    line1: 'T',
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            birthCertificateOrders: {
                period: this.statsPeriodFilter,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.birthCertificateOrders',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.BIRTH,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            marriageCertificateOrders: {
                period: this.statsPeriodFilter,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.marriageCertificateOrders',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.MARRIAGE,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            },
            deathCertificateOrders: {
                period: this.statsPeriodFilter,
                value: undefined!,
                variation: {value: undefined!, direction: undefined!, valueStyleClass: variationValueStyleClass},
                description: this.TR_BASE + 'stats.deathCertificateOrders',
                additionalInfo: {
                    line1: 'lce.core.certificateTypeLetter.' + LCECertificateType.DEATH,
                    line1StyleClass: additionalInfoLine1StyleClass
                }
            }
        };
    }

    private initializeFilterItems(): void {
        this.translationService.translateItems(this.statsPeriodFilterItems);
        this.translationService.translateItems(this.periodFilterItems);
        this.translationService.translateItems(this.statusFilterItems);
        this.translationService.translateItems(this.certificateTypeFilterItems);

        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statsPeriodFilterItems);
                this.translationService.translateItems(this.periodFilterItems);
                this.translationService.translateItems(this.statusFilterItems);
                this.translationService.translateItems(this.certificateTypeFilterItems);

                this.statsPeriodFilterItems = [...this.statsPeriodFilterItems];
                this.periodFilterItems = [...this.periodFilterItems];
                this.statusFilterItems = [...this.statusFilterItems];
                this.certificateTypeFilterItems = [...this.certificateTypeFilterItems];
            })
        );
    }

    private processSearchText(text: string): string {
        let finalText = text.trim();

        // If phone number ...
        if (XSUtils.containsOnlyDigits(finalText.replace(new RegExp('\\+', 'g'), XS_STR_EMPTY).replace(new RegExp('-', 'g'), XS_STR_EMPTY), true)) {
            finalText = XSUtils.removeWhiteSpaces(finalText).replace(new RegExp('-', 'g'), XS_STR_EMPTY);
        }

        return finalText;
    }

    private handleRouterState(): void {
        const routerStateData = this.router.getCurrentNavigation()?.extras?.state;
        if (!XSUtils.isEmpty(routerStateData)) {
            this.municipalityCode = routerStateData!.municipalityCode;
            const predefinedPeriod: XSPredefinedPeriod = routerStateData!.predefinedPeriod;
            const certificateOrderStatus: LCECertificateOrderStatus = routerStateData!.status;
            const certificateType: LCECertificateType = routerStateData!.certificateType;
            this.search(predefinedPeriod, certificateOrderStatus, certificateType);
        }
    }
}
