import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LCECertificateOrderCreate} from '@lce/core';
import {LOG, XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSConfirmation, XSDialogable} from '@xs/core';
import {XSPaymentChargePartial} from '@xs/payment/base';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderCreateComponent} from '../create/lce-certificate-order-create.component';
import {LCECertificateOrderProcessOptions, LCECertificateOrderProcessStep} from './lce-certificate-order-process';
import {LCECertificateOrderProcessService} from './lce-certificate-order-process.service';
import {LCECertificateOrderProcessCheckoutComponent} from './checkout/lce-certificate-order-process-checkout.component';

@Component({
    selector: 'lce-certificate-order-process',
    templateUrl: './lce-certificate-order-process.component.html',
    providers: [LCECertificateOrderProcessService],
    host: {class: 'xs-flex xs-width-full'}
})
export class LCECertificateOrderProcessComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly CERTIFICATE_ORDER_ICON = LCE_SHARED_ICON.certificateOrder;
    readonly ICON_BACK = LCE_SHARED_ICON.back;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.process.';

    readonly SAVE_ORDER_CREATE_LOADER_ID = XSUtils.uuid() + 'saveOrderCreate';
    readonly RETRIEVE_ORDER_CREATE_LOADER_ID = XSUtils.uuid() + 'retrieveOrderCreate';

    @Input() styleClass?: string;

    @Input() options: LCECertificateOrderProcessOptions;

    @Output() cancelEvent = new EventEmitter<void>();
    @Output() completeEvent = new EventEmitter<void>();
    @Output() backEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<void>();

    @ViewChild('orderCreateForm') orderCreateForm: LCECertificateOrderCreateComponent;
    @ViewChild('checkout') checkout: LCECertificateOrderProcessCheckoutComponent;
    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    processFormGroup = new FormGroup({});

    stepModel: any[] = [
        {id: LCECertificateOrderProcessStep.CREATE, label: this.TR_BASE + 'createStep', disabled: true},
        {id: LCECertificateOrderProcessStep.PAYMENT, label: this.TR_BASE + 'paymentStep', disabled: true},
        {id: LCECertificateOrderProcessStep.CONFIRMATION, label: this.TR_BASE + 'confirmationStep', disabled: true}
    ];

    previousButtonLabel: string = this.TR_BASE + 'previousButtonLabel';
    nextButtonLabel: string = this.TR_BASE + 'nextButtonLabel';

    certificateOrderCreate?: LCECertificateOrderCreate;

    cancelConfirmation: XSConfirmation = {
        key: 'cancelConfirmationKey',
        trMessage: 'xs.core.label.confirmationCancelForm',
        icon: LCE_SHARED_ICON.confirmation,
        accept: () => {
            this.cancel();
        }
    };

    saveOrderCreateError: any;
    retrieveOrderCreateError: any;

    firstInitialization = true;

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private certificateOrderProcessService: LCECertificateOrderProcessService) {
        super();
    }

    get orderCreateSessionID(): string | undefined {
        return this.certificateOrderProcessService.getOrderCreateSessionID();
    }

    get currentStep(): LCECertificateOrderProcessStep {
        return this.certificateOrderProcessService.getCurrentStep();
    }

    get isCheckoutLoaderRunning(): boolean {
        return !XSUtils.isEmpty(this.checkout) && this.checkout!.isCheckoutLoaderRunning();
    }

    get stepTitle(): string {
        switch (this.currentStep) {
            case LCECertificateOrderProcessStep.CREATE:
                return this.TR_BASE + 'createStep';
            case LCECertificateOrderProcessStep.PAYMENT:
                return this.TR_BASE + 'paymentStep';
            case LCECertificateOrderProcessStep.CONFIRMATION:
                return this.TR_BASE + 'confirmationStep';
        }
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.styleClass = this.dialogConfig.data.styleClass;
            this.options = {
                forceShippingInternationalProvider: this.dialogConfig.data.forceShippingInternationalProvider,
                countryISO: this.dialogConfig.data.countryISO,
                parameters: this.dialogConfig.data.parameters,
                useProcuration: this.dialogConfig.data.useProcuration,
                useDelivery: this.dialogConfig.data.useDelivery,
                showProcurationFillForm: this.dialogConfig.data.showProcurationFillForm,
                canUseDeliveryDate: this.dialogConfig.data.canUseDeliveryDate,
                canUseDesiredDeliveryTimeRange: this.dialogConfig.data.canUseDesiredDeliveryTimeRange,
                canUseNote: this.dialogConfig.data.canUseNote,
                showBackButton: this.dialogConfig.data.showBackButton,
                showMakeNewPaymentButton: this.dialogConfig.data.showMakeNewPaymentButton,
                showConfirmationTransactionID: this.dialogConfig.data.showConfirmationTransactionID,
                showFillFormButton: this.dialogConfig.data.showFillFormButton,
                backButtonLabel: this.dialogConfig.data.backButtonLabel,
                facilityCode: this.dialogConfig.data.facilityCode
            };
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }

        XSAssert.notEmpty(this.options, 'options');
        XSAssert.notEmpty(this.options.countryISO, 'options.countryISO');
        if (XSUtils.isNull(this.options.useDelivery)) this.options.useDelivery = true;
        if (XSUtils.isNull(this.options.useProcuration)) this.options.useProcuration = true;

        this.certificateOrderProcessService.validate(this.options.useDelivery, this.options.facilityCode);

        if (XSUtils.isEmpty(this.options.backButtonLabel)) this.options.backButtonLabel = 'xs.common.label.back';

        this.certificateOrderProcessService.initialize();

        if (this.firstInitialization && this.currentStep === LCECertificateOrderProcessStep.PAYMENT) {
            this.goTo(LCECertificateOrderProcessStep.CREATE);
        } else this.goTo(this.currentStep);

        this.firstInitialization = false;
    }

    ngOnDestroy(): void {
        this.leave();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public cancel(): void {
        this.orderCreateForm.reset();
        this.cancelEvent.emit();
    }

    public shouldShowCancelConfirmation(): boolean {
        return !this.isCreateStepFormEmpty();
    }

    public isSaveOrderCreateRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.SAVE_ORDER_CREATE_LOADER_ID);
    }

    public isRetrieveOrderCreateRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.RETRIEVE_ORDER_CREATE_LOADER_ID);
    }

    public canShowErrorActionButton(): boolean {
        return this.certificateOrderProcessService.canShowErrorActionButton();
    }

    public onComplete(): void {
        this.certificateOrderProcessService.setOrderCreateSessionID(undefined);
        this.certificateOrderProcessService.clearLocalStorage();
        this.goTo(LCECertificateOrderProcessStep.CREATE);
        this.completeEvent.emit();
    }

    public onMakeNewOrder(): void {
        this.certificateOrderProcessService.setOrderCreateSessionID(undefined);
        this.certificateOrderProcessService.clearLocalStorage();
        this.goTo(LCECertificateOrderProcessStep.CREATE);
    }

    public onPreviousStep(): void {
        let currentStep = this.certificateOrderProcessService.getCurrentStep();
        if (currentStep !== LCECertificateOrderProcessStep.PAYMENT) {
            throw new Error('previous operation is only allowed on the payment step.');
        }
        this.goTo(LCECertificateOrderProcessStep.CREATE);
        LOG().debug('---| currentStep : ' + currentStep);
    }

    public onNextStep(eventCharge?: XSPaymentChargePartial): void {
        let currentStep = this.certificateOrderProcessService.getCurrentStep();
        if (currentStep === LCECertificateOrderProcessStep.CREATE) this.goTo(LCECertificateOrderProcessStep.PAYMENT);
        else if (currentStep === LCECertificateOrderProcessStep.PAYMENT) {
            if (XSUtils.isEmpty(eventCharge)) {
                throw new Error('payment charge can no be empty on the payment step.');
            }
            this.certificateOrderProcessService.makePayment(eventCharge!);
            this.goTo(LCECertificateOrderProcessStep.CONFIRMATION);
        }
        LOG().debug('---| currentStep : ' + currentStep);
    }

    public onStepClick(orderStep: string): void {
        console.log('---| orderStep Clicked : ' + orderStep + ' || activeStep: ' + orderStep);
        this.goTo(orderStep as LCECertificateOrderProcessStep);
    }

    public isConfirmationStep(): boolean {
        return this.certificateOrderProcessService.isConfirmationStep();
    }

    public isPaymentStep(): boolean {
        return this.certificateOrderProcessService.isPaymentStep();
    }

    public isCreateStep(): boolean {
        return this.certificateOrderProcessService.isCreateStep();
    }

    public hasSaveOrderCreateError(): boolean {
        return !XSUtils.isNull(this.saveOrderCreateError);
    }

    public hasRetrieveOrderCreateError(): boolean {
        return !XSUtils.isNull(this.retrieveOrderCreateError);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Make Payment ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public isCreateStepFormEmpty(): boolean {
        return XSUtils.isNull(this.orderCreateForm) ? true : this.orderCreateForm.isFormEmpty();
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Save / Retrieve Order Create ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    private saveOrderCreate(orderCreate: LCECertificateOrderCreate, fnCallbackNext?: (cOrderCreate?: LCECertificateOrderCreate) => void): void {
        this.saveOrderCreateError = undefined;
        this.retrieveOrderCreateError = undefined;
        this.certificateOrderProcessService.clearOrderCreateSessionIDFromStorage();
        this.loaderService.startLoader(this.SAVE_ORDER_CREATE_LOADER_ID);
        this.subscription.add(
            this.certificateOrderProcessService
                .saveOrderCreate(orderCreate)
                .pipe(finalize(() => this.loaderService.stopLoader(this.SAVE_ORDER_CREATE_LOADER_ID)))
                .subscribe({
                    next: cOrderCreate => fnCallbackNext!(cOrderCreate),
                    error: error => {
                        this.retrieveOrderCreateError = undefined;
                        this.saveOrderCreateError = error;
                    }
                })
        );
    }

    private leave(): void {
        if (this.currentStep === LCECertificateOrderProcessStep.CONFIRMATION) this.certificateOrderProcessService.clearLocalStorage();
        else this.certificateOrderProcessService.setCurrentStep(LCECertificateOrderProcessStep.CREATE);
    }

    private retrieveOrderCreate(): void {
        this.retrieveOrderCreateError = undefined;
        this.loaderService.startLoader(this.RETRIEVE_ORDER_CREATE_LOADER_ID);
        this.subscription.add(
            this.certificateOrderProcessService
                .retrieveOrderCreate()
                .pipe(finalize(() => this.loaderService.stopLoader(this.RETRIEVE_ORDER_CREATE_LOADER_ID)))
                .subscribe({
                    next: certificateOrderCreateSession => {
                        this.certificateOrderCreate = {...certificateOrderCreateSession.orderCreate};
                        console.log('---| Retrieved Saved Order Create', this.certificateOrderCreate);
                    },
                    error: error => {
                        this.certificateOrderCreate = undefined;
                        this.retrieveOrderCreateError = error;
                    }
                })
        );
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    private goTo(step: LCECertificateOrderProcessStep): void {
        XSAssert.notEmpty(step, 'step');
        LOG().debug(`---| currentStep: ${this.currentStep} || step: ${step}`);
        switch (step) {
            case LCECertificateOrderProcessStep.CREATE:
                this.handleStepCreate();
                break;
            case LCECertificateOrderProcessStep.PAYMENT:
                this.handleStepPayment();
                break;
            case LCECertificateOrderProcessStep.CONFIRMATION:
                this.handleStepConfirmation();
                break;
        }
    }

    private handleStepConfirmation(): void {
        this.enableStep(LCECertificateOrderProcessStep.CREATE, false);
        this.enableStep(LCECertificateOrderProcessStep.PAYMENT, false);
        this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, true);
        this.certificateOrderProcessService.setCurrentStep(LCECertificateOrderProcessStep.CONFIRMATION);
    }

    private handleStepPayment(): void {
        this.orderCreateForm?.checkFormValidity();
        this.enableStep(LCECertificateOrderProcessStep.CREATE, true);
        this.enableStep(LCECertificateOrderProcessStep.PAYMENT, true);
        if (this.orderCreateForm?.isFormValid()) {
            this.saveOrderCreate(this.orderCreateForm.getFormValue(), () => {
                this.certificateOrderProcessService.setCurrentStep(LCECertificateOrderProcessStep.PAYMENT);
                this.certificateOrderCreate = undefined;
                this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, false);
                this.certificateOrderProcessService.setCurrentStep(LCECertificateOrderProcessStep.PAYMENT);
            });
        } else {
            LOG().debug('Invalid Order Create Process Form', this.processFormGroup.errors);
        }
    }

    private handleStepCreate(): void {
        this.enableStep(LCECertificateOrderProcessStep.CREATE, true);
        this.enableStep(LCECertificateOrderProcessStep.PAYMENT, false);
        this.enableStep(LCECertificateOrderProcessStep.CONFIRMATION, false);
        if (!this.firstInitialization) this.certificateOrderProcessService.setShowPendingRequest(false);

        this.certificateOrderProcessService.setCurrentStep(LCECertificateOrderProcessStep.CREATE);
        if (!XSUtils.isEmpty(this.orderCreateSessionID)) this.retrieveOrderCreate();
    }

    private enableStep(step: LCECertificateOrderProcessStep, state: boolean): void {
        this.stepModel.find(item => item.id === step).disabled = !state;
    }
}
