<div class="xs-position-relative xs-width-full {{ styleClass }}">

    <xs-swiper [options]="swiperOptions">
        <ng-template xsTemplate="item">
            <div class="xs-flex-row xs-align-items-center">
                <xs-statistic-tile
                        (valueClickEvent)="customerValueClickEvent.emit($event)"
                        [error]="data.customer.error"
                        [icon]="ICON.customer"
                        [loaderID]="data.customer.loaderID"
                        [loading]="data.customer.loading"
                        [showBackground]="true"
                        [showSeparator]="true"
                        [text]="TR_BASE + 'totalCustomers'"
                        [value]="data.customer.value"
                        [zeroLeftPadding]="true"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-325 xs-mr-40"
                        subText="lce.shared.dashboard.summaryBar.mobileApplication">
                </xs-statistic-tile>
                <xs-statistic-tile
                        (valueClickEvent)="certificateOrderValueClickEvent.emit($event)"
                        [error]="data.order.error"
                        [icon]="ICON.certificateOrder"
                        [loaderID]="data.order.loaderID"
                        [loading]="data.order.loading"
                        [showBackground]="true"
                        [showSeparator]="true"
                        [subText]="municipalityFullName"
                        [text]="TR_BASE + 'totalOrders'"
                        [value]="data.order.value"
                        [zeroLeftPadding]="true"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-325 xs-mr-40">
                </xs-statistic-tile>
                <xs-statistic-tile
                        (valueClickEvent)="suggestionValueClickEvent.emit($event)"
                        [error]="data.suggestion.error"
                        [icon]="ICON.suggestion"
                        [loaderID]="data.suggestion.loaderID"
                        [loading]="data.suggestion.loading"
                        [showBackground]="true"
                        [showSeparator]="true"
                        [subText]="municipalityFullName"
                        [text]="TR_BASE + 'totalSuggestions'"
                        [value]="data.suggestion.value"
                        [zeroLeftPadding]="true"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-325 xs-mr-40">
                </xs-statistic-tile>
                <xs-statistic-tile
                        (valueClickEvent)="newsArticleValueClickEvent.emit($event)"
                        [error]="data.newsArticle.error"
                        [icon]="ICON.news"
                        [loaderID]="data.newsArticle.loaderID"
                        [loading]="data.newsArticle.loading"
                        [showBackground]="true"
                        [showSeparator]="true"
                        [subText]="municipalityFullName"
                        [text]="TR_BASE + 'totalNewsArticles'"
                        [value]="data.newsArticle.value"
                        [zeroLeftPadding]="true"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-325 xs-mr-40">
                </xs-statistic-tile>
                <xs-statistic-tile
                        (valueClickEvent)="smartCityArticleValueClickEvent.emit($event)"
                        [error]="data.smartCityArticle.error"
                        [icon]="ICON.smartCity"
                        [loaderID]="data.smartCityArticle.loaderID"
                        [loading]="data.smartCityArticle.loading"
                        [showBackground]="true"
                        [showSeparator]="true"
                        [subText]="municipalityFullName"
                        [text]="TR_BASE + 'totalSmartCityArticles'"
                        [value]="data.smartCityArticle.value"
                        [zeroLeftPadding]="true"
                        iconSize="small"
                        orientation="horizontal"
                        styleClass="xs-width-325">
                </xs-statistic-tile>
            </div>

        </ng-template>
    </xs-swiper>
</div>