<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #centralLoader [loaderId]="LOADER_ID_CENTRAL" [loaderSize]="30"></xs-loader>
    </div>
    <div [ngClass]="{ 'xs-card-border': showBorder }" class="xs-card xs-width-full {{ styleClass }}">
        <div *ngIf="showHeader" class="xs-card-header">
            <xs-title text="{{ TR_BASE + 'title' }}" textStyleClass="xs-color-secondary xs-font-size-medium-imp">
                <ng-template xsTemplate="rightSection">
                    <xs-button
                            (clickEvent)="onEditDelivery()"
                            *ngIf="readonly !== false"
                            iconStyleClass="xs-font-size-13-imp"
                            label="xs.core.label.edit" size="intermediate"
                            type="text">
                    </xs-button>
                </ng-template>
            </xs-title>
        </div>
        <div class="xs-card-content">
            <div class="grid xs-fields xs-fields-spacer-20">
                <div class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-text [label]="TR_BASE + 'birthNumber'" [value]="data!.birthNumber" valueStyleClass="xs-font-size-medium xs-color-secondary">
                    </xs-data-field-text>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-calendar [calendarOptions]="{ type: 'dateTime', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }" [label]="TR_BASE + 'bornOn'"
                                            [value]="data!.bornOn"></xs-data-field-calendar>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <div class="xs-data-field">
                        <xs-data-field-label [label]="TR_BASE + 'declaredBy'" styleClass="xs-mb-10"></xs-data-field-label>
                        <div class="xs-data-field-value-container xs-font-weight-normal-imp">
                            <lce-facility-worker-partial [data]="data!.declaredBy" [showIcon]="true" [showPosition]="true"></lce-facility-worker-partial>
                        </div>
                    </div>
                </div>

                <div class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-text
                            [upperCase]="true"
                            [value]="birthDeclarationCode!"
                            label="xs.core.label.code"
                            valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-calendar
                            [calendarOptions]="{ type: 'dateTime', formatFR: DATE_FORMAT_FR, formatEN: DATE_FORMAT_EN }"
                            [label]="TR_BASE + 'receivedOn'"
                            [value]="data!.receivedOn">
                    </xs-data-field-calendar>
                </div>
                <div class="col-12 md:col-6 lg:col-4"></div>

                <div *ngIf="data!.newBornName?.firstName" class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-text [label]="TR_BASE + 'newBornFirstName'" [value]="data!.newBornName?.firstName"></xs-data-field-text>
                </div>

                <div *ngIf="data!.newBornName?.lastName" class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-text [label]="TR_BASE + 'newBornLastName'" [value]="data!.newBornName?.lastName"></xs-data-field-text>
                </div>

                <div *ngIf="data!.newBornGender" class="col-12 md:col-6 lg:col-4">
                    <xs-data-field-text
                            [label]="TR_BASE + 'newBornGender'"
                            [valueBaseTranslation]="TR_BASE_GENDER"
                            [value]="data!.newBornGender">
                    </xs-data-field-text>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <xs-ivar
            [ellipsis]="true"
            [line1]="TR_BASE + 'title'"
            line2="{{ 'lce.shared.birthDeclaration.label.birth' | translate }} {{ data?.birthNumber }}"
            styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>
