import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCEMunicipality, LCEMunicipalityCanOptions, LCEMunicipalityPartial, LCEMunicipalitySearch, LCEMunicipalityService} from '@lce/core';
import {XSPagination, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDataManagerOptions} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';
import {LCEMunicipalitiesFeatureService} from './lce-municipalities-feature.service';
import {LCEMunicipalityDialogService} from './lce-municipality-dialog.service';

@Component({
    selector: 'lce-municipalities',
    templateUrl: './lce-municipalities.component.html',
    host: {class: 'xs-flex-column xs-flex-1'}
})
export class LCEMunicipalitiesComponent implements OnInit {

    readonly ICON_MUNICIPALITY: string = LCE_SHARED_ICON.municipality;

    readonly TR_CORE: string = 'xs.core.';
    readonly TR_CORE_LABEL: string = this.TR_CORE + 'label.';

    readonly TR_BASE: string = 'lce.shared.municipality.';
    readonly TR_BASE_MAIN: string = this.TR_BASE + 'main.';

    @Input() styleClass?: string;

    @Input() showBackgroundIcon?: boolean;

    @Input() canOptions?: LCEMunicipalityCanOptions;

    @ViewChild('dataManager') dataManager: XSDataManagerComponent;

    dataManagerOptions: XSDataManagerOptions;

    statsLoading: boolean = false;
    stats: XSPKDTOStats;
    statsError: any;

    private subscription: Subscription = new Subscription();

    constructor(
        public municipalityService: LCEMunicipalityService,
        private featureService: LCEMunicipalitiesFeatureService,
        private municipalityDialogService: LCEMunicipalityDialogService) {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.showBackgroundIcon)) this.showBackgroundIcon = true;
        this.buildDataManagerOptions();
        this.retrieveStats();
        this.subscription.add(this.featureService.onRefresh.subscribe(() => this.refresh()));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public canEdit(): boolean {
        return this.canOptions?.update !== false;
    }

    public onEdit(): void {
        this.municipalityDialogService.openCreateUpdateDialog({
            municipality: this.dataManager.selectedRecordData,
            showRecordAfterSave: false,
            onSave: (municipalityRecord) => {
                this.dataManager.updateSelectedRecordData(municipalityRecord);
                this.dataManager.selectedRowResult.fullName = municipalityRecord.fullName;
            }
        });
    }

    private refresh(): void {
        this.retrieveStats();
        this.dataManager.updateSearch();
    }

    private retrieveStats(): void {
        this.statsError = undefined;
        this.statsLoading = true;
        this.subscription.add(
            this.municipalityService
                .retrieveStats()
                .pipe(finalize(() => (this.statsLoading = false)))
                .subscribe({
                    next: (districtStats) => (this.stats = districtStats),
                    error: (error) => (this.statsError = error)
                })
        );
    }

    private retrieveMunicipality(selectedRowResult: LCEMunicipalityPartial): Observable<LCEMunicipality> {
        return this.municipalityService.retrieve(selectedRowResult.id);
    }

    private searchMunicipalities(query?: string, pagination?: XSPagination): Observable<XSSearchResult<LCEMunicipalityPartial>> {
        const municipalitySearch: LCEMunicipalitySearch = {
            query: XSUtils.isEmpty(query) ? '' : XSUtils.trim(query),
            paginationPage: pagination?.page,
            paginationSize: pagination?.size,
            sort: ['name|desc']
        };
        return this.municipalityService.search(municipalitySearch);
    }

    private openAdvancedSearch(): void {
        console.log('openAdvancedSearch ...');
    }

    private buildDataManagerOptions(): void {
        this.dataManagerOptions = {
            listingSectionGridColClass: 'col-4',
            recordSectionGridColClass: 'col-8',

            fnSearch: (query, pagination) => this.searchMunicipalities(query, pagination),
            fnRetrieveRecordData: (selectedRowData) => this.retrieveMunicipality(selectedRowData),

            search: {
                defaultPaginationSize: 20,
                fnAdvancedSearchClick: () => this.openAdvancedSearch()
            },
            results: {
                idFieldName: 'id',
                showToolbar: true,
                toolbar: {
                    showSort: true,
                    showSelectDeselectAll: false
                },
                captionTitle: this.TR_BASE_MAIN + 'resultFound'
            },
            record: {
                showBorder: true,
                scrollable: true
            }
        };
    }
}
