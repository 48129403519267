import {XSGender, XSInstantRange, XSPKDTOAuditCreatedOn, XSPKSearchFilter} from '@xs/base';
import {LCECertificateOrderStatus} from './certificate/order/lce-certificate-order-status';
import {LCENewsArticleStatus} from './news/lce-news';
import {LCESmartCityArticleStatus, LCESmartCityArticleType} from './smartcity/lce-smartcity';
import {LCECertificateType} from './certificate/lce-certificate';

export enum LCENotificationChannel {
    SMS = 'sms',
    EMAIL = 'email',
    WEBSOCKET = 'websocket',
    PUSH = 'push',
}

export interface LCENotificationDisplayData {
    line1: string;
    line2?: string;
}

// ----------------------------------------------------------------------------------------------------------

export interface LCENotificationSmartCityArticleReviewerAdded extends LCENotification {
}

export interface LCENotificationSmartCityArticleNew extends LCENotification {
    data: {
        titleEN: string;
        titleFR: string;
        type: LCESmartCityArticleType;
        status: LCESmartCityArticleStatus;
        createdOn: string;
        coverImage: string;
        municipalityName: string; // municipalityName
    };
}

// ----------------------------------------------------------------------------------------------------------

export interface LCENotificationNewsArticleReviewerAdded extends LCENotificationNewsArticleNew {
}

export interface LCENotificationNewsArticleNew extends LCENotification {
    data: {
        id: string;
        code: string;
        titleEN: string;
        titleFR: string;
        status: LCENewsArticleStatus;
        createdOn: string;
        coverImage: string;
        municipalityName: string; // municipalityName
    };
}

// ----------------------------------------------------------------------------------------------------------

export interface LCENotificationBirthDeclarationNew extends LCENotification {
    data: {
        birthNumber: string;
        code: string;
        bornOn: string;
        gender: XSGender;
        facilityName: string;
        municipalityName: string; // municipalityName
    };
}

// ----------------------------------------------------------------------------------------------------------

export interface LCENotificationCertificateOrder extends LCENotification {
    data: {
        orderNumber: string;
        createdOn: string;
        updatedOn: string;
        certificateType: LCECertificateType;
        status: LCECertificateOrderStatus;
        facilityName: string;
        municipalityName: string; // municipalityName
    };
}

// ----------------------------------------------------------------------------------------------------------
// === * ===
// ----------------------------------------------------------------------------------------------------------

export enum LCENotificationType {
    CERTIFICATE_ORDER_NEW = 'certificateOrder-new',
    CERTIFICATE_ORDER_CANCELLED = 'certificateOrder-cancelled',
    CERTIFICATE_ORDER_OUT_FOR_DELIVERY = 'certificateOrder-outForDelivery',
    CERTIFICATE_ORDER_ERROR = 'certificateOrder-error',
    CERTIFICATE_ORDER_DELIVERED = 'certificateOrder-delivered',
    CERTIFICATE_ORDER_STATUS_CHANGED = 'certificateOrder-statusChanged',

    BIRTH_DECLARATION_NEW = 'birthDeclaration-new',

    NEWS_ARTICLE_NEW = 'newsArticle-new',
    NEWS_ARTICLE_REVIEWER_ADDED = 'newsArticle-reviewerAdded',

    SMART_CITY_ARTICLE_NEW = 'smartCityArticle-new',
    SMART_CITY_ARTICLE_REVIEWER_ADDED = 'smartCityArticle-reviewerAdded',

    PAYMENT_TRANSFER_SUCCESS = 'paymentTransfer-success',
    PAYMENT_TRANSFER_FAILED = 'paymentTransfer-failed',
}

export interface LCENotification extends XSPKDTOAuditCreatedOn {
    id: string;
    type: LCENotificationType;
    data: Object;
    resourceIDs: string[];
    resourceCodes?: string[];
    read?: boolean;
    readOn?: string;
    municipalityCode: string; // municipalityCode
    facilityCode?: string; // facilityCode
}

export interface LCENotificationPartial {
    id: string;
    type: LCENotificationType;
    data: Object;
    resourceIDs: string[];
    resourceCodes?: string[];
    read?: boolean;
    readOn?: string;
}

export interface LCENotificationSearch extends XSPKSearchFilter {
    types?: LCENotificationType[];
    resourceIDs?: string[];
    resourceCodes?: string[];
    read?: boolean;
    readOnRange?: XSInstantRange;
}

export enum LCENotificationReadState {
    ALL = 'all',
    UNREAD = 'unread',
}

export enum LCENotificationPeriod {
    ALL = 'all',
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    THIS_WEEK = 'thisWeek',
}
