import {Component, Input, OnInit} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {of, Subscription} from 'rxjs';
import {LCEUser, LCEUserAccountService, LCEUserMunicipalEmployeeService} from '@lce/core';
import {DatePipe} from '@angular/common';
import {LOG, XS_LOREM_IPSUM, XSAssert, XSUtils} from '@xs/base';
import {
    XSAuthenticationUpdatePassword,
    XSAuthenticationUpdatePINCode,
    XSCoreContextService,
    XSUpdatePasswordOptions,
    XSUpdatePINCodeOptions,
    XSUserTemporaryPasswordCreate,
    XSUserTemporaryPasswordCreateOptions
} from '@xs/core';
import {delay} from 'rxjs/operators';

@Component({
    selector: 'lce-user-account-authentication',
    templateUrl: './lce-user-account-authentication.component.html',
    providers: [DatePipe]
})
export class LCEUserAccountAuthenticationComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.user.label.';
    readonly TR_BASE_COMMON: string = 'xs.common.label.';
    readonly TR_BASE_CORE: string = 'xs.core.label.';

    @Input() styleClass?: string;
    @Input() data: LCEUser;
    @Input() showUpdatePINCode: boolean;


    loading = {
        updatePINCode: false,
        updatePassword: false,
        resetPassword: false
    };
    temporaryPasswordCreateOptions: XSUserTemporaryPasswordCreateOptions;
    updatePinCodeOptions: XSUpdatePINCodeOptions;
    updatePasswordOptions: XSUpdatePasswordOptions;
    description = XS_LOREM_IPSUM.medium;
    private subscription: Subscription = new Subscription();

    constructor(private userService: LCEUserAccountService, private userMunicipalEmployeeService: LCEUserMunicipalEmployeeService, private contextService: XSCoreContextService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'user');
        this.buildOptions();
        if (XSUtils.isNull(this.showUpdatePINCode)) this.showUpdatePINCode = true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public resetPassword(): void {
        this.loading.resetPassword = true;
        this.subscription.add(
            this.userService.resetPassword(this.data.id).subscribe((response) => {
                this.loading.resetPassword = false;
                this.data.passwordUpdatedOn = response.passwordResetOn;
                LOG().debug('User (' + this.data.id + ') Password Reset On ' + response.passwordResetOn + '].');
            })
        );
    }


    private buildOptions(): void {
        this.temporaryPasswordCreateOptions = {
            fnGenerate: (temporaryPasswordCreate: XSUserTemporaryPasswordCreate) => {
                // ....
                return of({
                    pinCodeUpdatedOn: new Date().toDateString(),
                    pinCodeUpdatedBy: undefined
                }).pipe(delay(2000));
            }
        };

        //TODO: Change method updatePassword by updatePinCode
        this.updatePinCodeOptions = {
            fnUpdate: (updatePinCode: XSAuthenticationUpdatePINCode) => {
                // ....
                return this.userMunicipalEmployeeService.updatePINCode(this.data.id, 'currentPassword', updatePinCode.oldPINCode, updatePinCode.newPINCode);
            }
        };

        this.updatePasswordOptions = {
            fnUpdate: (updatePassword: XSAuthenticationUpdatePassword) => {
                // ....
                return this.userService.updatePassword(this.data.id, updatePassword.oldPassword, updatePassword.newPassword);
            }
        };
    }
}
