import {XSIDCodeFullName, XSIDOrderNumber, XSPKAuditFullSearchFilter, XSPKDTOAuditFull} from '@xs/base';
import {LCEUserPartial} from '../user/lce-user';
import {LCEUserMunicipalEmployeePartial} from '../user/lce-user-municipal-employee';
import {LCEUserCustomerPartial} from '../user/lce-user-customer';
import {XSStatusAudit} from '@iro-xs/xs-base/domain/xs-pk-dto';

export interface LCEFacilityTownHallStampTokenCreate {
    facilityCode: string;
    numberOfStamps: number;
    mobilePhoneNumber?: string;
    validUntil?: string;
    note?: string;
}

export enum LCEFacilityTownHallStampTokenStatus {
    UNCONSUMED = 'unconsumed',
    CONSUMED = 'consumed',
    PARTIALLY_CONSUMED = 'partiallyConsumed',
}

export interface LCEFacilityTownHallStampToken extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;
    mobilePhoneNumber?: string;
    customer?: LCEUserCustomerPartial;
    facility: XSIDCodeFullName;
    numberOfStamps: number;
    numberOfStampsLeft?: number;
    qrCode: string;
    validUntil?: string;
    note?: string;
    status: LCEFacilityTownHallStampTokenStatus;
    statusAudit?: XSStatusAudit;
    consumedOn?: string;
    consumedBy?: LCEUserMunicipalEmployeePartial;
    consumptions?: LCEFacilityTownHallStampTokenConsumption<LCEUserMunicipalEmployeePartial>[];
    test?: boolean;
}

export interface LCEFacilityTownHallStampTokenConsumption<T> {
    certificateOrder: XSIDOrderNumber;
    consumedNumberOfStamps: number;
    on: string;
    by: T;
}

export interface LCEFacilityTownHallStampTokenDetail {
    id: string;
    code: string;
    createdOn: string;
    createdBy: LCEUserPartial;
    facility: XSIDCodeFullName;
    numberOfStamps: number;
    mobilePhoneNumber?: string;
    status: LCEFacilityTownHallStampTokenStatus;
    consumedOn?: string;
    qrCode: string;
    test?: boolean;
}

export interface LCEFacilityTownHallStampTokenPartial {
    id: string;
    createdOn: string;
    code: string;
    facility: XSIDCodeFullName;
    numberOfStamps: number;
    numberOfStampsLeft?: number;
    mobilePhoneNumber?: string;
    validUntil?: string;
    status: LCEFacilityTownHallStampTokenStatus;
    consumedOn?: string;
    test?: boolean;
}

export interface LCEFacilityTownHallStampTokenSearch extends XSPKAuditFullSearchFilter {
    codes?: string;

    facilityIDs?: string[];
    facilityCodes?: string[];

    municipalityIDs?: string[];
    municipalityCodes?: string[];

    numberOfStamps?: number[];
    numberOfStampsRange?: string;

    validUntilRange?: string;

    statuses?: LCEFacilityTownHallStampTokenStatus[];

    consumed?: boolean;
    consumedOnRange?: string;
    consumedBys?: string[];
}
