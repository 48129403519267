import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {LCECertificateType, LCECertificateTypeStr} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService, XSLoaderType} from '@xs/common';

@Component({
    selector: 'lce-certificate-order-letter-stat',
    template: `
        <div class="xs-flex-row-center" [ngClass]="{ 'xs-border-discrete xs-border-radius-default xs-plr-15 xs-ptb-10': showBorder !== false }">
            <span class="xs-display-block xs-font-size-extra-large">{{ TR_BASE + type | translate }}</span>
            <span class="xs-display-block xs-font-size-medium xs-font-weight-600 xs-ml-10">{{ valueStr }}</span>
        </div>
        <xs-loader class="xs-position-relative xs-mt-8" [loaderType]="LOADER_TYPE" [loaderId]="LOADER_ID"></xs-loader>
    `,
    host: {class: 'xs-flex-column-center xs-position-relative'}
})
export class LCECertificateOrderLetterStatComponent implements OnInit, OnChanges, AfterViewInit {
    readonly TR_BASE: string = 'lce.core.certificateTypeLetter.';

    LOADER_ID = XSUtils.uuid();
    LOADER_TYPE = XSLoaderType.THREE_BOUNCE;

    @Input() styleClass?: string;
    @Input() showBorder?: boolean;
    @Input() loading?: boolean;

    @Input() type: LCECertificateType | LCECertificateTypeStr;
    @Input() value: number | undefined;

    valueStr: string;

    constructor(private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.type, 'type');
        this.updateValueStr();
    }

    ngAfterViewInit(): void {
        this.updateLoadingState();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.loading) && !changes.loading!.isFirstChange()) this.updateLoadingState();
        if (!XSUtils.isEmpty(changes.value) && !changes.value!.isFirstChange()) this.updateValueStr();
    }

    public updateValueStr(): void {
        this.valueStr = this.loading === true ? '...' : this.value + '';
    }

    private updateLoadingState(): void {
        if (this.loading === true) this.loaderService.startLoader(this.LOADER_ID);
        else this.loaderService.stopLoader(this.LOADER_ID);
    }
}
