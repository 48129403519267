import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCEUserDeliveryManPartial, LCEUserDeliveryManSearch, LCEUserDeliveryManService} from '@lce/core';
import {XSPagination, XSSearchResult, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSLoaderType, XSPersonNamePipe, XSTranslationService} from '@xs/common';
import {XSButton, XSPaginationLoadMoreOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

export enum LCEDeliveryManStatus {
    AVAILABLE = 'available',
    ON_DELIVERY = 'onDelivery',
}

@Component({selector: 'lce-delivery-men-widget', templateUrl: './lce-delivery-men-widget.component.html', providers: [XSPersonNamePipe]})
export class LCEDeliveryMenWidgetComponent implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;
    readonly TR_BASE = 'lce.shared.user.deliveryMen.';
    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly LOADER_ID = XSUtils.uuid();
    readonly ORDER_NUMBER_LOADER_ID = XSUtils.uuid();
    readonly loaderType: XSLoaderType = XSLoaderType.THREE_BOUNCE;

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subTitleStyleClass?: string;

    @Input() title?: string;
    @Input() subTitle?: string;

    @Input() selectMode?: 'multiple' | 'single';

    @Input() showHeaderBackgroundColor?: boolean;
    @Input() showMap?: boolean;
    @Input() disabled: boolean;
    @Input() mapPosition: 'bottom' | 'right';

    @Output() selectEvent = new EventEmitter<LCEUserDeliveryManPartial[] | LCEUserDeliveryManPartial>();

    totalResults: number = 0;

    deliveryMen: LCEUserDeliveryManPartial[];
    selectedDeliveryMen: LCEUserDeliveryManPartial[] = [];

    statusFilter: LCEDeliveryManStatus;
    statusFilterItems: XSLabelValueItem[] = [
        {trLabel: this.TR_BASE + 'status.' + LCEDeliveryManStatus.AVAILABLE, value: LCEDeliveryManStatus.AVAILABLE},
        {trLabel: this.TR_BASE + 'status.' + LCEDeliveryManStatus.ON_DELIVERY, value: LCEDeliveryManStatus.ON_DELIVERY}
    ];

    searchText: string;
    searching: boolean;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    pagination: XSPagination = {
        size: 5,
        page: 0
    };

    deliveryManSearch: LCEUserDeliveryManSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size,
        sort: ['name|desc']
    };

    paginationOptions: XSPaginationLoadMoreOptions = {
        fnSearch: (pagination) => {
            this.deliveryManSearch.paginationPage = pagination.page;
            this.deliveryManSearch.paginationSize = pagination.size;
            return this.deliveryManService.search(this.deliveryManSearch!);
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private deliveryManService: LCEUserDeliveryManService,
        private translationService: XSTranslationService,
        private personNamePipe: XSPersonNamePipe
    ) {
        this.initializeTranslation();
    }

    get mapOnBottom(): boolean {
        return this.mapPosition === 'bottom';
    }

    get mapOnRight(): boolean {
        return this.mapPosition === 'right';
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.selectMode)) this.selectMode = 'single';
        if (XSUtils.isNull(this.showMap)) this.showMap = true;
        if (XSUtils.isEmpty(this.mapPosition)) this.mapPosition = 'bottom';
        if (XSUtils.isEmpty(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isEmpty(this.subTitle)) this.subTitle = this.TR_BASE + 'subTitle';
        this.search();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onInputSearch(): void {
        this.deliveryManSearch.query = this.searchText;
        this.search();
    }

    public update(): void {
        this.search();
    }

    public search(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID);
        this.loaderService.startLoader(this.ORDER_NUMBER_LOADER_ID);
        // if (!XSUtils.isEmpty(this.statusFilter)) this.deliveryManSearch.activeState = this.statusFilter;

        this.deliveryMen = [];
        this.subscription.add(
            this.deliveryManService
                .search(this.deliveryManSearch!)
                .pipe(finalize(() => {
                    this.loaderService.stopLoader(this.LOADER_ID);
                    this.loaderService.stopLoader(this.ORDER_NUMBER_LOADER_ID);
                }))
                .subscribe({
                    next: (searchResults: XSSearchResult<LCEUserDeliveryManPartial>) => {
                        this.deliveryMen = searchResults.data;
                        this.pagination = searchResults.pagination!;
                        this.totalResults = searchResults.total;
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    public onSelect(deliveryMan: LCEUserDeliveryManPartial): void {
        this.handleSelectedDeliveryMen(deliveryMan);
        if (this.selectMode === 'single') {
            const selectedDeliveryMan = this.selectedDeliveryMen[0];
            !XSUtils.isNull(selectedDeliveryMan)
                ? (this.subTitle = `${this.personNamePipe.transform(selectedDeliveryMan.name)} | ${selectedDeliveryMan.code}`)
                : (this.subTitle = this.TR_BASE + 'subTitle');
            this.selectEvent.emit(selectedDeliveryMan);
        } else {
            this.selectEvent.emit(this.selectedDeliveryMen);
        }
    }

    public isSelected(deliveryMan: LCEUserDeliveryManPartial): boolean {
        return !XSUtils.isNull(this.selectedDeliveryMen.find((elt) => elt.id === deliveryMan.id));
    }

    public handlePaginationLoad(searchResults: XSSearchResult<LCEUserDeliveryManPartial>): void {
        this.deliveryMen = this.deliveryMen.concat(searchResults.data);
        this.totalResults = searchResults.total;
    }

    public handlePaginationError(error: any) {
        this.error = error;
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.statusFilterItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.statusFilterItems);
                this.statusFilterItems = [...this.statusFilterItems];
            })
        );
    }

    private handleSelectedDeliveryMen(deliveryMan: LCEUserDeliveryManPartial): void {
        if (this.selectedDeliveryMen.includes(deliveryMan)) {
            let index = this.selectedDeliveryMen.indexOf(deliveryMan);
            this.selectedDeliveryMen.splice(index, 1);
        } else {
            if (this.selectMode === 'single') this.selectedDeliveryMen = [];
            this.selectedDeliveryMen.push(deliveryMan);
        }
    }
}
