<div
        [ngClass]="{ 'xs-p-10 xs-border-radius-default xs-border-discrete': showBorder !== false }"
        class="xs-position-relative xs-flex-column xs-width-full xs-width-full xs-height-full xs-justify-content-center">

    <xs-loader #loader [loaderId]="LOADER_ID" [loaderSize]="LOADER_SIZE" [loaderType]="LOADER_TYPE" class="xs-absolute-center-vh"></xs-loader>


    <div *ngIf="!loader.isRunning()" class="xs-flex-column xs-display-block xs-font-size-percent-80">

        <span *ngIf="showLabel === true" class="xs-display-block xs-mb-8">{{ 'lce.shared.label.lastOrderReceived' | translate }}</span>

        <div *ngIf="!hasError() && (data | xsIsNotEmpty)"
             [showDelay]="2000"
             class="xs-flex-column xs-color-secondary xs-font-weight-500"
             pTooltip="{{ 'lce.core.label.receivedOn' | translate }} {{ data!.createdOn | xsDate : 'xsMediumDateTime' }}"
             tooltipPosition="top"
             tooltipStyleClass="xs-tooltip-ellipsis">

            <span (click)="onOrderNumberClick($event)" [ngClass]="{ 'xs-clickable': orderNumberClickable }" class="xs-display-block xs-width-125">
                {{ data!.orderNumber | uppercase }}
            </span>

            <xs-time-ago [givenDate]="data!.createdOn" [live]="true" styleClass="xs-display-block xs-mt-3 xs-font-weight-400-imp"></xs-time-ago>
        </div>

        <div *ngIf="hasError()" class="xs-flex-column-center">
            <xs-error-icon [clickable]="errorShowDialogOnClick" [error]="error" [showErrorDialogOnClick]="errorShowDialogOnClick">
            </xs-error-icon>
            <span class="xs-display-block xs-color-error xs-mt-3">{{ 'xs.core.label.somethingWentWrong' | translate }}</span>
        </div>

    </div>

    <xs-ivar *ngIf="(data | xsIsEmpty) && !loader.isRunning()" row1="lce.shared.label.noOrderReceived" row1StyleClass="xs-font-size-percent-85-imp xs-color-secondary"></xs-ivar>
</div>
