<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-header xs-pb-15">
        <div class="lce-template-sheldon-router-title-container">
            <span class="lce-template-sheldon-router-title">{{ TR_BASE_MAIN + 'title' | translate }}</span>
            <span class="lce-template-sheldon-router-sub-title">{{ TR_BASE_MAIN + 'subTitle' | translate }}</span>
        </div>
        <div class="lce-template-sheldon-router-action-container">
            <xs-button (clickEvent)="onCreate()" [icon]="ICON.plus" [label]="TR_BASE_MAIN + 'createDistrict'" [transform]="false" class="xs-mr-40" size="intermediate" type="text"></xs-button>
            <xs-button (clickEvent)="onRefresh()" [icon]="ICON.refresh" [transform]="false" type="icon"></xs-button>
        </div>
    </div>
    <div class="lce-template-sheldon-router-content">
        <lce-districts [canOptions]="canOptions" styleClass="xs-mt-25"></lce-districts>
    </div>
</div>
