import { Injectable } from '@angular/core';
import { LCE_CORE_ENDPOINTS } from '../../constants/lce-core-endpoint.constant';
import { LCEUserEmployee, LCEUserEmployeeCreate, LCEUserEmployeePartial, LCEUserEmployeeSearch } from '../../domain/user/lce-user-employee';
import { LCEHttpClientService } from '../lce-http-client.service';
import { LCEUserService } from './lce-user.service';
import { Observable } from 'rxjs';
import { LCEValidatorUtils } from '../../utils/lce-validator-utils';

@Injectable({ providedIn: 'root' })
export class LCEUserEmployeeService extends LCEUserService<LCEUserEmployee, LCEUserEmployeePartial, LCEUserEmployeeSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.employees.index, 'employee');
    }

    public create(employeeCreate: LCEUserEmployeeCreate): Observable<LCEUserEmployee> {
        LCEValidatorUtils.validateEmployeeCreate(employeeCreate);
        return this.httpClientService.post<LCEUserEmployee>(this.buildEndpoint(), employeeCreate);
    }
}
