import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import {
    LCE_CORE_ENDPOINTS,
    LCECoreContextService,
    LCEDeliveryCompany,
    LCEDeliveryCompanyCreate,
    LCEDeliveryCompanyPartial,
    LCEDeliveryCompanyRegistrationResponse,
    LCEDeliveryCompanySearch,
    LCEUserPartial
} from '@lce/core';
import {Injectable} from '@angular/core';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import LCE_DELIVERY_JSON from '../data/lce-delivery-companies.json';
import {LCE_MOCK_ADDRESS_UNSTRUCTURED_YOPOUGON, LCE_MOCK_CONTACT_PERSON_JOHN_DOE} from '@lce/mock/core/lce-mock-various-data';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.deliveryCompanies';

@Injectable()
export class LCEMockDeliveryCompanyHandler extends XSMockPKResourceAuditFullHandler<LCEDeliveryCompany, LCEDeliveryCompanyPartial, LCEDeliveryCompanySearch> {

    public static DELIVERY_COMPANY_STORAGE: Map<string, LCEDeliveryCompany> = new Map<string, LCEDeliveryCompany>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEDeliveryCompany>[] = [
        (deliveryCompany, query) => deliveryCompany.name.toLowerCase().includes(query),
        (deliveryCompany, query) => deliveryCompany.fullName.toLowerCase().includes(query),
        (deliveryCompany, query) => deliveryCompany.shortName?.toLowerCase().includes(query),
        (deliveryCompany, query) => deliveryCompany.municipality?.name.toLowerCase().includes(query)
    ];
    private searchPredicates: XSMockSearchPredicate<LCEDeliveryCompany>[] = [(deliveryCompany, params) => this.httpParamArrayIncludes(params, 'codes', deliveryCompany.code)];

    constructor(private contextService: LCECoreContextService, private mockMunicipalityHandler: LCEMockMunicipalityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.deliveryCompanies.index);
        this.mockDataArray = [];
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public getStorage(): Map<string, LCEDeliveryCompany> {
        return LCEMockDeliveryCompanyHandler.DELIVERY_COMPANY_STORAGE;
    }

    toPartial(deliveryCompany: LCEDeliveryCompany): LCEDeliveryCompanyPartial {
        return {
            id: deliveryCompany.id,
            code: deliveryCompany.code,
            name: deliveryCompany.name,
            fullName: deliveryCompany.fullName,
            shortName: deliveryCompany.shortName!,
            logo: deliveryCompany.logo!
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 5,
                    active: 5,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEDeliveryCompanyPartial> {
        return this.searchResponseData(params, this.queryPredicates);
    }

    private buildStorage(): void {
        LCE_DELIVERY_JSON.forEach((jsonDeliveryCompany) => {
            const registered = XSUtils.randomElement([true, false]);
            const deliveryCompany: LCEDeliveryCompany = {
                address: LCE_MOCK_ADDRESS_UNSTRUCTURED_YOPOUGON,
                municipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
                registered: registered,
                registeredOn: registered !== null ? new Date().toISOString() : undefined,
                registeredBy: registered !== null ? LCEMockUtils.randomUserMunicipalEmployee() : undefined, // TODO : should be a random LCE employee not a municipal employee.
                unregisteredOn: !registered ? new Date().toISOString() : undefined,
                unregisteredBy: !registered ? LCEMockUtils.randomUserMunicipalEmployee() : undefined,  // TODO : should be a random LCE employee not a municipal employee.
                contactPerson: LCE_MOCK_CONTACT_PERSON_JOHN_DOE,
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                ...jsonDeliveryCompany
            };
            LCEMockDeliveryCompanyHandler.DELIVERY_COMPANY_STORAGE.set(deliveryCompany.id, deliveryCompany);
            this.addResourceMockData(deliveryCompany.id);
        });
    }

    private buildUnregisterMockData(deliveryCompanyID: string): XSMockData<LCEDeliveryCompanyRegistrationResponse> {
        let requestURL = `${LCE_CORE_ENDPOINTS.deliveryCompanies.index}/${LCE_CORE_ENDPOINTS.deliveryCompanies.unregister}`;
        requestURL = requestURL.replace('{deliveryCompanyID}', deliveryCompanyID);
        return {
            id: DATASET_BASE_ID + '.unregister.' + deliveryCompanyID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 5000,
            getResponseData: () => {
                const deliveryCompany = this.findOneByID(deliveryCompanyID);
                if (XSUtils.isNull(deliveryCompany)) {
                    throw new HttpErrorResponse({
                        error: {data: `failed to unregister delivery company given by the id : [${deliveryCompanyID}].`},
                        status: HttpStatusCode.InternalServerError,
                        statusText: 'Internal Server Error',
                        url: requestURL
                    });
                }
                return this.buildRegistrationResponse(false, deliveryCompany.code);
            }
        };
    }

    private buildRegisterMockData(deliveryCompanyID: string): XSMockData<LCEDeliveryCompanyRegistrationResponse> {
        let requestURL = `${LCE_CORE_ENDPOINTS.deliveryCompanies.index}/${LCE_CORE_ENDPOINTS.deliveryCompanies.register}`;
        requestURL = requestURL.replace('{deliveryCompanyID}', deliveryCompanyID);
        return {
            id: DATASET_BASE_ID + '.register.' + deliveryCompanyID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.deliveryCompanies.index}/${deliveryCompanyID}/register`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 5000,
            getResponseData: () => {
                const deliveryCompany = this.findOneByID(deliveryCompanyID);
                if (XSUtils.isNull(deliveryCompany)) {
                    throw new HttpErrorResponse({
                        error: {data: `failed to register delivery company given by the id : [${deliveryCompanyID}].`},
                        status: HttpStatusCode.InternalServerError,
                        statusText: 'Internal Server Error',
                        url: requestURL
                    });
                }
                return this.buildRegistrationResponse(true, deliveryCompany.code);
            }
        };
    }

    private buildRegistrationResponse(registered: boolean, code: string): LCEDeliveryCompanyRegistrationResponse {
        const on = new Date().toISOString();
        const by = this.getAuthenticatedUser();
        return {
            code: code,
            registered: registered,
            registeredOn: registered ? on : undefined,
            registeredBy: registered ? by : undefined,
            unregisteredOn: !registered ? on : undefined,
            unregisteredBy: !registered ? by : undefined,
            updatedOn: on,
            updatedBy: by
        };
    }

    private createResponseData(body: any): LCEDeliveryCompany {
        const deliveryCompanyCreate: LCEDeliveryCompanyCreate = body as LCEDeliveryCompanyCreate;
        const deliveryCompany: LCEDeliveryCompany = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
            state: XSPKDTOAuditFullState.ACTIVE,
            name: deliveryCompanyCreate.name,
            fullName: deliveryCompanyCreate.fullName,
            code: 'LCE-DEL-COM' + XSUtils.randomDigits(6),
            shortName: deliveryCompanyCreate.shortName,
            cc: deliveryCompanyCreate.cc,
            address: deliveryCompanyCreate.address,
            municipality: this.mockMunicipalityHandler.findOneByCodeAsPartial(deliveryCompanyCreate.municipalityCode),
            contactPerson: deliveryCompanyCreate.contactPerson
        };

        LCEMockDeliveryCompanyHandler.DELIVERY_COMPANY_STORAGE.set(deliveryCompany.id, deliveryCompany);
        this.addResourceMockData(deliveryCompany.id);
        return deliveryCompany;
    }

    private addResourceMockData(deliveryCompanyID: string) {
        this.addResourceBaseMockData(deliveryCompanyID);
        this.mockDataArray.push(this.buildUnregisterMockData(deliveryCompanyID));
        this.mockDataArray.push(this.buildRegisterMockData(deliveryCompanyID));
    }
}
