import {XSAmount} from '@xs/base';
import {XSPaymentActor} from '@xs/payment/base';
import {XSResourceRegistration} from '../../xs';

export enum LCEFacilityTownHallCertificateOrderRegistrationType {
    STAMP_LOAD = 'stampLoad',
    AUTOMATIC_TRANSFER = 'automaticTransfer'
}

export interface LCEFacilityTownHallCertificateOrderRegistrationStampLoad<T> extends XSResourceRegistration<T> {

    threshold: number;
    totalNumberOfStamp: number;
    totalNumberOfStampsUpdatedOn: number;
}

export interface LCEFacilityTownHallCertificateOrderRegistrationAutomaticTransfer<T> extends XSResourceRegistration<T> {
    receiver: XSPaymentActor;
}

export interface LCEFacilityTownHallCertificateOrderRegistration<T> extends XSResourceRegistration<T> {

    registrationType?: LCEFacilityTownHallCertificateOrderRegistrationType;

    registrationStampLoad?: LCEFacilityTownHallCertificateOrderRegistrationStampLoad<T>;

    registrationAutomaticTransfer?: LCEFacilityTownHallCertificateOrderRegistrationAutomaticTransfer<T>;

    orderFee?: XSAmount;

    orderUnitPrice?: XSAmount;
}
