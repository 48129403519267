import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_MARRIAGE_COLOR, LCEDeliveryCompany} from '@lce/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-delivery-company-register-status', templateUrl: './lce-delivery-company-register-status.component.html'})
export class LCEDeliveryCompanyRegisterStatusComponent implements OnInit {

    ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() containerStyleClass?: string;
    @Input() statusStyleClass?: string;

    @Input() ellipsis?: boolean;
    @Input() showCircle?: boolean;

    @Input() data: LCEDeliveryCompany;
    protected readonly LCE_MARRIAGE_COLOR = LCE_MARRIAGE_COLOR;

    get statusColor(): { status: string, color: string } {
        if ((XSUtils.isNull(this.data.registered) || this.data.registered === false) &&
            XSUtils.isEmpty(this.data.registeredOn) && XSUtils.isEmpty(this.data.unregisteredOn)) {
            return {status: 'notRegisteredYet', color: 'black'};
        } else if (this.data.registered === false) {
            return {status: 'unregistered', color: 'orange'};
        }
        return {status: 'registered', color: 'green'};
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showCircle)) this.showCircle = true;
    }
}
