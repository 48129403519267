import { Injectable } from '@angular/core';
import { LCEHttpClientService } from './lce-http-client.service';
import { LCEEvent, LCEEventPartial, LCEEventSearch } from '../domain/lce-event';
import { Observable } from 'rxjs';
import { XSAssert, XSBaseService, XSSearchResult } from '@xs/base';
import { LCE_CORE_ENDPOINTS } from '../constants/lce-core-endpoint.constant';

@Injectable({ providedIn: 'root' })
export class LCEEventService extends XSBaseService {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.events.index);
    }

    public search(eventSearch: LCEEventSearch): Observable<XSSearchResult<LCEEventPartial>> {
        XSAssert.notEmpty(eventSearch, 'eventSearch');
        let params = this.toHttpParameters(eventSearch);
        return this.httpClientService.get<XSSearchResult<LCEEventPartial>>(this.buildEndpoint(LCE_CORE_ENDPOINTS.events.search), params);
    }

    public retrieve(eventID: string): Observable<LCEEvent> {
        XSAssert.notEmpty(eventID, 'eventID');
        return this.httpClientService.get<LCEEvent>(this.buildEndpoint(eventID));
    }
}
