<div class="{{ styleClass }}">
    <div class="grid grid-nogutter xs-width-full">
        <div class="col-4 xs-flex-row xs-align-items-center xs-justify-content-start xs-pr-25-imp">
            <p-breadcrumb
                    *ngIf="isNavigationVisible()"
                    [home]="getHomeItem()"
                    [model]="getItems()">
            </p-breadcrumb>
        </div>
        <div class="col-8 xs-flex-row xs-align-items-center xs-justify-content-end xs-overflow-hidden">
            <xs-inline-message
                    *ngIf="hasMessage()"
                    [data]="getMessage()"
                    [ellipsis]="true"
                    [textStyleClass]="messageTextStyleClass"
                    class="xs-width-full"
                    styleClass="xs-width-full xs-justify-content-end">
            </xs-inline-message>
        </div>
    </div>
</div>
