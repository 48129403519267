import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XSAssert} from '@xs/base';
import {LCECertificateType} from '@lce/core';

@Component({selector: 'lce-certificate-order-process-pending-request', templateUrl: 'lce-certificate-order-process-pending-request.component.html'})
export class LCECertificateOrderProcessPendingRequestComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() timeAgoStyleClass?: string;

    @Input() certificateType: LCECertificateType;

    @Input() referenceNumber: string;

    @Input() createdOn: string;
    @Input() timeAgoLive?: boolean;
    @Input() timeAgoShortMode?: boolean;

    @Output() continueEvent = new EventEmitter<void>();

    ngOnInit(): void {
        XSAssert.notEmpty(this.certificateType, 'certificateType');
        XSAssert.notEmpty(this.referenceNumber, 'orderNumber');
        XSAssert.notEmpty(this.createdOn, 'createdOn');
    }
}