import {Injectable} from '@angular/core';
import {XSAddress, XSAssert, XSContactPerson, XSPKResourceCodeAuditFullService} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../constants/lce-core-endpoint.constant';
import {LCEServicePoint, LCEServicePointCreate, LCEServicePointPartial, LCEServicePointSearch} from '../domain/lce-service-point';
import {LCEValidatorUtils} from '../utils/lce-validator-utils';
import {LCEHttpClientService} from './lce-http-client.service';

@Injectable({providedIn: 'root'})
export class LCEServicePointService extends XSPKResourceCodeAuditFullService<LCEServicePoint, LCEServicePointPartial, LCEServicePointSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.servicePoints.index, 'servicePoint');
    }

    public updateSecondaryContactPerson(servicePointID: string, secondaryContactPerson: XSContactPerson | undefined):
        Observable<{ id: string; secondaryContactPerson: XSContactPerson; updatedOn: string }> {
        XSAssert.notEmpty(servicePointID, 'servicePointID');
        return this.updateF(servicePointID, {secondaryContactPerson: secondaryContactPerson}, ['id', 'secondaryContactPerson', 'updatedOn']);
    }

    public updatePrimaryContactPerson(servicePointID: string, primaryContactPerson: XSContactPerson):
        Observable<{ id: string; primaryContactPerson: XSContactPerson; updatedOn: string }> {
        XSAssert.notEmpty(servicePointID, 'servicePointID');
        XSAssert.notEmpty(primaryContactPerson, 'primaryContactPerson');
        return this.updateF(servicePointID, {primaryContactPerson: primaryContactPerson}, ['id', 'primaryContactPerson', 'updatedOn']);
    }

    public updateAddress(servicePointID: string, address: XSAddress):
        Observable<{ id: string; address: XSAddress; updatedOn: string }> {
        XSAssert.notEmpty(servicePointID, 'servicePointID');
        XSAssert.notEmpty(address, 'address');
        return this.updateF(servicePointID, {address: address}, ['id', 'address', 'updatedOn']);
    }

    public create(servicePointCreate: LCEServicePointCreate): Observable<LCEServicePoint> {
        LCEValidatorUtils.validateServicePointCreate(servicePointCreate);
        return this.httpClientService.post<LCEServicePoint>(this.buildEndpoint(), servicePointCreate);
    }
}
