import {Component, OnInit} from '@angular/core';
import {LCECertificateOrdersFeatureService} from '../lce-certificate-orders-feature.service';
import {LCESoundAlertEventArg, LCESoundAlertService} from '../../api/services/lce-sound-alert.service';
import {LCECertificateOrderEventName, LCEEventType, LCEResourceType} from '@lce/core';

@Component({selector: 'lce-certificate-orders-feature-settings', templateUrl: './lce-certificate-orders-feature-settings.component.html'})
export class LCECertificateOrdersFeatureSettingsComponent implements OnInit {

    readonly TR_BASE = 'lce.shared.certificateOrders.featureSettings.';

    readonly newOrderEventArg: LCESoundAlertEventArg = {
        eventType: LCEEventType.RESOURCE,
        resourceType: LCEResourceType.CERTIFICATE_ORDER,
        eventName: LCECertificateOrderEventName.NEW_ORDER
    };

    constructor(private featureService: LCECertificateOrdersFeatureService, private soundAlertService: LCESoundAlertService) {
    }

    get soundAlertEnabled(): boolean {
        return this.soundAlertService.isEventSoundAlertEnabled(this.newOrderEventArg);
    }

    ngOnInit(): void {
    }

    public getBatchProcessCodeVisible(): boolean {
        return this.featureService.isBatchProcessCodeVisible();
    }

    public getStatisticsVisible(): boolean {
        return this.featureService.isStatisticsVisible();
    }

    public onBatchProcessCodeVisibleChange(checked: boolean): void {
        this.featureService.setBatchProcessCodeVisible(checked);
    }

    public onStatisticsVisibleChange(checked: boolean): void {
        this.featureService.setStatisticsVisible(checked);
    }

    public onSoundAlertEnabledChange(checked: boolean) {
        this.soundAlertService.setEventSoundAlertEnabled(checked, this.newOrderEventArg);
    }
}
