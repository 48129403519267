import {Injectable} from '@angular/core';
import {XSInlineMessage} from '@xs/core';
import {MenuItem} from 'primeng/api';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LCEBreadcrumbService {

    private messageSubject = new BehaviorSubject<XSInlineMessage | undefined>(undefined);
    onMessage = this.messageSubject.asObservable();

    private navigationVisibleSubject = new BehaviorSubject<boolean>(false);
    onNavigationVisible = this.navigationVisibleSubject.asObservable();

    private readonly items: MenuItem[];

    private readonly homeItem: MenuItem;

    constructor() {
        this.items = [{label: 'Users', routerLink: '/'}, {label: 'New User', routerLink: '/'}, {label: 'Accessories'}];
        this.homeItem = {icon: 'pi pi-home', routerLink: '/'};
    }

    // --------------------------------------------------------------------------------------------------------------------------------------
    // --- Message ---
    // --------------------------------------------------------------------------------------------------------------------------------------

    public getMessage(): XSInlineMessage | undefined {
        return this.messageSubject.getValue();
    }

    public setMessage(message: XSInlineMessage): void {
        this.messageSubject.next(message);
    }

    public clearMessage(): void {
        this.messageSubject.next(undefined);
    }

    // --------------------------------------------------------------------------------------------------------------------------------------
    // --- Navigation ---
    // --------------------------------------------------------------------------------------------------------------------------------------

    public setNavigationVisible(visible: boolean): void {
        this.navigationVisibleSubject.next(visible);
    }

    public isNavigationVisible(): boolean {
        return this.navigationVisibleSubject.getValue();
    }

    // --------------------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------------------

    public getItems(): MenuItem[] {
        return this.items;
    }

    public getHomeItem(): MenuItem {
        return this.homeItem;
    }
}
