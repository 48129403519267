import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {XSAuthenticationForgotPasswordComponent, XSAuthenticationHelpComponent, XSAuthenticationLoginComponent, XSAuthenticationUpdatePasswordRequiredComponent} from '@xs/core';
import {LCECMDAuthenticationComponent} from './authentication/lce-cmd-authentication.component';
import {LCECMDAuthenticationGuard} from './core/guards/lce-cmd-authentication.guard';
import {LCECMDResourcesComponent} from './features/resources/lce-cmd-resources.component';
import {LCECMDResourcesModule} from './features/resources/lce-cmd-resources.module';
import {LCETemplateDefaultComponent} from './templates/default/lce-template-default.component';
import {LCETemplateDefaultExceptionComponent} from './templates/default/exception/lce-template-default-exception.component';
import {LCETemplateExceptionType} from './templates/default/lce-template-default';
import {LCETemplateSheldonComponent} from './templates/sheldon/lce-template-sheldon.component';
import {LCETemplateModule} from './templates/lce-template.module';
import {LCECMDDashboardComponent} from './features/dashboard/lce-cmd-dashboard.component';
import {LceCMDDashboardModule} from './features/dashboard/lce-cmd-dashboard.module';

const routes: Routes = [
    {
        path: '',
        component: LCETemplateDefaultComponent,
        children: [
            {
                path: 'authentication',
                component: LCECMDAuthenticationComponent,
                canActivate: [LCECMDAuthenticationGuard],
                children: [
                    {path: '', redirectTo: 'login'},
                    {path: 'login', component: XSAuthenticationLoginComponent},
                    {path: 'forgot-password', component: XSAuthenticationForgotPasswordComponent},
                    {path: 'update-password-required', component: XSAuthenticationUpdatePasswordRequiredComponent},
                    {path: 'help', component: XSAuthenticationHelpComponent}
                ]
            },
            {path: 'error', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.ERROR}},
            {path: 'not-found', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.NOT_FOUND}},
            {path: 'access-denied', component: LCETemplateDefaultExceptionComponent, data: {exceptionType: LCETemplateExceptionType.ACCESS_DENIED}},
            {path: 'login', redirectTo: 'authentication/login'},
            {path: '', redirectTo: 'authentication/login'}
        ]
    },
    {
        path: '',
        component: LCETemplateSheldonComponent,
        canActivate: [LCECMDAuthenticationGuard],
        children: [
            {path: '', redirectTo: 'dashboard'},
            {path: 'dashboard', component: LCECMDDashboardComponent},
            {path: 'resources', component: LCECMDResourcesComponent},
            {
                path: 'certificate-orders',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-cmd-certificate-orders.module" */ '../../src/app/features/certificate-orders/lce-cmd-certificate-orders.module').then(
                        (m) => m.LCECMDCertificateOrdersModule
                    )
            },
            {
                path: 'certificate-orders-manual-dispatcher',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-cmd-certificate-orders-manual-dispatcher.module" */ '../../src/app/features/manual-dispatcher/lce-cmd-certificate-orders-manual-dispatcher.module').then(
                        (m) => m.LCECMDCertificateOrdersManualDispatcherModule
                    )
            },
            {
                path: 'payments',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-cmd-payments.module" */ '../../src/app/features/payment/lce-cmd-payments.module').then((m) => m.LCECMDPaymentsModule)
            },
            {
                path: 'user-management',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-cmd-user-management.module" */ '../../src/app/features/user-management/lce-cmd-user-management.module').then((m) => m.LCECMDUserManagementModule)
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import(/* webpackChunkName: "lce-cmd-settings.module" */ '../../src/app/features/settings/lce-cmd-settings.module').then((m) => m.LCECMDSettingsModule)
            }
        ]
    },
    {path: '**', redirectTo: 'not-found'},
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}), LCETemplateModule, LceCMDDashboardModule, LCECMDResourcesModule],
    exports: [RouterModule]
})
export class LCECMDRoutingModule {
}
