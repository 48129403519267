import {Injectable} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderCreate, LCECertificateOrderCreateSession, LCECertificateOrderCreateSessionService} from '@lce/core';
import {LOG, XSAssert, XSEnvironmentName, XSUtils} from '@xs/base';
import {XSCoreContextService, XSLocalStorageService} from '@xs/core';
import {XSPaymentChargePartial} from '@xs/payment/base';
import {Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LCECertificateOrderProcessStep} from './lce-certificate-order-process';

@Injectable()
export class LCECertificateOrderProcessService {

    protected resizeSubject = new Subject<void>();
    onResize = this.resizeSubject.asObservable();
    private readonly STORAGE_KEY_ROOT: string = 'certificateOrderProcess';
    private readonly STORAGE_KEY_ORDER_CREATE_SESSION_ID: string = 'orderCreateSessionID';
    private readonly STORAGE_KEY_TRANSACTION_ID: string = 'transactionID';
    private readonly STORAGE_KEY_CURRENT_STEP: string = 'currentStep';
    private readonly STORAGE_KEY_MFO_CERTIFICATE_ORDER: string = 'MFOCertificateOder';

    private orderCreateSessionID: string | undefined;
    private transactionID: string;
    private currentStep: LCECertificateOrderProcessStep;
    private showPendingRequest: boolean = true;

    constructor(
        private contextService: XSCoreContextService,
        private localStorageService: XSLocalStorageService,
        private certificateOrderCreateSessionService: LCECertificateOrderCreateSessionService) {
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public validate(useDelivery: boolean | undefined, facilityCode: string | undefined): void {
        if (!useDelivery && XSUtils.isEmpty(facilityCode)) {
            throw new Error(`facilityCode cannot be empty when delivery is not used.`);
        }

        if (useDelivery && !XSUtils.isEmpty(facilityCode)) {
            throw new Error(`facilityCode cannot be set when delivery is used.`);
        }
    }

    public emitResize() {
        this.resizeSubject.next();
    }

    public isConfirmationStep(): boolean {
        return this.getCurrentStep() === LCECertificateOrderProcessStep.CONFIRMATION;
    }

    public isPaymentStep(): boolean {
        return this.getCurrentStep() === LCECertificateOrderProcessStep.PAYMENT;
    }

    public isCreateStep(): boolean {
        return this.getCurrentStep() === LCECertificateOrderProcessStep.CREATE;
    }

    public setMFOCertificateOrderIDInLocalStorage(certificateOrderID: string): void {
        XSAssert.notEmpty(certificateOrderID, 'certificateOrderID');
        this.setInLocalStorage(this.STORAGE_KEY_MFO_CERTIFICATE_ORDER, certificateOrderID);
    }

    public getMFOCertificateOrderID(): string | undefined {
        return this.getFromLocalStorage(this.STORAGE_KEY_MFO_CERTIFICATE_ORDER);
    }

    public getShowPendingRequest(): boolean {
        return this.showPendingRequest;
    }

    public setShowPendingRequest(showPendingRequest: boolean): void {
        this.showPendingRequest = showPendingRequest;
    }

    public getCurrentStep(): LCECertificateOrderProcessStep {
        if (!XSUtils.isEmpty(this.currentStep)) return this.currentStep;
        const currentStep = this.getFromLocalStorage(this.STORAGE_KEY_CURRENT_STEP) as LCECertificateOrderProcessStep;
        return XSUtils.isEmpty(currentStep) ? LCECertificateOrderProcessStep.CREATE : currentStep;
    }

    public setOrderCreateSessionID(OrderCreateSessionID: string | undefined) {
        this.orderCreateSessionID = OrderCreateSessionID;
    }

    public getOrderCreateSessionID(): string | undefined {
        if (!XSUtils.isEmpty(this.orderCreateSessionID)) return this.orderCreateSessionID;
        let oSessionID = this.getFromLocalStorage(this.STORAGE_KEY_ORDER_CREATE_SESSION_ID);
        return XSUtils.isEmpty(oSessionID) ? undefined : oSessionID;
    }

    public clearOrderCreateSessionIDFromStorage(): void {
        this.clearLocalStorage(this.STORAGE_KEY_ORDER_CREATE_SESSION_ID);
    }

    public getTransactionID(): string | undefined {
        if (!XSUtils.isEmpty(this.transactionID)) return this.transactionID;
        let transactionID = this.getFromLocalStorage(this.STORAGE_KEY_TRANSACTION_ID);
        return XSUtils.isEmpty(transactionID) ? undefined : transactionID;
    }

    public setMFOCurrentStep(step: LCECertificateOrderProcessStep): void {
        XSAssert.notEmpty(step, 'step');
        this.currentStep = step;
        this.setInLocalStorage(this.STORAGE_KEY_CURRENT_STEP, this.currentStep);
    }

    public setCurrentStep(step: LCECertificateOrderProcessStep): void {
        XSAssert.notEmpty(step, 'step');
        if (step === LCECertificateOrderProcessStep.PAYMENT && XSUtils.isEmpty(this.getOrderCreateSessionID())) {
            throw new Error(`[PAYMENT Step] orderCreateSessionID shouldn't be blank.`);
        }
        if (step === LCECertificateOrderProcessStep.CONFIRMATION && XSUtils.isEmpty(this.getTransactionID())) {
            throw new Error(`[CONFIRMATION Step] transactionID shouldn't be blank.`);
        }
        this.currentStep = step;
        this.setInLocalStorage(this.STORAGE_KEY_CURRENT_STEP, this.currentStep);
    }

    public initialize(): void {
        this.currentStep = this.getCurrentStep();
        console.log('---| Current Step: ', this.currentStep);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Confirmation Step ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public retrieveOrder(transactionID: string): Observable<LCECertificateOrder> {
        return of(undefined!);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Payment Step ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public makePayment(charge: XSPaymentChargePartial): any {
        this.transactionID = charge.transactionID;
        this.setInLocalStorage(this.STORAGE_KEY_TRANSACTION_ID, charge.transactionID);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === Create Step ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public retrieveOrderCreate(): Observable<LCECertificateOrderCreateSession> {
        const oSessionID = this.getOrderCreateSessionID();
        if (XSUtils.isEmpty(oSessionID)) of(undefined);
        console.log('===| oSessionID: ', oSessionID);
        return this.certificateOrderCreateSessionService.retrieve(oSessionID!);
    }

    public saveOrderCreate(orderCreate: LCECertificateOrderCreate): Observable<LCECertificateOrderCreate> {
        XSAssert.notEmpty(orderCreate, 'orderCreate');
        let observable: Observable<LCECertificateOrderCreateSession>;
        let operation: string;
        if (XSUtils.isEmpty(this.getOrderCreateSessionID())) {
            operation = 'CREATE';
            observable = this.certificateOrderCreateSessionService.create(orderCreate);
        } else {
            operation = 'UPDATE';
            observable = this.certificateOrderCreateSessionService.update(this.getOrderCreateSessionID()!, orderCreate);
        }
        LOG().debug('---| Saving Order Create [${operation}].');
        console.log(`===| Order Create ${operation}: `, orderCreate);
        return observable
            .pipe(
                tap(orderCreateSession => {
                    this.orderCreateSessionID = orderCreateSession.id;
                    this.setInLocalStorage(this.STORAGE_KEY_ORDER_CREATE_SESSION_ID, this.orderCreateSessionID);
                }),
                map(orderCreateSession => orderCreateSession.orderCreate)
            );
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------

    public canFillForm() {
        return this.isDevelopment();
    }

    public canShowErrorActionButton() {
        return this.isDevelopment();
    }

    public clearLocalStorage(storageKey?: string): void {
        if (XSUtils.isEmpty(storageKey)) {
            this.localStorageService.remove(this.STORAGE_KEY_ROOT);
            return;
        }
        this.localStorageService.removeObjectProperty(this.STORAGE_KEY_ROOT, storageKey!);
    }

    private isDevelopment(): boolean {
        const environmentName: XSEnvironmentName = this.contextService.getAppConfig().environment;
        return environmentName === XSEnvironmentName.NOBACKEND || environmentName === XSEnvironmentName.LOCAL || environmentName === XSEnvironmentName.DEV;
    }

    private setInLocalStorage(storageKey: string, data: string): void {
        return this.localStorageService.setObjectProperty(this.STORAGE_KEY_ROOT, storageKey, data);
    }

    private getFromLocalStorage(storageKey: string): string | undefined {
        return this.localStorageService.getObjectProperty(this.STORAGE_KEY_ROOT, storageKey)?.trim();
    }

}
