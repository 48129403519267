import {Injectable} from '@angular/core';
import {XSAddress, XSAssert, XSContactPerson, XSPKResourceAuditFullService} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../constants/lce-core-endpoint.constant';
import {LCEHttpClientService} from './lce-http-client.service';
import {LCEDeliveryCompany, LCEDeliveryCompanyCreate, LCEDeliveryCompanyPartial, LCEDeliveryCompanyRegistrationResponse, LCEDeliveryCompanySearch} from '../domain/lce-delivery-company';

@Injectable({providedIn: 'root'})
export class LCEDeliveryCompanyService extends XSPKResourceAuditFullService<LCEDeliveryCompany, LCEDeliveryCompanyPartial, LCEDeliveryCompanySearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.deliveryCompanies.index, 'deliveryCompany');
    }

    public create(deliveryCompanyCreate: LCEDeliveryCompanyCreate): Observable<LCEDeliveryCompany> {
        XSAssert.notEmpty(deliveryCompanyCreate, 'deliveryCompanyCreate');
        return this.httpClientService.post<LCEDeliveryCompany>(this.buildEndpoint(), deliveryCompanyCreate);
    }

    public updateContactPerson(deliveryCompanyID: string, contactPerson: XSContactPerson):
        Observable<{ id: string; contactPerson: XSContactPerson; updatedOn: string }> {
        XSAssert.notEmpty(deliveryCompanyID, 'deliveryCompanyID');
        XSAssert.notEmpty(contactPerson, 'contactPerson');
        return this.updateF(deliveryCompanyID, {contactPerson: contactPerson}, ['id', 'contactPerson', 'updatedOn']);
    }

    public updateAddress(deliveryCompanyID: string, address: XSAddress):
        Observable<{ id: string; address: XSAddress; updatedOn: string }> {
        XSAssert.notEmpty(deliveryCompanyID, 'deliveryCompanyID');
        XSAssert.notEmpty(address, 'address');
        return this.updateF(deliveryCompanyID, {address: address}, ['id', 'address', 'updatedOn']);
    }

    public register(deliveryCompanyID: string): Observable<LCEDeliveryCompanyRegistrationResponse> {
        XSAssert.notEmpty(deliveryCompanyID, 'deliveryCompanyID');
        const endpoint = this.buildEndpoint(LCE_CORE_ENDPOINTS.deliveryCompanies.register.replace('{deliveryCompanyID}', deliveryCompanyID));
        return this.httpClientService.patch<LCEDeliveryCompanyRegistrationResponse>(endpoint);
    }

    public unregister(deliveryCompanyID: string): Observable<LCEDeliveryCompanyRegistrationResponse> {
        XSAssert.notEmpty(deliveryCompanyID, 'deliveryCompanyID');
        const endpoint = this.buildEndpoint(LCE_CORE_ENDPOINTS.deliveryCompanies.unregister.replace('{deliveryCompanyID}', deliveryCompanyID));
        return this.httpClientService.patch<LCEDeliveryCompanyRegistrationResponse>(endpoint);
    }
}
