<xs-ragnar
        (avatarClickEvent)="clickEvent.emit(data)"
        (line1ClickEvent)="clickEvent.emit(data)"
        [avatarClickable]="clickable"
        [avatar]="showIcon ? {type: 'icon', data: ICON_DISTRICT, size: 'extra-small'} : undefined"
        [disabled]="disabled"
        [label]="label"
        [line1Clickable]="clickable"
        [line1]="data.fullName"
        [line2]="showCode ? data.code : undefined"
        [styleClass]="styleClass"
        avatarStyleClass="lce-resource-partial-avatar-icon xs-color-secondary"
        line1StyleClass="{{ intermediate ? 'xs-font-size-intermediate' : '' }} {{ fullNameStyleClass }}"
        line2StyleClass="xs-color-secondary xs-font-size-small">
</xs-ragnar>
