import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEDashboardService, LCEDashboardStatsSummaryCountType} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSSwiperOptions} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';

interface SingleData {
    loaderID: string;
    loading?: any;
    value?: number;
    error?: any;
}

@Component({selector: 'lce-dashboard-stats-global', templateUrl: './lce-dashboard-stats-global.component.html'})
export class LCEDashboardStatsGlobalComponent implements OnInit {
    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.dashboard.summaryBar.';

    @Input() styleClass?: string;

    @Input() municipalityCode?: string;
    @Input() municipalityFullName?: string;

    @Output() customerValueClickEvent = new EventEmitter<Event>();
    @Output() certificateOrderValueClickEvent = new EventEmitter<Event>();
    @Output() suggestionValueClickEvent = new EventEmitter<Event>();
    @Output() newsArticleValueClickEvent = new EventEmitter<Event>();
    @Output() smartCityArticleValueClickEvent = new EventEmitter<Event>();

    swiperOptions: XSSwiperOptions;

    data: {
        customer: SingleData;
        order: SingleData;
        suggestion: SingleData;
        newsArticle: SingleData;
        smartCityArticle: SingleData;
    };

    private subscription = new Subscription();

    constructor(private dashboardService: LCEDashboardService, private eventProcessorService: LCEEventProcessorService) {
    }

    ngOnInit(): void {
        if (!XSUtils.isEmpty(this.municipalityCode) || !XSUtils.isEmpty(this.municipalityFullName)) {
            XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
            XSAssert.notEmpty(this.municipalityFullName, 'municipalityFullName');
        }
        this.swiperOptions = this.buildSwiperOptions();

        this.data = {
            customer: {loaderID: 'customerLoaderID'},
            order: {loaderID: 'orderLoaderID'},
            suggestion: {loaderID: 'suggestionLoaderID'},
            newsArticle: {loaderID: 'newsArticleLoaderID'},
            smartCityArticle: {loaderID: 'smartCityArticleLoaderID'}
        };

        this.update();

        this.subscription.add(
            this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.update([LCEDashboardStatsSummaryCountType.CERTIFICATE_ORDER]))
        );
        this.subscription.add(
            this.eventProcessorService.onNewSuggestion.subscribe(() => this.update([LCEDashboardStatsSummaryCountType.SUGGESTION]))
        );
    }

    public update(countTypes?: LCEDashboardStatsSummaryCountType[]): void {
        this.setAllErrorTo(undefined, countTypes);
        this.setAllValueToUndefined(countTypes);
        this.setAllLoadingTo(true, countTypes);
        this.subscription.add(this.dashboardService.retrieveStatsGlobal(this.municipalityCode, countTypes)
            .pipe(finalize(() => {
                this.setAllLoadingTo(false, countTypes);
            }))
            .subscribe({
                next: response => {
                    if (!XSUtils.isNull(response.countCustomers)) this.data.customer.value = response.countCustomers;
                    if (!XSUtils.isNull(response.countCertificateOrders)) this.data.order.value = response.countCertificateOrders;
                    if (!XSUtils.isNull(response.countSuggestions)) this.data.suggestion.value = response.countSuggestions;
                    if (!XSUtils.isNull(response.countNewsArticles)) this.data.newsArticle.value = response.countNewsArticles;
                    if (!XSUtils.isNull(response.countSmartCityArticles)) this.data.smartCityArticle.value = response.countSmartCityArticles;
                },
                error: error => this.setAllErrorTo(error)
            }));
    }

    private setAllLoadingTo(state: boolean, countTypes?: LCEDashboardStatsSummaryCountType[]): void {
        if (XSUtils.isEmpty(countTypes)) {
            this.data.customer.loading = state;
            this.data.order.loading = state;
            this.data.suggestion.loading = state;
            this.data.newsArticle.loading = state;
            this.data.smartCityArticle.loading = state;
            return;
        }
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CUSTOMER)) this.data.customer.loading = state;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CERTIFICATE_ORDER)) this.data.order.loading = state;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SUGGESTION)) this.data.suggestion.loading = state;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.NEWS_ARTICLE)) this.data.newsArticle.loading = state;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SMART_CITY_ARTICLE)) this.data.smartCityArticle.loading = state;
    }

    private setAllValueToUndefined(countTypes?: LCEDashboardStatsSummaryCountType[]): void {
        if (XSUtils.isEmpty(countTypes)) {
            this.data.customer.value = undefined;
            this.data.order.value = undefined;
            this.data.suggestion.value = undefined;
            this.data.newsArticle.value = undefined;
            this.data.smartCityArticle.value = undefined;
            return;
        }
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CUSTOMER)) this.data.customer.value = undefined;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CERTIFICATE_ORDER)) this.data.order.value = undefined;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SUGGESTION)) this.data.suggestion.value = undefined;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.NEWS_ARTICLE)) this.data.newsArticle.value = undefined;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SMART_CITY_ARTICLE)) this.data.smartCityArticle.value = undefined;
    }

    private setAllErrorTo(error: any, countTypes?: LCEDashboardStatsSummaryCountType[]): void {
        if (XSUtils.isEmpty(countTypes)) {
            this.data.customer.error = error;
            this.data.order.error = error;
            this.data.suggestion.error = error;
            this.data.newsArticle.error = error;
            this.data.smartCityArticle.error = error;
            return;
        }
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CUSTOMER)) this.data.customer.error = error;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.CERTIFICATE_ORDER)) this.data.order.error = error;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SUGGESTION)) this.data.suggestion.error = error;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.NEWS_ARTICLE)) this.data.newsArticle.error = error;
        if (countTypes?.includes(LCEDashboardStatsSummaryCountType.SMART_CITY_ARTICLE)) this.data.smartCityArticle.error = error;
    }

    private buildSwiperOptions(): XSSwiperOptions {
        return {
            direction: 'horizontal',

            spaceBetween: 15,
            freeMode: true,
            slidesPerView: 'auto',
            grabCursor: true,

            navigation: true,
            navigationHideOnMouseLeave: true,

            navigationArrowVisibleAfterViewInit: false,

            pagination: false,
            paginationType: 'progressbar',

            autoplay: false,
            autoplayPauseOnMouseEnter: true
        };
    }

}
