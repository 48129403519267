import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
    LCECertificateOrder,
    LCECertificateOrderCancelReason,
    LCECertificateOrderErrorReason,
    LCECertificateOrderPartial,
    LCECertificateOrderStatus,
    LCECertificateOrderStatusService,
    LCECertificateOrderUpdateStatusResponse
} from '@lce/core';
import {XSAssert, XSReason, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSMenuItem, XSReasonCreateUpdateOptions} from '@xs/core';
import {TieredMenu} from 'primeng/tieredmenu';
import {Observable, Subscription} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedUtils} from '../../api/utils/lce-shared-utils';

@Component({selector: 'lce-certificate-order-actions', templateUrl: './lce-certificate-order-actions.component.html'})
export class LCECertificateOrderActionsComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    ORDER_STATUS = LCECertificateOrderStatus;

    readonly TR_ORDER_ERROR_REASON = 'lce.core.certificateOrderError.reason.';
    readonly TR_ORDER_CANCEL_REASON = 'lce.core.certificateOrderCancel.reason.';

    @Input() styleClass?: string;
    @Input() buttonStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() spaceBetween?: string;

    @Input() showColoredIcon?: boolean;

    @Input() data: LCECertificateOrderPartial | LCECertificateOrder;

    @Output() statusUpdatedEvent = new EventEmitter<LCECertificateOrderUpdateStatusResponse>();
    @Output() errorEvent = new EventEmitter<any>();

    @ViewChild('actionMenu') actionMenu: TieredMenu;

    actionMenuItems: XSMenuItem[] = [];

    loading = {
        processing: false,
        ready: false,
        menu: false
    };

    error: any;

    internalDisabled: boolean = false;

    cancelReasonOptions: XSReasonCreateUpdateOptions<LCECertificateOrderCancelReason> = {
        showActionButton: true,
        fnSave: (reason: XSReason<LCECertificateOrderCancelReason>) => this.setOrderAsCancelled(reason),
        dropdownItems: [
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.WAS_IN_ERROR,
                value: LCECertificateOrderCancelReason.WAS_IN_ERROR
            },
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.FRAUD,
                value: LCECertificateOrderCancelReason.FRAUD
            },
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.FAILED_TO_DELIVER_TOO_MANY_TIMES,
                value: LCECertificateOrderCancelReason.FAILED_TO_DELIVER_TOO_MANY_TIMES
            },
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.PAYMENT_ISSUE,
                value: LCECertificateOrderCancelReason.PAYMENT_ISSUE
            },
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.TEST,
                value: LCECertificateOrderCancelReason.TEST
            },
            {
                label: this.TR_ORDER_CANCEL_REASON + LCECertificateOrderCancelReason.OTHER,
                value: LCECertificateOrderCancelReason.OTHER
            }
        ],
        title: 'lce.core.certificateOrderCancel.title',
        subTitle: 'lce.core.certificateOrderCancel.subTitle',
        valuePlaceholder: 'lce.core.certificateOrderCancel.placeholder',
        messageMaxLength: 250,
        saveButtonLabel: 'lce.shared.statusAction.cancel',
        cancelButtonLabel: 'xs.core.label.close'
    };

    errorReasonOptions: XSReasonCreateUpdateOptions<LCECertificateOrderErrorReason> = {
        showActionButton: true,
        fnSave: (reason: XSReason<LCECertificateOrderErrorReason>) => this.setOrderAsError(reason),
        dropdownItems: [
            {
                label: this.TR_ORDER_ERROR_REASON + LCECertificateOrderErrorReason.WRONG_CERTIFICATE_TYPE,
                value: LCECertificateOrderErrorReason.WRONG_CERTIFICATE_TYPE
            },
            {
                label: this.TR_ORDER_ERROR_REASON + LCECertificateOrderErrorReason.WRONG_CERTIFICATE_REFERENCE_NUMBER,
                value: LCECertificateOrderErrorReason.WRONG_CERTIFICATE_REFERENCE_NUMBER
            },
            {
                label: this.TR_ORDER_ERROR_REASON + LCECertificateOrderErrorReason.WRONG_CERTIFICATE_ISSUE_DATE,
                value: LCECertificateOrderErrorReason.WRONG_CERTIFICATE_ISSUE_DATE
            },
            {
                label: this.TR_ORDER_ERROR_REASON + LCECertificateOrderErrorReason.OTHER,
                value: LCECertificateOrderErrorReason.OTHER
            }
        ],
        title: 'lce.core.certificateOrderError.title',
        subTitle: 'lce.core.certificateOrderError.subTitle',
        valuePlaceholder: 'lce.core.certificateOrderError.placeholder',
        messageMaxLength: 250,
        saveButtonLabel: 'lce.shared.statusAction.error',
        cancelButtonLabel: 'xs.core.label.close'
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private cd: ChangeDetectorRef,
        private translationService: XSTranslationService,
        private certificateOrderStatusService: LCECertificateOrderStatusService) {
    }

    get actionIcon(): string | undefined {
        return this.showColoredIcon === true ? this.ICON.circle : undefined;
    }

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
        if (XSUtils.isNull(this.showColoredIcon)) {
            this.showColoredIcon = true;
        }
        if (XSUtils.isEmpty(this.spaceBetween)) {
            this.spaceBetween = '40px';
        }
    }

    public getActionIconStyleClassColor(orderStatus: LCECertificateOrderStatus): string {
        return LCESharedUtils.getCertificateOrderColorStatusStyleClass(orderStatus);
    }

    public canShowStatusActions(): boolean {
        return this.data.status !== LCECertificateOrderStatus.DELIVERED;
    }

    public canShowStatusAction(status: LCECertificateOrderStatus): boolean {
        if (status === LCECertificateOrderStatus.PROCESSING) {
            return this.data.status === LCECertificateOrderStatus.READY ||
                this.data.status === LCECertificateOrderStatus.ERROR ||
                this.data.status === LCECertificateOrderStatus.CANCELLED;

        } else if (status === LCECertificateOrderStatus.READY) {
            return this.data.status === LCECertificateOrderStatus.PROCESSING;
        }
        return true;
    }

    public canEnableStatusAction(status: LCECertificateOrderStatus): boolean {
        return status !== this.data.status;
    }

    public canShowMenu(): boolean {
        return this.isRecord();
    }

    public showRecord(): void {
        console.log('Show Record ...');
    }

    public onMenuClick(event: any): void {
        this.buildActionMenuItems();
        this.actionMenu.toggle(event);
    }

    public buildActionMenuItems(): void {
        this.actionMenuItems = [
            {
                trLabel: 'lce.shared.label.showOrder',
                command: () => this.showRecord()
            }
        ];
        this.translationService.translateItems(this.actionMenuItems);
    }

    public setOrderAsCancelled(reason: XSReason<LCECertificateOrderCancelReason>): Observable<XSReason<LCECertificateOrderCancelReason>> {
        this.error = undefined;
        this.internalDisabled = true;
        this.errorEvent.emit(this.error);
        return this.certificateOrderStatusService
            .setOrderAsCancelled(this.data.id, reason)
            .pipe(
                tap({
                    next: response => this.updateStatusHandleResponse(response),
                    error: error => this.updateStatusHandleError(error)
                }),
                map(() => reason),
                finalize(() => {
                    this.internalDisabled = false;
                })
            );
    }

    public setOrderAsError(reason: XSReason<LCECertificateOrderErrorReason>): Observable<XSReason<LCECertificateOrderErrorReason>> {
        this.error = undefined;
        this.internalDisabled = true;
        this.errorEvent.emit(this.error);
        return this.certificateOrderStatusService
            .setOrderAsError(this.data.id, reason)
            .pipe(
                tap({
                    next: response => this.updateStatusHandleResponse(response),
                    error: error => this.updateStatusHandleError(error)
                }),
                map(() => reason),
                finalize(() => {
                    this.internalDisabled = false;
                })
            );
    }

    public setOrderAsReady(): void {
        this.updateStatusBefore(LCECertificateOrderStatus.READY);
        this.subscription.add(
            this.certificateOrderStatusService
                .setOrderAsReady(this.data.id)
                .pipe(finalize(() => this.updateStatusFinalize(LCECertificateOrderStatus.READY)))
                .subscribe({
                    next: response => this.updateStatusHandleResponse(response),
                    error: (error: any) => this.updateStatusHandleError(error)
                })
        );
    }

    public setOrderAsProcessing(): void {
        this.updateStatusBefore(LCECertificateOrderStatus.PROCESSING);
        this.subscription.add(
            this.certificateOrderStatusService
                .setOrderAsProcessing(this.data.id)
                .pipe(finalize(() => this.updateStatusFinalize(LCECertificateOrderStatus.PROCESSING)))
                .subscribe({
                    next: response => this.updateStatusHandleResponse(response),
                    error: (error: any) => this.updateStatusHandleError(error)
                })
        );
    }

    private updateStatusBefore(certificateOrderStatus: LCECertificateOrderStatus.PROCESSING | LCECertificateOrderStatus.READY): void {
        this.error = undefined;
        this.loading[certificateOrderStatus] = true;
        this.internalDisabled = true;
        this.errorEvent.emit(this.error);
    }

    private updateStatusFinalize(certificateOrderStatus: LCECertificateOrderStatus.PROCESSING | LCECertificateOrderStatus.READY): void {
        this.loading[certificateOrderStatus] = false;
        this.internalDisabled = false;
    }

    private updateStatusHandleError(error: any): void {
        this.error = error;
        this.errorEvent.emit(this.error);
    }

    private updateStatusHandleResponse(response: LCECertificateOrderUpdateStatusResponse): void {
        XSAssert.isTrue(response.id === this.data.id, 'response.id and this.data.id must be equal.');
        XSAssert.isTrue(response.orderNumber === this.data.orderNumber, 'response.id and this.data.id must be equal.');
        this.data.status = response.status;

        console.log();
        this.data.statusUpdatedOn = response.statusUpdatedOn;
        if (this.isRecord()) {
            const record = this.data as LCECertificateOrder;
            record.updatedOn = response.updatedOn;
            record.updatedBy = response.updatedBy;
        }
        this.cd.detectChanges();
        this.statusUpdatedEvent.emit(response);
    }

    private isRecord(): boolean {
        return this.data.hasOwnProperty('qrCode');
    }
}
