import {
    LCEDeliveryDestination,
    LCEDeliveryDestinationCustomLocation,
    LCEDeliveryDestinationFacility,
    LCEDeliveryDestinationInternational,
    LCEDeliveryDestinationServicePoint,
    LCEDeliveryDestinationType,
    LCEFacilityPartial,
    LCEServicePointPartial,
    LCEShippingInternationalProvider
} from '@lce/core';
import {XSAddress, XSAddressStructured, XSAssert} from '@xs/base';

export class LCECertificateOrderUtils {

    public static buildDeliveryDestination(type: LCEDeliveryDestinationType, data: LCEFacilityPartial | LCEServicePointPartial | XSAddress | XSAddressStructured, shippingProvider?: LCEShippingInternationalProvider): LCEDeliveryDestination {
        switch (type) {
            case LCEDeliveryDestinationType.FACILITY:
                return {type: LCEDeliveryDestinationType.FACILITY, facility: (data as LCEFacilityPartial).code} as LCEDeliveryDestinationFacility;
            case LCEDeliveryDestinationType.SERVICE_POINT:
                return {type: LCEDeliveryDestinationType.SERVICE_POINT, servicePoint: (data as LCEServicePointPartial).code} as LCEDeliveryDestinationServicePoint;
            case LCEDeliveryDestinationType.CUSTOM_LOCATION:
                return {type: LCEDeliveryDestinationType.CUSTOM_LOCATION, address: data as XSAddress} as LCEDeliveryDestinationCustomLocation;
            case LCEDeliveryDestinationType.INTERNATIONAL:
                XSAssert.notEmpty(shippingProvider, 'shippingProvider');
                return {type: LCEDeliveryDestinationType.INTERNATIONAL, address: data as XSAddressStructured, shippingProvider: shippingProvider!} as LCEDeliveryDestinationInternational;
        }
    }
}