import {Injectable, Optional} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCEArticleReviewStatus,
    LCEBirthDeclarationEventName,
    LCECertificateOrder,
    LCECertificateOrderEventName,
    LCEDistrictEventName,
    LCEEvent,
    LCEEventPartial,
    LCEEventSearch,
    LCEEventType,
    LCEFacilityEventName,
    LCEMunicipalityEventName,
    LCENewsArticleStatus,
    LCENewsEventName,
    LCENotificationChannel,
    LCEResourceType,
    LCEServicePointEventName,
    LCESmartCityArticleStatus,
    LCESmartCityEventName,
    LCESuggestionEventName,
    LCESuggestionStatus,
    LCEUserPartial
} from '@lce/core';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {XSGender, XSUtils} from '@xs/base';
import {XSMockPKResourceHandler, XSMockSearchQueryPredicate} from '@xs/mock';
import {LCE_MBO_MOCK_CUSTOMER_BRAD_PITT, LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_DISTRICT_CODE_ABIDJAN, LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL, LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON} from '../lce-mock.constant';
import {LCEMockCertificateOrderHandler} from './certificate/lce-mock-certificate-order-handler';
import {LCEMockFacilityHandler} from './facility/lce-mock-facility-handler';
import {LCEMockBirthDeclarationHandler} from './lce-mock-birth-declaration-handler';
import {LCEMockDistrictHandler} from './lce-mock-district-handler';
import {LCEMockMunicipalityHandler} from './lce-mock-municipality-handler';
import {LCEMockServicePointHandler} from './lce-mock-service-point-handler';
import {LCEMockSuggestionHandler} from './lce-mock-suggestion-handler';
import {LCEMockNewsArticleHandler} from './news/lce-mock-news-article-handler';
import {LCEMockSmartCityArticleHandler} from './smartcity/lce-mock-smartcity-article-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.events';

@Injectable()
export class LCEMockEventHandler extends XSMockPKResourceHandler<LCEEvent, LCEEventPartial, LCEEventSearch> {

    public static EVENT_STORAGE: Map<string, LCEEvent> = new Map<string, LCEEvent>();

    constructor(
        private mockDistrictHandler: LCEMockDistrictHandler,
        private mockFacilityHandler: LCEMockFacilityHandler,
        private mockMunicipalityHandler: LCEMockMunicipalityHandler,
        private mockServicePointHandler: LCEMockServicePointHandler,
        private mockCertificateOrderHandler: LCEMockCertificateOrderHandler,
        @Optional() private mockSuggestionHandler: LCEMockSuggestionHandler,
        @Optional() private mockNewsArticleHandler: LCEMockNewsArticleHandler,
        @Optional() private mockSmartCityArticleHandler: LCEMockSmartCityArticleHandler,
        @Optional() private mockBirthDeclarationHandler: LCEMockBirthDeclarationHandler) {

        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.events.index);
        this.mockDataArray = [];

        this.mockCertificateOrderHandler.onCreate.subscribe(certificateOrder => {
                if (XSUtils.isEmpty(certificateOrder)) return;
                const event = this.buildCertificateOrderEvent({resource: certificateOrder});
                LCEMockEventHandler.EVENT_STORAGE.set(event.id, event);
                this.addResourceBaseMockData(event.id);
            }
        );
    }

    public getStorage(): Map<string, LCEEvent> {
        return LCEMockEventHandler.EVENT_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return LCEMockUtils.randomUserMunicipalEmployee();
    }

    public toPartial(event: LCEEvent): LCEEventPartial {
        return {
            id: event.id,
            createdOn: event.createdOn,
            createdBy: event.createdBy,
            type: event.type,
            resourceType: event.resourceType,
            eventName: event.eventName,
            resourceID: event.resourceID,
            resourceCode: event.resourceCode,
            data: event.data
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        const queryPredicates: XSMockSearchQueryPredicate<LCEEvent>[] = [
            (event, query) => event.resourceCode.toLowerCase().includes(query?.toLowerCase())
        ];

        this.mockDataArray = [
            ...this.mockDataArray,
            // Search
            this.buildSearchMockData({
                queryPredicates: queryPredicates,
                predicates: [
                    (event, params) => this.httpParamArrayIncludes(params, 'types', event.type),
                    (event, params) => this.httpParamArrayIncludes(params, 'resourceTypes', event.resourceType),
                    (event, params) => this.httpParamArrayIncludes(params, 'eventNames', event.eventName),
                    (event, params) => this.httpParamArrayIncludes(params, 'resourceIDs', event.resourceID),
                    (event, params) => this.httpParamArrayIncludes(params, 'resourceCodes', event.resourceCode),
                    (event, params) => this.httpParamArrayIncludes(params, 'municipalityCodes', event.municipalityCodes),
                    (event, params) => this.httpParamArrayIncludes(params, 'facilityCodes', event.facilityCodes),
                    (event, params) => this.httpParamArrayIncludes(params, 'districtCodes', event.districtCodes),
                    (event, params) => this.httpParamBooleanEquals(params, 'notifiable', event.notifiable),
                    (event, params) => this.httpParamArrayIncludes(params, 'notificationChannels', event.notificationChannels),
                    (event, params) => this.httpParamBooleanEquals(params, 'notificationProcessed', event.notificationProcessed),
                    (event, params) => this.httpParamBooleanEquals(params, 'batch', event.batch)
                ]
            })
        ];
    }

    private buildStorage(): void {
        this.buildEvents().forEach((event) => {
            LCEMockEventHandler.EVENT_STORAGE.set(event.id, event);
            this.addResourceBaseMockData(event.id);
        });
    }

    private buildEvents(): LCEEvent[] {
        const events = [
            this.buildDistrictEvent(),
            this.buildMunicipalityEvent(),
            this.buildFacilityEvent(),
            this.buildServicePointEvent()
        ];
        if (this.mockBirthDeclarationHandler) events.push(this.buildBirthDeclarationEvent());
        if (this.mockSuggestionHandler) events.push(this.buildSuggestionEvent());
        if (this.mockNewsArticleHandler) events.push(this.buildNewsArticleEvent());
        if (this.mockSmartCityArticleHandler) events.push(this.buildSmartCityArticleEvent());

        return events.concat(this.buildCertificateOrderEvents());
    }

    private buildBirthDeclarationEvent(): LCEEvent {
        const resourceIDCode = this.mockBirthDeclarationHandler.findRandomIDCode();
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.BIRTH_DECLARATION,
            eventName: this.randomBirthDeclarationEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                municipalityCode: LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON,
                birthNumber: XSUtils.randomDigits(10),
                newBornGender: XSUtils.randomElement([XSGender.MALE, XSGender.FEMALE]),
                newBornName: {firstName: 'Amenan Sylvie', lastName: 'Koffi'}
            }
        };
    }

    private buildCertificateOrderEvents(): LCEEvent[] {
        let cOrders = Array.from(this.mockCertificateOrderHandler.getStorage().values());
        let events: LCEEvent[] = [];
        cOrders.forEach(cOrder => {
            events.push(this.buildCertificateOrderEvent(
                {resource: cOrder}
            ));
        });
        return events;
    }

    private buildCertificateOrderEvent(arg?: { resource: LCECertificateOrder }): LCEEvent {
        let resource: LCECertificateOrder;

        if (!XSUtils.isEmpty(arg)) resource = arg!.resource;
        else resource = this.mockCertificateOrderHandler.findOneByID(LCEMockCertificateOrderHandler.DEFAULT_ID);

        return {
            id: XSUtils.uuid(),
            type: LCEEventType.RESOURCE,
            resourceType: LCEResourceType.CERTIFICATE_ORDER,
            eventName: LCECertificateOrderEventName.NEW_ORDER,
            resourceID: resource.id,
            resourceCode: resource.orderNumber,
            districtCodes: [resource.facility.municipality.district.code],
            municipalityCodes: [resource.facility.municipality.code],
            facilityCodes: [resource.facility.code],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: resource.createdBy,
            createdOn: resource.createdOn,
            data: {
                certificateType: resource.certificate.type,
                status: resource.status,
                destination: resource.delivery.destination,
                batchProcessCode: resource.batchProcessCode
            }
        };
    }

    private buildDistrictEvent(): LCEEvent {
        const resourceIDCode = this.mockDistrictHandler.findRandomIDCode();
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.DISTRICT,
            eventName: this.randomDistrictEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                fullName: 'District d\'Abidjan',
                code: LCE_MOCK_DISTRICT_CODE_ABIDJAN
            }
        };
    }

    private buildFacilityEvent(): LCEEvent {
        const resourceIDCode = this.mockFacilityHandler.findRandomIDCode();
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.FACILITY,
            eventName: this.randomFacilityEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                fullName: 'Mairie de Yopougon',
                code: LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL
            }
        };
    }

    private buildMunicipalityEvent(): LCEEvent {
        const resourceIDCode = this.mockMunicipalityHandler.findRandomIDCode();
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.MUNICIPALITY,
            eventName: this.randomMunicipalityEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                fullName: 'Commune de Yopougon',
                code: LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON
            }
        };
    }

    private buildNewsArticleEvent(): LCEEvent {
        const resourceIDCode = this.mockNewsArticleHandler.findRandomIDCode();
        const newsArticleCard = this.mockNewsArticleHandler.findOneByID(resourceIDCode.id);
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.NEWS_ARTICLE,
            eventName: this.randomNewsEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                titleEN: newsArticleCard.title,
                titleFR: newsArticleCard.title,
                status: XSUtils.randomElement(XSUtils.getEnums(LCENewsArticleStatus)),
                coverImage: newsArticleCard.coverImage,
                reviewStatus: XSUtils.randomElement(XSUtils.getEnums(LCEArticleReviewStatus))
            }
        };
    }

    private buildServicePointEvent(): LCEEvent {
        const resourceIDCode = this.mockServicePointHandler.findRandomIDCode();
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.SERVICE_POINT,
            eventName: this.randomServicePointEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                fullName: 'Point de Services Cosmos Yopougon Selmer',
                code: 'LCE-SEP-ABJ-YOP-2233'
            }
        };
    }

    private buildSmartCityArticleEvent(): LCEEvent {
        const resourceIDCode = this.mockSmartCityArticleHandler.findRandomIDCode();
        const smartCityArticleCard = this.mockSmartCityArticleHandler.findOneByIDAsPartial(resourceIDCode.id);
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.SMARTCITY_ARTICLE,
            eventName: this.randomSmartCityEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                titleEN: smartCityArticleCard.title,
                titleFR: smartCityArticleCard.title,
                status: XSUtils.randomElement(XSUtils.getEnums(LCESmartCityArticleStatus)),
                coverImage: smartCityArticleCard.coverImage,
                reviewStatus: XSUtils.randomElement(XSUtils.getEnums(LCEArticleReviewStatus))
            }
        };
    }

    private buildSuggestionEvent(): LCEEvent {
        const resourceIDCode = this.mockSuggestionHandler.findRandomIDCode();
        const isAnonymous = XSUtils.randomElement([true, false]);
        return {
            id: XSUtils.uuid(),
            type: this.randomType(),
            resourceType: LCEResourceType.SUGGESTION,
            eventName: this.randomSuggestionEventName(),
            resourceID: resourceIDCode.id,
            resourceCode: resourceIDCode.code,
            districtCodes: [LCE_MOCK_DISTRICT_CODE_ABIDJAN],
            municipalityCodes: [LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON],
            facilityCodes: [LCE_MOCK_FACILITY_CODE_YOPOUGON_TOWN_HALL],
            notifiable: XSUtils.randomElement([true, false]),
            notificationDelayInSeconds: 2,
            notificationChannels: [this.randomChannel(), this.randomChannel()],
            notificationProcessed: XSUtils.randomElement([true, false]),
            notificationProcessedOn: new Date().toDateString(),
            batch: XSUtils.randomElement([true, false]),
            createdBy: XSUtils.randomElement([LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS]),
            createdOn: new Date().toDateString(),
            data: {
                status: LCESuggestionStatus.CONSIDERED,
                anonymous: isAnonymous,
                customerCode: isAnonymous ? undefined : LCE_MBO_MOCK_CUSTOMER_BRAD_PITT.code
            }
        };
    }

    // ------------------------------------------------------------------------------------------------------------------------------
    // === Random ===
    // ------------------------------------------------------------------------------------------------------------------------------

    private randomType(): LCEEventType {
        return XSUtils.randomElement(XSUtils.getEnums(LCEEventType));
    }

    private randomChannel(): LCENotificationChannel {
        return XSUtils.randomElement(XSUtils.getEnums(LCENotificationChannel));
    }

    private randomBirthDeclarationEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCEBirthDeclarationEventName));
    }

    private randomCertificateOrderEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCECertificateOrderEventName));
    }

    private randomNewsEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCENewsEventName));
    }

    private randomSmartCityEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCESmartCityEventName));
    }

    private randomDistrictEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCEDistrictEventName));
    }

    private randomMunicipalityEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCEMunicipalityEventName));
    }

    private randomFacilityEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCEFacilityEventName));
    }

    private randomServicePointEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCEServicePointEventName));
    }

    private randomSuggestionEventName(): string {
        return XSUtils.randomElement(XSUtils.getEnums(LCESuggestionEventName));
    }
}
