import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_USER_TYPE, LCEUserEmployeePartial, LCEUserMunicipalEmployeePartial, LCEUserPartial, LCEUserPositionPipe, LCEUserType} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSPersonNamePipe, XSTranslationService} from '@xs/common';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-detail-head',
    templateUrl: './lce-user-detail-head.component.html',
    providers: [LCEUserPositionPipe, XSPersonNamePipe],
    styles: [':host { display: flex; width: max-content; }']
})
export class LCEUserDetailHeadComponent implements OnInit {

    readonly ICON_LOGOUT: string = LCE_SHARED_ICON.logout;

    @Input() styleClass?: string;

    @Input() data: LCEUserPartial;

    @Input() account?: boolean;

    @Input() logout?: boolean;
    @Input() loggingOut?: boolean;

    @Output() accountClickEvent = new EventEmitter<Event>();

    @Output() logoutClickEvent = new EventEmitter<Event>();

    private readonly TR_ANONYMOUS: string = 'lce.core.label.anonymous';

    constructor(
        private translationService: XSTranslationService,
        private userPositionPipe: LCEUserPositionPipe,
        private personNamePipe: XSPersonNamePipe) {
    }

    get dataLine1(): string {
        if (XSUtils.isEmpty(this.data.name)) {
            return this.TR_ANONYMOUS;
        }
        return this.personNamePipe.transform(this.data.name);
    }

    get dataLine2(): string {
        if (this.data.type === LCEUserType.EMPLOYEE || this.data.type === LCEUserType.MUNICIPAL_EMPLOYEE) {
            const userPartial = this.data as LCEUserEmployeePartial | LCEUserMunicipalEmployeePartial;
            return this.userPositionPipe.transform(userPartial.position, this.data.type);
        } else {
            return this.translationService.translateKey(LCE_TR_BASE_USER_TYPE + this.data.type);
        }
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.account)) this.account = false;
        if (XSUtils.isNull(this.logout)) this.logout = false;
    }

    public isCustomer(): boolean {
        return this.data.type === LCEUserType.CUSTOMER;
    }

    public isUserSystem(): boolean {
        return this.data.type === LCEUserType.SYSTEM;
    }
}
