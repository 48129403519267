import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {XSUtils} from '@xs/base';

@Injectable({providedIn: 'root'})
export class LCESharedService {

    private batchProcessCompletedSubject = new Subject<void>();
    onBatchProcessCompleted = this.batchProcessCompletedSubject.asObservable();

    private suggestionStatusChangedSubject = new Subject<void>();
    onSuggestionStatusChanged = this.suggestionStatusChangedSubject.asObservable();

    private openCertificateOrderDialogSubject = new Subject<{ orderID?: string; orderNumber?: string; stampTokenClickable?: boolean }>();
    onOpenCertificateOrderDialog = this.openCertificateOrderDialogSubject.asObservable();

    private openCertificateOrderBatchProcessDialogSubject = new Subject<{ batchProcessID?: string; batchProcessCode?: string; }>();
    onOpenCertificateOrderBatchProcessDialog = this.openCertificateOrderBatchProcessDialogSubject.asObservable();

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------
    // === Dialog ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------

    public emitOpenCertificateOrderBatchProcessDialog(arg: { batchProcessID?: string; batchProcessCode?: string; }) {
        if (XSUtils.isEmpty(arg.batchProcessID) && XSUtils.isEmpty(arg.batchProcessCode)) {
            throw new Error(`arg.batchProcessID and arg.orderNumber cannot all be empty at the same time.`);
        }
        if (!XSUtils.isEmpty(arg.batchProcessID) && !XSUtils.isEmpty(arg.batchProcessCode)) {
            throw new Error(`arg.batchProcessID and arg.batchProcessCode cannot both be set at the same time.`);
        }
        this.openCertificateOrderBatchProcessDialogSubject.next(arg);
    }

    public emitOpenCertificateOrderDialog(arg: { orderID?: string; orderNumber?: string; stampTokenClickable?: boolean }) {
        if (XSUtils.isEmpty(arg.orderID) && XSUtils.isEmpty(arg.orderNumber)) {
            throw new Error(`arg.orderID and arg.orderNumber cannot all be empty at the same time.`);
        }
        if (!XSUtils.isEmpty(arg.orderID) && !XSUtils.isEmpty(arg.orderNumber)) {
            throw new Error(`arg.orderID and arg.orderNumber cannot both be set at the same time.`);
        }
        this.openCertificateOrderDialogSubject.next(arg);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------

    public emitSuggestionStatusChanged() {
        this.suggestionStatusChangedSubject.next();
    }

    public emitBatchProcessingCompleted(): void {
        this.batchProcessCompletedSubject.next();
    }
}
