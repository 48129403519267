import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCECertificateOrderConfirmation, LCECertificateOrderService} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrderProcessService} from '../lce-certificate-order-process.service';
import {Message} from 'primeng/api';

@Component({
    selector: 'lce-certificate-order-process-confirmation',
    templateUrl: './lce-certificate-order-process-confirmation.component.html',
    providers: [LCECertificateOrderProcessService]
})
export class LCECertificateOrderProcessConfirmationComponent implements OnInit, OnDestroy, OnChanges {

    readonly ICON = LCE_SHARED_ICON;
    readonly ICON_CERTIFICATE = LCE_SHARED_ICON.certificateOrder;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE = 'lce.shared.certificateOrders.process.confirmation.';

    readonly SUCCESS_MESSAGE_KEY: string = 'successMessageKey';
    readonly ERROR_MESSAGE_KEY: string = 'errorMessageKey';

    @Input() styleClass?: string;

    @Input() transactionID?: string;
    @Input() data?: LCECertificateOrderConfirmation;

    @Input() error?: any;
    @Input() loading?: boolean;
    @Input() showBorder?: boolean;
    @Input() showMakeNewPaymentButton?: boolean;
    @Input() showTransactionID?: boolean;

    @Output() completeEvent = new EventEmitter<void>();
    @Output() makeNewOrderEvent = new EventEmitter<void>();

    successMessage: Message | undefined;
    errorMessage: Message | undefined;

    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private translationService: XSTranslationService,
        private certificateOrderService: LCECertificateOrderService,
        private certificateOrderProcessService: LCECertificateOrderProcessService) {
    }

    ngOnInit(): void {
        this.transactionID = !XSUtils.isNull(this.transactionID) ? this.transactionID : this.certificateOrderProcessService.getTransactionID();

        if (XSUtils.isEmpty(this.transactionID) && XSUtils.isNull(this.data)) {
            throw new Error('transactionID and data cannot both be empty at the same time.');
        }

        if (!XSUtils.isEmpty(this.transactionID) && !XSUtils.isNull(this.data)) {
            throw new Error('transactionID and data cannot both be set at the same time.');
        }

        if (!XSUtils.isNull(this.data)) {
            XSAssert.notEmpty(this.loading, 'loading');
        }

        if (XSUtils.isNull(this.showBorder)) this.showBorder = true;
        if (!XSUtils.isNull(this.transactionID)) this.retrieveCertificateOrderByTransactionID();

        this.successMessage = this.buildSuccessMessage();
        this.errorMessage = this.buildErrorMessage();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.loading)) {
            if (this.loading) this.loaderService.startLoader(this.LOADER_ID!);
            else this.loaderService.stopLoader(this.LOADER_ID!);
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public onNewPayment(): void {
        this.makeNewOrderEvent.emit();
    }

    public complete(): void {
        this.completeEvent.emit();
    }

    private retrieveCertificateOrderByTransactionID(): void {

        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.certificateOrderService
                .retrieveByTransactionID(this.transactionID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: certificateOrder => {
                        this.data = certificateOrder;
                        console.log('Confirmation certificate Order', this.data);
                    },
                    error: (error: any) => {
                        this.error = error;
                    }
                })
        );
    }

    private buildErrorMessage(): Message {
        return {
            severity: 'error',
            summary: this.translationService.translateKey(this.TR_BASE + 'errorMessageLine1'),
            detail: this.translationService.translateKey(this.TR_BASE + 'errorMessageLine2'),
            closable: false
        };
    }

    private buildSuccessMessage(): Message {
        return {
            severity: 'success',
            summary: this.translationService.translateKey(this.TR_BASE + 'successMessage1'),
            detail: this.translationService.translateKey(this.TR_BASE + 'successMessage2'),
            closable: false
        };
    }
}
