import {HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {XSAssert, XSContactPerson, XSStringResponse} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEUserMunicipalEmployee, LCEUserMunicipalEmployeeCreate, LCEUserMunicipalEmployeePartial, LCEUserMunicipalEmployeeSearch, LCEUserMunicipalEmployeeUpdatePINCodeResponse} from '../../domain/user/lce-user-municipal-employee';
import {LCEValidatorUtils} from '../../utils/lce-validator-utils';
import {LCEHttpClientService} from '../lce-http-client.service';
import {LCEUserService} from './lce-user.service';

@Injectable({providedIn: 'root'})
export class LCEUserMunicipalEmployeeService extends LCEUserService<LCEUserMunicipalEmployee, LCEUserMunicipalEmployeePartial, LCEUserMunicipalEmployeeSearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.municipalEmployees.index, 'municipalEmployee');
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    public retrievePINCodePlainText(userID: string): Observable<XSStringResponse> {
        XSAssert.notEmpty(userID, 'userID');
        return this.httpClientService.get<XSStringResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.municipalEmployees.pinCodePlaintText));
    }

    public generatePINCode(userID: string): Observable<LCEUserMunicipalEmployeeUpdatePINCodeResponse> {
        XSAssert.notEmpty(userID, 'userID');
        return this.httpClientService.patch<LCEUserMunicipalEmployeeUpdatePINCodeResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.municipalEmployees.pinCodeGenerate));
    }

    public updatePINCode(userID: string, currentPassword: string, currentPINCode: string, newPINCode: string): Observable<LCEUserMunicipalEmployeeUpdatePINCodeResponse> {
        XSAssert.notEmpty(userID, 'userID');
        XSAssert.notEmpty(currentPassword, 'currentPassword');
        XSAssert.notEmpty(currentPINCode, 'currentPINCode');
        XSAssert.notEmpty(newPINCode, 'newPINCode');
        const params: HttpParams = new HttpParams().set('currentPassword', currentPassword.trim()).set('currentPINCode', currentPINCode).set('newPINCode', newPINCode.trim());
        return this.httpClientService.patch<LCEUserMunicipalEmployeeUpdatePINCodeResponse>(this.buildEndpoint(userID, LCE_CORE_ENDPOINTS.municipalEmployees.pinCodeUpdate), null, params);
    }

    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------------------------------------------------------------

    public updateEmergencyContactPerson(
        municipalEmployeeID: string,
        contactPerson: XSContactPerson | undefined
    ): Observable<{ id: string; emergencyContactPerson: XSContactPerson; updatedOn: string }> {
        XSAssert.notEmpty(municipalEmployeeID, 'municipalEmployeeID');
        XSAssert.notEmpty(contactPerson, 'address');
        return this.updateF(municipalEmployeeID, {emergencyContactPerson: contactPerson}, ['id', 'emergencyContactPerson', 'updatedOn']);
    }

    public create(municipalEmployeeCreate: LCEUserMunicipalEmployeeCreate): Observable<LCEUserMunicipalEmployee> {
        LCEValidatorUtils.validateMunicipalEmployeeCreate(municipalEmployeeCreate);
        return this.httpClientService.post<LCEUserMunicipalEmployee>(this.buildEndpoint(), municipalEmployeeCreate);
    }
}
