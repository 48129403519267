import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEMunicipality, LCEMunicipalityCreate, LCEMunicipalityPartial, LCEMunicipalitySearch, LCEMunicipalitySettings, LCEUserPartial} from '@lce/core';
import {XSContactPerson, XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSBabavoss} from '@xs/common';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_MUNICIPALITY_JSON from '../data/lce-municipalities.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCEMockUtils} from '../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON} from '../lce-mock.constant';
import {LCEMockDistrictHandler} from './lce-mock-district-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.municipalities';

@Injectable()
export class LCEMockMunicipalityHandler extends XSMockPKResourceAuditFullHandler<LCEMunicipality, LCEMunicipalityPartial, LCEMunicipalitySearch>
    implements XSMockPKSearchable<LCEMunicipalityPartial> {

    public static MUNICIPALITY_STORAGE: Map<string, LCEMunicipality> = new Map<string, LCEMunicipality>();
    public static MUNICIPALITY_SETTINGS_STORAGE: Map<string, LCEMunicipalitySettings> = new Map<string, LCEMunicipalitySettings>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEMunicipality>[] = [
        (municipality, query) => municipality.fullName.toLowerCase().includes(query),
        (municipality, query) => municipality.name.toLowerCase().includes(query),
        (municipality, query) => municipality.shortName?.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEMunicipality>[] = [
        (municipality, params) => this.httpParamArrayIncludes(params, 'codes', municipality.code),
        (municipality, params) => this.httpParamArrayIncludes(params, 'districtCodes', municipality.district.code)
    ];

    constructor(private contextService: LCECoreContextService, private mockDistrictHandler: LCEMockDistrictHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.municipalities.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEMunicipality> {
        return LCEMockMunicipalityHandler.MUNICIPALITY_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(municipality: LCEMunicipality): LCEMunicipalityPartial {
        return {
            id: municipality.id,
            code: municipality.code,
            name: municipality.name,
            fullName: municipality.fullName,
            shortName: municipality.shortName,
            district: municipality.district
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.AUDIT_FULL_ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 3,
                    active: 3,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEMunicipalityPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    public getMunicipalityYopougon(): LCEMunicipalityPartial {
        return this.findOneByCodeAsPartial(LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON);
    }

    public findMunicipalitySettingsByID(municipalityID: string): LCEMunicipalitySettings {
        return LCEMockMunicipalityHandler.MUNICIPALITY_SETTINGS_STORAGE.get(municipalityID)!;
    }

    public findMunicipalitySettingsByCode(municipalityCode: string): LCEMunicipalitySettings {
        const municipalityID = this.findOneByCodeAsPartial(municipalityCode).id;
        return this.findMunicipalitySettingsByID(municipalityID);
    }

    private createResponseData(body: any): LCEMunicipality {
        const municipalityCreate: LCEMunicipalityCreate = body as LCEMunicipalityCreate;
        const mainImage = 'assets/mock/images/municipalities/ci-create-municipality.webp';
        const municipality: LCEMunicipality = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-MUN-' + XSUtils.randomDigits(6),
            name: municipalityCreate.name,
            fullName: municipalityCreate.fullName,
            shortName: municipalityCreate.shortName,
            populationSize: municipalityCreate.populationSize,
            populationUpdateYear: municipalityCreate.populationUpdateYear,
            squareKM: municipalityCreate.squareKM,
            mainImage: municipalityCreate.mainImage ? municipalityCreate.mainImage : mainImage,
            district: this.mockDistrictHandler.findOneByCodeAsPartial(municipalityCreate.districtCode),
            actualMayorName: {
                firstName: XSUtils.randomElement(['John', 'Sara', 'Steve', 'Eli', 'Noah']),
                lastName: XSUtils.randomElement(['Doe', 'Allison', 'Coulibaly', 'Koné', 'Konan'])
            },
            mayorSince: municipalityCreate.mayorSince
        };

        LCEMockMunicipalityHandler.MUNICIPALITY_STORAGE.set(municipality.id, municipality);
        this.addResourceBaseMockData(municipality.id);
        this.addMunicipalitySettingsMockData(municipality.id);

        return municipality;
    }

    private buildStorage(): void {
        LCE_MUNICIPALITY_JSON.forEach((municipalityJSON) => {
            const municipality: LCEMunicipality = {
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                ...municipalityJSON,
                district: this.mockDistrictHandler.getDistrictAbidjan(),
                actualMayorName: {firstName: municipalityJSON.firstName, lastName: municipalityJSON.lastName}
            };
            LCEMockMunicipalityHandler.MUNICIPALITY_STORAGE.set(municipality.id, municipality);
            this.addResourceBaseMockData(municipality.id);
            this.addMunicipalitySettingsMockData(municipality.id);
        });
    }

    private addMunicipalitySettingsMockData(municipalityID: string): void {
        LCEMockMunicipalityHandler.MUNICIPALITY_SETTINGS_STORAGE.set(municipalityID, this.buildMunicipalitySettings());

        const getMunicipalitySettingsMockData: XSMockData<LCEMunicipalitySettings> = {
            id: DATASET_BASE_ID + '.getSettings.' + municipalityID,
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: `${LCE_CORE_ENDPOINTS.municipalities.index}/${municipalityID}/${LCE_CORE_ENDPOINTS.municipalities.settings}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 1000,
            getResponseData: () => LCEMockMunicipalityHandler.MUNICIPALITY_SETTINGS_STORAGE.get(municipalityID)!
        };
        const updateMunicipalitySettingsMockData: XSMockData<LCEMunicipalitySettings> = {
            id: DATASET_BASE_ID + '.updateSettings.' + municipalityID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: `${LCE_CORE_ENDPOINTS.municipalities.index}/${municipalityID}/${LCE_CORE_ENDPOINTS.municipalities.settings}`,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 3000,
            getResponseData: rArg => {
                const mSettings = LCEMockMunicipalityHandler.MUNICIPALITY_SETTINGS_STORAGE.get(municipalityID)!;
                const key = Object.keys(rArg.body)[0];
                const value = Object.values(rArg.body)[0];
                XSUtils.setNestedPropertyValue(mSettings, key, value);

                return XSUtils.clone(mSettings);
            }
        };

        this.mockDataArray.push(getMunicipalitySettingsMockData);
        this.mockDataArray.push(updateMunicipalitySettingsMockData);
    }

    private buildMunicipalitySettings(): LCEMunicipalitySettings {
        return {
            sessionDuration: 5 * 60, // 5 minutes
            authenticationCodeAsUsername: true,
            authenticationPrimaryPhoneNumberAsUsername: true,
            publicInformation: this.buildPublicInformation(),
            primaryContactPerson: this.buildPrimaryContactPerson(),
            secondaryContactPerson: this.buildSecondaryContactPerson()
        };
    }

    private buildSecondaryContactPerson(): XSContactPerson {
        return {
            name: {
                firstName: 'Ousman Cherif',
                lastName: 'Diakité'
            },
            email: 'ousman.cherif.diakite@yopougon.ci',
            primaryPhoneNumber: '+2250101010102'
        };
    }

    private buildPrimaryContactPerson(): XSContactPerson {
        return {
            name: {
                firstName: 'Vamoussa',
                lastName: 'Coulibaly'
            },
            email: 'vamoussa.coulibaly@yopougon.ci',
            primaryPhoneNumber: '+2250101010101'
        };
    }

    private buildPublicInformation(): XSBabavoss {
        return {
            email: 'info@yopougon.ci',
            phoneNumber: '+2252242431313',
            openingHours: 'Ouvert du Lundi au Vendredi de 8:30 à 16:30.'
        };
    }
}
