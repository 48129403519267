import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCECertificateOrderService, LCEWebSocketConnectionStatus, LCEWebSocketService} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSAssert, XSCount, XSTemporalUtils} from '@xs/base';
import {XSSpectorState, XSSpectorStateDefinitions} from '@xs/core';
import {Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEEventProcessorService} from '../../api/services/lce-event-processor.service';
import {LCESharedService} from '../../api/services/lce-shared.service';

@Component({
    selector: 'lce-certificate-orders-pending-orders-indicator',
    template: `
        <xs-spector
                (clickEvent)="clickEvent.emit()"
                [blink]="spectorData.blink"
                [bottomLabel]="spectorData.bottomLabel"
                [disabled]="disabled"
                [error]="spectorData.error"
                [iconFontSize]="spectorData.iconFontSize"
                [icon]="spectorData.icon"
                [showBorder]="false"
                [showBottomLabel]="spectorData.showBottomLabel"
                [stateDefinitions]="spectorData.stateDefinitions"
                [state]="spectorData.state"
                [valueOffStyleClass]="spectorData.valueOffStyleClass"
                [valueOff]="spectorData.valueOff"
                bottomLabelEmpty="No Pending Resource Element"
                bottomLabelStyleClass="xs-font-size-extra-small-imp"
                styleClass="xs-max-width-200 {{ styleClass }}"
                value="{{ spectorData.value === 0 ? undefined : spectorData.value }}">
        </xs-spector>
    `
})
export class LCECertificateOrdersPendingOrdersIndicatorComponent implements OnInit, OnDestroy, AfterViewInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_LABEL = 'lce.shared.pendingOrdersIndicator.';

    @Input() styleClass?: string;

    @Input() municipalityCode: string;

    @Input() disabled?: boolean;

    @Input() showBottomLabel?: boolean;

    @Output() clickEvent = new EventEmitter<void>();

    spectorData = {
        showBorder: false,
        value: 0,
        state: XSSpectorState.NORMAL,
        stateDefinitions: {
            normal: {colorClass: 'xs-color-success', blinkFrequency: '0.0s'},
            warning: {colorClass: 'xs-color-warn', blinkFrequency: '1.5s'}, // orange
            danger: {colorClass: 'xs-color-danger', blinkFrequency: '0.8s'}
        } as XSSpectorStateDefinitions,
        icon: LCE_SHARED_ICON.certificateOrder,
        iconFontSize: '50px',
        showBottomLabel: true,
        bottomLabel: XS_STR_EMPTY,
        blink: false,
        valueOff: false,
        valueOffStyleClass: 'xs-color-light',
        error: undefined
    };

    status: LCEWebSocketConnectionStatus = LCEWebSocketConnectionStatus.CLOSED;

    private subscription: Subscription = new Subscription();

    private dangerThreshold: number = 10;
    private warningThreshold: number = 5;
    private checkEveryNSSecondThreshold: number = 60 * 2; // 2 minutes
    private deltaFromLastPendingOrderInSeconds: number = 60 * 3; // 3 minutes
    private lastPendingOrderDate: string;
    private interval: any;

    constructor(
        private sharedService: LCESharedService,
        private certificateOrderService: LCECertificateOrderService,
        private eventProcessorService: LCEEventProcessorService,
        private webSocketService: LCEWebSocketService
    ) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.municipalityCode, 'municipalityCode');
        this.setNormalState();
        this.installEventListeners();
    }

    ngOnDestroy(): void {
        if (this.interval) clearInterval(this.interval);
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.retrieveCount();
    }

    public isNormalState(): boolean {
        return this.spectorData.state == XSSpectorState.NORMAL;
    }

    public setNormalState(): void {
        this.spectorData.state = XSSpectorState.NORMAL;
        this.spectorData.blink = false;
        this.handleStateChange();
    }

    public setWarningState(): void {
        this.spectorData.state = XSSpectorState.WARNING;
        this.spectorData.blink = true;
        this.handleStateChange();
    }

    public setDangerState(): void {
        this.spectorData.state = XSSpectorState.DANGER;
        this.spectorData.blink = true;
        this.handleStateChange();
    }

    private installEventListeners(): void {
        this.subscription.add(this.eventProcessorService.onNewCertificateOrder.subscribe(() => this.retrieveCount()));
        this.subscription.add(this.sharedService.onBatchProcessCompleted.subscribe(() => this.retrieveCount()));
        this.subscription.add(
            this.webSocketService.onConnectionStatusChange.subscribe(cStatus => {
                this.status = cStatus;
                this.spectorData.valueOff = this.status === LCEWebSocketConnectionStatus.CLOSED;
            })
        );

        this.interval = setInterval(() => {
            const diff: number = XSTemporalUtils.computeDiffInSeconds(new Date(this.lastPendingOrderDate), new Date());
            if (this.isNormalState() && this.spectorData.value > 0 && diff >= this.deltaFromLastPendingOrderInSeconds) {
                this.setWarningState();
                LOG().info('pending orders has been waiting for too long (>= ' + this.deltaFromLastPendingOrderInSeconds + ' seconds). Warning mode activated.');
            }
        }, this.checkEveryNSSecondThreshold * 1000);
    }

    private retrieveCount(): void {
        this.subscription.add(
            this.certificateOrderService.countMunicipalityPendingOrders(this.municipalityCode).subscribe({
                next: pendingOrdersCount => this.handlePendingOrdersCount(pendingOrdersCount),
                error: error => (this.spectorData.error = error)
            })
        );
    }

    private handlePendingOrdersCount(pendingOrdersCount: XSCount): void {
        this.spectorData.value = pendingOrdersCount.total;
        this.lastPendingOrderDate = new Date().toISOString();
        if (this.spectorData.value >= this.dangerThreshold) this.setDangerState();
        else if (this.spectorData.value >= this.warningThreshold) this.setWarningState();
        else this.setNormalState();
    }

    private handleStateChange(): void {
        this.spectorData.bottomLabel = this.TR_BASE_LABEL + this.spectorData.state;
    }
}
