<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-content">

        <div class="xs-flex-column xs-height-full">
            <p-tabView #pTabView [scrollable]="navScrollable" class="xs-tabview" styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                <p-tabPanel header="{{ 'lce.shared.district.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-cmd-districts></lce-cmd-districts>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.municipality.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-cmd-municipalities></lce-cmd-municipalities>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.facility.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-cmd-facilities></lce-cmd-facilities>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.servicePoint.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-cmd-service-points></lce-cmd-service-points>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel header="{{ 'lce.shared.deliveryCompany.main.title' | translate }}">
                    <ng-template pTemplate="content">
                        <lce-cmd-delivery-companies></lce-cmd-delivery-companies>
                    </ng-template>
                </p-tabPanel>

            </p-tabView>
        </div>

    </div>
</div>
