import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {
    LCEDashboardCertificateOrderChartDoughnutComponent,
    LCEDashboardCertificateOrderChartLineComponent,
    LCEDashboardCertificateOrderStatsComponent,
    LCEDashboardNewsLastArticleComponent,
    LCEDashboardSmartCityLastArticleComponent,
    LCEDashboardStatsGlobalComponent
} from '@lce/shared';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';
import {LCEFacilityPartial, LCENewsArticleSearch, LCENewsArticleService, LCENewsArticleStatus} from '@lce/core';
import {finalize} from 'rxjs/operators';
import {LCE_CMD_ICON} from '../../core/constants/lce-cmd-icon.constant';
import {LCECMDDashboardService} from './lce-cmd-dashboard.service';

@Component({selector: 'lce-cmd-dashboard', templateUrl: './lce-cmd-dashboard.component.html'})
export class LCECMDDashboardComponent implements OnInit {

    ICON = LCE_CMD_ICON;

    readonly TR_BASE: string = 'lce.cmd.features.dashboard.';

    @ViewChild('dashboardStatsGlobal') dashboardStatsGlobal: LCEDashboardStatsGlobalComponent;
    @ViewChild('dashboardCertificateOrderStats') dashboardCertificateOrderStats: LCEDashboardCertificateOrderStatsComponent;
    @ViewChild('dashboardNewsLastArticle') dashboardNewsLastArticle: LCEDashboardNewsLastArticleComponent;
    @ViewChild('dashboardSmartCityLastArticle') dashboardSmartCityLastArticle: LCEDashboardSmartCityLastArticleComponent;

    @ViewChild('certificateOrderChartDoughnut') certificateOrderChartDoughnut: LCEDashboardCertificateOrderChartDoughnutComponent;
    @ViewChild('certificateOrderChartLine') certificateOrderChartLine: LCEDashboardCertificateOrderChartLineComponent;

    municipalityCode: string;
    municipalityFullName: string;
    facilityCode: string;

    tileData = {
        news: {
            inReview: {
                value: XS_STR_EMPTY,
                error: undefined,
                loading: false
            },
            scheduled: {
                value: XS_STR_EMPTY,
                error: undefined,
                loading: false
            }
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(
        private router: Router,
        private dashboardService: LCECMDDashboardService,
        private newsArticleService: LCENewsArticleService) {
    }

    ngOnInit(): void {
        // this.countNewsArticlesInReview();
        // this.countNewsArticlesScheduled();
    }

    public onFacilityChange(facility: LCEFacilityPartial): void {
        this.municipalityCode = facility.municipality.code;
        this.refresh();
    }


    // ------------------------------------------------------------------------------------------------------------
    // === Go To ===
    // ------------------------------------------------------------------------------------------------------------

    public onSettingsClick(): void {
    }

    public refresh(): void {
        this.dashboardService.emitRefresh();
        if (!XSUtils.isNull(this.dashboardStatsGlobal)) this.dashboardStatsGlobal.update();
        if (!XSUtils.isNull(this.dashboardCertificateOrderStats)) this.dashboardCertificateOrderStats.update();
        if (!XSUtils.isNull(this.dashboardNewsLastArticle)) this.dashboardNewsLastArticle.update();
        if (!XSUtils.isNull(this.dashboardSmartCityLastArticle)) this.dashboardSmartCityLastArticle.update();
        if (!XSUtils.isNull(this.certificateOrderChartDoughnut)) this.certificateOrderChartDoughnut.update();
        if (!XSUtils.isNull(this.certificateOrderChartLine)) this.certificateOrderChartLine.update();
        this.countNewsArticlesInReview();
        this.countNewsArticlesScheduled();
    }

    public goToNewsArticlesScheduledPublication(): void {
        this.router.navigate(['/news/articles'],
            {
                state: {
                    status: LCENewsArticleStatus.SCHEDULED
                }
            })
            .then();
    }

    public goToNewsArticlesInReview(): void {
        this.router.navigate(['/news/articles'],
            {
                state: {
                    status: LCENewsArticleStatus.IN_REVIEW
                }
            })
            .then();
    }

    public goToCustomers(): void {
        this.router.navigateByUrl('/customers').then();
    }

    public goToCertificateOrders(): void {
        this.router.navigateByUrl('/certificate-orders').then();
    }

    public goToSuggestions(): void {
        this.router.navigateByUrl('/suggestions').then();
    }

    public goToNewsArticles(): void {
        this.router.navigateByUrl('/news/articles').then();
    }

    public goToSmartCityArticles(): void {
        this.router.navigateByUrl('/smartcity/articles').then();
    }

    // ------------------------------------------------------------------------------------------------------------
    // === *** ===
    // ------------------------------------------------------------------------------------------------------------

    private countNewsArticlesInReview(): void {
        const search: LCENewsArticleSearch = {
            statuses: [LCENewsArticleStatus.IN_REVIEW]
        };
        this.tileData.news.inReview.loading = true;
        this.tileData.news.inReview.error = undefined;
        this.subscription.add(
            this.newsArticleService.count(search)
                .pipe(finalize(() => this.tileData.news.inReview.loading = false))
                .subscribe({
                    next: response => {
                        this.tileData.news.inReview.value = response.total.toString();
                    },
                    error: error => this.tileData.news.inReview.error = error
                })
        );
    }

    private countNewsArticlesScheduled(): void {
        const search: LCENewsArticleSearch = {
            statuses: [LCENewsArticleStatus.SCHEDULED]
        };
        this.tileData.news.scheduled.loading = true;
        this.tileData.news.scheduled.error = undefined;
        this.subscription.add(
            this.newsArticleService.count(search)
                .pipe(finalize(() => this.tileData.news.scheduled.loading = false))
                .subscribe({
                    next: response => {
                        this.tileData.news.scheduled.value = response.total.toString();
                    },
                    error: error => this.tileData.news.scheduled.error = error
                })
        );
    }
}
