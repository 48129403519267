import { LCECertificateType, LCEUserCustomerPartial, LCEUserMunicipalEmployeePartial } from '@lce/core';
import { XSAssert, XSContinent, XSGeolocation, XSPKDTOAuditFull, XSUtils } from '@xs/base';
import { LCE_MBO_MOCK_CUSTOMER_ANGELINA_JOLIE, LCE_MBO_MOCK_CUSTOMER_BRAD_PITT, LCE_MBO_MOCK_CUSTOMER_VICTOR_HUGO, LCE_MBO_MOCK_PERSONA, LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY, LCEMBOMockPersonaMunicipalEmployee } from './lce-mock-user-data';

export class LCEMockUtils {

    public static randomCertificateType(): LCECertificateType {
        return XSUtils.randomElement<LCECertificateType>([LCECertificateType.BIRTH, LCECertificateType.MARRIAGE, LCECertificateType.DEATH]);
    }

    public static restoreResourceResponseData<T extends XSPKDTOAuditFull>(resource: T, by: any): T {
        XSUtils.setNestedPropertyValue(resource, 'deleted', false);
        XSUtils.setNestedPropertyValue(resource, 'restoredBy', by);
        XSUtils.setNestedPropertyValue(resource, 'restoredOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'updatedOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'updatedBy', by);
        return { ...resource };
    }

    public static deleteResourceResponseData<T extends XSPKDTOAuditFull>(resource: T, by: any): T {
        XSUtils.setNestedPropertyValue(resource, 'deleted', true);
        XSUtils.setNestedPropertyValue(resource, 'deletedBy', by);
        XSUtils.setNestedPropertyValue(resource, 'deletedOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'updatedOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'updatedBy', by);
        return { ...resource };
    }

    public static activateDeactivateResourceResponseData<T extends XSPKDTOAuditFull>(resource: T, activeState: boolean, by: any): T {
        XSUtils.setNestedPropertyValue(resource, 'active', activeState);
        XSUtils.setNestedPropertyValue(resource, 'activeUpdatedOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'activeUpdatedBy', by);
        XSUtils.setNestedPropertyValue(resource, 'updatedOn', new Date().toISOString());
        XSUtils.setNestedPropertyValue(resource, 'updatedBy', by);
        return { ...resource };
    }

    public static randomUserCustomer(nullable?: boolean): LCEUserCustomerPartial {
        let arr = [LCE_MBO_MOCK_CUSTOMER_VICTOR_HUGO, LCE_MBO_MOCK_CUSTOMER_ANGELINA_JOLIE, LCE_MBO_MOCK_CUSTOMER_BRAD_PITT];
        if (nullable === true) {
            arr.push(undefined!);
        }
        return XSUtils.randomElement(arr);
    }

    public static randomUserMunicipalEmployee(): LCEUserMunicipalEmployeePartial {
        return XSUtils.randomElement([LCE_MBO_MOCK_USER_STEVE_JOBS, LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY]);
    }

    public static buildUserMunicipalEmployeePartial(persona: LCEMBOMockPersonaMunicipalEmployee): LCEUserMunicipalEmployeePartial {
        XSAssert.notEmpty(persona, 'persona');
        return XSUtils.clone(LCE_MBO_MOCK_PERSONA[persona].userPartial);
    }

    public static buildIPGeolocationYamoussokro(): XSGeolocation {
        return {
            timestamp: new Date().toISOString(),
            ip: '78.124.212.432',
            timeZone: '+0000',
            city: 'yamoussokro',
            countryISO: 'ci',
            continent: XSContinent.AF,
            coordinate: { latitude: 5.362184994594163, longitude: -3.989449302611587 }
        };
    }

    public static buildIPGeolocationAbidjan(): XSGeolocation {
        return {
            timestamp: new Date().toISOString(),
            ip: '16.123.321.123',
            timeZone: '+0500',
            city: 'abidjan',
            countryISO: 'ci',
            continent: XSContinent.AF,
            coordinate: { latitude: 5.362184994594163, longitude: -3.989449302611587 }
        };
    }

    public static buildIPGeolocationMontreal(): XSGeolocation {
        return {
            timestamp: new Date().toISOString(),
            ip: '96.127.212.217',
            timeZone: '+0500',
            city: 'montreal',
            postalCode: 'j4n 0g9',
            province: { name: 'quebec', code: 'qc' },
            countryISO: 'ca',
            continent: XSContinent.NA,
            coordinate: { latitude: 45.54014446452551, longitude: -73.45995530853851 },
            asn: { code: 'AS1403', name: 'EBOX' },
            security: { isTor: false, isProxy: false, isThreat: false }
        };
    }
}
