import {Component, Input, OnInit} from '@angular/core';
import {XSDialogable} from '@xs/core';
import {LCEPermission} from '@lce/core';
import {XSUtils} from '@xs/base';
import {LCEAuthorizationTesterService} from './lce-authorization-tester.service';

@Component({
    selector: 'lce-authorization-tester',
    templateUrl: './lce-authorization-tester.component.html',
    providers: [LCEAuthorizationTesterService]
})
export class LCEAuthorizationTesterComponent extends XSDialogable implements OnInit {

    readonly TR_BASE: string = 'lce.shared.authorization.tester.';

    @Input() styleClass?: string;
    @Input() dialogStyleClass?: string;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() data?: LCEPermission;

    constructor(private authorizationTesterService: LCEAuthorizationTesterService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.data = this.dialogConfig.data.data;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.dialogStyleClass = this.dialogConfig.data.dialogStyleClass;
        }
        if (XSUtils.isEmpty(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isEmpty(this.subtitle)) this.subtitle = this.TR_BASE + 'subtitle';
    }
}