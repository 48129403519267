<div class="xs-flex xs-flex-wrap xs-align-items-center xs-justify-content-space-between {{ styleClass }}">
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.eye }} xs-display-block xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ readCount | xsHumanReadableNumber }}</span>
    </span>
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.qrCode }} xs-display-block xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ scanCount | xsHumanReadableNumber }}</span>
    </span>
    <span class="xs-flex-inline xs-align-items-center">
        <i class="{{ ICON.share }} xs-display-block xs-mr-5"></i>
        <span class="xs-display-block xs-font-size-small xs-font-weight-500">{{ shareCount | xsHumanReadableNumber }}</span>
    </span>
</div>