import {Pipe, PipeTransform} from '@angular/core';
import {XS_STR_EMPTY, XS_STR_SPACE, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {LCE_APP_SHORT_NAME, LCE_TR_BASE_USER_EMPLOYEE_POSITION, LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION} from '../constants/lce-core.constant';
import {LCEUserType} from '../domain/user/lce-user';
import {LCEUserUtils} from '../utils/lce-user-utils';
import {LCEEnvironmentService} from '../services/lce-environment.service';

@Pipe({name: 'lceUserPosition', pure: false})
export class LCEUserPositionPipe implements PipeTransform {

    constructor(private translationService: XSTranslationService, private environmentService: LCEEnvironmentService) {
    }

    public transform(value: string | undefined, userType: LCEUserType): string {
        return this.translateUserPosition(value, userType);
    }

    private translateUserPosition(userPosition: string | undefined, userType: LCEUserType): string {
        if (XSUtils.isEmpty(userPosition)) return XS_STR_EMPTY;
        const trKey = this.getTRBase(userType) + userPosition;
        const trValue = this.translationService.translateKey(trKey);
        const finalValue = trKey === trValue ? userPosition! : trValue;
        const prefix = LCEUserUtils.isUserEmployee(userType) && this.environmentService.isMBO() ? LCE_APP_SHORT_NAME + XS_STR_SPACE : XS_STR_EMPTY;
        return prefix + finalValue;
    }

    private getTRBase(userType: LCEUserType): string {
        if (LCEUserUtils.isUserEmployee(userType)) return LCE_TR_BASE_USER_EMPLOYEE_POSITION;
        else return LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION;
    }
}
