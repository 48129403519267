<div class="xs-width-full {{ styleClass }}" [ngClass]="{ 'xs-disabled': disabled === true }">
    <div class="grid xs-fields xs-fields-spacer-15 xs-fields-plr-15 {{ formContainerStyleClass }}">
        <div class="col-12 md:col-6">
            <xs-input-field-email [options]="emailField"></xs-input-field-email>
        </div>
        <div class="col-12 md:col-6">
            <xs-input-field-phone-number [options]="primaryPhoneNumberField"></xs-input-field-phone-number>
        </div>
        <div *ngIf="showSecondaryPhoneNumberField !== false" class="col-12 md:col-6">
            <xs-input-field-phone-number [options]="secondaryPhoneNumberField"></xs-input-field-phone-number>
        </div>
        <div *ngIf="showWhatsAppPhoneNumberField !== false" class="col-12 md:col-6">
            <xs-input-field-phone-number [options]="whatsAppPhoneNumberField"></xs-input-field-phone-number>
        </div>

        <div class="col-12 md:col-6">
            <xs-input-field-address [options]="addressField"></xs-input-field-address>
        </div>
        <div class="col-12 md:col-6">
            <xs-input-field-contact-person [options]="emergencyContactPersonField"></xs-input-field-contact-person>
        </div>
    </div>
</div>
