import {LCECertificateOrderStatus, LCESuggestionStatus} from '@lce/core';
import {XSStyleHelper} from '@xs/core';
import {XSAssert, XSUtils} from '@xs/base';

export class LCESharedUtils {
    public static readonly CERTIFICATE_ORDER_COLOR_STATUS_STYLE_CLASS_MAP = new Map<LCECertificateOrderStatus, string>([
        [LCECertificateOrderStatus.PENDING, XSStyleHelper.CLASS.color.secondary],
        [LCECertificateOrderStatus.PROCESSING, XSStyleHelper.CLASS.color.secondary],
        [LCECertificateOrderStatus.READY, XSStyleHelper.CLASS.color.primary],
        [LCECertificateOrderStatus.ERROR, XSStyleHelper.CLASS.color.danger],
        [LCECertificateOrderStatus.CANCELLED, 'xs-color-dark'],
        [LCECertificateOrderStatus.OUT_FOR_DELIVERY, 'xs-color-orange'],
        [LCECertificateOrderStatus.DELIVERED, XSStyleHelper.CLASS.color.success]
    ]);

    public static readonly SUGGESTION_COLOR_STATUS_STYLE_CLASS_MAP = new Map<LCESuggestionStatus, string>([
        [LCESuggestionStatus.UNREAD, XSStyleHelper.CLASS.color.primaryImp],
        [LCESuggestionStatus.READ, XSStyleHelper.CLASS.color.secondaryImp],
        [LCESuggestionStatus.CONSIDERED, XSStyleHelper.CLASS.color.orangeImp],
        [LCESuggestionStatus.SELECTED, XSStyleHelper.CLASS.color.greenImp]
    ]);

    public static getSuggestionColorStatusStyleClass(status: LCESuggestionStatus): string {
        XSAssert.notEmpty(status, 'status');
        return LCESharedUtils.SUGGESTION_COLOR_STATUS_STYLE_CLASS_MAP.get(status)!;
    }

    public static getCertificateOrderColorStatusStyleClass(status: LCECertificateOrderStatus): string {
        XSAssert.notEmpty(status, 'status');
        const color = LCESharedUtils.CERTIFICATE_ORDER_COLOR_STATUS_STYLE_CLASS_MAP.get(status);
        if (XSUtils.isEmpty(color)) return XSStyleHelper.CLASS.color.secondary;
        return color!;
    }
}
