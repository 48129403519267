import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_CORE_LABEL, LCECertificateOrderProcuration, LCEUserCustomerPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-certificate-order-procuration',
    templateUrl: './lce-certificate-order-procuration.component.html'
})
export class LCECertificateOrderProcurationComponent implements OnInit {

    readonly TR_BASE = LCE_TR_BASE_CORE_LABEL;
    readonly TR_BASE_LABEL = 'lce.shared.certificateOrders.create.procuration.';
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;

    @Input() data: LCECertificateOrderProcuration;

    @Output() phoneNumberClickEvent = new EventEmitter<LCEUserCustomerPartial>();

    get isSame(): boolean {
        if (XSUtils.isEmpty(this.data.representativeCustomer)) return false;
        return this.data.representativeCustomer!.primaryPhoneNumber === this.data.representativePhoneNumber;
    }

    ngOnInit() {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isEmpty(this.showBorder)) this.showBorder = true;
        if (XSUtils.isEmpty(this.showHeader)) this.showHeader = true;

    }

    public openCustomerRecord(): void {
        if (this.isSame) this.phoneNumberClickEvent.emit(this.data.representativeCustomer!);
        else return;
    }
}