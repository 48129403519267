import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSUtils} from '@xs/base';
import {XSMenuItem} from '@xs/core';
import {LCE_CMD_ICON} from '../../../core/constants/lce-cmd-icon.constant';

interface LCEVerticalSidebarMenuItem extends XSMenuItem {
    trShortLabel?: string;
    badgeStyleClass?: string;
    badgeValue?: string;
}

@Component({selector: 'lce-vertical-sidebar', templateUrl: './lce-vertical-sidebar.component.html', styleUrls: ['./lce-vertical-sidebar.component.scss']})
export class LCEVerticalSidebarComponent implements OnInit {
    ICON = LCE_CMD_ICON;

    menuItems: LCEVerticalSidebarMenuItem[];
    suggestionMenuItem: LCEVerticalSidebarMenuItem;

    @Input() expandMenu: boolean;

    @Input() expandCollapse?: boolean;

    @Input() bottomTextMenu?: boolean;

    @Output() expandMenuChange = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
        this.menuItems = this.buildMenu();
        if (XSUtils.isNull(this.expandMenu)) this.expandMenu = true;
        if (XSUtils.isNull(this.expandCollapse)) this.expandCollapse = true;
        if (XSUtils.isNull(this.bottomTextMenu)) this.bottomTextMenu = false;

    }

    public isExpanded() {
        return this.expandMenu;
    }

    public onOverlayButtonClick() {
        this.expandMenu = !this.expandMenu;
        this.expandMenuChange.emit(this.expandMenu);
    }

    private buildMenu(): LCEVerticalSidebarMenuItem[] {
        this.suggestionMenuItem = {
            icon: LCE_CMD_ICON.suggestion,
            iconStyleClass: 'xs-font-size-18-imp',
            trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.suggestions',
            trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.suggestions',
            url: '/suggestions'
        };
        return [

            {
                icon: LCE_CMD_ICON.home,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.dashboard',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.dashboard',
                url: '/dashboard'
            },
            {
                icon: LCE_CMD_ICON.certificateOrder,
                iconStyleClass: 'xs-width-20-imp xs-height-20-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.certificateOrders',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.certificateOrders',
                url: '/certificate-orders'
            },
            {
                icon: LCE_CMD_ICON.order,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.cOrderManualDispatcher',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.cOrderManualDispatcher',
                url: '/certificate-orders-manual-dispatcher'
            },
            /*
                       {
                           icon: LCE_CMD_ICON.birthDeclaration,
                           iconStyleClass: 'xs-font-size-28-imp',
                           trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.birthDeclaration',
                           trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.birthDeclaration',
                           url: '/birth-declarations'
                       },
                       this.suggestionMenuItem,
                       {
                           icon: LCE_CMD_ICON.news,
                           iconStyleClass: 'xs-font-size-24-imp',
                           trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.news',
                           trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.news',
                           url: '/news'
                       },
                       {
                           icon: LCE_CMD_ICON.smartCity,
                           iconStyleClass: 'xs-font-size-23-imp',
                           trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.smartcity',
                           trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.smartcity',
                           url: '/smartcity'
                       },

                       {
                           icon: LCE_CMD_ICON.customer,
                           iconStyleClass: 'xs-font-size-28-imp',
                           trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.customers',
                           trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.customers',
                           url: '/customers'
                       },
                       */

            {
                icon: LCE_CMD_ICON.payment,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.payments',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.payments',
                url: '/payments'
            },
            {
                icon: LCE_CMD_ICON.resources,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.resources',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.resources',
                url: '/resources'
            },
            {
                icon: LCE_CMD_ICON.users,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.userManagement',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.userManagement',
                url: '/user-management'
            },
            {
                icon: LCE_CMD_ICON.settings,
                iconStyleClass: 'xs-font-size-18-imp',
                trLabel: 'lce.cmd.template.sheldon.verticalSidebar.menu.settings',
                trShortLabel: 'lce.cmd.template.sheldon.verticalSidebar.menuShort.settings',
                url: '/settings'
            }
        ];
    }

}
