import {Injectable} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderUpdateStatusResponse, LCECertificateType, LCEShippingInternationalProvider} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCESharedService} from '../api/services/lce-shared.service';
import {LCECertificateOrderRecordComponent} from './record/lce-certificate-order-record.component';
import {LCECertificateOrderCreateProcessParameters} from './process/lce-certificate-order-process';
import {LceCertificateOrderProcessMFOComponent} from './process/mfo/lce-certificate-order-process-mfo.component';
import {LCECertificateOrderProcessComponent} from './process/lce-certificate-order-process.component';

export interface LCECertificateOrderProcessDialogArg {
    styleClass?: string;
    isMFOProcess?: boolean;
    options: {
        forceShippingInternationalProvider?: LCEShippingInternationalProvider,
        parameters?: LCECertificateOrderCreateProcessParameters;
        useProcuration?: boolean;
        useDelivery?: boolean;
        showProcurationFillForm?: boolean;
        showBackButton?: boolean;
        backButtonLabel?: string;
        facilityCode?: string;
        showMakeNewPaymentButton?: boolean;
        showConfirmationTransactionID?: boolean;
        showFillFormButton?: boolean;
        countryISO: string;
        canUseDeliveryDate?: boolean;
        canUseDesiredDeliveryTimeRange?: boolean;
        canUseNote?: boolean;
    };
    onClose?: () => void;
}

export interface LCECertificateOrderRecordDialogArg {
    orderID?: string;
    orderNumber?: string;
    type?: LCECertificateType,
    stampTokenClickable?: boolean;
    readonly?: boolean;
    onClose?: () => void;
    onStatusUpdated?: (updateStatusResponse: LCECertificateOrderUpdateStatusResponse) => void;
}

@Injectable({providedIn: 'root'})
export class LCECertificateOrderDialogService {

    constructor(
        private dialogService: XSDialogService,
        private sharedService: LCESharedService) {
        this.sharedService.onOpenCertificateOrderDialog.subscribe(arg => this.openRecordDialog(arg));
    }

    public openProcessDialog(arg: LCECertificateOrderProcessDialogArg): void {
        XSAssert.notNull(arg, 'arg');
        const dConfig: XSDialogConfig = {
            data: {
                countryISO: arg.options.countryISO,
                parameters: arg.options.parameters,
                useProcuration: arg.options.useProcuration,
                useDelivery: arg.options.useDelivery,
                showProcurationFillForm: arg.options.showProcurationFillForm,
                showBackButton: arg.options.showBackButton,
                showMakeNewPaymentButton: arg.options.showMakeNewPaymentButton,
                showConfirmationTransactionID: arg.options.showConfirmationTransactionID,
                backButtonLabel: arg.options.backButtonLabel,
                showFillFormButton: arg.options.showFillFormButton,
                canUseDeliveryDate: arg.options.canUseDeliveryDate,
                canUseDesiredDeliveryTimeRange: arg.options.canUseDesiredDeliveryTimeRange,
                canUseNote: arg.options.canUseNote,
                facilityCode: arg.options.facilityCode,
                styleClass: 'xs-mt-30',
                loadingStyleClass: 'xs-min-height-800 xs-min-width-1500'
            },
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1500'
        };
        let certificateOrderProcessComponent: any;

        if (arg.isMFOProcess) certificateOrderProcessComponent = this.dialogService.openComponentDialog(LceCertificateOrderProcessMFOComponent, dConfig);
        else certificateOrderProcessComponent = this.dialogService.openComponentDialog(LCECertificateOrderProcessComponent, dConfig);

        certificateOrderProcessComponent.closeEvent.subscribe((event: LCECertificateOrder | undefined) => {
            if (arg?.onClose) arg!.onClose();
            if (!XSUtils.isEmpty(event)) this.openRecordDialog({orderID: event!.id});
        });

        certificateOrderProcessComponent.completeEvent.subscribe(() => {
            certificateOrderProcessComponent.closeDialog();
        });

        certificateOrderProcessComponent.cancelEvent.subscribe(() => {
            console.log('cancelEvent');
            certificateOrderProcessComponent.closeDialog();
        });
    }

    public openRecordDialog(arg: LCECertificateOrderRecordDialogArg): void {
        XSAssert.notNull(arg, 'arg');
        LCECertificateOrderRecordComponent.validate(arg.orderID, arg.orderNumber, 'arg');
        const dConfig: XSDialogConfig = {
            data: {
                orderID: arg.orderID,
                type: arg.type,
                orderNumber: arg.orderNumber,
                stampTokenClickable: arg.stampTokenClickable,
                readonly: arg.readonly,
                styleClass: 'xs-mt-30',
                loadingStyleClass: 'xs-min-height-800 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: false,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };
        const certificateOrderRecordComponent: LCECertificateOrderRecordComponent = this.dialogService.openComponentDialog(LCECertificateOrderRecordComponent, dConfig);

        certificateOrderRecordComponent.closeEvent.subscribe(() => {
            if (arg?.onClose) arg!.onClose();
        });
        certificateOrderRecordComponent.statusUpdatedEvent.subscribe(updateStatusResponse => {
            if (arg?.onStatusUpdated) arg!.onStatusUpdated(updateStatusResponse);
        });
    }
}
