<div class="xs-flex-column-center xs-width-full {{ styleClass }}">
    <div class="grid xs-fields xs-fields-spacer-5">
        <div class="col-12">
            <lce-input-field-facility-autocomplete [options]="facilityField" [showIcon]="true">
            </lce-input-field-facility-autocomplete>
        </div>
        <div class="col-12">
            <xs-input-field-dropdown [options]="typeField"></xs-input-field-dropdown>
        </div>
        <div class="col-12">
            <xs-input-field-text [options]="referenceNumberField"></xs-input-field-text>
        </div>
        <div class="col-12">
            <xs-input-field-calendar [options]="issueDateField"></xs-input-field-calendar>
        </div>
    </div>
</div>
