<div class="xs-width-full {{ styleClass }}" [ngClass]="{ 'xs-disabled': disabled === true }">
    <div class="grid xs-fields xs-fields-spacer-15 xs-fields-plr-15 {{ formContainerStyleClass }}">
        <div class="col-12 md:col-6">
            <xs-input-field-text [options]="firstNameField"></xs-input-field-text>
        </div>
        <div class="col-12 md:col-6">
            <xs-input-field-text [options]="lastNameField"></xs-input-field-text>
        </div>
        <div class="col-12 md:col-6">
            <xs-input-field-dropdown [options]="genderField"></xs-input-field-dropdown>
        </div>
        <div class="col-12 md:col-6">
            <xs-input-field-radios [options]="languageField"></xs-input-field-radios>
        </div>
    </div>
</div>
