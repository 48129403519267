import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCECertificateOrderPartial, LCECertificateOrderStatus, LCECertificateOrderUpdateStatusResponse} from '@lce/core';

@Component({selector: 'lce-certificate-order-row', templateUrl: './lce-certificate-order-row.component.html'})
export class LCECertificateOrderRowComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() data: LCECertificateOrderPartial;

    @Input() readonly?: boolean;

    @Input() orderNumberClickable?: boolean;

    @Output() statusUpdatedEvent = new EventEmitter<LCECertificateOrderUpdateStatusResponse>();

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
    }

    public onStatusUpdated(updateStatusResponse: LCECertificateOrderUpdateStatusResponse) {
        this.statusUpdatedEvent.emit(updateStatusResponse);
        this.data.status = updateStatusResponse.status;
        this.data.statusUpdatedOn = updateStatusResponse.statusUpdatedOn;
    }

    public isStatusDelivered() {
        return this.data.status === LCECertificateOrderStatus.DELIVERED;
    }
}
