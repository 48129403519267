import {Injectable} from '@angular/core';
import {LCEFacilityPartial, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {XSGeolocationService} from '@xs/core';
import {LCECMDAppConfig} from '../domain/lce-cmd-app-config';
import {LCESharedContextService} from '@lce/shared';

@Injectable({providedIn: 'root'})
export class LCECMDContextService extends LCESharedContextService {

    private defaultFacility: LCEFacilityPartial;

    constructor(protected geolocationService: XSGeolocationService) {
        super(geolocationService);
    }

    // --- Facility ----

    public getDefaultFacility(): LCEFacilityPartial {
        return this.defaultFacility;
    }

    public setDefaultFacility(facility: LCEFacilityPartial): void {
        this.defaultFacility = facility;
    }

    // --- User ----

    public setUser(user: LCEUserMunicipalEmployeePartial): void {
        super.setUser(user);
    }

    public getUser(): LCEUserMunicipalEmployeePartial {
        return super.getUser() as LCEUserMunicipalEmployeePartial;
    }

    // --- Config ----

    public getAppConfig(): LCECMDAppConfig {
        return super.getAppConfig() as LCECMDAppConfig;
    }

    public setAppConfig(appConfig: LCECMDAppConfig): void {
        super.setAppConfig(appConfig);
    }
}
