<div [ngClass]="{ 'xs-disabled': disabled || internalDisabled }"
     class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <div *ngIf="canShowStatusActions()" class="xs-flex-row xs-align-items-center xs-width-full">
        <xs-button
                (clickEvent)="setOrderAsProcessing()"
                *ngIf="canShowStatusAction(ORDER_STATUS.PROCESSING)"
                [disabled]="!canEnableStatusAction(ORDER_STATUS.PROCESSING)"
                [icon]="actionIcon"
                [loading]="loading.processing"
                [style.margin-left]="spaceBetween"
                iconStyleClass="{{ getActionIconStyleClassColor(ORDER_STATUS.PROCESSING) }} xs-font-size-10-imp xs-mt-1-imp"
                label="lce.shared.statusAction.process"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
        <xs-button
                (clickEvent)="setOrderAsReady()"
                *ngIf="canShowStatusAction(ORDER_STATUS.READY)"
                [disabled]="!canEnableStatusAction(ORDER_STATUS.READY)"
                [icon]="actionIcon"
                [loading]="loading.ready"
                [style.margin-left]="spaceBetween"
                iconStyleClass="{{ getActionIconStyleClassColor(ORDER_STATUS.READY) }} xs-font-size-10-imp xs-mt-1-imp"
                label="lce.shared.statusAction.ready"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                styleClass="{{ buttonStyleClass }}"
                type="text">
        </xs-button>
        <xs-reason-button
                *ngIf="canShowStatusAction(ORDER_STATUS.ERROR)"
                [createUpdateOptions]="errorReasonOptions"
                [disabled]="!canEnableStatusAction(ORDER_STATUS.ERROR)"
                [icon]="actionIcon"
                [style.margin-left]="spaceBetween"
                [styleClass]="buttonStyleClass"
                iconStyleClass="{{ getActionIconStyleClassColor(ORDER_STATUS.ERROR) }} xs-font-size-10-imp xs-mt-1-imp"
                label="lce.shared.statusAction.error"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                type="text">
        </xs-reason-button>
        <xs-reason-button
                *ngIf="canShowStatusAction(ORDER_STATUS.CANCELLED)"
                [createUpdateOptions]="cancelReasonOptions"
                [disabled]="!canEnableStatusAction(ORDER_STATUS.CANCELLED)"
                [icon]="actionIcon"
                [style.margin-left]="spaceBetween"
                [styleClass]="buttonStyleClass"
                iconStyleClass="{{ getActionIconStyleClassColor(ORDER_STATUS.CANCELLED) }} xs-font-size-10-imp xs-mt-1-imp"
                label="lce.shared.statusAction.cancel"
                loaderContainerStyleClass="xs-mt-minus-5"
                loaderPosition="bottom"
                size="intermediate"
                type="text">
        </xs-reason-button>
    </div>
    <xs-button
            (clickEvent)="onMenuClick($event)"
            *ngIf="canShowMenu()"
            [icon]="ICON.ellipsisVertical"
            [loading]="loading.menu"
            [style.margin-left]="spaceBetween"
            loaderContainerStyleClass="xs-mt-minus-5"
            loaderPosition="bottom"
            styleClass="{{ buttonStyleClass }}"
            type="text">
    </xs-button>
    <p-tieredMenu
        #actionMenu
        [appendTo]="'body'"
        [model]="actionMenuItems"
        [popup]="true"
        styleClass="xs-min-width-225">
    </p-tieredMenu>
</div>