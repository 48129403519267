import {Injectable} from '@angular/core';
import {LCEMunicipality} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDataManagerComponent, XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEMunicipalityCreateUpdateComponent} from './create-update/lce-municipality-create-update.component';
import {LCEMunicipalityRecordComponent} from './record/lce-municipality-record.component';

export interface LCEMunicipalityDialogCreateUpdateArg {
    municipalityID?: string;
    municipality?: LCEMunicipality;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (municipality?: LCEMunicipality) => void;
    onSave?: (municipality: LCEMunicipality) => void;
}

export interface LCEMunicipalityDialogRecordArg {
    municipalityID?: string;
    municipality?: LCEMunicipality;
    showEditButton?: boolean;
    dataManager?: XSDataManagerComponent;
    onClose?: (municipality?: LCEMunicipality) => void;
    onEdit?: (municipality: LCEMunicipality) => void;
}

@Injectable()
export class LCEMunicipalityDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEMunicipalityDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');
        if (XSUtils.isEmpty(arg.municipalityID) && XSUtils.isEmpty(arg.municipality)) {
            throw new Error('arg.municipalityID and arg.municipality cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.municipalityID) && !XSUtils.isEmpty(arg.municipality)) {
            throw new Error('arg.municipalityID and arg.municipality cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                municipalityID: arg.municipalityID,
                municipality: arg.municipality,
                showEditButton: arg.showEditButton,
                dataManager: arg.dataManager,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const recordComponent: LCEMunicipalityRecordComponent = this.dialogService.openComponentDialog(LCEMunicipalityRecordComponent, dConfig);

        recordComponent.closeEvent.subscribe((saveMunicipality: LCEMunicipality) => {
            if (arg?.onClose) arg!.onClose!(saveMunicipality);
        });
        recordComponent.editEvent.subscribe((municipalityRecord: LCEMunicipality) => {
            if (arg?.onEdit) arg.onEdit(municipalityRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    municipality: municipalityRecord,
                    onClose: (municipality) => this.openRecordDialog({municipality: municipality, showEditButton: true}),
                    onSave: (municipality) => this.openRecordDialog({municipality: municipality, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEMunicipalityDialogCreateUpdateArg): void {
        if (!XSUtils.isEmpty(arg?.municipalityID) && !XSUtils.isEmpty(arg?.municipality)) {
            throw new Error('arg.municipalityID and arg.municipality cannot both be set at the same time.');
        }
        const dConfig: XSDialogConfig = {
            data: {
                municipalityID: arg?.municipalityID,
                municipality: arg?.municipality,
                styleClass: 'xs-width-full xs-pt-30',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEMunicipalityCreateUpdateComponent = this.dialogService.openComponentDialog(LCEMunicipalityCreateUpdateComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });
        createUpdateComponent.closeEvent.subscribe((municipality: LCEMunicipality) => {
            if (arg?.onClose) arg!.onClose!(municipality);
        });
        createUpdateComponent.saveEvent.subscribe((municipality: LCEMunicipality) => {
            if (!municipality) {
                createUpdateComponent.closeDialog();
                return;
            }
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(municipality);
            if (arg?.showRecordAfterSave === true) this.openRecordDialog({municipality: municipality});
        });
    }
}
