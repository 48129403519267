<div class="lce-template-sheldon-router">
    <div class="lce-template-sheldon-router-header xs-pb-15">
        <div class="lce-template-sheldon-router-title-container">
            <span class="lce-template-sheldon-router-title">{{ TR_BASE_MAIN + 'title' | translate }}</span>
            <span class="lce-template-sheldon-router-sub-title">{{ TR_BASE_MAIN + 'subTitle' | translate }}</span>
            </div>
        <div class="lce-template-sheldon-router-action-container">
            <xs-button [transform]="false" type="text" size="intermediate" class="xs-mr-40" [icon]="ICON.plus"
                [label]="TR_BASE_MAIN + 'createMunicipality'" (clickEvent)="onCreate()">
            </xs-button>
            <xs-button [transform]="false" type="icon" [icon]="ICON.refresh" (clickEvent)="onRefresh()"></xs-button>
        </div>
    </div>
    <div class="lce-template-sheldon-router-content">
        <lce-municipalities styleClass="xs-mt-25"></lce-municipalities>
    </div>
</div>