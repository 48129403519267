import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEMunicipalitiesFeatureService} from '@lce/shared';
import {LCECMDMunicipalitiesRoutingModule} from './lce-cmd-municipalities-routing.module';
import {LCECMDMunicipalitiesComponent} from './lce-cmd-municipalities.component';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, LCECMDMunicipalitiesRoutingModule],
    declarations: [LCECMDMunicipalitiesComponent],
    exports: [LCECMDMunicipalitiesComponent],
    providers: [LCEMunicipalitiesFeatureService]
})
export class LCECMDMunicipalitiesModule {
}
