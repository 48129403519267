<div
        class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #facilityRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !facilityRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE_LABEL }}errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="headerAvatar" [subtitle]="headerSubTitle" [title]="headerTitle"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-flex-wrap xs-justify-content-space-between xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="facility!"></lce-user-resource-audit>

                <div class="xs-flex-row xs-align-items-center">
                    <xs-error
                            *ngIf=" (resourceAuditFullMenuAction | xsIsNotEmpty) && resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <xs-button
                            (clickEvent)="onEdit()"
                            *ngIf="canEdit()"
                            [disabled]="(resourceAuditFullMenuAction | xsIsNotEmpty) && !resourceAuditFullMenuAction?.canDoAction()"
                            [icon]="ICON_EDIT"
                            class="xs-mr-40"
                            label="xs.core.label.edit"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <xs-pk-resource-audit-full-menu-action
                        #resourceAuditFullMenuAction
                        *ngIf="canShowAuditFullMenuActions()"
                        [dataManager]="dataManager!"
                        [resourceService]="facilityService">
                    </xs-pk-resource-audit-full-menu-action>
                </div>

            </div>
            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Facility Information  === -->
                <!-- -----------------------------------------------------------------------------------------------    ------- -->
                <div class="col-12 md:col-6">
                    <xs-data-field-text [upperCase]="true" [value]="facility!.code" label="xs.core.label.code"
                                        valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-resource-state [value]="facility"></xs-data-field-resource-state>
                </div>
                <div class="col-12 md:col-6">
                    <div class="xs-data-field">
                        <xs-data-field-label label="lce.shared.label.municipality"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <lce-municipality-partial [data]="facility!.municipality"></lce-municipality-partial>
                        </div>
                    </div>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE_LABEL + 'name'" [value]="facility!.name">
                    </xs-data-field-text>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-text [label]="TR_BASE_LABEL + 'fullName'" [value]="facility!.fullName">
                    </xs-data-field-text>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-text
                            [label]="TR_BASE_LABEL + 'subName'"
                            [required]="false"
                            [value]="facility!.subName">
                    </xs-data-field-text>
                </div>
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Contact === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-12 md:col-6">
                    <xs-data-field-contact [required]="false" [value]="facilityContact" label="xs.core.label.contact">
                    </xs-data-field-contact>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-address [editable]="canEdit()" [options]="addressFieldOptions" [value]="facility!.address"
                                           label="xs.common.label.address"></xs-data-field-address>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-contact-person [deletable]="canEdit()"
                                                  [editable]="canEdit()" [options]="primaryContactPersonFieldOptions" [value]="facility!.primaryContactPerson"
                                                  label="xs.core.label.primaryContactPerson">
                    </xs-data-field-contact-person>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-contact-person [deletable]="canEdit()" [editable]="canEdit()"
                                                  [options]="secondaryContactPersonFieldOptions" [required]="false" [value]="facility!.secondaryContactPerson"
                                                  label="xs.core.label.secondaryContactPerson">
                    </xs-data-field-contact-person>
                </div>
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="xs-flex-column xs-data-field">
                    <div [style.border-left-width]="'15px'" class="xs-card xs-card-border">
                        <div class="xs-card-header">
                            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                                <div class="xs-flex-column">
                                    <span class="xs-display-block xs-font-size-intermediate xs-font-weight-500">{{ TR_BASE + 'townHallRegistration.label.title' | translate }}</span>
                                    <span class="xs-display-block xs-font-size-small xs-mt-1">{{ TR_BASE + 'townHallRegistration.label.subTitle' | translate }}</span>
                                </div>
                                <xs-icon [value]="ICON_CERTIFICATE_ORDER" color="secondary" size="30px"></xs-icon>
                            </div>
                        </div>
                        <div class="xs-card-content xs-pt-40-imp xs-pb-0-imp xs-pl-50-imp xs-pr-30-imp">
                            <div class="xs-flex-row xs-justify-content-end">
                                <xs-button
                                        (clickEvent)="onAddUpdatePartnershipDetail()"
                                        *ngIf="canUpdatePartnership()"
                                        [label]="hasPartnership() ? TR_BASE + 'townHallStampLoad.label.updatePartnerShipDetail' : TR_BASE + 'townHallStampLoad.label.addPartnerShipDetail'"
                                        class="xs-sm-display-none"
                                        size="intermediate"
                                        type="text">
                                </xs-button>
                            </div>
                            <lce-facility-town-hall-registration-record [certificateOrderRegistration]="facility!.certificateOrderRegistration!" [showHeader]="false"></lce-facility-town-hall-registration-record>
                        </div>
                    </div>
                </div>
                <div class="col-12 xs-flex-row-center">
                    <div class="xs-width-full xs-max-width-400">
                        <xs-image [src]="facility?.mainImage" alt="Facility Image" noImageStyleClass="xs-max-width-150">
                        </xs-image>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="facility!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON_FACILITY"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
