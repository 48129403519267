import {XSLabelValue} from '@xs/common';
import {LCECertificateType} from '../domain/certificate/lce-certificate';
import {LCECertificateOrderStatus} from '../domain/certificate/order/lce-certificate-order-status';
import {LCEFacilityType} from '../domain/facility/lce-facility';
import {LCEUserEmployeeMainRole, LCEUserEmployeePosition} from '../domain/user/lce-user-employee';
import {LCEUserMunicipalEmployeeMainRole, LCEUserMunicipalEmployeePosition} from '../domain/user/lce-user-municipal-employee';
import IVORIAN_FIRSTNAMES_JSON from '../assets/data/lce-ivorian-firstName.json';
import IVORIAN_LASTNAMES_JSON from '../assets/data/lce-ivorian-lastName.json';
import {
    LCE_MOCK_MUNICIPALITY_CODE_COCODY,
    LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON,
    LCE_MOCK_MUNICIPALITY_NAME_COCODY,
    LCE_MOCK_MUNICIPALITY_NAME_YOPOUGON,
    LCE_TR_BASE_CERTIFICATE_ORDER_STATUS,
    LCE_TR_BASE_CERTIFICATE_TYPE,
    LCE_TR_BASE_FACILITY_TYPE,
    LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE,
    LCE_TR_BASE_USER_EMPLOYEE_POSITION,
    LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE,
    LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION
} from './lce-core.constant';

export const LCE_MUNICIPALITY_ITEMS: XSLabelValue[] = [
    {label: LCE_MOCK_MUNICIPALITY_NAME_YOPOUGON, value: LCE_MOCK_MUNICIPALITY_CODE_YOPOUGON},
    {label: LCE_MOCK_MUNICIPALITY_NAME_COCODY, value: LCE_MOCK_MUNICIPALITY_CODE_COCODY}
];

export const LCE_CERTIFICATE_TYPE_ITEMS: XSLabelValue[] = [
    {label: LCE_TR_BASE_CERTIFICATE_TYPE + LCECertificateType.BIRTH, value: LCECertificateType.BIRTH},
    {label: LCE_TR_BASE_CERTIFICATE_TYPE + LCECertificateType.MARRIAGE, value: LCECertificateType.MARRIAGE},
    {label: LCE_TR_BASE_CERTIFICATE_TYPE + LCECertificateType.DEATH, value: LCECertificateType.DEATH}
];

export const LCE_FACILITY_TYPE_ITEMS: XSLabelValue[] = [
    {label: LCE_TR_BASE_FACILITY_TYPE + LCEFacilityType.TOWN_HALL, value: LCEFacilityType.TOWN_HALL},
    {label: LCE_TR_BASE_FACILITY_TYPE + LCEFacilityType.TOWN_HALL_ANNEXE, value: LCEFacilityType.TOWN_HALL_ANNEXE},
    {label: LCE_TR_BASE_FACILITY_TYPE + LCEFacilityType.POLICE_STATION, value: LCEFacilityType.POLICE_STATION},
    {label: LCE_TR_BASE_FACILITY_TYPE + LCEFacilityType.COURTHOUSE, value: LCEFacilityType.COURTHOUSE}
];

export const LCE_CERTIFICATE_ORDER_STATUS_COLOR_MAP: Map<LCECertificateOrderStatus, { color: string; backgroundColor: string; }> = new Map([
    [LCECertificateOrderStatus.PENDING, {color: 'xs-color-gray', backgroundColor: 'xs-background-gray'}],
    [LCECertificateOrderStatus.PROCESSING, {color: 'xs-color-gray', backgroundColor: 'xs-background-gray'}],
    [LCECertificateOrderStatus.PRINT, {color: 'xs-color-orange', backgroundColor: 'xs-background-orange'}],
    [LCECertificateOrderStatus.CANCELLED, {color: 'xs-color-black', backgroundColor: 'xs-background-black'}],
    [LCECertificateOrderStatus.ERROR, {color: 'xs-color-danger', backgroundColor: 'xs-background-error'}],
    [LCECertificateOrderStatus.TRANSIT, {color: 'xs-color-gray', backgroundColor: 'xs-background-error'}],
    [LCECertificateOrderStatus.DELIVERY_ASSIGNED, {color: 'xs-color-primary', backgroundColor: 'xs-background-primary'}],
    [LCECertificateOrderStatus.READY, {color: 'xs-color-primary', backgroundColor: 'xs-background-primary'}],
    [LCECertificateOrderStatus.OUT_FOR_DELIVERY, {color: 'xs-color-gray', backgroundColor: 'xs-background-gray'}],
    [LCECertificateOrderStatus.DELIVERED, {color: 'xs-color-success', backgroundColor: 'xs-background-green'}]
]);
export const LCE_CERTIFICATE_ORDER_STATUS_ITEMS: XSLabelValue[] = [
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PENDING, value: LCECertificateOrderStatus.PENDING},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PROCESSING, value: LCECertificateOrderStatus.PROCESSING},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.PRINT, value: LCECertificateOrderStatus.PRINT},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.ERROR, value: LCECertificateOrderStatus.ERROR},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.TRANSIT, value: LCECertificateOrderStatus.TRANSIT},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.READY, value: LCECertificateOrderStatus.READY},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.OUT_FOR_DELIVERY, value: LCECertificateOrderStatus.OUT_FOR_DELIVERY},
    {label: LCE_TR_BASE_CERTIFICATE_ORDER_STATUS + LCECertificateOrderStatus.DELIVERED, value: LCECertificateOrderStatus.DELIVERED}
];

// === User Municipal Employee ===
export const LCE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLES: XSLabelValue[] = [
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.ROOT, value: LCEUserMunicipalEmployeeMainRole.ROOT},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.SUPER_ADMIN, value: LCEUserMunicipalEmployeeMainRole.SUPER_ADMIN},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.ADMIN, value: LCEUserMunicipalEmployeeMainRole.ADMIN},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.MANAGER, value: LCEUserMunicipalEmployeeMainRole.MANAGER},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.AGENT, value: LCEUserMunicipalEmployeeMainRole.AGENT},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE + LCEUserMunicipalEmployeeMainRole.GUEST, value: LCEUserMunicipalEmployeeMainRole.GUEST}
];
export const LCE_USER_MUNICIPAL_EMPLOYEE_POSITIONS: XSLabelValue[] = [
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.SYSTEM_ADMINISTRATOR, value: LCEUserMunicipalEmployeePosition.SYSTEM_ADMINISTRATOR},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.SYSTEM_MAINTAINER, value: LCEUserMunicipalEmployeePosition.SYSTEM_MAINTAINER},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.MAYOR, value: LCEUserMunicipalEmployeePosition.MAYOR},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.DEPUTY_MAYOR, value: LCEUserMunicipalEmployeePosition.DEPUTY_MAYOR},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.DSA, value: LCEUserMunicipalEmployeePosition.DSA},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.DSI, value: LCEUserMunicipalEmployeePosition.DSI},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.ADMINISTRATIVE_AGENT, value: LCEUserMunicipalEmployeePosition.ADMINISTRATIVE_AGENT},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.IT_AGENT, value: LCEUserMunicipalEmployeePosition.IT_AGENT},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.MUNICIPAL_EMPLOYEE, value: LCEUserMunicipalEmployeePosition.MUNICIPAL_EMPLOYEE},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.GUEST, value: LCEUserMunicipalEmployeePosition.GUEST},
    {label: LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_POSITION + LCEUserMunicipalEmployeePosition.UNKNOWN, value: LCEUserMunicipalEmployeePosition.UNKNOWN}
];

// === User LCE Employee ===
export const LCE_USER_EMPLOYEE_MAIN_ROLES: XSLabelValue[] = [
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.ROOT, value: LCEUserEmployeeMainRole.ROOT},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.SUPER_ADMIN, value: LCEUserEmployeeMainRole.SUPER_ADMIN},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.ADMIN, value: LCEUserEmployeeMainRole.ADMIN},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.MANAGER, value: LCEUserEmployeeMainRole.MANAGER},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.AGENT, value: LCEUserEmployeeMainRole.AGENT},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.EMPLOYEE, value: LCEUserEmployeeMainRole.EMPLOYEE},
    {label: LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE + LCEUserEmployeeMainRole.GUEST, value: LCEUserEmployeeMainRole.GUEST}
];
export const LCE_USER_EMPLOYEE_POSITIONS: XSLabelValue[] = [
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.CEO, value: LCEUserEmployeePosition.CEO},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.TECHNICAL_DIRECTOR, value: LCEUserEmployeePosition.TECHNICAL_DIRECTOR},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.DISPATCHER, value: LCEUserEmployeePosition.DISPATCHER},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.FIELD_AGENT, value: LCEUserEmployeePosition.FIELD_AGENT},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.OFFICE_AGENT, value: LCEUserEmployeePosition.OFFICE_AGENT},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.QUALITY_ASSURANCE_AGENT, value: LCEUserEmployeePosition.QUALITY_ASSURANCE_AGENT},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.QUALITY_ASSURANCE_MANAGER, value: LCEUserEmployeePosition.QUALITY_ASSURANCE_MANAGER},
    {label: LCE_TR_BASE_USER_EMPLOYEE_POSITION + LCEUserEmployeePosition.GUEST, value: LCEUserEmployeePosition.GUEST}
];

// === JSONs ===
export const LCE_IVORIAN_FIRSTNAMES = IVORIAN_FIRSTNAMES_JSON;
export const LCE_IVORIAN_LASTNAMES = IVORIAN_LASTNAMES_JSON;
