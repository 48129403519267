import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable, Optional} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCEEnvironmentService, LCEGlobalSearchResult, LCEGlobalSearchResultMap, LCEResourceType} from '@lce/core';
import {LCEMockCertificateOrderHandler} from '@lce/mock/core/handlers/certificate/lce-mock-certificate-order-handler';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCEMockBirthDeclarationHandler} from '@lce/mock/core/handlers/lce-mock-birth-declaration-handler';
import {LCEMockDistrictHandler} from '@lce/mock/core/handlers/lce-mock-district-handler';
import {LCEMockFacilityWorkerHandler} from '@lce/mock/core/handlers/lce-mock-facility-worker-handler';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockServicePointHandler} from '@lce/mock/core/handlers/lce-mock-service-point-handler';
import {LCEMockNewsArticleHandler} from '@lce/mock/core/handlers/news/lce-mock-news-article-handler';
import {LCEMockSmartCityArticleHandler} from '@lce/mock/core/handlers/smartcity/lce-mock-smartcity-article-handler';
import {LCEMockUserCustomerHandler} from '@lce/mock/core/handlers/user/lce-mock-user-customer-handler';
import {LCEMockUserDeliveryManHandler} from '@lce/mock/core/handlers/user/lce-mock-user-delivery-man-handler';
import {LCEMockUserEmployeeHandler} from '@lce/mock/core/handlers/user/lce-mock-user-employee-handler';
import {LCEMockUserMunicipalEmployeeHandler} from '@lce/mock/core/handlers/user/lce-mock-user-municipal-employee-handler';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSUtils} from '@xs/base';
import {XSMockHttpHandler, XSMockPKSearchable} from '@xs/mock';

@Injectable()
export class LCEMockGlobalSearchHandler extends XSMockHttpHandler {

    private static HANDLERS: Map<LCEResourceType, XSMockPKSearchable<any>> = new Map<LCEResourceType, XSMockPKSearchable<any>>();

    private readonly N_FIRST_RESULTS = 5;

    constructor(
        private environmentService: LCEEnvironmentService,
        private mockDistrictHandler: LCEMockDistrictHandler,
        private mockMunicipalityHandler: LCEMockMunicipalityHandler,
        private mockFacilityHandler: LCEMockFacilityHandler,
        private mockServicePointHandler: LCEMockServicePointHandler,
        private mockCertificateOrderHandler: LCEMockCertificateOrderHandler,
        @Optional() private mockFacilityWorkerHandler: LCEMockFacilityWorkerHandler,
        @Optional() private mockBirthDeclarationHandler: LCEMockBirthDeclarationHandler,
        @Optional() private mockNewsArticleHandler: LCEMockNewsArticleHandler,
        @Optional() private mockSmartcityArticleHandler: LCEMockSmartCityArticleHandler,
        @Optional() private mockUserCustomerHandler: LCEMockUserCustomerHandler,
        @Optional() private mockUserMunicipalEmployeeHandler: LCEMockUserMunicipalEmployeeHandler,
        @Optional() private mockUserEmployeeHandler: LCEMockUserEmployeeHandler,
        @Optional() private mockUserDeliveryManHandler: LCEMockUserDeliveryManHandler) {
        super();

        LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.DISTRICT, mockDistrictHandler);
        LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.MUNICIPALITY, mockMunicipalityHandler);
        LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.FACILITY, mockFacilityHandler);
        LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.SERVICE_POINT, mockServicePointHandler);
        LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.CERTIFICATE_ORDER, mockCertificateOrderHandler);
        // --- * ---
        if (mockFacilityWorkerHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.FACILITY_WORKER, mockFacilityWorkerHandler);
        if (mockBirthDeclarationHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.BIRTH_DECLARATION, mockBirthDeclarationHandler);
        // --- * ---
        if (mockNewsArticleHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.NEWS_ARTICLE, mockNewsArticleHandler);
        if (mockSmartcityArticleHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.SMARTCITY_ARTICLE, mockSmartcityArticleHandler);
        // --- * ---
        if (mockUserCustomerHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.CUSTOMER, mockUserCustomerHandler);
        if (mockUserMunicipalEmployeeHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.MUNICIPAL_EMPLOYEE, mockUserMunicipalEmployeeHandler);
        if (mockUserEmployeeHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.EMPLOYEE, mockUserEmployeeHandler);
        if (mockUserDeliveryManHandler) LCEMockGlobalSearchHandler.HANDLERS.set(LCEResourceType.DELIVERY_MAN, mockUserDeliveryManHandler);
    }

    buildMockDataArray(): void {
        this.mockDataArray = [
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.globalSearch',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.globalSearch,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 500,
                getResponseData: rArg => this.searchResponseData(rArg.params)
            }
        ];
    }

    private searchResponseData(params?: HttpParams): LCEGlobalSearchResultMap {
        const query = params?.get('query')?.trim();

        const result: LCEGlobalSearchResultMap = {};

        if (query === 'empty') return result;

        const httpParams = params?.set('paginationPage', 0).set('paginationSize', this.N_FIRST_RESULTS);

        LCEMockGlobalSearchHandler.HANDLERS.forEach((handler: XSMockPKSearchable<any>, resourceType: LCEResourceType) => {
            if (!this.canSearchForResource(resourceType)) {
                return;
            }
            const searchResult = handler.search(httpParams);

            if (!XSUtils.isEmpty(searchResult?.data) && searchResult.total > 0) {
                result[resourceType] = {
                    type: resourceType,
                    total: searchResult.total,
                    data: searchResult.data
                } as LCEGlobalSearchResult<any>;
            }
        });

        return result;
    }

    private canSearchForResource(resourceType: LCEResourceType): boolean {
        return !(this.environmentService.isMBO() && resourceType === LCEResourceType.EMPLOYEE);
    }
}
