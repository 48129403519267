import {XSIntegerRange, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSResourceAuditFullCanOptions} from '@xs/base';
import {LCEUserPartial} from './user/lce-user';

export interface LCEDistrictCanOptions extends XSResourceAuditFullCanOptions {
}

export enum LCEDistrictEventName {
    NEW = 'districtNew',
}

export interface LCEDistrictSearch extends XSPKAuditFullSearchFilter {
    codes?: string[];
    numberOfMunicipalitiesRange?: XSIntegerRange;
    populationSizeRange?: XSIntegerRange;
    populationUpdateYearRange?: XSIntegerRange;
    squareKMRange?: XSIntegerRange;
}

export interface LCEDistrictCreate {
    name: string;
    abbreviation: string;
    fullName: string;
    shortName?: string;
    capital?: string;
    numberOfMunicipalities: number;
    populationSize?: number;
    populationUpdateYear?: number;
    squareKM?: number;
    mapImage?: any;
}

export interface LCEDistrictPartial {
    id: string;
    code: string;
    name: string;
    fullName: string;
    shortName?: string;
    abbreviation: string;
}

export interface LCEDistrict extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;
    name: string;
    abbreviation: string;
    fullName: string;
    shortName?: string;
    capital?: string;
    numberOfMunicipalities: number;
    populationSize?: number;
    populationUpdateYear?: number;
    squareKM?: number;
    mapImage?: string;
}
