import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {LCECertificateOrderPartial, LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateOrderStatus} from '@lce/core';
import {XSPagination, XSPeriod, XSPredefinedPeriod, XSSearchResult, XSUtils} from '@xs/base';
import {XSLabelValueItem, XSLoaderService, XSLoaderType, XSTranslationService} from '@xs/common';
import {XSButton, XSInputMultiSelectOptions, XSPaginationLoadMoreOptions} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-certificate-order-widget', templateUrl: './lce-certificate-order-widget.component.html'})
export class LCECertificateOrderWidgetComponent implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;
    readonly SEARCH_TEXT_DELAY: number = 500;
    readonly SEARCH_TEXT_MIN_N_CHARS: number = 2;
    readonly SEARCH_TEXT_MAX_LENGTH: number = 100;

    readonly ORDER_STATUS_TR_BASE = 'lce.core.certificateOrderStatus.';
    readonly TR_BASE = 'lce.shared.certificateOrders.widget.orders.';

    readonly LOADER_ID = XSUtils.uuid();
    readonly ORDER_NUMBER_LOADER_ID = XSUtils.uuid();
    readonly loaderType: XSLoaderType = XSLoaderType.THREE_BOUNCE;

    @Input() styleClass?: string;
    @Input() titleStyleClass?: string;
    @Input() subTitleStyleClass?: string;

    @Input() title?: string;
    @Input() subTitle?: string;

    @Input() selectMode?: 'multiple' | 'single';

    @Input() showMap?: boolean;
    @Input() showHeaderBackgroundColor?: boolean;

    @Input() disabled?: boolean;
    @Output() selectedOrderEvent = new EventEmitter<Partial<LCECertificateOrderPartial> | Partial<LCECertificateOrderPartial>[]>();

    selectedOrders: LCECertificateOrderPartial[] = [];
    unassignedOrders: LCECertificateOrderPartial[];

    totalResults: number = 0;

    orderStatus: LCECertificateOrderStatus[];
    orderStatusItems: XSLabelValueItem[] = [
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.PRINT, value: LCECertificateOrderStatus.PRINT},
        {trLabel: this.ORDER_STATUS_TR_BASE + LCECertificateOrderStatus.READY, value: LCECertificateOrderStatus.READY}
    ];
    statusSearchField: XSInputMultiSelectOptions;

    pagination: XSPagination = {
        size: 5,
        page: 0
    };

    certificateOrderSearch: LCECertificateOrderSearch = {
        paginationPage: this.pagination.page,
        paginationSize: this.pagination.size,
        statuses: [LCECertificateOrderStatus.READY],
        sort: ['createdBy|desc']
    };

    searchText: string;
    searching: boolean;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.search()
    };

    paginationOptions: XSPaginationLoadMoreOptions = {
        fnSearch: (pagination) => {
            this.certificateOrderSearch.paginationPage = pagination.page;
            this.certificateOrderSearch.paginationSize = pagination.size;
            return this.certificateOrderService.search(this.certificateOrderSearch!);
        }
    };

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private certificateOrderService: LCECertificateOrderService, private translationService: XSTranslationService) {
        this.initializeTranslation();
    }

    private _disabledSearchFields: boolean;

    get disabledSearchFields(): boolean {
        return this._disabledSearchFields;
    }

    ngOnInit(): void {
        this.buildSearchFields();
        this.search();

        if (XSUtils.isNull(this.title)) this.title = this.TR_BASE + 'title';
        if (XSUtils.isNull(this.subTitle)) this.subTitle = this.TR_BASE + 'subTitle.orderNoSelected';

        if (XSUtils.isNull(this.selectMode)) this.selectMode = 'single';
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onInputSearch(): void {
        this.certificateOrderSearch.query = this.searchText;
        this.search();
    }

    public onStatusSearch(status: any): void {
        this.certificateOrderSearch.statuses = status;
        this.search();
    }

    public onPeriodSelectEvent(period: XSPeriod): void {
        console.log('Period', period);
        this.certificateOrderSearch.createdOnPredefinedPeriod = period.value as XSPredefinedPeriod;
        this.search();
    }

    public update(disabledSearchFields: boolean = false): void {
        this.search(disabledSearchFields);
        this.selectedOrders = [];
        this.subTitle = this.TR_BASE + 'subTitle.orderNoSelected';
    }

    public isSelected(order: LCECertificateOrderPartial): boolean {
        return !XSUtils.isNull(this.selectedOrders.find((elt) => elt.id === order.id));
    }

    public handlePaginationLoad(searchResults: XSSearchResult<LCECertificateOrderPartial>): void {
        this.unassignedOrders = this.unassignedOrders.concat(searchResults.data);
        this.totalResults = searchResults.total;
    }

    public handlePaginationError(error: any) {
        this.error = error;
    }

    public onOrderSelect(order: LCECertificateOrderPartial): void {
        this.setOrderSelected(order);

        this.selectMode === 'single'
            ? this.selectedOrderEvent.emit(this.toIdOrderNumber(this.selectedOrders[0]))
            : this.selectedOrderEvent.emit(this.selectedOrders.map((el) => this.toIdOrderNumber(el)));

        if (this.selectedOrders.length > 0) this.subTitle = this.translationService.translateKey(this.subTitle = this.TR_BASE + 'subTitle.orderSelected', {nOrder: this.selectedOrders.length});
    }

    public search(disabledSearchFields?: boolean): void {
        this.error = undefined;
        this.unassignedOrders = [];
        if (disabledSearchFields === true) this.setDisabledSearchField(true);

        this.loaderService.startLoader(this.LOADER_ID);
        this.loaderService.startLoader(this.ORDER_NUMBER_LOADER_ID);
        this.subscription.add(
            this.certificateOrderService
                .search(this.certificateOrderSearch!)
                .pipe(
                    finalize(() => {
                        this.loaderService.stopLoader(this.LOADER_ID);
                        this.loaderService.stopLoader(this.ORDER_NUMBER_LOADER_ID);
                    })
                )
                .subscribe({
                    next: (searchResults: XSSearchResult<LCECertificateOrderPartial>) => {
                        this.unassignedOrders = searchResults.data;
                        this.pagination = searchResults.pagination!;
                        this.totalResults = searchResults.total;
                        this.selectedOrders = [];
                        if (disabledSearchFields === true) this.setDisabledSearchField(false);
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    private setDisabledSearchField(disabledSearchFields: boolean) {
        this._disabledSearchFields = disabledSearchFields;
    }

    private buildSearchFields(): void {
        this.statusSearchField = {
            labelField: 'trLabel',
            valueField: 'value',
            filter: false,
            filterBy: 'trLabel',
            showToggleAll: true,
            translateItemLabels: true
        };
    }

    private initializeTranslation(): void {
        this.translationService.translateItems(this.orderStatusItems);
        this.subscription.add(
            this.translationService.onLanguageChanged.subscribe(() => {
                this.translationService.translateItems(this.orderStatusItems);
                this.orderStatusItems = [...this.orderStatusItems];
            })
        );
    }

    private setOrderSelected(order: LCECertificateOrderPartial): void {
        if (this.selectedOrders.includes(order)) {
            let index = this.selectedOrders.indexOf(order);
            this.selectedOrders.splice(index, 1);
        } else {
            if (this.selectMode === 'single') this.selectedOrders = [];
            this.selectedOrders.push(order);
        }
    }

    private toIdOrderNumber(order: LCECertificateOrderPartial): Partial<LCECertificateOrderPartial> {
        return {
            id: order.id,
            orderNumber: order.orderNumber
        };
    }
}
