<div class="xs-flex-column {{ styleClass }}">
    <xs-ragnar
            (bottomButtonClickEvent)="continueEvent.emit()"
            [avatar]="{type: 'icon', data: ICON.clock}"
            [bottomButton]="{type: 'text', label:'lce.shared.certificateOrders.process.continueRequest'}"
            [timeAgoValue]="createdOn"
            line1="lce.shared.certificateOrders.process.pendingRequest"
            line2="{{ 'lce.core.certificateTypeMedium.' + certificateType | translate }} - {{ referenceNumber }}"
            line2Color="secondary"
            line2StyleClass="xs-font-weight-600"
            styleClass="xs-p-10-imp">
    </xs-ragnar>
</div>