import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LCEUser, LCEUserCreate, LCEUserDeliveryManService, LCEUserEmployeeService, LCEUserMunicipalEmployeeService, LCEUserPartial, LCEUserSearch, LCEUserService, LCEUserType} from '@lce/core';
import {LOG, XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSIvarAvatarType} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {LCEUserAccountContactCreateUpdateComponent} from './contact/lce-user-account-contact-create-update.component';
import {LCEUserAccountPersonalInformationCreateUpdateComponent} from './personal-information/lce-user-account-personnal-information-create-update.component';

@Component({
    selector: 'lce-user-account-create-update',
    templateUrl: './lce-user-account-create-update.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCEUserAccountCreateUpdateComponent extends XSDialogable implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE_LABEL: string = 'lce.shared.user.label.';
    readonly TR_BASE: string = 'lce.shared.user.';

    readonly LOADER_ID_CENTRAL: string = 'userCreateUpdateCentralLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() formContainerStyleClass?: string;

    @Input() showSecondaryPhoneNumberField?: boolean;
    @Input() showWhatsAppPhoneNumberField?: boolean;

    @Input() userID?: string;
    @Input() user?: LCEUser;
    @Input() userType: LCEUserType;

    @Output() saveEvent = new EventEmitter<LCEUser>();
    @Output() closeEvent = new EventEmitter<LCEUser>();

    @ViewChild('personalInformationCreateUpdateComponent') personalInformationCreateUpdateComponent: LCEUserAccountPersonalInformationCreateUpdateComponent;
    @ViewChild('accountCreateUpdateComponent') accountCreateUpdateComponent: LCEUserAccountContactCreateUpdateComponent;

    disabled: boolean = false;
    formGroup: FormGroup = new FormGroup({});
    personalInformationFields = new FormGroup({});
    contactFields = new FormGroup({});

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveUser()
    };

    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;
    avatar: string;
    avatarType: XSIvarAvatarType;
    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private userEmployeeService: LCEUserEmployeeService,
        private userMunicipalEmployeeService: LCEUserMunicipalEmployeeService,
        private userDeliveryManService: LCEUserDeliveryManService
    ) {
        super();
    }

    get headerTitle(): string {
        let title = XS_STR_EMPTY;
        if (this.isCreateMode()) title = this.TR_BASE_LABEL + 'createTitle';
        else if (!XSUtils.isEmpty(this.user?.name)) title = XSCommonDomainUtils.getPersonFullName(this.user!.name!);
        if (XSUtils.isEmpty(title)) title = this.TR_BASE_LABEL + 'user';
        return title;
    }

    get headerSubTitle(): string {
        let subTitle = XS_STR_EMPTY;
        if (this.isCreateMode()) subTitle = this.TR_BASE_LABEL + 'createSubTitle';
        else if (!XSUtils.isEmpty(this.user?.name)) subTitle = this.user!.code;
        if (XSUtils.isEmpty(subTitle)) subTitle = '...';
        return subTitle!;
    }

    get userService(): LCEUserService<LCEUser, LCEUserPartial, LCEUserSearch> {
        switch (this.userType) {
            case LCEUserType.EMPLOYEE:
                return this.userEmployeeService;
            case LCEUserType.DELIVERY_MAN:
                return this.userDeliveryManService;
            case LCEUserType.MUNICIPAL_EMPLOYEE:
                return this.userMunicipalEmployeeService;
            default:
                throw new Error('type not considered ');
        }
    }

    ngOnInit(): void {

        if (this.isDialog()) {
            this.userID = this.dialogConfig.data.userID;
            this.user = this.dialogConfig.data.user;
            this.userType = this.dialogConfig.data.userType;

            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.user));
        }
        if (this.isCreateMode() || !XSUtils.isEmpty(this.user)) {
            if (!XSUtils.isEmpty(this.user)) {
                this.userID = this.user!.id;
            }
            this.initialize();
        } else {
            this.retrieveUser();
        }

    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.userID) || !XSUtils.isEmpty(this.user);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.userID) && XSUtils.isEmpty(this.user);
    }

    public onUploadImage(): void {
        console.log('onUploadImage ...');
    }

    public hasUser(): boolean {
        return !XSUtils.isEmpty(this.user);
    }

    public reset(): void {
        this.formGroup.reset();
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) {
            return !this.isFormEmpty();
        } else {
            return this.buildUpdate(this.formGroup.value).size !== 0;
        }
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    private buildFields(): void {
        this.formGroup.addControl('personalInformation', this.personalInformationFields);
        this.formGroup.addControl('contact', this.contactFields);
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
        this.updateAvatar();
    }

    private isFormEmpty(): boolean {
        return this.personalInformationCreateUpdateComponent.isFormEmpty() && this.accountCreateUpdateComponent.isFormEmpty();
    }

    private updateAvatar(): void {
        if (XSUtils.isEmpty(this.user?.profileImage)) {
            if (!XSUtils.isEmpty(this.formGroup.get('firstName')?.value) && !XSUtils.isEmpty(this.formGroup.get('firstName')?.value)) {
                this.avatar = XSCommonDomainUtils.getPersonInitials({
                    firstName: this.formGroup.get('firstName')?.value,
                    lastName: this.formGroup.get('firstName')?.value
                });
                this.avatarType = XSIvarAvatarType.LABEL;
            } else {
                this.avatar = this.ICON.user;
                this.avatarType = XSIvarAvatarType.ICON;
            }
        } else {
            this.avatar = this.user!.profileImage!;
            this.avatarType = XSIvarAvatarType.IMAGE;
        }
    }

    private retrieveUser(): void {
        this.retrieveError = undefined;
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.userService
            .retrieve(this.userID!)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
            .subscribe({
                next: (userRecord) => {
                    this.user = userRecord;
                    this.initialize();
                    this.updateDialogSize();
                },
                error: (error: any) => (this.retrieveError = error)
            });
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating User [userID: ' + this.userID + '] ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('User not updated. No change detected ! [code: ' + this.user!.code + ', id: ' + this.userID + ']');
            this.saveEvent.emit(this.user);
            return;
        }
        this.createUpdateLoading = true;

        this.subscription.add(
            this.userService
                .update(this.userID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (userRecord) => {
                        this.user = userRecord;
                        this.userID = this.user.id;
                        LOG().debug('User successfully updated :-) [code: ' + this.user.code + ', id: ' + this.userID + ']');
                        this.saveEvent.emit(this.user);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating User ...');

        this.createUpdateLoading = true;
        const formData = this.formGroup.value;
        const userCreate = this.buildCreate(formData);

        console.log('userCreate', userCreate);

        this.subscription.add(
            this.userService
                .create(userCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (userRecord) => {
                        this.user = userRecord;
                        this.userID = this.user.id;
                        LOG().debug('User successfully saved :-) [code: ' + this.user.code + ', id: ' + this.userID + ']');
                        this.saveEvent.emit(userRecord);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildUpdate(formData: any): Map<string, any> {

        const fieldValueMap = new Map<string, any>();
        if (!XSUtils.equal(XSUtils.trim(formData.personalInformation.firstName), XSUtils.trim(this.user!.name!.firstName))) {
            fieldValueMap.set('name.firstName', XSUtils.trim(formData.personalInformation.firstName));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.personalInformation.lastName), XSUtils.trim(this.user!.name!.lastName))) {
            fieldValueMap.set('name.lastName', XSUtils.trim(formData.personalInformation.lastName));
        }
        if (!XSUtils.equal(formData.personalInformation.gender, this.user!.gender)) fieldValueMap.set('gender', formData.gender);
        if (!XSUtils.equal(formData.personalInformation.language, this.user!.language)) fieldValueMap.set('language', formData.language);

        if (!XSUtils.equal(XSUtils.trim(formData.contact.primaryPhoneNumber), XSUtils.trim(this.user!.primaryPhoneNumber))) {
            fieldValueMap.set('primaryPhoneNumber', XSUtils.trim(formData.contact.primaryPhoneNumber));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.contact.secondaryPhoneNumber), XSUtils.trim(this.user!.secondaryPhoneNumber))) {
            fieldValueMap.set('secondaryPhoneNumber', XSUtils.trim(formData.contact.secondaryPhoneNumber));
        }
        if (!XSUtils.equal(XSUtils.trim(formData.contact.whatsAppPhoneNumber), XSUtils.trim(this.user!.whatsAppPhoneNumber))) {
            fieldValueMap.set('whatsAppPhoneNumber', XSUtils.trim(formData.contact.whatsAppPhoneNumber));
        }

        if (!XSUtils.equal(XSUtils.trim(formData.contact.email), XSUtils.trim(this.user!.email))) {
            fieldValueMap.set('email', XSUtils.trim(formData.contact.email));
        }

        if (!XSUtils.equal(formData.contact.address, this.user!.address)) fieldValueMap.set('address', formData.contact.address);

        if (!XSUtils.equal(formData.contact.emergencyContactPerson, this.user!.emergencyContactPerson)) fieldValueMap.set('emergencyContactPerson', formData.emergencyContactPerson);

        return fieldValueMap;
    }

    private buildCreate(formData: any): LCEUserCreate {
        const userCreate: LCEUserCreate = {
            name: {firstName: formData.personalInformation.firstName.trim(), lastName: formData.personalInformation.lastName.trim()},
            gender: formData.personalInformation.gender,
            language: formData.personalInformation.language,

            primaryPhoneNumber: formData.contact.primaryPhoneNumber.trim(),
            secondaryPhoneNumber: formData.contact.secondaryPhoneNumber?.trim(),
            whatsAppPhoneNumber: formData.contact.whatsAppPhoneNumber?.trim(),
            email: formData.contact.email.trim(),
            address: formData.contact.address,

            emergencyContactPerson: formData.contact.emergencyContactPerson
        };
        XSUtils.removeNullAndUndefinedEntries(userCreate);

        return userCreate;
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'xs.core.label.confirmationResetForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.reset(),
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'xs.core.label.confirmationLeaveForm',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => this.closeDialog(),
            reject: () => {
            }
        };
    }
}
