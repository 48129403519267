import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {
    LCEFacilityTownHallCertificateOrderRegistration,
    LCEFacilityTownHallCertificateOrderRegistrationCreate,
    LCEFacilityTownHallCertificateOrderRegistrationCreateAutomaticTransfer,
    LCEFacilityTownHallCertificateOrderRegistrationCreateStampLoad,
    LCEFacilityTownHallCertificateOrderRegistrationResponse,
    LCEFacilityTownHallCertificateOrderRegistrationType,
    LCEFacilityTownHallStampLoadService,
    LCEUserPartial
} from '@lce/core';
import {LOG, XSAssert, XSCurrencyStr, XSIDCode, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService, XSValidators} from '@xs/common';
import {XSConfirmation, XSDialogable, XSInputFieldRadiosOptions, XSInputFieldTextOptions} from '@xs/core';
import {XSPaymentActorType} from '@xs/payment/base';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESharedContextService} from '../../api/services/lce-shared-context.service';

@Component({
    selector: 'lce-facility-town-hall-registration-create',
    templateUrl: './lce-facility-town-hall-registration-create.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEFacilityTownHallRegistrationCreateComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.facility.townHallRegistration.';
    readonly CREATE_UPDATE_LOADER_ID = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() facility: XSIDCode;
    @Input() certificateOrderRegistration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;
    @Input() currencyCode?: XSCurrencyStr;
    @Input() showBorder?: boolean;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    @Output() saveEvent = new EventEmitter<LCEFacilityTownHallCertificateOrderRegistrationResponse>();
    @Output() closeEvent = new EventEmitter<LCEFacilityTownHallCertificateOrderRegistrationResponse>();

    formGroup: FormGroup = new FormGroup({});
    registrationTypeField: XSInputFieldRadiosOptions;
    orderFeeField: XSInputFieldTextOptions;
    orderUnitPriceField: XSInputFieldTextOptions;

    closeConfirmation: XSConfirmation = {
        key: 'closeConfirmationKey',
        trMessage: 'xs.core.label.confirmationLeaveForm',
        icon: this.ICON.confirmation,
        accept: () => {
            this.closeDialog();
        }
    };
    createUpdateError: any;
    registration: LCEFacilityTownHallCertificateOrderRegistrationCreateStampLoad | LCEFacilityTownHallCertificateOrderRegistrationCreateAutomaticTransfer;
    registrationType: LCEFacilityTownHallCertificateOrderRegistrationType;
    user: LCEUserPartial;
    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private contextService: LCESharedContextService, private facilityTownHallStampLoadService: LCEFacilityTownHallStampLoadService) {
        super();
        this.user = this.contextService.getUser();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facility = this.dialogConfig.data.facility;
            this.certificateOrderRegistration = this.dialogConfig.data.certificateOrderRegistration;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit());
        }
        XSAssert.notEmpty(this.facility, 'facility');

        if (XSUtils.isNull(this.currencyCode)) this.currencyCode = 'XOF';
        this.buildFields();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isCreateLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.CREATE_UPDATE_LOADER_ID);
    }

    public createUpdate(): void {
        if (this.createMode()) this.create();
        else this.update();
    }

    public shouldShowCloseConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    private createMode(): boolean {
        return XSUtils.isEmpty(this.certificateOrderRegistration);
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Creating facility town hall certificate order registration ...');

        this.loaderService.startLoader(this.CREATE_UPDATE_LOADER_ID);
        const formData = this.formGroup.value;
        console.log('Create FormData', formData);
        const facilityTownHallRegistrationCreate = this.buildCreate(formData);
        this.subscription.add(
            this.facilityTownHallStampLoadService.createRegistration(this.facility!.id, facilityTownHallRegistrationCreate)
                .pipe(finalize(() => this.loaderService.stopLoader(this.CREATE_UPDATE_LOADER_ID)))
                .subscribe({
                    next: (registrationResponse) => {
                        this.saveEvent.emit(registrationResponse);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating facility town hall certificate order registration  ...');

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = this.buildUpdate(formData);

        if (fieldValueMap.size === 0) {
            LOG().debug('Certificate order registration not updated. No change detected ! [code: ' + this.facility!.code + ', id: ' + this.facility!.id + ']');
            this.saveEvent.emit(undefined);
            return;
        }

        this.loaderService.startLoader(this.CREATE_UPDATE_LOADER_ID);

        this.subscription.add(
            this.facilityTownHallStampLoadService
                .updateRegistration(this.facility.id!, fieldValueMap)
                .pipe(finalize(() => this.loaderService.stopLoader(this.CREATE_UPDATE_LOADER_ID)))
                .subscribe({
                    next: (updatedRegistrationResponse) => {
                        LOG().debug('Facility town hall certificate order registration successfully updated :-) ');
                        this.saveEvent.emit(updatedRegistrationResponse);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildCreate(formData: any): LCEFacilityTownHallCertificateOrderRegistrationCreate {
        let facilityTownHallCertificateOrderRegistrationCreate: LCEFacilityTownHallCertificateOrderRegistrationCreate = {

            facilityCode: this.facility!.code,
            registrationType: formData.registrationType,
            orderFee: {value: formData.orderFee, currency: this.currencyCode!},
            orderUnitPrice: {value: formData.orderUnitPrice, currency: this.currencyCode!}
        };
        if (this.registrationType === LCEFacilityTownHallCertificateOrderRegistrationType.AUTOMATIC_TRANSFER) facilityTownHallCertificateOrderRegistrationCreate.createAutomaticTransfer = this.registration as LCEFacilityTownHallCertificateOrderRegistrationCreateAutomaticTransfer;
        else facilityTownHallCertificateOrderRegistrationCreate.createStampLoad = this.registration as LCEFacilityTownHallCertificateOrderRegistrationCreateStampLoad;
        return facilityTownHallCertificateOrderRegistrationCreate;
    }

    private buildUpdate(formData: any): Map<string, any> {
        const fieldValueMap = new Map<string, any>();
        if (formData.registrationType !== this.certificateOrderRegistration?.registrationType) fieldValueMap.set('certificateOrderRegistration.registrationType', formData.registrationType);
        if (!XSUtils.equal(formData.orderUnitPrice, this.certificateOrderRegistration?.orderUnitPrice)) fieldValueMap.set('certificateOrderRegistration.orderUnitPrice', {
            value: formData.orderUnitPrice,
            currency: this.currencyCode!
        });
        if (!XSUtils.equal(formData.orderFee, this.certificateOrderRegistration?.orderFee)) fieldValueMap.set('certificateOrderRegistration.orderFee', {value: formData.orderFee, currency: this.currencyCode!});

        return fieldValueMap;
    }

    private isFormEmpty(): boolean {
        return XSUtils.isEmpty(this.formGroup.controls.orderFeeField?.value) &&
            XSUtils.isEmpty(this.formGroup.controls.orderUnitPriceField?.value) &&
            XSUtils.isEmpty(this.formGroup.controls.registrationTypeField?.value);
    }

    private onRegistrationTypeChange(registrationType: LCEFacilityTownHallCertificateOrderRegistrationType): void {
        this.registrationType = registrationType;
        if (registrationType === LCEFacilityTownHallCertificateOrderRegistrationType.AUTOMATIC_TRANSFER) {
            this.registration = {
                receiver: {
                    actorType: XSPaymentActorType.ENTITY,
                    name: this.user.name,
                    mobilePhoneNumber: this.user.primaryPhoneNumber
                }
            } as LCEFacilityTownHallCertificateOrderRegistrationCreateAutomaticTransfer;
        } else {
            this.registration = {} as LCEFacilityTownHallCertificateOrderRegistrationCreateStampLoad;
        }
    }

    private buildFields(): void {
        this.orderFeeField = {
            fieldName: 'orderFee',
            control: new FormControl(this.certificateOrderRegistration?.orderFee?.value),
            label: this.TR_BASE + 'label.orderFee',
            subLabel: this.TR_BASE + 'label.orderFeeSubLabel',
            subLabelStyleClass: 'xs-font-size-small',
            groupAddon: true,
            rightAddonValue: this.currencyCode,
            type: 'number'
        };

        this.orderUnitPriceField = {
            fieldName: 'orderUnitPrice',
            control: new FormControl(this.certificateOrderRegistration?.orderUnitPrice?.value),
            label: this.TR_BASE + 'label.orderUnitPrice',
            subLabel: this.TR_BASE + 'label.orderUnitPriceSubLabel',
            subLabelStyleClass: 'xs-font-size-small',
            groupAddon: true,
            rightAddonValue: this.currencyCode,
            type: 'number'
        };

        this.registrationTypeField = {
            fieldName: 'registrationType',
            label: this.TR_BASE + 'label.registrationType',
            control: new FormControl(this.certificateOrderRegistration?.registrationType, [Validators.required, XSValidators.checkedRadioRequired()]),
            colStyleClass: 'md:col-6',
            radios: [
                {
                    label: this.TR_BASE + 'label.registrationTypestampLoad',
                    value: LCEFacilityTownHallCertificateOrderRegistrationType.STAMP_LOAD,
                    belowText: this.TR_BASE + 'label.registrationTypeStampLoadBelowText'
                },
                {
                    label: this.TR_BASE + 'label.registrationTypeautomaticTransfer',
                    value: LCEFacilityTownHallCertificateOrderRegistrationType.AUTOMATIC_TRANSFER,
                    belowText: this.TR_BASE + 'label.registrationTypeAutomaticTransferBelowText'
                }
            ],
            labelsStyleClass: 'xs-font-size-percent-95-imp',
            changeEvent: registrationType => this.onRegistrationTypeChange(registrationType)
        };

        this.formGroup.addControl(this.orderFeeField.fieldName, this.orderFeeField.control!);
        this.formGroup.addControl(this.orderUnitPriceField.fieldName, this.orderUnitPriceField.control!);
        this.formGroup.addControl(this.registrationTypeField.fieldName, this.registrationTypeField.control!);
    }
}
