import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSCommonUtils, XSSize, XSSizeStr} from '@xs/common';
import {LCE_SHARED_ICON} from '../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-number-of-document', templateUrl: './lce-number-of-document.component.html'})
export class LCENumberOfDocumentComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;
    @Input() disabled?: boolean;
    @Input() belowText?: string;
    @Input() belowTextStyleClass?: string;
    @Input() plusSignStyleClass?: string;
    @Input() minusSignStyleClass?: string;
    @Input() numberStyleClass?: string;

    @Input() size?: XSSize | XSSizeStr | string;

    @Input() numberOfCopiesColor?: string;
    @Input() numberOfCopies: number;
    @Output() numberOfCopiesChange = new EventEmitter<number>();
    @Input() min: number;
    @Input() max: number;

    @Input() formControl: FormControl;
    fontSizeStyleClass?: string;
    fontSizeStyleValue?: string;
    touched: boolean = false;
    private readonly DEFAULT_NUMBER_OF_COPIES: number = 1;
    private readonly DEFAULT_MIN_NUMBER_OF_COPIES: number = 1;
    private readonly DEFAULT_MAX_NUMBER_OF_COPIES: number = 15;

    constructor() {
    }

    ngOnInit(): void {
        if (XSUtils.isNull(this.numberOfCopies)) this.numberOfCopies = this.DEFAULT_NUMBER_OF_COPIES;
        if (XSUtils.isNull(this.min)) this.min = this.DEFAULT_MIN_NUMBER_OF_COPIES;
        if (XSUtils.isNull(this.max)) this.max = this.DEFAULT_MAX_NUMBER_OF_COPIES;
        this.updateSize();
    }

    public markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    public numberOfCopiesMinusOne(): void {
        this.markAsTouched();
        if (this.numberOfCopies === this.min) return;
        this.numberOfCopies--;
        this.numberOfCopiesChange.emit(this.numberOfCopies);
    }

    public numberOfCopiesPlusOne(): void {
        this.markAsTouched();
        if (this.numberOfCopies === this.max) return;
        this.numberOfCopies++;
        this.numberOfCopiesChange.emit(this.numberOfCopies);
    }

    private onTouched: any = () => {
    };

    private updateSize(): void {
        this.fontSizeStyleClass = XS_STR_EMPTY;
        this.fontSizeStyleValue = XS_STR_EMPTY;
        if (XSUtils.isEmpty(this.size)) {
            return;
        }
        if (this.size!.toString().endsWith('px')) {
            this.fontSizeStyleValue = this.size;
            if (!this.fontSizeStyleValue!.includes('!important')) {
                this.fontSizeStyleValue += '!important';
            }
        } else {
            this.isSizeOK(this.size, 'size');
            this.fontSizeStyleClass = 'xs-font-size-' + this.size! + '-imp';
        }
    }

    // TODO: to be remove
    private getVarName(varName?: string): string {
        return XSUtils.isEmpty(varName) ? 'the given variable' : varName!;
    }

    // TODO: to be remove
    private isSizeOK(size: any, varName?: string): void {
        if (!XSCommonUtils.isSize(size)) {
            throw new Error(this.getVarName(varName) + ' must be a size.');
        }
    }
}
