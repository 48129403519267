<div [ngClass]="{ 'xs-disabled': disabled }" class="xs-card xs-card-border xs-min-width-300 xs-width-full {{ styleClass }}">
    <div [ngClass]="{'xs-background-color-discrete': showHeaderBackgroundColor}" [style.filter]="'brightness(106%)'"
         class="xs-card-header xs-card-header-separator">
        <xs-title
                [expandCollapseEnable]="true"
                [expandCollapseTarget]="content"
                [expanded]="true"
                [icon]="ICON.deliveryMan"
                [subText]="subTitle"
                [text]="title!"
                subTextStyleClass="xs-font-size-intermediate xs-color-secondary {{ subTitleStyleClass }}"
                textStyleClass="xs-font-size-default xs-font-weight-500-imp xs-color-secondary {{ titleStyleClass }}">
        </xs-title>
    </div>

    <div #content class="xs-card-content xs-p-0-imp">
        <div [ngClass]="{ 'grid grid-nogutter': mapOnRight }">
            <div [ngClass]="{ 'col-5 xs-border-right-discrete': mapOnRight, 'col-12 xs-border-right-none': !showMap }" class="xs-pt-25-imp">
                <div class="xs-flex-column-center xs-plr-15 xs-mb-30">
                    <xs-input-search
                            (changeEvent)="onInputSearch()"
                            [(value)]="searchText"
                            [delay]="SEARCH_TEXT_DELAY"
                            [maxLength]="SEARCH_TEXT_MAX_LENGTH"
                            [minNChars]="SEARCH_TEXT_MIN_N_CHARS"
                            [placeholder]="TR_BASE + 'searchPlaceholder'"
                            [searching]="searching"
                            inputId="searchText"
                            styleClass="xs-mb-10 xs-min-width-450">
                    </xs-input-search>
                    <p-selectButton
                            (onChange)="search()"
                            [(ngModel)]="statusFilter"
                            [multiple]="true"
                            [options]="statusFilterItems"
                            styleClass="xs-select-button xs-select-button-small xs-min-width-350">
                    </p-selectButton>
                </div>

                <div class="xs-flex xs-justify-content-space-between xs-position-relative xs-align-item-center xs-border-bottom-discrete xs-plr-10 xs-pb-5">
                    <div class="xs-flex xs-align-item-center xs-width-175">
                        <div class="xs-position-absolute xs-pl-10">
                            <xs-loader #orderNumberLoader [loaderId]="ORDER_NUMBER_LOADER_ID" [loaderSize]="15" [loaderType]="loaderType"></xs-loader>
                        </div>

                        <span *ngIf="!orderNumberLoader.isRunning()" class="xs-display-block xs-color-secondary">
                            {{ TR_BASE + 'caption' | translate : {nResults: totalResults} }}
                        </span>
                    </div>

                    <div class="xs-flex xs-align-item-center">
                        <xs-icon [disabled]="true" [value]="ICON.setting" color="secondary" styleClass="xs-clickable xs-mr-40"></xs-icon>
                        <xs-icon (clickEvent)="showMap = !showMap" [value]="ICON.map" size="15px" styleClass="{{ showMap ? 'xs-color-primary-imp' : 'xs-color-secondar-imp' }}"></xs-icon>
                    </div>
                </div>

                <div class="xs-flex-row-center xs-position-relative xs-mlr-10 xs-mt-20 xs-mb-30 xs-min-height-300">
                    <div class="xs-absolute-center-vh">
                        <xs-loader #searchLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
                    </div>
                    <div *ngIf="hasError() && !searchLoader.isRunning()" class="xs-absolute-center-vh xs-width-full">
                        <xs-error
                                [actionButton]="errorRetryButton"
                                [error]="error"
                                [showActionButton]="true"
                                [showErrorDetailButton]="true"
                                message="xs.core.label.httpErrorMessage"
                                mode="block"
                                subMessage="xs.core.error.contactAdminMessage">
                        </xs-error>
                    </div>
                    <ul *ngIf="!hasError() && !searchLoader.isRunning() && deliveryMen | xsIsNotEmpty" class="xs-listing xs-listing-separator xs-width-full xs-overflow-y-scroll xs-max-height-300">
                        <li
                                (click)="onSelect(deliveryMan)"
                                *ngFor="let deliveryMan of deliveryMen"
                                [ngClass]="{ 'lce-background-items-selected xs-border-radius-default': isSelected(deliveryMan) }"
                                class="xs-listing-item xs-pt-5-imp xs-pb-10-imp xs-mlr-10-imp xs-cursor-pointer">
                            <lce-delivery-man-assignment [data]="deliveryMan" [showRefreshButton]="false"></lce-delivery-man-assignment>
                        </li>
                        <li *ngIf="deliveryMen | xsIsNotEmpty" class="xs-background-none-imp">
                            <xs-paginator-load-more
                                    (errorEvent)="handlePaginationError($event)"
                                    (loadEvent)="handlePaginationLoad($event)"
                                    [options]="paginationOptions"
                                    [paginationPage]="pagination.page"
                                    [paginationSize]="pagination.size"
                                    [total]="totalResults"
                                    styleClass="xs-mt-5 xs-flex-row-center">
                            </xs-paginator-load-more>
                        </li>
                    </ul>

                    <div *ngIf="!hasError() && !searchLoader.isRunning() && deliveryMen | xsIsEmpty" class="xs-flex-column-center xs-width-full">
                        <xs-no-result-found iconStyleClass="xs-font-size-35-imp"></xs-no-result-found>
                    </div>
                </div>
            </div>
            <div *ngIf="showMap" [ngClass]="{ 'xs-border-top-discrete': mapOnBottom, ' xs-flex xs-justify-content-center xs-align-item-center col-7': mapOnRight }" class="xs-p-10">
                <div class="xs-flex-column-center xs-min-height-300">
                    <xs-icon [value]="ICON.map" color="discrete" size="150px"></xs-icon>
                </div>
            </div>
        </div>
    </div>
</div>
