import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {LCECoreModule} from '@lce/core';
import {XSBaseModule} from '@xs/base';
import {XSCommonModule} from '@xs/common';
import {XSCoreModule} from '@xs/core';
import {XSPaymentCheckoutModule} from '@xs/payment/checkout';
import {XSPaymentCoreModule} from '@xs/payment/core';
import {ChartModule} from 'primeng/chart';
import {SkeletonModule} from 'primeng/skeleton';
import {LCECategoryCreateUpdateComponent} from './article/categories/create-update/lce-category-create-update.component';
import {LCECategoryDataManagerComponent} from './article/categories/data-manager/lce-category-data-manager.component';
import {LCEArticleCategoriesComponent} from './article/categories/lce-article-categories.component';
import {LCECategoryPartialComponent} from './article/categories/partial/lce-article-category-partial.component';
import {LCECategoryRecordComponent} from './article/categories/record/lce-category-record.component';
import {LCEArticleReviewersComponent} from './article/reviewers/lce-article-reviewers.component';
import {LCEArticleTagsComponent} from './article/tags/lce-article-tags.component';
import {LCEAuthenticationLoginAttemptsComponent} from './authentification/login-attempt/lce-authentication-login-attempts.component';
import {LCEAuthenticationLoginAttemptRowComponent} from './authentification/login-attempt/row/lce-authentication-login-attempt-row.component';
import {LCEAuthenticationPINCodeComponent} from './authentification/pin-code/lce-authentication-pin-code.component';
import {LCEBirthDeclarationCreateUpdateComponent} from './birth-declaration/create-update/lce-birth-declaration-create-update.component';
import {LCEBirthDeclarationDeliveryFormComponent} from './birth-declaration/delivery/create-update/birth-declaration-delivery-create-update.component';
import {LCEBirthDeclarationDeliveryComponent} from './birth-declaration/delivery/lce-birth-declaration-delivery.component';
import {LCEBirthDeclarationsComponent} from './birth-declaration/lce-birth-declarations.component';
import {LCEBirthDeclarationParentFormComponent} from './birth-declaration/parent/create-update/lce-birth-declaration-parent-create-update.component';
import {LCEBirthDeclarationParentComponent} from './birth-declaration/parent/lce-birth-declaration-parent.component';
import {LCEBirthDeclarationPartialComponent} from './birth-declaration/partial/lce-birth-declaration-partial.component';
import {LCEBirthDeclarationRecordComponent} from './birth-declaration/record/lce-birth-declaration-record.component';
import {LCECertificateOrderBatchProcessesStateComponent} from './certificate-order-batch-process/lce-certificate-order-batch-processes-state.component';
import {LCECertificateOrderBatchProcessesComponent} from './certificate-order-batch-process/lce-certificate-order-batch-processes.component';
import {LCECertificateOrderBatchProcessPartialComponent} from './certificate-order-batch-process/partial/lce-certificate-order-batch-process-partial.component';
import {LCECertificateOrderBatchProcessRecordComponent} from './certificate-order-batch-process/record/lce-certificate-order-batch-process-record.component';
import {LCECertificateOrderActionsComponent} from './certificate-order/actions/lce-certificate-order-actions.component';
import {LCECertificateOrderDeliveryActionsComponent} from './certificate-order/actions/lce-certificate-order-delivery-actions.component';
import {LCECertificateOrdersAdvancedSearchComponent} from './certificate-order/advanced-search/lce-certificate-orders-advanced-search.component';
import {LCECertificateOrderCreateCertificateComponent} from './certificate-order/create/certificate/lce-certificate-order-create-certificate.component';
import {LCECertificateOrderCreateCustomerComponent} from './certificate-order/create/customer/lce-certificiate-order-create-customer.component';
import {LCECertificateOrderCreateDeliveryComponent} from './certificate-order/create/delivery/lce-certificate-order-create-delivery.component';
import {LCECertificateOrderCreateComponent} from './certificate-order/create/lce-certificate-order-create.component';
import {LCECertificateOrderCreateProcurationComponent} from './certificate-order/create/procuration/lce-certificate-order-create-procuration.component';
import {LCECertificateOrderDataBoxesComponent} from './certificate-order/data-boxes/lce-certificate-order-data-boxes.component';
import {LCECertificateOrderEventsComponent} from './certificate-order/event-audit/lce-certificate-order-events.component';
import {LCECertificateOrdersFeatureSettingsComponent} from './certificate-order/feature-settings/lce-certificate-orders-feature-settings.component';
import {LCECertificateOrderLastComponent} from './certificate-order/last/lce-certificate-order-last.component';
import {LCECertificateOrderNumberOfCopiesComponent} from './certificate-order/lce-certificate-orders-number-of-copies.component';
import {LCECertificateOrdersComponent} from './certificate-order/lce-certificate-orders.component';
import {LCECertificateOrderManualDispatcher} from './certificate-order/manual-dispatcher/lce-certificate-order-manual-dispatcher.component';
import {LCECertificateOrderPartialComponent} from './certificate-order/partial/lce-certificate-order-partial.component';
import {LCECertificateOrdersPendingOrdersIndicatorComponent} from './certificate-order/pending-orders/lce-certificate-orders-pending-orders-indicator.component';
import {LCECertificateOrdersPendingOrdersSquareComponent} from './certificate-order/pending-orders/lce-certificate-orders-pending-orders-square.component';
import {LCECertificateOrdersPendingOrdersComponent} from './certificate-order/pending-orders/lce-certificate-orders-pending-orders.component';
import {LCECertificateOrderPriceDetailsComponent} from './certificate-order/price-details/lce-certificate-order-price-details.component';
import {LCECertificateOrderPriceEstimatorComponent} from './certificate-order/price-estimator/lce-certificate-order-price-estimator.component';
import {LCECertificateOrderProcessCheckoutComponent} from './certificate-order/process/checkout/lce-certificate-order-process-checkout.component';
import {LCECertificateOrderProcessConfirmationComponent} from './certificate-order/process/confirmation/lce-certificate-order-process-confirmation.component';
import {LCECertificateOrderProcessComponent} from './certificate-order/process/lce-certificate-order-process.component';
import {LCECertificateOrderProcessPendingRequestComponent} from './certificate-order/process/pending-request/lce-certificate-order-process-pending-request.component';
import {LCECertificateOrderDeliveryComponent} from './certificate-order/record/delivery/lce-certificate-order-delivery.component';
import {LCECertificateOrderDetailComponent} from './certificate-order/record/general/detail/lce-certificate-order-detail.component';
import {LCECertificateOrderGeneralComponent} from './certificate-order/record/general/lce-certificate-order-general.component';
import {LCECertificateOrderHeadComponent} from './certificate-order/record/head/lce-certificate-order-head.component';
import {LCECertificateOrderRecordComponent} from './certificate-order/record/lce-certificate-order-record.component';
import {LCECertificateOrderPaymentComponent} from './certificate-order/record/payment/lce-certificate-order-payment.component';
import {LCECertificateOrderProcurationComponent} from './certificate-order/record/procuration/lce-certificate-order-procuration.component';
import {LCECertificateOrderTimelineComponent} from './certificate-order/record/timeline/lce-certificate-order-timeline.component';
import {LCECertificateOrderRowComponent} from './certificate-order/row/lce-certificate-order-row.component';
import {LCECertificateOrderLetterStatComponent} from './certificate-order/stats/lce-certificate-order-letter-stat.component';
import {LCECertificateOrderSimpleStatsComponent} from './certificate-order/stats/lce-certificate-order-simple-stats.component';
import {LCECertificateOrderStatsPaymentComponent} from './certificate-order/stats/lce-certificate-order-stats-payment.component';
import {LCECertificateOrderStatusComponent} from './certificate-order/status/lce-certificate-order-status.component';
import {LCECertificateOrdersTableComponent} from './certificate-order/table/lce-certificate-orders-table.component';
import {LCEUseMobileVersionComponent} from './certificate-order/use-mobile-version/lce-use-mobile-version.component';
import {LCECertificateOrderWidgetComponent} from './certificate-order/widget/lce-certificate-order-widget.component';
import {LCEDeliveryManOrderWidgetComponent} from './certificate-order/widget/man/lce-delivery-man-order-widget.component';
import {LCECertificateAvatarComponent} from './certificate/avatar/lce-certificate-avatar.component';
import {LCECertificateRecordComponent} from './certificate/lce-certificate.component';
import {LCECertificatePartialComponent} from './certificate/partial/lce-certificate-partial.component';
import {LCEWebSocketConnectionStatusComponent} from './connection-status/lce-websocket-connection-status.component';
import {LCEDashboardCertificateOrderChartDoughnutComponent} from './dashboard/certificate-order-chart-doughnut/lce-dashboard-certificate-order-chart-doughnut.component';
import {LCEDashboardCertificateOrderChartLineComponent} from './dashboard/certificate-order-chart-line/lce-dashboard-certificate-order-chart-line.component';
import {LCEDashboardCertificateOrderStatsComponent} from './dashboard/certificate-order-stats/lce-dashboard-certificate-order-stats.component';
import {LCEDashboardNewsLastArticleComponent} from './dashboard/news-last-article/lce-dashboard-news-last-article.component';
import {LCEDashboardSmartCityLastArticleComponent} from './dashboard/smartcity-last-article/lce-dashboard-smartcity-last-article.component';
import {LCEDashboardStatsGlobalComponent} from './dashboard/stats-global/lce-dashboard-stats-global.component';
import {LCEDeliveryCompanyCreateUpdateComponent} from './delivery/company/create-update/lce-delivery-company-create-update.component';
import {LCEDeliveryCompaniesComponent} from './delivery/company/lce-delivery-companies.component';
import {LCEDeliveryCompanyPartialComponent} from './delivery/company/partial/lce-delivery-company-partial.component';
import {LCEDeliveryCompanyRecordComponent} from './delivery/company/record/lce-delivery-company-record.component';
import {LCEDeliveryCompanyRegisterStatusComponent} from './delivery/company/register-status/lce-delivery-company-register-status.component';
import {LCEDeliveryDestinationTypeComponent} from './delivery/destination/lce-delivery-destination-type.component';
import {LCEDeliveryDestinationComponent} from './delivery/destination/lce-delivery-destination.component';
import {LCEInputFieldDistrictAutocompleteMultipleComponent} from './district/autocomplete/lce-input-field-district-autocomplete-multiple.component';
import {LCEInputFieldDistrictAutocompleteComponent} from './district/autocomplete/lce-input-field-district-autocomplete.component';
import {LCEDistrictCreateUpdateComponent} from './district/create-update/lce-district-create-update.component';
import {LCEDataFieldDistrictComponent} from './district/data-field/lce-data-field-district.component';
import {LCEDistrictsComponent} from './district/lce-districts.component';
import {LCEDistrictPartialComponent} from './district/partial/lce-district-partial.component';
import {LCEDistrictRecordComponent} from './district/record/lce-district-record.component';
import {LCEDownloadAppButtonsComponent} from './download-buttons/lce-download-app-buttons.component';
import {LCEEventDataRowComponent} from './event/data/data-row/lce-event-data-row.component';
import {LCEEventDataComponent} from './event/data/lce-event-data.component';
import {LCEEventNameTypeComponent} from './event/event-name/lce-event-name-type.component';
import {LCEEventFiltersComponent} from './event/filters/lce-event-filters.component';
import {LCEEventHeaderComponent} from './event/header/lce-event-header.component';
import {LCEEventsComponent} from './event/lce-events.component';
import {LCEEventRecordComponent} from './event/record/lce-event-record.component';
import {LCEEventResourceComponent} from './event/resource/lce-event-resource.component';
import {LCEEventsTableComponent} from './event/table/lce-events-table.component';
import {LCEFacilityTownHallRegistrationCreateComponent} from './facility-town-hall-registration/create/lce-facility-town-hall-registration-create.component';
import {LCEFacilityTownHallRegistrationRecordComponent} from './facility-town-hall-registration/record/lce-facility-town-hall-registration-record.component';
import {LCEFacilityTownHallStampLoadCreateComponent} from './facility-town-hall-stamp/load/create/lce-facility-town-hall-stamp-load-create.component';
import {LCEFacilityTownHallStampLoadLastComponent} from './facility-town-hall-stamp/load/last/lce-facility-town-hall-stamp-load-last.component';
import {LCEFacilityTownHallStampLoadsComponent} from './facility-town-hall-stamp/load/lce-facility-town-hall-stamp-loads.component';
import {LCEFacilityTownHallStampLoadPartialComponent} from './facility-town-hall-stamp/load/partial/lce-facility-town-hall-stamp-load-partial.component';
import {LCEFacilityTownHallStampLoadRecordComponent} from './facility-town-hall-stamp/load/record/lce-facility-town-hall-stamp-load-record.component';
import {LCEFacilityTownHallStampTokenActionsComponent} from './facility-town-hall-stamp/token/actions/lce-facility-town-hall-stamp-token-actions.component';
import {LCEFacilityTownHallStampTokenConsumptionComponent} from './facility-town-hall-stamp/token/consumption/lce-facility-town-hall-stamp-token-consumption.component';
import {LCEFacilityTownHallStampTokenCreateComponent} from './facility-town-hall-stamp/token/create/lce-facility-town-hall-stamp-token-create.component';
import {LCEFacilityTownHallStampTokenGeneratorComponent} from './facility-town-hall-stamp/token/generator/lce-facility-town-hall-stamp-token-generator.component';
import {LCEFacilityTownHallStampTokenHistoryGlobalComponent} from './facility-town-hall-stamp/token/history/lce-facility-town-hall-stamp-token-history-global.component';
import {LCEFacilityTownHallStampTokenHistoryUserComponent} from './facility-town-hall-stamp/token/history/lce-facility-town-hall-stamp-token-history-user.component';
import {LCEFacilityTownHallStampTokenDeleteLastComponent} from './facility-town-hall-stamp/token/manager/lce-facility-town-hall-stamp-token-delete-last.component';
import {LCEFacilityTownHallStampTokenManagerComponent} from './facility-town-hall-stamp/token/manager/lce-facility-town-hall-stamp-token-manager.component';
import {LCEFacilityTownHallStampTokenSettingsComponent} from './facility-town-hall-stamp/token/manager/lce-facility-town-hall-stamp-token-settings.component';
import {LCEFacilityTownHallStampTokenQRCodeComponent} from './facility-town-hall-stamp/token/qrcode/lce-facility-town-hall-stamp-token-qrcode.component';
import {LCEFacilityTownHallStampTokenRecordComponent} from './facility-town-hall-stamp/token/record/lce-facility-town-hall-stamp-token-record.component';
import {LCEFacilityTownHallStampRemainingComponent} from './facility-town-hall-stamp/token/remaining/lce-facility-townhall-stamp-remaining.component';
import {LCEFacilityTownHallStampTokenStatusComponent} from './facility-town-hall-stamp/token/status/lce-facility-town-hall-stamp-token-status.component';
import {LceFacilityTownHallStampTokenTableComponent} from './facility-town-hall-stamp/token/table/lce-facility-town-hall-stamp-token-table.component';
import {LCEFacilityTownHallPaymentLastTransferComponent} from './facility-town-hall-transfer/lce-facility-town-hall-payment-last-transfer.component';
import {LCEFacilityTownHallPaymentTransferComponent} from './facility-town-hall-transfer/lce-facility-town-hall-payment-transfer.component';
import {LCEFacilityTownHallPaymentTransferPartialComponent} from './facility-town-hall-transfer/partial/lce-facility-town-hall-payment-transfer-partial.component';
import {LCEFacilityWorkerCreateUpdateComponent} from './facility-worker/create-update/lce-facility-worker-create-update.component';
import {LCEFacilityWorkerDetailHeadComponent} from './facility-worker/detail/head/lce-facility-worker-detail-head.component';
import {LCEFacilityWorkerDetailComponent} from './facility-worker/detail/lce-facility-worker-detail.component';
import {LCEFacilityWorkersComponent} from './facility-worker/lce-facility-workers.component';
import {LCEFacilityWorkerPartialComponent} from './facility-worker/partial/lce-facility-worker-partial.component';
import {LCEFacilityWorkerRecordComponent} from './facility-worker/record/lce-facility-worker-record.component';
import {LCEInputFieldFacilityAutocompleteMultipleComponent} from './facility/autocomplete/lce-input-field-facility-autocomplete-multiple.component';
import {LCEInputFieldFacilityAutocompleteComponent} from './facility/autocomplete/lce-input-field-facility-autocomplete.component';
import {LCEFacilityCreateUpdateComponent} from './facility/create-update/lce-facility-create-update.component';
import {LCEDataFieldFacilityComponent} from './facility/data-field/lce-data-field-facility.component';
import {LCEFacilitiesComponent} from './facility/lce-facilities.component';
import {LCEFacilityPartialOverlayComponent} from './facility/partial-overlay/lce-facility-partial-overlay.component';
import {LCEFacilityPartialComponent} from './facility/partial/lce-facilty-partial.component';
import {LCEFacilityRecordComponent} from './facility/record/lce-facility-record.component';
import {LCEGlobalSearchInputResultComponent} from './global-search/input/lce-global-search-input-result.component';
import {LCEGlobalSearchInputComponent} from './global-search/input/lce-global-search-input.component';
import {LCEGlobalSearchComponent} from './global-search/lce-global-search.component';
import {LCEGlobalSearchBirthDeclarationsTabPanelComponent} from './global-search/tab-panel/birth-declaration/lce-global-search-birth-declarations-tab-panel.component';
import {LCEGlobalSearchCertificateOrdersTabPanelComponent} from './global-search/tab-panel/certificate-order/lce-global-search-certificate-orders-tab-panel.component';
import {LCEGlobalSearchDistrictsTabPanelComponent} from './global-search/tab-panel/district/lce-global-search-districts-tab-panel.component';
import {LCEGlobalSearchFacilitiesTabPanelComponent} from './global-search/tab-panel/facility/lce-global-search-facilities-tab-panel.component';
import {LCEGlobalSearchTabPanelComponent} from './global-search/tab-panel/lce-global-search-tab-panel.component';
import {LCEGlobalSearchMunicipalitiesTabPanelComponent} from './global-search/tab-panel/municipality/lce-global-search-municipalities-tab-panel.component';
import {LCEGlobalSearchNewsTabPanelComponent} from './global-search/tab-panel/news/lce-global-search-news-tab-panel.component';
import {LCEGlobalSearchServicePointsTabPanelComponent} from './global-search/tab-panel/service-point/lce-global-search-service-points-tab-panel.component';
import {LCEGlobalSearchSmartCityTabPanelComponent} from './global-search/tab-panel/smartcity/lce-global-search-smartcity-tab-panel.component';
import {LCERecordHeaderComponent} from './header/lce-record-header.component';
import {LCELogoComponent} from './logo/lce-logo.component';
import {LCEMunicipalitySettingsComponent} from './municipality-settings/lce-municipality-settings.component';
import {LCEInputFieldMunicipalityAutocompleteMultipleComponent} from './municipality/autocomplete/lce-input-field-municipality-autocomplete-multiple.component';
import {LCEInputFieldMunicipalityAutocompleteComponent} from './municipality/autocomplete/lce-input-field-municipality-autocomplete.component';
import {LCEMunicipalityCreateUpdateComponent} from './municipality/create-update/lce-municipality-create-update.component';
import {LCEDataFieldMunicipalityComponent} from './municipality/data-field/lce-data-field-municipality.component';
import {LCEMunicipalitiesComponent} from './municipality/lce-municipalities.component';
import {LCEMunicipalityPartialOverlayComponent} from './municipality/partial-overlay/lce-municipality-partial-overlay.component';
import {LCEMunicipalityPartialComponent} from './municipality/partial/lce-municipality-partial.component';
import {LCEMunicipalityPartialsComponent} from './municipality/partial/lce-municipality-partials.component';
import {LCEMunicipalityRecordComponent} from './municipality/record/lce-municipality-record.component';
import {LCENewsArticleCardWebComponent} from './news/article-card/lce-news-article-card-web.compsonent';
import {LCENewsArticleCardComponent} from './news/article-card/lce-news-article-card.component';
import {LCENewsArticleCommentComponent} from './news/article-comment/lce-news-article-comment.component';
import {LCEArticleCommentsComponent} from './news/article-comments/lce-news-article-comments.component';
import {LCENewsArticleCreateUpdateComponent} from './news/article-create-update/lce-news-article-create-update.component';
import {LCENewsArticleDetailComponent} from './news/article-detail/lce-news-article-detail.component';
import {LCENewsArticlePartialOverlay} from './news/article-partial-overlay/lce-news-article-partial-overlay';
import {LCENewsArticlePartialComponent} from './news/article-partial/lce-news-article-partial.component';
import {LCEArticleStatsIconsComponent} from './news/article-stats-icons/lce-news-article-stats-icons.component';
import {LCENewsArticleUserCommentComponent} from './news/article-user-comment/lce-news-article-user-comment.component';
import {LCENewsArticleViewComponent} from './news/article-view/lce-news-article-view.component';
import {LCENewsArticlesWebComponent} from './news/articles/lce-news-articles-web.component';
import {LCENewsArticlesComponent} from './news/articles/lce-news-articles.component';
import {LCENotificationsComponent} from './notifications/lce-notifications.component';
import {LCENotificationComponent} from './notifications/notification/lce-notification.component';
import {LCENumberOfDocumentComponent} from './number-of-document/lce-number-of-document.component';
import {LCEInputFieldServicePointAutocompleteMultipleComponent} from './service-point/autocomplete/lce-input-field-service-point-autocomplete-multiple.component';
import {LCEInputFieldServicePointAutocompleteComponent} from './service-point/autocomplete/lce-input-field-service-point-autocomplete.component';
import {LCEServicePointCreateUpdateComponent} from './service-point/create-update/lce-service-point-create-update.component';
import {LCEDataFieldServicePointComponent} from './service-point/data-field/lce-data-field-service-point.component';
import {LCEServicePointsComponent} from './service-point/lce-service-points.component';
import {LCEServicePointPartialOverlayComponent} from './service-point/partial-overlay/lce-service-point-partial-overlay.component';
import {LCEServicePointPartialComponent} from './service-point/partial/lce-service-point-partial.component';
import {LCEServicePointRecordComponent} from './service-point/record/lce-service-point-record.component';
import {LCESmartCityArticleCardComponent} from './smartcity/article-card/lce-smartcity-article-card.component';
import {LCESmartCityCreateUpdateComponent} from './smartcity/article-create-update/lce-smartcity-article-create-update.component';
import {LCESmartCityArticleDetailComponent} from './smartcity/article-detail/lce-smartcity-article-detail.component';
import {LCESmartCityArticlePartialOverlay} from './smartcity/article-partial-overlay/lce-smartcity-article-partial-overlay';
import {LCESmartCityArticlePartialComponent} from './smartcity/article-partial/lce-smartcity-article-partial.component';
import {LCESmartCityStatsIconsComponent} from './smartcity/article-stats-icons/lce-smartcity-article-stats-icons.component';
import {LCESmartCityArticleTypeComponent} from './smartcity/article-type/lce-smartcity-article-type.component';
import {LCESmartCityArticleViewWebComponent} from './smartcity/article-view/lce-smartcity-article-view-web.component';
import {LCESmartCityArticleViewComponent} from './smartcity/article-view/lce-smartcity-article-view.component';
import {LCESmartCityArticlesWebComponent} from './smartcity/articles/lce-smartcity-articles-web.component';
import {LCESmartCityArticlesComponent} from './smartcity/articles/lce-smartcity-articles.component';
import {LCEStatisticBoxComponent} from './statistic/lce-statistic-box.component';
import {LCEStatisticVariationComponent} from './statistic/variation/lce-statistic-variation.component';
import {LCESuggestionActionsComponent} from './suggestion/actions/lce-suggestion-actions.component';
import {LCESuggestionLastComponent} from './suggestion/last/lce-suggestion-last.component';
import {LCESuggestionLateralPanelComponent} from './suggestion/lateral-panel/lce-suggestion-lateral-panel.component';
import {LCESuggestionsComponent} from './suggestion/lce-suggestions.component';
import {LCESuggestionPartialComponent} from './suggestion/partial/lce-suggestion-partial.component';
import {LCESuggestionRecordComponent} from './suggestion/record/lce-suggestion-record.component';
import {LCESuggestionsSettingsComponent} from './suggestion/settings/lce-suggestions-settings.component';
import {LCESuggestionStatusComponent} from './suggestion/status/lce-suggestion-status.component';
import {LCEUserAccountAuthenticationComponent} from './user/account/authentication/lce-user-account-authentication.component';
import {LCEUserAccountContactCreateUpdateComponent} from './user/account/create-update/contact/lce-user-account-contact-create-update.component';
import {LCEUserAccountCreateUpdateComponent} from './user/account/create-update/lce-user-account-create-update.component';
import {LCEUserAccountPersonalInformationCreateUpdateComponent} from './user/account/create-update/personal-information/lce-user-account-personnal-information-create-update.component';
import {LCEUserAccountComponent} from './user/account/lce-user-account.component';
import {LCEUserAccountProfileComponent} from './user/account/profile/lce-user-account-profile.component';
import {LCEUserAccountSettingsComponent} from './user/account/settings/lce-user-account-settings.component';
import {LCEUserAccountStatisticsComponent} from './user/account/statistics/lce-user-account-statistics.component';
import {LCEDataFieldUserResourceAuditInlineComponent} from './user/common/audit/lce-data-field-user-resource-audit-inline.component';
import {LCEUserResourceAuditInlineComponent} from './user/common/audit/lce-user-resource-audit-inline.component';
import {LCEUserResourceAuditComponent} from './user/common/audit/lce-user-resource-audit.component';
import {LCEInputFieldUserAutoCompleteComponent} from './user/common/autocomplete/lce-input-field-user-autocomplete';
import {LCEInputFieldUserAutoCompleteComponentMultiple} from './user/common/autocomplete/lce-input-field-user-autocomplete-multiple';
import {LCEUserDetailHeadComponent} from './user/common/detail/lce-user-detail-head.component';
import {LCEUserDetailComponent} from './user/common/detail/lce-user-detail.component';
import {LCEUserIvarComponent} from './user/common/ivar/lce-user-ivar.component';
import {LCEUserAvatarComponent} from './user/common/partial/lce-user-avatar.component';
import {LCEUserPartialComponent} from './user/common/partial/lce-user-partial.component';
import {LCEUserStatusComponent} from './user/common/status/lce-user-status.component';
import {LCECustomerActionsComponent} from './user/customer/actions/lce-customer.actions-component';
import {LCEUserCustomerAnonymousComponent} from './user/customer/lce-user-customer-anonymous.component';
import {LCEUserCustomersComponent} from './user/customer/lce-user-customers.component';
import {LCEUserCustomerGeneralComponent} from './user/customer/record/general/lce-user-customer-general.component';
import {LCEUserCustomerRecordComponent} from './user/customer/record/lce-user-customer-record.component';
import {LCEUserCustomerOrdersComponent} from './user/customer/record/orders/lce-user-customer-orders.component';
import {LCEUserCustomerTimelineComponent} from './user/customer/record/timeline/lce-user-customer-timeline.component';
import {LCEUserCustomerUsageComponent} from './user/customer/record/usage/lce-user-user-customer-usage.component';
import {LCEUserCustomerStatisticsComponent} from './user/customer/statistic/lce-user-customer-statistics.component';
import {LCEUserCustomersTableComponent} from './user/customer/table/lce-user-customers-table.component';
import {LCEDeliveryMenWidgetRowResultComponent} from './user/delivery-man/widget/lce-delivery-men-widget-row-result.component';
import {LCEDeliveryMenWidgetComponent} from './user/delivery-man/widget/lce-delivery-men-widget.component';
import {LCEMunicipalEmployeeActionsComponent} from './user/municipal-employee/actions/lce-user-municipal-employee-actions.component';
import {LCEMunicipalEmployeeCreateUpdateComponent} from './user/municipal-employee/create-update/lce-user-municipal-employee-create-update.component';
import {LCEUserMunicipalEmployeeRecordAuthenticationComponent} from './user/municipal-employee/record/authentication/lce-user-municipal-employee-record-authentication.component';
import {LCEUserMunicipalEmployeeRecordAuthorizationComponent} from './user/municipal-employee/record/authorization/lce-user-municipal-employee-record-authorization.component';
import {LCEUserMunicipalEmployeeRecordGeneralComponent} from './user/municipal-employee/record/general/lce-user-municipal-employee-record-general.component';
import {LCEUserMunicipalEmployeeRecordComponent} from './user/municipal-employee/record/lce-user-municipal-employee-record.component';
import {LCEUserMunicipalEmployeeRecordSettingsComponent} from './user/municipal-employee/record/settings/lce-user-municipal-employee-record-settings.component';
import {LCEUserMunicipalEmployeeRecordStatisticsComponent} from './user/municipal-employee/record/statistics/lce-user-municipal-employee-record-statistics.component';
import {XSAuthenticationPINCodeComponent} from './xs/authentification-pin-code/xs-authentication-pin-code.component';
import {XSFeatureItemComponent} from './xs/feature-item/xs-feature-item.component';
import {LCEBrandComponent} from './brand/lce-brand.component';
import {LceCertificateOrderProcessMFOComponent} from './certificate-order/process/mfo/lce-certificate-order-process-mfo.component';
import {LCECertificateOrderProcessConfirmationMFOComponent} from './certificate-order/process/mfo/confirmation/lce-certificate-order-process-confirmation-mfo.component';
import {LCECertificateOrdersPriceSettingsComponent} from './certificate-order/price-settings/lce-certificate-orders-price-settings.component';
import {LCEFacilityTownHallStampTokenNumberComponent} from './facility-town-hall-stamp/token/number/lce-facility-townhall-stamp-token-number.component';
import {LCETerminalPartialComponent} from './terminal/partial/lce-terminal-partial.component';
import {LCETerminalRecordComponent} from './terminal/record/lce-terminal-record.component';
import {LCETerminalsComponent} from './terminal/lce-terminals.component';
import {LCETerminalCreateUpdateComponent} from './terminal/create-update/lce-terminal-create-update.component';
import {LCEDeliveryManPartialComponent} from './user/delivery-man/partial/lce-delivery-man-partial.component';
import {LCEInputFieldDeliveryManAutocompleteComponent} from './user/delivery-man/autocomplete/lce-delivery-men-autocomplete.component';
import {LCEDeliveryRecordComponent} from './delivery/record/lce-delivey-record.component';
import {LCEDeliveryGeneralComponent} from './delivery/record/general/lce-delivery-general.component';
import {LCEDeliveryAssignmentStatusComponent} from './delivery/assignment/status/lce-delivery-assignment-status.component';
import {LCEDeliveryAssignmentRequestComponent} from './delivery/assignment/request/assignment/lce-delivery-assignment-request.component';
import {LCEDeliveryAssignmentComponent} from './delivery/assignment/lce-delivery-assignment.component';
import {LCEDeliveryManAssignmentComponent} from './delivery/assignment/delivery-man/lce-delivery-man-assignment.component';
import {LCEDeliveryUnassignmentRequestComponent} from './delivery/assignment/request/unassignment/lce-delivery-unassignment-request.component';
import {LCEDeliveryAssignmentRequestOperationComponent} from './delivery/assignment/request/operation/lce-delivery-assignment-request-operation.component';
import {LCEBreadcrumbComponent} from './template/breadcrumb/lce-breadcrumb.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {LCEUserAccountAvatarComponent} from './user/account/avatar/lce-user-account-avatar.component';
import {LCEContextMenuComponent} from './template/context-menu/lce-context-menu.component';
import {XSAuthorizationBaseComponent} from './authorization/base/xs-authorization-base.component';
import {LCEAuthorizationDashBoardComponent} from './authorization/dashboard/lce-authorization-dashboard.component';
import {LCEAuthorizationSearchComponent} from './authorization/search/lce-authorization-search.component';
import {LCEAuthorizationComponent} from './authorization/lce-authorization.component';
import {LCEAuthorizationTesterComponent} from './authorization/tester/lce-authorization-tester.component';
import {LCEFacilitySelectComponent} from './facility/select/lce-facility-select.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        // --- * ---
        XSBaseModule,
        XSCommonModule,
        XSCoreModule,
        LCECoreModule,
        XSPaymentCoreModule,
        XSPaymentCheckoutModule,
        SkeletonModule,
        ChartModule,
        BreadcrumbModule
    ],
    declarations: [
        // --- *** Move to XS Core Lib *** ---
        XSAuthenticationPINCodeComponent,
        XSFeatureItemComponent,
        // XSInputMapLocationComponent,
        // ---Download App Buttons ---
        LCEDownloadAppButtonsComponent,
        // --- Shared Header ---
        LCERecordHeaderComponent,
        // --- Logo ---
        LCELogoComponent,
        // --- Authentication ---
        LCEAuthenticationPINCodeComponent,
        LCEAuthenticationLoginAttemptRowComponent,
        LCEAuthenticationLoginAttemptsComponent,
        // --- Template ---
        LCEContextMenuComponent,
        // --- Brand ---
        LCEBrandComponent,
        // --- Breadcrumb ---
        LCEBreadcrumbComponent,
        // --- Terminal ---
        LCETerminalPartialComponent,
        LCETerminalRecordComponent,
        LCETerminalsComponent,
        LCETerminalCreateUpdateComponent,
        // --- Suggestion ---
        LCESuggestionStatusComponent,
        LCESuggestionPartialComponent,
        LCESuggestionRecordComponent,
        LCESuggestionsComponent,
        LCESuggestionsSettingsComponent,
        LCESuggestionActionsComponent,
        LCESuggestionLateralPanelComponent,
        LCESuggestionLastComponent,
        // --- Notification ---
        LCENotificationComponent,
        LCENotificationsComponent,
        // --- Article ---
        LCECategoryRecordComponent,
        LCECategoryPartialComponent,
        LCECategoryCreateUpdateComponent,
        LCEArticleCategoriesComponent,
        LCECategoryDataManagerComponent,
        LCEArticleReviewersComponent,
        LCEArticleTagsComponent,
        // --- News ---
        LCENewsArticlePartialComponent,
        LCENewsArticleCardComponent,
        LCENewsArticleCardWebComponent,
        LCENewsArticleCommentComponent,
        LCENewsArticleCreateUpdateComponent,
        LCEArticleCommentsComponent,
        LCEArticleStatsIconsComponent,
        LCENewsArticleUserCommentComponent,
        LCENewsArticleViewComponent,
        LCENewsArticlesComponent,
        LCENewsArticlesWebComponent,
        LCENewsArticlePartialOverlay,
        LCENewsArticleDetailComponent,
        // --- Smartcity ---
        LCESmartCityArticlePartialComponent,
        LCESmartCityArticleCardComponent,
        LCESmartCityCreateUpdateComponent,
        LCESmartCityStatsIconsComponent,
        LCESmartCityArticleViewComponent,
        LCESmartCityArticlesComponent,
        LCESmartCityArticlesWebComponent,
        LCESmartCityArticlePartialOverlay,
        LCESmartCityArticleDetailComponent,
        LCESmartCityArticleViewWebComponent,
        LCESmartCityArticleTypeComponent,
        //--- Birth Declaration ---
        LCEBirthDeclarationDeliveryComponent,
        LCEBirthDeclarationDeliveryFormComponent,
        LCEBirthDeclarationParentComponent,
        LCEBirthDeclarationParentFormComponent,
        LCEBirthDeclarationCreateUpdateComponent,
        LCEBirthDeclarationPartialComponent,
        LCEBirthDeclarationRecordComponent,
        LCEBirthDeclarationsComponent,
        // --- Certificate Order Batch Process ---
        LCECertificateOrderBatchProcessesComponent,
        LCECertificateOrderBatchProcessRecordComponent,
        LCECertificateOrderBatchProcessPartialComponent,
        LCECertificateOrderBatchProcessesStateComponent,
        // --- Certificate Order ---
        LCECertificateOrderDataBoxesComponent,
        LCECertificateOrderPriceEstimatorComponent,
        LCECertificateOrderEventsComponent,
        LCECertificateOrderPriceDetailsComponent,
        LCECertificateOrderProcessPendingRequestComponent,
        LCECertificateOrderProcessConfirmationComponent,
        LCECertificateOrderProcessConfirmationMFOComponent,
        LCECertificateOrderProcessCheckoutComponent,
        LceCertificateOrderProcessMFOComponent,
        LCECertificateOrderProcessComponent,
        LCECertificateOrderWidgetComponent,
        LCEDeliveryManOrderWidgetComponent,
        LCEDeliveryManPartialComponent,
        LCEInputFieldDeliveryManAutocompleteComponent,
        LCECertificateOrderManualDispatcher,
        LCECertificateOrderStatusComponent,
        LCECertificateOrderPartialComponent,
        LCECertificateOrderLetterStatComponent,
        LCECertificateOrderSimpleStatsComponent,
        LCECertificateOrderActionsComponent,
        LCECertificateOrderDeliveryActionsComponent,
        LCECertificateOrderNumberOfCopiesComponent,
        LCECertificateOrderRowComponent,
        LCECertificateOrderLastComponent,
        LCECertificateOrderDeliveryComponent,
        LCECertificateOrderDetailComponent,
        LCECertificateOrderGeneralComponent,
        LCECertificateOrderHeadComponent,
        LCECertificateOrderTimelineComponent,
        LCECertificateOrderRecordComponent,
        LCECertificateOrdersPendingOrdersIndicatorComponent,
        LCECertificateOrdersPendingOrdersSquareComponent,
        LCECertificateOrdersAdvancedSearchComponent,
        LCECertificateOrdersFeatureSettingsComponent,
        LCECertificateOrdersTableComponent,
        LCECertificateOrdersPendingOrdersComponent,
        LCECertificateOrdersComponent,
        LCECertificateOrderCreateProcurationComponent,
        LCECertificateOrderCreateCertificateComponent,
        LCECertificateOrderCreateCustomerComponent,
        LCECertificateOrderCreateDeliveryComponent,
        LCENumberOfDocumentComponent,
        LCECertificateOrderCreateComponent,
        LCECertificateOrderStatsPaymentComponent,
        LCECertificateOrderProcurationComponent,
        LCECertificateOrderPaymentComponent,
        LCECertificateOrdersPriceSettingsComponent,
        // --- Certificate ---
        LCECertificateAvatarComponent,
        LCECertificatePartialComponent,
        LCECertificateRecordComponent,
        // --- Connection Status ---
        LCEWebSocketConnectionStatusComponent,
        // --- Dashboard -----
        LCEDashboardStatsGlobalComponent,
        LCEDashboardCertificateOrderChartDoughnutComponent,
        LCEDashboardCertificateOrderChartLineComponent,
        LCEDashboardCertificateOrderStatsComponent,
        LCEDashboardNewsLastArticleComponent,
        LCEDashboardSmartCityLastArticleComponent,
        // --- Delivery ---
        LCEDeliveryRecordComponent,
        LCEDeliveryDestinationComponent,
        LCEDeliveryDestinationTypeComponent,
        LCEDeliveryAssignmentComponent,
        LCEDeliveryGeneralComponent,
        LCEDeliveryAssignmentStatusComponent,
        LCEDeliveryAssignmentRequestComponent,
        LCEDeliveryManAssignmentComponent,
        LCEDeliveryUnassignmentRequestComponent,
        LCEDeliveryAssignmentRequestOperationComponent,
        // --- Statistic ---
        LCEStatisticVariationComponent,
        LCEStatisticBoxComponent,
        // --- Service Point ---
        LCEServicePointPartialComponent,
        LCEServicePointPartialOverlayComponent,
        LCEInputFieldServicePointAutocompleteComponent,
        LCEInputFieldServicePointAutocompleteMultipleComponent,
        LCEDataFieldServicePointComponent,
        LCEServicePointCreateUpdateComponent,
        LCEServicePointRecordComponent,
        LCEServicePointsComponent,
        // --- Facility ---
        LCEFacilitySelectComponent,
        LCEFacilityPartialComponent,
        LCEFacilityPartialOverlayComponent,
        LCEInputFieldFacilityAutocompleteComponent,
        LCEInputFieldFacilityAutocompleteMultipleComponent,
        LCEDataFieldFacilityComponent,
        LCEFacilityCreateUpdateComponent,
        LCEFacilityRecordComponent,
        LCEFacilitiesComponent,
        LCEFacilityTownHallPaymentTransferComponent,
        LCEFacilityTownHallPaymentLastTransferComponent,
        LCEFacilityTownHallPaymentTransferPartialComponent,
        //Facility Town Hall Stamp Token
        LCEFacilityTownHallStampTokenNumberComponent,
        LCEFacilityTownHallStampTokenHistoryUserComponent,
        LCEFacilityTownHallStampTokenCreateComponent,
        LCEFacilityTownHallStampTokenGeneratorComponent,
        LCEFacilityTownHallStampTokenSettingsComponent,
        LCEFacilityTownHallStampTokenDeleteLastComponent,
        LCEFacilityTownHallStampTokenManagerComponent,
        LceFacilityTownHallStampTokenTableComponent,
        LCEFacilityTownHallStampTokenActionsComponent,
        LCEFacilityTownHallStampTokenRecordComponent,
        LCEFacilityTownHallStampTokenStatusComponent,
        LCEFacilityTownHallStampTokenQRCodeComponent,
        LCEFacilityTownHallStampTokenConsumptionComponent,
        LCEFacilityTownHallStampTokenHistoryGlobalComponent,
        LCEFacilityTownHallStampRemainingComponent,
        // --- Facility Town Hall Stamp Load ---
        LCEFacilityTownHallStampLoadsComponent,
        LCEFacilityTownHallStampLoadPartialComponent,
        LCEFacilityTownHallStampLoadRecordComponent,
        LCEFacilityTownHallStampLoadCreateComponent,
        LCEFacilityTownHallStampLoadLastComponent,
        // --- Facility Town Hall Registration ---
        LCEFacilityTownHallRegistrationCreateComponent,
        LCEFacilityTownHallRegistrationRecordComponent,
        // --- Municipality Setting ---
        LCEMunicipalitySettingsComponent,
        // --- Municipality ---
        LCEMunicipalityPartialComponent,
        LCEMunicipalityPartialsComponent,
        LCEMunicipalityPartialOverlayComponent,
        LCEInputFieldMunicipalityAutocompleteComponent,
        LCEInputFieldMunicipalityAutocompleteMultipleComponent,
        LCEDataFieldMunicipalityComponent,
        LCEMunicipalityCreateUpdateComponent,
        LCEMunicipalityRecordComponent,
        LCEMunicipalitiesComponent,
        // --- District ---
        LCEDistrictPartialComponent,
        LCEInputFieldDistrictAutocompleteComponent,
        LCEInputFieldDistrictAutocompleteMultipleComponent,
        LCEDataFieldDistrictComponent,
        LCEDistrictCreateUpdateComponent,
        LCEDistrictRecordComponent,
        LCEDistrictsComponent,
        // --- Facility Worker Manager ---
        LCEFacilityWorkerPartialComponent,
        LCEFacilityWorkerRecordComponent,
        LCEFacilityWorkerCreateUpdateComponent,
        LCEFacilityWorkersComponent,
        LCEFacilityWorkerDetailComponent,
        LCEFacilityWorkerDetailHeadComponent,
        // --- User Customer ---
        LCECustomerActionsComponent,
        LCEUserCustomerTimelineComponent,
        LCEUserCustomerGeneralComponent,
        LCEUserCustomerOrdersComponent,
        LCEUserCustomerUsageComponent,
        LCEUserCustomersComponent,
        LCEUserCustomerRecordComponent,
        LCEUserCustomerStatisticsComponent,
        LCEUserCustomersTableComponent,
        LCEUserCustomerAnonymousComponent,
        // --- User Municipal Employee ---
        LCEMunicipalEmployeeActionsComponent,
        LCEMunicipalEmployeeCreateUpdateComponent,
        LCEUserMunicipalEmployeeRecordComponent,
        LCEUserMunicipalEmployeeRecordGeneralComponent,
        LCEUserMunicipalEmployeeRecordAuthenticationComponent,
        LCEUserMunicipalEmployeeRecordAuthorizationComponent,
        LCEUserMunicipalEmployeeRecordSettingsComponent,
        LCEUserMunicipalEmployeeRecordStatisticsComponent,
        // --- User Delivery Man ---
        LCEDeliveryMenWidgetComponent,
        LCEDeliveryMenWidgetRowResultComponent,
        // --- User ---
        LCEUserIvarComponent,
        LCEUserStatusComponent,
        LCEUserAvatarComponent,
        LCEUserPartialComponent,
        LCEUserDetailHeadComponent,
        LCEUserDetailComponent,
        LCEUserResourceAuditComponent,
        LCEUserResourceAuditInlineComponent,
        LCEUseMobileVersionComponent,
        LCEDataFieldUserResourceAuditInlineComponent,
        LCEInputFieldUserAutoCompleteComponent,
        LCEInputFieldUserAutoCompleteComponentMultiple,
        LCEUserAccountComponent,
        LCEUserAccountAvatarComponent,
        LCEUserAccountProfileComponent,
        LCEUserAccountAuthenticationComponent,
        LCEUserAccountContactCreateUpdateComponent,
        LCEUserAccountPersonalInformationCreateUpdateComponent,
        LCEUserAccountCreateUpdateComponent,
        LCEUserAccountSettingsComponent,
        LCEUserAccountStatisticsComponent,
        // --- Event ---
        LCEEventsComponent,
        LCEEventsTableComponent,
        LCEEventRecordComponent,
        LCEEventDataRowComponent,
        LCEEventDataComponent,
        LCEEventNameTypeComponent,
        LCEEventResourceComponent,
        LCEEventHeaderComponent,
        LCEEventFiltersComponent,
        // --- Global Search ---
        LCEGlobalSearchComponent,
        LCEGlobalSearchInputComponent,
        LCEGlobalSearchInputResultComponent,
        LCEGlobalSearchTabPanelComponent,
        LCEGlobalSearchCertificateOrdersTabPanelComponent,
        LCEGlobalSearchBirthDeclarationsTabPanelComponent,
        LCEGlobalSearchDistrictsTabPanelComponent,
        LCEGlobalSearchFacilitiesTabPanelComponent,
        LCEGlobalSearchMunicipalitiesTabPanelComponent,
        LCEGlobalSearchNewsTabPanelComponent,
        LCEGlobalSearchServicePointsTabPanelComponent,
        LCEGlobalSearchSmartCityTabPanelComponent,
        // --- Delivery company ---
        LCEDeliveryCompaniesComponent,
        LCEDeliveryCompanyRecordComponent,
        LCEDeliveryCompanyPartialComponent,
        LCEDeliveryCompanyCreateUpdateComponent,
        LCEDeliveryCompanyRegisterStatusComponent,
        // Authorization
        LCEAuthorizationTesterComponent,
        LCEAuthorizationComponent,
        LCEAuthorizationSearchComponent,
        LCEAuthorizationDashBoardComponent,
        XSAuthorizationBaseComponent
    ],
    exports: [
        LCEDownloadAppButtonsComponent,
        RouterModule,
        // --- * ---
        XSBaseModule,
        XSCommonModule,
        XSCoreModule,
        LCECoreModule,
        SkeletonModule,
        ChartModule,
        BreadcrumbModule,
        // --- *** Move to XS Core Lib *** ---
        XSFeatureItemComponent,
        // --- Logo ---
        LCELogoComponent,
        // --- Shared Header ---
        LCERecordHeaderComponent,
        // --- Authentication ---
        LCEAuthenticationPINCodeComponent,
        LCEAuthenticationLoginAttemptsComponent,
        // --- Template ---
        LCEContextMenuComponent,
        // --- Brand ---
        LCEBrandComponent,
        // --- Breadcrumb ---
        LCEBreadcrumbComponent,
        // --- Terminal ---
        LCETerminalPartialComponent,
        LCETerminalRecordComponent,
        LCETerminalsComponent,
        LCETerminalCreateUpdateComponent,
        // --- Suggestion ---
        LCESuggestionStatusComponent,
        LCESuggestionPartialComponent,
        LCESuggestionRecordComponent,
        LCESuggestionsComponent,
        LCESuggestionsSettingsComponent,
        LCESuggestionLastComponent,
        // --- Notification ---
        LCENotificationComponent,
        LCENotificationsComponent,
        // --- Article ---
        LCECategoryRecordComponent,
        LCECategoryPartialComponent,
        LCECategoryCreateUpdateComponent,
        LCEArticleCategoriesComponent,
        LCEArticleReviewersComponent,
        LCEArticleTagsComponent,
        // --- News ---
        LCENewsArticlePartialComponent,
        LCENewsArticleCardWebComponent,
        LCENewsArticleCommentComponent,
        LCENewsArticleCreateUpdateComponent,
        LCEArticleCommentsComponent,
        LCEArticleStatsIconsComponent,
        LCENewsArticleUserCommentComponent,
        LCENewsArticleViewComponent,
        LCENewsArticlesComponent,
        LCENewsArticlesWebComponent,
        LCENewsArticlePartialOverlay,
        LCENewsArticleDetailComponent,
        // --- Smartcity ---
        LCESmartCityArticlePartialComponent,
        LCESmartCityArticleCardComponent,
        LCESmartCityArticleCardComponent,
        LCESmartCityCreateUpdateComponent,
        LCESmartCityStatsIconsComponent,
        LCESmartCityArticleViewComponent,
        LCESmartCityArticlesComponent,
        LCESmartCityArticlesWebComponent,
        LCESmartCityArticlePartialOverlay,
        LCESmartCityArticleDetailComponent,
        LCESmartCityArticleViewWebComponent,
        LCESmartCityArticleTypeComponent,
        //--- Birth Declaration ---
        LCEBirthDeclarationDeliveryComponent,
        LCEBirthDeclarationDeliveryFormComponent,
        LCEBirthDeclarationParentComponent,
        LCEBirthDeclarationParentFormComponent,
        LCEBirthDeclarationCreateUpdateComponent,
        LCEBirthDeclarationPartialComponent,
        LCEBirthDeclarationRecordComponent,
        LCEBirthDeclarationsComponent,
        // --- Certificate Order Batch Process ---
        LCECertificateOrderBatchProcessRecordComponent,
        LCECertificateOrderBatchProcessesStateComponent,
        // --- Certificate Order ---
        LCECertificateOrderDataBoxesComponent,
        LCECertificateOrderPriceEstimatorComponent,
        LCECertificateOrderEventsComponent,
        LCECertificateOrderPriceDetailsComponent,
        LCECertificateOrderProcessConfirmationComponent,
        LCECertificateOrderProcessCheckoutComponent,
        LCECertificateOrderProcessPendingRequestComponent,
        LCECertificateOrderProcessComponent,
        LceCertificateOrderProcessMFOComponent,
        LCECertificateOrderProcessConfirmationMFOComponent,
        LCEDeliveryManOrderWidgetComponent,
        LCEDeliveryManPartialComponent,
        LCEInputFieldDeliveryManAutocompleteComponent,
        LCECertificateOrderWidgetComponent,
        LCECertificateOrderManualDispatcher,
        LCECertificateOrderStatusComponent,
        LCECertificateOrderPartialComponent,
        LCECertificateOrderLetterStatComponent,
        LCECertificateOrderSimpleStatsComponent,
        LCECertificateOrderNumberOfCopiesComponent,
        LCECertificateOrderRowComponent,
        LCECertificateOrderLastComponent,
        LCECertificateOrderDetailComponent, // TODO: To Be Removed
        LCECertificateOrderHeadComponent, // TODO: To Be Removed
        LCECertificateOrderRecordComponent,
        LCECertificateOrdersPendingOrdersIndicatorComponent,
        LCECertificateOrdersPendingOrdersSquareComponent,
        LCECertificateOrdersAdvancedSearchComponent,
        LCECertificateOrdersFeatureSettingsComponent,
        LCECertificateOrdersPendingOrdersComponent,
        LCECertificateOrdersComponent,
        LCECertificateOrderCreateComponent,
        LCECertificateOrderStatsPaymentComponent,
        LCECertificateOrderProcurationComponent,
        LCECertificateOrderPaymentComponent,
        LCECertificateOrdersPriceSettingsComponent,
        // --- Certificate ---
        LCECertificateAvatarComponent,
        LCECertificatePartialComponent,
        LCECertificateRecordComponent,
        // --- WebSocket Connection Status ---
        LCEWebSocketConnectionStatusComponent,
        // --- Dashboard -----
        LCEDashboardStatsGlobalComponent,
        LCEDashboardCertificateOrderChartDoughnutComponent,
        LCEDashboardCertificateOrderChartLineComponent,
        LCEDashboardCertificateOrderStatsComponent,
        LCEDashboardNewsLastArticleComponent,
        LCEDashboardSmartCityLastArticleComponent,
        // --- Delivery ---
        LCEDeliveryRecordComponent,
        LCEDeliveryDestinationComponent,
        LCEDeliveryDestinationTypeComponent,
        LCEDeliveryGeneralComponent,
        LCEDeliveryAssignmentStatusComponent,
        LCEDeliveryAssignmentRequestComponent,
        LCEDeliveryAssignmentComponent,
        LCEDeliveryManAssignmentComponent,
        LCEDeliveryUnassignmentRequestComponent,
        LCEDeliveryAssignmentRequestOperationComponent,
        // --- Statistic ---
        LCEStatisticVariationComponent,
        LCEStatisticBoxComponent,
        // --- Service Point ---
        LCEServicePointPartialComponent,
        LCEServicePointPartialOverlayComponent,
        LCEInputFieldServicePointAutocompleteComponent,
        LCEInputFieldServicePointAutocompleteMultipleComponent,
        LCEDataFieldServicePointComponent,
        LCEServicePointCreateUpdateComponent,
        LCEServicePointRecordComponent,
        LCEServicePointsComponent,
        // --- Facility ---
        LCEFacilitySelectComponent,
        LCEFacilityPartialComponent,
        LCEFacilityPartialOverlayComponent,
        LCEInputFieldFacilityAutocompleteComponent,
        LCEInputFieldFacilityAutocompleteMultipleComponent,
        LCEDataFieldFacilityComponent,
        LCEFacilityCreateUpdateComponent,
        LCEFacilityRecordComponent,
        LCEFacilitiesComponent,
        LCEFacilityTownHallPaymentTransferComponent,
        LCEFacilityTownHallPaymentLastTransferComponent,
        LCEFacilityTownHallPaymentTransferPartialComponent,
        //Facility Town Hall Stamp Token
        LCEFacilityTownHallStampTokenNumberComponent,
        LCEFacilityTownHallStampTokenHistoryUserComponent,
        LCEFacilityTownHallStampTokenCreateComponent,
        LCEFacilityTownHallStampTokenGeneratorComponent,
        LCEFacilityTownHallStampTokenManagerComponent,
        LceFacilityTownHallStampTokenTableComponent,
        LCEFacilityTownHallStampTokenActionsComponent,
        LCEFacilityTownHallStampTokenRecordComponent,
        LCEFacilityTownHallStampTokenStatusComponent,
        LCEFacilityTownHallStampTokenQRCodeComponent,
        LCEFacilityTownHallStampTokenConsumptionComponent,
        LCEFacilityTownHallStampTokenHistoryGlobalComponent,
        LCEFacilityTownHallStampRemainingComponent,
        // --- Facility Town Hall Stamp Load ---
        LCEFacilityTownHallStampLoadsComponent,
        LCEFacilityTownHallStampLoadRecordComponent,
        LCEFacilityTownHallStampLoadPartialComponent,
        LCEFacilityTownHallStampLoadCreateComponent,
        LCEFacilityTownHallStampLoadLastComponent,
        // --- Facility Town Hall Registration ---
        LCEFacilityTownHallRegistrationCreateComponent,
        LCEFacilityTownHallRegistrationRecordComponent,
        // --- Municipality Setting ---
        LCEMunicipalitySettingsComponent,
        // --- Municipality ---
        LCEMunicipalityPartialComponent,
        LCEMunicipalityPartialsComponent,
        LCEMunicipalityPartialOverlayComponent,
        LCEInputFieldMunicipalityAutocompleteComponent,
        LCEInputFieldMunicipalityAutocompleteMultipleComponent,
        LCEDataFieldMunicipalityComponent,
        LCEMunicipalityCreateUpdateComponent,
        LCEMunicipalityRecordComponent,
        LCEMunicipalitiesComponent,
        // --- District ---
        LCEDistrictPartialComponent,
        LCEInputFieldDistrictAutocompleteComponent,
        LCEInputFieldDistrictAutocompleteMultipleComponent,
        LCEDataFieldDistrictComponent,
        LCEDistrictCreateUpdateComponent,
        LCEDistrictRecordComponent,
        LCEDistrictsComponent,
        // --- Facility Worker ---
        LCEFacilityWorkerPartialComponent,
        LCEFacilityWorkerRecordComponent,
        LCEFacilityWorkerCreateUpdateComponent,
        LCEFacilityWorkersComponent,
        LCEFacilityWorkerDetailComponent,
        LCEFacilityWorkerDetailHeadComponent,
        // --- User Customer ---
        LCEUserCustomerAnonymousComponent,
        LCEUserCustomersComponent,
        LCEUserCustomerRecordComponent,
        // --- User Municipal Employee---
        LCEUserMunicipalEmployeeRecordComponent,
        // --- User Delivery Man ---
        LCEDeliveryMenWidgetComponent,
        // --- User ---
        LCEUserIvarComponent,
        LCEUserStatusComponent,
        LCEUserAvatarComponent,
        LCEUserPartialComponent,
        LCEUserDetailHeadComponent,
        LCEUserDetailComponent,
        LCEUserResourceAuditComponent,
        LCEUserResourceAuditInlineComponent,
        LCEUseMobileVersionComponent,
        LCEDataFieldUserResourceAuditInlineComponent,
        LCEInputFieldUserAutoCompleteComponent,
        LCEInputFieldUserAutoCompleteComponentMultiple,
        LCEUserAccountComponent,
        LCEUserAccountAvatarComponent,
        LCEUserAccountProfileComponent,
        LCEUserAccountAuthenticationComponent,
        LCEUserAccountCreateUpdateComponent,
        LCEUserAccountContactCreateUpdateComponent,
        LCEUserAccountPersonalInformationCreateUpdateComponent,
        LCEUserAccountSettingsComponent,
        LCEUserAccountStatisticsComponent,
        // --- Event ---
        LCEEventsComponent,
        LCEEventRecordComponent,
        // --- Global Search ---
        LCEGlobalSearchComponent,
        LCEGlobalSearchInputComponent,
        // --- Delivery Company ---
        LCEDeliveryCompaniesComponent,
        LCEDeliveryCompanyRecordComponent,
        LCEDeliveryCompanyPartialComponent,
        LCEDeliveryCompanyCreateUpdateComponent,
        // Authorization
        LCEAuthorizationTesterComponent,
        LCEAuthorizationComponent,
        LCEAuthorizationSearchComponent,
        LCEAuthorizationDashBoardComponent,
        XSAuthorizationBaseComponent
    ]
})
export class LCESharedModule {
}
