<div [ngClass]="{ 'xs-disabled': disabled === true }" class=" {{ styleClass }}">
    <xs-ivar
            (line1Click)="onClick($event)"
            [avatarRounded]="false"
            [avatar]="data.coverImage"
            [disabled]="disabled"
            [iconClickable]="showOverlay"
            [icon]="ICON.news"
            [line1Clickable]="showOverlay"
            [line1]="data.title"
            [line2]="showDescription ? data.description : undefined"
            [line3]="showCode ? data.code : undefined"
            [showAvatar]="showAvatar && (data.coverImage | xsIsNotEmpty) && forceShowIcon !== true"
            [showIcon]="(showAvatar && (data.coverImage | xsIsEmpty)) || forceShowIcon === true"
            avatarImageStyleClass="lce-article-partial-avatar lce-article-partial-avatar-image-{{ avatarSize }}"
            iconStyleClass="lce-article-partial-avatar lce-article-partial-avatar-icon-{{ avatarSize }} xs-color-secondary"
            line1StyleClass="xs-font-size-intermediate-imp {{ titleStyleClass }}"
            line2StyleClass="xs-font-size-small-imp xs-mt-3-imp"
            line3StyleClass="xs-font-size-small-imp xs-mt-3-imp xs-color-secondary xs-font-weight-500">
    </xs-ivar>

    <p-overlayPanel #partialOverlay *ngIf="showOverlay" [appendTo]="'body'" styleClass="xs-overlay-panel-content-no-padding xs-max-width-500">
        <lce-news-article-partial-overlay [data]="data" [showAvatar]="showAvatar" [viewArticle]="viewArticle"></lce-news-article-partial-overlay>
    </p-overlayPanel>
</div>
