import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lce-facility-town-hall-stamp-number',
    templateUrl: './lce-facility-town-hall-stamp-token-number.component.html'
})
export class LCEFacilityTownHallStampTokenNumberComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() numberOfStampsStyleClass?: string;
    @Input() badgeStyleClass?: string;

    @Input() numberOfStamps?: number;
    @Input() numberOfStampsRemaining?: number;

    @Input() numberOfStampsLabel?: string;
    @Input() numberOfStampsRemainingOrLabel?: string;

    @Input() badgeMode?: boolean;

    ngOnInit(): void {
        XSAssert.notEmpty(this.numberOfStamps, 'numberOfStamps');

        if (XSUtils.isEmpty(this.numberOfStampsLabel)) this.numberOfStampsLabel = 'lce.shared.facility.townHallStampToken.label.generatedStamps';
        if (XSUtils.isEmpty(this.numberOfStampsRemainingOrLabel)) this.numberOfStampsRemainingOrLabel = 'lce.shared.facility.townHallStampToken.label.nStampRemaining';

    }
}
