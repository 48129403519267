import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {LCEBirthDeclaration, LCEBirthDeclarationCreate, LCEBirthDeclarationService} from '@lce/core';
import {LOG, XS_STR_SPACE, XSAssert, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEBirthDeclarationDeliveryFormComponent} from '../delivery/create-update/birth-declaration-delivery-create-update.component';
import {LCEBirthDeclarationParentFormComponent} from '../parent/create-update/lce-birth-declaration-parent-create-update.component';

@Component({selector: 'lce-birth-declaration-create-update', templateUrl: './lce-birth-declaration-create-update.component.html'})
export class LCEBirthDeclarationCreateUpdateComponent extends XSDialogable implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.createUpdate.';

    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() birthDeclarationID?: string;
    @Input() birthDeclaration?: LCEBirthDeclaration;

    @Input() showEditButton?: boolean;

    @Input() facilityCode?: string;

    @Input() showHeader?: boolean;
    @Input() showFooter?: boolean;

    @Input() showBorder?: boolean;

    @Output() saveEvent = new EventEmitter<LCEBirthDeclaration>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclaration>();

    birthDeclarationFormGroup: FormGroup = new FormGroup({});
    deliveryFormGroup: FormGroup = new FormGroup({});
    motherFormGroup: FormGroup = new FormGroup({});
    fatherFormGroup: FormGroup = new FormGroup({});

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo
    };

    createUpdateError: any;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    @ViewChild('deliveryForm') deliveryForm: LCEBirthDeclarationDeliveryFormComponent;
    @ViewChild('motherParentForm') motherParentForm: LCEBirthDeclarationParentFormComponent;
    @ViewChild('fatherParentForm') fatherParentForm: LCEBirthDeclarationParentFormComponent;
    @Input() formKey?: 'delivery' | 'mother' | 'father';
    private subscription: Subscription = new Subscription();

    constructor(
        private loaderService: XSLoaderService,
        private birthDeclarationService: LCEBirthDeclarationService,
        private translationService: XSTranslationService) {
        super();
    }

    get headerTitle(): string {
        if (!this.isCreateMode()) {
            let headerText = this.translationService.translateKey('lce.shared.birthDeclaration.label.birth');
            headerText += XS_STR_SPACE + '-' + XS_STR_SPACE;
            headerText += this.birthDeclaration?.delivery.birthNumber;
            return headerText;
        }
        return this.TR_BASE + 'createTitle';
    }

    get headerSubTitle(): string {
        return this.isCreateMode() ? this.TR_BASE + 'createSubTitle' : this.birthDeclaration?.code!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.birthDeclaration = this.dialogConfig.data.birthDeclaration;
            this.facilityCode = this.dialogConfig.data.facilityCode;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.birthDeclaration));
            this.showHeader = this.dialogConfig.data.showHeader;
            this.showFooter = this.dialogConfig.data.showFooter;
        }
        this.facilityCode = 'LCE-FAW-001';
        XSAssert.notEmpty(this.facilityCode, 'facilityCode');

        if (this.isCreateMode() || !XSUtils.isEmpty(this.birthDeclaration)) {
            if (!XSUtils.isEmpty(this.birthDeclaration)) this.birthDeclarationID = this.birthDeclaration!.id;
            this.initialize();
        } else this.retrieveBirthDeclaration();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.birthDeclarationID);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.birthDeclarationID) && XSUtils.isEmpty(this.birthDeclaration);
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public fillForm(): void {
        this.deliveryForm.fillForm();
        this.motherParentForm.fillForm();
        this.fatherParentForm.fillForm();
    }

    public reset() {
        this.birthDeclarationFormGroup.reset();
    }

    // TODO
    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.birthDeclarationFormGroup.dirty && this.formValueChange();
    }

    public shouldShowResetConfirmation(): boolean {
        return this.isFormEmpty();
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private create(): void {
        XSFormUtils.validateFormGroup(this.birthDeclarationFormGroup);
        const isDelivery = this.deliveryForm.validate();
        const isMotherValid = this.motherParentForm.validate();
        const isFatherValid = this.fatherParentForm.validate();
        if (!isMotherValid || !isFatherValid || !isDelivery) return;

        if (!this.birthDeclarationFormGroup.valid) return;
        LOG().debug('Creating Birth Declaration ...');
        this.createUpdateLoading = true;
        const formData = this.birthDeclarationFormGroup.value;
        const birthDeclarationCreate: LCEBirthDeclarationCreate = {
            facilityCode: this.facilityCode!,
            delivery: this.deliveryForm.buildCreate(formData.delivery),
            mother: this.motherParentForm.buildCreate(formData.mother),
            father: this.fatherParentForm.buildCreate(formData.father)
        };
        this.subscription.add(
            this.birthDeclarationService
                .create(birthDeclarationCreate)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (birthDeclaration) => {
                        LOG().debug('Birth Declaration successfully saved :-) [code: ' + birthDeclaration.code + ', id: ' + birthDeclaration.id + ']');
                        this.saveEvent.emit(birthDeclaration);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private formValueChange(): boolean {
        if (!this.deliveryForm.formValueChange() && !this.motherParentForm.formValueChange() && !this.fatherParentForm.formValueChange()) return false;
        else return true;
    }

    private update(): void {
        XSFormUtils.validateFormGroup(this.birthDeclarationFormGroup);
        if (!this.birthDeclarationFormGroup.valid) return;
        LOG().debug('Updating birth declaration [birthDeclarationID: ' + this.birthDeclarationID + '] ...');

        const formData = this.birthDeclarationFormGroup.value;

        const fieldValueMap: Map<string, any> = new Map();
        this.deliveryForm.buildUpdate(fieldValueMap, formData.delivery);

        this.motherParentForm.buildUpdate(fieldValueMap, formData.mother, this.birthDeclaration!.mother, 'mother');
        this.motherParentForm.buildUpdate(fieldValueMap, formData.father, this.birthDeclaration!.father, 'father');

        if (fieldValueMap.size === 0) {
            LOG().debug('Birth Declaration not updated. No change detected ! [code: ' + this.birthDeclaration!.code + ', id: ' + this.birthDeclarationID + ']');
            this.saveEvent.emit(this.birthDeclaration);
            return;
        }

        this.createUpdateLoading = true;
        this.subscription.add(
            this.birthDeclarationService
                .update(this.birthDeclarationID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (birthDeclaration) => {
                        const updatedFacilityWorkerID = birthDeclaration.id;
                        LOG().debug('facilityWorker successfully updated :-) [code: ' + birthDeclaration.code + ', id: ' + updatedFacilityWorkerID + ']');
                        this.saveEvent.emit(birthDeclaration);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'lce.shared.confirmation.resetConfirmation',
            icon: this.ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'lce.shared.confirmation.closeConfirmation',
            icon: this.ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            }
        };
    }

    private isFormEmpty(): boolean {
        if (
            // ***
            this.deliveryForm.isFormEmpty() ||
            this.motherParentForm.isFormEmpty() ||
            this.fatherParentForm.isFormEmpty()
        ) {
            return false;
        }
        return true;
    }

    private buildFields(): void {
        this.birthDeclarationFormGroup.addControl('delivery', this.deliveryFormGroup);
        this.birthDeclarationFormGroup.addControl('mother', this.motherFormGroup);
        this.birthDeclarationFormGroup.addControl('father', this.fatherFormGroup);
    }

    private retrieveBirthDeclaration(): void {
        this.loaderService.startLoader(this.LOADER_ID_CENTRAL);
        this.retrieveError = undefined;
        this.subscription.add(
            this.birthDeclarationService
                .retrieve(this.birthDeclarationID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID_CENTRAL)))
                .subscribe({
                    next: (birthDeclaration: LCEBirthDeclaration) => {
                        this.birthDeclaration = birthDeclaration;
                    },
                    error: (error) => (this.retrieveError = error)
                })
        );
    }
}
