import {Component, Input, OnInit} from '@angular/core';
import {LCEFacilityWorkerDialogService} from '../lce-facility-worker-dialog.service';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCEFacilityWorkerPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({selector: 'lce-facility-worker-detail', templateUrl: './lce-facility-worker-detail.component.html'})
export class LCEFacilityWorkerDetailComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() data: LCEFacilityWorkerPartial;

    @Input() showViewRecordButton?: boolean;

    @Input() showBorder: boolean;

    constructor(private facilityWorkerDialogService: LCEFacilityWorkerDialogService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showViewRecordButton)) this.showViewRecordButton = false;
    }

    public onViewRecordBtnClick() {
        this.facilityWorkerDialogService.openRecordDialog({facilityWorkerID: this.data.id});
    }
}
