import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEFacility, LCEFacilityService, LCEFacilityTownHallCertificateOrderRegistration, LCEUserPartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSLoaderService} from '@xs/common';
import {XSButton, XSDialogable} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-facility-town-hall-registration-record', templateUrl: './lce-facility-town-hall-registration-record.component.html'})
export class LCEFacilityTownHallRegistrationRecordComponent extends XSDialogable implements OnInit, OnDestroy {

    ICON = LCE_SHARED_ICON;

    readonly LOADER_ID = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.facility.townHallRegistration.';
    readonly TR_BASE_LABEL: string = this.TR_BASE + 'label.';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() showHeader?: boolean;
    
    @Input() facilityID?: string;
    @Input() certificateOrderRegistration?: LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>;

    @Output() closeEvent = new EventEmitter<LCEFacilityTownHallCertificateOrderRegistration<LCEUserPartial>>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.retrieveFacility()
    };

    private subscription: Subscription = new Subscription();

    constructor(private facilityService: LCEFacilityService,
                private loaderService: XSLoaderService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.facilityID = this.dialogConfig.data.facilityID;
            this.certificateOrderRegistration = this.dialogConfig.data.certificateOrderRegistration;
            this.showHeader = this.dialogConfig.data.showHeader;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.certificateOrderRegistration));
        }

        if (XSUtils.isEmpty(this.facilityID) && XSUtils.isEmpty(this.certificateOrderRegistration)) {
            throw new Error('facilityID and certificateOrderRegistration cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityID) && !XSUtils.isEmpty(this.certificateOrderRegistration)) {
            throw new Error('facilityID and certificateOrderRegistration cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.facilityID)) this.retrieveFacility();
        else {
            XSAssert.notNull(this.certificateOrderRegistration, 'certificateOrderRegistration');
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.certificateOrderRegistration);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public retrieveFacility(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.subscription.add(
            this.facilityService
                .retrieve(this.facilityID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (facility: LCEFacility) => {
                        this.certificateOrderRegistration = facility.certificateOrderRegistration;
                    },
                    error: error => (this.error = error)
                })
        );
    }

}
