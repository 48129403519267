import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCECoreContextService, LCERequestorType, LCEUserMunicipalEmployeePartial, LCEUserPartial} from '@lce/core';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {
    IP_ADDRESS,
    LCE_HTTP_MOCK_DATASET_DEFAULT_ID,
    USER_AGENT_ANDROID,
    USER_AGENT_ANDROID_TABLET,
    USER_AGENT_CHROME_MAC_OSX,
    USER_AGENT_FIREFOX_WINDOWS,
    USER_AGENT_IPAD,
    USER_AGENT_IPHONE
} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSUtils} from '@xs/base';
import {XSAuthenticationFailedReason} from '@xs/core';
import {LCE_SHARED_ENDPOINT, LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttemptSearch} from '@lce/shared';
import {XSMockPKResourceHandler, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import {LCE_MBO_MOCK_USER_ELON_MUSK} from '@lce/mock/core/lce-mock-user-data';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.authenticationLoginAttempts';

@Injectable()
export class LCEMockAuthenticationLoginAttemptHandler extends XSMockPKResourceHandler<LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttempt, LCEAuthenticationLoginAttemptSearch> {

    public static AUTHENTICATION_LOGIN_ATTEMPT_STORAGE: Map<string, LCEAuthenticationLoginAttempt> = new Map<string, LCEAuthenticationLoginAttempt>();
    private queryPredicates: XSMockSearchQueryPredicate<LCEAuthenticationLoginAttempt>[] = [
        (loginAttemp, query) => loginAttemp.username.toLowerCase().includes(query)
    ];
    private searchPredicates: XSMockSearchPredicate<LCEAuthenticationLoginAttempt>[] = [
        (loginAttemp, params) => this.httpParamArrayIncludes(params, 'userTypes', loginAttemp.userType),
        (loginAttemp, params) => this.httpParamArrayIncludes(params, 'requestors', loginAttemp.requestor)];

    constructor(private contextService: LCECoreContextService) {
        super(DATASET_BASE_ID, LCE_SHARED_ENDPOINT.authenticationLoginAttempts.index);
    }

    getStorage(): Map<string, LCEAuthenticationLoginAttempt> {
        return LCEMockAuthenticationLoginAttemptHandler.AUTHENTICATION_LOGIN_ATTEMPT_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    toPartial(authenticationLoginAttempt: LCEAuthenticationLoginAttempt): LCEAuthenticationLoginAttempt {
        return authenticationLoginAttempt;
    }

    buildMockDataArray(): void {
        this.buildStorage();
        this.mockDataArray = [
            ...this.mockDataArray,
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.authentication.loginAttempts',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_SHARED_ENDPOINT.authenticationLoginAttempts.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: () => {
                    return Array.from(LCEMockAuthenticationLoginAttemptHandler.AUTHENTICATION_LOGIN_ATTEMPT_STORAGE.values());
                }
            },
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates})
        ];
    }

    private buildStorage(n: number = 10): void {
        for (let $i = 0; $i < n; $i++) {
            const attemptID = XSUtils.uuid();
            LCEMockAuthenticationLoginAttemptHandler.AUTHENTICATION_LOGIN_ATTEMPT_STORAGE.set(attemptID, this.buildRandomLoginAttempt(attemptID));
        }
    }

    private buildRandomLoginAttempt(id?: string, requestor?: LCERequestorType): LCEAuthenticationLoginAttempt {
        if (XSUtils.isEmpty(id)) {
            id = XSUtils.uuid();
        }
        if (XSUtils.isEmpty(requestor)) {
            requestor = XSUtils.randomElement([LCERequestorType.MUNICIPALITY_BACK_OFFICE_APP, LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP]);
        }
        const success = XSUtils.randomElement([true, false]);
        let user: LCEUserMunicipalEmployeePartial | undefined = undefined;
        if (success) {
            user = LCEMockUtils.randomUserMunicipalEmployee();
        }

        return {
            id: id!,
            createdOn: XSUtils.randomDateTime(),
            userID: user?.id,
            username: success ? user?.primaryPhoneNumber! : XSUtils.randomElement(['+2250707992255', 'wrong.email@iro-xs.com']),
            userType: user?.type,
            success: success,
            failedReason: success ? undefined : this.randomFailedReason(),
            requestor: requestor!,
            userAgent: this.randomUserAgent(),
            ipAddress: this.randomIPAddress(),
            geolocation: XSUtils.randomElement([LCEMockUtils.buildIPGeolocationAbidjan(), LCEMockUtils.buildIPGeolocationYamoussokro()]),
            viaPinCode: XSUtils.randomElement([true, false]),
            createdBy: LCE_MBO_MOCK_USER_ELON_MUSK
        };
    }

    private randomFailedReason(): XSAuthenticationFailedReason {
        return XSUtils.randomElement([
            XSAuthenticationFailedReason.ACCOUNT_DISABLED, XSAuthenticationFailedReason.ACCOUNT_DISABLED, XSAuthenticationFailedReason.PASSWORD_EXPIRED,
            XSAuthenticationFailedReason.PASSWORD_EXPIRED, XSAuthenticationFailedReason.MAX_ATTEMPTS_ALLOWED_EXCEEDED, XSAuthenticationFailedReason.WRONG_PASSWORD,
            XSAuthenticationFailedReason.USERNAME_NOT_FOUND
        ]);
    }

    private randomIPAddress(): string {
        return XSUtils.randomElement([IP_ADDRESS, '121.178.57.109', '123.777.22.333', '100.200.30.400', '12.345.67.890']);
    }

    private randomUserAgent(): string {
        return XSUtils.randomElement([
            USER_AGENT_CHROME_MAC_OSX, USER_AGENT_ANDROID, USER_AGENT_ANDROID_TABLET, USER_AGENT_IPHONE, USER_AGENT_IPAD, USER_AGENT_FIREFOX_WINDOWS
        ]);
    }
}
