<div class="xs-flex-row xs-width-full {{ styleClass }}">
    <div class="xs-flex-column xs-width-full">
        <div class="xs-flex-row xs-align-items-center xs-width-full">
            <div class="xs-display-block xs-width-150">
                <lce-user-avatar
                        [clickable]="false"
                        [clickable]="false"
                        [data]="data"
                        [showStatus]="false"
                        [showTooltip]="false"
                        avatarSize="extra-large">
                </lce-user-avatar>
            </div>

            <div class="xs-flex-column xs-flex-1">
                <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                    <xs-text
                            (clickEvent)="accountClickEvent.emit($event)"
                            [clickable]="account"
                            [fontWeight500]="!account"
                            [value]="dataLine1">
                    </xs-text>
                </div>
                <xs-text
                        [fontWeight500]="true"
                        [value]="dataLine2"
                        color="secondary"
                        size="intermediate"
                        styleClass="xs-mt-10">
                </xs-text>
                <xs-text
                        [uppercase]="true"
                        [value]="data.code"
                        color="secondary"
                        size="small"
                        styleClass="xs-mt-2">
                </xs-text>

                <div class="xs-flex-row xs-align-items-end xs-justify-content-space-between xs-mt-30">
                    <div *ngIf="!isUserSystem()" class="xs-flex-column">
                        <lce-user-status
                                *ngIf="data.status && !isCustomer()"
                                [status]="data.status!"
                                styleClass="xs-mb-2">
                        </lce-user-status>
                        <div class="xs-flex-row xs-font-size-small xs-text-secondary-color">
                            <span class="xs-color-secondary">{{ 'lce.core.label.lastActivity' | translate }} - </span>&nbsp;
                            <xs-time-ago
                                    *ngIf="data.lastActivity | xsIsNotEmpty"
                                    [givenDate]="data.lastActivity!"
                                    [shortMode]="true"
                                    styleClass="xs-color-secondary">
                            </xs-time-ago>
                            <span *ngIf="data.lastActivity | xsIsEmpty" class="xs-color-secondary">{{ 'lce.core.label.unknown' | translate }}</span>
                        </div>
                    </div>
                    <xs-button
                            (clickEvent)="logoutClickEvent.emit($event)"
                            *ngIf="logout"
                            [icon]="ICON_LOGOUT"
                            [loading]="loggingOut"
                            [transform]="false"
                            iconStyleClass="xs-font-size-16-imp"
                            loaderPosition="left"
                            type="icon">
                    </xs-button>
                </div>
            </div>
        </div>
    </div>
</div>
