<div class="xs-flex-column-center xs-width-fit-content {{ styleClass }}">
    <div
            [ngClass]="{ 'xs-justify-content-center xs-background-discrete xs-border-radius-25 xs-pl-15 xs-pr-20 xs-ptb-4 xs-min-width-150': showBackground }"
            class="xs-flex-inline xs-width-fit-content xs-align-items-center">
        <span
                [innerHTML]="(trBase + data) | translate"
                class="xs-display-block xs-font-size-small xs-color-secondary xs-font-weight-500 xs-white-space-nowrap {{ textStyleClass }}">
        </span>
    </div>
</div>
