<div class="xs-flex-column-center xs-width-full xs-position-relative {{ styleClass }} {{ loadingStyleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #articleViewLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>

    <div *ngIf="hasError() && !articleViewLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="lce.shared.smartcity.label.errorMessageRetrieveArticle"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div *ngIf="canDisplayData()" class="xs-flex-column xs-width-full xs-plr-25">
        <!-- === Audit === -->
        <div *ngIf="options!.showAudit === true || options!.showQRcode === true" class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full">
            <lce-user-resource-audit *ngIf="options!.showAudit === true" [data]="data!"></lce-user-resource-audit>
            <xs-qrcode *ngIf="options!.showQRcode === true" [data]="data!.qrCode" alt="Smart City QRCode" styleClass="xs-width-75"></xs-qrcode>
        </div>

        <!-- === Article Head === -->
        <div class="xs-flex-column-center xs-width-full xs-mt-25">
            <div class="xs-flex-column xs-width-full xs-max-width-750">
                <div class="xs-flex-column xs-width-full">
                    <lce-smartcity-article-type
                            [darkness]="true"
                            [small]="true"
                            [type]="data!.type"
                            styleClass="xs-mb-15">
                    </lce-smartcity-article-type>
                    <span class="xs-display-block xs-font-size-medium xs-font-weight-500">{{ data!.title }}</span>
                    <span *ngIf="data!.subTitle | xsIsNotEmpty" class="xs-display-block xs-font-size-intermediate xs-mt-5">{{ data!.subTitle }}</span>

                    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mt-30 xs-md-plr-15">
                        <span class="xs-display-block xs-font-size-intermediate xs-color-secondary">{{ data!.createdOn | xsDate : createdOnDateFormat }}</span>
                        <lce-smartcity-article-stats-icons
                                [readCount]="data!.readCount"
                                [scanCount]="data!.scanCount"
                                [shareCount]="data!.shareCount"
                                styleClass="xs-width-225 xs-color-secondary">
                        </lce-smartcity-article-stats-icons>
                    </div>
                </div>
                <div class="xs-flex-column xs-width-full xs-mt-10">
                    <img [src]="data!.coverImage" alt="" class="xs-width-full xs-border-radius-default"/>
                    <!-- TODO: a text describing the image cover should be display here ...  -->
                </div>
            </div>
        </div>

        <!-- === Article Content === -->
        <div class="xs-flex-column xs-width-full xs-mt-50">
            <div
                    *ngIf="data!.description | xsIsNotEmpty"
                    [innerHTML]="data!.description | xsTrim | xsSafe : 'html'"
                    class="xs-flex-row-center xs-font-weight-600 xs-mb-15">
            </div>
            <div
                    [innerHTML]="data!.content | xsTrim | xsSafe : 'html'"
                    class="xs-mtb-15 xs-line-height-1-5 xs-text-align-justify">
            </div>
        </div>
    </div>

</div>

<ng-template #dHeader>
    <xs-ivar
            [ellipsis]="true"
            [icon]="ICON.smartCity"
            [line2]="canDisplayData() ? (data!.code | uppercase) : 'LCE ...'"
            line1="lce.shared.smartcity.articles.title"
            line1StyleClass="xs-font-size-intermediate-imp"
            line2StyleClass="xs-font-size-small-imp"
            styleClass="xs-dialog-header">
    </xs-ivar>
</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-align-items-center xs-justify-content-end">
        <xs-button
                (clickEvent)="goToArticle()"
                *ngIf="canDisplayData()"
                [icon]="ICON.externalLink"
                iconStyleClass="xs-font-size-13-imp"
                label="lce.shared.label.goToArticle"
                size="intermediate"
                type="text">
        </xs-button>
        <xs-button (clickEvent)="closeDialog()" class="xs-ml-40" label="xs.core.label.ok" type="button">
        </xs-button>
    </div>
</ng-template>