import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCEMunicipalityService, LCEUserCustomerCreate} from '@lce/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSDialogable, XSInputFieldAutoCompleteOptions, XSInputFieldPhoneNumberOptions, XSInputFieldTextOptions} from '@xs/core';
import {XSUtils} from '@xs/base';

@Component({selector: 'lce-certificate-order-create-customer', templateUrl: './lce-certificate-order-create-customer.component.html'})
export class LCECertificateOrderCreateCustomerComponent extends XSDialogable implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() customerCreate?: LCEUserCustomerCreate;

    @Input() formGroup: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();

    firstNameField: XSInputFieldTextOptions;
    lastNameField: XSInputFieldTextOptions;
    emailField: XSInputFieldTextOptions;
    primaryPhoneNumberField: XSInputFieldPhoneNumberOptions;
    municipalityOfResidenceField: XSInputFieldAutoCompleteOptions;

    appendTo?: any;

    constructor(private municipalityService: LCEMunicipalityService) {
        super();
    }

    ngOnInit(): void {
        this.buildFields();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.customerCreate) && !changes.customerCreate!.isFirstChange()) this.update(this.customerCreate!);
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.firstNameField.control?.value) ||
            !XSUtils.isEmpty(this.lastNameField.control?.value) ||
            !XSUtils.isEmpty(this.emailField.control?.value) ||
            !XSUtils.isEmpty(this.primaryPhoneNumberField.control?.value) ||
            !XSUtils.isEmpty(this.municipalityOfResidenceField.control?.value));
    }

    private update(customerCreate: LCEUserCustomerCreate): void {
        this.firstNameField.control?.setValue(customerCreate.name?.firstName);
        this.lastNameField.control?.setValue(customerCreate.name?.lastName);
        this.emailField.control?.setValue(customerCreate.email);
        this.primaryPhoneNumberField.control?.setValue(customerCreate.primaryPhoneNumber);
        this.municipalityOfResidenceField.control?.setValue(customerCreate.municipalityOfResidence);
    }

    private buildFields(): void {
        this.firstNameField = {
            fieldName: 'firstName',
            control: new FormControl(undefined, Validators.required),
            label: 'xs.common.label.firstName'
        };

        this.lastNameField = {
            fieldName: 'lastName',
            control: new FormControl(undefined, Validators.required),
            label: 'xs.common.label.lastName'
        };

        this.emailField = {
            fieldName: 'email',
            control: new FormControl(undefined),
            label: 'xs.common.label.email'
        };

        this.primaryPhoneNumberField = {
            fieldName: 'primaryPhoneNumber',
            control: new FormControl(undefined, Validators.required),
            label: 'xs.common.label.primaryPhoneNumber',
            labelStyleClass: 'xs-ellipsis-imp',
            showCountryFlag: true,
            countryISO: 'ci'
        };

        this.municipalityOfResidenceField = {
            autoCompleteOptions: {
                labelField: 'fullName',
                search: (query: string) => {
                    return this.municipalityService.autocomplete(query);
                }
            },
            leftIcon: this.ICON.municipality,
            leftIconStyleClass: 'xs-color-secondary',
            fieldName: 'municipalityOfResidence',
            label: 'lce.shared.user.label.municipalityOfResidence',
            control: new FormControl(undefined)
        };
        this.formGroup.addControl(this.firstNameField.fieldName, this.firstNameField.control!);
        this.formGroup.addControl(this.lastNameField.fieldName, this.lastNameField.control!);
        this.formGroup.addControl(this.emailField.fieldName, this.emailField.control!);
        this.formGroup.addControl(this.primaryPhoneNumberField.fieldName, this.primaryPhoneNumberField.control!);
        this.formGroup.addControl(this.municipalityOfResidenceField.fieldName, this.municipalityOfResidenceField.control!);
    }
}
