import {XSPersonName, XSPKAuditFullSearchFilter, XSPKDTOAuditFull} from '@xs/base';
import {LCEMunicipalityPartial} from './lce-municipality';
import {LCEUserPartial} from './user/lce-user';

// ----------------------------------------------------------------------------------------------------------
// === * ===
// ----------------------------------------------------------------------------------------------------------

export interface LCESuggestionEventData {
    status: LCESuggestionStatus;
    anonymous: boolean;
    authorName?: XSPersonName;
}

export enum LCESuggestionEventName {
    NEW_SUGGESTION = 'suggestionNew',
    STATUS_READ = 'suggestionStatusRead',
    STATUS_UNREAD = 'suggestionStatusUnread',
    STATUS_CONSIDERED = 'suggestionStatusConsidered',
    STATUS_SELECTED = 'suggestionStatusSelected',
}

// ----------------------------------------------------------------------------------------------------------
// === * ===
// ----------------------------------------------------------------------------------------------------------

export interface LCESuggestionStatsStatuses {
    total: number;
    unread: number;
    read: number;
    considered: number;
    selected: number;
}

export interface LCESuggestionSearch extends XSPKAuditFullSearchFilter {
    codes?: string[];

    anonymous?: boolean;

    subject?: string;
    message?: string;

    customerIDs?: string[];
    customerCodes?: string[];

    statuses?: LCESuggestionStatus[];

    readOnRange?: string;
    readBy?: string[];

    unreadOnRange?: string;
    unreadBy?: string[];

    consideredOnRange?: string;
    consideredBy?: [];

    selectedOnRange?: string;
    selectedBy?: string[];
}

export interface LCESuggestionCreate {
    anonymous?: boolean;
    subject?: string;
    message: string;
    targetMunicipalityCodes?: string[];
    targetAllMunicipalities?: boolean;
}

export interface LCESuggestionPartial {
    id: string;
    code: string;
    status: LCESuggestionStatus;
    anonymous?: boolean;
    createdOn: string;
}

export interface LCESuggestion extends XSPKDTOAuditFull<LCEUserPartial> {
    code: string;
    author?: LCEUserPartial;
    anonymous?: boolean;

    status: LCESuggestionStatus;

    subject?: string;
    message: string;

    targetMunicipalities?: LCEMunicipalityPartial[];
    targetAllMunicipalities?: boolean;

    readOn?: string;
    readBy?: LCEUserPartial;

    unreadOn?: string;
    unreadBy?: LCEUserPartial;

    consideredOn?: string;
    consideredBy?: LCEUserPartial;

    selectedOn?: string;
    selectedBy?: LCEUserPartial;
}

export enum LCESuggestionStatus {
    READ = 'read',
    UNREAD = 'unread',
    CONSIDERED = 'considered',
    SELECTED = 'selected'
}
