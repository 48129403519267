import {Component, Input, OnInit} from '@angular/core';
import {
    LCE_TR_BASE_DELIVERY_DESTINATION_TYPE,
    LCEDeliveryDestination,
    LCEDeliveryDestinationCustomLocation,
    LCEDeliveryDestinationFacility,
    LCEDeliveryDestinationInternational,
    LCEDeliveryDestinationServicePoint,
    LCEDeliveryDestinationType,
    LCEFacilityPartial,
    LCEServicePointPartial
} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-delivery-destination',
    templateUrl: './lce-delivery-destination.component.html'
})
export class LCEDeliveryDestinationComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    TR_BASE_DELIVERY_DESTINATION_TYPE = LCE_TR_BASE_DELIVERY_DESTINATION_TYPE;

    @Input() styleClass?: string;

    @Input() data: LCEDeliveryDestination;

    @Input() full?: boolean;

    @Input() showOverlay?: boolean;

    get destinationInternational(): LCEDeliveryDestinationInternational {
        return this.data as LCEDeliveryDestinationInternational;
    }

    get destinationCustomLocation(): LCEDeliveryDestinationCustomLocation {
        return this.data as LCEDeliveryDestinationCustomLocation;
    }

    get destinationServicePoint(): LCEDeliveryDestinationServicePoint<LCEServicePointPartial> {
        return this.data as LCEDeliveryDestinationServicePoint<LCEServicePointPartial>;
    }

    get destinationFacility(): LCEDeliveryDestinationFacility<LCEFacilityPartial> {
        return this.data as LCEDeliveryDestinationFacility<LCEFacilityPartial>;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.full)) this.full = false;
    }

    public onInternationalCoordinateClick(): void {
        const coordinate = this.destinationInternational.address.coordinate;
        console.log('[Delivery Destination] International Coordinate Clicked !', coordinate);
    }

    public onCustomLocationCoordinateClick(): void {
        const coordinate = this.destinationCustomLocation.address.coordinate;
        console.log('[Delivery Destination] Custom Location Coordinate Clicked !', coordinate);
    }

    public isDestinationInternational(): boolean {
        return this.data.type === LCEDeliveryDestinationType.INTERNATIONAL;
    }

    public isDestinationCustomLocation(): boolean {
        return this.data.type === LCEDeliveryDestinationType.CUSTOM_LOCATION;
    }

    public isDestinationServicePoint(): boolean {
        return this.data.type === LCEDeliveryDestinationType.SERVICE_POINT;
    }

    public isDestinationFacility(): boolean {
        return this.data.type === LCEDeliveryDestinationType.FACILITY;
    }
}
