import {Injectable} from '@angular/core';
import {XSAssert} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEBirthDeclarationParentFormComponent} from './create-update/lce-birth-declaration-parent-create-update.component';
import {LCEBirthDeclarationParentComponent} from './lce-birth-declaration-parent.component';
import {LCEBirthDeclarationParent} from '@lce/core';

export interface LCEBirthDeclarationParentDialogCreateUpdateArg {
    parent?: LCEBirthDeclarationParent;
    birthDeclarationNumber?: string;
    parentKey: 'mother' | 'father';
    birthDeclarationID?: string;
    showCollapse?: boolean;
    showBorder?: boolean;
    showRecordAfterSave?: boolean;
    onCancel?: () => void;
    onClose?: (parent?: LCEBirthDeclarationParent) => void;
    onSave?: (parent?: LCEBirthDeclarationParent) => void;
}

export interface LCEBirthDeclarationParentDialogRecordArg {
    parent?: LCEBirthDeclarationParent;
    parentKey: 'mother' | 'father';
    birthDeclarationNumber?: string;
    showEditButton?: boolean;
    showBorder?: boolean;
    showCollapse?: boolean;
    onClose?: (parent?: LCEBirthDeclarationParent) => void;
    onEdit?: (parent?: LCEBirthDeclarationParent) => void;
}

@Injectable()
export class LCEBirthDeclarationParentDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEBirthDeclarationParentDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');

        const dConfig: XSDialogConfig = {
            data: {
                parent: arg.parent,
                parentKey: arg?.parentKey,
                showEditButton: arg.showEditButton,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600',
                birthDeclarationNumber: arg?.birthDeclarationNumber
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-900'
        };

        const recordComponent: LCEBirthDeclarationParentComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationParentComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedParentRecord: LCEBirthDeclarationParent) => {
            if (arg?.onClose) arg!.onClose!(savedParentRecord);
        });
        recordComponent.editEvent.subscribe((parentRecord: LCEBirthDeclarationParent) => {
            if (arg?.onEdit) arg.onEdit(parentRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    parent: parentRecord,
                    parentKey: arg.parentKey,
                    onClose: (parent) => this.openRecordDialog({parent: parent, showEditButton: true, parentKey: arg.parentKey}),
                    onSave: (parent) => this.openRecordDialog({parent: parent, showEditButton: true, parentKey: arg.parentKey})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEBirthDeclarationParentDialogCreateUpdateArg): void {
        const dConfig: XSDialogConfig = {
            data: {
                parentKey: arg?.parentKey,
                parent: arg?.parent,
                birthDeclarationID: arg?.birthDeclarationID,
                birthDeclarationNumber: arg?.birthDeclarationNumber,
                styleClass: 'xs-width-full ',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-500',
                showHeader: false,
                showFooter: false
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEBirthDeclarationParentFormComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationParentFormComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });

        createUpdateComponent.closeEvent.subscribe((parent: LCEBirthDeclarationParent) => {
            if (arg?.onClose) arg!.onClose!(parent);
        });

        createUpdateComponent.saveEvent.subscribe((parent: LCEBirthDeclarationParent) => {
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(parent);
            if (arg?.showRecordAfterSave === true)
                this.openRecordDialog({
                    parent: parent,
                    showEditButton: false,
                    parentKey: arg.parentKey,
                    showCollapse: false,
                    showBorder: false,
                    birthDeclarationNumber: arg?.birthDeclarationNumber
                });
        });
    }
}
