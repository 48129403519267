<div class="xs-flex-column xs-width-full xs-min-width-500 {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div *ngIf="canDisplayData()" class="xs-flex-column-center">
        <lce-certificate-order-head [qrCode]="data.qrCode"></lce-certificate-order-head>
    </div>

    <div class="xs-position-relative xs-flex-column xs-width-full xs-min-height-400 xs-height-full xs-mt-50">
        <div class="xs-absolute-center-vh">
            <xs-loader #recordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
        </div>

        <div *ngIf="!recordLoader.isRunning() && hasError()" class="xs-absolute-center-vh xs-width-full">
            <xs-error
                    [actionButton]="errorRetryButton"
                    [error]="error"
                    [showBorder]="false"
                    [showErrorDetailButton]="true"
                    message="{{ TR_BASE }}errorRetrieveCertificateOrder"
                    mode="block"
                    subMessage="xs.core.error.contactAdminMessage">
            </xs-error>
        </div>
        <div *ngIf="canDisplayData()" class="xs-flex-column xs-height-full">
            <p-tabView #pTabView [scrollable]="navScrollable" class="xs-tabview" styleClass="{{ navScrollable ? '' : 'xs-tabview-nav-center' }}">
                <p-tabPanel header="{{ TR_BASE + 'general' | translate }}">
                    <div class="xs-flex-column xs-min-height-600">
                        <lce-certificate-order-general (statusUpdatedEvent)="onStatusUpdatedEvent($event)" [data]="data" [readonly]="readonly" [stampTokenClickable]="stampTokenClickable"></lce-certificate-order-general>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="{{ TR_BASE + 'delivery' | translate }}">
                    <div class="xs-flex-column xs-min-height-600">
                        <lce-certificate-order-delivery [data]="data" [readonly]="readonly"></lce-certificate-order-delivery>
                    </div>
                </p-tabPanel>

                <p-tabPanel header="{{ TR_BASE + 'timeline.title' | translate }}">
                    <div class="xs-flex-column xs-min-height-600">
                        <lce-certificate-order-timeline [data]="data" [readonly]="readonly"></lce-certificate-order-timeline>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON.certificateOrder"
            [line2]="headerSubTitle"
            line1="lce.core.label.order"
    ></lce-record-header-component>
</ng-template>
