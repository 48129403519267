export interface XSPermissionBase {
    create?: boolean;
    read?: boolean;
    update?: boolean;
    deactivate?: boolean;
    activate?: boolean;
    delete?: boolean;
    restore?: boolean;
    purge?: boolean;
}

export enum XSPermissionBaseKey {
    CREATE = 'create',
    READ = 'read',
    UPDATE = 'update',
    DELETE = 'delete',
    ACTIVATE = 'activate',
    DEACTIVATE = 'deactivate',
    RESTORE = 'restore',
    PURGE = 'purge',
}

export type XSPermissionBaseCanShow = {
    [key in XSPermissionBaseKey]?: boolean;
};

// ngIf="canShow.create"

// ------------------------------------------------------------------------------
// --- *** ----
// ------------------------------------------------------------------------------


export interface LCEPermission {
    audit?: XSPermissionBase;
    settings?: LCEPermissionSettings;
    search?: LCEPermissionSearch;
    dashboard?: LCEPermissionDashboard;
}

export enum LCEPermissionKey {

}

// ------------------------------------------------------------------------------
// --- *** ----
// ------------------------------------------------------------------------------


export interface LCEPermissionSearch {
}

export interface LCEPermissionDashboard {
}

export interface LCEPermissionSettings {
}