import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LCEDistrictsFeatureService} from '@lce/shared';
import {LCECMDDistrictsComponent} from './lce-cmd-districts.component';
import {LCECMDDistrictsRoutingModule} from './lce-cmd-districts-routing.module';
import {LCECMDSharedModule} from '../../shared/lce-cmd-shared.module';

@NgModule({
    imports: [CommonModule, LCECMDSharedModule, LCECMDDistrictsRoutingModule],
    declarations: [LCECMDDistrictsComponent],
    exports: [
        LCECMDDistrictsComponent
    ],
    providers: [LCEDistrictsFeatureService]
})
export class LCECMDDistrictsModule {
}
