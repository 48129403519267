<div class="xs-flex-column xs-width-full xs-height-full">
    <div class="xs-card">
        <div class="xs-card-header xs-container-left-stripe-secondary">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                <div class="xs-flex-colum">
					<span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp">
						{{ TR_BASE + 'authentication' | translate }}
					</span>
                </div>
            </div>
        </div>
        <div class="xs-card-content xs-pt-50-imp">
            <div class="grid">
                <div class="col-12">
                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="pinCodeContainer" styleClass="xs-mb-15" text="{{ TR_BASE }}pinCode">
                    </xs-title>
                </div>

                <div #pinCodeContainer class="col-12 grid">
                    <div class="col-12 md:col-6 xs-min-height-125">
                        <xs-data-field-text [value]="pinCodeValue" label="{{ TR_BASE }}pinCode" styleClass="xs-data-field-spacer-20" valueStyleClass="xs-font-size-medium">
                            <ng-template xsTemplate="below">
                                <div class="xs-flex-column xs-mt-5">
                                    <xs-button
                                            (clickEvent)="onPINCodeShow()"
                                            [disabled]="(pinCodeValue !== PIN_CODE_HIDDEN_VALUE) || loading.generatePINCode"
                                            [loading]="loading.retrievePINCode"
                                            [loading]="loading.retrievePINCode"
                                            label="{{ TR_BASE }}showFiveSeconds"
                                            loaderPosition="right"
                                            size="small"
                                            type="text">
                                    </xs-button>
                                    <lce-user-resource-audit-inline
                                            *ngIf="data.pinCodeUpdatedOn | xsIsNotEmpty"
                                            [by]="data.pinCodeUpdatedBy"
                                            [on]="data.pinCodeUpdatedOn"
                                            onPrefix="xs.core.audit.updatedOn"
                                            styleClass="xs-mt-8 xs-color-secondary xs-font-weight-500">
                                    </lce-user-resource-audit-inline>
                                </div>
                            </ng-template>
                        </xs-data-field-text>
                    </div>

                    <div class="col-12 md:col-6">

                        <xs-button
                                (clickEvent)="generateNewPINCode()"
                                [icon]="ICON.redo"
                                [loading]="loading.generatePINCode"
                                label="{{ TR_BASE }}generateNewPINCode"
                                loaderPosition="bottom"
                                size="intermediate"
                                type="text">
                        </xs-button>
                        <span class="xs-display-block xs-color-secondary xs-font-size-small xs-mt-5">
                            {{ 'lce.shared.user.label.generateNewPINCode' | translate }}
                        </span>
                    </div>
                </div>

                <div class="col-12">
                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="passwordContainer" styleClass="xs-mb-15" text="{{ TR_BASE }}password"></xs-title>
                </div>
                <div #passwordContainer class="col-12 grid">
                    <div class="col-12 md:col-6">
                        <xs-data-field-calendar
                                [mediumLongDateTime]="true"
                                [required]="false"
                                [timeAgoLive]="true"
                                [timeAgo]="true"
                                [value]="data.passwordUpdatedOn"
                                label="{{ TR_BASE }}lastPasswordUpdate"
                                styleClass="xs-data-field-spacer-20">
                        </xs-data-field-calendar>
                    </div>

                    <div class="col-12 md:col-6 xs-min-height-125">
                        <lce-data-field-user-resource-audit-inline
                                [by]="data.passwordResetBy"
                                [on]="data.passwordResetOn"
                                label="Last Password Reset"
                                label="Last Password Reset">
                        </lce-data-field-user-resource-audit-inline>
                        <div *ngIf="canResetPassword()" class="xs-flex-column xs-mtb-30-imp">
                            <xs-button
                                    (clickEvent)="resetPassword()"
                                    [icon]="ICON.redo"
                                    [loading]="loading.resetPassword"
                                    label="{{ TR_BASE }}resetPassword"
                                    loaderPosition="bottom"
                                    size="intermediate"
                                    type="text">
                            </xs-button>
                            <span class="xs-display-block xs-color-secondary xs-font-size-small xs-mt-5">
                                {{ 'lce.shared.user.label.resetPasswordDescription' | translate }}
                            </span>
                        </div>
                    </div>
                    <!--
                    <div class="col-12">
                        <xs-title [showSeparator]="false" text="{{ TR_BASE }}changePassword"></xs-title>

                        <xs-authentication-update-password
                                (submitEvent)="onUpdatePassword($event)"
                                [loading]="loading.updatePassword"
                                contentStyleClass="xs-p-0-imp"
                                styleClass="xs-mtb-30">
                        </xs-authentication-update-password>
                    </div>
                    -->
                </div>

                <div class="col-12">
                    <xs-title [expandCollapseEnable]="true" [expandCollapseTarget]="loginHistoryContainer" styleClass="xs-mb-15" text="{{ TR_BASE }}loginHistory">
                    </xs-title>
                </div>

                <div #loginHistoryContainer class="col-12">
                    <xs-authentication-login-attempt-history [loginAttempts]="[]"></xs-authentication-login-attempt-history>
                </div>
            </div>
        </div>
    </div>
</div>
