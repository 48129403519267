import {Injectable} from '@angular/core';
import {LCEMBOAppConfig} from '@lce-mbo/domain/lce-mbo-app-config';
import {LCEContextData, LCEFacilityPartial, LCEMunicipalitySettings, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {XSAssert} from '@xs/base';
import {XSGeolocationService} from '@xs/core';
import {LCESharedContextService} from '@lce/shared';

@Injectable({providedIn: 'root'})
export class LCEMBOContextService extends LCESharedContextService {
    
    private facility: LCEFacilityPartial;

    private municipalitySettings: LCEMunicipalitySettings;

    constructor(protected geolocationService: XSGeolocationService) {
        super(geolocationService);
    }

    public getContextData(): LCEContextData {
        return {
            municipalityCode: this.facility.municipality.code,
            municipalityName: this.facility.municipality.name,
            facilityCode: this.facility.code,
            facilityName: this.facility.name
        };
    }

    // --- Municipality Settings ----

    public setMunicipalitySettings(municipalitySettings: LCEMunicipalitySettings): void {
        XSAssert.notEmpty(municipalitySettings, 'municipalitySettings');
        this.municipalitySettings = municipalitySettings;
    }

    public getMunicipalitySettings(): LCEMunicipalitySettings {
        return this.municipalitySettings;
    }

    // --- Municipality ----

    public getMunicipalityFullName(): string {
        return this.facility.municipality.fullName;
    }

    public getMunicipalityName(): string {
        return this.facility.municipality.name;
    }

    public getMunicipalityCode(): string {
        return this.facility.municipality.code;
    }

    // --- Facility ----

    public getFacilityName(): string {
        return this.facility.name;
    }

    public getFacilityCode(): string {
        return this.facility.code;
    }

    public getFacility(): LCEFacilityPartial {
        return this.facility;
    }

    public setFacility(facility: LCEFacilityPartial): void {
        XSAssert.notEmpty(facility, 'facility');
        this.facility = facility;
    }

    // --- User ----

    public setUser(user: LCEUserMunicipalEmployeePartial): void {
        super.setUser(user);
    }

    public getUser(): LCEUserMunicipalEmployeePartial {
        return super.getUser() as LCEUserMunicipalEmployeePartial;
    }

    // --- Config ----

    public getAppConfig(): LCEMBOAppConfig {
        return super.getAppConfig() as LCEMBOAppConfig;
    }

    public setAppConfig(appConfig: LCEMBOAppConfig): void {
        super.setAppConfig(appConfig);
    }
}
