import {AfterViewInit, ApplicationRef, Component, HostListener, OnInit} from '@angular/core';
import {XSLanguage} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSDeviceDetectorService, XSGlobalToastKey, XSScheme, XSSchemeService, XSScreenService} from '@xs/core';
import {PrimeNGConfig} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {LCEBreadcrumbService} from '@lce/shared';

@Component({
    selector: 'lce-cmd-root',
    template: `
        <router-outlet></router-outlet>
        <xs-loader-main></xs-loader-main>
        <p-toast [key]="TOP_RIGHT_TOAST_KEY" position="top-right"></p-toast>
        <p-toast [key]="BOTTOM_RIGHT_TOAST_KEY" position="bottom-right"></p-toast>
    `
})
export class LCECMDComponent implements OnInit, AfterViewInit {
    readonly TOP_RIGHT_TOAST_KEY: string = XSGlobalToastKey.TOP_RIGHT.toString();
    readonly BOTTOM_RIGHT_TOAST_KEY: string = XSGlobalToastKey.BOTTOM_RIGHT.toString();

    private readonly ENABLE_RIPPLE = true;

    constructor(
        applicationRef: ApplicationRef,
        dialogService: DialogService,
        private primengConfig: PrimeNGConfig,
        private translationService: XSTranslationService,
        private deviceDetectorService: XSDeviceDetectorService,
        private screenService: XSScreenService,
        private schemeService: XSSchemeService,
        private breadcrumbService: LCEBreadcrumbService) {
        // Workaround allowing to deal with the issue appRef.attachView is not a function, due to the fact that appRef is empty({}).
        dialogService['appRef'] = applicationRef;
    }

    ngOnInit() {
        this.primengConfig.ripple = this.deviceDetectorService.isMobile() ? false : this.ENABLE_RIPPLE;
        this.breadcrumbService.setMessage({text: {value: 'lce.shared.label.pilotPhaseMessage'}, closable: true});
    }

    ngAfterViewInit(): void {
        this.schemeService.updateSchemeFromLocalStorage();
    }

    @HostListener('window:resize')
    onResize() {
        this.screenService.handleWindowResizeEvent();
    }

    @HostListener('document:keydown.control.l')
    onCtrlL() {
        const cLanguage: XSLanguage = this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XSLanguage.FRENCH : XSLanguage.ENGLISH;
        this.translationService.changeCurrentLanguage(cLanguage).subscribe();
    }

    @HostListener('document:keydown.control.t')
    onCtrlT() {
        const cScheme: XSScheme = this.schemeService.getCurrentScheme() === XSScheme.LIGHT ? XSScheme.DARK : XSScheme.LIGHT;
        this.schemeService.changeScheme(cScheme);
    }
}
