<div class="xs-flex-column {{styleClass}}">
    <lce-delivery-general
            [data]="data"
            [showBorder]="showBorder"
            [showHeader]="showHeader">
    </lce-delivery-general>
    <lce-delivery-assignment
        #deliveryAssignment
        *ngIf="data.assignment | xsIsNotEmpty"
        [assignmentID]="data.assignment!.id"
        [showBorder]="showBorder"
        [showHeader]="showHeader"
        styleClass="xs-mt-50">
    </lce-delivery-assignment>
</div>