import { Component, Input, OnInit } from '@angular/core';
import { XSAssert } from '@xs/base';
import { LCEEventType } from '@lce/core';

@Component({ selector: 'lce-event-name-type', templateUrl: './lce-event-name-type.component.html' })
export class LCEEventNameTypeComponent implements OnInit {
    readonly TR_BASE = 'lce.shared.event.';

    @Input() styleClass?: string;
    @Input() eventNameStyleClass?: string;
    @Input() typeStyleClass?: string;

    @Input() eventName: string;
    @Input() type: LCEEventType;

    ngOnInit(): void {
        XSAssert.notEmpty(this.eventName, 'eventName');
        XSAssert.notEmpty(this.type, 'type');
    }
}
