<div
        (click)="onCardClick($event)"
        [ngClass]="{ 'xs-disabled': disabled === true }"
        [style.background-image]="getBackgroundImage()"
        [style.background-position]="'center'"
        [style.background-repeat]="'no-repeat'"
        [style.background-size]="'cover'"
        class="lce-news-article-card xs-cursor-pointer {{ styleClass }}"
>
  
    <div class="xs-position-absolute xs-bottom-0 xs-color-primary-contrast">
        <div class="lce-news-article-card-center">
            <lce-smartcity-article-type
                    [darkness]="true"
                    [small]="true"
                    [type]="data.type"
                    styleClass="xs-mb-15">
            </lce-smartcity-article-type>

            <span (click)="onCardClick($event)" [ngClass]="{ 'xs-cursor-pointer': isClickable() }" class="lce-news-article-card-title xs-cursor-pointer xs-font-size-default-imp">
            {{ data.title | translate }}
        </span>
            <span *ngIf="data.publishedOn | xsIsNotEmpty" class="lce-news-article-card-created-on">{{ data.publishedOn! | xsDate : dateFormat }}</span>
            <span *ngIf="data.publishedOn | xsIsEmpty" class="lce-news-article-card-created-on">{{ data.createdOn | xsDate : dateFormat }}</span>
            <span class="lce-news-article-card-description">{{ data.description! | translate }}</span>
        </div>

        <lce-smartcity-article-stats-icons
                [readCount]="data.readCount"
                [scanCount]="data.scanCount"
                [shareCount]="data.shareCount"
                styleClass="xs-width-full xs-text-secondary-color xs-plr-15 xs-ptb-10 xs-color-primary-contrast">
        </lce-smartcity-article-stats-icons>
    </div>
</div>