import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCE_TR_BASE_NOTIFICATION_CHANNEL, LCECertificateOrderDeliveryOperationRequest, LCENotificationChannel} from '@lce/core';
import {XSInputFieldCheckboxesOptions, XSInputFieldTextAreaOptions, XSInputFieldTextOptions} from '@xs/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSUtils} from '@xs/base';

@Component({
    selector: 'lce-delivery-assignment-request-operation',
    templateUrl: './lce-delivery-assignment-request-operation.component.html'
})
export class LCEDeliveryAssignmentRequestOperationComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() operationRequest?: LCECertificateOrderDeliveryOperationRequest;

    @Output() changeEvent = new EventEmitter<LCECertificateOrderDeliveryOperationRequest>();

    formGroup: FormGroup = new FormGroup({});
    notifyDeliveryManAfterNSecondsField: XSInputFieldTextOptions;
    notifyChannelsField: XSInputFieldCheckboxesOptions;
    noteField: XSInputFieldTextAreaOptions;

    ngOnInit(): void {
        this.buildFields();
    }

    private buildFields(): void {

        this.notifyChannelsField = {
            fieldName: 'notifyChannels',
            label: 'lce.core.label.notifyChannels',
            control: new FormControl(this.operationRequest?.notifyChannels),
            gridStyleClass: 'grid-nogutter',
            colStyleClass: 'lg:col-3 xs-mb-6',
            checkboxes: [
                {label: LCE_TR_BASE_NOTIFICATION_CHANNEL + LCENotificationChannel.SMS, checkedValue: LCENotificationChannel.SMS},
                {label: LCE_TR_BASE_NOTIFICATION_CHANNEL + LCENotificationChannel.EMAIL, checkedValue: LCENotificationChannel.EMAIL},
                {label: LCE_TR_BASE_NOTIFICATION_CHANNEL + LCENotificationChannel.PUSH, checkedValue: LCENotificationChannel.PUSH},
                {label: LCE_TR_BASE_NOTIFICATION_CHANNEL + LCENotificationChannel.WEBSOCKET, checkedValue: LCENotificationChannel.WEBSOCKET}
            ],
            valueChange: () => {
                this.emitValue();
            }
        };
        this.notifyDeliveryManAfterNSecondsField = {
            fieldName: 'notifyDeliveryManAfterNSeconds',
            label: 'lce.shared.delivery.request.notifyDeliveryManAfterNSeconds',
            control: new FormControl(this.operationRequest?.notifyDeliveryManAfterNSeconds, [Validators.min(30), Validators.max(3600)]),
            type: 'number',
            belowText: 'lce.shared.delivery.request.minMaxSecond',
            valueChange: () => {
                this.emitValue();
            }
        };
        this.noteField = {
            fieldName: 'note',
            placeholder: 'Note ...',
            control: new FormControl(this.operationRequest?.note),
            rows: 2,
            valueChange: () => {
                this.emitValue();
            }
        };

        this.formGroup.addControl(this.notifyChannelsField.fieldName, this.notifyChannelsField.control!);
        this.formGroup.addControl(this.notifyDeliveryManAfterNSecondsField.fieldName, this.notifyDeliveryManAfterNSecondsField.control!);
        this.formGroup.addControl(this.noteField.fieldName, this.noteField.control!);
    }

    private emitValue(): void {
        this.operationRequest = {
            ...this.formGroup.value,
            notifyDeliveryManAfterNSeconds: XSUtils.parseInt(this.notifyDeliveryManAfterNSecondsField.control?.value)
        };
        this.changeEvent.emit(this.operationRequest);
    }

}