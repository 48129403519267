import { XSAddress, XSAssert, XSContactPerson, XSPKResourceAuditFullService } from '@xs/base';
import { LCEUser, LCEUserCreate, LCEUserPartial, LCEUserSearch } from '../../domain/user/lce-user';
import { LCEHttpClientService } from '../lce-http-client.service';
import { Observable } from 'rxjs';
import { LCEValidatorUtils } from '../../utils/lce-validator-utils';

export abstract class LCEUserService<R extends LCEUser, P extends LCEUserPartial, S extends LCEUserSearch> extends XSPKResourceAuditFullService<R, P, S> {
    protected constructor(protected httpClientService: LCEHttpClientService, protected endpointIndex: string, protected resourceName: string) {
        super(httpClientService, endpointIndex, resourceName);
    }

    public updateUserEmergencyContactPerson(userID: string, contactPerson: XSContactPerson | undefined): Observable<LCEUser> {
        return this.update(userID, new Map<string, any>().set('emergencyContactPerson', contactPerson));
    }

    public updateAddress(userID: string, address: XSAddress | undefined): Observable<{ id: string; address?: XSAddress; updatedOn: string }> {
        XSAssert.notEmpty(userID, 'userID');
        return this.updateF(userID, { address: address }, ['id', 'address', 'updatedOn']);
    }

    public updateNote(userID: string, note: string | undefined): Observable<{ id: string; note?: string; updatedOn: string }> {
        XSAssert.notEmpty(userID, 'userID');
        return this.updateF(userID, { note: note }, ['id', 'note', 'updatedOn']);
    }

    public create(userCreate: LCEUserCreate): Observable<LCEUser> {
        LCEValidatorUtils.validateUserCreate(userCreate);
        return this.httpClientService.post<LCEUser>(this.buildEndpoint(), userCreate);
    }
}
