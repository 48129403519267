import {Pipe, PipeTransform} from '@angular/core';
import {XS_STR_EMPTY, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE, LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE} from '../constants/lce-core.constant';
import {LCEUserType} from '../domain/user/lce-user';
import {LCEUserUtils} from '../utils/lce-user-utils';

@Pipe({name: 'lceUserMainRole', pure: false})
export class LCEUserMainRolePipe implements PipeTransform {
    constructor(private translationService: XSTranslationService) {
    }

    public transform(value: string | undefined, userType: LCEUserType): string {
        return this.translateUserMainRole(value, userType);
    }

    private translateUserMainRole(userMainRole: string | undefined, userType: LCEUserType): string {
        if (XSUtils.isEmpty(userMainRole)) return XS_STR_EMPTY;
        const trKey = this.getTRBase(userType) + userMainRole;
        const trValue = this.translationService.translateKey(trKey);
        return trKey === trValue ? userMainRole! : trValue;
    }

    private getTRBase(userType: LCEUserType): string {
        if (LCEUserUtils.isUserEmployee(userType)) return LCE_TR_BASE_USER_EMPLOYEE_MAIN_ROLE;
        else return LCE_TR_BASE_USER_MUNICIPAL_EMPLOYEE_MAIN_ROLE;
    }
}
