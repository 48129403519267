import {HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEUserDeliveryMan, LCEUserDeliveryManCreate, LCEUserDeliveryManPartial, LCEUserDeliveryManSearch, LCEUserType} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockUserAccountHandler} from '@lce/mock/core/handlers/user/lce-mock-user-account-handler';
import {LCEMockUserFinder} from '@lce/mock/core/handlers/user/lce-mock-user-finder';
import {LCEMockUserHandler} from '@lce/mock/core/handlers/user/lce-mock-user-handler';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '@lce/mock/core/lce-mock.constant';
import {XSHttpMethod, XSPKDTOStats, XSUtils} from '@xs/base';
import {LCEMockCommonHandler} from '@lce/mock/core/handlers/lce-mock-common-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.delivery-men';

@Injectable()
export class LCEMockUserDeliveryManHandler extends LCEMockUserHandler<LCEUserDeliveryMan, LCEUserDeliveryManPartial, LCEUserDeliveryManSearch> {

    public static DELIVERY_MAN_STORAGE: Map<string, LCEUserDeliveryMan> = new Map<string, LCEUserDeliveryMan>();

    constructor(
        protected contextService: LCECoreContextService,
        protected mockMunicipalityHandler: LCEMockMunicipalityHandler,
        protected mockUserAccountHandler: LCEMockUserAccountHandler,
        private mockCommonHandler: LCEMockCommonHandler) {

        super(contextService, mockMunicipalityHandler, mockUserAccountHandler, DATASET_BASE_ID, LCE_CORE_ENDPOINTS.deliveryMen.index);
        this.mockDataArray = [];
        LCEMockUserFinder.USER_HANDLER_MAP.set(LCEUserType.DELIVERY_MAN, this);

        this.mockCommonHandler.onDeliveryAssignment.subscribe(event => {
            if (XSUtils.isNull(event)) return;
            const deliveryMan = this.findOneByID(event.assignmentResponse.assignedTo.id);

        });
    }

    public getStorage(): Map<string, LCEUserDeliveryMan> {
        return LCEMockUserDeliveryManHandler.DELIVERY_MAN_STORAGE;
    }

    public toPartial(deliveryMan: LCEUserDeliveryMan): LCEUserDeliveryManPartial {
        return {
            ...this.baseToPartial(deliveryMan)
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.deliveryMen.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.getQueryPredicates()}),
            // Search
            this.buildSearchMockData({
                queryPredicates: this.getQueryPredicates(),
                predicates: this.getSearchPredicates()
            }),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: XSUtils.randomInteger(0, 100),
                    active: XSUtils.randomInteger(0, 60),
                    inactive: XSUtils.randomInteger(0, 10),
                    deleted: XSUtils.randomInteger(0, 5)
                } as XSPKDTOStats
            }
        ];
    }

    protected buildCode(): string {
        return 'LCE-DEM-' + XSUtils.randomDigits(6);
    }

    private createResponseData(body: any): LCEUserDeliveryMan {
        const deliveryManCreate: LCEUserDeliveryManCreate = body as LCEUserDeliveryManCreate;
        const deliveryMan: LCEUserDeliveryMan = {
            ...this.baseCreate(deliveryManCreate),
            type: LCEUserType.DELIVERY_MAN
        };

        LCEMockUserDeliveryManHandler.DELIVERY_MAN_STORAGE.set(deliveryMan.id, deliveryMan);
        this.addUserMockData(deliveryMan.id);

        return deliveryMan;
    }

    private buildStorage(n: number = 10): void {
        for (let $i = 0; $i < n; $i++) {
            const deliveryMan = this.buildRandomDeliveryMan();
            LCEMockUserDeliveryManHandler.DELIVERY_MAN_STORAGE.set(deliveryMan.id, deliveryMan);
            this.addUserMockData(deliveryMan.id);
        }
    }

    private buildRandomDeliveryMan(): LCEUserDeliveryMan {
        return {
            ...this.baseRandomUser(),
            type: LCEUserType.DELIVERY_MAN
        };
    }
}
