<xs-ragnar
        (line1ClickEvent)="onOrderNumberClick($event)"
        *ngIf="!detailed"
        [disabled]="disabled"
        [iconClickable]="false"
        [icon]="showIcon ? {value: ICON_CERTIFICATE_ORDER} : undefined"
        [line1Clickable]="clickable"
        [line1]="data.orderNumber"
        [line2]="data.status  | lceCertificateOrderStatus"
        [styleClass]="styleClass"
        iconStyleClass="{{ iconStyleClass }} xs-font-size-20-imp"
        line1StyleClass="xs-font-size-intermediate-imp {{ orderNumberStyleClass }}"
        line2StyleClass="xs-font-size-small-imp">
</xs-ragnar>

<div *ngIf="detailed" class="xs-flex-row xs-width-full xs-align-items-center xs-justify-content-space-between">
    <div class="grid grid-nogutter xs-width-full">
        <div class="col-6">
            <div class="xs-flex-row xs-align-items-center">
                <xs-square-data
                        [indicatorColorStyleClass]="colorStatusStyleClass"
                        [indicator]="detailedStatusIndicator"
                        [showBorder]="true"
                        styleClass="xs-mr-10"
                        value="{{ data.numberOfCopies }}"
                        valueContainerStyleClass="xs-width-40-imp xs-height-40-imp"
                        valueStyleClass="xs-font-size-medium-imp">
                </xs-square-data>
                <xs-ragnar
                        (line1ClickEvent)="onOrderNumberClick($event)"
                        [line1Clickable]="clickable"
                        [line1]="data.orderNumber | uppercase"
                        [line2]="data.status | lceCertificateOrderStatus"
                        line1StyleClass="xs-font-size-intermediate-imp xs-font-weight-500 {{ orderNumberStyleClass }}"
                        line2StyleClass="xs-font-size-intermediate-imp xs-color-secondary">
                </xs-ragnar>
            </div>
        </div>
        <div class="col-6 xs-pl-50-imp">
            <lce-certificate-partial [data]="data.certificate"></lce-certificate-partial>
        </div>
    </div>
</div>
