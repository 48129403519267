import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
    LCE_CERTIFICATE_ORDER_STATUS_ITEMS,
    LCE_CERTIFICATE_TYPE_ITEMS,
    LCE_TR_BASE_CORE_LABEL,
    LCECertificateOrderSearch
} from '@lce/core';
import {XSCount, XSInstantRange, XSUtils} from '@xs/base';
import {XS_GENDER_ITEMS, XS_TR_BASE_COMMON_LABEL} from '@xs/common';
import {
    XSAdvancedSearchOptions,
    XSAdvancedSearchSidebarHideEvent,
    XSInputFieldBaseOptions,
    XSInputFieldCalendarOptions,
    XSInputFieldMultiSelectOptions,
    XSInputFieldTextOptions
} from '@xs/core';
import {Observable, Subscription} from 'rxjs';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCECertificateOrdersFeatureService} from '../lce-certificate-orders-feature.service';

@Component({
    selector: 'lce-certificate-orders-advanced-search',
    templateUrl: './lce-certificate-orders-advanced-search.component.html'
})
export class LCECertificateOrdersAdvancedSearchComponent implements OnInit, OnDestroy {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = 'lce.shared.certificateOrders.advancedSearch.';

    @Input() searchText: string;

    @Input() showDistrictFields: boolean = false;

    @Output() searchTextChange = new EventEmitter<string>();

    @Output() sidebarShow = new EventEmitter<void>();

    @Output() sidebarHide = new EventEmitter<XSAdvancedSearchSidebarHideEvent>();

    orderNumberOptions: XSInputFieldTextOptions;
    orderStatusFieldOptions: XSInputFieldMultiSelectOptions;

    // --- * ---
    certificateReferenceNumberOptions: XSInputFieldTextOptions;
    certificateTypeFieldOptions: XSInputFieldMultiSelectOptions;
    certificateIssueDateStartFieldOptions: XSInputFieldCalendarOptions;
    certificateIssueDateEndFieldOptions: XSInputFieldCalendarOptions;
    certificatePersonFirstNameFieldOptions: XSInputFieldTextOptions;
    certificatePersonLastNameFieldOptions: XSInputFieldTextOptions;
    centerCodeFieldOptions: XSInputFieldTextOptions;
    centerNameFieldOptions: XSInputFieldTextOptions;
    districtCodeFieldOptions: XSInputFieldTextOptions;
    districtNameFieldOptions: XSInputFieldTextOptions;
    customerFirstNameFieldOptions: XSInputFieldTextOptions;
    customerLastNameFieldOptions: XSInputFieldTextOptions;
    customerGenderFieldOptions: XSInputFieldMultiSelectOptions;
    customerPrimaryPhoneNumberFieldOptions: XSInputFieldTextOptions;
    customerEmailFieldOptions: XSInputFieldTextOptions;
    createdOnStartFieldOptions: XSInputFieldCalendarOptions;
    createdOnEndFieldOptions: XSInputFieldCalendarOptions;
    updatedOnStartFieldOptions: XSInputFieldCalendarOptions;
    updatedOnEndFieldOptions: XSInputFieldCalendarOptions;

    formGroup: FormGroup = new FormGroup({});

    options: XSAdvancedSearchOptions;

    private fields: XSInputFieldBaseOptions[];

    private subscription: Subscription = new Subscription();

    constructor(private featureService: LCECertificateOrdersFeatureService) {
    }

    ngOnInit(): void {
        this.buildForm();
        this.buildOptions();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onSearchTextChange(event: string): void {
        this.searchTextChange.emit(event);
    }

    private searchCount(query: string | undefined): Observable<XSCount> {
        const certificateOrderSearch = this.toCertificateOrderSearch(this.formGroup.value, query);
        //LOG().debug('Advanced Search Filter(s) : ', certificateOrderSearch);
        console.log('Advanced Search Filter(s) : ', certificateOrderSearch);
        return this.featureService.countOrders(certificateOrderSearch);
    }

    private countFilters(): number {
        let count = 0;
        const formData = this.formGroup.value;
        for (let key in formData) {
            if (!XSUtils.isNull(formData[key])) count++;
        }
        return count;
    }

    private buildOptions(): void {
        this.options = {
            searchCount: (searchText) => this.searchCount(searchText),
            countFilters: () => this.countFilters()
        };
    }

    private toCertificateOrderSearch(formData: any, query: string | undefined): LCECertificateOrderSearch {
        const certificateOrderSearch: LCECertificateOrderSearch = {};
        if (XSUtils.isEmpty(formData) && XSUtils.isEmpty(query)) return certificateOrderSearch;
        if (!XSUtils.isEmpty(query)) certificateOrderSearch.query = query!.trim();
        if (XSUtils.isEmpty(formData)) return certificateOrderSearch;

        if (!XSUtils.isEmpty(formData.orderNumber)) {
            certificateOrderSearch.orderNumbers = XSUtils.split(formData.orderNumber, ',');
        }
        if (!XSUtils.isEmpty(formData.orderStatus)) {
            certificateOrderSearch.statuses = formData.orderStatus;
        }
        if (!XSUtils.isEmpty(formData.certificateReferenceNumber)) {
            certificateOrderSearch.certificateReferenceNumbers = XSUtils.split(formData.certificateReferenceNumber, ',');
        }
        if (!XSUtils.isEmpty(formData.certificateType)) {
            certificateOrderSearch.certificateTypes = formData.certificateType;
        }
        if (!XSUtils.isEmpty(formData.certificateIssueDateStart) || !XSUtils.isEmpty(formData.certificateIssueDateEnd)) {
            certificateOrderSearch.certificateIssueDateRange = {
                start: formData.certificateIssueDateStart?.toISOString(),
                end: formData.certificateIssueDateEnd?.toISOString()
            };
        }
        // --- Facility Codes ---
        if (!XSUtils.isEmpty(formData.centerCode)) {
            certificateOrderSearch.facilityCodes = XSUtils.split(formData.centerCode, ',');
        }
        // --- District ---
        if (!XSUtils.isEmpty(formData.districtCode)) {
            certificateOrderSearch.districtCodes = XSUtils.split(formData.districtCode, ',');
        }
        // --- Customer ---
        if (!XSUtils.isEmpty(formData.customerFirstName)) {
            certificateOrderSearch.customerFirstName = formData.customerFirstName;
        }
        if (!XSUtils.isEmpty(formData.customerLastName)) {
            certificateOrderSearch.customerLastName = formData.customerLastName;
        }
        if (!XSUtils.isEmpty(formData.customerPrimaryPhoneNumber)) {
            certificateOrderSearch.customerPrimaryPhoneNumber = formData.customerPrimaryPhoneNumber;
        }
        if (!XSUtils.isEmpty(formData.customerEmail)) {
            certificateOrderSearch.customerEmail = formData.customerEmail;
        }
        // --- Audit ---
        if (!XSUtils.isEmpty(formData.createdOnStart) || !XSUtils.isEmpty(formData.createdOnEnd)) {
            certificateOrderSearch.createdOnRange = new XSInstantRange(formData.createdOnStart?.toISOString(), formData.createdOnEnd?.toISOString());
        }
        if (!XSUtils.isEmpty(formData.updatedOnStart) || !XSUtils.isEmpty(formData.updatedOnEnd)) {
            certificateOrderSearch.updatedOnRange = new XSInstantRange(formData.updatedOnStart?.toISOString(), formData.updatedOnEnd?.toISOString());
        }

        return certificateOrderSearch;
    }

    private buildForm(): void {
        this.fields = [
            (this.orderNumberOptions = {
                fieldName: 'orderNumber',
                label: LCE_TR_BASE_CORE_LABEL + 'orderNumberShort',
                control: new FormControl()
            }),
            (this.orderStatusFieldOptions = {
                fieldName: 'orderStatus',
                label: LCE_TR_BASE_CORE_LABEL + 'orderStatus',
                control: new FormControl(),
                items: LCE_CERTIFICATE_ORDER_STATUS_ITEMS
            }),
            // --- Certificate ---
            (this.certificateReferenceNumberOptions = {
                fieldName: 'certificateReferenceNumber',
                label: LCE_TR_BASE_CORE_LABEL + 'referenceNumber',
                control: new FormControl()
            }),
            (this.certificateTypeFieldOptions = {
                fieldName: 'certificateType',
                label: LCE_TR_BASE_CORE_LABEL + 'certificateType',
                control: new FormControl(),
                items: LCE_CERTIFICATE_TYPE_ITEMS
            }),
            (this.certificateIssueDateStartFieldOptions = {
                fieldName: 'certificateIssueDateStart',
                label: this.TR_BASE + 'certificateIssueDateFrom',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            }),
            (this.certificateIssueDateEndFieldOptions = {
                fieldName: 'certificateIssueDateEnd',
                label: this.TR_BASE + 'certificateIssueDateTo',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            }),
            (this.certificatePersonFirstNameFieldOptions = {
                fieldName: 'certificatePersonFirstName',
                label: XS_TR_BASE_COMMON_LABEL + 'firstName',
                control: new FormControl()
            }),
            (this.certificatePersonLastNameFieldOptions = {
                fieldName: 'certificatePersonLastName',
                label: XS_TR_BASE_COMMON_LABEL + 'lastName',
                control: new FormControl()
            }),
            // --- Center ---
            (this.centerCodeFieldOptions = {
                fieldName: 'centerCode',
                label: XS_TR_BASE_COMMON_LABEL + 'code',
                control: new FormControl()
            }),
            (this.centerNameFieldOptions = {
                fieldName: 'centerName',
                label: XS_TR_BASE_COMMON_LABEL + 'name',
                control: new FormControl()
            }),
            // --- Customer ---
            (this.customerFirstNameFieldOptions = {
                fieldName: 'customerFirstName',
                label: XS_TR_BASE_COMMON_LABEL + 'firstName',
                control: new FormControl()
            }),
            (this.customerLastNameFieldOptions = {
                fieldName: 'customerLastName',
                label: XS_TR_BASE_COMMON_LABEL + 'lastName',
                control: new FormControl()
            }),
            (this.customerGenderFieldOptions = {
                fieldName: 'customerGender',
                label: XS_TR_BASE_COMMON_LABEL + 'gender',
                control: new FormControl(),
                items: XS_GENDER_ITEMS
            }),
            (this.customerPrimaryPhoneNumberFieldOptions = {
                fieldName: 'customerPrimaryPhoneNumber',
                label: XS_TR_BASE_COMMON_LABEL + 'primaryPhoneNumber',
                control: new FormControl()
            }),
            (this.customerEmailFieldOptions = {
                fieldName: 'customerEmail',
                label: XS_TR_BASE_COMMON_LABEL + 'email',
                control: new FormControl()
            }),
            // --- Created On & Updated On ---
            (this.createdOnStartFieldOptions = {
                fieldName: 'createdOnStart',
                label: this.TR_BASE + 'createdOnStart',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            }),
            (this.createdOnEndFieldOptions = {
                fieldName: 'createdOnEnd',
                label: this.TR_BASE + 'createdOnEnd',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            }),
            (this.updatedOnStartFieldOptions = {
                fieldName: 'updatedOnStart',
                label: XS_TR_BASE_COMMON_LABEL + 'updatedOnFrom',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            }),
            (this.updatedOnEndFieldOptions = {
                fieldName: 'updatedOnEnd',
                label: XS_TR_BASE_COMMON_LABEL + 'updatedOnTo',
                control: new FormControl(),
                calendarOptions: {showIcon: true}
            })
        ];

        // --- District ---
        if (this.showDistrictFields) {
            this.fields.push(
                (this.districtCodeFieldOptions = {
                    fieldName: 'districtCode',
                    label: LCE_TR_BASE_CORE_LABEL + 'districtCode',
                    control: new FormControl()
                }),
                (this.districtNameFieldOptions = {
                    fieldName: 'districtName',
                    label: LCE_TR_BASE_CORE_LABEL + 'districtName',
                    control: new FormControl()
                })
            );
        }

        this.fields.forEach((field) => this.formGroup.addControl(field.fieldName, field.control!));
    }
}
