<div [ngClass]="{'xs-card-border': showBorder}" class="xs-width-full xs-card  {{styleClass}}">
    <div *ngIf="!isDialog()" class="xs-card-header">
        <span class="xs-card-header-title {{titleStyleClass}}"> {{ title! | translate }}</span>
        <span class="xs-card-header-sub-title {{subtitleStyleClass}}"> {{ subtitle! | translate }}</span>
    </div>
    <div class="xs-card-content">
        <div class="grid">
            <div class="col-12 md:col-6">
                <div [ngClass]="{'xs-disabled': isRunning()}" class="xs-width-full xs-flex-column-center xs-border-discrete xs-border-radius-default xs-p-10">
                    <div class="grid">
                        <div class="col-12">
                            <lce-number-of-document
                                    (numberOfCopiesChange)="numberOfCopies = $event"
                                    belowText="lce.shared.label.numberOfCopies"
                                    numberStyleClass="xs-font-size-50-imp lce-cop-copies-number-title-color xs-mlr-30"
                                    size="large"
                                    styleClass="xs-mb-50">
                            </lce-number-of-document>
                        </div>

                        <div class="col-12">
                            <xs-input-field-dropdown [options]="certificateTypeField"></xs-input-field-dropdown>
                        </div>


                        <lce-certificate-order-create-delivery
                                [(formGroup)]="deliveryFields"
                                class="xs-width-full">
                        </lce-certificate-order-create-delivery>
                    </div>

                </div>

            </div>
            <div class="col-12 md:col-6">
                <div class="xs-flex-row-center">
                    <lce-certificate-order-price-details
                        #priceDetails
                        [orderPriceRequest]="orderPriceRequest"
                        [showDefaultMessage]="PriceDetailsShowDefaultMessage"
                        class="xs-width-full">
                    </lce-certificate-order-price-details>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!isDialog()" class="xs-card-footer xs-card-footer-separator xs-mt-0-imp">
        <ng-template [ngTemplateOutlet]="dFooter"></ng-template>
    </div>
</div>

<ng-template #dHeader>
    <div class="xs-flex-column">
        <span class="xs-display-block xs-font-size-intermediate {{titleStyleClass}}"> {{ title! | translate }}</span>
        <span class="xs-display-block xs-font-size-small {{subtitleStyleClass}}"> {{ subtitle! | translate }}</span>
    </div>

</ng-template>

<ng-template #dFooter>
    <div class="xs-flex-row xs-width-full xs-justify-content-end">
        <xs-button
                (clickEvent)="calculate()"
                [disabled]="isRunning()"
                [loading]="isRunning()"
                label="lce.shared.certificateOrders.priceEstimator.calculatePrice"
                type="button"
        ></xs-button>
    </div>
</ng-template>