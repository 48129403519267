import {HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCECoreContextService, LCEDistrict, LCEDistrictCreate, LCEDistrictPartial, LCEDistrictSearch, LCEUserPartial} from '@lce/core';
import {XSHttpMethod, XSPKDTOAuditFullState, XSPKDTOStats, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_DISTRICT_JSON from '../data/lce-districts.json';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '../lce-mock-user-data';
import {LCEMockUtils} from '../lce-mock-utils';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.districts';

@Injectable()
export class LCEMockDistrictHandler extends XSMockPKResourceAuditFullHandler<LCEDistrict, LCEDistrictPartial, LCEDistrictSearch> implements XSMockPKSearchable<LCEDistrictPartial> {
    public static DISTRICT_STORAGE: Map<string, LCEDistrict> = new Map<string, LCEDistrict>();

    private queryPredicates: XSMockSearchQueryPredicate<LCEDistrict>[] = [
        (district, query) => district.fullName.toLowerCase().includes(query),
        (district, query) => district.name.toLowerCase().includes(query),
        (district, query) => district.shortName?.toLowerCase().includes(query),
        (district, query) => district.abbreviation.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCEDistrict>[] = [(district, params) => this.httpParamArrayIncludes(params, 'codes', district.code)];

    constructor(private contextService: LCECoreContextService) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.districts.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCEDistrict> {
        return LCEMockDistrictHandler.DISTRICT_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(district: LCEDistrict): LCEDistrictPartial {
        return {
            id: district.id,
            code: district.code,
            name: district.name,
            abbreviation: district.abbreviation,
            fullName: district.fullName,
            shortName: district.shortName
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            //Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: this.AUDIT_FULL_ENDPOINTS.create!,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: (rArg) => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Stats
            {
                id: DATASET_BASE_ID + '.stats',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: this.AUDIT_FULL_ENDPOINTS.stats,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 1000,
                responseData: {
                    total: 14,
                    active: 14,
                    inactive: 0,
                    deleted: 0
                } as XSPKDTOStats
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCEDistrictPartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    public getDistrictAbidjan(): LCEDistrictPartial {
        return this.findOneByCodeAsPartial('LCE-DST-ABJ');
    }

    private createResponseData(body: any): LCEDistrict {
        const districtCreate: LCEDistrictCreate = body as LCEDistrictCreate;
        const mapImage = 'assets/mock/images/districts/ci-create-district.webp';
        const district: LCEDistrict = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-DST-' + XSUtils.randomDigits(3),
            name: districtCreate.name,
            abbreviation: districtCreate.abbreviation,
            fullName: districtCreate.fullName,
            shortName: districtCreate.shortName,
            numberOfMunicipalities: districtCreate.numberOfMunicipalities,
            capital: districtCreate.capital,
            populationSize: districtCreate.populationSize,
            populationUpdateYear: districtCreate.populationUpdateYear,
            squareKM: districtCreate.squareKM,
            mapImage: districtCreate.mapImage ? districtCreate.mapImage : mapImage
        };

        LCEMockDistrictHandler.DISTRICT_STORAGE.set(district.id, district);
        this.addResourceBaseMockData(district.id);

        return district;
    }

    private buildStorage(): void {
        LCE_DISTRICT_JSON.forEach((jsonDistrict) => {
            const district: LCEDistrict = {
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                ...jsonDistrict
            };
            if (XSUtils.randomElement<boolean>([true, true, false])) {
                district.updatedBy = LCEMockUtils.randomUserMunicipalEmployee();
                district.updatedOn = new Date().toISOString();
            }
            LCEMockDistrictHandler.DISTRICT_STORAGE.set(district.id, district);
            this.addResourceBaseMockData(district.id);
        });
    }
}
