import {XSAddress, XSContactPerson, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSResourceAuditFullCanOptions} from '@xs/base';
import {LCEMunicipalityPartial} from './lce-municipality';
import {XSOpeningHours} from './xs';

export interface LCEServicePointCanOptions extends XSResourceAuditFullCanOptions {
}

export enum LCEServicePointEventName {
    NEW = 'servicePointNew',
}

export interface LCEServicePointSearch extends XSPKAuditFullSearchFilter {
    codes?: string;
    municipalityCodes?: string[];
    districtCodes?: string[];
    geoCircle?: string;
}

export interface LCEServicePointCreate {
    name: string;
    fullName: string;
    subName?: string;
    address: XSAddress;
    gmapLocation?: string;
    municipalityCode: string;
    contactPhoneNumber?: string;
    contactEmail?: string;
    primaryContactPerson: XSContactPerson;
    secondaryContactPerson?: XSContactPerson;
    openingHours?: XSOpeningHours;
    mainImage?: any;
    images?: any[];
}

export interface LCEServicePointPartial {
    id: string;
    code: string;
    name: string;
    fullName: string;
    subName?: string;
    municipality: LCEMunicipalityPartial;
}

export interface LCEServicePoint extends XSPKDTOAuditFull {
    code: string;
    name: string;
    fullName: string;
    subName?: string;
    address: XSAddress;
    gmapLocation?: string;
    municipality: LCEMunicipalityPartial;
    contactPhoneNumber?: string;
    contactEmail?: string;
    primaryContactPerson: XSContactPerson;
    secondaryContactPerson?: XSContactPerson;
    openingHours?: XSOpeningHours;
    mainImage?: string;
    images?: string[];
}
