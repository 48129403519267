import {Injectable} from '@angular/core';
import {LCECMDSharedService} from '../../core/services/lce-cmd-shared.service';

@Injectable({providedIn: 'root'})
export class LCECMDDashboardService {

    constructor(private sharedService: LCECMDSharedService) {
    }

    public emitRefresh() {
        this.sharedService.emitDashboardRefresh();
    }
}
