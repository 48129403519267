export const LCE_SHARED_ENDPOINT = {
    notifications: {
        index: 'notifications'
    },
    certificateOrderBatchProcesses: {
        index: 'certificate-orders/batch-processes',
        download: 'download',
        complete: 'complete',
        reopen: 'reopen'
    },
    dispatcher: {
        index: 'certificate-orders/dispatcher',
        assign: 'assign/deliveryMan/{deliveryManID}',
        unassign: 'unassign/deliveryMan/{deliveryManID}'
    },
    authenticationLoginAttempts: {
        index: 'authentication/login/attempts'
    },
    initialization: 'initialization',
    buildInfo: 'assets/build-info.json',
    actuatorInfo: 'actuator/info',
    geolocate: 'geolocate'
};
