import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCECoreContextService, LCEDeliveryCompany, LCEDeliveryCompanyCanOptions, LCEDeliveryCompanyService} from '@lce/core';
import {LOG, XSAddress, XSContactPerson, XSUtils} from '@xs/base';
import {XSLoaderService, XSSize} from '@xs/common';
import {XSAddressFieldOptions, XSAvatar, XSButton, XSContactPersonFieldOptions, XSDataManagerComponent, XSDialogable, XSPKResourceAuditFullMenuActionComponent} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: './lce-delivery-company-record',
    templateUrl: './lce-delivery-company-record.component.html',
    host: {class: 'xs-width-full'}
})
export class LCEDeliveryCompanyRecordComponent extends XSDialogable implements OnInit, OnDestroy {

    readonly ICON_DELIVERY_COMPANY: string = LCE_SHARED_ICON.deliveryCompany;
    readonly ICON_EDIT: string = LCE_SHARED_ICON.edit;
    readonly ICON_REDO: string = LCE_SHARED_ICON.redo;

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lce.shared.deliveryCompany.';

    readonly IMAGE_DEFAULT_SRC: string = 'assets/images/delivery/company.png';

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;
    @Input() deliveryCompanyID?: string;
    @Input() deliveryCompany?: LCEDeliveryCompany;
    @Input() editable?: boolean;

    @Input() dataManager?: XSDataManagerComponent;
    @Input() canOptions?: LCEDeliveryCompanyCanOptions;

    @Output() editEvent = new EventEmitter<LCEDeliveryCompany>();
    @Output() closeEvent = new EventEmitter<LCEDeliveryCompany>();

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON_REDO,
        onClick: () => this.retrieveDeliveryCompany()
    };

    addressFieldOptions: XSAddressFieldOptions;
    contactPersonFieldOptions: XSContactPersonFieldOptions;

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('resourceAuditFullMenuAction') resourceAuditFullMenuAction: XSPKResourceAuditFullMenuActionComponent<LCEDeliveryCompany>;

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCECoreContextService,
        public deliveryCompanyService: LCEDeliveryCompanyService,
        private loaderService: XSLoaderService) {
        super();
    }

    get headerAvatar(): XSAvatar {
        return {
            type: XSUtils.isEmpty(this.deliveryCompany?.logo) ? 'icon' : 'image',
            size: XSSize.SMALL,
            data: XSUtils.isEmpty(this.deliveryCompany?.logo) ? this.ICON_DELIVERY_COMPANY : this.deliveryCompany!.logo!.trim()
        };
    }

    get headerTitle(): string {
        return XSUtils.isEmpty(this.deliveryCompany) ? this.TR_BASE + +'.label.deliveryCompany' : this.deliveryCompany!.fullName;
    }

    get headerSubTitle(): string {
        return XSUtils.isEmpty(this.deliveryCompany) ? '...' : this.deliveryCompany!.code;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.deliveryCompanyID = this.dialogConfig.data.deliveryCompanyID;
            this.deliveryCompany = this.dialogConfig.data.deliveryCompany;
            this.editable = this.dialogConfig.data.showEditButton;
            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;
            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.deliveryCompany));
        }
        if (XSUtils.isEmpty(this.deliveryCompanyID) && XSUtils.isEmpty(this.deliveryCompany)) {
            throw new Error('deliveryCompanyID and deliveryCompany cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.deliveryCompanyID) && !XSUtils.isEmpty(this.deliveryCompany)) {
            throw new Error('deliveryCompany and deliveryCompany cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.deliveryCompanyID)) {
            this.retrieveDeliveryCompany();
        }
        this.initialize();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public canShowAuditFullMenuActions(): boolean {
        return !XSUtils.isNull(this.dataManager) && this.canEdit() && !XSUtils.isEmpty(this.canOptions?.audit);
    }

    public canEdit(): boolean {
        return this.editable !== false && this.canOptions?.update === true;
    }

    public onEdit(): void {
        this.editEvent.emit(this.deliveryCompany);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.deliveryCompany);
    }

    private initialize(): void {
        this.buildAddressFieldOptions();
        this.buildContactPersonFieldOptions();
    }

    private buildAddressFieldOptions(): void {
        this.addressFieldOptions = {
            formOptions: {
                type: this.deliveryCompany?.address?.type ? this.deliveryCompany.address.type : undefined!,
                onSave: (address: XSAddress) => {
                    LOG().debug('Saving Delivery Company Address (' + this.deliveryCompany!.id + ') ...', address);
                    return this.deliveryCompanyService.updateAddress(this.deliveryCompany!.id, address)
                        .pipe(tap(response => {
                            this.deliveryCompany!.address = response.address;
                            this.deliveryCompany!.updatedOn = response.updatedOn;
                            this.deliveryCompany!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
    }

    private buildContactPersonFieldOptions(): void {
        this.contactPersonFieldOptions = {
            formOptions: {
                showDescriptionField: false,
                showSecondaryPhoneNumberField: false,
                showEmailField: true,
                showNoteField: true,
                primaryPhoneNumber: {
                    countryISO: this.deliveryCompany!.address!.countryISO,
                    showCountryFlag: true
                },
                onSave: (contactPerson: XSContactPerson) => {
                    LOG().debug('Saving Delivery Company Contact Person (' + this.deliveryCompany!.id + ') ...', contactPerson);
                    return this.deliveryCompanyService.updateContactPerson(this.deliveryCompany!.id, contactPerson)
                        .pipe(tap(response => {
                            this.deliveryCompany!.contactPerson = response.contactPerson;
                            this.deliveryCompany!.updatedOn = response.updatedOn;
                            this.deliveryCompany!.updatedBy = this.contextService.getUser();
                        }));
                }
            }
        };
    }

    private retrieveDeliveryCompany(): void {
        this.error = undefined;
        this.loaderService.startLoader(this.LOADER_ID);
        this.subscription.add(
            this.deliveryCompanyService
                .retrieve(this.deliveryCompanyID!)
                .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
                .subscribe({
                    next: (deliveryCompanyRetrieve: LCEDeliveryCompany) => {
                        this.deliveryCompany = deliveryCompanyRetrieve;
                    },
                    error: (error) => (this.error = error)
                })
        );
    }

}
