import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {LCEUserMunicipalEmployee, LCEUserMunicipalEmployeePartial, LCEUserMunicipalEmployeeService, LCEUserUtils} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSCoreDomUtils, XSDialogable} from '@xs/core';
import {TabView} from 'primeng/tabview';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-user-municipal-employee-record',
    templateUrl: './lce-user-municipal-employee-record.component.html',
    styles: [':host { display: flex; width: 100%; }']
})
export class LCEUserMunicipalEmployeeRecordComponent extends XSDialogable implements OnInit, OnDestroy {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE_LABEL: string = 'lce.shared.user.label.';

    readonly LOADER_ID: string = 'userRecordLoader.' + XSUtils.uuid();

    @Input() styleClass?: string;
    @Input() loadingStyleClass?: string;

    @Input() userID?: string;
    @Input() user?: LCEUserMunicipalEmployee;

    @Input() readonly: boolean;

    @Output() editEvent = new EventEmitter<LCEUserMunicipalEmployee>();
    @Output() purgeEvent = new EventEmitter<string>();

    @Output() closeEvent = new EventEmitter<LCEUserMunicipalEmployee>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;

    navScrollable: boolean = false;

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        icon: this.ICON.refresh,
        label: 'xs.core.label.pleaseTryAgain',
        onClick: () => this.retrieveUser()
    };

    private subscription: Subscription = new Subscription();

    private tabViewResizeObserver: ResizeObserver;
    private tabView: TabView;

    constructor(private loaderService: XSLoaderService, private userService: LCEUserMunicipalEmployeeService) {
        super();
    }

    @ViewChild('pTabView') set pTabView(view: TabView) {
        if (view) {
            this.tabView = view;
            this.installTabViewResizeObserver();
        }
    }

    get headerTitle(): string {
        let title = XSUtils.isEmpty(this.user) ? this.TR_BASE_LABEL + 'user' : XSCommonDomainUtils.getPersonFullName(this.user!.name!);
        return title!;
    }

    get headerSubTitle(): string {
        let subTitle = XSUtils.isEmpty(this.user) ? '...' : this.user!.code;
        return subTitle!;
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.userID = this.dialogConfig.data.userID;
            this.user = this.dialogConfig.data.user;

            this.styleClass = this.dialogConfig.data.styleClass;
            this.loadingStyleClass = this.dialogConfig.data.loadingStyleClass;

            this.readonly = this.dialogConfig.data.readonly;

            this.dialogRef.onClose.subscribe(() => this.closeEvent.emit(this.user));
        }
        XSAssert.notNull(this.readonly, 'readonly');
        if (XSUtils.isEmpty(this.userID) && XSUtils.isEmpty(this.user)) {
            throw new Error('userID and user cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(this.userID) && !XSUtils.isEmpty(this.user)) {
            throw new Error('userID and user cannot both be set at the same time.');
        }
        if (!XSUtils.isEmpty(this.userID)) this.retrieveUser();
    }

    ngOnDestroy(): void {
        if (this.tabViewResizeObserver) this.tabViewResizeObserver.disconnect();
        this.subscription.unsubscribe();
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getUserPartial(): LCEUserMunicipalEmployeePartial {
        return LCEUserUtils.toMunicipalEmployeePartial(this.user!);
    }

    public canDisplayData(): boolean {
        return !this.hasError() && !this.isLoaderRunning() && !XSUtils.isEmpty(this.user);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    private retrieveUser(): void {
        this.error = undefined;
        this.startLoader();
        this.subscription.add(
            this.userService
                .retrieve(this.userID!)
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: userRecord => {
                        this.user = userRecord;
                    },
                    error: (error: any) => (this.error = error)
                })
        );
    }

    private installTabViewResizeObserver(): void {
        if (XSUtils.isNull(this.tabView) || !XSUtils.isNull(this.tabViewResizeObserver)) return;
        this.tabViewResizeObserver = new ResizeObserver(entries => this.updateTabViewScrollable());
        this.tabViewResizeObserver.observe(this.tabView.el.nativeElement);
    }

    private updateTabViewScrollable(): void {
        if (XSUtils.isNull(this.tabView)) return;
        const targetElement = XSCoreDomUtils.findElement(this.tabView.el.nativeElement, '.p-tabview-nav')!;
        this.navScrollable = XSCoreDomUtils.isOverflownX(targetElement);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }
}
