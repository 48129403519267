import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_CORE_LABEL, LCEDelivery, LCEDeliveryDestinationType} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN, XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR} from '@xs/common';
import {XSCalendarOptions} from '@xs/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-delivery-general', templateUrl: './lce-delivery-general.component.html'})
export class LCEDeliveryGeneralComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE = LCE_TR_BASE_CORE_LABEL;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() data: LCEDelivery;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;

    calendarOptions: XSCalendarOptions = {
        type: 'date',
        formatEN: XS_DATE_FORMAT_MEDIUM_LONG_DATE_EN,
        formatFR: XS_DATE_FORMAT_MEDIUM_LONG_DATE_FR
    };

    get deliveryIcon(): string | undefined {
        switch (this.data.destination.type) {
            case LCEDeliveryDestinationType.CUSTOM_LOCATION:
                return LCE_SHARED_ICON.mapPin;
            case LCEDeliveryDestinationType.FACILITY:
                return LCE_SHARED_ICON.facility;
            case LCEDeliveryDestinationType.SERVICE_POINT:
                return LCE_SHARED_ICON.servicePoint;
            case LCEDeliveryDestinationType.INTERNATIONAL:
                return LCE_SHARED_ICON.international;
            default:
                throw new Error(`unknown delivery destination type [${this.data.destination.type}]`);
        }
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showBorder)) this.showBorder = false;
        if (XSUtils.isNull(this.showHeader)) this.showHeader = false;
    }
}
