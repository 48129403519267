import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCECertificateOrder, LCEEventPartial, LCEUserPartial} from '@lce/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {OverlayPanel} from 'primeng/overlaypanel';

@Component({selector: 'lce-certificate-order-timeline', templateUrl: './lce-certificate-order-timeline.component.html'})
export class LCECertificateOrderTimelineComponent implements OnInit {

    readonly ICON = LCE_SHARED_ICON;
    readonly TR_BASE: string = 'lce.shared.certificateOrderRecord.';
    readonly TR_BASE_LABEL: string = 'lce.shared.label.';

    @Input() styleClass?: string;

    @Input() readonly?: boolean;

    @Input() data: LCECertificateOrder;

    @ViewChild('byOverlay') byOverlay: OverlayPanel;

    searchText: string;

    events: LCEEventPartial[] = [];

    eventCreatedBy: LCEUserPartial;

    constructor() {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public onAuditByClick(event: any): void {
        this.eventCreatedBy = event.data.createdBy;
        this.byOverlay.toggle(event.event);
    }

}
