<div [ngClass]="{ 'xs-border-radius-round xs-plr-2 xs-pt-2 xs-background-color-default': showAvatarBackground }" class="{{ styleClass }}">
    <xs-ivar
            (avatarClick)="onAvatarClick($event)"
            [avatarBadgeValue]="STR_SPACE"
            [avatarClickable]="isAvatarClickable()"
            [avatarErrorImageSrc]="IMAGE_NOT_FOUND_USER_AVATAR_SRC"
            [avatarShowTooltip]="showTooltip"
            [avatarSize]="avatarSize"
            [avatarTooltip]="avatarStatusTooltip"
            [avatarType]="avatarType"
            [avatar]="avatar"
            [disabled]="disabled"
            [ellipsis]="true"
            [line1]="data.name! | xsPersonName"
            [line3]="userCode"
            [showAvatarBadge]="showStatus"
            [showLines]="!showOnlyAvatar"
            avatarBadgePosition="bottom-right"
            avatarBadgeStyleClass="xs-min-width-12-imp xs-height-12-imp xs-right-5-imp {{ BASE_STYLE_CLASS_BG_COLOR_USER_STATUS + data.status }}"
            avatarIconStyleClass="xs-color-secondary-imp"
            avatarLabelStyleClass="xs-color-primary-imp"
            avatarStyleClass="xs-border-discrete {{ avatarStyleClass }}"
            line1StyleClass="{{ isSizeSmall() ? 'xs-font-size-small-imp' : '' }} {{ personNameStyleClass }}"
            line2StyleClass="xs-color-secondary {{ isSizeSmall() ? 'xs-font-size-small-imp' : '' }} {{ userPositionStyleClass }}"
            line3StyleClass="xs-color-secondary xs-text-transform-uppercase {{ isSizeSmall() ? 'xs-font-size-small-imp' : '' }} {{ userCodeStyleClass }}"
            xsImageError>
    </xs-ivar>

    <div #viewDetailContainer class="xs-append-to-overlay-container"></div>

    <!-- <p-overlayPanel *ngIf="viewDetail" [appendTo]="viewDetailContainer" #detailOverlay> -->
    <p-overlayPanel #detailOverlay *ngIf="viewDetail" [appendTo]="'body'">
        <lce-user-detail [data]="data" [showViewRecordButton]="hasViewDetailPermission() && showViewRecordButton === true"></lce-user-detail>
    </p-overlayPanel>
</div>
