import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCECMDDistrictsComponent} from './lce-cmd-districts.component';

const routes: Routes = [{path: 'districts', component: LCECMDDistrictsComponent}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCECMDDistrictsRoutingModule {
}
