import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {
    LCECertificateOrderEventData,
    LCECertificateOrderEventName,
    LCEEventPartial,
    LCEEventType,
    LCEResourceType,
    LCESuggestionEventData,
    LCESuggestionEventName,
    LCEWebSocketService
} from '@lce/core';
import {LOG, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSTranslationService} from '@xs/common';
import {XSGlobalToastKey, XSLocalStorageService, XSToastService} from '@xs/core';
import {Subject} from 'rxjs';
import {LCESoundAlertEventArg, LCESoundAlertService} from './lce-sound-alert.service';
import {LCE_SHARED_ICON} from '../constants/lce-shared-icon.constant';

@Injectable()
export abstract class LCEEventProcessorService {

    private newCertificateOrderSubject = new Subject<LCEEventPartial<LCECertificateOrderEventData>>();
    onNewCertificateOrder = this.newCertificateOrderSubject.asObservable();

    private newSuggestionSubject = new Subject<LCEEventPartial<LCESuggestionEventData>>();
    onNewSuggestion = this.newSuggestionSubject.asObservable();

    protected constructor(
        protected translationService: XSTranslationService,
        protected toastService: XSToastService,
        protected datePipe: DatePipe,
        protected localStorageService: XSLocalStorageService,
        protected webSocketService: LCEWebSocketService,
        protected soundAlertService: LCESoundAlertService) {
        this.webSocketService.onEvent.subscribe((event: LCEEventPartial) => this.handleEvent(event));
    }

    // ----------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------

    private processEventResourceSuggestionNew(event: LCEEventPartial<LCESuggestionEventData>): void {
        this.newSuggestionSubject.next(event);

        const newSuggestionStr: string = this.translationService.translateKey('lce.shared.event.eventName.suggestionNew');
        const receivedOn: string = this.translationService.translateKey('lce.core.label.orderReceivedOn');
        const hhmm: string = this.datePipe.transform(event.createdOn, 'HH:mm')!;

        const userStr = event.data!.anonymous === true ?
            this.translationService.translateKey('lce.shared.label.anonymousUser') :
            XSCommonDomainUtils.getPersonFullName(event.data?.authorName!);

        const tSummary: string = newSuggestionStr + ' - ' + userStr;
        const tDetail: string = event.resourceCode + ' ' + receivedOn + ' ' + hhmm;

        this.toastService.addToast({
            key: XSGlobalToastKey.BOTTOM_RIGHT.toString(),
            summary: tSummary,
            detail: tDetail,
            icon: LCE_SHARED_ICON.suggestion
        });

        this.playEventResourceSoundAlert(LCEResourceType.SUGGESTION, LCESuggestionEventName.NEW_SUGGESTION);
    }

    // ----------------------------------------------------------------------------------------------------------
    // === Certificate Order ===
    // ----------------------------------------------------------------------------------------------------------

    private processEventResourceCertificateOrderNew(event: LCEEventPartial<LCECertificateOrderEventData>): void {
        this.newCertificateOrderSubject.next(event);

        const newOrderStr: string = this.translationService.translateKey('lce.core.label.newOrder');
        const certificateTypeStr: string = this.translationService.translateKey('lce.core.certificateTypeMedium.' + event.data!.certificateType);
        const orderNumberStr: string = this.translationService.translateKey('lce.core.label.orderNumberShort');
        const receivedOn: string = this.translationService.translateKey('lce.core.label.orderReceivedOn');
        const hhmm: string = this.datePipe.transform(event.createdOn, 'HH:mm')!;

        const tSummary: string = newOrderStr + ' : ' + certificateTypeStr;
        const tDetail: string = orderNumberStr + ' ' + event.resourceCode + ' ' + receivedOn + ' ' + hhmm;

        this.toastService.addToast({
            key: XSGlobalToastKey.BOTTOM_RIGHT.toString(),
            severity: 'success',
            summary: tSummary,
            detail: tDetail,
            icon: LCE_SHARED_ICON.certificateOrder
        });

        this.playEventResourceSoundAlert(LCEResourceType.CERTIFICATE_ORDER, LCECertificateOrderEventName.NEW_ORDER);
    }

    private processEventResourceCertificateOrder(event: LCEEventPartial): void {
        switch (event.eventName) {
            case LCECertificateOrderEventName.NEW_ORDER:
                this.processEventResourceCertificateOrderNew(event);
                break;
            default:
                LOG().warn('Event Name [' + event.eventName + '] not handled yet !');
                break;
        }
    }

    // ----------------------------------------------------------------------------------------------------------
    // === * ===
    // ----------------------------------------------------------------------------------------------------------

    private playEventResourceSoundAlert(resourceType: LCEResourceType, eventName: string): void {
        const eventArg: LCESoundAlertEventArg = {
            eventType: LCEEventType.RESOURCE,
            resourceType: resourceType,
            eventName: eventName
        };
        if (this.soundAlertService.isEventSoundAlertEnabled(eventArg)) {
            this.soundAlertService.playEventSoundAlert(eventArg);
        }
    }

    private processEventResource(event: LCEEventPartial): void {
        switch (event.resourceType) {
            case LCEResourceType.CERTIFICATE_ORDER:
                this.processEventResourceCertificateOrder(event);
                break;
            case LCEResourceType.SUGGESTION:
                this.processEventResourceSuggestionNew(event);
                break;
            default:
                LOG().warn('Event Resource Type [' + event.resourceType + '] not handled yet !');
                break;
        }
    }

    private processEventAlert(event: LCEEventPartial): void {
        throw new Error(`Alert [${event.type}] event handling not implemented yet !`);
    }

    private processEventSystem(event: LCEEventPartial): void {
        throw new Error(`System [${event.type}] event handling not implemented yet !`);
    }

    private processEventInformation(event: LCEEventPartial): void {
        throw new Error(`Information [${event.type}] event handling not implemented yet !`);
    }

    private handleEvent(event: LCEEventPartial): void {
        if (XSUtils.isEmpty(event)) {
            return;
        }
        switch (event.type) {
            case LCEEventType.RESOURCE:
                this.processEventResource(event);
                break;
            case LCEEventType.ALERT:
                this.processEventAlert(event);
                break;
            case LCEEventType.SYSTEM:
                this.processEventSystem(event);
                break;
            case LCEEventType.INFORMATION:
                this.processEventInformation(event);
                break;
            default:
                LOG().warn('Event Type [' + event.type + '] not handled !');
                break;
        }
    }
}
