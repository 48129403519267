import {Injectable} from '@angular/core';
import {XS_STR_EMPTY, XSAddress, XSAssert, XSContactPerson, XSPKResourceCodeAuditFullService, XSUtils} from '@xs/base';
import {Observable} from 'rxjs';
import {LCE_CORE_ENDPOINTS} from '../../constants/lce-core-endpoint.constant';
import {LCEFacility, LCEFacilityCreate, LCEFacilityPartial, LCEFacilitySearch} from '../../domain/facility/lce-facility';
import {LCEHttpClientService} from '../lce-http-client.service';
import {HttpParams} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class LCEFacilityService extends XSPKResourceCodeAuditFullService<LCEFacility, LCEFacilityPartial, LCEFacilitySearch> {

    constructor(protected httpClientService: LCEHttpClientService) {
        super(httpClientService, LCE_CORE_ENDPOINTS.facilities.index, 'facility');
    }

    public autocompleteRegistered(query: string, limit: number = 5): Observable<LCEFacilityPartial[]> {
        let params = new HttpParams().set('query', XSUtils.isNull(query) ? XS_STR_EMPTY : query.trim()).set('limit', limit);
        return this.httpClientService.get<LCEFacilityPartial[]>(this.buildEndpoint('autocomplete'), params);
    }

    public updateSecondaryContactPerson(facilityID: string, secondaryContactPerson: XSContactPerson | undefined):
        Observable<{ id: string; secondaryContactPerson: XSContactPerson; updatedOn: string }> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        return this.updateF(facilityID, {secondaryContactPerson: secondaryContactPerson}, ['id', 'secondaryContactPerson', 'updatedOn']);
    }

    public updatePrimaryContactPerson(facilityID: string, primaryContactPerson: XSContactPerson): Observable<{
        id: string;
        primaryContactPerson: XSContactPerson;
        updatedOn: string
    }> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(primaryContactPerson, 'primaryContactPerson');
        return this.updateF(facilityID, {primaryContactPerson: primaryContactPerson}, ['id', 'primaryContactPerson', 'updatedOn']);
    }

    public updateAddress(facilityID: string, address: XSAddress): Observable<{
        id: string;
        address: XSAddress;
        updatedOn: string
    }> {
        XSAssert.notEmpty(facilityID, 'facilityID');
        XSAssert.notEmpty(address, 'address');
        return this.updateF(facilityID, {address: address}, ['id', 'address', 'updatedOn']);
    }

    public create(facilityCreate: LCEFacilityCreate): Observable<LCEFacility> {
        XSAssert.notEmpty(facilityCreate, 'facilityCreate');
        return this.httpClientService.post<LCEFacility>(this.buildEndpoint(), facilityCreate);
    }
}
