<div class="xs-flex-column xs-position-relative xs-width-full {{ styleClass }} {{ isLoaderRunning() ? loadingStyleClass : '' }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #deliveryCompanyRecordLoader [loaderId]="LOADER_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasError() && !deliveryCompanyRecordLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorRetryButton"
                [error]="error"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }} label.errorMessageRetrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>

    <div class="xs-card">
        <div *ngIf="!isDialog()" class="xs-card-header">
            <xs-header-stripe [avatar]="headerAvatar" [subtitle]="headerSubTitle" [title]="headerTitle"></xs-header-stripe>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-content">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mt-15 xs-mb-50">
                <lce-user-resource-audit [data]="deliveryCompany!"></lce-user-resource-audit>
                <lce-delivery-company-register-status [data]="deliveryCompany!"></lce-delivery-company-register-status>

                <div class="xs-flex-row xs-align-items-center">
                    <xs-error
                            *ngIf=" (resourceAuditFullMenuAction | xsIsNotEmpty) && resourceAuditFullMenuAction.error && !resourceAuditFullMenuAction.auditLoading"
                            [error]="resourceAuditFullMenuAction.error"
                            [message]="resourceAuditFullMenuAction.errorMessage!"
                            [showErrorDetailButton]="true"
                            mode="inline">
                    </xs-error>
                    <xs-button
                            (clickEvent)="onEdit()"
                            *ngIf="canEdit()"
                            [disabled]="(resourceAuditFullMenuAction | xsIsNotEmpty) && !resourceAuditFullMenuAction?.canDoAction()"
                            [icon]="ICON_EDIT"
                            class="xs-mr-40"
                            label="xs.core.label.edit"
                            size="intermediate"
                            type="text">
                    </xs-button>
                    <xs-pk-resource-audit-full-menu-action
                        #resourceAuditFullMenuAction
                        *ngIf="canShowAuditFullMenuActions()"
                        [dataManager]="dataManager!"
                        [resourceService]="deliveryCompanyService">
                    </xs-pk-resource-audit-full-menu-action>
                </div>

            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-fields-plr-15">
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <!-- === Delivery Company Information  === -->
                <!-- ------------------------------------------------------------------------------------------------------ -->
                <div class="col-6">
                    <xs-data-field-text
                            [upperCase]="true"
                            [value]="deliveryCompany!.code"
                            label="xs.core.label.code"
                            valueStyleClass="xs-font-size-intermediate xs-color-secondary">
                    </xs-data-field-text>
                </div>
                <div class="col-6">
                    <xs-data-field-resource-state [value]="deliveryCompany!"></xs-data-field-resource-state>
                </div>
                <div class="col-12 md:col-6">
                    <lce-data-field-user-resource-audit-inline
                            [by]="deliveryCompany!.registeredBy!"
                            [on]="deliveryCompany!.registeredOn!"
                            label="lce.shared.deliveryCompany.label.registered">
                    </lce-data-field-user-resource-audit-inline>
                </div>
                <div class="col-12 md:col-6">
                    <lce-data-field-user-resource-audit-inline [by]="deliveryCompany!.unregisteredBy!" [on]="deliveryCompany!.unregisteredOn!" label="lce.shared.deliveryCompany.label.unregistered">
                    </lce-data-field-user-resource-audit-inline>
                </div>

                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE + 'label.name'" [value]="deliveryCompany!.name" valueStyleClass="xs-font-size-intermediate"></xs-data-field-text>
                </div>

                <div class="col-6">
                    <xs-data-field-text [capitalize]="true" [label]="TR_BASE + 'label.fullName'" [value]="deliveryCompany!.fullName" valueStyleClass="xs-font-size-intermediate"></xs-data-field-text>
                </div>
                <div *ngIf="deliveryCompany!.municipality" class="col-6">
                    <div class="xs-data-field">
                        <div class="xs-data-field-value-container">
                            <lce-municipality-partial [data]="deliveryCompany!.municipality!" label="lce.shared.municipality.label.municipality"></lce-municipality-partial>
                        </div>
                    </div>
                </div>

                <div class="col-6">
                    <xs-data-field-text [label]="TR_BASE + 'label.cc'" [required]="false" [value]="deliveryCompany!.cc" valueStyleClass="xs-font-size-intermediate"></xs-data-field-text>
                </div>

                <div class="col-12 md:col-6">
                    <xs-data-field-address
                            [editable]="true"
                            [options]="addressFieldOptions"
                            [value]="deliveryCompany!.address"
                            label="xs.common.label.address">
                    </xs-data-field-address>
                </div>
                <div class="col-12 md:col-6">
                    <xs-data-field-contact-person
                            [deletable]="false"
                            [editable]="true"
                            [options]="contactPersonFieldOptions"
                            [value]="deliveryCompany!.contactPerson"
                            label="xs.core.label.primaryContactPerson">
                    </xs-data-field-contact-person>
                </div>
            </div>
        </div>
        <div *ngIf="canDisplayData()" class="xs-card-footer xs-align-items-end xs-mt-100">
            <xs-data-id [value]="deliveryCompany!.id"></xs-data-id>
        </div>
    </div>
</div>

<ng-template #dHeader>
    <lce-record-header-component
            [icon]="ICON_DELIVERY_COMPANY"
            [line1]="headerTitle"
            [line2]="headerSubTitle"
            styleClass="xs-dialog-header">
    </lce-record-header-component>
</ng-template>
