import {Injectable} from '@angular/core';
import {XSAssert} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCEBirthDeclarationDeliveryFormComponent} from './create-update/birth-declaration-delivery-create-update.component';
import {LCEBirthDeclarationDeliveryComponent} from './lce-birth-declaration-delivery.component';
import {LCEBirthDeclarationDelivery, LCEFacilityWorkerPartial} from '@lce/core';

export interface LCEBirthDeclarationDeliveryDialogCreateUpdateArg {
    delivery?: LCEBirthDeclarationDelivery<any>;
    birthDeclarationID?: string;
    showBorder?: boolean;
    showRecordAfterSave?: boolean;
    birthDeclarationCode?: string;
    onCancel?: () => void;
    onClose?: (delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => void;
    onSave?: (delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => void;
}

export interface LCEBirthDeclarationDeliveryDialogRecordArg {
    delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>;
    birthDeclarationCode?: string;
    showEditButton?: boolean;
    showBorder?: boolean;
    onClose?: (delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => void;
    onEdit?: (delivery?: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => void;
}

@Injectable()
export class LCEBirthDeclarationDeliveryDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openRecordDialog(arg: LCEBirthDeclarationDeliveryDialogRecordArg): void {
        XSAssert.notEmpty(arg, 'arg');

        const dConfig: XSDialogConfig = {
            data: {
                delivery: arg.delivery,
                showEditButton: arg.showEditButton,
                styleClass: 'xs-width-full',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-600',
                birthDeclarationCode: arg.birthDeclarationCode,
                showHeader: false
            },
            footer: true,
            footerOkButton: true,
            footerSeparator: true,
            header: true,
            headerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-900'
        };

        const recordComponent: LCEBirthDeclarationDeliveryComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationDeliveryComponent, dConfig);

        recordComponent.closeEvent.subscribe((savedDeliveryRecord: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => {
            if (arg?.onClose) arg!.onClose!(savedDeliveryRecord);
        });
        recordComponent.editEvent.subscribe((deliveryRecord: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => {
            if (arg?.onEdit) arg.onEdit(deliveryRecord);
            recordComponent.closeDialog();
            if (arg.showEditButton === true) {
                this.openCreateUpdateDialog({
                    delivery: deliveryRecord,
                    onClose: (delivery) => this.openRecordDialog({delivery: delivery, showEditButton: true}),
                    onSave: (delivery) => this.openRecordDialog({delivery: delivery, showEditButton: true})
                });
            }
        });
    }

    public openCreateUpdateDialog(arg?: LCEBirthDeclarationDeliveryDialogCreateUpdateArg): void {
        const dConfig: XSDialogConfig = {
            data: {
                delivery: arg?.delivery,
                birthDeclarationID: arg?.birthDeclarationID,
                styleClass: 'xs-width-full ',
                loadingStyleClass: 'xs-min-height-600 xs-min-width-500',
                showBorder: arg?.showBorder
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1000'
        };

        const createUpdateComponent: LCEBirthDeclarationDeliveryFormComponent = this.dialogService.openComponentDialog(LCEBirthDeclarationDeliveryFormComponent, dConfig);

        createUpdateComponent.cancelEvent.subscribe(() => {
            if (arg?.onCancel) arg!.onCancel!();
        });

        createUpdateComponent.closeEvent.subscribe((delivery: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => {
            if (arg?.onClose) arg!.onClose!(delivery);
        });

        createUpdateComponent.saveEvent.subscribe((delivery: LCEBirthDeclarationDelivery<LCEFacilityWorkerPartial>) => {
            createUpdateComponent.closeDialog();
            if (arg?.onSave) arg.onSave(delivery);
            if (arg?.showRecordAfterSave === true)
                this.openRecordDialog({delivery: delivery, showEditButton: false, showBorder: false, birthDeclarationCode: arg?.birthDeclarationCode});
        });
    }
}
