import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCENotification, LCENotificationPartial, LCENotificationSearch, LCENotificationType} from '@lce/core';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {XSUtils} from '@xs/base';
import {XSMockPKResourceHandler, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_NOTIFICATION_JSON from '../data/lce-notifications.json';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../lce-mock.constant';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.notifications';

@Injectable()
export class LCEMockNotificationHandler extends XSMockPKResourceHandler<LCENotification, LCENotificationPartial, LCENotificationSearch> {

    public static NOTIFICATION_STORAGE: Map<string, LCENotification> = new Map<string, LCENotification>();

    constructor(private mockFacilityHandler: LCEMockFacilityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.notifications.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCENotification> {
        return LCEMockNotificationHandler.NOTIFICATION_STORAGE;
    }

    public toPartial(notification: LCENotification): LCENotificationPartial {
        return {
            id: notification.id,
            type: notification.type,
            data: notification.data,
            resourceIDs: notification.resourceIDs,
            resourceCodes: notification.resourceCodes,
            read: notification.read,
            readOn: notification.readOn
        };
    }

    buildMockDataArray(): void {
        this.buildStorage();

        const queryPredicates: XSMockSearchQueryPredicate<LCENotification>[] = [
            (notification, query) => notification.municipalityCode.toLowerCase().includes(query?.toLowerCase()),
            (notification, query) => notification.facilityCode?.toLowerCase().includes(query?.toLowerCase())
        ];

        this.mockDataArray = [
            ...this.mockDataArray,
            // Search
            this.buildSearchMockData({
                queryPredicates: queryPredicates,
                predicates: [
                    (notification, params) => this.httpParamArrayIncludes(params, 'types', notification.type),
                    (notification, params) => this.httpParamArrayIncludes(params, 'resourceIDs', notification.resourceIDs),
                    (notification, params) => this.httpParamArrayIncludes(params, 'resourceCodes', notification.resourceCodes),
                    (notification, params) => this.httpParamBooleanEquals(params, 'read', notification.read)
                ]
            })
        ];
    }

    private buildStorage(): void {
        const facilities = [this.mockFacilityHandler.getYopougonTownHall(), this.mockFacilityHandler.getCocodyTownHall()];
        LCE_NOTIFICATION_JSON.forEach(notificationJSON => {
            const facility = XSUtils.randomElement(facilities);
            const notification: LCENotification = {
                id: notificationJSON.id,
                type: notificationJSON.type as LCENotificationType,
                data: notificationJSON.data,
                resourceIDs: notificationJSON.resourceIDs,
                resourceCodes: notificationJSON.resourceCodes,
                municipalityCode: facility.municipality.code,
                facilityCode: facility.code,
                createdOn: new Date().toDateString()
            };
            LCEMockNotificationHandler.NOTIFICATION_STORAGE.set(notification.id, notification);
            this.addResourceBaseMockData(notification.id);
        });
    }
}
