import {Component, Input, OnInit} from '@angular/core';
import {LCECertificatePartial} from '@lce/core';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {XS_DATE_FORMAT_YYYY, XSTranslationService} from '@xs/common';

@Component({selector: 'lce-certificate-partial', templateUrl: './lce-certificate-partial.component.html'})
export class LCECertificatePartialComponent implements OnInit {
    @Input() styleClass?: string;

    @Input() data: LCECertificatePartial;

    @Input() municipality?: string;

    @Input() showLetterBorder?: boolean;

    constructor(private translationService: XSTranslationService) {
    }

    get dateFormat(): string {
        return this.translationService.getCurrentLanguage() === XSLanguage.FRENCH ? XS_DATE_FORMAT_YYYY : XS_DATE_FORMAT_YYYY;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showLetterBorder)) this.showLetterBorder = true;
    }
}
