import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {XSCommonModule} from '@xs/common';
import {XSPaymentBaseModule} from '@xs/payment/base';
import {LCECertificateOrderStatusPipe} from './pipes/lce-certificate-order-status.pipe';
import {LCEResourceTypePipe} from './pipes/lce-resource-type.pipe';
import {LCEUserMainRolePipe} from './pipes/lce-user-main-role.pipe';
import {LCEUserPositionPipe} from './pipes/lce-user-position.pipe';

@NgModule({
    imports: [
        CommonModule,
        XSCommonModule,
        XSPaymentBaseModule
    ],
    declarations: [
        // --- Pipes ---
        LCEUserMainRolePipe,
        LCEUserPositionPipe,
        LCEResourceTypePipe,
        LCECertificateOrderStatusPipe
    ],
    exports: [
        // --- * ---
        XSCommonModule,
        XSPaymentBaseModule,
        // --- Pipes ---
        LCEUserMainRolePipe,
        LCEUserPositionPipe,
        LCEResourceTypePipe,
        LCECertificateOrderStatusPipe
    ]
})
export class LCECoreModule {
}
