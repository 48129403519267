import { formatNumber } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LCE_SHARED_ICON } from '../../api/constants/lce-shared-icon.constant';
import { XSUtils } from '@xs/base';
import { XSTranslationService } from '@xs/common';

@Component({ selector: 'lce-news-article-stats-icons', templateUrl: './lce-news-article-stats-icons.component.html' })
export class LCEArticleStatsIconsComponent implements OnInit {
    ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() numberOfViews: number;
    @Input() numberOfComments: number;
    @Input() numberOfShares: number;

    constructor(private translationService: XSTranslationService) {}

    ngOnInit(): void {}

    public formatValue(value: any): any {
        if (!XSUtils.isNumber(value)) return value;
        return formatNumber(value, this.translationService.getCurrentLanguage().toString());
    }
}
