import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, TemplateRef} from '@angular/core';
import {LCECertificateOrder, LCEEventPartial, LCEEventSearch, LCEEventService, LCEResourceType} from '@lce/core';
import {XSAssert, XSPagination, XSTemplateDirective, XSUtils} from '@xs/base';
import {XSClickEvent, XSLoaderService} from '@xs/common';
import {XSButton} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-certificate-order-events', templateUrl: './lce-certificate-order-events.component.html'})
export class LCECertificateOrderEventsComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

    readonly EVENT_ICON = LCE_SHARED_ICON.timeline;

    readonly TEMPLATE_NAME_AUDIT: string = 'audit';

    readonly PAGINATOR_ROWS: number = 10;

    readonly LOADER_ID = XSUtils.uuid() + 'certificateOrderEvents';

    @Input() styleClass?: string;

    @Input() timelineStyleClass?: string;

    @Input() eventStyleClass?: string;

    @Input() auditStyleClass?: string;
    @Input() auditOnStyleClass?: string;
    @Input() auditByStyleClass?: string;

    @Input() titleStyleClass?: string;
    @Input() subtitleStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() title?: string;
    @Input() subtitle?: string;

    @Input() certificateOrder: LCECertificateOrder;

    @Input() showHeader?: boolean;
    @Input() showBorder?: boolean;

    @Input() verticalGapHeight?: string;

    @Input() auditByClickable?: boolean;

    @Input() containerMaxHeight?: string;

    @Input() dataAuditTemplateRef?: TemplateRef<any>;

    @Output() refreshEvent = new EventEmitter<void>();
    @Output() auditByClickEvent = new EventEmitter<XSClickEvent<LCEEventPartial>>();

    data: LCEEventPartial[];

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: LCE_SHARED_ICON.redo,
        onClick: () => this.search()
    };

    pagination: XSPagination = {page: 0, size: this.PAGINATOR_ROWS};
    eventSearch: LCEEventSearch = {paginationPage: this.pagination.page, paginationSize: this.pagination.size};
    @ContentChildren(XSTemplateDirective) private templates: QueryList<any>;

    private subscription = new Subscription();

    constructor(private eventService: LCEEventService, private loaderService: XSLoaderService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.certificateOrder.id, 'orderNumber');
        if (XSUtils.isNull(this.auditByClickable)) this.auditByClickable = this.auditByClickEvent.observers.length > 0;
        this.search();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!XSUtils.isEmpty(changes.certificateOrder) && !changes.certificateOrder!.isFirstChange()) {
            this.search();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        if (!XSUtils.isNull(this.templates) && this.templates.length > 0) {
            this.dataAuditTemplateRef = this.templates.find(item => this.TEMPLATE_NAME_AUDIT === item.getName())?.template;
        }
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public hasError(): boolean {
        return !XSUtils.isNull(this.error);
    }

    public onAuditByClick(nativeEvent: any, event: LCEEventPartial): void {
        this.auditByClickEvent.emit({event: nativeEvent.event, data: event});
    }

    public isFakeData(id: string): boolean {
        return id === 'fakeEventID';
    }

    private search(): void {
        this.loaderService.startLoader(this.LOADER_ID);
        this.error = undefined;
        this.eventSearch.resourceTypes = [LCEResourceType.CERTIFICATE_ORDER];
        this.eventSearch.resourceIDs = [this.certificateOrder.id];
        this.eventSearch.resourceCodes = [this.certificateOrder.orderNumber];
        this.subscription.add(this.eventService
            .search(this.eventSearch)
            .pipe(finalize(() => this.loaderService.stopLoader(this.LOADER_ID)))
            .subscribe({
                next: searchResult => {
                    this.data = searchResult.data;
                    this.buildInternalData();
                },
                error: error => this.error = error
            })
        );
    }

    private buildInternalData(): void {
        if (this.data.length === 1) {
            for (let $i = 0; $i < 2; $i++) {
                this.data.push({
                    id: 'fakeEventID',
                    createdOn: undefined!,
                    createdBy: undefined!,
                    type: undefined!,
                    resourceType: undefined!,
                    eventName: undefined!,
                    resourceID: undefined!,
                    resourceCode: undefined!,
                    data: undefined!
                });
            }
        }
    }

}
