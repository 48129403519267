<div [ngClass]="{ 'xs-flex-column': !oneLine, 'xs-flex-row xs-align-items-end': oneLine }" class="{{ styleClass }}">
    <xs-data-calendar *ngIf="on | xsIsNotEmpty" [options]="calendarOptions" [value]="on!" valueStyleClass="xs-font-size-intermediate xs-font-weight-600 {{ onStyleClass }}"></xs-data-calendar>
    <span *ngIf="by | xsIsNotEmpty" [ngClass]="{ 'xs-mt-2': !oneLine, 'xs-ml-8': oneLine }" class="xs-display-block xs-font-weight-normal-imp xs-font-size-intermediate {{ byStyleClass }}">
        <span>{{ 'xs.core.audit.by' | translate }} </span>
        <span (click)="onNameClick($event)" [innerHTML]="getName(by!.name) | translate" [ngClass]="{ 'xs-clickable': viewDetail }"></span>
    </span>

    <p-overlayPanel #detailOverlay *ngIf="viewDetail && (by | xsIsNotEmpty)" [appendTo]="'body'">
        <ng-template pTemplate>
            <lce-user-detail [data]="by!" [showViewRecordButton]="hasViewRecordPermission() && showViewRecordButton === true"></lce-user-detail>
        </ng-template>
    </p-overlayPanel>
</div>
