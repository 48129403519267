import { LCEBirthDeclaration, LCEBirthDeclarationPartial } from '../domain/lce-birth-declaration';

export class LCECoreDomainUtils {

    public static toBirthDeclarationPartial(birthDeclaration: LCEBirthDeclaration): LCEBirthDeclarationPartial {
        return {
            id: birthDeclaration.id,
            createdOn: birthDeclaration.createdOn,
            code: birthDeclaration.code,
            birthNumber: birthDeclaration.delivery.birthNumber,
            newBornName: birthDeclaration.delivery.newBornName,
            newBornGender: birthDeclaration.delivery.newBornGender,
            motherName: birthDeclaration.mother.name
        };
    }
}
