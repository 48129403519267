<div class="xs-card xs-min-height-400 xs-max-height-450 xs-min-width-550 {{styleClass}}">
    <div class="xs-card-header">
        <span class="xs-card-header-title {{ titleStyleClass }}">{{ title! | translate }}</span>
        <span class="xs-card-header-subtitle {{ subtitleStyleClass }}">{{ subtitle! | translate }}</span>
    </div>
    <div [ngClass]="{'xs-disabled': loading}" class="xs-card-content xs-card-content-scrollable">
        <lce-delivery-men-autocomplete (selectEvent)="handleDeliveryManSelected($event)" [options]="deliveryMenAutoCompleteField" [showIcon]="true"></lce-delivery-men-autocomplete>
        <div *ngIf="showSelected && (selectedDeliveryMan | xsIsNotEmpty)" class="xs-mt-20 xs-mb-10">
            <lce-delivery-man-assignment [data]="selectedDeliveryMan!"></lce-delivery-man-assignment>

            <hr class="xs-hr xs-hr-discrete xs-mtb-15-imp"/>

            <div class="xs-width-full xs-flex-row xs-justify-content-space-between xs-align-items-center xs-mb-15">
                <div class="xs-flex-column">
                    <span class="xs-display-block xs-font-size-intermediate xs-color-secondary">{{ TR_BASE + 'notifier' | translate }}</span>
                    <span class="xs-display-block xs-font-size-extra-small xs-color-secondary">{{ TR_BASE + 'notifierDescription' | translate }}</span>
                </div>

                <xs-input-switch
                        (changeEvent)="handleNotifierDeliveryMan($event)"
                        [(value)]="notifyDeliveryMan">
                </xs-input-switch>
            </div>
            <xs-button
                    (clickEvent)="showOperation = !showOperation"
                    [disabled]="!notifyDeliveryMan"
                    [label]="showOperation ? 'lce.shared.delivery.request.lessOptions' : 'lce.shared.delivery.request.moreOptions'"
                    size="intermediate"
                    type="text">
            </xs-button>

            <lce-delivery-assignment-request-operation
                    (changeEvent)="handleRequestOperation($event)"
                    *ngIf="showOperation && notifyDeliveryMan"
                    [operationRequest]="$any(assignmentRequest)"
                    styleClass="xs-mt-30 xs-max-width-550">
            </lce-delivery-assignment-request-operation>
        </div>

    </div>
    <div class="xs-card-footer xs-border-top-discrete">
        <div class="xs-flex-row xs-justify-content-space-between">
            <div class="xs-flex-row xs-position-relative xs-ml-15">
                <div class="xs-absolute-center-vh">
                    <xs-loader #loader [loaderId]="LOADER_ID"></xs-loader>
                </div>
                <xs-error
                        *ngIf="error && !loader.isRunning()"
                        [error]="error"
                        [message]="TR_BASE + 'assignErrorMessage'"
                        [showErrorDetailButton]="true" mode="inline">
                </xs-error>
            </div>
            <div class="xs-flex-row">
                <xs-button
                        (clickEvent)="onCancel()"
                        [disabled]="disabled || loading"
                        class="xs-mr-25"
                        label="xs.core.label.cancel"
                        size="small"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="onAssign()"
                        [confirmation]="assignmentConfirmation"
                        [disabled]="disabled || loading || (selectedDeliveryMan | xsIsEmpty)"
                        [label]="TR_BASE +'assign'"
                        size="small"
                        styleClass="xs-width-125-imp"
                        type="button">
                </xs-button>
            </div>
        </div>
    </div>

</div>