import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';

@Component({
    selector: 'lce-facility-town-hall-stamp-token-qrcode',
    templateUrl: './lce-facility-town-hall-stamp-token-qrcode.component.html'
})
export class LCEFacilityTownHallStampTokenQRCodeComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() qrCode: string;

    @Input() code?: string;
    @Input() showCode?: boolean;

    @Input() showTimeAgo?: boolean;
    @Input() timeAgoValue?: string;
    @Input() showTimeAgoShortMode?: boolean;
    @Input() timeAgoPrefix?: string;

    ngOnInit(): void {
        XSAssert.notEmpty(this.qrCode, 'qrCode');
    }
}
