<div [ngClass]="{'xs-min-width-175': badgeMode}" class="xs-flex-row xs-align-items-center xs-position-relative {{styleClass}}">
    <xs-square-data
            [bottomText]="badgeMode ? undefined : numberOfStampsLabel!"
            [showBorder]="true"
            [value]="numberOfStamps! | xsToString"
            valueStyleClass="xs-font-size-medium">
    </xs-square-data>
    <xs-chip
            *ngIf="!badgeMode && (numberOfStampsRemaining | xsIsNotEmpty) && numberOfStampsRemaining! > 0 && (numberOfStampsRemaining! !== numberOfStamps)"
            [small]="true"
            [value]="numberOfStampsRemaining"
            label="{{numberOfStampsRemainingOrLabel! | translate : {stampRemaining: numberOfStampsRemaining!} }}"
            styleClass="xs-background-color-secondary-imp xs-color-primary-contrast-imp xs-ml-15">
    </xs-chip>
    <xs-badge
            *ngIf="badgeMode && (numberOfStampsRemaining | xsIsNotEmpty) && numberOfStampsRemaining! > 0 && (numberOfStampsRemaining! !== numberOfStamps)"
            [mode]="'light'"
            [small]="true"
            styleClass="xs-position-absolute xs-top-minus-10 xs-right-35 xs-min-width-20 {{ badgeMode }}"
            value="{{numberOfStampsRemainingOrLabel! | translate : {stampRemaining: numberOfStampsRemaining!} }}">
    </xs-badge>
</div>