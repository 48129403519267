import {XSAddress, XSPersonName, XSPKAuditFullSearchFilter, XSPKDTOAuditFull, XSUserPartial} from '@xs/base';
import {LCEFacilityPartial} from './facility/lce-facility';
import {LCEResourceType} from './lce-core';

export interface LCEEventResource {
    resourceID: string;
    resourceCode: string;
    resourceType: LCEResourceType;
}

export interface LCEFacilityWorkerSearch extends XSPKAuditFullSearchFilter {
    code?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    primaryPhoneNumber?: string;
    secondaryPhoneNumber?: string;
    positions?: LCEFacilityWorkerPosition[];
    facility?: string;
}

export interface LCEFacilityWorkerCreate {
    facilityCode: string;
    name: XSPersonName;
    email?: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber?: string;
    address?: XSAddress;
    note?: string;
    position: LCEFacilityWorkerPosition;
    hiredOn?: string;
}

export interface LCEFacilityWorkerPartial {
    id: string;
    code: string;
    facilityCode: string;
    facilityFullName: string;
    name: XSPersonName;
    primaryPhoneNumber: string;
    email?: string;
    position: LCEFacilityWorkerPosition;
}

export enum LCEFacilityWorkerPosition {
    NURSE = 'nurse',
    MIDWIFE = 'midwife',
    POLICE_OFFICER = 'policeOfficer',
    COURTHOUSE_OFFICER = 'courthouseOfficer',
    DOCTOR = 'doctor',
    OTHER = 'other',
}

export interface LCEFacilityWorker extends XSPKDTOAuditFull<XSUserPartial> {
    code: string;
    facility: LCEFacilityPartial;
    name: XSPersonName;
    email?: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber?: string;
    address?: XSAddress;
    note?: string;
    position: LCEFacilityWorkerPosition;
    hiredOn?: string;
}
