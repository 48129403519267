<div class="lce-delivery-company-main {{ styleClass }}">
    <div class="lce-delivery-company-main-container">
        <div class="xs-flex-row-center xs-width-full xs-mt-25 xs-mb-50">
            <div class="grid xs-width-full">
                <div class="col-12 md:col-3 xs-flex-row-center xs-position-relative xs-ptb-15">
                    <xs-background-icon
                            *ngIf="showBackgroundIcon"
                            [absoluteCenterVH]="true"
                            [data]="{ value: ICON_DELIVERY_COMPANY, color: 'discrete', size: '150px' }"
                            [opacity]="0.3">
                    </xs-background-icon>
                    <xs-statistic-tile
                            [loading]="statsLoading"
                            [value]="stats?.total"
                            class="xs-z-index-1"
                            text="{{ TR_BASE }}stats.total">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [loading]="statsLoading"
                            [value]="stats?.active"
                            text="{{ TR_BASE }}stats.active">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [loading]="statsLoading"
                            [value]="stats?.inactive"
                            text="{{ TR_BASE }}stats.inactive">
                    </xs-statistic-tile>
                </div>
                <div class="col-12 md:col-3 xs-flex-row-center xs-ptb-15">
                    <xs-statistic-tile
                            [loading]="statsLoading"
                            [value]="stats?.deleted"
                            text="{{ TR_BASE }}stats.deleted">
                    </xs-statistic-tile>
                </div>
            </div>
        </div>
        <!-- === * === -->
        <xs-data-manager #dataManager [options]="dataManagerOptions" styleClass="xs-min-height-750 xs-mt-50">
            <ng-template xsTemplate="sort">
                <xs-coming-soon [showBorder]="false" [showIcon]="true"></xs-coming-soon>
            </ng-template>

            <!-- === Row Result Template ===-->
            <ng-template let-oneResult xsTemplate="row">
                <lce-delivery-company-partial
                        [data]="oneResult"
                        iconStyleClass="xs-color-secondary xs-font-size-20-imp"
                        logoStyleClass="xs-border-discrete xs-border-width-2 xs-width-50-imp xs-height-50-imp">
                </lce-delivery-company-partial>
            </ng-template>

            <!-- === Record Template ===-->
            <ng-template let-recordData xsTemplate="record">
                <lce-delivery-company-record
                        (editEvent)="onEdit()"
                        [canOptions]="canOptions"
                        [dataManager]="dataManager"
                        [deliveryCompany]="recordData"
                        loadingStyleClass="xs-min-height-500 xs-min-width-600"
                        styleClass="xs-width-full xs-max-width-1000">
                </lce-delivery-company-record>
            </ng-template>
        </xs-data-manager>

    </div>
</div>