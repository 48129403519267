import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCECertificateOrderCreate,
    LCECertificateOrderCreateSession,
    LCECertificateOrderCreateSessionSearch,
    LCECertificateOrderPriceDetail,
    LCECertificateOrderPriceRequest,
    LCECertificateType,
    LCECoreContextService,
    LCEFacilityPartial,
    LCEUserPartial
} from '@lce/core';
import {LCEMockFacilityHandler} from '@lce/mock/core/handlers/facility/lce-mock-facility-handler';
import {LCE_MBO_MOCK_USER_STEVE_JOBS} from '@lce/mock/core/lce-mock-user-data';
import {XSCurrency, XSHttpMethod, XSHttpParamsUtils, XSStorageService, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceHandler} from '@xs/mock';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID} from '../../lce-mock.constant';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.certificateOrderCreateSessions';

@Injectable()
export class LCEMockCertificateOrderCreateSessionHandler extends XSMockPKResourceHandler<LCECertificateOrderCreateSession, LCECertificateOrderCreateSession, LCECertificateOrderCreateSessionSearch> {

    public static readonly CERTIFICATE_ORDER_CREATE_SESSION_ID = 'ef1298a6-9c10-497c-af4f-dc6d44d59bcc';

    public static STORAGE: Map<string, LCECertificateOrderCreateSession> = new Map<string, LCECertificateOrderCreateSession>();

    constructor(
        private localStorageService: XSStorageService,
        private contextService: LCECoreContextService,
        private mockFacility: LCEMockFacilityHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCECertificateOrderCreateSession> {
        return LCEMockCertificateOrderCreateSessionHandler.STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    toPartial(certificateOrderCreateSession: LCECertificateOrderCreateSession): LCECertificateOrderCreateSession {
        return certificateOrderCreateSession;
    }

    buildMockDataArray(): void {
        let requestURLBase = `${LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.index}`;
        let sRequestURL = `${requestURLBase}/${LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.session}`;
        let rRequestURL = `${requestURLBase}/${LCE_CORE_ENDPOINTS.certificateOrderCreatePriceCalculator.request}`;

        this.getLastCertificateOrderCreateSessionFromLocalStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 2000,
                getResponseData: (rArg) => this.createResponseData(rArg.body)
            },
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.priceCalculator.request',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: rRequestURL,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 4000,
                getResponseData: (rArg) => {
                    if (XSUtils.isEmpty(rArg.body)) {
                        throw new HttpErrorResponse({
                            error: {data: `body request can not be empty.`},
                            status: HttpStatusCode.InternalServerError,
                            statusText: 'Internal Server Error',
                            url: rRequestURL
                        });
                    }
                    return this.buildCertificateOrderRequestPriceDetail(rArg.body);
                }

            },
            {
                id: LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.priceCalculator.session',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: sRequestURL,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 4000,
                getResponseData: (rArg) => {
                    const certificateOrderCreateSessionID: string = XSHttpParamsUtils.getAsString(rArg.params!, 'certificateOrderCreateSessionID')!;
                    const currency: XSCurrency = XSHttpParamsUtils.getAsString(rArg.params!, 'currency')! as XSCurrency;
                    if (XSUtils.isEmpty(certificateOrderCreateSessionID)) {
                        throw new HttpErrorResponse({
                            error: {data: `failed to get given id : [${certificateOrderCreateSessionID}].`},
                            status: HttpStatusCode.InternalServerError,
                            statusText: 'Internal Server Error',
                            url: sRequestURL
                        });
                    }

                    let cOrderCreateSession = LCEMockCertificateOrderCreateSessionHandler.STORAGE.get(certificateOrderCreateSessionID);
                    if (XSUtils.isEmpty(cOrderCreateSession)) {
                        throw new HttpErrorResponse({
                            error: {data: `failed to get certificateOrderCreateSession by given id : [${certificateOrderCreateSessionID}].`},
                            status: HttpStatusCode.InternalServerError,
                            statusText: 'Internal Server Error',
                            url: sRequestURL
                        });
                    }
                    console.log(cOrderCreateSession);
                    return this.buildCertificateOrderSessionPriceDetail(cOrderCreateSession!, currency);
                }

            }
        ];
    }

    public findLast(): LCECertificateOrderCreateSession | undefined {
        const array = Array.from(this.getStorage().values());
        if (XSUtils.isEmpty(array)) return undefined;
        return array[array.length - 1];
    }

    public removeLastCertificateOrderCreateSessionFromLocalStorage(): void {
        this.localStorageService.removeObjectProperty('mock', 'lastCertificateOrderCreateSession');
    }

    private buildUpdateMock(orderCreateSessionID: string): XSMockData<LCECertificateOrderCreateSession> {
        return {
            id: DATASET_BASE_ID + '.update',
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.index + '/' + LCE_CORE_ENDPOINTS.certificateOrderCreateSessions.update.replace('{orderCreateSessionID}', orderCreateSessionID),
            requestStatus: HttpStatusCode.Created,
            requestDelay: 2000,
            getResponseData: (rArg) => this.updateResponseData(orderCreateSessionID, rArg.body)
        };
    }

    private buildCertificateOrderRequestPriceDetail(certificateOrderPriceRequest: LCECertificateOrderPriceRequest): LCECertificateOrderPriceDetail {
        let facility = this.mockFacility.findOneByCodeAsPartial(certificateOrderPriceRequest.facilityCode);
        return this.buildCertificateOrderPriceDetail(certificateOrderPriceRequest.certificateType!, facility, certificateOrderPriceRequest.numberOfCopies, certificateOrderPriceRequest.currency as XSCurrency);
    }

    private buildCertificateOrderSessionPriceDetail(cOrderCreateSession: LCECertificateOrderCreateSession, currency: XSCurrency): LCECertificateOrderPriceDetail {
        const certificateCreate = cOrderCreateSession.orderCreate.certificateCreate;
        const facility = this.mockFacility.findOneByCodeAsPartial(cOrderCreateSession.orderCreate.facilityCode);
        return this.buildCertificateOrderPriceDetail(certificateCreate.type, facility, cOrderCreateSession?.orderCreate.numberOfCopies, currency);
    }

    private buildCertificateOrderPriceDetail(type: LCECertificateType, facility: LCEFacilityPartial, numberOfCopies: number, currency: XSCurrency): LCECertificateOrderPriceDetail {
        const unitPriceValue = 1000;

        const taxValue = 300;
        const deliveryCostValue = 700;
        const totalFeesValue = 300;
        const totalValue = (unitPriceValue * numberOfCopies!) + taxValue + deliveryCostValue + totalFeesValue;
        return {
            unitPrice: {value: unitPriceValue, currency: currency},
            numberOfCopies: numberOfCopies,
            priceOfCopies: {value: unitPriceValue * numberOfCopies, currency: currency},
            tax: {value: taxValue, currency: currency},
            deliveryCost: {value: deliveryCostValue, currency: currency},
            totalFees: {value: totalFeesValue, currency: currency},
            total: {value: totalValue, currency: currency},
            context: {
                certificateType: type,
                facilityCode: facility.code,
                facilityName: facility.name,
                municipalityCode: facility.municipality.code,
                municipalityFullName: facility.municipality.name
            },
            parameters: {
                currencyRate: 61.11,
                priceSettingsID: XSUtils.uuid()
            }

        };
    }

    private updateResponseData(orderCreateSessionID: string, body: any): LCECertificateOrderCreateSession {
        const certificateOrderCreateSession: LCECertificateOrderCreateSession = {
            id: orderCreateSessionID,
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            orderCreate: body as LCECertificateOrderCreate
        };
        LCEMockCertificateOrderCreateSessionHandler.STORAGE.set(orderCreateSessionID, certificateOrderCreateSession);
        return certificateOrderCreateSession;
    }

    private createResponseData(body: any): LCECertificateOrderCreateSession {
        const certificateOrderCreateSession: LCECertificateOrderCreateSession = {
            id: LCEMockCertificateOrderCreateSessionHandler.CERTIFICATE_ORDER_CREATE_SESSION_ID,
            createdOn: new Date().toISOString(),
            createdBy: LCE_MBO_MOCK_USER_STEVE_JOBS,
            orderCreate: body as LCECertificateOrderCreate
        };
        LCEMockCertificateOrderCreateSessionHandler.STORAGE.set(certificateOrderCreateSession.id, certificateOrderCreateSession);
        this.addResourceBaseMockData(certificateOrderCreateSession.id);
        this.mockDataArray.push(this.buildUpdateMock(certificateOrderCreateSession.id));
        this.localStorageService.setObjectProperty('mock', 'lastCertificateOrderCreateSession', certificateOrderCreateSession);

        return certificateOrderCreateSession;
    }

    private getLastCertificateOrderCreateSessionFromLocalStorage(): void {
        const last = this.localStorageService.getObjectProperty('mock', 'lastCertificateOrderCreateSession');
        if (!XSUtils.isEmpty(last)) {
            LCEMockCertificateOrderCreateSessionHandler.STORAGE.set(last!.id, last!);
            this.addResourceBaseMockData(last!.id);
        }
    }
}
