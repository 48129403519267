export enum LCETemplateExceptionType {
    NOT_FOUND = 'notFound',
    ACCESS_DENIED = 'accessDenied',
    ERROR = 'error',
}

export interface LCETemplateExceptionState {
    secondaryMessage?: string;
    redirectURL?: string;
    redirectButtonLabel?: string;
}
