import {Injectable} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSDialogConfig, XSDialogService} from '@xs/core';
import {LCENewsArticle} from '@lce/core';
import {LCENewsArticleDetailComponent} from './article-detail/lce-news-article-detail.component';

export interface LCENewsArticleDialogDetailArg {
    articleID?: string;
    articleCode?: string;
    article?: LCENewsArticle;
    onClose?: (newsArticle?: LCENewsArticle) => void;
}

@Injectable()
export class LCENewsArticleDialogService {
    constructor(private dialogService: XSDialogService) {
    }

    public openArticleDialog(arg: LCENewsArticleDialogDetailArg): void {
        XSAssert.notEmpty(arg, 'arg');

        if (XSUtils.isEmpty(arg.articleCode) && XSUtils.isEmpty(arg.articleID) && XSUtils.isEmpty(arg.article)) {
            throw new Error('arg.newsArticleCode and arg.newsArticleID and arg.newsArticle cannot both be empty at the same time.');
        }
        if (!XSUtils.isEmpty(arg.articleCode) && !XSUtils.isEmpty(arg.articleID) && !XSUtils.isEmpty(arg.article)) {
            throw new Error('arg.newsArticleCode and arg.newsArticleID and arg.newsArticle cannot both be set at the same time.');
        }

        const dConfig: XSDialogConfig = {
            data: {
                articleCode: arg.articleCode,
                newsArticleID: arg.articleID,
                newsArticle: arg.article,
                loadingStyleClass: 'xs-min-width-500 xs-min-height-600'
            },
            header: true,
            headerSeparator: true,
            footer: true,
            footerSeparator: true,
            dialogStyleClass: 'xs-width-percent-90-imp xs-max-width-1200'
        };

        const articleDetailComponent: LCENewsArticleDetailComponent = this.dialogService.openComponentDialog(LCENewsArticleDetailComponent, dConfig);

        articleDetailComponent.closeEvent.subscribe((savedArticle: LCENewsArticle) => {
            if (arg?.onClose) arg!.onClose!(savedArticle);
        });
    }
}
