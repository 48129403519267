<div class="xs-position-relative xs-flex-column xs-align-items-center xs-width-full xs-min-width-300 xs-min-height-400 xs-border-discrete xs-border-radius-default xs-p-15 {{ styleClass }}">

    <!-- === Header === -->
    <div class="xs-flex-row xs-align-item-center xs-justify-content-space-between xs-width-full xs-mb-30">
        <div class="xs-flex-row xs-align-item-center">
            <i class="{{ ICON.chartPie }} xs-display-block xs-color-secondary xs-mr-5"></i>
            <span class="xs-display-block xs-color-secondary xs-font-size-intermediate">{{ TR_BASE + 'header' | translate }}</span>
        </div>

        <div class="xs-flex-row xs-align-items-center">
            <div class="xs-position-relative xs-mr-25">
                <xs-loader #loader [loaderId]="LOADER_ID" class="xs-absolute-center-vh"></xs-loader>
            </div>
            <xs-button [disabled]="hasError() || loader.isRunning() || !hasData()" [icon]="ICON.ellipsisVertical" [transform]="false" type="icon">
            </xs-button>
        </div>
    </div>

    <!-- === Error === -->
    <div *ngIf="!loader.isRunning() && hasError()" class="xs-position-relative xs-width-full xs-min-height-250">
        <div class="xs-absolute-center-vh xs-width-full">
            <xs-error [actionButton]="errorRetryButton" [error]="error" [showBorder]="false" [showErrorDetailButton]="true" mode="block">
            </xs-error>
        </div>
    </div>

    <!-- === Chart Doughnut === -->
    <div *ngIf="!hasError() && !hasData()" class="xs-absolute-center-vh">
        <xs-icon [value]="ICON.chartPie" color="discrete" size="150px" styleClass="xs-opacity-40"></xs-icon>
    </div>

    <div *ngIf="!hasError() && hasData()" class="xs-flex-column xs-height-full">
        <p-chart
            #chart
            [data]="chartDoughnutData"
            [ngClass]="{ 'xs-disabled': loader.isRunning() }"
            [options]="chartDoughnutOptions"
            class="lce-chart xs-mt-25"
            id="certificateOrderChartDoughnut"
            type="doughnut">
        </p-chart>

        <!-- === Chart Legend === -->
        <div class="xs-flex-column xs-mt-50 xs-width-full xs-max-width-300 xs-color-secondary">
            <div [ngClass]="{ 'xs-border-bottom-discrete': showSeparators }" class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-ptb-10">
            <span class="xs-display-block xs-font-size-intermediate">
                <i [style.color]="BIRTH_COLOR" class="{{ ICON.circle }} xs-font-size-small-imp"></i> &nbsp; {{ 'lce.core.certificateTypeMedium.birth' | translate }}
            </span>
                <span class="xs-display-block xs-font-weight-500">{{ loader.isRunning() ? '...' : birthPercentage }} %</span>
            </div>
            <div [ngClass]="{ 'xs-border-bottom-discrete': showSeparators }" class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-ptb-10">
            <span class="xs-display-block xs-font-size-intermediate">
                <i [style.color]="MARRIAGE_COLOR" class="{{ ICON.circle }} xs-font-size-small-imp"></i> &nbsp; {{ 'lce.core.certificateTypeMedium.marriage' | translate }}
            </span>
                <span class="xs-display-block xs-font-weight-500">{{ loader.isRunning() ? '...' : marriagePercentage }} %</span>
            </div>
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-ptb-10 ">
            <span class="xs-display-block xs-font-size-intermediate">
                <i [style.color]="DEATH_COLOR" class="{{ ICON.circle }} xs-font-size-small-imp"></i> &nbsp; {{ 'lce.core.certificateTypeMedium.death' | translate }}
            </span>
                <span class="xs-display-block xs-font-weight-500">{{ loader.isRunning() ? '...' : deathPercentage }} %</span>
            </div>
        </div>
    </div>
</div>