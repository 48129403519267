import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON, LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE, LCEBirthDeclarationParent, LCEBirthDeclarationService, LCEIdentityDocumentType} from '@lce/core';
import {LOG, XSAddressType, XSAssert, XSUtils} from '@xs/base';
import {XSFormUtils, XSLoaderService} from '@xs/common';
import {XSButton, XSConfirmation, XSDialogable, XSInputFieldAddressOptions, XSInputFieldCalendarOptions, XSInputFieldDropdownCountryOptions, XSInputFieldPhoneNumberOptions, XSInputFieldTextOptions, XSInputRadio} from '@xs/core';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-birth-declaration-parent-create-update', templateUrl: 'lce-birth-declaration-parent-create-update.component.html'})
export class LCEBirthDeclarationParentFormComponent extends XSDialogable implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.birthDeclaration.parent.';

    readonly LOADER_ID_CENTRAL: string = XSUtils.uuid();

    readonly DOCUMENT_IDENTITY_TYPE_RADIOS: XSInputRadio[] = [
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.PASSPORT,
            inputId: LCEIdentityDocumentType.PASSPORT,
            value: LCEIdentityDocumentType.PASSPORT
        },
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.DRIVER_LICENSE,
            inputId: LCEIdentityDocumentType.DRIVER_LICENSE,
            value: LCEIdentityDocumentType.DRIVER_LICENSE
        },
        {
            label: LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE + LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
            inputId: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
            value: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD
        }
    ];

    @Input() styleClass?: string;

    @Input() showBorder?: boolean = true;

    @Input() showCollapse: boolean;

    @Input() disabled?: boolean;

    @Input() formGroup: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();

    @Input() birthDeclarationID?: string;
    @Input() data?: LCEBirthDeclarationParent;

    @Input() type: 'mother' | 'father';

    @Input() birthDeclarationNumber?: string;

    @Output() saveEvent = new EventEmitter<LCEBirthDeclarationParent>();
    @Output() cancelEvent = new EventEmitter<void>();
    @Output() closeEvent = new EventEmitter<LCEBirthDeclarationParent>();

    @ViewChild('dHeader', {static: true}) headerTemplateRef: TemplateRef<any>;
    @ViewChild('dFooter', {static: true}) footerTemplateRef: TemplateRef<any>;

    firstNameField: XSInputFieldTextOptions;
    lastNameField: XSInputFieldTextOptions;
    birthDateField: XSInputFieldCalendarOptions;
    birthLocationField: XSInputFieldCalendarOptions;
    occupationField: XSInputFieldTextOptions;
    addressField: XSInputFieldAddressOptions;
    nationalityField: XSInputFieldDropdownCountryOptions;
    phoneNumberField: XSInputFieldPhoneNumberOptions;
    identityTypeField: XSInputFieldTextOptions;
    identityNumberField: XSInputFieldTextOptions;

    resetConfirmation: XSConfirmation;
    closeConfirmation: XSConfirmation;

    createUpdateLoading: boolean = false;

    selectedIdentityType: LCEIdentityDocumentType;

    retrieveError: any;
    retrieveErrorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo
    };

    createUpdateError: any;

    private subscription: Subscription = new Subscription();

    constructor(private loaderService: XSLoaderService, private birthDeclarationService: LCEBirthDeclarationService) {
        super();
    }

    ngOnInit(): void {
        if (this.isDialog()) {
            this.birthDeclarationID = this.dialogConfig.data.birthDeclarationID;
            this.data = this.dialogConfig.data.parent;
            this.type = this.dialogConfig.data.parentKey;
            this.showCollapse = this.dialogConfig.data.showCollapse;
            this.showBorder = this.dialogConfig.data.showBorder;
            this.birthDeclarationNumber = this.dialogConfig.data.birthDeclarationNumber;
        }
        XSAssert.notNull(this.type, 'type');
        this.initialize();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onIdentityTypeClicked(): void {
        if (this.selectedIdentityType == LCEIdentityDocumentType.DRIVER_LICENSE) {
            this.identityNumberField.placeholder = 'lce.shared.birthDeclaration.label.driverLicenseNumber';
        } else if (this.selectedIdentityType == LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD) {
            this.identityNumberField.placeholder = 'lce.shared.birthDeclaration.label.nationalIdentityCardNumber';
        } else {
            this.identityNumberField.placeholder = 'lce.shared.birthDeclaration.label.passportNumber';
        }
        this.updateIdentityType(this.selectedIdentityType);
    }

    public reset() {
        this.formGroup.reset();
    }

    public isLoaderRunning(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID_CENTRAL);
    }

    public hasRetrieveError(): boolean {
        return !XSUtils.isNull(this.retrieveError);
    }

    public hasCreateUpdateError(): boolean {
        return !XSUtils.isNull(this.createUpdateError);
    }

    public isUpdateMode(): boolean {
        return !XSUtils.isEmpty(this.data);
    }

    public isCreateMode(): boolean {
        return XSUtils.isEmpty(this.data) && XSUtils.isEmpty(this.data);
    }

    public shouldShowResetConfirmation(): boolean {
        return !this.isFormEmpty();
    }

    public isFormEmpty(): boolean {
        if (
            // ***
            !XSUtils.isEmpty(this.firstNameField.control?.value) ||
            !XSUtils.isEmpty(this.lastNameField.control?.value) ||
            !XSUtils.isEmpty(this.phoneNumberField.control?.value)
            // TODO
        ) {
            return false;
        }
        return true;
    }

    public validate(): boolean {
        XSFormUtils.validateFormGroup(this.formGroup);
        return this.formGroup.valid;
    }

    public buildCreate(formData: any): LCEBirthDeclarationParent {
        let birthDeclarationParentCreate: LCEBirthDeclarationParent = {
            name: {firstName: XSUtils.trim(formData.firstName), lastName: XSUtils.trim(formData.lastName)},
            birthDate: formData.birthDate,
            birthLocation: formData.birthLocation,
            occupation: XSUtils.trim(formData.occupation),
            address: formData.address,
            nationality: formData.nationality.iso,
            identityType: formData.identityType,
            identityNumber: XSUtils.trim(formData.identityNumber),
            phoneNumber: XSUtils.trim(formData.phoneNumber)
        };
        XSUtils.removeNullAndUndefinedEntries(birthDeclarationParentCreate);
        return birthDeclarationParentCreate;
    }

    public buildUpdate(fieldValueMap: Map<string, any>, formData: any, parent: LCEBirthDeclarationParent | undefined, parentKey: 'mother' | 'father'): void {
        const key = `${parentKey}.`;
        if (!XSUtils.equal(formData.firstName, parent?.name?.firstName)) fieldValueMap.set(key + 'name.firstName', XSUtils.trim(formData.firstName));
        if (!XSUtils.equal(formData.lastName, parent?.name?.lastName)) fieldValueMap.set(key + 'name.lastName', XSUtils.trim(formData.lastName));
        if (!XSUtils.equal(formData.birthDate, parent?.birthDate)) fieldValueMap.set(key + 'birthDate', formData.birthDate);
        if (!XSUtils.equal(formData.birthLocation, parent?.birthLocation)) fieldValueMap.set(key + 'birthLocation', formData.birthLocation);
        if (!XSUtils.equal(formData.occupation, XSUtils.trim(parent?.occupation))) fieldValueMap.set(key + 'occupation', formData.occupation);
        if (!XSUtils.equal(formData.address, parent?.address)) fieldValueMap.set(key + 'address', formData.address);
        if (!XSUtils.equal(formData.nationality, XSUtils.trim(parent?.nationality))) fieldValueMap.set(key + 'nationality', formData.nationality);
        if (!XSUtils.equal(formData.identityType, parent?.identityType)) fieldValueMap.set(key + 'identityType', formData.identityType);
        if (!XSUtils.equal(formData.identityNumber, XSUtils.trim(parent?.identityNumber))) fieldValueMap.set(key + 'identityNumber', formData.identityNumber);
        if (!XSUtils.equal(formData.phoneNumber, XSUtils.trim(parent?.phoneNumber))) fieldValueMap.set(key + 'phoneNumber', formData.phoneNumber);
    }

    public fillForm(): void {
        this.updateIdentityType(LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD);
        this.formGroup.get(this.firstNameField.fieldName)?.setValue('Steve');
        this.formGroup.get(this.lastNameField.fieldName)?.setValue('Jobs');
        this.formGroup.get(this.birthDateField.fieldName)?.setValue('2022-03-25');
        this.formGroup.get(this.birthLocationField.fieldName)?.setValue('Abidjan');
        this.formGroup.get(this.occupationField.fieldName)?.setValue('Professeur');
        this.formGroup.get(this.addressField.fieldName)?.setValue(LCE_MOCK_ADDRESS_STRUCTURED_ADDRESS_GASTON);
        this.formGroup.get(this.nationalityField.fieldName)?.setValue({iso: 'ci'});
        this.formGroup.get(this.identityNumberField.fieldName)?.setValue('CI12456799');
        this.formGroup.get(this.phoneNumberField.fieldName)?.setValue('+2250101010101');
    }

    public shouldShowCloseConfirmation(): boolean {
        if (this.isCreateMode()) return !this.isFormEmpty();
        else return this.formGroup.dirty && this.formValueChange();
    }

    public createUpdate(): void {
        this.createUpdateError = undefined;
        if (this.isCreateMode()) this.create();
        else this.update();
    }

    public formValueChange(): boolean {
        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = new Map();
        this.buildUpdate(fieldValueMap, formData, this.data, this.type);
        if (fieldValueMap.size === 0) return false;
        else return true;
    }

    public update(): void {
        XSFormUtils.validateFormGroup(this.formGroup);
        if (!this.formGroup.valid) return;
        LOG().debug('Updating Parent [parent: ' + this.data + '] ...');
        this.createUpdateLoading = true;

        const formData = this.formGroup.value;
        const fieldValueMap: Map<string, any> = new Map();
        this.buildUpdate(fieldValueMap, formData, this.data, this.type);

        if (fieldValueMap.size === 0) {
            LOG().debug('Parent not updated. No change detected ');
            this.saveEvent.emit(this.data!);
            return;
        }
        this.subscription.add(
            this.birthDeclarationService
                .update(this.birthDeclarationID!, fieldValueMap)
                .pipe(finalize(() => (this.createUpdateLoading = false)))
                .subscribe({
                    next: (birthDeclaration) => {
                        LOG().debug('Birth Declaration successfully saved :-) [code: ' + birthDeclaration.code + ', id: ' + birthDeclaration.id + ']');
                        this.type == 'mother' ? this.saveEvent.emit(birthDeclaration.mother) : this.saveEvent.emit(birthDeclaration.father);
                    },
                    error: (error) => (this.createUpdateError = error)
                })
        );
    }

    public getHeaderTemplateRef(): TemplateRef<any> | undefined {
        return this.headerTemplateRef;
    }

    public getFooterTemplateRef(): TemplateRef<any> | undefined {
        return this.footerTemplateRef;
    }

    private initialize(): void {
        this.buildFields();
        this.buildConfirmations();
    }

    private buildFields(): void {
        this.firstNameField = {
            fieldName: 'firstName',
            control: new FormControl(this.data?.name.firstName, Validators.required),
            label: this.TR_BASE + 'firstName'
        };
        this.lastNameField = {
            fieldName: 'lastName',
            control: new FormControl(this.data?.name.lastName, Validators.required),
            label: this.TR_BASE + 'lastName'
        };

        let birthDate = this.data?.birthDate ? new Date(this.data?.birthDate) : '';
        this.birthDateField = {
            fieldName: 'birthDate',
            label: this.TR_BASE + 'birthDate',
            calendarOptions: {dateFormatFR: 'yy-mm-dd', showIcon: true},
            control: new FormControl(birthDate)
        };

        this.birthLocationField = {
            fieldName: 'birthLocation',
            label: this.TR_BASE + 'birthLocation',
            control: new FormControl(this.data?.birthLocation),
            placeholder: this.TR_BASE + 'birthLocation'
        };

        this.occupationField = {
            fieldName: 'occupation',
            control: new FormControl(this.data?.occupation),
            label: this.TR_BASE + 'occupation'
        };

        this.addressField = {
            fieldName: 'address',
            label: this.TR_BASE + 'address',
            control: new FormControl(this.data?.address),
            addressOptions: {
                formOptions: {
                    type: undefined!,
                    title: true,
                    typeSelection: true,
                    allowedSelectionTypes: [XSAddressType.BASIC, XSAddressType.STRUCTURED, XSAddressType.UNSTRUCTURED]
                }
            }
        };
        this.nationalityField = {
            fieldName: 'nationality',
            label: this.TR_BASE + 'nationality',
            control: new FormControl(this.data?.nationality, [Validators.required]),
            showFlag: true,
            showName: true,
            showPhoneCode: true,
            placeholder: 'Select a country ...'
        };
        this.phoneNumberField = {
            fieldName: 'phoneNumber',
            control: new FormControl(this.data?.phoneNumber, Validators.required),
            label: this.TR_BASE + 'phoneNumber',
            countryISO: 'ci',
            showCountryFlag: true
        };
        this.identityTypeField = {
            fieldName: 'identityType',
            control: new FormControl(this.data?.identityType)
        };
        this.identityNumberField = {
            fieldName: 'identityNumber',
            control: new FormControl(this.data?.identityNumber, Validators.required),
            groupAddon: true,
            placeholder: this.TR_BASE + 'identityNumber',
            leftAddonValue: '#'
        };

        if (!XSUtils.isEmpty(this.data?.identityType)) {
            this.selectedIdentityType = this.data!.identityType!;
            this.identityTypeField.control!.setValue(this.selectedIdentityType);
        }

        this.formGroup.addControl(this.firstNameField.fieldName, this.firstNameField.control!);
        this.formGroup.addControl(this.lastNameField.fieldName, this.lastNameField.control!);
        this.formGroup.addControl(this.birthDateField.fieldName, this.birthDateField.control!);
        this.formGroup.addControl(this.birthLocationField.fieldName, this.birthLocationField.control!);
        this.formGroup.addControl(this.occupationField.fieldName, this.occupationField.control!);
        this.formGroup.addControl(this.addressField.fieldName, this.addressField.control!);
        this.formGroup.addControl(this.nationalityField.fieldName, this.nationalityField.control!);
        this.formGroup.addControl(this.identityTypeField.fieldName, this.identityTypeField.control!);
        this.formGroup.addControl(this.identityNumberField.fieldName, this.identityNumberField.control!);
        this.formGroup.addControl(this.phoneNumberField.fieldName, this.phoneNumberField.control!);
    }

    private buildConfirmations() {
        this.resetConfirmation = {
            key: 'resetConfirmationKey',
            trMessage: 'lce.shared.confirmation.resetConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.reset();
            },
            reject: () => {
            }
        };
        this.closeConfirmation = {
            key: 'closeConfirmationKey',
            trMessage: 'lce.shared.confirmation.closeConfirmation',
            icon: LCE_SHARED_ICON.confirmation,
            accept: () => {
                this.closeDialog();
            },
            reject: () => {
            }
        };
    }

    private updateIdentityType(identityType: LCEIdentityDocumentType) {
        this.selectedIdentityType = identityType;
        this.formGroup.get(this.identityTypeField.fieldName)!.setValue(this.selectedIdentityType);
    }

    private create(): void {
    }
}
