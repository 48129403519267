import {Injectable} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderPartial, LCECertificateOrderSearch, LCECertificateOrderService, LCECertificateType} from '@lce/core';
import {XSAssert, XSCount, XSPagination, XSSearchResult, XSSort, XSUtils} from '@xs/base';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LCE_CERTIFICATE_ORDERS_STORAGE_KEY} from '../api/constants/lce-shared.constant';
import {XSDialogConfig, XSDialogService, XSLocalStorageService} from '@xs/core';
import {LCECertificateOrdersPendingOrdersComponent} from './pending-orders/lce-certificate-orders-pending-orders.component';
import {LCECertificateOrderBatchProcessDialogService} from '../certificate-order-batch-process/lce-certificate-order-batch-process-dialog.service';

@Injectable()
export class LCECertificateOrdersFeatureService {
    static readonly TR_BASE = 'lce.shared.certificateOrders.';

    static readonly STORAGE_KEY = LCE_CERTIFICATE_ORDERS_STORAGE_KEY;
    static readonly STORAGE_KEY_STATISTICS_EXPANDED = 'statistics.expanded';
    static readonly STORAGE_KEY_STATISTICS_VISIBLE = 'statistics.visible';
    static readonly STORAGE_KEY_BATCH_PROCESS_CODE_VISIBLE = 'batchProcessCode.visible';

    public sort: XSSort | undefined;
    public pagination: XSPagination;

    private readonly USER_TABLE_DEFAULT_PAGINATION_ROWS: number = 10;

    private startSearchSubject = new Subject<void>();
    onStartSearch = this.startSearchSubject.asObservable();

    private endSearchSubject = new Subject<XSSearchResult<LCECertificateOrderPartial>>();
    onEndSearch = this.endSearchSubject.asObservable();

    private statisticsVisibleChangeSubject = new BehaviorSubject<boolean>(undefined!);
    onStatisticsVisibleChange = this.statisticsVisibleChangeSubject.asObservable();

    private batchProcessCodeVisibleChangeSubject = new BehaviorSubject<boolean>(undefined!);
    onBatchProcessCodeVisibleChange = this.batchProcessCodeVisibleChangeSubject.asObservable();

    private refreshSubject = new Subject<void>();
    onRefresh = this.refreshSubject.asObservable();

    private batchProcessCompletedSubject = new Subject<void>();
    onBatchProcessCompleted = this.batchProcessCompletedSubject.asObservable();

    constructor(
        private dialogService: XSDialogService,
        private certificateOrderService: LCECertificateOrderService,
        private certificateOrderBatchProcessDialogService: LCECertificateOrderBatchProcessDialogService,
        private localStorageService: XSLocalStorageService
    ) {
        this.pagination = {page: 1, size: this.USER_TABLE_DEFAULT_PAGINATION_ROWS};

        let statisticsVisible = this.localStorageService.getObjectProperty(LCECertificateOrdersFeatureService.STORAGE_KEY, LCECertificateOrdersFeatureService.STORAGE_KEY_STATISTICS_VISIBLE);
        if (XSUtils.isNull(statisticsVisible)) statisticsVisible = true;
        this.setStatisticsVisible(statisticsVisible);

        let batchProcessCodeVisible = this.localStorageService.getObjectProperty(
            LCECertificateOrdersFeatureService.STORAGE_KEY,
            LCECertificateOrdersFeatureService.STORAGE_KEY_BATCH_PROCESS_CODE_VISIBLE
        );
        if (XSUtils.isNull(batchProcessCodeVisible)) batchProcessCodeVisible = false;
        this.setBatchProcessCodeVisible(batchProcessCodeVisible);
    }

    public getCertificateType(certificateType: LCECertificateType | 'total' | undefined): LCECertificateType | 'total' {
        if (XSUtils.isEmpty(certificateType) || certificateType === 'total') {
            return 'total';
        }
        return certificateType!;
    }

    public openPendingOrdersDialog(municipalityCode: string): void {
        XSAssert.notEmpty(municipalityCode, 'municipalityCode');
        const dConfig: XSDialogConfig = {
            header: true,
            footer: true,
            footerSeparator: true,
            maxWidth: '500px',
            closable: true,
            data: {
                municipalityCode: municipalityCode
            }
        };
        const component = this.dialogService.openComponentDialog(LCECertificateOrdersPendingOrdersComponent, dConfig);

        component.newBatchProcessEvent.subscribe(() => {
            this.batchProcessCompletedSubject.next();
            component.closeDialog();
            this.certificateOrderBatchProcessDialogService.openDataManagerDialog();
        });
    }

    public emitBatchProcessCompleted(): void {
        this.batchProcessCompletedSubject.next();
    }

    public emitRefresh() {
        this.refreshSubject.next();
    }

    public isBatchProcessCodeVisible(): boolean {
        return this.batchProcessCodeVisibleChangeSubject.getValue();
    }

    public isStatisticsVisible(): boolean {
        return this.statisticsVisibleChangeSubject.getValue();
    }

    public setBatchProcessCodeVisible(visible: boolean) {
        XSAssert.notNull(visible, 'visible');
        this.localStorageService.setObjectProperty(LCECertificateOrdersFeatureService.STORAGE_KEY, LCECertificateOrdersFeatureService.STORAGE_KEY_BATCH_PROCESS_CODE_VISIBLE, visible);
        this.batchProcessCodeVisibleChangeSubject.next(visible);
    }

    public setStatisticsVisible(visible: boolean) {
        XSAssert.notNull(visible, 'visible');
        this.localStorageService.setObjectProperty(LCECertificateOrdersFeatureService.STORAGE_KEY, LCECertificateOrdersFeatureService.STORAGE_KEY_STATISTICS_VISIBLE, visible);
        this.statisticsVisibleChangeSubject.next(visible);
    }

    public retrieveOrder(certificateOrderID: string): Observable<LCECertificateOrder> {
        return this.certificateOrderService.retrieve(certificateOrderID);
    }

    public countOrders(certificateOrderSearch: LCECertificateOrderSearch): Observable<XSCount> {
        return this.certificateOrderService.count(certificateOrderSearch);
    }

    public search(certificateOrderSearch: LCECertificateOrderSearch): Observable<XSSearchResult<LCECertificateOrderPartial>> {
        this.startSearchSubject.next();
        certificateOrderSearch.paginationPage = this.pagination.page - 1;
        certificateOrderSearch.paginationSize = this.pagination.size;

        if (XSUtils.isEmpty(this.sort)) {
            certificateOrderSearch.sort = ['createdOn|desc'];
        } else {
            const sortStr = this.sort!.by.map((sortIem) => sortIem.field + '|' + sortIem.order).join(',');
            certificateOrderSearch.sort = [sortStr];
        }
        return this.certificateOrderService.search(certificateOrderSearch).pipe(tap((response) => this.endSearchSubject.next(response)));
    }
}
