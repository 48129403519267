<div class="xs-position-relative xs-width-full xs-height-full xs-plr-15 xs-min-width-800 xs-min-height-600 xs-overflow-x-auto {{ styleClass }}">
    <div class="xs-absolute-center-vh">
        <xs-loader #mainLoader [loaderId]="LOADER_MAIN_ID" [loaderSize]="30"></xs-loader>
    </div>
    <div *ngIf="hasMainError() && !mainLoader.isRunning()" class="xs-absolute-center-vh">
        <xs-error
                [actionButton]="errorMainRetryButton"
                [error]="errorMain"
                [showActionButton]="true"
                [showErrorDetailButton]="true"
                message="{{ TR_BASE }}errorMessage.retrieve"
                mode="block"
                subMessage="xs.core.error.contactAdminMessage">
        </xs-error>
    </div>
    <div *ngIf="!hasMainError() && !mainLoader.isRunning()" class="xs-card xs-height-full">
        <div *ngIf="!isDialog()" class="xs-card-header xs-card-header-separator">
            <span class="xs-card-header-title">{{ TR_BASE + 'recordName' | translate : {dateStr: (data!.createdOn! | xsDate : 'xsMediumLongDateTime')} }}</span>
            <span class="xs-card-header-sub-title xs-font-weight-500 xs-color-secondary">{{ data!.code | uppercase }}</span>
        </div>
        <div [ngClass]="{ 'xs-disabled': loading.refresh }" class="xs-card-content xs-pb-0-imp">
            <div class="xs-flex-row xs-align-items-center xs-justify-content-end xs-width-full xs-mb-15">
                <xs-button
                        *ngIf="readonly !== true"
                        [confirmation]="completeReopenBatchProcessConfirmation"
                        [icon]="completeReopenIcon"
                        [label]="completeReopenButtonLabel"
                        [loading]="loading.completeReopen"
                        [style.margin-left]="SPACE_BETWEEN_BTN"
                        loaderPosition="bottom"
                        size="intermediate"
                        type="text">
                </xs-button>
                <xs-button
                        (clickEvent)="onOpenDialog()"
                        *ngIf="!isDialog()"
                        [icon]="ICON.window"
                        [style.margin-left]="SPACE_BETWEEN_BTN"
                        type="icon">
                </xs-button>
                <xs-button
                        (clickEvent)="onDownload()"
                        [icon]="ICON.download"
                        [loading]="loading.download"
                        [style.margin-left]="SPACE_BETWEEN_BTN"
                        loaderPosition="bottom"
                        type="icon">
                </xs-button>
                <xs-button
                        (clickEvent)="onRefresh()"
                        [icon]="ICON.refresh"
                        [loading]="loading.refresh"
                        [style.margin-left]="SPACE_BETWEEN_BTN"
                        loaderPosition="bottom"
                        type="icon">
                </xs-button>
            </div>

            <div class="xs-flex-row xs-align-items-center xs-width-full xs-height-30">
                <xs-error *ngIf="hasErrorMessage() && !hasRefreshError()" [message]="errorMessage!" mode="inline"></xs-error>
                <xs-error *ngIf="hasErrorMessage() && hasRefreshError()" [error]="errorRefresh" [message]="errorMessage!" mode="inline"></xs-error>
            </div>

            <div class="grid xs-fields xs-fields-spacer-30 xs-p-15-imp xs-mlr-0-imp xs-mb-50-imp xs-mt-15-imp xs-border-left-width-5-imp xs-border-left-discrete">
                <div class="col-7">
                    <xs-data-field-text
                            [label]="TR_BASE + 'batchProcessingStatus'"
                            [value]="TR_BASE + 'batchProcessingCompleted.' + data!.completed | translate">
                    </xs-data-field-text>
                </div>
                <div class="col-5">
                    <div class="xs-flex-row xs-align-items-center">
                        <xs-square-data
                                [bottomText]="TR_BASE + 'orders'"
                                [indicatorIcon]="data!.completed ? ICON.thumbsUp : ICON.inProgress"
                                [indicator]="true"
                                [showBorder]="false"
                                indicatorColorStyleClass="{{ data!.completed ? 'xs-color-primary' : 'xs-color-secondary' }}"
                                indicatorStyleClass="xs-top-minus-15 xs-right-minus-20 xs-font-size-16-imp"
                                value="{{ data!.size }}">
                        </xs-square-data>

                        <span [innerHTML]="'lce.shared.label.nTotalCopies' | translate : { total: nTotalCopies }"
                              class="xs-display-block xs-font-size-intermediate xs-color-secondary xs-ml-40">
                        </span>
                    </div>
                </div>
                <div class="col-7">
                    <div class="xs-data-field xs-mb-0-imp">
                        <xs-data-field-label [label]="TR_BASE + 'started'"></xs-data-field-label>
                        <div class="xs-data-field-value-container">
                            <lce-user-resource-audit-inline [by]="data!.createdBy" [on]="data!.createdOn"></lce-user-resource-audit-inline>
                            <lce-user-resource-audit-inline
                                    *ngIf="data!.reopenedOn | xsIsNotEmpty"
                                    [by]="data!.reopenedBy"
                                    [onPrefix]="TR_BASE + 'reopenOn'"
                                    [on]="data!.reopenedOn"
                                    styleClass="xs-mt-10">
                            </lce-user-resource-audit-inline>
                        </div>
                    </div>
                </div>
                <div class="col-5">
                    <lce-data-field-user-resource-audit-inline [by]="data!.completedBy" [label]="TR_BASE + 'completed'" [on]="data!.completedOn">
                    </lce-data-field-user-resource-audit-inline>
                </div>
            </div>

            <!-- === Stats === -->
            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-mb-30">
                <lce-certificate-order-letter-stat [value]="countOrders.birth" type="birth"></lce-certificate-order-letter-stat>
                <lce-certificate-order-letter-stat [value]="countOrders.marriage" type="marriage"></lce-certificate-order-letter-stat>
                <lce-certificate-order-letter-stat [value]="countOrders.death" type="death"></lce-certificate-order-letter-stat>
            </div>

            <div class="xs-card xs-height-full xs-min-height-500">
                <div class="xs-card-header xs-card-header-separator xs-plr-0-imp">
                    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                        <span class="xs-card-header-title xs-color-secondary xs-font-size-medium-imp xs-font-weight-400-imp">
                            {{ TR_BASE + 'orders' | translate }}
                        </span>

                        <xs-input-search
                                (changeEvent)="searchOrder()"
                                [(value)]="searchOrderText"
                                [delay]="500"
                                [maxLength]="100"
                                [minNChars]="3"
                                class="xs-max-width-200 xs-ml-75"
                                inputId="searchOrderText"
                                placeholder="..."
                                styleClass="xs-input-search-small">
                        </xs-input-search>
                    </div>
                </div>
                <div class="xs-card-content xs-plr-0-imp xs-pt-20-imp">
                    <div class="xs-flex-row-center xs-width-full xs-mb-30">
                        <xs-badge
                                (clickEvent)="onCountOrderStatusClick()"
                                [mode]="selectedOrderStatus ? 'discrete' : 'primary'"
                                styleClass="xs-min-width-90"
                                value="{{ 'lce.shared.label.total' | translate }} ({{ countOrderStatuses.total }})"
                                valueStyleClass="xs-font-weight-500-imp">
                        </xs-badge>
                        <xs-badge
                                (clickEvent)="onCountOrderStatusClick(ORDER_STATUS.READY)"
                                [mode]="selectedOrderStatus === ORDER_STATUS.READY ? 'primary' : 'discrete'"
                                styleClass="xs-ml-30 xs-min-width-90"
                                value="{{ ORDER_STATUS.READY | lceCertificateOrderStatus }} ({{ countOrderStatuses.ready }})"
                                valueStyleClass="xs-font-weight-500-imp">
                        </xs-badge>
                        <xs-badge
                                (clickEvent)="onCountOrderStatusClick(ORDER_STATUS.ERROR)"
                                [mode]="selectedOrderStatus === ORDER_STATUS.ERROR ? 'primary' : 'discrete'"
                                styleClass="xs-ml-30 xs-min-width-90"
                                value="{{ ORDER_STATUS.ERROR | lceCertificateOrderStatus }} ({{ countOrderStatuses.error }})"
                                valueStyleClass="xs-font-weight-500-imp">
                        </xs-badge>
                        <xs-badge
                                (clickEvent)="onCountOrderStatusClick(ORDER_STATUS.CANCELLED)"
                                [mode]="selectedOrderStatus === ORDER_STATUS.CANCELLED ? 'primary' : 'discrete'"
                                styleClass="xs-ml-30 xs-min-width-90"
                                value="{{ ORDER_STATUS.CANCELLED | lceCertificateOrderStatus }} ({{ countOrderStatuses.cancelled }})"
                                valueStyleClass="xs-font-weight-500-imp">
                        </xs-badge>
                    </div>

                    <ul *ngIf="orders | xsIsNotEmpty" class="xs-listing xs-listing-separator xs-width-full xs-height-full">
                        <li *ngFor="let order of orders" class="xs-listing-item">
                            <lce-certificate-order-row (statusUpdatedEvent)="onStatusUpdated()" [data]="order" [orderNumberClickable]="true"
                                                       [readonly]="this.data!.completed || readonly === true">
                            </lce-certificate-order-row>
                        </li>
                    </ul>

                    <div *ngIf="orders | xsIsEmpty" class="xs-flex-column-center xs-width-full xs-pt-40">
                        <xs-no-result-found></xs-no-result-found>
                    </div>
                </div>
            </div>
        </div>
        <div class="xs-card-footer xs-align-items-end">
            <xs-data-id [value]="data!.id" styleClass="xs-mt-40"></xs-data-id>
        </div>
    </div>
</div>

<!-- === Dialog Header Template === -->
<ng-template #dHeader>
    <xs-ivar
            *ngIf="data | xsIsNotEmpty"
            [ellipsis]="true"
            [line2]="data!.code | uppercase"
            line1="{{ TR_BASE + 'recordName' | translate : { dateStr: (data!.createdOn! | xsDate : 'xsMediumDateTime') } }}"
            styleClass="xs-dialog-header">
    </xs-ivar>
    <xs-ivar *ngIf="data | xsIsEmpty" line1="..." line2="LCE-BCH- ..." styleClass="xs-dialog-header"></xs-ivar>
</ng-template>
