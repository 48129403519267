import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderUpdateStatusResponse} from '@lce/core';
import {XSAssert} from '@xs/base';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';

@Component({selector: 'lce-certificate-order-general', templateUrl: './lce-certificate-order-general.component.html'})
export class LCECertificateOrderGeneralComponent implements OnInit {
    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrderRecord.';

    @Input() styleClass?: string;

    @Input() readonly?: boolean;
    @Input() stampTokenClickable?: boolean;

    @Input() data: LCECertificateOrder;

    @Output() statusUpdatedEvent = new EventEmitter<LCECertificateOrderUpdateStatusResponse>();

    error: any;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public onStatusUpdated(updateStatusResponse: LCECertificateOrderUpdateStatusResponse): void {
        XSAssert.isTrue(this.data.id === updateStatusResponse.id, 'data.id and updateStatusResponse.id must be equal.');
        XSAssert.isTrue(this.data.orderNumber === updateStatusResponse.orderNumber, 'data.orderNumber and updateStatusResponse.orderNumber must be equal.');

        this.data.statusAudit[this.data.status] = {
            on: updateStatusResponse.updatedOn,
            by: updateStatusResponse.updatedBy
        };
        this.data.status = updateStatusResponse.status;
        this.data.statusUpdatedOn = updateStatusResponse.statusUpdatedOn;
        this.data.updatedOn = updateStatusResponse.updatedOn;
        this.data.updatedBy = updateStatusResponse.updatedBy;
        this.data = {...this.data};
        this.statusUpdatedEvent.emit(updateStatusResponse);
    }

    public onError(error: any): void {
        this.error = error;
    }
}
