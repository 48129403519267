<div class="xs-position-relative xs-flex-column xs-width-full xs-min-width-500 xs-height-full xs-min-height-400 xs-border-discrete xs-border-radius-default xs-p-15 {{ styleClass }}">

    <!-- === Header === -->
    <div class="xs-flex-row xs-align-item-center xs-justify-content-space-between xs-width-full xs-mb-30">
        <div class="xs-flex-row xs-align-item-center">
            <i class="{{ ICON.chartLine }} xs-display-block xs-color-secondary xs-mr-5"></i>
            <span class="xs-display-block xs-color-secondary xs-font-size-intermediate">{{ TR_BASE + 'header' | translate }}</span>
        </div>

        <div class="xs-flex-row xs-align-items-center">
            <div class="xs-position-relative xs-mr-25">
                <xs-loader #loader [loaderId]="LOADER_ID" class="xs-absolute-center-vh"></xs-loader>
            </div>
            <xs-button [disabled]="hasError() || loader.isRunning() || !hasData()" [icon]="ICON.ellipsisVertical" [transform]="false" type="icon">
            </xs-button>
        </div>
    </div>

    <!-- === Error === -->
    <div *ngIf="!loader.isRunning() && hasError()" class="xs-position-relative xs-width-full xs-min-height-250">
        <div class="xs-absolute-center-vh xs-width-full">
            <xs-error [actionButton]="errorRetryButton" [error]="error" [showBorder]="false" [showErrorDetailButton]="true" mode="block">
            </xs-error>
        </div>
    </div>

    <!-- === Chart Line === -->
    <p-chart
        #chart
        *ngIf="!hasError() && hasData()"
        [data]="chartLineData"
        [ngClass]="{ 'xs-disabled': loader.isRunning() }"
        [options]="chartLineOptions"
        class="lce-chart xs-scrollbar-hide"
        id="certificateOrderChartLine"
        type="line">
    </p-chart>

    <div *ngIf="!hasError() && !hasData()" class="xs-absolute-center-vh">
        <xs-icon [value]="ICON.chartLine" color="discrete" size="150px" styleClass="xs-opacity-40"></xs-icon>
    </div>

</div>