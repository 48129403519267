import {Component, Input, OnInit} from '@angular/core';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';
import {LCESmartCityArticlePartial} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSIvarAvatarType} from '@xs/core';
import {LCESmartCityArticleDialogService} from '../lce-smartcity-article-dialog.service';

@Component({selector: 'lce-smartcity-article-partial-overlay', templateUrl: './lce-smartcity-article-partial-overlay.html', providers: [LCESmartCityArticleDialogService]})
export class LCESmartCityArticlePartialOverlay implements OnInit {

    readonly ICON = LCE_SHARED_ICON;

    @Input() styleClass?: string;

    @Input() data: LCESmartCityArticlePartial;

    @Input() viewArticle?: boolean;

    @Input() showAvatar?: boolean;

    @Input() showBorder?: boolean;

    avatar: { value?: string; type?: XSIvarAvatarType } = {};

    constructor(private smartcityDialogService: LCESmartCityArticleDialogService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');

        if (XSUtils.isNull(this.viewArticle)) this.viewArticle = false;

        if (XSUtils.isNull(this.data.coverImage)) {
            this.avatar.type = XSIvarAvatarType.ICON;
            this.avatar.value = this.ICON.smartCity;
        } else {
            this.avatar.type = XSIvarAvatarType.IMAGE;
            this.avatar.value = this.data.coverImage!;
        }
    }

    public onViewRecordBtnClick() {
        this.smartcityDialogService.openArticleDialog({articleID: this.data.id});
    }
}
