import {Component, Input, OnInit} from '@angular/core';
import {LCECoreContextService, LCEUserPartial} from '@lce/core';
import {LCEUserAccountDialogService, LCEUserAccountPanelResource} from '@lce/shared';
import {LCE_CMD_ICON} from '../../../../core/constants/lce-cmd-icon.constant';
import {LCECMDAuthenticationService} from '../../../../core/services/lce-cmd-authentication.service';

@Component({
    selector: 'lce-user-menu',
    templateUrl: './lce-user-menu.component.html',
    providers: [LCEUserAccountDialogService]
})
export class LCEUserMenuComponent implements OnInit {
    @Input() styleClass?: string;

    ICON = LCE_CMD_ICON;

    user: LCEUserPartial;

    constructor(
        private contextService: LCECoreContextService,
        private authenticationService: LCECMDAuthenticationService,
        private userAccountDialogService: LCEUserAccountDialogService) {
    }

    ngOnInit() {
        this.user = this.contextService.getUser();
    }

    public openSettingsDialog(): void {
        this.userAccountDialogService.openRecordDialog({
            userID: this.user.id, userType: this.user.type, header: false, tabViewActiveIndex: LCEUserAccountPanelResource.SETTINGS
        });
    }

    public openAccountDialog(): void {
        this.userAccountDialogService.openRecordDialog({userID: this.user.id, userType: this.user.type, header: false});
    }

    public onLogout(): void {
        this.authenticationService.logout();
    }
}
