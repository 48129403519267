import {HttpErrorResponse, HttpParams, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {
    LCE_CORE_ENDPOINTS,
    LCEArticleReviewStatus,
    LCECoreContextService,
    LCENewsArticle,
    LCENewsArticleCard,
    LCENewsArticleCreate,
    LCENewsArticlePartial,
    LCENewsArticleSearch,
    LCENewsArticleStatus,
    LCEUserPartial
} from '@lce/core';
import {LCEMockMunicipalityHandler} from '@lce/mock/core/handlers/lce-mock-municipality-handler';
import {LCEMockNewsArticleCommentHandler} from '@lce/mock/core/handlers/news/lce-mock-news-article-comment-handler';
import {LCEMockUtils} from '@lce/mock/core/lce-mock-utils';
import {XSHttpMethod, XSPKDTOAuditFullState, XSSearchResult, XSUtils} from '@xs/base';
import {XSMockData, XSMockPKResourceAuditFullHandler, XSMockPKSearchable, XSMockSearchPredicate, XSMockSearchQueryPredicate} from '@xs/mock';
import LCE_NEWS_JSON_ARTICLES from '../../data/lce-news-articles.json';
import {LCE_MBO_MOCK_USER_ELON_MUSK, LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY} from '../../lce-mock-user-data';
import {LCE_HTTP_MOCK_DATASET_DEFAULT_ID, LCE_MOCK_TAGS} from '../../lce-mock.constant';
import {LCEMockNewsArticleCategoryHandler} from './lce-mock-news-article-category-handler';

const DATASET_BASE_ID: string = LCE_HTTP_MOCK_DATASET_DEFAULT_ID + '.news.articles';

@Injectable()
export class LCEMockNewsArticleHandler extends XSMockPKResourceAuditFullHandler<LCENewsArticle, LCENewsArticlePartial, LCENewsArticleSearch>
    implements XSMockPKSearchable<LCENewsArticlePartial> {

    public static ARTICLE_STORAGE: Map<string, LCENewsArticle> = new Map<string, LCENewsArticle>();

    private queryPredicates: XSMockSearchQueryPredicate<LCENewsArticle>[] = [
        (article, query) => article.code.toLowerCase().includes(query),
        (article, query) => article.ownerMunicipality.code.toLowerCase().includes(query),
        (article, query) => article.title.toLowerCase().includes(query),
        (article, query) => article.subTitle?.toLowerCase().includes(query),
        (article, query) => article.description.toLowerCase().includes(query),
        (article, query) => article.content.toLowerCase().includes(query)
    ];

    private searchPredicates: XSMockSearchPredicate<LCENewsArticle>[] = [
        (article, params) => this.httpParamArrayIncludes(params, 'codes', article.code),
        (article, params) => this.httpParamStringIncludes(params, 'title', article.title),
        (article, params) => this.httpParamStringIncludes(params, 'subTitle', article.subTitle),
        (article, params) => this.httpParamStringIncludes(params, 'description', article.description),
        (article, params) => this.httpParamStringIncludes(params, 'content', article.content),
        (article, params) => this.httpParamArrayIncludes(params, 'statuses', article.status),
        (article, params) => this.httpParamArrayIncludes(params, 'categoryCodes', article.categories.map(category => category.code))
    ];

    constructor(
        private contextService: LCECoreContextService,
        private mockMunicipalityHandler: LCEMockMunicipalityHandler,
        private mockNewsArticleCategoryHandler: LCEMockNewsArticleCategoryHandler,
        private mockNewsArticleCommentHandler: LCEMockNewsArticleCommentHandler) {
        super(DATASET_BASE_ID, LCE_CORE_ENDPOINTS.news.articles.index);
        this.mockDataArray = [];
    }

    public getStorage(): Map<string, LCENewsArticle> {
        return LCEMockNewsArticleHandler.ARTICLE_STORAGE;
    }

    public getAuthenticatedUser(): LCEUserPartial {
        return this.contextService.getUser();
    }

    public toPartial(article: LCENewsArticle): LCENewsArticlePartial {
        return {
            id: article.id,

            createdOn: article.createdOn,

            code: article.code,

            title: article.title,
            description: article.description,
            coverImage: article.coverImage,

            readCount: article.readCount,
            likeCount: article.likeCount,
            commentCount: article.commentCount,
            shareCount: article.shareCount,

            publishedOn: article.publishedOn
        };
    }

    public buildMockDataArray(): void {
        this.buildStorage();

        this.mockDataArray = [
            ...this.mockDataArray,
            // Create
            {
                id: DATASET_BASE_ID + '.create',
                active: true,
                requestMethod: XSHttpMethod.POST,
                requestURL: LCE_CORE_ENDPOINTS.news.articles.index,
                requestStatus: HttpStatusCode.Created,
                requestDelay: 3000,
                getResponseData: rArg => this.createResponseData(rArg.body)
            },
            // Count
            this.buildCountMockData(),
            // Autocomplete
            this.buildAutocompleteMockData({queryPredicates: this.queryPredicates}),
            // Search (Partials)
            this.buildSearchMockData({queryPredicates: this.queryPredicates, predicates: this.searchPredicates}),
            // Search (Cards)
            {
                id: `${DATASET_BASE_ID}.searchCards`,
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.news.articles.index + '/' + LCE_CORE_ENDPOINTS.news.articles.searchAsCards,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => {
                    return this.searchResponseData<LCENewsArticleCard>(rArg.params, this.queryPredicates, this.searchPredicates, article => this.toCard(article));
                }
            },
            // Last
            {
                id: DATASET_BASE_ID + '.lastCards',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.news.articles.index + '/' + LCE_CORE_ENDPOINTS.news.articles.lastAsCards,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => {
                    return this.getNLastResources(parseInt(rArg.params!.get('nLast')!)).map(article => this.toCard(article));
                }
            },
            {
                id: DATASET_BASE_ID + '.autocompleteTags',
                active: true,
                requestMethod: XSHttpMethod.GET,
                requestURL: LCE_CORE_ENDPOINTS.news.articles.index + '/' + LCE_CORE_ENDPOINTS.news.articles.autocompleteTags,
                requestStatus: HttpStatusCode.Ok,
                requestDelay: 2000,
                getResponseData: rArg => this.buildAutocompleteTagsResponseData(rArg.params!)
            }
        ];
    }

    public search(params: HttpParams | undefined): XSSearchResult<LCENewsArticlePartial> {
        return this.searchResponseData(params, this.queryPredicates, this.searchPredicates);
    }

    private buildRetrieveArticleByCode(articleCode: string): XSMockData<LCENewsArticle> {
        let requestURL = LCE_CORE_ENDPOINTS.news.articles.code.replace('${articleCode}', articleCode);
        return {
            id: DATASET_BASE_ID + '.retrieveByCode.' + articleCode,
            active: true,
            requestMethod: XSHttpMethod.GET,
            requestURL: requestURL,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByCode(articleCode);
                if (XSUtils.isNull(article)) {
                    throw new HttpErrorResponse({
                        error: {data: `failed to get news article : [${article}].`},
                        status: HttpStatusCode.InternalServerError,
                        statusText: 'Internal Server Error',
                        url: requestURL
                    });
                }
                return article;
            }
        };
    }


    private buildAutocompleteTagsResponseData(params: HttpParams): string[] {
        const query = params.get('query')?.trim();
        const limit = parseInt(params.get('limit')!);
        return LCE_MOCK_TAGS
            .filter(tag => {
                if (XSUtils.isEmpty(query)) {
                    return true;
                }
                return tag.includes(query!);
            })
            .slice(0, limit);
    }

    private buildAddTagsMockData(articleID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.addTags.' + articleID,
            active: true,
            requestMethod: XSHttpMethod.PATCH,
            requestURL: LCE_CORE_ENDPOINTS.news.articles.index + '/' + articleID + '/' + LCE_CORE_ENDPOINTS.news.articles.tags,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByID(articleID);
                if (XSUtils.isNull(article.tags)) {
                    article.tags = [];
                }
                article.tags!.concat(rArg.params?.get('tags')!.split(',')!);
                return article.tags;
            }
        };
    }

    private buildRemoveTagsMockData(articleID: string): XSMockData {
        return {
            id: DATASET_BASE_ID + '.removeTags.' + articleID,
            active: true,
            requestMethod: XSHttpMethod.DELETE,
            requestURL: LCE_CORE_ENDPOINTS.news.articles.index + '/' + articleID + '/' + LCE_CORE_ENDPOINTS.news.articles.tags,
            requestStatus: HttpStatusCode.Ok,
            requestDelay: 2000,
            getResponseData: rArg => {
                const article = this.findOneByID(articleID);
                if (XSUtils.isNull(article.tags)) {
                    article.tags = [];
                }
                const tagsToRemove = rArg.params?.get('tags')!.split(',')!;
                article.tags = article.tags!.filter(tag => !tagsToRemove.some(item => item === tag));
                return article.tags;
            }
        };
    }

    private createResponseData(body: any): LCENewsArticle {
        const articleCreate: LCENewsArticleCreate = body as LCENewsArticleCreate;
        const coverImage = 'assets/images/articles/robo.webp';

        const article: LCENewsArticle = {
            id: XSUtils.uuid(),
            createdOn: new Date().toISOString(),
            createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
            state: XSPKDTOAuditFullState.ACTIVE,
            code: 'LCE-NES-' + XSUtils.randomDigits(6),
            ownerMunicipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
            title: articleCreate.title.fr!,
            subTitle: articleCreate.subTitle?.fr,
            description: articleCreate.description.fr!,
            content: articleCreate.content.fr!,
            status: LCENewsArticleStatus.CREATED,
            coverImage: XSUtils.isNull(articleCreate.coverImage) ? coverImage : articleCreate.coverImage,
            images: [],
            reviewers: [
                {
                    user: LCE_MBO_MOCK_USER_ELON_MUSK,
                    addedOn: new Date().toISOString(),
                    reviewStatus: LCEArticleReviewStatus.PENDING,
                    isDefault: true
                },
                {
                    user: LCE_MBO_MOCK_USER_VAMOUSSA_COULIBALY,
                    addedOn: new Date().toISOString(),
                    reviewStatus: XSUtils.randomElement([LCEArticleReviewStatus.APPROVED, LCEArticleReviewStatus.REQUEST_CHANGES])
                }
            ],
            categories: this.mockNewsArticleCategoryHandler.findByCodeAsPartials(articleCreate.categoryCodes),
            tags: articleCreate.tags,
            favoriteCount: 0,
            readCount: 0,
            commentCount: 0,
            shareCount: 0,
            likeCount: 0,
            dislikeCount: 0
        };

        LCEMockNewsArticleHandler.ARTICLE_STORAGE.set(article.id, article);
        this.addArticleMockData(article);

        return article;
    }

    private buildStorage(): void {
        LCE_NEWS_JSON_ARTICLES.forEach((jsonArticle) => {
            const article: LCENewsArticle = {
                ...jsonArticle,
                createdOn: new Date().toISOString(),
                createdBy: LCEMockUtils.randomUserMunicipalEmployee(),
                state: XSPKDTOAuditFullState.ACTIVE,
                code: this.buildCode(),
                ownerMunicipality: this.mockMunicipalityHandler.getMunicipalityYopougon(),
                status: XSUtils.randomElement(XSUtils.getEnums(LCENewsArticleStatus)),
                reviewers: [
                    {
                        user: LCEMockUtils.randomUserMunicipalEmployee(),
                        addedOn: new Date().toISOString(),
                        reviewStatus: LCEArticleReviewStatus.PENDING,
                        isDefault: true
                    },
                    {
                        user: LCEMockUtils.randomUserMunicipalEmployee(),
                        addedOn: new Date().toISOString(),
                        reviewStatus: XSUtils.randomElement([LCEArticleReviewStatus.APPROVED, LCEArticleReviewStatus.REQUEST_CHANGES])
                    }
                ],
                favoriteCount: XSUtils.randomInteger(20, 1000),
                readCount: XSUtils.randomInteger(0, 1000000),
                commentCount: XSUtils.randomInteger(0, 500),
                shareCount: XSUtils.randomInteger(0, 100),
                likeCount: XSUtils.randomInteger(0, 100000),
                dislikeCount: XSUtils.randomInteger(0, 1000)
            };
            LCEMockNewsArticleHandler.ARTICLE_STORAGE.set(article.id, article);
            this.addArticleMockData(article);
        });
    }

    private addArticleMockData(article: LCENewsArticle): void {
        super.addResourceBaseMockData(article.id);
        this.buildAddTagsMockData(article.id);
        this.buildRemoveTagsMockData(article.id);
        this.mockNewsArticleCommentHandler.addArticleCommentsMockData({id: article.id, code: article.code});
        this.mockDataArray.push(this.buildRetrieveArticleByCode(article.code));
    }

    private toCard(article: LCENewsArticle): LCENewsArticleCard {
        return {
            id: article.id,

            createdOn: article.createdOn,
            createdBy: article.createdBy,

            code: article.code,

            title: article.title,
            description: article.description,
            coverImage: article.coverImage,

            readCount: article.readCount,
            likeCount: article.likeCount,
            commentCount: article.commentCount,
            shareCount: article.shareCount,

            publishedOn: article.publishedOn,
            publishedBy: article.publishedBy
        };
    }

    private buildCode(): string {
        return 'LCE-NES-' + XSUtils.randomDigits(6);
    }
}
