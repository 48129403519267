import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LCE_TR_BASE_CORE_LABEL, LCEDelivery} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEDeliveryAssignmentComponent} from '../assignment/lce-delivery-assignment.component';
import {LCEDeliveriesFeatureService} from '../lce-deliveries-feature.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'lce-delivery-record',
    templateUrl: './lce-delivery-record.component.html'
})
export class LCEDeliveryRecordComponent implements OnInit, OnDestroy {

    readonly TR_BASE = LCE_TR_BASE_CORE_LABEL;

    @Input() styleClass?: string;
    @Input() leftSideStyleClass?: string;

    @Input() showBorder?: boolean;
    @Input() showHeader?: boolean;

    @Input() data: LCEDelivery;

    @ViewChild('deliveryAssignment') deliveryAssignment: LCEDeliveryAssignmentComponent;

    private subscription = new Subscription();

    constructor(private deliveriesFeatureService: LCEDeliveriesFeatureService) {
        this.subscription.add(this.deliveriesFeatureService.onRefreshAssignment.subscribe(() => {
            console.log('onRefreshAssingnment');
            if (!XSUtils.isEmpty(this.deliveryAssignment)) this.deliveryAssignment.refresh();
        }));
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

}
