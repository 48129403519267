import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LCE_BIRTH_COLOR, LCE_DEATH_COLOR, LCE_MARRIAGE_COLOR, LCECertificateOrderService, XS_MONTH_NAME_MAP, XSMonthKey} from '@lce/core';
import {XS_STR_EMPTY, XSDateRange, XSPredefinedPeriod, XSUtils} from '@xs/base';
import {XSLoaderService, XSTranslationService} from '@xs/common';
import {XSButton} from '@xs/core';
import {UIChart} from 'primeng/chart';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {LCE_SHARED_ICON} from '../../api/constants/lce-shared-icon.constant';

@Component({
    selector: 'lce-dashboard-certificate-order-chart-line',
    templateUrl: './lce-dashboard-certificate-order-chart-line.component.html',
    host: {class: 'xs-height-full'}
})
export class LCEDashboardCertificateOrderChartLineComponent implements OnInit {

    ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.dashboard.chartLine.';

    readonly LOADER_ID: string = 'chartLineLoader';

    @Input() styleClass?: string;

    @Input() municipalityCode?: string;

    @Input() districtCode?: string;

    @Input() period?: XSPredefinedPeriod;

    @ViewChild('chart') chart: UIChart;

    chartLineData: any;
    chartLineOptions: any;

    currentMonthKeys: XSMonthKey[];

    error: any;
    errorRetryButton: XSButton = {
        type: 'text',
        label: 'xs.core.label.pleaseTryAgain',
        size: 'intermediate',
        icon: this.ICON.redo,
        onClick: () => this.update()
    };

    private readonly TR_BIRTH = 'lce.core.certificateType.birth';
    private readonly TR_MARRIAGE = 'lce.core.certificateType.marriage';
    private readonly TR_DEATH = 'lce.core.certificateType.death';

    private subscription = new Subscription();

    constructor(
        private translationService: XSTranslationService,
        private loaderService: XSLoaderService,
        private certificateOrderService: LCECertificateOrderService) {
    }

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.period)) this.period = XSPredefinedPeriod.THIS_YEAR;
        if (!XSUtils.isEmpty(this.municipalityCode) && !XSUtils.isEmpty(this.districtCode)) {
            throw new Error('municipalityCode and districtCode cannot be set both at the same time.');
        }
        this.buildChartLineData();
        this.buildCharLineOptions();
        this.update();
        this.subscription.add(this.translationService.onLanguageChanged.subscribe(() => this.updateLabels()));
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    public hasData(): boolean {
        return !XSUtils.isEmpty(this.chartLineData.labels);
    }

    public update(): void {
        this.loaderService.startLoader(this.LOADER_ID);

        const predefinedPeriod: XSPredefinedPeriod | undefined = this.period;
        const dateRange: XSDateRange | undefined = undefined;
        const municipalityCodes: string[] | undefined = XSUtils.isEmpty(this.municipalityCode) ? undefined : [this.municipalityCode!];

        this.error = undefined;

        this.subscription.add(
            this.certificateOrderService.chartLine(predefinedPeriod, dateRange, municipalityCodes)
                .pipe(finalize(() => {
                    this.loaderService.stopLoader(this.LOADER_ID);
                }))
                .subscribe(
                    {
                        next: response => {
                            this.currentMonthKeys = response.xAxis;
                            this.chartLineData.labels = this.translateMonthKeys(this.currentMonthKeys);
                            this.chartLineData.datasets[0].data = response.birthYAxisData;
                            this.chartLineData.datasets[1].data = response.marriageYAxisData;
                            this.chartLineData.datasets[2].data = response.deathYAxisData;
                            if (!XSUtils.isNull(this.chart) && this.hasData()) this.chart.refresh();
                        },
                        error: error => this.error = error
                    })
        );
    }

    private updateLabels(): void {
        const trLabels = this.translationService.translate([this.TR_BIRTH, this.TR_MARRIAGE, this.TR_DEATH]);
        this.chartLineData.labels = this.translateMonthKeys(this.currentMonthKeys);
        this.chartLineData.datasets[0].label = trLabels[this.TR_BIRTH];
        this.chartLineData.datasets[1].label = trLabels[this.TR_MARRIAGE];
        this.chartLineData.datasets[2].label = trLabels[this.TR_DEATH];
        if (!XSUtils.isNull(this.chart)) this.chart.refresh();
    }

    private translateMonthKeys(monthKeys: XSMonthKey[]): string[] {
        if (XSUtils.isEmpty(monthKeys)) return [];
        const language: string = this.translationService.getCurrentLanguage();
        return monthKeys.map(monthKey => XS_MONTH_NAME_MAP[language][monthKey]);
    }

    private buildChartLineData(): void {
        this.currentMonthKeys = [];
        this.chartLineData = {
            labels: [],
            tooltips: {
                displayColors: false
            },
            datasets: [
                {
                    label: XS_STR_EMPTY,
                    fill: false,
                    borderColor: LCE_BIRTH_COLOR,
                    yAxisID: 'y',
                    tension: 0.4,
                    data: []
                },
                {
                    label: XS_STR_EMPTY,
                    fill: false,
                    borderColor: LCE_MARRIAGE_COLOR,
                    yAxisID: 'y',
                    tension: 0.4,
                    data: []
                },
                {
                    label: XS_STR_EMPTY,
                    fill: false,
                    borderColor: LCE_DEATH_COLOR,
                    yAxisID: 'y',
                    tension: 0.4,
                    data: []
                }
            ]
        };
        this.updateLabels();
    }

    private buildCharLineOptions(): void {
        this.chartLineOptions = {
            stacked: false,

            plugins: {
                legend: {
                    labels: {
                        boxHeight: 1,
                        padding: 40,
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: false,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
    }
}
