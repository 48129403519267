import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {XSInputFieldBaseOptions, XSInputFieldCalendarOptions, XSInputFieldDropdownOptions, XSInputFieldTextOptions} from '@xs/core';
import {LCECertificateCreate, LCECertificateType, LCEFacilityPartial} from '@lce/core';
import {LCE_SHARED_ICON} from '../../../api/constants/lce-shared-icon.constant';
import {XSLabelValue} from '@xs/common';
import {XS_STR_EMPTY, XSTemporalUtils, XSUtils} from '@xs/base';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'lce-certificate-order-create-certificate',
    templateUrl: './lce-certificate-order-create-certificate.component.html',
    providers: [DatePipe]
})
export class LCECertificateOrderCreateCertificateComponent implements OnInit, OnChanges {

    readonly ICON = LCE_SHARED_ICON;

    readonly TR_BASE: string = 'lce.shared.certificateOrders.create.certificate.';
    readonly TR_BASE_CERTIFICATE_TYPE: string = 'lce.core.certificateTypeMedium.';

    @Input() styleClass?: string;

    @Input() certificateCreate?: LCECertificateCreate;
    @Input() facilityCode?: string;
    @Input() facility?: LCEFacilityPartial;

    @Input() formGroup: FormGroup = new FormGroup({});
    @Output() formGroupChange = new EventEmitter<FormGroup>();

    typeField: XSInputFieldDropdownOptions;
    referenceNumberField: XSInputFieldTextOptions;
    issueDateField: XSInputFieldCalendarOptions;
    facilityField: XSInputFieldBaseOptions;

    private readonly CERTIFICATE_TYPE: XSLabelValue[] = [
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.BIRTH, value: LCECertificateType.BIRTH},
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.MARRIAGE, value: LCECertificateType.MARRIAGE},
        {label: this.TR_BASE_CERTIFICATE_TYPE + LCECertificateType.DEATH, value: LCECertificateType.DEATH}
    ];

    constructor() {
    }

    ngOnInit(): void {
        this.buildFields();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.certificateCreate) && !changes.certificateCreate!.isFirstChange()) this.update(this.certificateCreate!);
        if (!XSUtils.isEmpty(changes.facility) && !changes.facility!.isFirstChange()) this.facilityField.control?.setValue(this.facility);
    }

    public isFormEmpty(): boolean {
        return !(!XSUtils.isEmpty(this.facilityField.control?.value) ||
            !XSUtils.isEmpty(this.typeField.control?.value) ||
            !XSUtils.isEmpty(this.issueDateField.control?.value) ||
            !XSUtils.isEmpty(this.referenceNumberField.control?.value));
    }

    private update(create: LCECertificateCreate): void {
        this.typeField.control?.setValue(create.type);
        this.referenceNumberField.control?.setValue(create.referenceNumber);
        const dateFormat = create.issueDate ? XSTemporalUtils.toDate(create.issueDate) : XS_STR_EMPTY;
        if (!XSUtils.isEmpty(dateFormat)) this.issueDateField.control?.setValue(dateFormat);
    }

    private buildFields(): void {
        this.facilityField = {
            fieldName: 'facility',
            control: new FormControl(undefined, Validators.required),
            label: this.TR_BASE + 'facility',
            belowText: this.TR_BASE + 'facilityBelowText'
        };

        this.typeField = {
            fieldName: 'type',
            label: this.TR_BASE + 'type',
            control: new FormControl(undefined, Validators.required),
            items: this.CERTIFICATE_TYPE,
            placeholder: this.TR_BASE + 'typePlaceholder'
        };

        this.referenceNumberField = {
            fieldName: 'referenceNumber',
            label: this.TR_BASE + 'referenceNumber',
            inputStyleClass: 'xs-text-transform-uppercase-imp xs-font-size-intermediate-imp',
            control: new FormControl(undefined, Validators.required)
        };

        this.issueDateField = {
            fieldName: 'issueDate',
            control: new FormControl(undefined, Validators.required),
            label: this.TR_BASE + 'issueDate',
            placeholder: '25-01-1998',
            calendarOptions: {
                showIcon: true
            }
        };
        this.formGroup!.addControl(this.facilityField.fieldName, this.facilityField.control!);
        this.formGroup!.addControl(this.typeField.fieldName, this.typeField.control!);
        this.formGroup!.addControl(this.issueDateField.fieldName, this.issueDateField.control!);
        this.formGroup!.addControl(this.referenceNumberField.fieldName, this.referenceNumberField.control!);
    }

}
