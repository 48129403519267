import {Injectable} from '@angular/core';
import {XSCommonContextService} from '@xs/common';
import {XSCoordinate} from '@xs/base';
import {LCEUserPartial} from '../domain/user/lce-user';

@Injectable()
export abstract class LCECoreContextService extends XSCommonContextService {

    public setUser(user: LCEUserPartial): void {
        super.setUser(user);
    }

    public getUser(): LCEUserPartial {
        return super.getUser() as LCEUserPartial;
    }

    public abstract getCurrentCountryISO(): string;

    public abstract getCurrentPosition(): XSCoordinate;
}
